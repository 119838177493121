<ng-container *ngIf='sikoBreakpointObserver.breakpointChange$ | async'>
  <ng-container *ngIf='!isSapOrder; else sapOrder'>
    <div *ngIf='sikoBreakpointObserver.isBreakpointActive(["xs", "sm"])' class='pb-2 mt-5'>
      <div class='card' *ngFor='let item of sikoItems; let i = index'>
        <div class='card-body'>
          <ng-container *ngIf='sikoBreakpointObserver.isBreakpointActive(["sm"])'>
            <div class='row'>
              <div class='col-3'>
                <div class='image'>
                  <a
                    [title]='item.product?.name'
                    [routerLink]='item.product | sikoProductUrl'>
                    <app-media
                      class='cart-icon'
                      style='max-width: 120px'
                      [container]='$any(item.product?.images?.PRIMARY)'
                      format='cartIcon'
                    ></app-media>
                  </a>
                </div>
              </div>
              <div class='col-9 pl-5'>
                <div>
                  <ng-container [ngTemplateOutlet]='catalogProductName'
                                [ngTemplateOutletContext]='{item: item.product}'
                  ></ng-container>
                  <div class='product-code'>{{item.product?.code}}</div>
                </div>
                <div class='d-flex' *ngIf='!arrayFreeGifts.includes(i)'><span>{{"siko.price" | cxTranslate}}
                  :</span>
                  <app-price [price]='item.basePrice | sikoPrice'
                             [MOPrice]='promotionLocation.includes("SAVED_CART")'>
                  </app-price>
                </div>
                <div *ngIf='!arrayFreeGifts.includes(i)'><span>{{"siko.yourPrice" | cxTranslate}}
                  :</span>{{item.totalPrice | sikoPrice}}</div>
                <div><span>{{"sikoOrder.orderDetail.quantity" | cxTranslate}}:</span>
                  <ng-container
                    *ngIf='getControl(item) | async as control'>
                    <div *ngIf='!readonly else isReadonly'>
                      <app-cart-item-counter
                        [product]='item.product'
                        [displayRemoveIcon]='true'
                        [isLimited]='true'
                        [posName]='item.deliveryPointOfService?.displayName'
                        [index]='i'
                        [control]="$any(control.get('quantity'))"
                      ></app-cart-item-counter>
                    </div>
                    <ng-template #isReadonly>
                    <span *ngIf='item.quantity && item.product?.unitData?.conversion'>
                      {{item.quantity * +item.product!.unitData!.conversion | cxNumeric : '1.0-3'}}&nbsp;
                      <app-product-unit
                        [count]='item.quantity'
                        [productUnitData]='item.product?.unitData'
                        [inheritTextStyles]='true'
                      ></app-product-unit>
                    </span>
                    </ng-template>
                  </ng-container>
                  <div *ngIf='!isOrder' class='delivery'><span>{{"siko.delivery" | cxTranslate}}:</span>
                    <ng-container *ngIf='isPickUpInStore && item.deliveryPointOfService else deliveryToAddress'>
                      <span class='type-delivery'>{{'siko.pickUpInStore' | cxTranslate}}</span>
                      <span class='store'>{{item.deliveryPointOfService.displayName}}</span>
                    </ng-container>
                    <ng-template #deliveryToAddress>
                      {{'siko.deliveryToAddress' | cxTranslate}}
                    </ng-template>
                  </div>
                  <div>
                    <div class='total-price'>
                      <div class='row'>
                        <div class='col-6'>
                          <ng-container *ngIf='arrayFreeGifts.includes(i) else totalPrice'>
                            <div class='gift-cart-item-info'>
                              <h4 class='siko-color-success-primary mb-0 mt-1'>{{'siko.free' | cxTranslate}}</h4>
                            </div>
                          </ng-container>
                          <ng-template #totalPrice>
                            {{item.totalPrice | sikoPrice}}
                            <app-product-group *ngIf='!isOrder' [sikoProduct]='item.product'></app-product-group>
                          </ng-template>
                        </div>
                        <div class='col-6 text-right'>
                          <span [routerLink]='item.product | sikoProductUrl'>
                            <img [src]='"order_detail_vector.svg" | sikoIconAsset' alt='Arrow icon'>
                          </span>
                        </div>
                        <div class='col-12' *ngIf='promotionLocation === "SAVED_CART" && arrayFreeGifts.includes(i)'>
                          <div class='promotion-name'>
                            {{getPromotionName(i)}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div *ngIf='sikoBreakpointObserver.isBreakpointActive(["xs"])' class='d-flex mb-2'>
            <div class='image'>
              <a
                [attr.data-testid]='$any("image-product-" + item.product?.code + "-detail-button")'
                [title]='item.product?.name'
                [routerLink]='item.product | sikoProductUrl'>
                <app-media
                  class='cart-icon d-flex align-items-center justify-content-center'
                  style='max-width: 120px'
                  [container]='$any(item.product?.images?.PRIMARY)'
                  format='cartIcon'
                ></app-media>
              </a>
            </div>
            <div>
              <ng-container [ngTemplateOutlet]='catalogProductName'
                            [ngTemplateOutletContext]='{item: item.product}'
              ></ng-container>
              <div class='product-code'>{{item.product?.code}}</div>
            </div>
          </div>
          <ng-container *ngIf='sikoBreakpointObserver.isBreakpointActive(["xs"])'>
            <div class='d-flex' *ngIf='!arrayFreeGifts.includes(i)'><span>{{"siko.price" | cxTranslate}}
              :</span>
             <app-price [price]='item.basePrice | sikoPrice'
                        [MOPrice]='promotionLocation.includes("SAVED_CART")'>
             </app-price>
            </div>
            <div *ngIf='!arrayFreeGifts.includes(i)'><span>{{"siko.yourPrice" | cxTranslate}}
              :</span>{{item.totalPrice | sikoPrice}}</div>
            <div><span>{{"sikoOrder.orderDetail.quantity" | cxTranslate}}:</span>
              <ng-container
                *ngIf='getControl(item) | async as control'>
                <div *ngIf='!readonly else isReadonly'>
                  <app-cart-item-counter
                    [product]='item.product'
                    [displayRemoveIcon]='true'
                    [isLimited]='true'
                    [posName]='item.deliveryPointOfService?.displayName'
                    [index]='i'
                    [control]="$any(control.get('quantity'))"
                  ></app-cart-item-counter>
                </div>
                <ng-template #isReadonly>
          <span *ngIf='item.quantity && item.product?.unitData?.conversion'>
            {{item.quantity * +item.product!.unitData!.conversion | cxNumeric : '1.0-3'}}&nbsp;
            <app-product-unit
              [count]='item.quantity'
              [productUnitData]='item.product?.unitData'
              [inheritTextStyles]='true'
            ></app-product-unit>
          </span>
                </ng-template>
              </ng-container>
              <div class='delivery' *ngIf='!isOrder'><span>{{"siko.delivery" | cxTranslate}}:</span>
                <ng-container *ngIf='isPickUpInStore && item.deliveryPointOfService else deliveryToAddress'>
                  <span class='type-delivery'>{{'siko.pickUpInStore' | cxTranslate}}</span>
                  <span class='store'>{{item.deliveryPointOfService.displayName}}</span>
                </ng-container>
                <ng-template #deliveryToAddress>
                  {{'siko.deliveryToAddress' | cxTranslate}}
                </ng-template>
              </div>
              <div>
                <div class='total-price'>
                  <div class='row'>
                    <div class='col-6'>
                      <ng-container *ngIf='arrayFreeGifts.includes(i) else totalPrice'>
                        <div class='gift-cart-item-info'>
                          <h4 class='siko-color-success-primary mb-0 mt-1'>{{'siko.free' | cxTranslate}}</h4>
                        </div>
                      </ng-container>
                      <ng-template #totalPrice>
                        {{item.totalPrice | sikoPrice}}
                        <app-product-group *ngIf='!isOrder' [sikoProduct]='item.product'></app-product-group>
                      </ng-template>
                    </div>
                    <div class='col-6 text-right'>
                      <span [routerLink]='item.product | sikoProductUrl'>
                        <img [src]='"order_detail_vector.svg" | sikoIconAsset' alt='Arrow icon'>
                      </span>
                    </div>
                    <div class='col-12' *ngIf='promotionLocation === "SAVED_CART" && arrayFreeGifts.includes(i)'>
                      <div class='promotion-name'>
                        {{getPromotionName(i)}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf='sikoBreakpointObserver.isBreakpointActive(["md", "lg", "xl"])'>
      <div class='table-responsive'>
        <table
          aria-label='Cart items list'
          class='table' [attr.data-testid]='$any("products-table")'>
          <thead>
          <tr>
            <th scope='col'>{{ 'siko.item' | cxTranslate }}</th>
            <th scope='col' class='text-left'>{{ 'siko.price' | cxTranslate }} <span
              class='light-font'>({{ 'siko.withoutVat' | cxTranslate }})</span></th>
            <th scope='col' class='text-left'>{{ 'siko.yourPrice' | cxTranslate }} <span
              class='light-font'>({{ 'siko.withoutVat' | cxTranslate }})</span></th>
            <th scope='col' class='text-left'>{{ 'sikoOrder.orderDetail.quantity' | cxTranslate }}</th>
            <th scope='col' class='text-left' *ngIf='!isOrder'>
              {{ 'siko.delivery' | cxTranslate }}
            </th>
            <th scope='col' class='text-right'>{{ 'siko.total' | cxTranslate }} <span
              class='light-font'>({{ 'siko.withoutVat' | cxTranslate }})</span></th>
            <th scope='col' *ngIf='!readonly'>
              <!--      empty slot for cart item actions (remove)-->
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor='let item of sikoItems; let i = index'>
            <td>
              <div class='d-flex align-items-center'>
                <div class='p-1 image'>
                  <a
                    [attr.data-testid]='$any("image-product-" + item.product?.code + "-detail-button")'
                    [title]='item.product?.name'
                    [routerLink]='item.product | sikoProductUrl'>
                    <app-media
                      class='cart-icon d-flex align-items-center justify-content-center'
                      [container]='$any(item.product?.images?.PRIMARY)'
                      format='cartIcon'
                    ></app-media>
                  </a>
                </div>
                <div class='p-1'>
                  <ng-container [ngTemplateOutlet]='catalogProductName'
                                [ngTemplateOutletContext]='{item: item.product}'
                  ></ng-container>
                  <div class='d-block product-code'>{{item.product?.code}}</div>
                </div>
              </div>
            </td>
            <td>
              <ng-container *ngIf='!arrayFreeGifts.includes(i)'>
                <app-price [price]='item.basePrice | sikoPrice'
                           [MOPrice]='promotionLocation.includes("SAVED_CART")'>
                </app-price>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf='!arrayFreeGifts.includes(i)'>
                <p *ngIf='item.product?.discountPercentString && !readonly'>
                  <span class='text-muted'>{{'siko.discount' | cxTranslate}}: </span>
                  <strong>
                    {{item.product?.discountPercentString | discountPercentString}}
                  </strong>
                </p>
                <span>{{item.price | sikoPrice}}</span>
              </ng-container>
            </td>
            <td>
              <ng-container
                *ngIf='getControl(item) | async as control'>
                <div *ngIf='!readonly else isReadonly'>
                  <app-cart-item-counter
                    [product]='item.product'
                    [displayRemoveIcon]='true'
                    [isLimited]='true'
                    [posName]='item.deliveryPointOfService?.displayName'
                    [index]='i'
                    [control]="$any(control.get('quantity'))"
                  ></app-cart-item-counter>
                </div>
              </ng-container>
              <ng-template #isReadonly>
                <span *ngIf='item.quantity && item.product?.unitData?.conversion'>
                  {{item.quantity * +item.product!.unitData!.conversion | cxNumeric: '1.0-3'}}&nbsp;
                <app-product-unit
                  [count]='item.quantity'
                  [productUnitData]='item.product?.unitData'
                  [inheritTextStyles]='true'
                ></app-product-unit>
                </span>
              </ng-template>
            </td>
            <td *ngIf='!isOrder'>
              <ng-container *ngIf='isPickUpInStore && item.deliveryPointOfService else deliveryToAddress'>
                <span class='d-block delivery-to-store'>{{'siko.pickUpInStore' | cxTranslate}}</span>
                <span class='d-block'>{{item.deliveryPointOfService.displayName}}</span>
              </ng-container>
              <ng-template #deliveryToAddress>
                {{'siko.deliveryToAddress' | cxTranslate}}
              </ng-template>
            </td>
            <ng-container *ngIf='item.entryNumber && arrayFreeGifts.includes(item.entryNumber) else totalPrice'>
              <td>
                <div class='gift-cart-item-info'>
                  <h4 class='siko-color-success-primary text-right mb-0 mt-1'>{{'siko.free' | cxTranslate}}</h4>
                  <div class='text-right' *ngIf='promotionLocation === "SAVED_CART"'>
                    {{getPromotionName(item.entryNumber)}}
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-template #totalPrice>
              <td class='text-right total-price'>
                {{item.totalPrice | sikoPrice}}
                <div class='bonus-group'>
                  <app-product-group *ngIf='!isOrder' [sikoProduct]='item.product'></app-product-group>
                </div>
              </td>
            </ng-template>
            <td
              *ngIf='!readonly && enableActions'
              (click)='onRemoveClick(item)'
            >
              {{ 'siko.removeOrderEntry' | cxTranslate }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #sapOrder>
    <div *ngIf='sikoBreakpointObserver.isBreakpointActive(["xs", "sm"])' class='pb-2 mt-5'>
      <div class='card' *ngFor='let item of sapOrderEntries; let i = index'>
        <div class='card-body'>
          <div *ngIf='sikoBreakpointObserver.isBreakpointActive(["xs"])' class='d-flex mb-2'>
            <ng-container
              [ngTemplateOutlet]='item.catalogProduct ? catalogProductImageContainer : sapProductImageContainer'
              [ngTemplateOutletContext]='{item: item}'
            ></ng-container>
            <div>
              <ng-container
                [ngTemplateOutlet]='item.catalogProduct ? catalogProductName : sapProductName'
                [ngTemplateOutletContext]='{item: item}'
              ></ng-container>
              <div class='product-code'>{{item.code}}</div>
            </div>
          </div>
          <ng-container *ngIf='sikoBreakpointObserver.isBreakpointActive(["xs"])'>
            <div>
              <span>{{ 'sikoOrder.sapOrderDetail.table.itemStatus' | cxTranslate }}</span>
              <span>
                <app-order-status-badge
                  mode='SAP_ORDER'
                  [status]='item?.statusDisplay'>
                {{"sikoOrder.sapOrderStatus.item." + item.statusDisplay | cxTranslate}}
              </app-order-status-badge>
              </span>
            </div>
            <div><span>{{'sikoOrder.sapOrderDetail.table.batch' | cxTranslate }}</span>:
              {{item.batch === '' ? '-' : item.batch}}
            </div>
            <div *ngIf='!arrayFreeGifts.includes(i)'><span>{{"siko.yourPrice" | cxTranslate}}
              :</span>{{isItemCancelled(item) ? '-' : item.priceTotal | sikoPrice}}</div>
            <div *ngIf='item.amount'><span>{{"sikoOrder.orderDetail.quantity" | cxTranslate}}:</span>
              {{isItemCancelled(item) ? '0' : item.amount * +item.unit!.conversion}}&nbsp;
              <app-product-unit
                [count]='item.amount'
                [productUnitData]='item.unit'
                [inheritTextStyles]='true'
              ></app-product-unit>
            </div>
            <div>
              <div class='total-price'>
                <div class='row'>
                  <div class='col-6'>
                    <ng-container *ngIf='arrayFreeGifts.includes(i) else totalPrice'>
                      <div class='gift-cart-item-info'>
                        <h4 class='siko-color-success-primary mb-0 mt-1'>{{'siko.free' | cxTranslate}}</h4>
                      </div>
                    </ng-container>
                    <ng-template #totalPrice>
                      {{isItemCancelled(item) ? '' : item.priceTotal | sikoPrice}}
                    </ng-template>
                  </div>
                  <div class='col-6 text-right'>
                    <span *ngIf='item.catalogProduct' [routerLink]='item | sikoProductUrl'>
                      <img [src]='"order_detail_vector.svg" | sikoIconAsset' alt='Navigate to product detail'>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf='sikoBreakpointObserver.isBreakpointActive(["sm"])'>
            <div class='row'>
              <div class='col-3'>
                <ng-container
                  [ngTemplateOutlet]='item.catalogProduct ? catalogProductImageContainer : sapProductImageContainer'
                  [ngTemplateOutletContext]='{item: item}'
                ></ng-container>
              </div>
              <div class='col-9 pl-5'>
                <ng-container
                  [ngTemplateOutlet]='item.catalogProduct ? catalogProductName : sapProductName'
                  [ngTemplateOutletContext]='{item: item}'
                ></ng-container>
                <div class='product-code'>{{item.code}}</div>
                <div>
                  <span>{{ 'sikoOrder.sapOrderDetail.table.itemStatus' | cxTranslate }}</span>
                  <span>
                    <app-order-status-badge
                      mode='SAP_ORDER'
                      [status]='item?.statusDisplay'>
                      {{"sikoOrder.sapOrderStatus.item." + item.statusDisplay | cxTranslate}}
                    </app-order-status-badge>
                  </span>
                </div>
                <div><span>{{'sikoOrder.sapOrderDetail.table.batch' | cxTranslate }}</span>:
                  {{item.batch === '' ? '-' : item.batch}}
                </div>
                <div *ngIf='!arrayFreeGifts.includes(i)'><span>{{"siko.yourPrice" | cxTranslate}}
                  :</span>{{isItemCancelled(item) ? '-' : item.priceNet | sikoPrice}}</div>
                <div *ngIf='item.amount'><span>{{"sikoOrder.orderDetail.quantity" | cxTranslate}}:</span>
                  {{isItemCancelled(item) ? '0' : item.amount * +item.unit!.conversion}}&nbsp;
                  <app-product-unit
                    [count]='item.amount'
                    [productUnitData]='item.unit'
                    [inheritTextStyles]='true'
                  ></app-product-unit>
                </div>
                <div>
                  <div class='total-price'>
                    <div class='row'>
                      <div class='col-6'>
                        <ng-container *ngIf='arrayFreeGifts.includes(i) else totalPrice'>
                          <div class='gift-cart-item-info'>
                            <h4 class='siko-color-success-primary mb-0 mt-1'>{{'siko.free' | cxTranslate}}</h4>
                          </div>
                        </ng-container>
                        <ng-template #totalPrice>
                          {{isItemCancelled(item) ? '' : item.priceTotal | sikoPrice}}
                        </ng-template>
                      </div>
                      <div class='col-6 text-right'>
                        <span *ngIf='item.catalogProduct' [routerLink]='item | sikoProductUrl'>
                          <img [src]='"order_detail_vector.svg" | sikoIconAsset' alt='Navigate to product detail'>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf='sikoBreakpointObserver.isBreakpointActive(["md", "lg", "xl"])'>
      <div class='table-responsive'>
        <table
          aria-label='Cart items list'
          class='table'>
          <thead>
          <tr>
            <th scope='col'>{{ 'siko.item' | cxTranslate }}</th>
            <th scope='col' class='text-left'>{{ 'sikoOrder.sapOrderDetail.table.itemStatus' | cxTranslate }}</th>
            <th scope='col' class='text-left'>{{'sikoOrder.sapOrderDetail.table.batch' | cxTranslate }}</th>
            <th scope='col' class='text-left'>{{ 'siko.yourPrice' | cxTranslate }} <span
              class='light-font'>({{ 'siko.withoutVat' | cxTranslate }})</span></th>
            <th scope='col' class='text-left'>{{ 'sikoOrder.orderDetail.quantity' | cxTranslate }}</th>
            <th scope='col' class='text-right'>{{ 'siko.total' | cxTranslate }} <span
              class='light-font'>({{ 'siko.withoutVat' | cxTranslate }})</span></th>
            <th scope='col' *ngIf='!readonly'>
              <!--      empty slot for cart item actions (remove)-->
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor='let item of sapOrderEntries; let i = index'>
            <td>
              <div class='d-flex align-items-center'>
                <ng-container
                  [ngTemplateOutlet]='item.catalogProduct ? catalogProductImageContainer : sapProductImageContainer'
                  [ngTemplateOutletContext]='{item: item}'
                ></ng-container>
                <div class='p-1'>
                  <ng-container
                    [ngTemplateOutlet]='item.catalogProduct ? catalogProductName : sapProductName'
                    [ngTemplateOutletContext]='{item: item}'
                  ></ng-container>
                  <div class='d-block product-code'>{{item.code}}</div>
                </div>
              </div>
            </td>
            <td>
              <app-order-status-badge
                mode='SAP_ORDER'
                [status]='item?.statusDisplay'>
                {{"sikoOrder.sapOrderStatus.item." + item.statusDisplay | cxTranslate}}
              </app-order-status-badge>
            </td>
            <td>
              {{item.batch}}
            </td>
            <td>
              <ng-container *ngIf='!arrayFreeGifts.includes(i)'>
                {{isItemCancelled(item) ? '-' : item.priceNet | sikoPrice}}
              </ng-container>
            </td>
            <td *ngIf='item.amount'>
              {{isItemCancelled(item) ? '0' : item.amount * +item.unit!.conversion}}&nbsp;
              <app-product-unit
                [count]='item.amount'
                [productUnitData]='item.unit'
                [inheritTextStyles]='true'
              ></app-product-unit>
            </td>
            <ng-container *ngIf='arrayFreeGifts.includes(i) else totalPrice'>
              <td>
                <div class='gift-cart-item-info'>
                  <h4 class='siko-color-success-primary text-right mb-0 mt-1'>{{'siko.free' | cxTranslate}}</h4>
                  <div class='text-right' *ngIf='promotionLocation === "SAVED_CART"'>
                    {{getPromotionName(i)}}
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-template #totalPrice>
              <td class='text-right total-price'>
                {{isItemCancelled(item) ? '-' : item.priceTotal | sikoPrice}}
              </td>
            </ng-template>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #imageContainer let-item='item'>
    <ng-container *ngIf='item.imageUrl else noImage'>
      <div class='image'>
        <img class='cart-icon' [src]='getItemImageUrl(item.imageUrl)' alt='Navigate to product detail'>
      </div>
    </ng-container>
    <ng-template #noImage>
      <div class='image'>
        <img class='cart-icon' src='../assets/imgNotFound.jpg' alt='Image not found icon'>
      </div>
    </ng-template>
  </ng-template>
  <ng-template #catalogProductImageContainer let-item='item'>
    <a [title]='item.name' [routerLink]='item | sikoProductUrl'>
      <ng-container [ngTemplateOutlet]='imageContainer' [ngTemplateOutletContext]='{item: item}'></ng-container>
    </a>
  </ng-template>
  <ng-template #sapProductImageContainer let-item='item'>
    <div>
      <ng-container [ngTemplateOutlet]='imageContainer' [ngTemplateOutletContext]='{item: item}'></ng-container>
    </div>
  </ng-template>
  <ng-template #catalogProductName let-item='item'>
    <a
      [attr.data-testid]='$any("product-" + item.product?.code + "-detail-button")'
      class='product-name'
      [routerLink]='item | sikoProductUrl'>
      {{item.name}}
    </a>
  </ng-template>
  <ng-template #sapProductName let-item='item'>
    <span class='product-name'>{{item.name}}</span>
  </ng-template>
</ng-container>
