<div class='container-fluid'>
  <ng-container *ngIf='product$ | async as sikoProduct else skeletonLoading'>
    <div class='row main-product-section'>
      <div class='col'>
        <cx-page-slot position='Summary'></cx-page-slot>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <cx-page-slot position='CrossSelling'></cx-page-slot>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <cx-page-slot position='Tabs' [isPageFold]='true'></cx-page-slot>
      </div>
    </div>
    <div
      scrolledToPdp #scrolledToElement='scrolledToPdp'
      id='js--similar-products-tab'
      class='row'
    >
      <div class='col'>
        <cx-page-slot position='UpSelling' class='btf-slot'></cx-page-slot>
      </div>
    </div>
  </ng-container>
  <ng-template #skeletonLoading>
    <div class='row'>
      <div class='col'>
        <app-skeleton skeletonType='pdp'></app-skeleton>
      </div>
    </div>
  </ng-template>
</div>
