import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SikoProduct } from '@siko/models';
import {
    ProductMobileTabMode,
    SikoProductTabsBottomSheetComponent,
} from '@siko/features/product/product-detail/components/tab-paragraph-container/product-tabs-bottom-sheet/product-tabs-bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CurrentProductService } from '@spartacus/storefront';
import { SikoRoutingService } from '../../../routing/siko-routing.service';
import { SikoDialogService } from '@siko/shared';

@Injectable({
    providedIn: 'root',
})
export class SikoPdpCommunicationService {

    addNewReviewSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly tabParagraphActiveNumSubject$ = new BehaviorSubject<number>(1);

    constructor(
        readonly dialogService: SikoDialogService,
        readonly routingService: SikoRoutingService,
        readonly bottomSheet: MatBottomSheet,
        readonly currentProductService: CurrentProductService,
    ) {
    }

    get tabParagraphActiveNum$(): Observable<number> {
        return this.tabParagraphActiveNumSubject$.asObservable();
    }

    get addNewReview$(): Observable<boolean> {
        return this.addNewReviewSubject$.asObservable();
    }

    setTabParagraphActiveNum(activeTabNum: number): void {
        this.tabParagraphActiveNumSubject$.next(activeTabNum);
    }

    setAddNewReview(addNewReview: boolean): void {
        this.addNewReviewSubject$.next(addNewReview);
    }

    handleScroll(elementId: string, mobileMode: ProductMobileTabMode, addNewReview = false): void {
        const headerOffset = 50;
        const el = document.getElementById(elementId);

        if (document.body.clientWidth >= 992) {
            const elementPosition = el?.getBoundingClientRect().top;

            if (elementPosition) {
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            }
        }
        else {
            this.openMobile(mobileMode);
        }
    }

    openMobile(mode: ProductMobileTabMode): void {
        if (mode !== 'UNDEFINED') {
            this.currentProductService.getProduct()
                .subscribe((product: SikoProduct | null) => {
                    this.bottomSheet.open(SikoProductTabsBottomSheetComponent, {
                        panelClass: 'siko-bottom-sheet',
                        data: {
                            product,
                            mode,
                        },
                    });
                })
                .unsubscribe();
        }
    }

    scrollToElement(
        elementId: string,
        activeTabNum: number,
        mobileMode: ProductMobileTabMode,
        product: SikoProduct | null = null,
        scrollNeedsRedirect = false,
        addNewReview = false,
    ): void {

        if (addNewReview) {
            this.setAddNewReview(true);
        }
        else {
            this.setAddNewReview(false);
        }

        this.setTabParagraphActiveNum(activeTabNum);
        this.dialogService.dismissAll('scrolling to element');

        if (scrollNeedsRedirect && product) {
            void this.routingService.goToProduct(product)
                .then(() => {
                    setTimeout(() => {
                        this.handleScroll(elementId, mobileMode, addNewReview);
                    }, 100);
                });
        }
        else {
            this.handleScroll(elementId, mobileMode, addNewReview);
        }
    }

}
