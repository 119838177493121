import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {
    SIKO_SELECTED_DELIVERY_MODE_ENUM,
    SIKO_SHIPMENT,
    SikoCartModification,
    SikoOrder,
    SikoSapOrder,
} from '@siko/models';
import { SikoOrderDocumentsService } from '@siko/shared';
import { SikoOrderOverviewService } from '@siko/features/order-overview/occ/services/order-overview.service';
import { Observable } from 'rxjs';
import { GlobalMessageType } from '@spartacus/core';
import { filter } from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-order-overview',
    templateUrl: './order-overview.component.html',
    styleUrls: ['./order-overview.component.scss'],
})
export class SikoOrderOverviewComponent implements OnDestroy {

    @Input() order?: SikoOrder;
    @Input() sikoSapOrder?: SikoSapOrder;
    @Input() displaySapOrderAdditionalPaymentComponent = false;
    @Input() orderConfirmation = false;
    @Output() readonly reorderClick = new EventEmitter<MouseEvent>();
    globalMessageType = GlobalMessageType;

    constructor(
        readonly sikoOrderDocumentsService: SikoOrderDocumentsService,
        readonly sikoOrderOverviewService: SikoOrderOverviewService,
    ) {}

    get cartModificationPayment$(): Observable<boolean> {
        return this.sikoOrderOverviewService.isReordering$.pipe(
            filter((isReordering: boolean) => isReordering),
        );
    }

    get cartModificationHistory$(): Observable<SikoCartModification[]> {
        return this.sikoOrderOverviewService.cartModificationHistory$;
    }

    get displayReorderButton(): boolean {
        const order: SikoOrder | undefined = this.order !== undefined ? this.order : this.sikoSapOrder?.order;

        return order !== undefined && order.statusDisplay !== 'cancelled'
            && (!this.sikoSapOrder || this.sikoSapOrder.orderStatus !== 'cancelled');
    }

    get isOrderWithShipment(): boolean {
        if (this.sikoSapOrder) {
            return this.sikoSapOrder.deliveryType?.split('.')
                ?.pop() === SIKO_SHIPMENT;
        }

        return this.order?.deliveryMode?.code === SIKO_SELECTED_DELIVERY_MODE_ENUM.ZAV_NET;
    }

    clearWarnings(): void {
        this.sikoOrderOverviewService.clearCartModificationHistory();
    }

    ngOnDestroy(): void {
        this.sikoOrderOverviewService.clearCartModificationHistory();
    }

}
