import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SikoPageSlotModule } from '@siko/features/shared-components/page-slot/page-slot.module';
import { ProductDetailPageModule } from '@siko/features/product/product-detail/product-detail-page.module';

@Component({
    selector: 'app-configuration-template',
    standalone: true,
    imports: [CommonModule, SikoPageSlotModule, ProductDetailPageModule],
    templateUrl: './configuration-template.component.html',
    styleUrls: ['./configuration-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoConfigurationTemplateComponent {

}
