<ng-container *ngIf='breakpointObserver.breakpointChange$ | async'>
  <ng-container *ngIf='breakpointObserver.isBreakpointActive(["xs"]) else tabletOrDesktop'>
    <svg width='100%' height='100%' viewBox='0 0 360 334' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M24 8C24 3.58172 27.5817 0 32 0H360V104H32C27.5817 104 24 100.418 24 96V8Z' fill='#F0F4F4' />
      <g clip-path='url(#clip0_21015_563733)'>
        <rect x='32' y='8' width='56' height='56' rx='8' fill='white' />
      </g>
      <rect x='96' y='8' width='240' height='24' rx='8' fill='white' />
      <rect x='96' y='40' width='135' height='24' rx='8' fill='white' />
      <rect x='252' y='72' width='84' height='24' rx='8' fill='white' />
      <rect x='24' y='120' width='312' height='102' rx='8' fill='#F0F4F4' />
      <rect x='24' y='238' width='312' height='40' rx='8' fill='#F0F4F4' />
      <rect x='24' y='294' width='312' height='40' rx='8' fill='#F0F4F4' />
      <defs>
        <clipPath id='clip0_21015_563733'>
          <rect x='32' y='8' width='56' height='56' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </ng-container>
  <ng-template #tabletOrDesktop>
    <ng-container *ngIf='breakpointObserver.isBreakpointActive(["sm", "md"]) else desktop'>
      <svg width='100%' viewBox='0 0 624 464' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_21015_563692)'>
          <rect width='80' height='80' rx='8' fill='#F0F4F4' />
        </g>
        <rect x='88' width='536' height='24' rx='8' fill='#F0F4F4' />
        <rect x='88' y='32' width='135' height='24' rx='8' fill='#F0F4F4' />
        <rect x='540' y='64' width='84' height='24' rx='8' fill='#F0F4F4' />
        <rect y='120' width='624' height='272' rx='8' fill='#F0F4F4' />
        <g clip-path='url(#clip1_21015_563692)'>
          <rect y='424' width='198' height='40' rx='4' fill='#F0F4F4' />
        </g>
        <rect x='214' y='424' width='410' height='40' rx='4' fill='#F0F4F4' />
        <defs>
          <clipPath id='clip0_21015_563692'>
            <rect width='80' height='80' rx='8' fill='white' />
          </clipPath>
          <clipPath id='clip1_21015_563692'>
            <rect y='424' width='198' height='40' rx='4' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </ng-container>
    <ng-template #desktop>
      <svg width='100%' viewBox='0 0 946 464' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_21015_563666)'>
          <rect width='80' height='80' rx='8' fill='#F0F4F4' />
        </g>
        <rect x='88' width='858' height='24' rx='8' fill='#F0F4F4' />
        <rect x='88' y='32' width='135' height='24' rx='8' fill='#F0F4F4' />
        <rect x='862' y='64' width='84' height='24' rx='8' fill='#F0F4F4' />
        <rect y='120' width='946' height='272' rx='8' fill='#F0F4F4' />
        <g clip-path='url(#clip1_21015_563666)'>
          <rect y='424' width='198' height='40' rx='4' fill='#F0F4F4' />
        </g>
        <rect x='214' y='424' width='732' height='40' rx='4' fill='#F0F4F4' />
        <defs>
          <clipPath id='clip0_21015_563666'>
            <rect width='80' height='80' rx='8' fill='white' />
          </clipPath>
          <clipPath id='clip1_21015_563666'>
            <rect y='424' width='198' height='40' rx='4' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </ng-template>
  </ng-template>
</ng-container>
