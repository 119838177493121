import { StateUtils } from '@spartacus/core';
import { SIKO_SAP_ORDER_DATA } from '@siko/features/my-account/sap-order-detail/store/reducers';
import { LoadSapOrderDocumentsResponse, SikoSapOrder } from '@siko/models';

export const LOAD_SAP_ORDER = '[Sap-order] Load Sap Order';
export const LOAD_SAP_ORDER_FAIL = '[Sap-order] Load Sap Order Fail';
export const LOAD_SAP_ORDER_SUCCESS = '[Sap-order] Load Sap Order Success';

export const LOAD_SAP_ORDER_DOCUMENTS = '[Sap-order] Load Sap Order Documents';
export const LOAD_SAP_ORDER_DOCUMENTS_FAIL = '[Sap-order] Load Sap Order Documents Fail';
export const LOAD_SAP_ORDER_DOCUMENTS_SUCCESS = '[Sap-order] Load Sap Order Documents Success';

export const LOAD_ORDER_DOCUMENTS = '[Order] Load Order Documents';
export const LOAD_ORDER_DOCUMENTS_FAIL = '[Order] Load Order Documents Fail';
export const LOAD_ORDER_DOCUMENTS_SUCCESS = '[Order] Load Order Documents Success';

export class LoadSapOrder extends StateUtils.LoaderLoadAction {
    readonly type = LOAD_SAP_ORDER;

    constructor(public payload: { currentUserId: string; sapOrderId: string }) {
        super(SIKO_SAP_ORDER_DATA);
    }
}

export class LoadSapOrderFail extends StateUtils.LoaderFailAction {
    readonly type = LOAD_SAP_ORDER_FAIL;

    constructor(public payload: any) {
        super(SIKO_SAP_ORDER_DATA, payload);
    }
}

export class LoadSapOrderSuccess extends StateUtils.LoaderSuccessAction {
    readonly type = LOAD_SAP_ORDER_SUCCESS;

    constructor(public payload: SikoSapOrder) {
        super(SIKO_SAP_ORDER_DATA);
    }
}

export class LoadSapOrderDocuments extends StateUtils.LoaderLoadAction {
    readonly type = LOAD_SAP_ORDER_DOCUMENTS;

    constructor(public payload: { currentUserId: string; orderCode: string }) {
        super(SIKO_SAP_ORDER_DATA);
    }
}

export class LoadSapOrderDocumentsFail extends StateUtils.LoaderFailAction {
    readonly type = LOAD_SAP_ORDER_DOCUMENTS_FAIL;

    constructor(public payload: any) {
        super(SIKO_SAP_ORDER_DATA, payload);
    }
}

export class LoadSapOrderDocumentsSuccess extends StateUtils.LoaderSuccessAction {
    readonly type = LOAD_SAP_ORDER_DOCUMENTS_SUCCESS;

    constructor(public payload: LoadSapOrderDocumentsResponse) {
        super(SIKO_SAP_ORDER_DATA);
    }
}

export class LoadOrderDocuments extends StateUtils.LoaderLoadAction {
    readonly type = LOAD_ORDER_DOCUMENTS;

    constructor(public payload: { currentUserId: string; orderCode: string }) {
        super(SIKO_SAP_ORDER_DATA);
    }
}

export class LoadOrderDocumentsSuccess extends StateUtils.LoaderSuccessAction {
    readonly type = LOAD_ORDER_DOCUMENTS_SUCCESS;

    constructor(public payload: LoadSapOrderDocumentsResponse) {
        super(SIKO_SAP_ORDER_DATA);
    }
}

export class LoadOrderDocumentsFail extends StateUtils.LoaderFailAction {
    readonly type = LOAD_ORDER_DOCUMENTS_FAIL;

    constructor(public payload: any) {
        super(SIKO_SAP_ORDER_DATA, payload);
    }
}

export type SikoSapOrderAction =
    | LoadSapOrder
    | LoadSapOrderDocuments
    | LoadSapOrderDocumentsFail
    | LoadSapOrderDocumentsSuccess
    | LoadSapOrderFail
    | LoadSapOrderSuccess;


