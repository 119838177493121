import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SikoPageSlotComponent } from '@siko/features/shared-components/page-slot/page-slot.component';
import { OutletModule, PageComponentModule } from '@spartacus/storefront';

@NgModule({
    imports: [CommonModule, OutletModule, PageComponentModule],
    declarations: [SikoPageSlotComponent],
    exports: [SikoPageSlotComponent],
})
export class SikoPageSlotModule {}
