import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SikoOpeningSchedule } from '@siko/models';
import { SikoDialogService } from '@siko/shared';

export interface SikoOpeningHoursDialog {
    openingHours?: SikoOpeningSchedule;
    pointOfServiceName?: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-opening-hours-dialog',
    templateUrl: './opening-hours-dialog.component.html',
    styleUrls: ['./opening-hours-dialog.component.scss'],
})
export class SikoOpeningHoursDialogComponent {

    @Input() openingHours?: SikoOpeningSchedule;
    @Input() pointOfServiceName?: string;

    constructor(
        public dialogService: SikoDialogService,
    ) {}

}
