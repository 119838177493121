import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsmMainUiComponent } from '@spartacus/asm/components';

@Component({
    selector: 'cx-asm-main-ui',
    templateUrl: './asm-main-ui.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoAsmMainUiComponent extends AsmMainUiComponent {

}
