import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import {
    AuthGuard, OccEndpointsService,
    ProtectedRoutesGuard,
    ProtectedRoutesService,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SikoPublicUrlsService } from '../routing/siko-public-urls.service';

@Injectable({ providedIn: 'root' })
export class SikoProtectedRoutesGuard extends ProtectedRoutesGuard {
    constructor(
        protected service: ProtectedRoutesService,
        protected authGuard: AuthGuard,
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected sikoPublicUrlsService: SikoPublicUrlsService,
    ) {
        super(service, authGuard);
    }

    /**
     * When the anticipated url is protected, it switches to the AuthGuard. Otherwise emits true.
     */
    canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
        let urlSegments: string[] = route.url.map((seg) => seg.path);

        urlSegments = urlSegments.length ? urlSegments : [''];

        const [currentUrl] = urlSegments;

        return this.sikoPublicUrlsService.getPublicUrls().pipe(
            switchMap((publicUrls: string[]) => this.processUrls(publicUrls, currentUrl, urlSegments))
        );
    }

    processUrls(publicUrls: string[], currentUrl: string, urlSegments: string[]): Observable<UrlTree | boolean> {
        if (publicUrls.includes(currentUrl) && currentUrl) {
            return of(true);
        }
        else if (this.service.isUrlProtected(urlSegments)) {
            return this.authGuard.canActivate().pipe(
                map((result: UrlTree | boolean): UrlTree | boolean => result)
            );
        }

        return of(true);
    }

}
