import { AfterViewChecked, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { SikoHelperFunctionsService } from '@siko/shared';
import { SikoMediaUrlResolverService } from '@siko/common';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-information',
    styleUrls: ['./product-information.component.scss'],
    templateUrl: './product-information.component.html',
})
export class SikoProductInformationComponent implements AfterViewChecked {
    @Input() displayHeader = true;

    product$: Observable<Product | null> = this.currentProductService.getProduct();

    constructor(
        private readonly helpers: SikoHelperFunctionsService,
        private readonly currentProductService: CurrentProductService,
        private readonly sikoMediaUrlResolverService: SikoMediaUrlResolverService,
    ) {
    }

    ngAfterViewChecked(): void {
        this.initImages();
    }

    private initImages(): void {
        const images = document.querySelectorAll('.product-description-tab img') as unknown as HTMLImageElement[] | null;
        if (images) {
            images?.forEach((image: HTMLImageElement) => {
                const imageSrc = image.getAttribute('src');
                if (imageSrc?.startsWith('/medias')) {
                    image.src = this.sikoMediaUrlResolverService.getMediaUrl(imageSrc);
                }
            });
        }
    }


}
