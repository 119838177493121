import { NgModule } from '@angular/core';
import { B2bCommonModule, SikoProductUrlPipe } from '@siko/shared';
import { SikoCartItemListComponent } from '@siko/features/shared-components/cart-item-list/cart-item-list.component';
import { ConfigModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { SikoItemCounterModule } from '@siko/features/shared-components/item-counter/item-counter.module';
import { SikoOrderStatusBadgeModule } from '@siko/features/shared-components/order-status-badge/order-status-badge.module';
import { SikoMediaComponent } from '@siko/features/shared-components/media/media.component';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';
import { SikoProductGroupComponent } from '@siko/features/shared-components/product-group/product-group.component';
import { SikoPriceComponent } from '@siko/features/shared-components/price/price.component';

@NgModule({
    declarations: [
        SikoCartItemListComponent,
    ],
    imports: [
        ConfigModule.withConfig({
            cmsComponents: {
                CartItemListComponent: {
                    component: SikoCartItemListComponent,
                },
            },
        }),
        B2bCommonModule,
        UrlModule,
        RouterModule,
        SikoMediaComponent,
        SikoProductUnitComponent,
        SikoItemCounterModule,
        SikoOrderStatusBadgeModule,
        SikoProductGroupComponent,
        SikoProductUrlPipe,
        SikoPriceComponent,
    ],
    exports: [
        SikoCartItemListComponent,
    ],
})
export class SikoCartItemListModule {}
