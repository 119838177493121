<ng-container *ngIf='counter | async as counterItems'>
  <ng-container *ngIf='counterItems > 0'>
    <ng-container *ngIf='type?.includes("grey")'>
      <div class='wrapper-box d-flex flex-row align-items-stretch'>
        <div class='icon-wrapper d-flex align-items-stretch'>
          <img [src]='"header/mini-cart-counter.svg" | sikoIconAsset' alt='Mini cart counter'>
        </div>
        <div class='counter d-flex flex-row justify-content-center align-items-center'>
          <span class='count'>
            {{ 'miniCart.count' | cxTranslate: { count: checkCounterValue(counterItems) } }}
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf='type?.includes("yellow")'>
      <div class='wrapper-news-bubble d-flex flex-row align-items-stretch'>
        <div class='icon-wrapper d-flex align-items-stretch'>
          <img class='icon-counter' [src]='"header/news-counter.svg" | sikoIconAsset' alt='News counter icon'>
        </div>
        <div class='counter d-flex flex-row justify-content-center align-items-center'>
          <span class='count'>
            {{ 'miniCart.count' | cxTranslate: { count: checkCounterValue(counterItems) } }}
          </span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
