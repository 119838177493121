import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { SikoTrackingUtils } from '@siko/shared/utils/siko-tracking-utils';
import { IN_OUT_ANIMATION, SikoAutoUnsubscribe } from '@siko/common';
import { distinctUntilKeyChanged, filter } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { ProductActions } from '@spartacus/core';
import { SearchProducts } from '@spartacus/core/src/product/store/actions/product-search.action';
import { SikoB2bPlpService } from '@siko/features/product/product-listing/siko-b2b-plp.service';
import { B2bCommonModule } from '@siko/shared';
import { SikoPageSlotModule } from '@siko/features/shared-components/page-slot/page-slot.module';
import { SikoSkeletonComponent } from '@siko/features/shared-components/skeleton/skeleton.component';
import { SikoProductListComponentService } from '@siko/features/product/product-listing/product-list/service/siko-product-list-component.service';


@SikoAutoUnsubscribe([
    'scrollerSubscription',
    'modelSubscription',
    'searchProductsSubscription',
    'searchProductsFinishedSubscription',
])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-siko-search-outlet',
    templateUrl: './siko-product-grid-outlet.component.html',
    styleUrls: ['./siko-product-grid-outlet.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [IN_OUT_ANIMATION],
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoPageSlotModule,
        SikoSkeletonComponent,
    ],
})
export class SikoProductGridOutletComponent implements OnInit {

    @ViewChild('topPage') el?: ElementRef;

    scrollerSubscription?: Subscription;
    modelSubscription?: Subscription;
    searchProductsSubscription?: Subscription;
    searchProductsFinishedSubscription?: Subscription;

    private readonly isScrollingUp$ = new BehaviorSubject(false);

    get isScrollingUp(): Observable<boolean> {
        return this.isScrollingUp$.asObservable();
    }

    constructor(
        public readonly sikoPlpService: SikoB2bPlpService,
        private readonly actions: Actions,
        private readonly trackingUtils: SikoTrackingUtils,
        private readonly productListComponentService: SikoProductListComponentService,
    ) { }


    ngOnInit(): void {
        this.isScrollingUp$.next(false);

        this.scrollerSubscription = fromEvent(window, 'scroll')
            .pipe()
            .subscribe(() => {
                this.dealWithScroll(window.scrollY);
            });

        this.modelSubscription = this.productListComponentService.model$
            .pipe(
                filter(data => Boolean(data.categoryCode)),
                distinctUntilKeyChanged('categoryCode'),
            )
            .subscribe(data => {
                this.trackingUtils.pushCategoryViewEvent(data);
            });

        this.searchProductsSubscription = this.actions.pipe(ofType(ProductActions.SEARCH_PRODUCTS))
            .pipe(distinctUntilKeyChanged('payload.queryText'))
            .subscribe((data: SearchProducts) => {
                if (data.auxiliary === undefined) {
                    this.sikoPlpService.setLoadingSubject(true);
                }
            });

        this.searchProductsFinishedSubscription = this.actions.pipe(ofType(ProductActions.SEARCH_PRODUCTS_SUCCESS, ProductActions.SEARCH_PRODUCTS_FAIL))
            .subscribe(() => {
                this.sikoPlpService.setLoadingSubject(false);
            });
    }

    dealWithScroll(y: number): void {
        this.isScrollingUp$.next(y > 500);
    }

    scrollToProducts(): void {
        this.el?.nativeElement.scrollIntoView();
    }

}
