import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlTree } from '@angular/router';
import {
    AuthService,
    CmsActivatedRouteSnapshot,
    CmsService,
    GlobalMessageService,
    GlobalMessageType,
    isNotNullable,
    isNotUndefined,
    Page,
    PageContext,
    ProtectedRoutesGuard,
    RouteLoadStrategy,
    RoutingConfigService,
    RoutingService,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { CmsPageGuard, CmsPageGuardService } from '@spartacus/storefront';
import { Title } from '@angular/platform-browser';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SikoCmsPageGuard extends CmsPageGuard {

    constructor(
        private readonly globalMessageService: GlobalMessageService,
        protected routingService: RoutingService,
        protected cmsService: CmsService,
        protected protectedRoutesGuard: ProtectedRoutesGuard,
        protected service: CmsPageGuardService,
        protected routingConfig: RoutingConfigService,
        private readonly title: Title,
        private readonly authService: AuthService,
    ) {
        super(routingService, cmsService, protectedRoutesGuard, service, routingConfig);
    }

    canActivate(
        route: CmsActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<UrlTree | boolean> {
        this.authService.isUserLoggedIn()
            .pipe(
                take(1),
                tap((isLoggedIn: boolean) => {
                    if (!isLoggedIn) {

                        if (state.url.includes('coupon/claim')) {
                            this.globalMessageService.add({
                                key: 'httpHandlers.loginRequire',
                            }, GlobalMessageType.MSG_TYPE_WARNING);
                        }

                        this.title.setTitle('');
                        this.cmsService.getCurrentPage()
                            .pipe(
                                filter(isNotNullable),
                                take(1),
                                map((page: Page) => {
                                    if (page.title) {
                                        this.title.setTitle(page.title + ' | SIKO B2B');
                                    }
                                }),
                            )
                            .subscribe();
                    }
                }),
            )
            .subscribe();

        if (state.url === '/logout') {
            return of(false);
        }

        return this.protectedRoutesGuard.canActivate(route)
            .pipe(
                switchMap((canActivate) =>
                    canActivate === true
                        ? this.routingService.getNextPageContext()
                            .pipe(
                                filter(isNotUndefined),
                                take(1),
                                switchMap((pageContext: PageContext) => {
                                    const modifiedPageContext: PageContext = {
                                        id: pageContext.id.toUpperCase(),
                                        type: pageContext.type,
                                    };
                                    const isProductPage = pageContext.type === 'ProductPage';
                                    return this.cmsService.getPage(isProductPage ? modifiedPageContext : pageContext, this.sikoShouldReload())
                                        .pipe(
                                            first(),
                                            switchMap((pageData) =>
                                                pageData
                                                    ? this.service.canActivatePage(
                                                        isProductPage ? modifiedPageContext : pageContext,
                                                        pageData,
                                                        route,
                                                        state,
                                                    )
                                                    : this.service.canActivateNotFoundPage(
                                                        isProductPage ? modifiedPageContext : pageContext,
                                                        route,
                                                        state,
                                                    ),
                                            ),
                                        );
                                }),
                            )
                        : of(canActivate),
                ),
            );
    }

    private sikoShouldReload(): boolean {
        return this.routingConfig.getLoadStrategy() !== RouteLoadStrategy.ONCE;
    }

}
