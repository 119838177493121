import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SiteContextConfig } from '@spartacus/core';
import { SikoUtils } from '@siko/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-checkout-contacts',
    templateUrl: './checkout-contacts.component.html',
    styleUrls: ['./checkout-contacts.component.scss']
})
export class CheckoutContactsComponent implements OnInit {

    baseSite?: string;

    ngOnInit(): void {
        const siteContext: SiteContextConfig | undefined = SikoUtils.getSiteContext(false);
        const baseSite = siteContext ? siteContext.context?.baseSite : '';

        this.baseSite = baseSite && baseSite.length > 0 ? baseSite[0].toString().replace(/-/g,  '') : '';
    }

}
