import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AuthService } from '@spartacus/core';
import { Sikob2bHeaderCommunicationService } from '@siko/features/header/Sikob2bHeaderCommunication.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SikoAutoUnsubscribe } from '@siko/common';
import { SikoHeaderService } from '@siko/features/header/siko-header.service';

@SikoAutoUnsubscribe(['scrollerSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-header-outlet',
    templateUrl: './header-outlet.component.html',
    styleUrls: ['./header-outlet.component.scss'],
})
export class SikoHeaderOutletComponent implements OnInit {

    @ViewChild('stickyMenuRef') el?: ElementRef;
    @ViewChild('topNav') topNav?: ElementRef;
    @ViewChild('topHeaderSlot') topHeaderSlot?: ElementRef;

    isLoggedIn$ = this.auth.isUserLoggedIn();
    scrollerSubscription?: Subscription;
    isDesktop$ = this.breakpointObserver.observe(['(min-width: 1301px)']);
    isTablet$ = this.breakpointObserver.observe(['(min-width: 744px)']);
    isMobile$ = this.breakpointObserver.observe(['(max-width: 743.98px)']);
    isCheckoutPage$: Observable<boolean> = this.sikoHeaderService.isCheckoutPage$;

    constructor(
        private readonly headerCommunicationService: Sikob2bHeaderCommunicationService,
        private readonly auth: AuthService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly sikoHeaderService: SikoHeaderService,
    ) {
    }

    ngOnInit(): void {
        this.scrollerSubscription = fromEvent(window, 'scroll')
            .pipe()
            .subscribe(() => {
                this.makeStickyMenu(window.pageYOffset);
            });
    }

    makeStickyMenu(y: number): void {
        const offsetTop = this.el?.nativeElement.offsetTop;

        if (y >= offsetTop + 1) {
            this.topNav?.nativeElement.classList.add('hide-default-menu');
            this.topHeaderSlot?.nativeElement.classList.add('hide-default-menu');
            this.headerCommunicationService.setIsShowStickyMenu(true);
        }
        else {
            this.topNav?.nativeElement.classList.remove('hide-default-menu');
            this.topHeaderSlot?.nativeElement.classList.remove('hide-default-menu');
            this.headerCommunicationService.setIsShowStickyMenu(false);
        }
    }

}
