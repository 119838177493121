import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-dialog-close-icon-svg',
    template: `
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M22.5 1.5L12 12L22.5 22.5' stroke='#193137' stroke-width='2' />
            <path d='M1.5 22.5L12 12L1.5 1.5' stroke='#193137' stroke-width='2' />
            <path d='M22.5 1.5L14.1213 9.87868C12.9497 11.0503 12.9497 12.9497 14.1213 14.1213L22.5 22.5'
                  stroke='#193137' stroke-width='2' />
            <path d='M1.5 22.5L9.87868 14.1213C11.0503 12.9497 11.0503 11.0503 9.87868 9.87868L1.5 1.5' stroke='#193137'
                  stroke-width='2' />
            <path d='M1.5 1.5L9.87868 9.87868C11.0503 11.0503 12.9497 11.0503 14.1213 9.87868L22.5 1.5' stroke='#193137'
                  stroke-width='2' />
            <path d='M22.5 22.5L14.1213 14.1213C12.9497 12.9497 11.0503 12.9497 9.87868 14.1213L1.5 22.5'
                  stroke='#193137' stroke-width='2' />
        </svg>
    `,
    styleUrls: ['./dialog-close-icon-svg.component.scss'],
})
export class SikoDialogCloseIconSvgComponent {

}
