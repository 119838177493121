import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    isNotNullable,
    RoutingService,
    SiteContextSelectors,
    StateWithSiteContext,
    TranslationService,
    UserIdService,
} from '@spartacus/core';
import { MultiCartStatePersistenceService } from '@spartacus/cart/base/core';
import { OrderActions, OrderHistoryService, StateWithOrder } from '@spartacus/order/core';
import { OccOrderAdapter } from '@spartacus/order/occ';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { SikoB2BUser, SikoOrder, SikoPromotionResult } from '@siko/models';
import { SikoAutoUnsubscribe } from '@siko/common';
import { Actions, ofType } from '@ngrx/effects';
import { B2bCommonModule } from '@siko/shared';
import { SpinnerModule } from '@spartacus/storefront';
import { SikoOrderDetailTotalsModule } from '@siko/features/shared-components/order-details-total/order-detial-totals.module';
import { SikoOrderDetailsItemsModule } from '@siko/features/shared-components/order-details-items/order-details-items.module';
import { SikoCouponCardComponent } from '@siko/features/shared-components/coupon-card/coupon-card.component';
import { SikoOrderConfirmationModule } from '@siko/features/checkout/order-confirmation/order-confirmation.module';

@SikoAutoUnsubscribe([
    'loadOrderFailSubscription',
    'languageSubscription'
])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-order-confirmation-outlet',
    templateUrl: './order-confirmation-outlet.component.html',
    standalone: true,
    providers: [
        OrderHistoryService,
    ],
    imports: [
        B2bCommonModule,
        SikoOrderDetailTotalsModule,
        SikoOrderConfirmationModule,
        SikoOrderDetailsItemsModule,
        SpinnerModule,
        SikoCouponCardComponent
    ],

})
export class SikoOrderConfirmationOutletComponent implements OnInit {

    paymentGatewayPaymentStatus?: string | null;
    destroy$ = new Subject<void>();
    loadOrderFailSubscription?: Subscription;
    hasGiveawayCoupons = false;

    sikoUser$: Observable<SikoB2BUser> = this.userProfileFacade.get()
        .pipe(
            filter(isNotNullable),
            takeUntil(this.destroy$),
        );

    order$: Observable<SikoOrder> = this.orderHistoryService.getOrderDetails()
        .pipe(
            filter(isNotNullable),
            tap((order: SikoOrder) => {
                this.hasGiveawayCoupons = this.checkIfHasGiveawayCoupons(order);
            })
        );

    languageChange$: Observable<string | null> = this.siteContextStore.pipe(
        select(SiteContextSelectors.getActiveLanguage),
    );

    languageSubscription: Subscription = this.languageChange$.pipe(
        distinctUntilChanged(),
    ).subscribe(() => {
        const orderCode = this.activatedRoute.snapshot.paramMap.get('orderCode');
        if (orderCode) {
            this.orderHistoryService.loadOrderDetails(orderCode);
        }
    });

    constructor(
        private readonly siteContextStore: Store<StateWithSiteContext>,
        private readonly multiCartStatePersistenceService: MultiCartStatePersistenceService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly occOrderAdapter: OccOrderAdapter,
        private readonly routingService: RoutingService,
        private readonly userIdService: UserIdService,
        private readonly store: Store<StateWithOrder>,
        private readonly userProfileFacade: UserProfileFacade,
        private readonly orderHistoryService: OrderHistoryService,
        private readonly actions: Actions,
        public translation: TranslationService,
    ) {
    }

    ngOnInit(): void {
        this.loadOrderFailSubscription = this.actions.pipe(
            ofType(OrderActions.LOAD_ORDER_DETAILS_FAIL),
        )
            .subscribe(() => {
                this.routingService.goByUrl('/');
            });

        this.multiCartStatePersistenceService.initSync();
        this.paymentGatewayPaymentStatus = this.activatedRoute.snapshot.queryParamMap.get('status');

    }

    private checkIfHasGiveawayCoupons(sikoOrder: SikoOrder): boolean {
        return sikoOrder.appliedOrderPromotions?.find((i: SikoPromotionResult) => i.giveAwayCouponCodes && i.giveAwayCouponCodes.length > 0) !== undefined;
    }

}
