import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SikoExponeaService {

    identify(uid: string | undefined): void {
        if (!uid) {
            return;
        }

        const email = uid.startsWith('b2b|') ? uid.substring(4) : uid;

        if (typeof exponea !== 'undefined') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            exponea.identify(email);
        }
    }

}
