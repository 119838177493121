<div class='flex-row-item'>
  <div
    class='wrapper'
    [routerLink]='localizedUrl'
   >
    <div class='image'>
      <img
        [src]="imageUrl === '' ? $any('./assets/imgNotFound.jpg') : imageUrl"
        [alt]='imageAlt'
      />
    </div>
    <div class='content-wrapper d-flex justify-content-between align-items-center py-4'>
      <h4 class='title pl-4' [innerHTML]='linkName'></h4>
      <img class='icon' [src]='"header/arrow.svg" | sikoIconAsset' alt='Arrow icon'>
    </div>
  </div>
</div>
