import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '@spartacus/core';
import { SikoProductUrlPipe } from '@siko/shared/pipes/product-url.pipe';

@Injectable({
    providedIn: 'root',
})
export class SikoRoutingService {

    constructor(
        protected router: Router,
        protected productUrlPipe: SikoProductUrlPipe,
    ) { }

    async goToProduct(product: Product): Promise<boolean> {
        return this.router.navigate(this.productUrlPipe.transform(product));
    }

}
