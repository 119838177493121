<ng-container *ngIf='sapOrder$ | async as sapOrder'>

  <ng-container *ngIf='sapOrder.orderStatus !== "completed" && sapOrder.orderStatus !== "cancelled" && sapOrder.orderStatus !== "error"'>
    <div class='mb-32'>
      <span class='label'>{{ 'sikoOrder.sapOrderAdditionalPayment.status' | cxTranslate }}</span>
      <span
        [ngClass]='getPaymentStatusClass(sapOrderPaymentStatus)'
        class='d-block'>{{'sikoOrder.sapOrderAdditionalPayment.' + sapOrderPaymentStatus | cxTranslate}}</span>
    </div>
  </ng-container>

  <ng-container *ngIf='displayPaymentButton(sapOrder)'>
    <div class='mb-32'>
      <span class='label'>{{ 'sikoOrder.sapOrderAdditionalPayment.unpaidAmount' | cxTranslate }}</span>
      <span class='d-block'>{{sapOrder.depositDiff | sikoPrice}}</span>
    </div>
  </ng-container>

  <ng-container *ngIf='displayPaymentButton(sapOrder)'>

    <div>
      <ng-container *ngIf='sapOrder.sapOrderPaymentStatus as status'>
        <div class='my-16'>
          <app-info-banner-component
            *ngIf='!displayCheckPaymentButton(sapOrder)'
            text="{{'sikoOrder.sapOrderAdditionalPayment.button.'+sapOrder.sapOrderPaymentStatus  | cxTranslate}}"
            [mode]='getInfoBannerMode(sapOrder.sapOrderPaymentStatus)'
          >
          </app-info-banner-component>
          <siko-button
            *ngIf='displayCheckPaymentButton(sapOrder) && !!sapOrder.sapOrderPaymentTransactionId'
            mode='primary'
            iconCode='open_in_new'
            iconPosition='after'
            size='md'
            text='{{ "sikoOrder.sapOrderAdditionalPayment.button.checkPayment" | cxTranslate }}'
            [redirectUrl]='"https://payments.comgate.cz/status/" + sapOrder.sapOrderPaymentTransactionId'
            target='_blank'>
          </siko-button>
        </div>
      </ng-container>

      <siko-button
        testId='pay-button'
        *ngIf='!sapOrder.sapOrderPaymentStatus'
        size='md'
        [text]='getActionButtonTranslationKey() | cxTranslate'
        iconPosition='before'
        iconCode='credit_card'
        [iconOutline]='true'
        (clickEvent)='onAdditionalPaymentClick(sapOrder.orderId)'
      >
        <span class='pay-icon'></span>
      </siko-button>
    </div>

    <ng-container *ngIf='reorderButtonDisplayed'>
      <hr>
    </ng-container>

  </ng-container>

</ng-container>
