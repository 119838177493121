import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SikoAddress, BaseSites } from '@siko/models';
import { BaseSiteService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { B2bCommonModule } from '@siko/shared';

export type AddressMode = 'address' | 'businessIds' | 'full';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        B2bCommonModule,
    ],
})
export class SikoAddressComponent {

    @Input() address?: SikoAddress;
    @Input() mode?: AddressMode;
    @Input() name?: string;
    @Input() isShippingAddress?: boolean;

    constructor(
        private readonly baseSiteService: BaseSiteService,
    ) {}

    currentBasesite$: Observable<string> = this.baseSiteService.getActive();

    getBusinessTaxId(address: SikoAddress): string | undefined {
        return address.country?.isocode !== 'CZ' && address.businessTaxId ? address.businessTaxId : undefined;
    }

    getVatIdTranslationKey(baseSite: string): string {
        return baseSite === BaseSites.B2B_SPA_CZ ? 'siko.address.vatIdCz' : 'siko.address.vatIdOthers';
    }

}
