import { Injectable } from '@angular/core';
import { FileDownloadService } from '@spartacus/storefront';

@Injectable({
    providedIn: 'root',
})
export class SikoFileDownloadService {
    constructor(private readonly fileDownloadService: FileDownloadService) {}

    download(url: string, fileName?: string, attachment = false): void {
        if (attachment) {
            if (!url.includes('attachment=true') && url.includes('context')) {
                url = url.concat('&attachment=true');
            }
        }
        this.fileDownloadService.download(url, fileName);
    }

    downloadFile(blob: Blob, fileName: string, type = 'application/pdf'): void {
        const file = new Blob([blob], { type });
        const fileUrl = URL.createObjectURL(file);

        this.fileDownloadService.download(fileUrl, fileName);
        URL.revokeObjectURL(fileUrl);
    }
}
