import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
    SIKO_SAP_ORDER_FEATURE,
    SikoSapOrderState,
    StateWithSikoSapOrder
} from '@siko/features/my-account/sap-order-detail/store/siko-sap-order-state';
import { SikoSapOrder, SikoSapOrderDeliveryNote, SikoSapOrderInvoice } from '@siko/models';

export const getSapOrderState: MemoizedSelector<
  StateWithSikoSapOrder,
  SikoSapOrderState
  > = createFeatureSelector<SikoSapOrderState>(SIKO_SAP_ORDER_FEATURE);

export const getSapOrderData: MemoizedSelector<
  StateWithSikoSapOrder,
  SikoSapOrder | undefined
  > = createSelector(
      getSapOrderState, (sapOrderState: SikoSapOrderState) =>
          sapOrderState.sapOrder.value?.sapOrderData ?? undefined
  );

export const getSapOrderInvoices: MemoizedSelector<
  StateWithSikoSapOrder,
  SikoSapOrderInvoice[]
  > = createSelector(
      getSapOrderState, (sapOrderState: SikoSapOrderState) =>
          sapOrderState.sapOrder.value?.invoices ?? []
  );

export const getSapOrderDeliveryNotes: MemoizedSelector<
  StateWithSikoSapOrder,
  SikoSapOrderDeliveryNote[]
  > = createSelector(
      getSapOrderState, (sapOrderState: SikoSapOrderState) =>
          sapOrderState.sapOrder.value?.deliveryNotes ?? []
  );

export const getSapOrderDocuments: MemoizedSelector<
  StateWithSikoSapOrder,
  any
  > = createSelector(
      getSapOrderState, (sapOrderState: SikoSapOrderState) =>
          [...sapOrderState.sapOrder.value?.deliveryNotes || [], ...sapOrderState.sapOrder.value?.invoices || []] ?? []
  );
