import { CheckoutConfig, CheckoutStepType } from '@spartacus/checkout/base/root';
import { RoutingConfig } from '@spartacus/core';

/**
 * Overridden defaultB2BCheckoutConfig
 */
export const CUSTOM_CHECKOUT_STEPS_CONFIG: CheckoutConfig = {
    checkout: {
        steps: [
            {
                id: 'paymentType',
                name: 'sikoCheckout.checkoutProgress.methodOfPayment',
                routeName: 'checkoutPaymentType',
                type: [CheckoutStepType.PAYMENT_TYPE],
            },
            {
                id: 'deliveryAddress',
                name: 'sikoCheckout.checkoutProgress.shippingAddress',
                routeName: 'checkoutDeliveryAddress',
                type: [CheckoutStepType.DELIVERY_ADDRESS],
            },
            {
                id: 'reviewOrder',
                name: 'sikoCheckout.checkoutProgress.reviewOrder',
                routeName: 'checkoutReviewOrder',
                type: [CheckoutStepType.REVIEW_ORDER],
            },
        ],
    },
};

export const CUSTOM_CHECKOUT_ROUTING_CONFIG: RoutingConfig = {
    routing: {
        routes: {
            checkoutLogin: { paths: ['checkout-login'], authFlow: true },
            checkout: { paths: ['checkout'] },
            checkoutDeliveryAddress: { paths: ['checkout/delivery-address'] },
            checkoutDeliveryMode: { paths: ['checkout/delivery-mode'] },
            checkoutPaymentDetails: { paths: ['checkout/payment-details'] },
            checkoutReviewOrder: { paths: ['checkout/review-order'] },
        },
    },
};
