export enum BONUS_COEFFICIENT {
    BRONZE = 'bronze',
    SILVER = 'silver',
    GOLD = 'gold',
}

export enum BONUS_ACTION {
    ORDERED = 'ORDERED',
    INVOICED = 'INVOICED',
    MANUAL_INCREASE = 'MANUAL_INCREASE',
    MANUAL_DECREASE = 'MANUAL_DECREASE'
}
