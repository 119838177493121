import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MyAccountMenuCommunicationService } from '@siko/features/my-account/my-account-menu-communication.service';
import { Observable, of } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { B2bCommonModule } from '@siko/shared';
import { SikoPageSlotModule } from '@siko/features/shared-components/page-slot/page-slot.module';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-my-account-wrapper',
    templateUrl: './my-account-wrapper.component.html',
    styleUrls: ['./my-account-wrapper.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoPageSlotModule,
        RouterModule,
    ],
})
export class SikoMyAccountOutletComponent {
    constructor(
        private readonly router: Router,
        public myAccountSideMenuService: MyAccountMenuCommunicationService,
    ) {
    }

    get currentRoute$(): Observable<string> {
        return of(this.router.routerState.snapshot.url);
    }
}
