<ng-container *ngIf="review">
  <div class="review">
    <div class="d-flex flex-column">
      <siko-star-rating
        *ngIf="review.rating"
        [disabled]="true"
        [ratingCount]="5"
        [resetAble]="true"
        [value]="review.rating"
        [countReviews]="1"
        size="sm"
      ></siko-star-rating>
      <ng-container *ngIf="review.comment as comment">
        <span class="siko-fs-14 my-4">{{comment}}</span>
      </ng-container>
      <div class="d-flex flex-row align-items-baseline">
<!--        TODO: Export-B2B- After merge of Export-B2B use a directive that will hide professional reviews info-->
        <ng-container *ngIf="review.createdByProfessional">
          <div class="b2b-review siko-font-primary-semiBold siko-color-grey-3 siko-fs-14 mr-4">
            {{ 'pdp.reviewTab.reviewWrittenByB2BUser' | cxTranslate }}
          </div>
        </ng-container>
        <ng-container *ngIf="review.alias as alias">
          <div class="siko-font-primary-semiBold siko-fs-14 mr-4">
            {{ alias }}
          </div>
        </ng-container>
        <ng-container *ngIf="review.date as date">
          <div class="siko-font-primary-semiBold siko-fs-14 siko-color-grey-3">
            {{ date | date: 'dd.MM.YYYY' }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
