import {
    Component, forwardRef,
} from '@angular/core';
import { FileUploadComponent } from '@spartacus/storefront';
import { SikoFileUploadService } from '@siko/features/shared-components/import-entries-dialog/services/siko-file-upload.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Component that adds a file upload control.
 */
@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SikoFileUploadComponent),
            multi: true,
        },
    ],
})
export class SikoFileUploadComponent extends FileUploadComponent {

    constructor(
        readonly sikoUploadFileService: SikoFileUploadService,
    ) {
        super();
    }

    selectFile($event: Event) {
        const files = ($event.target as HTMLInputElement)?.files;
        this.onChangeCallback(files);
        if (files) {
            this.sikoUploadFileService.setUploadFileName(files[0].name);
        }
        this.update.emit(files);
    }

}
