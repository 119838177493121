import { Component, OnInit } from '@angular/core';
import {
    SikoOccBonusProgramAdapter,
} from '@siko/features/my-account/bonus-program/occ/adapters/occ-bonus-program.adapter';
import { SikoBonusRecordDetailModel } from '../../../../../models/bonus-program.model';

@Component({
    selector: 'app-bonus-program-record-dialog',
    templateUrl: './bonus-program-record-dialog.component.html',
    styleUrls: ['./bonus-program-record-dialog.component.scss'],
})
export class SikoBonusProgramRecordDialogComponent implements OnInit {

    code?: string;
    record?: SikoBonusRecordDetailModel;
    typeOfRecord?: 'invoice' | 'order';

    constructor(
        readonly occBonusProgramAdapter: SikoOccBonusProgramAdapter,
    ) {}

    ngOnInit(): void {
        if (this.code) {
            this.occBonusProgramAdapter.getDetailRecord(this.code)
                .subscribe(data => {
                    this.record = data;
                    this.typeOfRecord = data.invoiceOrders && data.invoiceOrders.length > 0 ? 'invoice' : 'order';
                });
        }
    }
}
