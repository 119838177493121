import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentProductService } from '@spartacus/storefront';
import { SikoProduct } from '@siko/models';
import { SikoPdpCommunicationService } from '@siko/features/product/product-detail/pdp-communication.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-mobile-tabs',
    templateUrl: './product-mobile-tabs.component.html',
    styleUrls: ['./product-mobile-tabs.component.scss'],
})
export class SikoProductMobileTabsComponent {

    product$: Observable<SikoProduct | null> = this.currentProductService.getProduct();

    constructor(
    public pdpCommunicationService: SikoPdpCommunicationService,
    private readonly currentProductService: CurrentProductService,
    ) {
    }
}
