import { Injectable } from '@angular/core';
import {
    ConverterService,
    Occ,
    PRODUCT_NORMALIZER,
} from '@spartacus/core';
import { OccOrderNormalizer } from '@spartacus/order/occ';
import { OrderEntry } from '@spartacus/cart/base/root';
import { Order } from '@spartacus/order/root';

@Injectable({ providedIn: 'root' })
export class SikoOccOrderNormalizer extends OccOrderNormalizer {

    constructor(
        private readonly sikoConverter: ConverterService
    ) {
        super(sikoConverter);
    }

    convert(source: Occ.Order, target?: Order): Order {
        if (target === undefined) {
            target = { ...(source as any) } as Order;
        }

        if (source.pickupOrderGroups) {
            target.pickupOrderGroups = source.pickupOrderGroups.map((pickupOrderGroup: Occ.PickupOrderEntryGroup) => {
                const convertedGroup = {
                    ...pickupOrderGroup,
                    entries: [],
                };

                if (pickupOrderGroup.entries) {
                    for (const entry of pickupOrderGroup.entries) {
                        const convertedEntry: OrderEntry = this.sikoConvertOrderEntry(
                            entry,
                            source.code
                        );

                        // @ts-expect-error
                        convertedGroup.entries.push(convertedEntry);
                    }
                }

                return convertedGroup;
            });
        }

        return super.convert(source, target);
    }

    private sikoConvertOrderEntry(
        source?: Occ.OrderEntry,
        code?: string
    ): OrderEntry {
        return {
            ...source,
            product: this.sikoConverter.convert(source?.product, PRODUCT_NORMALIZER),
            orderCode: code
        };
    }

}
