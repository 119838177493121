import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CurrentProductService, CustomFormValidators } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
    ProductActions,
    ProductReviewService,
    RoutingService,
    StateWithProduct,
} from '@spartacus/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SikoPdpCommunicationService } from '@siko/features/product/product-detail/pdp-communication.service';
import { SikoProduct } from '@siko/models';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SikoAutoUnsubscribe, SikoBreakpointObserverService } from '@siko/common';
import { ActivatedRoute } from '@angular/router';


@SikoAutoUnsubscribe(['addNewReviewSubscription', 'storeActionsSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-reviews-tab',
    templateUrl: './product-reviews-tab.component.html',
    styleUrls: ['./product-reviews-tab.component.scss'],
})
export class SikoProductReviewsTabComponent implements OnInit {

    @Input() displayHeader = true;
    @ViewChild('titleInput', { static: false }) titleInput?: ElementRef;
    @ViewChild('writeReviewButton', { static: false }) writeReviewButton?: ElementRef;

    isWritingReview = false;
    maxListItems = 3;
    reviewForm: UntypedFormGroup = this.fb.group({
        title: [''],
        comment: ['', Validators.required],
        rating: [null, CustomFormValidators.starRatingEmpty],
        reviewerName: ['', Validators.required],
    });
    isReviewSent$ = new BehaviorSubject<boolean>(false);
    showReviewTab$ = new BehaviorSubject<boolean>(true);
    product$: Observable<SikoProduct | null> = this.currentProductService.getProduct();
    addNewReviewSubscription?: Subscription;
    storeActionsSubscription?: Subscription;

    constructor(
        protected pdpCommunicationService: SikoPdpCommunicationService,
        protected reviewService: ProductReviewService,
        protected currentProductService: CurrentProductService,
        public sikoBreakpointObserver: SikoBreakpointObserverService,
        private readonly fb: UntypedFormBuilder,
        protected cd: ChangeDetectorRef,
        private readonly actions: Actions,
        private readonly bottomSheet: MatBottomSheet,
        readonly route: ActivatedRoute,
        readonly store: Store<StateWithProduct>,
        readonly routingService: RoutingService,
    ) {
    }

    ngOnInit(): void {
        this.addNewReviewSubscription = this.pdpCommunicationService.addNewReview$
            .subscribe((addNewReview: boolean) => {
                if (addNewReview) {
                    this.initiateWriteReview();
                }
                else {
                    this.cancelWriteReview();
                }
            });

        this.storeActionsSubscription = this.actions.pipe(
            ofType(
                ProductActions.POST_PRODUCT_REVIEW_SUCCESS,
                ProductActions.POST_PRODUCT_REVIEW_FAIL,
            ),
        )
            .subscribe((action: Action) => {
                if (action.type === ProductActions.POST_PRODUCT_REVIEW_SUCCESS) {
                    this.isReviewSent$.next(true);
                    this.showReviewTab$.next(false);
                }
                else if (action.type === ProductActions.POST_PRODUCT_REVIEW_FAIL) {
                    this.isReviewSent$.next(false);
                }
            });
    }

    closeTemplateSheetMenu(): void {
        this.bottomSheet.dismiss();
    }

    initiateWriteReview(): void {
        this.isWritingReview = true;

        this.cd.detectChanges();

        if (this.titleInput && this.titleInput.nativeElement) {
            this.titleInput.nativeElement.focus();
        }
    }

    cancelWriteReview(): void {
        this.isWritingReview = false;
        this.resetReviewForm();

        this.cd.detectChanges();

        if (this.writeReviewButton && this.writeReviewButton.nativeElement) {
            this.writeReviewButton.nativeElement.focus();
        }
    }

    setRating(rating: number): void {
        this.reviewForm.controls.rating.setValue(rating);
    }

    submitReview(product: SikoProduct): void {
        if (this.reviewForm.valid) {
            this.addReview(product);
        }
        else {
            this.reviewForm.markAllAsTouched();
        }
    }

    addReview(product: SikoProduct): void {
        if (product.code) {
            const reviewFormControls = this.reviewForm.controls;
            const review = {
                headline: reviewFormControls.title.value,
                comment: reviewFormControls.comment.value,
                rating: reviewFormControls.rating.value,
                alias: reviewFormControls.reviewerName.value,
            };

            this.reviewService.add(product.code, review);

            this.isWritingReview = false;
            this.resetReviewForm();

            this.cd.detectChanges();

            if (this.writeReviewButton && this.writeReviewButton.nativeElement) {
                this.writeReviewButton.nativeElement.focus();
            }
        }
    }

    showMoreReviews(): void {
        this.maxListItems = this.maxListItems + 3;
    }

    isUserAllowedToWriteReview(canWriteReview: boolean | undefined): boolean {
        return canWriteReview || this.route.snapshot.queryParams.review === 'on';
    }

    private resetReviewForm(): void {
        this.reviewForm = this.fb.group({
            title: [''],
            comment: ['', Validators.required],
            rating: [null, CustomFormValidators.starRatingEmpty],
            reviewerName: ['', Validators.required],
        });
    }

}
