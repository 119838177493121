import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CurrentProductService } from '@spartacus/storefront';
import { SikoPdpCommunicationService } from '@siko/features/product/product-detail/pdp-communication.service';
import { SikoProduct } from '@siko/models';
import { B2bCommonModule } from '@siko/shared';

@Component({
    selector: 'app-product-header-description',
    templateUrl: './product-header-description.component.html',
    standalone: true,
    imports: [
        B2bCommonModule
    ]
})
export class SikoProductHeaderDescriptionComponent {

    private productFromInput?: SikoProduct;

  @Input() set inputProduct(input: SikoProduct){
        this.productFromInput = input;
        this.scrollNeedsRedirect = true;
    }
  scrollNeedsRedirect = false;

  constructor(
    public pdpCommunicationService: SikoPdpCommunicationService,
    private readonly currentProductService: CurrentProductService
  ) {
  }

  get product$(): Observable<SikoProduct | null> {
      return this.productFromInput ? of(this.productFromInput) : this.currentProductService.getProduct();
  }
}
