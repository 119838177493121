import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { DatePipe, DOCUMENT } from '@angular/common';
import { SavedCartFormDialogComponent } from '@spartacus/cart/saved-cart/components';
import { LaunchDialogService } from '@spartacus/storefront';
import { EventService, GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { Actions, ofType } from '@ngrx/effects';
import { SikoActiveCartService } from '@siko/features/cart';
import { SikoTrackingUtils } from '@siko/shared';
import { SikoAutoUnsubscribe } from '@siko/common';
import { fromEvent, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SavedCartActions } from '@spartacus/cart/saved-cart/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { CartActions } from '@spartacus/cart/base/core';
import { SikoSavedCartService } from '@siko/features/my-account/saved-cart/saved-cart.service';

@SikoAutoUnsubscribe([
    'cloneSavedCartFailSubscription',
    'cloneSavedCartSubscription',
    'restoreSavedCartSubscription',
    'restoreSavedCartFailSubscription',
    'onClickSubscription',
    'saveCartAddSuccessSubscription',
    'activeCartSubscription',
    'deleteCartSuccessSubscription',
    'saveCartFailSubscription'
])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'cx-saved-cart-form-dialog',
    templateUrl: './saved-cart-form-dialog.component.html',
    styleUrls: ['./saved-cart-form-dialog.component.scss'],
})
export class SikoSavedCartFormDialogComponent extends SavedCartFormDialogComponent implements OnInit {

    onClickSubscription?: Subscription;
    activeCartSubscription?: Subscription;
    saveCartAddSuccessSubscription?: Subscription;
    deleteCartSuccessSubscription?: Subscription;
    restoreSavedCartFailSubscription?: Subscription;
    restoreSavedCartSubscription?: Subscription;
    cloneSavedCartSubscription?: Subscription;
    cloneSavedCartFailSubscription?: Subscription;
    saveCartFailSubscription?: Subscription;

    nameMaxLength = 50;
    currentCart?: Cart;
    currentCartEntries: number = 0;
    actualDate: Date = new Date();

    savedCartForm: FormGroup = this.fb.group({
        savedCartName: [],
    });

    constructor(
        readonly saveCartService: SikoSavedCartService,
        readonly fb: FormBuilder,
        @Inject(DOCUMENT) private readonly document: Document,
        readonly trackingUtils: SikoTrackingUtils,
        readonly actions: Actions,
        readonly activeCartService: ActiveCartFacade,
        readonly sikoActiveCartService: SikoActiveCartService,
        launchDialogService: LaunchDialogService,
        el: ElementRef,
        eventService: EventService,
        routingService: RoutingService,
        globalMessageService: GlobalMessageService,
    ) {
        super(launchDialogService, el, saveCartService, eventService, routingService, globalMessageService);
    }

    // TODO replace for Angular pipe
    get nameCharacterLeft(): number {
        return (
            this.nameMaxLength -
            (this.form.get('name')?.value?.length || 0)
        );
    }

    removeEntriesAndRestore(cartCode?: string): void {
        if (cartCode) {
            if (this.currentCart?.entries) {
                this.sikoActiveCartService.deleteCart();
                this.deleteCartSuccessSubscription = this.actions.pipe(
                    ofType(CartActions.DELETE_CART_SUCCESS),
                )
                    .subscribe(data => {
                        this.restoreSavedCart(cartCode);
                    });
            }
        }
    }

    saveEntriesAndRestore(cartCode?: string): void {
        if (cartCode && this.currentCart?.code) {
            let savedCartName = this.savedCartForm.controls?.savedCartName.value;
            if (savedCartName === null) {
                const datePipe = new DatePipe('cs-CZ');
                savedCartName = datePipe.transform(new Date(), 'd.M.Y H:mm');
            }

            let oldCart = this.currentCart;
            this.saveCartService.saveCart({
                cartId: this.currentCart.code,
                saveCartName: savedCartName,
                saveCartDescription: '',
            });

            this.saveCartAddSuccessSubscription = this.actions.pipe(
                ofType(SavedCartActions.SAVE_CART_SUCCESS),
            )
                .subscribe(data => {
                    this.trackingUtils.pushCartSaveEvent(oldCart, false);
                    this.restoreSavedCart(cartCode);
                });
        }
    }


    ngOnInit(): void {
        super.ngOnInit();

        this.activeCartSubscription = this.activeCartService.getActive()
            .subscribe((data: Cart) => {
                this.currentCart = data;
                if (data.entries) {
                    this.currentCartEntries = data.entries.length;
                }
            });

        this.onClickSubscription = fromEvent(this.document, 'click')
            .subscribe((event: Event) => {
                if (event.target instanceof Element && event.target.classList.contains('siko-dialog-window')) {
                    this.close('click');
                }
            });

        this.restoreSavedCartFailSubscription = this.saveCartService.getRestoreSavedCartFail().subscribe(data => {
            if (data) {
                this.close('');
                this.saveCartService.setRestoreSavedCartFail(false);
            }
        })

        this.cloneSavedCartFailSubscription = this.actions.pipe(ofType(SavedCartActions.CLONE_SAVED_CART_FAIL))
            .subscribe(() => {
                this.close('Non valid entries');
                this.globalMessageService.add({ key: 'httpHandlers.cart.noRestoredEntries' }, GlobalMessageType.MSG_TYPE_ERROR);
            });


        this.saveCartFailSubscription = this.actions.pipe(
            ofType(SavedCartActions.SAVE_CART_FAIL),
        )
            .subscribe(() => {
                this.close('Cart is not found');
                this.globalMessageService.add({ key: 'httpHandlers.cart.reloadCartRequired' }, GlobalMessageType.MSG_TYPE_ERROR);
            });
    }

    close(reason: string): void {
        const modalElement = document.querySelector('.modal-content');
        if (modalElement) {
            modalElement.classList.remove('show');
        }

        setTimeout(() => {
            super.close(reason);
        }, 300);
    }

    restoreSavedCart(cartId: string): void {
        if (this.cart.entries) {
            this.sikoActiveCartService.setRestoringEntries(this.cart.entries);
        }

        if (this.isCloneSavedCart) {
            this.cloneSavedCartSubscription = this.saveCartService.cloneSavedCart(
                cartId,
                this.form.get('cloneName')?.value,
            ).subscribe();
            this.restoreSavedCartSubscription = this.saveCartService.restoreSavedCart(cartId).subscribe();
        }
        else {
            this.restoreSavedCartSubscription = this.saveCartService.restoreSavedCart(cartId).subscribe();
        }
    }

}
