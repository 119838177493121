<ng-container *ngIf='storeEntity'>
  <div [ngClass]='plp ? "show-on-plp" : ""'>
    <div class='in-stock' *ngIf='this.stockLevelStatus === "inStock"'>
      <div class='d-flex'>
        <div>
          <span class='icon'></span>
        </div>
        <div>
          {{"siko.inStock" | cxTranslate }}
          <span class='text-nowrap'>{{getStockLevel()}}
            <app-product-unit [productUnitData]='unit' [inheritTextStyles]='true' [count]='+getStockLevel()'></app-product-unit></span>
        </div>
      </div>

    </div>
    <div class='out-of-stock' *ngIf='this.stockLevelStatus === "outOfStock"'>
      <div class='d-flex align-items-center'>
        <div><app-red-cross-icon></app-red-cross-icon></div>
        {{"siko.outOfStock" | cxTranslate }}
      </div>
      <div *ngIf='saleEnded'>
        {{'siko.saleEnded' | cxTranslate}}
      </div>
    </div>
    <div class='on-order' *ngIf='this.stockLevelStatus === "onOrder"'>
      <span class='icon'></span>
      <div>
        <div>{{"siko.onOrder" | cxTranslate }}</div>
        <div *ngIf='deliveryTimeText'>{{deliveryTimeText}}</div>
      </div>
    </div>
    <div class='upcoming' *ngIf='this.stockLevelStatus === "upcoming"'>
      <div class='d-flex'>
        <div>
          <span class='icon'></span>
        </div>
        <div>
          <div>{{"siko.awaiting" | cxTranslate }}</div>
          <div *ngIf='storeEntity.stockInfo?.nextDeliveryDate'>{{"siko.except" | cxTranslate}} {{storeEntity.stockInfo?.nextDeliveryDate | cxDate : "dd.MM.YYYY"}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf='productDisponibility'>
  <div [ngClass]='plp || checkout ? "show-on-plp" : ""'>
    <div class='in-stock' *ngIf='this.stockLevelStatus === "inStock"'>
      <div class='d-flex align-items-center'>
        <span class='icon'></span>
        <div>
          <span class='text-nowrap'>
            {{"siko.inStock" | cxTranslate}}
            <ng-container *ngIf='!checkout'>
              {{getStockLevel()}}
              <app-product-unit [productUnitData]='unit' [inheritTextStyles]='true' [count]='+getStockLevel()'></app-product-unit>
            </ng-container>
          </span>
        </div>
      </div>

    </div>
    <div class='on-order' *ngIf='this.stockLevelStatus === "inCentralStock"'>
      <span class='icon'></span>
      <div>
        <div>{{"siko.inCentralStock" | cxTranslate }}</div>
      </div>
    </div>
    <div class='out-of-stock' *ngIf='this.stockLevelStatus === "outOfStock"'>
      <span class='pr-2'><app-red-cross-icon></app-red-cross-icon></span>{{"siko.outOfStock" | cxTranslate }}
      <div *ngIf='saleEnded'>
        {{'siko.saleEnded' | cxTranslate}}
      </div>
    </div>
    <div class='on-order' *ngIf='this.stockLevelStatus === "onOrder"'>
      <div class='d-flex align-items-center'>
        <span class='icon'></span>
        <div class='siko-lh-150'>
          {{"siko.onOrder" | cxTranslate }}
          <div class='text'>{{ this.deliveryTimeText }}</div>
        </div>
      </div>
    </div>
    <div class='upcoming' *ngIf='this.stockLevelStatus === "upcoming"'>
      <div class='d-flex' [ngClass]='checkout ? "in-checkout align-items-center" : ""'>
        <div>
          <span class='icon'></span>
        </div>
        <div>
          <div>{{"siko.awaiting" | cxTranslate }}</div>
          <div *ngIf='!checkout'>{{productDisponibility.text}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
