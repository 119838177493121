import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CategoryNavigationComponent, CmsComponentData, NavigationService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CmsNavigationComponent } from '@spartacus/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SikoNavigationService } from '@siko/features/header/navigation.service';
import { SikoNavigationNode } from '@siko/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'cx-category-navigation',
    templateUrl: './category-navigation.component.html',
    styleUrls: ['./category-navigation.component.scss'],
})
export class SikoCategoryNavigationComponent extends CategoryNavigationComponent {

    node$: Observable<SikoNavigationNode> = this.service.getNavigationNode(
        this.componentData.data$,
    );

    sikoComponentData$: Observable<CmsNavigationComponent> = this.componentData.data$;
    sikoNode$: Observable<SikoNavigationNode> = this.sikoNavigationService.getNavigationNode(
        this.sikoComponentData$,
    );

    isDesktop$ = this.breakpointObserver.observe(['(min-width: 744px)']);

    constructor(
        protected componentData: CmsComponentData<CmsNavigationComponent>,
        protected service: NavigationService,
        protected sikoNavigationService: SikoNavigationService,
        private readonly breakpointObserver: BreakpointObserver,
    ) {
        super(componentData, service);
    }


}
