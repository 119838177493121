import { TranslationResources } from '@spartacus/core';

import CS_TRANSLATIONS from './langs/cs.json';
import EN_TRANSLATIONS from './langs/en.json';
import SK_TRANSLATIONS from './langs/sk.json';

export const SIKO_BONUS_POINTS_INFO_TRANSLATIONS: TranslationResources = {
    cs: CS_TRANSLATIONS,
    en: EN_TRANSLATIONS,
    sk: SK_TRANSLATIONS,
};
