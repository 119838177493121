import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { FacetList, FacetListComponent, FacetService } from '@spartacus/storefront';
import { SikoB2bPlpService } from '@siko/features/product/product-listing/siko-b2b-plp.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { SikoAutoUnsubscribe } from '@siko/common';
import { SikoFacet, SikoFacetList } from '@siko/models';

@SikoAutoUnsubscribe(['facetsSubscription', 'onClickSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-facet-list',
    templateUrl: './facet-list.component.html',
    styleUrls: ['./facet-list.component.scss'],
})
export class SikoFacetListComponent extends FacetListComponent implements OnInit {

    @Input() productCount? = 0;
    @Input() urlToCancelFacets?: string;
    @Input() virtualCategory?: string;

    isSideMenuOpen$: Observable<boolean> = this.plpCommunication.isSideMenuOpen$;

    clickObservable$: Observable<Event> = fromEvent(document, 'click');

    facetsSubscription?: Subscription;
    onClickSubscription?: Subscription;

    facetList$: Observable<SikoFacetList> = this.facetService.facetList$;

    constructor(
        public readonly plpCommunication: SikoB2bPlpService,
        facetService: FacetService,
        elementRef: ElementRef,
        renderer: Renderer2,
    ) {
        super(facetService, elementRef, renderer);
    }

    ngOnInit(): void {
        this.facetsSubscription = this.facetList$.subscribe(data => {
            data.activeFacets?.forEach((value, index) => {
                if (value.facetCode === 'allCategories') {
                    data.activeFacets?.splice(index, 1);
                }
            });
        });

        this.onClickSubscription = this.clickObservable$
            .subscribe((event: Event) => {
                if (event.target instanceof Element && event.target.classList.contains('overlay-bg')) {
                    this.hideFilter();
                }
            });
    }

    hideFilter(): void {
        this.plpCommunication.setIsSideMenuOpen(false);
        this.renderer.removeClass(document.body, 'no-scroll');
    }

    showFacets(facetList: FacetList): boolean {
        if (facetList.facets?.length === 0) {
            return false;
        }

        for (let facet of facetList.facets) {
            if (facet.values && facet.values.length > 0) {
                return true;
            }
        }

        return false;
    }

}
