import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'discountPercentString',
})
export class SikoDiscountPercentStringPipe implements PipeTransform {

    transform(value: string | undefined, ...args: unknown[]): string {
        if (!value) {return '';}

        if (value.indexOf('-') === -1) {
            return `-${value}`;
        }

        return value;
    }

}
