import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StateWithSikoNews } from '@siko/features/news/store/siko-news-state';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SikoNewsSelectors } from '@siko/features/news/store/selectors';
import { Title } from '@angular/platform-browser';
import { SiteContextSelectors, StateWithSiteContext, TranslationService } from '@spartacus/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { SikoNewsActions } from '../../../news/store/actions';
import { SikoAutoUnsubscribe } from '@siko/common';
import { B2bCommonModule } from '@siko/shared';
import { PageSlotModule } from '@spartacus/storefront';


@SikoAutoUnsubscribe(['languageChangeSubscription', 'currentPageSubscription', 'translationSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-news-page-template',
    templateUrl: './news-page-template.component.html',
    styleUrls: ['./news-page-template.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
        PageSlotModule
    ]
})
export class SikoNewsPageOutletComponent implements OnInit {

    languageChangeSubscription?: Subscription;
    currentPageSubscription?: Subscription;
    translationSubscription?: Subscription;

    currentPageSubject$ = new BehaviorSubject<number>(0);

    currentPage$: Observable<number> = this.newsStore.pipe(
        select(SikoNewsSelectors.getCurrentPage),
    );

    constructor(
        private readonly newsStore: Store<StateWithSikoNews>,
        private readonly siteContextStore: Store<StateWithSiteContext>,
        private readonly titleService: Title,
        protected translation: TranslationService,
    ) {
    }

    ngOnInit(): void {
        this.currentPage$.subscribe((currentPage: number) => {
            this.newsStore.dispatch(new SikoNewsActions.LoadNews({ currentPage }));
        })
            .unsubscribe();

        this.translationSubscription = this.translation.translate('news.title')
            .subscribe(translation => {
                this.titleService.setTitle(translation.concat(' | SIKO B2B'));
            });

        this.languageChangeSubscription = this.siteContextStore.pipe(
            select(SiteContextSelectors.getActiveLanguage),
            distinctUntilChanged(),
        )
            .subscribe(() => {
                const currentPage = this.currentPageSubject$.getValue();

                this.newsStore.dispatch(new SikoNewsActions.LoadNews({ currentPage }));
            });

        this.currentPageSubscription = this.newsStore.pipe(
            select(SikoNewsSelectors.getCurrentPage),
        )
            .subscribe((currentPage: number) => {
                this.currentPageSubject$.next(currentPage);
            });
    }

}
