<div [ngClass]="getCssClassesForMessage" class="cx-message">
  <div class='d-flex w-100 align-items-center'>
    <div class='icon-wrapper p-4'>
    </div>
    <div class='text-wrapper' *ngIf="!actionButtonText">
      <div>
        {{ text }}
      </div>
      <div class="break"></div>
      <div class='mt-2' *ngIf="accordionText">
        <siko-link
          label='{{ accordionText }}'
          (click)="showBody = !showBody"
          [style]="$any('primary')"
          [disableLink]='true'>
        </siko-link>
        <div *ngIf="showBody || !accordionText" class="cx-message-body">
          <ng-content></ng-content>
        </div>
      </div>
      <div class='close-button'
           *ngIf='isVisibleCloseButton'
           (click)="closeMessage.emit()">
      </div>
    </div>
    <div class='text-wrapper action-button' *ngIf="actionButtonText">
      <div class="d-inline-block">
        {{ text }}
      </div>
      <div class="d-inline-block button">
        <siko-link
          label='{{ actionButtonText }}'
          (click)="buttonAction.emit()"
          [style]="$any('primary')"
          [disableLink]='true'>
        </siko-link>
      </div>
      <div class='close-button'
           (click)="closeMessage.emit()">
      </div>
    </div>
  </div>
</div>
