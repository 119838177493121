import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SikoNewsCmsData, SikoNewsData } from '@siko/models';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OccNewsAdapter {

    userId = '';
    private readonly backendBaseUrl: string;

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        readonly userIdService: UserIdService,
    ) {
        this.backendBaseUrl = this.occEndpoints.getBaseUrl();
        this.userIdService.getUserId().pipe(
            distinctUntilChanged()
        )
            .subscribe((userId: string) => {
                this.userId = userId;
            });
    }

    getNews(pageNumber: number): Observable<SikoNewsData>{
        return this.http.get(`${this.backendBaseUrl}/users/${this.userId}/newspages/pagination?page=${pageNumber}`);
    }

    markNewsAsSeen(newsUid: string): Observable<unknown>{
        return this.http.patch(`${this.backendBaseUrl}/users/${this.userId}/newspages/mark-seen/${newsUid}`, {});
    }

    markNewsAsUnseen(newsUid: string): Observable<unknown>{
        return this.http.patch(`${this.backendBaseUrl}/users/${this.userId}/newspages/mark-not-seen/${newsUid}`, {});
    }

    markAllAsSeen(): Observable<unknown>{
        return this.http.patch(`${this.backendBaseUrl}/users/${this.userId}/newspages/mark-all-seen`, {});
    }

    getNotSeenCount(): Observable<number>{
        return this.http.get<number>(`${this.backendBaseUrl}/users/${this.userId}/newspages/notseencount`);
    }

    getSelectedNews(newsUid: string): Observable<SikoNewsCmsData>{
        return this.http.get<SikoNewsCmsData>(
            `${this.backendBaseUrl}/cms/pages/${newsUid}`
        );
    }
}
