import { Injectable } from '@angular/core';
import { ActiveCartFacade, Cart, MultiCartFacade, OrderEntriesSource, ProductData } from '@spartacus/cart/base/root';
import {
    UserIdService,
} from '@spartacus/core';
import { from, Observable, queueScheduler } from 'rxjs';
import {
    concatMap,
    delayWhen,
    filter, finalize,
    map,
    observeOn,
    switchMap, take,
    tap,
} from 'rxjs/operators';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { OccBopisAdapter } from '@siko/features/cart/occ/adapters/occ-bopis-adapter.service';
import { SikoImportSummaryService } from '@siko/features/cart/services/siko-import-summary.service';
import { generateImportBatchId } from '@siko/constants';

@Injectable({
    providedIn: 'root',
})
export class SikoNewSavedCartOrderEntriesContext {
    readonly type = OrderEntriesSource.NEW_SAVED_CART;

    constructor(
        private readonly sikoImportSummaryService: SikoImportSummaryService,
        private readonly occBopisAdapter: OccBopisAdapter,
        protected userIdService: UserIdService,
        protected multiCartService: MultiCartFacade,
        protected savedCartService: SavedCartFacade,
        readonly activeCartService: ActiveCartFacade,
    ) {
    }

    add(
        products: ProductData[],
        savedCartInfo?: { name: string; description: string },
    ): Observable<string> {
        const entriesToAdd: any[] = [];

        products.forEach((value, index) => {
            if (value.quantity && value.quantity > 0) {
                entriesToAdd.push({
                    productCode: value.productCode,
                    quantity: value.quantity,
                });
            }
            else {
                this.sikoImportSummaryService.addQuantityError(value.productCode);
            }
        });

        const batchKey = generateImportBatchId();
        let currentCartId = '';

        this.activeCartService.getActiveCartId().subscribe(currentActiveCartId => {
            currentCartId = currentActiveCartId;
        })
            .unsubscribe();

        return this.userIdService.takeUserId()
            .pipe(
                switchMap((userId: string) =>
                    this.multiCartService
                        .createCart({
                            userId,
                            extraData: { active: false },
                        })
                        .pipe(
                            // take(1),
                            filter((cartData: Cart) => Boolean(cartData.code)),
                            map(
                                (cartData: Cart) => cartData.code!,
                            ),
                            tap((cartId: string) => {
                                if (currentCartId !== cartId) {
                                    this.savedCartService.saveCart({
                                        cartId,
                                        saveCartName: savedCartInfo?.name,
                                        saveCartDescription: savedCartInfo?.description,
                                    });
                                    this.savedCartService.loadSavedCarts();
                                }
                            }),
                            observeOn(queueScheduler),
                            delayWhen(() =>
                                this.savedCartService
                                    .getSaveCartProcessLoading()
                                    .pipe(filter((loading: boolean) => !loading))),
                            tap((cartId: string) =>
                                from(entriesToAdd)
                                    .pipe(
                                        concatMap((product: any) => this.occBopisAdapter.importCart(
                                            userId,
                                            cartId,
                                            product.quantity,
                                            product.productCode,
                                            `${batchKey}`
                                        )),
                                        finalize(() => {
                                            this.sikoImportSummaryService.summary$.next({
                                                ...this.sikoImportSummaryService.summary$.value,
                                                loading: false,
                                            });
                                        }),
                                    )
                                    .subscribe(data => {
                                        this.sikoImportSummaryService.mapMessages(data);
                                    })),
                        )),
            );
    }

}
