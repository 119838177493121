import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SikoProduct, SikoProductDisponibility } from '@siko/models';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, pluck, tap } from 'rxjs/operators';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { SikoActiveCartService } from '@siko/features/cart';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isNotNullable, ProductScope, ProductService } from '@spartacus/core';
import { SikoCartEntryEnum } from '@siko/features/shared-components/added-to-cart-dialog/components/added-to-cart-dialog/added-to-cart-dialog.component';
import { isProductForSale, showAddToCartButtons } from '../../../../product/product-utils';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-detail-dialog',
    templateUrl: './product-detail-dialog.component.html',
    styleUrls: ['./product-detail-dialog.component.scss'],
})
export class SikoProductDetailDialogComponent {

    sikoProductCode?: string;
    sourceForGTM = '';
    purchasableJustInOtherShop = false;
    forSale = true;
    showAddToCartButtons?: boolean;
    addToCartForm = new UntypedFormGroup({
        quantity: new UntypedFormControl(1, { updateOn: 'blur' }),
    });

    predefinedPointOfServiceName$: Observable<unknown> = this.userProfileFacade.get()
        .pipe(
            pluck('orgUnit', 'sikoPredefinedPointOfService', 'displayName'),
        );
    constructor(
        public activeModal: NgbActiveModal,
        private readonly userProfileFacade: UserProfileFacade,
        private readonly sikoActiveCartService: SikoActiveCartService,
        private readonly productService: ProductService,
    ) {
    }

    get sikoProduct$(): Observable<SikoProduct | null> {
        return this.sikoProductCode ?
            this.productService.get(this.sikoProductCode, ProductScope.DETAILS)
                .pipe(
                    filter(isNotNullable),
                    map(product => product as SikoProduct),
                    tap((sikoProduct: SikoProduct) => {
                        if (sikoProduct.disponibility?.level === 0) {
                            this.purchasableJustInOtherShop = true;
                        }

                        this.forSale = sikoProduct.disponibility && sikoProduct.availableIn !== undefined && sikoProduct.hasOwnProperty('sapStatus')
                            ? isProductForSale(sikoProduct.disponibility, +sikoProduct.availableIn, sikoProduct.sapStatus!)
                            : true;

                        const inCentralStock = sikoProduct.disponibility?.level ? sikoProduct.disponibility.level > 0 : false;

                        this.showAddToCartButtons = sikoProduct.availableIn && sikoProduct.code && this.forSale
                            ? showAddToCartButtons(this.forSale, inCentralStock, +sikoProduct.availableIn, sikoProduct.sapStatus)
                            : false;
                    }),
                    catchError(error => of(null)),
                ) : of(null);
    }

    addToCart(sikoProduct: SikoProduct, addFavourite = true): void {
        // DISPONIBILITY storeDisponibility property if addFavourite = true || disponibility if addFavourite = false
        const canAddToCart = this.sikoActiveCartService.canAddToCart();

        if (!canAddToCart) {
            return;
        }

        const productStoreDisponibility: SikoProductDisponibility | undefined = addFavourite ? sikoProduct.disponibility?.storeDisponibility : sikoProduct.disponibility;
        const isFis: boolean | undefined = sikoProduct.disponibility?.isFIS;

        if (!productStoreDisponibility) {
            return;
        }

        if (this.addToCartForm.get('quantity')) {
            const productCode = sikoProduct.code;
            const quantity: number = this.addToCartForm.get('quantity')?.value as number;

            if (!productCode || quantity <= 0) {
                return;
            }

            // TODO: temp disable of code B2B1-1793
            // if (quantity > productStoreDisponibility.level && !isFis) {
            //    quantity = productStoreDisponibility.level;
            // }

            this.activeModal.dismiss('Adding to cart');

            setTimeout(() => {
                this.sikoActiveCartService.openAddingToCartModal(
                    quantity,
                    productCode,
                    addFavourite ? SikoCartEntryEnum.FAVOURITE : SikoCartEntryEnum.DELIVERY,
                    this.sourceForGTM,
                );
            }, 500);

        }
    }

}
