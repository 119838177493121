import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    CmsComponentData,
    PageLayoutService,
    ProductListComponent,
    ViewConfig,
} from '@spartacus/storefront';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { SikoHelperFunctionsService } from '@siko/shared';
import { SikoB2bPlpService } from '@siko/features/product/product-listing/siko-b2b-plp.service';
import {
    CmsService,
    GlobalMessageService,
    isNotUndefined,
    StateWithSiteContext,
} from '@spartacus/core';
import {
    CmsAdBannerContextComponent,
    CmsAdCategoriesComponent,
    DisplayItem, DisplayPageContent,
    SikoProductSearchPage,
} from '@siko/models';
import { SikoAutoUnsubscribe } from '@siko/common';
import { DOCUMENT } from '@angular/common';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { SikoProductListComponentService } from '@siko/features/product/product-listing/product-list/service/siko-product-list-component.service';
import { Store } from '@ngrx/store';

@SikoAutoUnsubscribe([
    'queryParamsSubscription',
    'searchPageSubscription',
    'adBannersSubscription',
    'routerSubscription',
])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SikoProductListComponent extends ProductListComponent implements OnInit {

    showMobileSorting = false;
    isStoreDisponibility = false;
    destroy$ = new Subject<void>();
    isEmptySearchResult = false;
    sikoViewMode: string | null = null;
    model$: Observable<SikoProductSearchPage> = this.productListComponent.model$;
    modelWithAdBanners$: Observable<DisplayPageContent> = this.model$.pipe(
        switchMap(productSearchPageModel => {
            const breadcrumbs = productSearchPageModel.sikoBreadcrumbs ?? [];

            this.plpCommunication.setDisplayPlpSkeleton(false);

            return this.resolveAdBannersAsync(breadcrumbs).pipe(
                map(({ firstAdBanner, secondAdBanner }) => {
                    const items: DisplayItem[] = [];

                    if (productSearchPageModel.products){
                        productSearchPageModel.products.forEach((product, index) => {
                            items.push({
                                type: 'product',
                                data: product
                            });

                            if (index === 1 && firstAdBanner) {
                                items.push({
                                    type: 'adBanner',
                                    data: firstAdBanner
                                });
                            }

                            if (index === 8 && secondAdBanner) {
                                items.push({
                                    type: 'adBanner',
                                    data: secondAdBanner
                                });
                            }
                        });
                    }


                    return {
                        items,
                        productSearchPageModel
                    };
                })
            );
        })
    );
    adBannerCategories$: Observable<CmsAdCategoriesComponent[]> = this.componentData.data$.pipe(
        switchMap((data: any) => {
            const categories = data.adBannerCategories?.trim().split(' ') || [];

            if (categories.length === 0) {
                return of([]);
            }

            return combineLatest(
                categories.map((component: string) =>
                    this.cmsService.getComponentData(component).pipe(
                        map(componentData => componentData as CmsAdCategoriesComponent)
                    ))
            ) as Observable<CmsAdCategoriesComponent[]>;
        }),
    );
    sikoBreadcrumbs: any[] | undefined = [];
    queryParamsSubscription?: Subscription;
    searchPageSubscription?: Subscription;
    adBannersSubscription?: Subscription;
    routerSubscription?: Subscription;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        readonly plpCommunication: SikoB2bPlpService,
        readonly renderer: Renderer2,
        readonly productListComponent: SikoProductListComponentService,
        readonly activatedRoute: ActivatedRoute,
        readonly helperFunctionsService: SikoHelperFunctionsService,
        readonly componentData: CmsComponentData<any>,
        readonly cmsService: CmsService,
        readonly router: Router,
        readonly store: Store<StateWithSiteContext>,
        pageLayoutService: PageLayoutService,
        globalMessageService: GlobalMessageService,
        scrollConfig: ViewConfig,
    ) {
        super(pageLayoutService, productListComponent, globalMessageService, scrollConfig);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.plpCommunication.setDisplayPlpSkeleton(true);
        this.sikoViewMode = sessionStorage.getItem('sikoViewMode');

        if (this.sikoViewMode !== null) {
            this.plpCommunication.setViewMode(this.sikoViewMode);
        }

        /**
         * Determining which disponibility should be displayed on each product in the ProductGridItemComponent.
         * Cases:
         *  No store is selected = Disponibility of main store is displayed (Cimelice).
         *  One store is selected = Disponibility of selected store is displayed.
         *  More than one store is selected = Disponibility of main store is displayed (Cimelice).
         */
        this.queryParamsSubscription = this.activatedRoute.queryParams
            .pipe(
                filter(isNotUndefined),
            )
            .subscribe((params: Params) => {
                if (!this.helperFunctionsService.isObjectEmpty(params) && params.hasOwnProperty('query')) {
                    const paramsArr = params.query?.split(':') as string[];
                    const count =
                        this.helperFunctionsService.getCountOfStringOccurencesInArray(paramsArr, 'availableInStores');

                    this.isStoreDisponibility = count >= 1;
                }
                else {
                    this.isStoreDisponibility = false;
                }
            });
    }

    resolveAdBannersAsync(breadcrumbs: any[]): Observable<{ firstAdBanner?: CmsAdBannerContextComponent; secondAdBanner?: CmsAdBannerContextComponent }> {
        if (breadcrumbs.length === 0) {
            return of({});
        }

        return this.adBannerCategories$.pipe(
            take(1),
            map((components: CmsAdCategoriesComponent[]) => {
                let firstAdBanner: CmsAdBannerContextComponent | undefined;
                let secondAdBanner: CmsAdBannerContextComponent | undefined;

                const reversedCategories = [...breadcrumbs].reverse();

                for (const category of reversedCategories) {
                    const component = components.find(comp => comp.category === category.categoryCode);

                    if (!component) {
                        continue;
                    }

                    if (component.firstAdBanners) {
                        firstAdBanner = this.assignBannerImages(firstAdBanner, component.firstAdBanners);
                    }

                    if (component.secondAdBanners) {
                        secondAdBanner = this.assignBannerImages(secondAdBanner, component.secondAdBanners);
                    }

                    if (!!firstAdBanner?.listViewImage && !!firstAdBanner.gridViewImage &&
                        !!secondAdBanner?.listViewImage && !!secondAdBanner.gridViewImage) {
                        break;
                    }
                }

                return {
                    firstAdBanner,
                    secondAdBanner
                };
            })
        );
    }

    assignBannerImages(target: CmsAdBannerContextComponent | undefined,
        source: CmsAdBannerContextComponent): CmsAdBannerContextComponent | undefined {
        if (!target) {
            return source;
        }

        if (!target.listViewImage) {
            target = {
                ...target,
                listViewImage: source.listViewImage,
            };
        }

        if (!target.gridViewImage) {
            target = {
                ...target,
                gridViewImage: source.gridViewImage,
            };
        }

        return target;
    }

    showFilter(disableShowFilter: boolean): void {
        if (disableShowFilter) {
            return;
        }

        this.plpCommunication.setIsSideMenuOpen(true);
        this.renderer.addClass(document.body, 'no-scroll');
    }

    toggleMobileSorting(): void {
        this.showMobileSorting = !this.showMobileSorting;
    }

    getViewMode(): Observable<string> {
        return this.plpCommunication.viewMode$;
    }

    isShowFilterButtonDisabled(model: SikoProductSearchPage): boolean {
        if (model.facets) {
            return model.facets.length === 0;
        }

        return true;
    }

}
