<form *ngIf='form' [formGroup]='form' (submit)='formSubmitSubject$.next(undefined)'>
  <ng-container *ngIf='form.get("file")?.value.length === 0'>
    <div class='siko-fs-14'>
      {{ 'cartShared.importEntriesDialog.importProductFileDetails' | cxTranslate }}
    </div>
    <div class='pt-3 siko-fs-14'>
      {{ 'cartShared.importEntriesDialog.importProductFileInfo' | cxTranslate }}
    </div>
  </ng-container>
  <div class='upload-section'>
    <app-file-upload [formControl]="$any(form.get('file'))" [accept]='allowedTypes'>
    </app-file-upload>
  </div>
  <div class='form-errors' *ngIf='form.get("file")?.value.length !== 0'>
    <siko-form-errors
      [control]="$any(form.get('file'))"
      prefix='formErrors.file'
    ></siko-form-errors>
  </div>
  <ng-container
    *ngIf='form.get("file")?.value.length !== 0 && !form.get("file")?.errors && currentCartEntries > 0 else import'>
    <hr class='my-5'>
    <h3 class='not-allowed-merge mb-4'><span class='siko-color-grey-3'>{{'cartShared.importEntriesDialog.notAllowedMergeCarts' | cxTranslate}}</span>
      {{'cartShared.importEntriesDialog.actionWithCurrentCart' | cxTranslate}}
    </h3>
    <div class='d-flex flex-column flex-sm-row align-items-center justify-content-between mt-3'>
      <div class='name-saved-cart w-100'>
        <siko-input-field
          name='savedCartName'
          placeholder='{{actualDate | cxDate : "d.M.Y H:mm"}}'
          label="{{ 'cartShared.importEntriesDialog.nameOfSavedCart' | cxTranslate }}"
          [control]='$any(savedCartForm.controls?.savedCartName)'
        >
        </siko-input-field>
      </div>
      <div class='saved-cart w-100'>
        <siko-button
          (clickEvent)='saveEntriesAndImport()'
          [text]='"cartShared.importEntriesDialog.saveAndImport" | cxTranslate'
          size='md'
          iconPosition='after'
          iconSvg='checkout/arrow_white'
          mode='cart'
          [block]=true
        >
        </siko-button>
      </div>
    </div>
    <div class='mt-4 text-center'>
      <siko-button
        [disabled]="form.get('file')?.status === 'PENDING' || form.get('file')?.value.length === 0"
        (clickEvent)='removeEntriesAndImport()'
        [text]='"cartShared.importEntriesDialog.deleteAndImport" | cxTranslate'
        size='md'
        iconPosition='after'
        iconSvg='cart/arrow-next-black'
        mode='light'
      >
      </siko-button>
    </div>
  </ng-container>
  <ng-template #import>
    <div class='mt-4 text-center'>
      <siko-button
        [disabled]="form.get('file')?.status === 'PENDING' || form.get('file')?.value.length === 0 || form.get('file')?.errors !== null"
        (clickEvent)='formSubmitSubject$.next(undefined)'
        [text]='"cartShared.importEntriesDialog.readyToImport" | cxTranslate'
        size='md'
        iconPosition='before'
        iconCode='upload'
        mode='cart'
      >
      </siko-button>
    </div>
  </ng-template>
</form>
