<ng-container *ngIf='cartModificationHistory$ | async as modifications'>
  <app-message
    *ngIf='modifications.length'
    (closeMessage)='clearWarnings()'
    [accordionText]="'sikoQuickOrder.quickOrderList.warnings.reviewWarnings' | cxTranslate"
    [text]="'sikoOrder.orderDetail.reorderWarningMessage' | cxTranslate"
    [type]='globalMessageType.MSG_TYPE_WARNING'
    class='quick-order-warnings-message'
  >
    <ul class='quick-order-warnings'>
      <li *ngFor='let warning of modifications' class='quick-order-warning-item'>
        <span>
          {{
            'sikoQuickOrder.quickOrderList.warnings.productWasReduced' | cxTranslate : {
              name: warning?.entry?.product?.name,
              code: warning?.entry?.product?.code,
              quantityAdded: warning.quantityAdded
            }
          }}
        </span>
      </li>
    </ul>
  </app-message>
</ng-container>

<ng-container *ngIf='order'>
  <div class='row' *ngIf='!orderConfirmation'>
    <div class='col'>
      <h2 class='heading'><span>{{ 'siko.order' | cxTranslate }}</span> {{ order.code }}</h2>
    </div>
  </div>
  <div class='container-fluid description my-2 p-4 d-none d-sm-block'>
    <div class='row'>
      <div class='col-sm-12 col-no-padding' [ngClass]='!orderConfirmation ? "col-md-8" : "col-md-12"'>
        <div class='row'>
          <div class='col-sm-4'>
            <div class='d-block'>
              <div class='section' *ngIf='!orderConfirmation'>
                <span class='label'>ID</span>
                <span class='d-block'>{{ order.code }}</span>
              </div>
              <div class='section'>
              <span class='label'>
              {{ 'siko.orderPoNumber' | cxTranslate }}
              </span>
                <span class='d-block'>{{ order.purchaseOrderNumber }}</span>
              </div>
              <div class='section'>
              <span class='label'>
              {{ 'siko.orderPlacedBy' | cxTranslate }}
              </span>
                <span class='d-block'>{{ order.orgCustomer?.name }}</span>
              </div>
            </div>
          </div>
          <div class='col-sm-4'>
            <div class='d-block'>
              <ng-container *ngIf='isOrderWithShipment; else notShipment'>
                <div class='section'>
                  <span class='label'>{{ 'siko.datePlaced' | cxTranslate }}</span>
                  <span class='d-block'>{{ order.created | cxDate : "dd.MM.YYYY HH:mm" }}</span>
                </div>
              </ng-container>

              <ng-template #notShipment>
                <div class='section item-group'>
                  <span class='date-line'></span>
                  <span class='label'>{{ 'siko.datePlaced' | cxTranslate }}</span>
                  <span class='d-block'>{{ order.created | cxDate : "dd.MM.YYYY HH:mm" }}</span>
                </div>
                <div class='section item-group'>
                  <span class='label'>
                  {{ 'sikoOrder.sapOrderDocuments.plannedCollectionDate' | cxTranslate }}
                  </span>
                  <span class='d-block'>{{ order.sikoPreferredPickupDate | cxDate : "dd.MM.YYYY" }}</span>
                </div>
              </ng-template>
            </div>
          </div>
          <div class='col-sm-4'>
            <div class='d-block'>
              <div class='section'>
                <span class='label'>{{ 'siko.totalPrice' | cxTranslate }}</span>
                <span class='d-block'>
                  {{ order.totalPriceWithTax | sikoPrice }}
                </span>
              </div>
              <div class='section'>
              <span class='label'>
              {{ 'siko.orderStatus' | cxTranslate }}
              </span>
                <app-order-status-badge
                  mode='COMMERCE_ORDER'
                  [status]='order.status'
                >{{ 'sikoOrder.orderStatusTrans.' + order.status | cxTranslate }}
                </app-order-status-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='col-sm-12 col-md-4 actions'>
        <ng-container *ngIf='displayReorderButton && !orderConfirmation'>
          <app-reorder-button
            [orderCode]='order.code'
          ></app-reorder-button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class='container-fluid description my-2 d-block d-sm-none'>
    <table aria-label='Table with order details'>
      <tr *ngIf='!orderConfirmation'>
        <th scope='row'>ID</th>
        <td>
          {{ order.code }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'siko.orderPoNumber' | cxTranslate }}
        </th>
        <td>
          {{ order.purchaseOrderNumber }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'siko.datePlaced' | cxTranslate }}
        </th>
        <td>
          {{ order.created | cxDate : "dd.MM.YYYY HH:mm" }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'sikoOrder.sapOrderDocuments.plannedCollectionDate' | cxTranslate }}
        </th>
        <td>
          {{ order.sikoPreferredPickupDate | cxDate : "dd.MM.YYYY" }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'siko.totalPrice' | cxTranslate }}
        </th>
        <td>
          {{ order.totalPriceWithTax | sikoPrice }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'siko.orderStatus' | cxTranslate }}
        </th>
        <td>
          <app-order-status-badge
            mode='COMMERCE_ORDER'
            [status]='order.status'
          >
            {{ 'sikoOrder.orderStatusTrans.' + order.status | cxTranslate }}
          </app-order-status-badge>
        </td>
      </tr>
    </table>
    <ng-container *ngIf='displayReorderButton && !orderConfirmation'>
      <app-reorder-button [orderCode]='order.code'></app-reorder-button>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf='sikoSapOrder'>
  <div class='row'>
    <div class='col'>
      <h2 class='heading'><span>{{ 'siko.sapOrder' | cxTranslate }}</span> {{ sikoSapOrder.orderId }}</h2>
    </div>
  </div>
  <div class='container-fluid description my-2 p-4 d-none d-sm-block'>
    <div class='row'>
      <div class='col-sm-12 col-md-8 col-no-padding'>
        <div class='row'>
          <div class='col-sm-4'>
            <div class='d-block'>
              <div class='section'>
                <span class='label'>{{ 'siko.sapCodeTitle' | cxTranslate }}</span>
                <span
                  class='d-block'>{{ sikoSapOrder.order?.sapOrdersString ? sikoSapOrder.order?.sapOrdersString : sikoSapOrder.orderId }}</span>
              </div>
              <div class='section'>
              <span class='label'>
              {{ 'siko.orderPoNumber' | cxTranslate }}
              </span>
                <span class='d-block'>{{ sikoSapOrder.purchaseOrderNumber }}</span>
              </div>
              <div *ngIf='sikoSapOrder.order' class='section'>
              <span class='label'>
              {{ 'siko.orderPlacedBy' | cxTranslate }}
              </span>
                <span class='d-block'>{{ sikoSapOrder.order.orgCustomer?.name }}</span>
              </div>
            </div>
          </div>
          <div class='col-sm-4'>
            <div class='d-block'>
              <ng-container *ngIf='isOrderWithShipment; else notShipment'>
                <div class='section'>
                  <span class='label'>{{ 'siko.datePlaced' | cxTranslate }}</span>
                  <span class='d-block'>{{ sikoSapOrder.orderCreationDate | cxDate : "dd.MM.YYYY HH:mm" }}</span>
                </div>
              </ng-container>

              <ng-template #notShipment>
                <div class='section item-group'>
                  <span class='date-line'></span>
                  <span class='label'>{{ 'siko.datePlaced' | cxTranslate }}</span>
                  <span class='d-block'>{{ sikoSapOrder.orderCreationDate | cxDate : "dd.MM.YYYY HH:mm" }}</span>
                </div>
                <div class='section item-group'>
                  <span class='label'>
                  {{ 'sikoOrder.sapOrderDocuments.plannedCollectionDate' | cxTranslate }}
                  </span>
                  <span class='d-block'>{{ sikoSapOrder.preferredPickupDate | cxDate : "dd.MM.YYYY" }}</span>
                </div>
              </ng-template>
            </div>
          </div>
          <div class='col-sm-4'>
            <div class='d-block'>
              <div class='section'>
                <span class='label'>{{ 'siko.totalPrice' | cxTranslate }}</span>
                <span class='d-block'>
                  {{ sikoSapOrder.priceTotalWithTransportPrice | sikoPrice }}
                </span>
              </div>
              <div class='section'>
              <span class='label'>
              {{ 'siko.orderStatus' | cxTranslate }}
              </span>
                <app-order-status-badge
                  mode='SAP_ORDER'
                  [status]='sikoSapOrder.orderStatus'>
                  {{ 'sikoOrder.sapOrderStatus.order.' + sikoSapOrder.orderStatus?.replace('-', '') | cxTranslate }}
                </app-order-status-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='col-12 col-sm-6 col-lg-4 actions'>
        <ng-container *ngIf='displaySapOrderAdditionalPaymentComponent'>
          <app-sap-order-additional-payment
            [reorderButtonDisplayed]='displayReorderButton'
          ></app-sap-order-additional-payment>
        </ng-container>
        <ng-container *ngIf='displayReorderButton'>
          <app-reorder-button
            [displayBlock]='true'
            [orderCode]='sikoSapOrder.order?.code'></app-reorder-button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class='container-fluid description my-2 d-block d-sm-none'>
    <table aria-label='Table with order details'>
      <tr>
        <th scope='row'>ID</th>
        <td>
          {{ sikoSapOrder.orderId }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'siko.orderPoNumber' | cxTranslate }}
        </th>
        <td>
          {{ sikoSapOrder.purchaseOrderNumber }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'siko.datePlaced' | cxTranslate }}
        </th>
        <td>
          {{ sikoSapOrder.orderCreationDate | cxDate : "dd.MM.YYYY HH:mm" }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'sikoOrder.sapOrderDocuments.plannedCollectionDate' | cxTranslate }}
        </th>
        <td>
          {{ sikoSapOrder.preferredPickupDate | cxDate : "dd.MM.YYYY" }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'siko.totalPrice' | cxTranslate }}
        </th>
        <td>
          {{ sikoSapOrder.priceTotalWithTransportPrice | sikoPrice }}
        </td>
      </tr>
      <tr>
        <th scope='row'>
          {{ 'siko.orderStatus' | cxTranslate }}
        </th>
        <td>
          <app-order-status-badge
            mode='SAP_ORDER'
            [status]='sikoSapOrder.orderStatus'>
            {{ 'sikoOrder.sapOrderStatus.order.' + sikoSapOrder.orderStatus?.replace('-', '') | cxTranslate }}
          </app-order-status-badge>
        </td>
      </tr>
    </table>
    <ng-container *ngIf='displaySapOrderAdditionalPaymentComponent'>
      <app-sap-order-additional-payment
        [reorderButtonDisplayed]='displayReorderButton'
      ></app-sap-order-additional-payment>
    </ng-container>
    <ng-container *ngIf='displayReorderButton'>
      <app-reorder-button
        [orderCode]='sikoSapOrder.order?.code'
        [displayBlock]='true'
      ></app-reorder-button>
    </ng-container>
  </div>
</ng-container>
