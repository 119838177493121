<div class='col-12 col-lg-12 pb-5 pl-15 pl-md-0' *ngIf='model$ | async as model'>
  <ng-container *ngIf='subTitle; else title'>
    <h2>{{model.category?.name}}</h2>
  </ng-container>
  <ng-template #title>
    <h1>{{model.category?.name}}</h1>
  </ng-template>
  <ng-container *ngIf='!isShowCategoryDescription'>
    <ng-container *ngIf='model.category?.summary as categorySummary'>
      <div class='short-text' *ngIf='!model.virtualCategoryName'>
        <span [innerHTML]="categorySummary | sikoTruncateText: [200, '...']"></span>
        <siko-link
          *ngIf='categorySummary.length > 200'
          [phoneLink]='true'
          [mode]='$any("default")'
          [style]='$any("primary")'
          [disableLink]=true
          label='{{"siko.continueToRead"|cxTranslate}}'
          (click)='toggleCategoryDescription()'
        >
        </siko-link>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf='isShowCategoryDescription'>
    <div class='long-text'>
      <span [innerHTML]='model.category?.summary'></span>
    </div>
  </ng-container>
</div>
