<ng-container *ngIf='viewMode$ | async as viewMode'>
  <button
    [attr.data-testid]='$any("grid-view-button")'
    class="btn cx-product-layout"
    [ngClass]='viewMode === ViewModes.Grid ? "active" : ""'
    (click)="changeMode(ViewModes.Grid)"
    tabindex="0"
    attr.aria-label="{{('productView.gridView' | cxTranslate) }}"
  >
    <img alt='grid icon' [src]='"plp/grid.svg" | sikoIconAsset'>
  </button>
  <button
    [attr.data-testid]='$any("row-view-button")'
    [ngClass]='viewMode === ViewModes.List ? "active" : ""'
    class="btn cx-product-layout"
    (click)="changeMode(ViewModes.List)"
    tabindex="0"
    attr.aria-label="{{('productView.listView' | cxTranslate)}}"
  >
    <img alt='list icon' [src]='"plp/list.svg" | sikoIconAsset'>
  </button>
</ng-container>
