<ng-container *ngIf='quantitySeen$ | async as quantitySeen'>
  <div class='container-fluid'>
    <ng-container *ngIf='total$ | async as total'>
      <div class='mb-5 justify-content-center'>
        <div class='col-12 text-center'>
    <span class='siko-font-primary-semiBold siko-fs-14 siko-color-grey-3' [attr.data-testId]='$any("infinite-scroll-seen-quantity")'>
      {{'siko.seenQuantityOfTotal' | cxTranslate: { count: total, quantity: quantitySeen } }}
    </span>
        </div>
      </div>
      <ng-container *ngIf='quantitySeen < total && displayInfiniteScroll()'>
        <ng-container *ngIf='{observableValue : loadingNewProducts$ | async } as loadingNewProducts'>
          <div class='row load-more-btn-container justify-content-center'>
            <div class='col-6 col-sm-4 col-md-3 mx-auto'
                 [ngClass]='loadingNewProducts.observableValue ? "loading-products" : ""'>
              <ng-container *ngIf='loadingNewProducts.observableValue else notLoading'>
                <app-duck-loader [miniMode]='true'></app-duck-loader>
              </ng-container>
              <ng-template #notLoading>
                <div class='load-more-btn'>
                  <siko-button
                    testId='load-more-button'
                    [size]='$any("md")'
                    [text]='"siko.plpLoadMore"| cxTranslate'
                    (click)='loadMore()'
                  >
                  </siko-button>
                </div>
              </ng-template>
            </div>
          </div>
          <div class='row' [ngClass]='loadingNewProducts.observableValue ? "animate" : ""'>
            <div class='col-xl-3 col-md-4 col-6'>
              <div class='shadow-card'></div>
            </div>
            <div class='col-xl-3 col-md-4 col-6'>
              <div class='shadow-card'></div>
            </div>
            <div class='col-xl-3 col-md-4 d-none d-md-block'>
              <div class='shadow-card'></div>
            </div>
            <div class='col-xl-3 d-none d-xl-block'>
              <div class='shadow-card'></div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
