import { Injectable } from '@angular/core';
import { ProductListComponentService, SearchCriteria } from '@spartacus/storefront';

@Injectable({ providedIn: 'root' })
export class SikoProductListComponentService extends ProductListComponentService {
    protected route(queryParams: SearchCriteria): void {
        this.router.navigate([], {
            queryParams: {...queryParams, currentPage: null},
            queryParamsHandling: 'merge',
            relativeTo: this.activatedRoute,
        });
    }

}
