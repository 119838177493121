import { Injectable } from '@angular/core';
import { BONUS_ACTION, BONUS_COEFFICIENT } from '../../../constants/bonus-program-constants';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SikoBonusProgramRecordDialogComponent } from '@siko/features/my-account/bonus-program/components/bonus-program-record-dialog/bonus-program-record-dialog.component';
import { SikoDialogService } from '@siko/shared';

@Injectable({
    providedIn: 'root',
})
export class SikoBonusProgramService {

    constructor(
        readonly sikoDialogService: SikoDialogService
    ) { }

    getBonusCoefficient(coefficient: number): BONUS_COEFFICIENT {
        if (coefficient <= 0.4) {
            return BONUS_COEFFICIENT.BRONZE;
        }
        else if (coefficient > 0.4 && coefficient <= 0.7) {
            return BONUS_COEFFICIENT.SILVER;
        }

        return BONUS_COEFFICIENT.GOLD;
    }

    openBonusRecordDetail(code: string | undefined): void {
        const modalRef: NgbModalRef = this.sikoDialogService.open(SikoBonusProgramRecordDialogComponent, {
            centered: true,
            size: 'lg',
            windowClass: 'siko-dialog-window',
        });

        const modalInstance: SikoBonusProgramRecordDialogComponent = modalRef.componentInstance as SikoBonusProgramRecordDialogComponent;

        modalInstance.code = code;
    }

    isAllowedBonusRecordDetail(action?: string): boolean {
        return action === BONUS_ACTION.INVOICED || action === BONUS_ACTION.ORDERED;
    }

    isDecreasedPoints(action?: string): boolean {
        return action === BONUS_ACTION.ORDERED || action === BONUS_ACTION.MANUAL_DECREASE;
    }

    showBonusNote(action?: string) {
        return action?.includes('MANUAL_');
    }

}
