import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GlobalMessageComponent } from '@spartacus/storefront';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-global-message',
    templateUrl: './global-message.component.html',
    styleUrls: ['./global-message.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({
                    transform: 'translateY(120%)',
                    opacity: 0,
                }),
                animate('400ms ease-in', style({
                    transform: 'translateY(0%)',
                    opacity: 1,
                })),
            ]),
            transition(':leave', [
                style({
                    position: 'absolute',
                }),
                animate('400ms ease-in', style({ transform: 'translateY(-100%)' })),
            ]),
        ]),
    ],
})

export class SikoGlobalMessageComponent extends GlobalMessageComponent {

}
