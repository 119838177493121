import { ApplicationRef, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Injectable({
    providedIn: 'root',
})
export class SikoDialogService {
    private modals: NgbModalRef[] = [];

    constructor(
        private ngbModalService: NgbModal,
        protected applicationRef: ApplicationRef,
    ) {

        const originalClose = NgbModalRef.prototype.close;
        const originalDismiss = NgbModalRef.prototype.dismiss;

        NgbModalRef.prototype.close = function(reason: string): void {
            const backdropElement = document.querySelector('.modal-backdrop');
            const modalElement = document.querySelector('.siko-dialog-window');

            if (backdropElement) {
                backdropElement.classList.remove('show');
            }

            if (modalElement) {
                modalElement.classList.remove('show');
            }

            if (modalElement) {
                setTimeout(() => {
                    originalClose.call(this, reason);
                }, 300);
            }
            else {
                originalClose.call(this, reason);
            }
        };

        NgbModalRef.prototype.dismiss = function(reason: string): void {
            const backdropElement = document.querySelector('.modal-backdrop');
            const modalElement = document.querySelector('.siko-dialog-window');

            if (backdropElement) {
                backdropElement.classList.remove('show');
            }

            if (modalElement) {
                modalElement.classList.remove('show');
            }

            if (modalElement) {
                setTimeout(() => {
                    originalDismiss.call(this, reason);
                }, 300);
            }
            else {
                originalDismiss.call(this, reason);
            }
        };
    }

    protected get rootComponent(): any {
        return this.applicationRef?.components?.[0]?.location?.nativeElement;
    }

    open(content: any, options?: NgbModalOptions): NgbModalRef {
        options = { container: this.rootComponent, ...options };

        const activeModal: NgbModalRef = this.ngbModalService.open(content, options);

        this.modals.push(activeModal);
        this.handleModalRemoveEvents(activeModal);

        return activeModal;
    }

    protected handleModalRemoveEvents(modal: NgbModalRef): void {
        modal.result.finally(() => {
            this.modals = this.modals.filter((m: NgbModalRef) => m !== modal);
        });
    }

    getActiveModal(): NgbModalRef | null {
        const modal = this.modals[this.modals.length - 1];
        return modal ? modal : null;
    }

    dismissActiveModal(reason?: any): void {
        const modal: NgbModalRef | null = this.getActiveModal();

        if (modal) {
            modal.dismiss(reason);
        }
    }

    dismissAll(reason?: any): void {
        this.ngbModalService.dismissAll(reason);
    }

    closeActiveModal(reason?: any): void {
        const modal: NgbModalRef | null = this.getActiveModal();

        if (modal) {
            modal.close(reason);
        }
    }
}
