<ng-container *ngIf='sikoProduct && sikoProduct.potentialPromotions'>
  <ng-container *ngFor='let promotion of sikoProduct.potentialPromotions'>
    <div *ngIf='promotion.title || promotion.description' class='outer-align'>
      <div class='outer-wrapper px-32 py-16 text-center'>
        <div>
          <siko-text
            text='{{ promotion.title }}'
            fontFamily='secondary'
            fontSize='18'
            fontWeight='700'
            color='base-red'
            lineHeight='120'
          ></siko-text>
        </div>
        <div>
          <siko-text
            text='{{ promotion.description }}'
            fontFamily='primary'
            fontSize='14'
            fontWeight='600'
            color='base-black'
            lineHeight='150'
            class='pt-1'
          ></siko-text>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

