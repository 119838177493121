<ng-container *ngIf='isSapOrderSummary else notSapOrderSummary'>
  <ng-container *ngIf='sapOrder'>
    <div class='d-flex justify-content-between'>
      <p> {{ 'siko.weight' | cxTranslate }} </p>
      {{ sapOrder.totalWeight | sikoCxNumeric: '1.0-2' }} kg
    </div>

    <div class='d-flex justify-content-between'>
      <p> {{ 'siko.subtotal' | cxTranslate }} <span class='light-text'>({{'siko.withoutVat' | cxTranslate}})</span>
      </p>
      {{ sapOrder.priceNet | sikoPrice }}
    </div>

    <ng-container *ngIf='sapOrder.discount?.value != 0'>
      <div class='d-flex justify-content-between siko-color-success-primary'>
        <p> {{ 'siko.orderDiscounts' | cxTranslate }} </p>
        -{{ sapOrder.discount | sikoPrice }}
      </div>
    </ng-container>

    <div [ngClass]='{"d-flex justify-content-between": true, "siko-color-success-primary": hasFreeDelivery}'>
      <p> {{ 'siko.shipping' | cxTranslate }} </p>
      {{ sapOrder.deliveryPrice?.value !== 0 ? (sapOrder.deliveryPrice | sikoPrice) : "siko.free" | cxTranslate }}
    </div>

    <div class='d-flex justify-content-between'>
      <p> {{ 'siko.totalVAT' | cxTranslate }} </p>
      {{ sapOrder.vat | sikoPrice }}
    </div>
    <div class='d-flex justify-content-between total'>
      <h3> {{ 'siko.orderTotal' | cxTranslate }} </h3>
      <h3> {{ sapOrder.priceTotalWithTransportPrice | sikoPrice }} </h3>
    </div>
    <ng-container *ngIf='sapOrder.discount?.value != 0'>
      <div class='d-flex justify-content-end total-sale'>
        {{'siko.savedAmountMessage' | cxTranslate: {savedAmount: sapOrder.discount | sikoPrice} }}
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #notSapOrderSummary>
  <ng-container *ngIf='cart && isCartSummary else orderSummary'>
    <div class='d-flex justify-content-between'>
      <p> {{ 'siko.weight' | cxTranslate }} </p>
      <p [attr.data-testId]='$any("summary-totalWeight")'> {{ cart.sikoTotalWeight | sikoCxNumeric : '1.0-2' }} kg</p>
    </div>

    <div class='d-flex justify-content-between'>
      <p> {{ 'siko.subtotal' | cxTranslate }} <span class='light-text'>({{'siko.withoutVat' | cxTranslate}})</span>
      </p>
      <p [attr.data-testId]='$any("summary-subTotal")'> {{ cart.subTotal | sikoPrice }} </p>
    </div>

    <ng-container *ngIf='cart.totalDiscounts?.value !== 0'>
      <div class='d-flex justify-content-between siko-color-success-primary'>
        <p> {{ 'siko.orderDiscounts' | cxTranslate }} </p>
        <p [attr.data-testId]='$any("summary-discount")'> -{{ cart.totalDiscounts | sikoPrice }}</p>
      </div>
    </ng-container>
    <div *ngIf='cart.deliveryCost || hasFreeDelivery'
         [ngClass]='{"d-flex justify-content-between": true, "siko-color-success-primary": hasFreeDelivery}'>
      <p> {{ 'siko.shipping' | cxTranslate }} </p>
      <p [attr.data-testId]='$any("summary-deliveryCost")'> {{ hasFreeDelivery || cart.deliveryCost?.value === 0 ?
        ("siko.free" | cxTranslate) : cart.deliveryCost | sikoPrice }}
      </p>
    </div>

    <div class='d-flex justify-content-between'>
      <p> {{ 'siko.totalVAT' | cxTranslate }} </p>
      <p [attr.data-testId]='$any("summary-totalTax")'> {{ cart.totalTax | sikoPrice }} </p>
    </div>
    <div class='d-flex flex-wrap justify-content-between total' [ngClass]='{"final" : isOrderSynced}'>
      <div class='d-inline-block'>{{ 'siko.orderTotal' | cxTranslate }}&nbsp;({{'siko.withVat' | cxTranslate}})</div>
      <div class='ml-auto' *ngIf='cartTotal'>
        <app-price [attr.data-testId]='$any("summary-orderTotal")' [price]='cartTotal' [finalPriceBig]='isOrderSynced'></app-price>
      </div>
    </div>
  </ng-container>
  <ng-template #orderSummary>
    <ng-container *ngIf='order'>
      <div class='d-flex justify-content-between'>
        <p> {{ 'siko.weight' | cxTranslate }} </p>
        <p [attr.data-testId]='$any("order-summary-weight")'> {{ order.sikoTotalWeight | sikoCxNumeric : "1.0-2" }} kg</p>
      </div>

      <div class='d-flex justify-content-between'>
        <p> {{ 'siko.subtotal' | cxTranslate }} </p>
        <p [attr.data-testId]='$any("order-summary-subTotal")'> {{ order.subTotal | sikoPrice }} </p>
      </div>

      <ng-container *ngIf='order.totalDiscounts?.value !== 0'>
        <div class='d-flex justify-content-between siko-color-success-primary'>
          <p> {{ 'siko.orderDiscounts' | cxTranslate }} </p>
          <p [attr.data-testId]='$any("order-summary-discount")'> -{{ order.totalDiscounts | sikoPrice }}</p>
        </div>
      </ng-container>

      <div *ngIf='order.deliveryCost'
           [ngClass]='{"d-flex justify-content-between": true, "siko-color-success-primary": hasFreeDelivery}'>
        <p> {{ 'siko.shipping' | cxTranslate }} </p>
        <span [attr.data-testId]='$any("order-summary-deliveryCost")'>{{ order.deliveryCost.value !== 0 ? ( order.deliveryCost | sikoPrice ) : "siko.free" | cxTranslate }}</span>
      </div>

      <div class='d-flex justify-content-between'>
        <p> {{ 'siko.totalVAT' | cxTranslate }} </p>
        <p [attr.data-testId]='$any("order-summary-totalTax")'> {{ order.totalTax | sikoPrice }} </p>
      </div>

      <div class='d-flex justify-content-between total'>
        <div>{{ 'siko.orderTotal' | cxTranslate }}</div>
        <div [attr.data-testId]='$any("order-summary-totalPrice")'>{{ order.totalPriceWithTax | sikoPrice }}</div>
      </div>
    </ng-container>
  </ng-template>

</ng-template>
