import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
// TODO:Spartacus - 'handleVoucherOperationError has been removed and is no longer part of the public API. Please use new methods in BadVoucherRequestHandler
import { BadRequestHandler, GlobalMessageService, GlobalMessageType, Occ, Priority } from '@spartacus/core';
import ErrorModel = Occ.ErrorModel;
import { SikoDialogService } from '@siko/shared';

@Injectable({
    providedIn: 'root',
})
export class SikoValidationErrorHandler extends BadRequestHandler {

    constructor(
        readonly sikoDialogservice: SikoDialogService,
        globalMessageService: GlobalMessageService,
        @Inject(PLATFORM_ID) protected platformId?: Object,
    ) {
        super(globalMessageService, platformId);
    }

    handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
        const handled =
            this.handleCartRestoreErrors(request, response) ||
            this.handleCartNotFoundReloadRequiredErrors(request, response) ||
            this.handleOrgUserNotFoundError(request, response);

        if (!handled) {
            super.handleError(request, response);
        }
    }

    getPriority(): Priority {
        return Priority.HIGH;
    }

    protected handleValidationError(request: HttpRequest<any>, response: HttpErrorResponse): void {
        if (!this.handleInvalidCodeError(request, response)) {
            super.handleValidationError(request, response);
        }
    }

    protected handleInvalidCodeError(request: HttpRequest<any>, response: HttpErrorResponse): boolean {
        const validationErrors = this.getValidationErrors(response);

        if (validationErrors.length === 0 || !this.anyInvalidCodeError(validationErrors)) {
            return false;
        }

        let errorMsg;

        if (request.urlWithParams.includes('pageType=ProductPage')) {
            errorMsg = 'httpHandlers.notFound.product';
        }
        else if (request.urlWithParams.includes('pageType=CategoryPage')) {
            errorMsg = 'httpHandlers.notFound.category';
        }

        if (errorMsg !== undefined) {
            this.globalMessageService.add({ key: errorMsg }, GlobalMessageType.MSG_TYPE_ERROR);

            return true;
        }

        return false;
    }

    protected getValidationErrors(response: HttpErrorResponse): ErrorModel[] {
        return super
            .getErrors(response)
            .filter((e: ErrorModel) => e.type === 'ValidationError');
    }

    protected anyInvalidCodeError(errors: ErrorModel[]): boolean {
        const invalidCodeErrors = errors.filter(
            (e: ErrorModel) => e.reason === 'invalid' && e.subject === 'code',
        );

        return invalidCodeErrors.length > 0;
    }

    protected handleCartRestoreErrors(request: HttpRequest<any>, response: HttpErrorResponse): boolean {
        const cartRestoreRequest = response.url?.includes('/restoresavedcart');

        if (!cartRestoreRequest) {
            return false;
        }

        const noEntriesError: ErrorModel | undefined = super
            .getErrors(response)
            .find((e: ErrorModel) =>
                e.type === 'CommerceSaveCartError' &&
                e.message === 'No entries were restored from saved cart');

        if (noEntriesError) {
            const errorMsg = 'httpHandlers.cart.noRestoredEntries';

            this.globalMessageService.add({ key: errorMsg }, GlobalMessageType.MSG_TYPE_ERROR);

            return true;
        }

        return false;
    }

    protected handleOrgUserNotFoundError(request: HttpRequest<any>, response: HttpErrorResponse): boolean {
        if (!response.url?.includes('/orgCustomers')) {
            return false;
        }

        const noEntriesError: ErrorModel | undefined = super.getErrors(response)
            .find((e: ErrorModel) =>
                e.type === 'UnknownIdentifierError' &&
                e.message?.includes('Cannot find user with propertyValue'));

        if (noEntriesError) {
            const errorMsg = 'httpHandlers.userUidNotFound';
            const regex = /propertyValue\s+'(\d+)'/;
            const match = noEntriesError.message?.match(regex);
            let uid;

            if (match !== null && match !== undefined) {
                [, uid] = match;
            }
            else {
                uid = 'undefined';
            }

            const params = { uid };

            this.globalMessageService.add(
                {
                    key: errorMsg,
                    params
                }
                , GlobalMessageType.MSG_TYPE_ERROR
            );

            return true;
        }

        return false;
    }

    protected handleCartNotFoundReloadRequiredErrors(request: HttpRequest<any>, response: HttpErrorResponse): boolean {
        const reloadRequiredError: ErrorModel | undefined = super
            .getErrors(response)
            .find((e: ErrorModel) => e.type === 'Sikob2bCartNotFoundReloadRequiredError');

        if (reloadRequiredError) {
            const errorMsg = 'httpHandlers.cart.reloadCartRequired';

            this.globalMessageService.add({ key: errorMsg }, GlobalMessageType.MSG_TYPE_ERROR);
            this.sikoDialogservice.closeActiveModal('Reload cart is required');

            return true;
        }

        return false;
    }

}
