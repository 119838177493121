import { Injectable } from '@angular/core';
import {
    BasePageMetaResolver,
    PageType,
    ProductSearchService,
    RoutingService,
    SearchPageMetaResolver,
    TranslationService
} from '@spartacus/core';
import {  Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SikoSearchPageMetaResolver extends SearchPageMetaResolver {

    constructor(
        protected routingService: RoutingService,
        protected productSearchService: ProductSearchService,
        protected translation: TranslationService,
        protected basePageMetaResolver: BasePageMetaResolver
    ) {
        super(routingService, productSearchService, translation, basePageMetaResolver);
        this.pageType = PageType.CONTENT_PAGE;
        this.pageTemplate = 'SearchResultsGridPageTemplate';
    }

    resolveTitle(): Observable<string> {
        return this.query$.pipe(
            switchMap((query: string) =>
                this.translation.translate('productListingPage.searchResultPageTitle', {
                    query
                }))
        );
    }

}
