import { InjectionToken, Provider } from '@angular/core';
import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer
} from '@ngrx/store';
import { StateUtils, SiteContextActions } from '@spartacus/core';
import {
    SikoSapOrderDetailState,
    SikoSapOrderState
} from '@siko/features/my-account/sap-order-detail/store/siko-sap-order-state';
import {
    sikoSapOrderReducer
} from '@siko/features/my-account/sap-order-detail/store/reducers/sap-order-reducer';

export const SIKO_SAP_ORDER_DATA = '[Sap-order] Siko Sap Order Data';

export const getReducers = (): ActionReducerMap<SikoSapOrderState> => ({
    sapOrder: StateUtils.loaderReducer<SikoSapOrderDetailState>(SIKO_SAP_ORDER_DATA, sikoSapOrderReducer),
});

export const REDUCER_TOKEN: InjectionToken<ActionReducerMap<SikoSapOrderState>> =
  new InjectionToken<ActionReducerMap<SikoSapOrderState>>('SikoSapOrderReducers');

export const REDUCER_PROVIDER: Provider = {
    provide: REDUCER_TOKEN,
    useFactory: getReducers
};

export const clearSikoSapOrderState = (
    reducer: ActionReducer<SikoSapOrderState>
): ActionReducer<SikoSapOrderState> => (state, action) => {
    if (action.type === SiteContextActions.LANGUAGE_CHANGE) {
        state = undefined;
    }

    // TODO add other actions if neccessary
    return reducer(state, action);
};

export const META_REDUCERS: MetaReducer[] = [clearSikoSapOrderState];
