<form (ngSubmit)='onSubmit()' [formGroup]='customerSelectionForm'>
  <label>
    <input
      required='true'
      #searchTerm
      type='text'
      formControlName='searchTerm'
      [attr.aria-label]="'asm.customerSearch.searchTerm.label' | cxTranslate"
      placeholder="{{ 'asm.customerSearch.searchTerm.label' | cxTranslate }}"
      (keydown.arrowdown)='focusFirstItem($any($event))'
      (keydown.end)='setSelectionEnd($any($event))'
    />
    <cx-form-errors
      [control]="customerSelectionForm.get('searchTerm')"
    ></cx-form-errors>
  </label>
  <button type='submit' [class.active]='selectedCustomer'>
    {{ 'asm.customerSearch.submit' | cxTranslate }}
  </button>
</form>

<div *ngIf='searchResults | async as results' class='asm-results' #resultList>
  <button
    #searchResultItem
    *ngFor='let result of results.entries; let i = index'
    [tabindex]='activeFocusedButtonIndex === i ? 0 : -1'
    [attr.aria-selected]='activeFocusedButtonIndex === i'
    [class.active]='activeFocusedButtonIndex === i'
    (keydown.arrowup)='focusPreviousChild($any($event))'
    (keydown.arrowdown)='focusNextChild($any($event))'
    (keydown.arrowright)='focusInputText($any($event))'
    (keydown.arrowleft)='focusInputText($any($event))'
    (keydown.enter)='selectCustomerFromList($any($event), result)'
    (keydown.escape)='closeResults($any($event))'
    (keydown.home)='focusInputText($any($event))'
    (keydown.end)='focusInputText($any($event))'
    (click)='selectCustomerFromList($any($event), result)'
  >
    <span class='result-name'>{{ result.name }}</span>
    <span class='result-id'>{{ result.uid }}</span>
  </button>

  <button
    #searchResultItem
    (keydown.escape)='closeResults($any($event))'
    (keydown.arrowright)='focusInputText($any($event))'
    (keydown.arrowleft)='focusInputText($any($event))'
    (keydown.home)='focusInputText($any($event))'
    (keydown.end)='focusInputText($any($event))'
    [class.active]='activeFocusedButtonIndex === 0'
    [attr.aria-selected]='activeFocusedButtonIndex === 0'
    *ngIf='
      !(searchResultsLoading$ | async) &&
      searchTerm.value.length >= 3 &&
      !!results.entries &&
      results.entries.length <= 0
    '
  >
    <span>{{ 'asm.customerSearch.noMatchResult' | cxTranslate }}</span>
  </button>
</div>

<div class='asm-results' *ngIf='searchResultsLoading$ | async'>
  <cx-dot-spinner
    aria-hidden='false'
    [attr.aria-label]="'common.loading' | cxTranslate"
  ></cx-dot-spinner>
</div>
