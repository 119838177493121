export const CREDIT_CARD_PAYMENT = 'CARD';

export const CHECKOUT_RECALCULATION_REQUIRED_ERR_MESSAGE = 'checkout.error.not.recalculated';
export const CHECKOUT_TERMS_CONFIRM_REQUIRED_ERR_MESSAGE = 'cart.term.unchecked';
export const CHECKOUT_INVALID_TOTAL_PRICE_ERR_MESSAGE = 'cart.totalPrice.invalid';

export enum CHECKOUT_PAYMENT_GATEWAY_STATUS {
  PAYMENT_STATUS_PENDING = 'PENDING',
  PAYMENT_STATUS_CANCELLED = 'CANCELLED',
  PAYMENT_STATUS_PAID = 'PAID',
  PAYMENT_STATUS_AUTHORIZED = 'AUTHORIZED',
  PAYMENT_STATUS_UNKNOWN = 'UNKNOWN',
  PAYMENT_STATUS_INITIATED = 'INITIATED',
  PAYMENT_STATUS_REPLACED = 'REPLACED',
  PAYMENT_STATUS_ABORTED = 'ABORTED',
  PAYMENT_STATUS_ERROR = 'ERROR',
}
