import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { B2bCommonModule, SikoDebounceClickDirective } from '@siko/shared';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SikoAbstractItemCounterComponent } from './abstract-item-counter/abstract-item-counter.component';
import { SikoPdpItemCounterComponent } from './pdp-item-counter/pdp-item-counter.component';
import { SikoQuickOrderItemCounterComponent } from './quick-order-item-counter/quick-order-item-counter.component';
import { SikoCartItemCounterComponent } from './cart-item-counter/cart-item-counter.component';
import { SikoTicketItemCounterComponent } from './ticket-item-counter/ticket-item-counter.component';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';


@NgModule({
    declarations: [
        SikoAbstractItemCounterComponent,
        SikoPdpItemCounterComponent,
        SikoQuickOrderItemCounterComponent,
        SikoCartItemCounterComponent,
        SikoTicketItemCounterComponent,
    ],
    imports: [
        B2bCommonModule,
        ReactiveFormsModule,
        SikoProductUnitComponent,
        SikoDebounceClickDirective,
        NgbTooltipModule,
    ],
    exports: [
        SikoPdpItemCounterComponent,
        SikoQuickOrderItemCounterComponent,
        SikoCartItemCounterComponent,
        SikoTicketItemCounterComponent,
    ],
})
export class SikoItemCounterModule {}
