import { Injectable, OnDestroy } from '@angular/core';
import {
    EventService,
    GlobalMessageService,
    LogoutEvent,
} from '@spartacus/core';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SikoUserAccountEventListener implements OnDestroy {

    protected subscriptions = new Subscription();

    constructor(
        protected eventService: EventService,
        protected globalMessageService: GlobalMessageService,
    ) {
        this.onAuth();
    }

    /**
     * Registers events for the auth events.
     */
    protected onAuth(): void {
        this.subscriptions.add(
            this.eventService.get(LogoutEvent)
                .subscribe(() => {
                    // TODO Overriden Spartacus functionality - we do not want to display logout message
                    // this.globalMessageService.add(
                    //     { key: 'authMessages.signedOutSuccessfully' },
                    //     GlobalMessageType.MSG_TYPE_CONFIRMATION
                    // );
                }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
