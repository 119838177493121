import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    OccEndpointsService,
    UserIdService
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { Reorder } from '@siko/models';


@Injectable({
    providedIn: 'root'
})
export class SikoOccOrderOverviewAdapter {

    private backendBaseUrl?: string;
    private userId?: string;

    constructor(
    protected http: HttpClient,
    private readonly occEndpointsService: OccEndpointsService,
    protected userIdService: UserIdService,
    ) {}

    reorder(
        orderCode: string,
    ): Observable<Reorder>{
        this.backendBaseUrl = this.occEndpointsService.getBaseUrl();
        this.userIdService.getUserId().subscribe(
            (occUserId: string) => this.userId = occUserId
        )
            .unsubscribe();

        return this.http.get<Reorder>(`${this.backendBaseUrl}/users/${this.userId}/reorder`,
            {
                params: new HttpParams().set('orderCode', orderCode)
            });
    }

}
