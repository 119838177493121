import { Injectable } from '@angular/core';
import { BehaviorSubject, from, fromEvent, Observable } from 'rxjs';
import { filter, map, mergeMap, throttleTime } from 'rxjs/operators';
import dayjs from 'dayjs';


@Injectable({
    providedIn: 'root',
})
export class SikoUserActivityService {

    lastActive$: Observable<Date>;
    private readonly lastActiveStorageKey = 'lastActive';
    private readonly events: string[] = ['keydown', 'click', 'wheel', 'mousemove'];
    private readonly lastActive: BehaviorSubject<Date>;
    private readonly recordTimeoutMs: number = 500;

    constructor() {
        const lastActiveDate = this.getLastActiveFromLocalStorage() ?? new Date();

        this.lastActive = new BehaviorSubject<Date>(lastActiveDate);
        this.lastActive$ = this.lastActive.asObservable();
    }

    startMonitoringUserActivity(): void {
        from(this.events)
            .pipe(mergeMap((event: string) => fromEvent(document, event)), throttleTime(this.recordTimeoutMs))
            .subscribe(() => {
                this.recordLastActiveDate();
            });

        // synchronise the login state between different tabs
        fromEvent<StorageEvent>(window, 'storage')
            .pipe(
                filter(event => event.storageArea === localStorage
                && event.key === this.lastActiveStorageKey
                && !!event.newValue),
                map(event => new Date(event.newValue!))
            )
            .subscribe(newDate => {
                this.lastActive.next(newDate);
            });
    }

    getLastActiveDate(): Date {
        return this.lastActive.value;
    }

    private recordLastActiveDate(): void {
        const currentDate = dayjs(new Date());

        // so we won't update local storage oftentimes
        if (dayjs().diff(this.getLastActiveDate(), 'millisecond') < this.recordTimeoutMs) {
            return;
        }

        localStorage.setItem(this.lastActiveStorageKey, currentDate.toString());
        this.lastActive.next(currentDate.toDate());
    }

    private getLastActiveFromLocalStorage(): Date | null {
        const valueFromStorage = localStorage.getItem(this.lastActiveStorageKey);

        return valueFromStorage ? new Date(valueFromStorage) : null;
    }

}
