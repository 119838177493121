import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { CounterMode } from '@siko/features/shared-components/bubble-item-counter/types/counter-mode.type';
import { B2bCommonModule } from '@siko/shared';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-bubble-item-counter',
    templateUrl: './bubble-item-counter.component.html',
    styleUrls: ['./bubble-item-counter.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
    ],
})
export class SikoBubbleItemCounterComponent {

    @Input() counter?: Observable<number | undefined>;
    @Input() type?: CounterMode = 'grey';
    maxCounterValue = '99+';

    // TODO replace for Angular pipe
    checkCounterValue(items: number): string {
        if (items > 99) {
            return this.maxCounterValue;
        }

        return items.toString();
    }

}
