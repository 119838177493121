<div class='footer'>
  <div class='d-flex flex-wrap mb-5'>
    <cx-page-slot [position]="'FooterA1'"></cx-page-slot>
    <cx-page-slot [position]="'FooterA2'"></cx-page-slot>
  </div>
  <div class='d-flex flex-wrap wrapper-B'>
    <cx-page-slot [position]="'FooterB1'"></cx-page-slot>
    <cx-page-slot [position]="'FooterB2'"></cx-page-slot>
  </div>
  <cx-page-slot [position]="'FooterC'"></cx-page-slot>
  <cx-page-slot [position]="'FooterD'"></cx-page-slot>
</div>
