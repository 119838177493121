import { Component, Input } from '@angular/core';
import { SikoProduct, SikoProductSellUnit } from '@siko/models';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-product-sell-units',
    templateUrl: './product-sell-units.component.html',
    styleUrls: ['./product-sell-units.component.scss'],
})

export class SikoProductSellUnitsComponent {
    sikoProduct?: SikoProduct;
    showProductSellUnits: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    @Input() set product(input: SikoProduct) {
        this.sikoProduct = input;
    }

    getReducedSellUnits(): SikoProductSellUnit[] | undefined {
        const reducedSellUnits: SikoProductSellUnit[] = [];

        if (this.sikoProduct?.sikoProductSellUnits && this.sikoProduct.sikoProductSellUnits.length !== 0) {
            this.addSellUnit(reducedSellUnits, 'MBL');

            if (!this.addSellUnit(reducedSellUnits, 'VBL')) {
                this.addSellUnit(reducedSellUnits, 'DBL');
            }

            return reducedSellUnits.length === 0 ? undefined : reducedSellUnits;
        }

        return undefined;
    }

    addSellUnit(sellUnits: SikoProductSellUnit[], unit: string): boolean {
        let sellUnit = null;

        if (this.sikoProduct?.sikoProductSellUnits) {
            sellUnit = this.sikoProduct.sikoProductSellUnits.find((i: SikoProductSellUnit) => i.unit && i.unit.sapCode === unit);
        }

        if (sellUnit) {
            sellUnits.push(sellUnit);
            return true;
        }

        return false;
    }

}
