import { Component,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
    ElementRef,
    Input,
    ViewChild } from '@angular/core';
import {
    SikoRegistrationGoogleMapRendererService
} from '@siko/features/shared-components/store-finder-map/registration-google-map-renderer.service';
import { B2bCommonModule } from '@siko/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-store-finder-map',
    templateUrl: './store-finder-map.component.html',
    styleUrls: ['./store-finder-map.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule
    ]
})
export class SikoStoreFinderMapComponent implements OnChanges{
    @ViewChild('mapElement', { static: true }) mapElement?: ElementRef;

    @Input() height = '176px';
    @Input() latitude?: number;
    @Input() longitude?: number;


    constructor(
      private readonly sikoRegistrationGoogleMapRendererService: SikoRegistrationGoogleMapRendererService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {

        if (this.latitude && this.longitude && this.mapElement) {
            const htmlElement: HTMLElement = this.mapElement.nativeElement as HTMLElement;

            this.sikoRegistrationGoogleMapRendererService.renderMap(
                htmlElement,
                {
                    latitude: this.latitude,
                    longitude: this.longitude
                }
            );
        }
    }

}
