<div class='cx-order-summary'>
  <div class='row'>
    <div class='col-sm-12 col-md-6 col-lg-5 col-xl-8 note'>
      <h5 class='siko-color-grey-3'>
        {{"sikoOrder.orderDetail.orderNote" | cxTranslate}}
      </h5>
      <span class='d-block siko-fs-14'>
        {{ sikoSapOrder ? sikoSapOrder.note : order?.note }}
      </span>
      <div class='giveaway-coupons' *ngIf='hasGiveawayCoupons()'>
        <h3 class='coupon-title'>{{ 'sikoMyCoupons.couponGiveAway' | cxTranslate }}</h3>
        <ng-container *ngFor='let promotions of orderWithPromotions?.appliedOrderPromotions'>
          <ng-container *ngFor='let coupon of promotions?.giveAwayCouponCodes'>
            <app-coupon-card
              [coupon]='coupon'
              [giveAwayCoupon]='true'
              class='d-flex justify-content-center pb-32 wider'
            ></app-coupon-card>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class='col-sm-12 col-md-6 col-lg-5 col-xl-4'>
      <ng-container *ngIf='order'>
        <app-order-summary [order]='order'></app-order-summary>
      </ng-container>
      <ng-container *ngIf='sikoSapOrder'>
        <app-order-summary [sapOrder]='sikoSapOrder' [isSapOrderSummary]='true'></app-order-summary>
      </ng-container>
    </div>
    <div class='col-12 d-flex justify-content-end actions'>
      <div class='back-to-history'>
        <siko-button
          testId='back-to-orders-button'
          [size]='$any("md")'
          [mode]='$any("light")'
          [text]='"sikoOrder.orderDetail.backToOrderList" | cxTranslate'
          (clickEvent)='backToOrderHistory()'
        >
        </siko-button>
      </div>
      <ng-container *ngIf='displayReorderButton'>
        <app-reorder-button [orderCode]='sikoSapOrder?.order?.code ?? order?.code'></app-reorder-button>
      </ng-container>
      <ng-container *ngIf='orderConfirmation'>
        <siko-button
          testId='back-to-shop-button'
          [size]='$any("md")'
          [mode]='$any("cart")'
          [text]='"siko.backToShop" | cxTranslate'
          (clickEvent)='backToShop()'
        >
        </siko-button>
      </ng-container>
    </div>
  </div>
</div>
