import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    HttpErrorModel, LoggerService,
    normalizeHttpError,
    ProductReference,
    ProductReferenceService,
    RoutingService,
} from '@spartacus/core';
import { Observable, of, Subscription } from 'rxjs';
import { SikoCart, SikoCartModification, SikoOrderEntry, SikoProductUnitData } from '@siko/models';
import { HTTP_ERROR } from '@siko/constants';
import { ADD_TO_CART_ENTRY_STATUS, SikoActiveCartService, SikoAddToCartSuccessPayload } from '@siko/features/cart';
import { SikoAutoUnsubscribe, SikoBreakpointObserverService } from '@siko/common';
import { SikoDialogService, SikoTrackingUtils, SikoUtils } from '@siko/shared';
import { Store } from '@ngrx/store';
import { CartActions, StateWithMultiCart } from '@spartacus/cart/base/core';
import { ActiveCartFacade, CartModification } from '@spartacus/cart/base/root';

export type SikoCartEntryType = 'favourite' | 'delivery' | 'bopis';

export enum SikoCartEntryEnum {
    FAVOURITE = 'favourite',
    DELIVERY = 'delivery',
    BOPIS = 'bopis',
}

@SikoAutoUnsubscribe([
    'entrySubscription',
    'addToCartSuccessSubscription',
    'addToCartFailureSubscription',
])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-added-to-cart-dialog',
    templateUrl: './added-to-cart-dialog.component.html',
    styleUrls: ['./added-to-cart-dialog.component.scss'],
})
export class SikoAddedToCartDialogComponent implements OnInit {

    HTTP_ERROR_ENUM = HTTP_ERROR;
    ADD_TO_CART_ENTRY_STATUS_ENUM = ADD_TO_CART_ENTRY_STATUS;
    CART_ERROR = 'CartError';

    entry$?: Observable<SikoOrderEntry | undefined>;
    cart$: Observable<SikoCart> = this.activeCartFacade.getActive();

    addToCartType: SikoCartEntryType = SikoCartEntryEnum.DELIVERY;
    productCode?: string;
    quantityToAdd?: number;
    sourceForGTM = 'pdp';
    bonusPoints = 0;

    selectedStoreId?: string;

    cartDeleted = false;
    httpError: string | undefined;
    addToCartStatus: string | undefined;
    cartModification: SikoCartModification | undefined;

    storeName? = '';
    isLoading = false;

    entrySubscription?: Subscription;
    addToCartSuccessSubscription?: Subscription;
    addToCartFailureSubscription?: Subscription;

    addedQuantity = 0;
    productReferences$?: Observable<ProductReference[]>;

    constructor(
        public breakpointObserver: SikoBreakpointObserverService,
        protected activeCartService: SikoActiveCartService,
        protected activeCartFacade: ActiveCartFacade,
        protected routingService: RoutingService,
        readonly productReferenceService: ProductReferenceService,
        readonly trackingUtils: SikoTrackingUtils,
        readonly cd: ChangeDetectorRef,
        readonly store: Store<StateWithMultiCart>,
        readonly dialogService: SikoDialogService,
        readonly logger: LoggerService,
    ) {}

    get sikoOrderEntry$(): Observable<SikoOrderEntry | undefined> | undefined {
        return this.entry$;
    }

    ngOnInit(): void {
        this.addToCart();

        this.addToCartSuccessSubscription = this.activeCartService.addToCartSuccessSubject$.subscribe(
            (successPayload: SikoAddToCartSuccessPayload) => {

                const payloadCartModification: CartModification = successPayload.cartModification;
                const payloadModificationEntry: SikoOrderEntry | undefined = payloadCartModification.entry;
                const payloadQuantityAdded: number | undefined = payloadCartModification.quantityAdded;

                if (payloadCartModification.quantityAdded && payloadCartModification.quantityAdded > 0) {
                    this.trackingUtils.pushModifyCartEvent([payloadModificationEntry], payloadQuantityAdded, 'addToCart', successPayload.sourceForGTM);
                }

                if (payloadCartModification.entry) {
                    this.entry$ = of(payloadCartModification.entry);
                }

                this.cartModification = payloadCartModification;
                this.addToCartStatus = payloadCartModification.statusCode;

                this.isLoading = false;
                this.cd.detectChanges();

                this.loadCart(successPayload.userId, successPayload.cartId);
            },
        );

        this.addToCartFailureSubscription = this.activeCartService.addToCartFailureSubject$.subscribe(
            (error: HttpErrorModel | undefined) => {
                this.isLoading = false;
                const normalizedError: HttpErrorModel | undefined = normalizeHttpError(error, this.logger);
                if (normalizedError?.details) {
                    this.httpError = normalizedError.details[0].type;
                }
                this.cd.detectChanges();
            },
        );

        this.entrySubscription = this.entry$?.subscribe((orderEntry: SikoOrderEntry | undefined) => {
            if (orderEntry && orderEntry.product?.code) {
                this.productReferences$ = this.productReferenceService.getProductReferences(
                    orderEntry.product.code, 'ACCESSORIES',
                );
            }
        });
    }

    deleteCart(): void {
        this.activeCartService.deleteCart();
        this.dialogService.closeActiveModal();
    }

    calculateAddedQuantity(unitData: SikoProductUnitData, quantity: number | undefined): number | void {
        if (!unitData?.conversion) {return;}
        if (quantity === undefined) {return;}

        return +(+unitData.conversion * quantity).toFixed(3);
    }

    formatUnitSymbol(unitData: SikoProductUnitData): string {
        return SikoUtils.formatUnitSymbol(unitData);
    }

    reloadPage(): void {
        window.location.reload();
    }

    private loadCart(userId: string, cartId: string): void {
        const loadCartPayload = {
            userId,
            cartId,
        };

        this.store.dispatch(new CartActions.LoadCart(loadCartPayload));
    }

    private addToCart(): void {
        if (!this.productCode || !this.addToCartType || !this.quantityToAdd) {return;}

        this.entry$ = this.activeCartFacade.getLastEntry(this.productCode);

        this.isLoading = true;

        this.activeCartService.addEntry(
            this.productCode,
            this.quantityToAdd,
            this.bonusPoints,
            'pdp',
        );
    }

}
