import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OccEndpointsService, Price } from '@spartacus/core';
import { CartModification } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { SikoBopisPointOfService, SikoCartModification } from '@siko/models';
import { HEADER_SIKO_IMPORT_BATCH } from '@siko/constants';

@Injectable({
    providedIn: 'root',
})
export class OccBopisAdapter {

    private readonly backendBaseUrl: string;

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
    ) {
        this.backendBaseUrl = this.occEndpoints.getBaseUrl();
    }

    addToCartForFavouriteStorePickUp(cartId: string, productCode: string, quantity: number): Observable<SikoCartModification> {
        return this.http.post<SikoCartModification>(
            `${this.backendBaseUrl}/store-pickup/carts/${cartId}/add-favourite?code=${productCode}&quantity=${quantity}`,
            {},
        );
    }

    addToCartQuickOrder(userId: string, cartId: string, quantity: number, productCode: string, batchKey: string): Observable<CartModification> {
        return this.http.post<CartModification>(
            `${this.backendBaseUrl}/orgUsers/${userId}/carts/${cartId}/add-quick-order?code=${productCode}&quantity=${quantity}`,
            {},
            {
                headers: {
                    [HEADER_SIKO_IMPORT_BATCH]: batchKey,
                },
            },
        );
    }

    importCart(userId: string, cartId: string, quantity: number, productCode: string, batchKey: string): Observable<CartModification> {
        return this.http.post<CartModification>(
            `${this.backendBaseUrl}/orgUsers/${userId}/carts/${cartId}/import-entry?code=${productCode}&quantity=${quantity}`,
            {},
            {
                headers: {
                    [HEADER_SIKO_IMPORT_BATCH]: batchKey,
                },
            },
        );
    }

    addToCart(userId: string, cartId: string, quantity: number, productCode: string, points: number = 0): Observable<CartModification> {
        return this.http.post<CartModification>(`
        ${this.backendBaseUrl}/orgUsers/${userId}/carts/${cartId}/entries?quantity=${quantity}&code=${productCode}${points > 0 ? ('&points=' + points) : ''}`, {});
    }

    addToCartForDelivery(userId: string, cartId: string, quantity: number, productCode: string): Observable<CartModification> {
        return this.http.post<CartModification>(`
        ${this.backendBaseUrl}/orgUsers/${userId}/carts/${cartId}/entries?quantity=${quantity}&code=${productCode}`, {});
    }

    addToCartForSelectedStorePickUp(
        cartId: string, productCode: string, quantity: number, storeId: string,
    ): Observable<CartModification> {
        return this.http.post<CartModification>(
            `${this.backendBaseUrl}/store-pickup/carts/${cartId}/add?code=${productCode}&quantity=${quantity}&storeId=${storeId}`,
            {},
        );
    }

    getPickUpInStorePointsOfServices(
        productCode: string | undefined,
    ): Observable<SikoBopisPointOfService[]> {
        return this.http.post<SikoBopisPointOfService[]>(
            `${this.backendBaseUrl}/store-pickup/${productCode}/pointOfServices`,
            {},
        );
    }

    getFormattedPrice(
        price: number,
    ): Observable<Price> {
        return this.http.get(
            `${this.backendBaseUrl}/formatted-price?price=${price}`,
            {},
        );
    }

    updateCartAmount(
        cartId: string,
        productCode: string,
        quantity: number,
        storeId: string,
    ): Observable<SikoCartModification> {
        return this.http.post(
            `${this.backendBaseUrl}/store-pickup/carts/${cartId}/update?entryNumber=${productCode}&quantity=${quantity}&storeId=${storeId}`
            , {},
        );
    }
}
