import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { SikoCart, SikoOrder, SikoSapOrder } from '@siko/models';
import { SikoPricePipe } from '@siko/shared/pipes/price.pipe';
import { B2bCommonModule } from '@siko/shared';
import { SikoPriceComponent } from '@siko/features/shared-components/price/price.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss'],
    standalone: true,
    providers: [
        SikoPricePipe
    ],
    imports: [
        B2bCommonModule,
        SikoPriceComponent,
    ],
})
export class SikoOrderSummaryComponent implements OnChanges{

    constructor(
        private readonly sikoPricePipe: SikoPricePipe
    ) {}

    @Input() cart?: SikoCart;
    @Input() order?: SikoOrder;
    @Input() sapOrder?: SikoSapOrder;
    @Input() isCartSummary?: boolean = true;
    @Input() isSapOrderSummary = false;
    @Input() pickupdate?: string;
    @Input() isOrderSynced?: boolean | null

    cartTotal?: string;

    ngOnChanges() {
        if (this.cart && this.cart.totalPriceWithTax?.value !== undefined) {
            this.cartTotal = this.cart.totalPriceWithTax.value < 0 ? this.sikoPricePipe.transform(0) : this.sikoPricePipe.transform(this.cart.totalPriceWithTax);
        }
    }

    get hasFreeDelivery(): boolean {
        const orderData: SikoOrder | undefined = this.isSapOrderSummary ? this.sapOrder?.order : this.order;

        if (orderData !== undefined) {
            return orderData.appliedOrderPromotions ? orderData.appliedOrderPromotions.some(promotionResult =>
                promotionResult.promotion?.sikoPromoType === 'FREE_DELIVERY') : false;
        }

        return this.cart?.appliedOrderPromotions ? this.cart.appliedOrderPromotions.some(promotionResult =>
            promotionResult.promotion?.sikoPromoType === 'FREE_DELIVERY') : false;
    }

}
