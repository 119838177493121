import {
    GlobalMessageService,
    GlobalMessageType,
    HttpErrorHandler,
    HttpResponseStatus,
    Priority,
} from '@spartacus/core';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { SapOrderSortService } from '@siko/features/my-account/order-history/services';

@Injectable({
    providedIn: 'root',
})
export class SikoBadGatewayHandler extends HttpErrorHandler {

    responseStatus = HttpResponseStatus.BAD_GATEWAY;

    constructor(
        readonly globalMessageService: GlobalMessageService,
        readonly sapOrderSortService: SapOrderSortService,
        @Inject(PLATFORM_ID) protected platformId?: Object,
    ) {
        super(globalMessageService, platformId);
    }

    handleError(request: HttpRequest<any>, response: HttpErrorResponse) {
        this.validateSapOrderException(request, response);
    }

    getPriority(): Priority {
        return Priority.LOW;
    }

    validateSapOrderException(request: HttpRequest<any>, response: HttpErrorResponse): void {
        if (request.url.includes('/sap-orders')) {
            this.globalMessageService.add(
                { key: 'httpHandlers.timeout' },
                GlobalMessageType.MSG_TYPE_WARNING,
            );

            this.sapOrderSortService.isFetchingSubject.next(true);
        }
        else {
            this.globalMessageService.add(
                { key: 'httpHandlers.badGateway' },
                GlobalMessageType.MSG_TYPE_ERROR,
            );
        }
    }

}
