/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

@Injectable({
    providedIn: 'root',
})

export class SikoCustomFormValidatorsService {
    static czPhoneRegex = /(\d{9})|(\d{3}\s\d{3}\s\d{3})[0-9]{9}/;
    static skPhoneRegex = /(\d{9})|(\d{3}\s\d{3}\s\d{3})|(2\s\d{4}\s\d{4})|([35][0-9]\s\d{3}\s\d{4})/;
    static czCompanyVatIdRegex = /^(CZ)(\d{8,10})$/;
    static skCompanyVatIdRegex = /^(\d{10})$/;
    static postCodeRegex = /^(((\d{3}) (\d{2}))|(\d{5}))$/;
    static passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9!@$%^*()_\-{};:.,]{8,}$/;
    static pinCodeRegex = /^(\d{6})$/;

    static noteLength = 255;

    constructor() {
        dayjs.extend(isSameOrBefore);
        dayjs.extend(isSameOrAfter);
    }

    phoneFormatOnPrefix(firstControlName: string, secondControlName: string): any {
        return (formGroup: UntypedFormGroup) => {

            const firstControl = formGroup.controls[firstControlName];
            const secondControl = formGroup.controls[secondControlName];

            if (!firstControl.value || !secondControl.value) {
                return;
            }

            const firstControlValue: string = firstControl.value as string;
            const secondControlValue: string = secondControl.value as string;

            secondControl.setErrors(this.validatePhone(firstControlValue, secondControlValue));
        };
    }

    validateCompanyVatIdOnCountryIso(firstControlName: string, secondControlName: string): any {
        return (formGroup: UntypedFormGroup) => {
            const firstControl = formGroup.controls[firstControlName];
            const secondControl = formGroup.controls[secondControlName];

            if (!firstControl.value || !secondControl.value) {
                return;
            }


            const firstControlValue: string = firstControl.value as string;
            const secondControlValue: string = secondControl.value as string;

            secondControl.setErrors(this.validateVatId(firstControlValue, secondControlValue));

            return this.validateVatId(firstControlValue, secondControlValue);
        };
    }

    validateTermsAndConditions() {
        return (formGroup: UntypedFormGroup) => {
            const termsControl = formGroup.controls.termsAndConditions;

            if (termsControl.value) {
                return;
            }

            termsControl.setErrors({ sikoTermsAndConditions: true });
        };
    }

    validatePostCodeOnIsoCode(firstControlName: string, secondControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const firstControl = formGroup.controls[firstControlName];
            const secondControl = formGroup.controls[secondControlName];

            if (!firstControl.value || !secondControl.value) {
                return;
            }

            const firstControlValue: string = firstControl.value as string;
            const secondControlValue: string = secondControl.value as string;

            secondControl.setErrors(this.validatePostCode(firstControlValue, secondControlValue));

        };
    }

    positiveNumberValidator(control: AbstractControl): ValidationErrors | null {
        let {value} = control;

        if (value === null || value === '') {
            return null;
        }

        value = value.replace(',', '.');

        const isNumber = /^[\d\s\.\-]+$/.test(value);

        if (!isNumber) {
            control.setValue('');


            return { sikoPositiveNumber: true };
        }

        return null;
    }

    validatePostCodeOnIsoCodeFromFormGroup(
        firstControlName: string,
        secondControl: UntypedFormControl | null,
        isocodeValue = 'CZ',
    ) {
        return (formGroup: UntypedFormGroup) => {
            if (secondControl && isocodeValue) {
                const firstControl = formGroup.controls[firstControlName];
                const firstControlValue: string = firstControl.value as string;

                secondControl.setErrors(this.validatePostCode(firstControlValue, isocodeValue));

            }
            else {
                return;
            }
        };
    }

    validateCheckoutAddressFullName(firstControlName: string, secondControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const firstControl = formGroup.controls[firstControlName];
            const secondControl = formGroup.controls[secondControlName];

            if (!firstControl.value || !secondControl.value) {
                return;
            }

            const firstControlValue: string = firstControl.value as string;
            const secondControlValue: string = secondControl.value as string;

            const fullNameError =
                firstControlValue.length + secondControlValue.length <= 40 ?
                    null : { sikoFullNameLength: true };

            secondControl.setErrors(fullNameError);
        };
    }

    validateCheckoutCompanyName(companyNameControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const companyNameControl = formGroup.controls[companyNameControlName];

            if (!companyNameControl.value) {
                return;
            }

            const companyNameValue: string = companyNameControl.value as string;

            const companyNameError = companyNameValue.length <= 40 ? null : { sikoCompanyName: true };

            companyNameControl.setErrors(companyNameError);
        };
    }

    validatePoNumber(poNumberControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const poNumberControl = formGroup.controls[poNumberControlName];

            if (!poNumberControl.value) {
                return;
            }

            const poNumberValue: string = poNumberControl.value as string;

            const poNumberError = poNumberValue.length <= 35 ? null : { sikoPoNumber: true };

            poNumberControl.setErrors(poNumberError);
        };
    }

    validatePreferredPickupDate(inputName: string, maxLimitDate: Date, arrayOfAvailableDates: Date[], earliestDeliveryTime: Date | dayjs.Dayjs) {
        return (formGroup: UntypedFormGroup): ValidationErrors | null => {
            const preferredPickupDate = formGroup.controls[inputName];

            let preferredPickupDateError = null;

            if (!preferredPickupDate.value) {
                preferredPickupDateError = { sikoRequiredDate: true };
            }

            const selectedDate = dayjs(preferredPickupDate.value);

            const dateFromAvailableDates = arrayOfAvailableDates.find(
                availableDate =>
                    availableDate.toDateString() === selectedDate.toDate().toDateString(),
            );

            if (dayjs(earliestDeliveryTime)
                .isAfter(selectedDate, 'day')) {
                preferredPickupDateError = { sikoPreferredPickupDateLowerThanToday: true };
            }


            if ((selectedDate.day() === 6 || selectedDate.day() === 0) && !dateFromAvailableDates) {
                preferredPickupDateError = { sikoPreferredPickupDateWeekendDisable: true };
            }

            if (dayjs(maxLimitDate)
                .isBefore(selectedDate, 'hour')) {
                preferredPickupDateError = { sikoPreferredPickupDateHigherThanMax: true };
            }

            preferredPickupDate.setErrors(preferredPickupDateError);

            return null;
        };
    }

    validateCheckoutNote(noteControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const noteControl = formGroup.controls[noteControlName];

            if (!noteControl.value) {
                return;
            }

            const noteValue: string = noteControl.value as string;

            const noteError = noteValue.length <= SikoCustomFormValidatorsService.noteLength ? null : { sikoCheckoutNote: true };

            noteControl.setErrors(noteError);
        };
    }

    validatePassword(passwordControlName: string): any {
        return (formGroup: UntypedFormGroup) => {
            const passwordControl = formGroup.controls[passwordControlName];

            if (!passwordControl.value) {
                return;
            }

            const passwordValue: string = passwordControl.value as string;

            const passwordError: { sikoPassword: boolean } | null =
                SikoCustomFormValidatorsService.passwordRegex.test(passwordValue) ? null : { sikoPassword: true };

            passwordControl.setErrors(passwordError);
        };
    }

    verifyPinCode(pinCodeControlName: string): any {
        return (formGroup: UntypedFormGroup) => {
            const pinCodeControl = formGroup.controls[pinCodeControlName];

            if (!pinCodeControl.value) {
                return;
            }

            const pinCodeValue: string = pinCodeControl.value as string;

            const pinError: { sikoPin: boolean } | null =
                SikoCustomFormValidatorsService.pinCodeRegex.test(pinCodeValue) ? null : { sikoPin: true };

            pinCodeControl.setErrors(pinError);
        };
    }

    private validatePostCode(companyAddressIsoCode: string, companyPostCode: string): { sikoPostCode: boolean } | null {

        const startPscCharacters =
            companyAddressIsoCode === 'CZ' ? ['1', '2', '3', '4', '5', '6', '7'] : ['0', '8', '9'];

        return SikoCustomFormValidatorsService.postCodeRegex.exec(companyPostCode) !== null
        && startPscCharacters.includes(companyPostCode.charAt(0)) ? null : { sikoPostCode: true };
    }

    private validateVatId(companyAddressIsoValue: string, companyVatIdValue: string): { sikoVat: boolean } | null {
        if (companyAddressIsoValue === 'SK') {
            return SikoCustomFormValidatorsService.skCompanyVatIdRegex.test(companyVatIdValue) ? null : { sikoVat: true };
        }
        else if (companyAddressIsoValue === 'CZ') {
            return SikoCustomFormValidatorsService.czCompanyVatIdRegex.test(companyVatIdValue) ? null : { sikoVat: true };
        }

        return null;
    }

    private validatePhone(phonePrefixValue: string, phoneValue: string): { sikoPhoneFormat: boolean } | null {
        if (phonePrefixValue === '+420') {
            return this.validateRegexValue(phoneValue, SikoCustomFormValidatorsService.czPhoneRegex) ? null : { sikoPhoneFormat: true };
        }
        else if (phonePrefixValue === '+421') {
            return this.validateRegexValue(phoneValue, SikoCustomFormValidatorsService.skPhoneRegex) ? null : { sikoPhoneFormat: true };
        }

        return null;
    }

    private validateRegexValue(value: string, regex: any): boolean {
        // @ts-expect-error
        return value.match(regex) !== null && value.match(regex)[0] === value;
    }
}
