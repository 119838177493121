import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { SmartEditConfig, SmartEditRootModule, SMART_EDIT_FEATURE } from '@spartacus/smartedit/root';

@NgModule({
    declarations: [],
    imports: [
        SmartEditRootModule
    ],
    providers: [
      provideConfig(<CmsConfig>{
        featureModules: {
            [SMART_EDIT_FEATURE]: {
                module: async() =>
                    import('@spartacus/smartedit').then(m => m.SmartEditModule),
                storefrontPreviewRoute: 'cx-preview'
            }
        }
      }),
      provideConfig(<SmartEditConfig>{
        smartEdit: {
          allowOrigin: 'localhost:9002, bo.d3.siko.cz:443, se.d3.siko.cz:443, bo.d2.siko.cz:443, se.d2.siko.cz:443, bo.d1.siko.cz:443, se.d1.siko.cz:443, bo.s1.siko.cz:443, se.s1.siko.cz:443, bo.p1.siko.cz:443, se.p1.siko.cz:443'
        },
      })
    ]
})
export class SmartEditFeatureModule { }
