import { Injectable } from '@angular/core';
import { SikoOccOrderOverviewAdapter } from '@siko/features/order-overview/occ/adapters/order-overview.adapter';
import { Reorder, SikoCartModification } from '@siko/models';
import { GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { CartActions, MultiCartStatePersistenceService, StateWithMultiCart } from '@spartacus/cart/base/core';
import { CartModification, CartModificationList } from '@spartacus/cart/base/root';
import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { QuickOrderAddEntryEvent } from '@spartacus/cart/quick-order/root';
import { SikoTrackingUtils } from '@siko/shared/utils/siko-tracking-utils';


@Injectable({
    providedIn: 'root',
})
export class SikoOrderOverviewService {

    cartModificationHistory = new BehaviorSubject<any[]>([]);

    isReorderingSubject$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly routingService: RoutingService,
        private readonly trackingUtils: SikoTrackingUtils,
        protected orderOverviewAdapter: SikoOccOrderOverviewAdapter,
        private readonly globalMessageService: GlobalMessageService,
        protected store: Store<StateWithMultiCart>,
        private readonly multiCartStatePersistenceService: MultiCartStatePersistenceService,
    ) {}

    get cartModificationHistory$(): Observable<SikoCartModification[]> {
        return this.cartModificationHistory.asObservable();
    }

    get isReordering$(): Observable<boolean> {
        return this.isReorderingSubject$.asObservable();
    }

    reorder(orderCode: string | undefined): void {
        this.isReorderingSubject$.next(true);
        if (orderCode) {
            this.orderOverviewAdapter.reorder(orderCode)
                .subscribe((reorder: Reorder) => {
                        this.clearCartModificationHistory();
                        this.store.dispatch(new CartActions.LoadCartSuccess({
                            userId: 'current',
                            cartId: reorder.currentCartCode,
                            cart: reorder.reorderCart,
                        }));

                        this.multiCartStatePersistenceService.initSync();

                        this.isReorderingSubject$.next(false);

                        if (reorder.reorderCart.entries) {
                            this.trackingUtils.pushModifyCartEvent(reorder.reorderCart.entries, undefined, 'addToCart', 'reorder');
                        }

                        if (reorder.proceedToCart) {
                            this.routingService.goByUrl('cart');
                        }
                        else {
                            this.displayCartModificationMessage(reorder.cartModification);
                        }
                    }
                    , (errorResponse: HttpResponse<object>) => {
                        if (errorResponse.status === HttpStatusCode.BadRequest) {
                            this.isReorderingSubject$.next(false);
                            this.globalMessageService.add(
                                { key: 'sikoCheckout.wrongOrderCode' },
                                GlobalMessageType.MSG_TYPE_INFO,
                            );
                        }
                    });
        }
    }

    createQuickOrderEvent(data: CartModification): QuickOrderAddEntryEvent | undefined {
        if (data.entry?.product?.code && data.quantity !== undefined) {
            return {
                productCode: data.entry.product.code,
                entry: data.entry,
                quantityAdded: data.quantityAdded,
                quantity: data.quantity,
            };
        }

        return undefined;
    }

    addCartModificationHistory(item: QuickOrderAddEntryEvent[]): void {
        this.cartModificationHistory.next(this.cartModificationHistory.getValue()
            .concat(item));
    }


    displayCartModificationMessage(
        modificationList: CartModificationList | undefined,
    ): void {
        if (modificationList?.cartModifications) {
            modificationList.cartModifications.forEach(cartModification => {
                const event: QuickOrderAddEntryEvent | undefined = this.createQuickOrderEvent(cartModification);

                if (event !== undefined) {
                    this.addCartModificationHistory([event]);
                }
            });
        }
    }


    clearCartModificationHistory(): void {
        this.cartModificationHistory.next([]);
    }

}
