import { Injectable } from '@angular/core';
import {
    ContextService,
} from '@spartacus/storefront';
import {
    SikoImportEntriesDialogComponent,
} from '@siko/features/shared-components/import-entries-dialog/components/import-entries-dialog/import-entries-dialog.component';
import { Observable } from 'rxjs';
import { SikoDialogService } from '@siko/shared';
import { AddOrderEntriesContext, ORDER_ENTRIES_CONTEXT, OrderEntriesContext } from '@spartacus/cart/base/root';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root',
})
export class SikoImportCartService {

    constructor(
        private readonly contextService: ContextService,
        private readonly dialogService: SikoDialogService,
    ) {
    }

    orderEntriesContext$: Observable<OrderEntriesContext | undefined> =
        this.contextService.get<OrderEntriesContext>(ORDER_ENTRIES_CONTEXT);

    sikoImportCartDialog(): void {
        if (!(this.dialogService.getActiveModal()?.componentInstance instanceof SikoImportEntriesDialogComponent)) {
            const modalRef: NgbModalRef = this.dialogService.open(SikoImportEntriesDialogComponent, {
                windowClass: 'siko-dialog-window',
                size: 'sm',
            });

            const modalInstance: SikoImportEntriesDialogComponent =
                modalRef.componentInstance as SikoImportEntriesDialogComponent;

            modalInstance.context$ = this.orderEntriesContext$ as Observable<AddOrderEntriesContext>;
        }
    }

}
