import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConverterService, Occ, OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CART_MODIFICATION_NORMALIZER, CartModification } from '@spartacus/cart/base/root';
import {
    SikoCart,
    SikoCartModification,
    SikoSelectedDeliveryModeType,
    SikoSimulatedCartModification,
} from '@siko/models';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SikoCartAdapter {

    userId = '';
    private readonly backendBaseUrl: string;

    constructor(
      protected http: HttpClient,
      protected occEndpoints: OccEndpointsService,
      protected converterService: ConverterService,
      readonly userIdService: UserIdService,
    ) {
        this.backendBaseUrl = this.occEndpoints.getBaseUrl();
        this.userIdService.getUserId().pipe(
            distinctUntilChanged()
        )
            .subscribe((userId: string) => {
                this.userId = userId;
            });
    }

    public update(
        userId: string,
        cartId: string,
        entryNumber: string,
        qty: number,
        pickupStore?: string
    ): Observable<SikoCartModification> {
        let params = {};
        if (pickupStore) {
            params = {
                deliveryPointOfService: {
                    name: pickupStore,
                },
            };
        }

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const url = this.occEndpoints.buildUrl('updateEntries', {
            urlParams: {
                userId,
                cartId,
                entryNumber,
            },
        });

        return this.http
            .patch<CartModification>(url, { quantity: qty, ...params }, { headers })
            .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
    }

    validateCart(cartId: string): Observable<SikoCartModification[]>{
        return this.http.get<SikoCartModification[]>(
            `${this.backendBaseUrl}/b2b/users/${this.userId}/carts/${cartId}/validate-entries`
        );
    }

    saveInvalidEntries(cartId: string, saveCartName: string): Observable<object>{
        return this.http
            .post(`${this.backendBaseUrl}/b2b/users/${this.userId}/carts/${cartId}/save-invalid-entries?saveCartName=${saveCartName}`, {});
    }

   setPartialDelivery(cartId: string, value: boolean, entryNumber: number): Observable<SikoCart>{
       return this.http
           .post<SikoCart>(`${this.backendBaseUrl}/b2b/users/${this.userId}/carts/${cartId}/entries/${entryNumber}/set-partial-delivery?isAllowed=${value}`, {});
    }

    deleteInvalidEntries(cartId: string): Observable<object>{
        return this.http
            .delete(`${this.backendBaseUrl}/b2b/users/${this.userId}/carts/${cartId}/delete-invalid-entries`, {});
    }

    getSimulatedDeliveryChange(userId: string, cartId: string, deliveryType: 'BAL_net' | 'pickup' | 'ZAV_net', pos: string | undefined, shipToUnit: string | undefined): Observable<SikoSimulatedCartModification[]> {
        let url = `${this.backendBaseUrl}/b2b/users/${userId}/carts/${cartId}/simulatedeliverychange?delivery=${deliveryType}&fields=SIMULATE_DELIVERY_CHANGE`;

        if (pos !== undefined) {
            url += `&pos=${pos}`;
        }

        if (shipToUnit !== undefined) {
            url += `&shipToUnit=${shipToUnit}`;
        }

        return this.http.get<SikoSimulatedCartModification[]>(url);
    }

    setDeliveryModeChange(userId: string, cartId: string, deliveryType: SikoSelectedDeliveryModeType, pos: string | undefined, shipToUnit: string | undefined): Observable<any> {
        let url = `${this.backendBaseUrl}/b2b/users/${userId}/carts/${cartId}/deliverychange?delivery=${deliveryType}`;

        if (pos !== undefined) {
            url += `&pos=${pos}`;
        }

        if (shipToUnit !== undefined) {
            url += `&shipToUnit=${shipToUnit}`;
        }

        return this.http.patch(url, {});
    }

    getReducedSavedCarts(): Observable<Occ.CartList> {
        return this.http.get<Occ.CartList>( `${this.backendBaseUrl}/b2b/users/${this.userId}/carts?savedCartsOnly=true&fields=carts(code,totalPrice(FULL),totalItems,saveTime,user,name,description)`);
    }

}
