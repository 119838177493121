import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SikoNavigationNode } from '@siko/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-promo-banner',
    templateUrl: './promo-banner.component.html',
    styleUrls: ['./promo-banner.component.scss'],
})
export class PromoBannerComponent {

    @Input() data?: SikoNavigationNode;

}
