<div class='d-flex wrapper' [ngClass]='{"justify-content-end": alignRight}'>
  <div>
    <div class='price' [ngClass]='
    {"mo-price" : MOPrice,
     "final-price" : finalPriceSmall || finalPriceBig,
     "lg" : finalPriceBig,
     "sm": finalPriceSmall}
'> {{price}}&nbsp;</div>
  </div>
  <ng-container *ngIf='showTaxLabel'>
    <div class=' muted text-nowrap'>
      <ng-container *ngIf='withTax else withoutTax'>
        ({{'siko.withVat' | cxTranslate}})
      </ng-container>
      <ng-template #withoutTax>
        ({{'siko.withoutVat' | cxTranslate}})
      </ng-template>
    </div>&nbsp;
  </ng-container>
</div>
