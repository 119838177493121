import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import { SikoProductDetailDialogComponent } from '@siko/features/shared-components/product-detail-dialog/components/product-detail-dialog/product-detail-dialog.component';
import { SikoItemCounterModule } from '@siko/features/shared-components/item-counter/item-counter.module';
import { SikoDialogComponentsModule } from '@siko/features/shared-components/dialog-components/dialog-components.module';
import { SikoDetailLoadingSkeletonComponent } from './components/detail-loading-skeleton/detail-loading-skeleton.component';
import { SikoProductAvailabilityInfoComponent } from '@siko/features/shared-components/product-availability-info/product-availability-info.component';
import { SikoMediaComponent } from '@siko/features/shared-components/media/media.component';
import { SikoProductAddToCartButtonComponent } from '@siko/features/shared-components/product-add-to-cart-button/product-add-to-cart-button.component';
import { SikoProductHeaderDescriptionComponent } from '@siko/features/shared-components/product-header-description/product-header-description.component';
import { SikoProductPriceComponent } from '@siko/features/shared-components/product-price/product-price.component';
import { SikoProductHeaderTitleComponent } from '@siko/features/shared-components/product-header-title/product-header-title.component';
import { SikoProductGroupComponent } from '@siko/features/shared-components/product-group/product-group.component';
import { SikoProductRatingComponent } from '@siko/features/shared-components/product-rating/product-rating.component';
import { SikoInfoBannerModule } from '@siko/features/shared-components/info-banner-component/info-banner.module';


@NgModule({
    declarations: [
        SikoProductDetailDialogComponent,
        SikoDetailLoadingSkeletonComponent,
    ],
    imports: [
        B2bCommonModule,
        SikoItemCounterModule,
        SikoProductHeaderDescriptionComponent,
        SikoProductPriceComponent,
        SikoProductHeaderTitleComponent,
        SikoDialogComponentsModule,
        SikoProductGroupComponent,
        SikoProductAddToCartButtonComponent,
        SikoMediaComponent,
        SikoProductRatingComponent,
        SikoProductAvailabilityInfoComponent,
        SikoInfoBannerModule,
    ],
    exports: [
        SikoProductDetailDialogComponent,
    ],
})
export class SikoProductDetailDialogModule {}
