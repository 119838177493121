import { PaginationConfig } from '@spartacus/storefront';

export const SIKO_PAGINATION_CONFIG: PaginationConfig = {
    pagination: {
        rangeCount: 3,
        dotsLabel: '...',
        startLabel: '«',
        previousLabel: '‹',
        nextLabel: '›',
        endLabel: '»',
        addFirst: true,
        addLast: true,
        addDots: true,
        addEnd: true,
        addStart: true,
        substituteDotsForSingularPage: true
    },
};
