<ng-container *ngIf='product$ | async as product'>
  <app-title-with-count
    *ngIf='displayHeader'
    [text]="'pdp.tabs.productInformation' | cxTranslate"
  ></app-title-with-count>
  <ng-container *ngIf='product.description else noProductInformation'>
    <div class='product-description-tab siko-cms-content' [innerHTML]="product.description | sanitize: 'html'"></div>
  </ng-container>
  <ng-template #noProductInformation>
    <p>{{'pdp.tabs.noProductInformation' | cxTranslate}}</p>
    <p>{{'pdp.tabs.contactOurSupportMessage' | cxTranslate}}</p>
  </ng-template>
</ng-container>
