import { Injectable } from '@angular/core';
import {
    CsvFileValidationErrors, FileReaderService,
    LaunchDialogService,
} from '@spartacus/storefront';
import { ProductData } from '@spartacus/cart/base/root';
import { Papa, ParseResult } from 'ngx-papaparse';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ValidationErrors } from '@angular/forms';
import { isNotUndefined } from '@spartacus/core';


@Injectable({
    providedIn: 'root',
})
export class SikoImportCsvValidator {

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected fileReaderService: FileReaderService,
        protected csvParser: Papa,
    ) {}

    /**
     * Combined csv validation
     *
     * @param file File we want to load as CSV.
     * @param isDataParsable (optional) Callback for verify that structure type is proper.
     * @param maxEntries (optional) Limitation for maximum entries count.
     * @return {Observable<CsvFileValidationErrors | null>} Result of validation
     */
    validateFile(
        file: File,
        {
            isDataParsable,
            maxEntries,
        }: {
            isDataParsable?: (data: ParseResult) => boolean;
            maxEntries?: number;
        }
    ): Observable<CsvFileValidationErrors | null> {
        const validationErrors: CsvFileValidationErrors = {};

        return (
            this.fileReaderService.loadTextFile(file) as Observable<string>
        ).pipe(
            tap((data: string) => {
                this.validateEmpty(data, validationErrors);
            }),
            map((res) =>
                this.csvParser.parse(res, {})),
            tap((parseResult: ParseResult) => {
                this.validateNotParsable(parseResult, validationErrors, isDataParsable);
                this.validateTooManyEntries(parseResult, validationErrors, maxEntries);
            }),
            catchError((errors) => of(errors)),
            map(() =>
                Object.keys(validationErrors).length === 0 ? null : validationErrors)
        );
    }

    csvDataToProduct(data: string[][], ignoreHeader: boolean): ProductData[] {
        return data.filter((value: string[], index: number) => !(ignoreHeader && index === 0) && value[0] !== '')
            .map((row: string[]) => {
                const productCode = row[0];
                const rawQuantity = row[1];

                if (typeof rawQuantity !== 'string' || typeof productCode !== 'string') {
                    throw new Error("Invalid file");
                }

                const quantity = Number(rawQuantity.replace(',', '.'));

                return {
                    productCode,
                    quantity,
                };
            });
    }

    protected validateEmpty(data: string, errors: ValidationErrors): void {
        if (data.toString().length === 0) {
            errors.empty = true;
            throw errors;
        }
    }

    protected validateTooManyEntries(
        parseResult: ParseResult,
        errors: ValidationErrors,
        maxEntries?: number
    ): void {
        if (maxEntries && parseResult.data.length > maxEntries) {
            errors.tooManyEntries = { maxEntries };
            throw errors;
        }
    }

    protected validateNotParsable(
        parseResult: ParseResult,
        errors: ValidationErrors,
        isDataParsable?: (parseResult: ParseResult) => boolean
    ): void {
        if (isDataParsable && !isDataParsable(parseResult)) {
            errors.notParsable = true;
            throw errors;
        }
    }

    /**
     * Validates if data can be parsed to product and its quantity
     *
     * @param parseResult raw CSV data
     * @returns boolean value. True if data can be processed, false otherwise.
     */
    isDataParsableToProducts(parseResult: ParseResult): boolean {
        const validateFileContent = new RegExp(/^(\d+)(,\d{1,3}|\.\d{1,3})?$/);

        return parseResult.data.length > 0 && parseResult.data
            .filter(isNotUndefined)
            .filter((row: string) => row[0].startsWith(''))
            .filter((value: string[], index: number) => !(index === 0) && value[0] !== '')
            .every((row: string) => validateFileContent.test(row[1]));
    }

}
