import { ErrorHandler, Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        readonly globalMessageService: GlobalMessageService,
    ) {}

    handleError(error: any): void {
        const chunkErrorMessage = /Loading chunk [\d]+ failed/;
        const maintenanceError = '0 Unknown Error';

        if (error.message.includes(maintenanceError)) {
            this.globalMessageService.add({ key: 'httpHandlers.maintenanceError' }, GlobalMessageType.MSG_TYPE_WARNING);
        }

        if (chunkErrorMessage.test(error.message)) {
            window.location.reload();
        }

        // Delegate error handling to Sentry's ErrorHandler
        Sentry.captureException(error);
    }

}
