import { SikoActionLoader } from '@siko/features/action-loader/models/action-loader.model';
import { StateUtils } from '@spartacus/core';

export const SIKO_ACTION_LOADER_DATA = '[Action-loader] Action Loader Data';
export const ADD_ACTION = '[Action-loader] Add an Action Loader';
export const REMOVE_ACTION = '[Action-loader] Remove an Action Loader';

export class AddAction implements StateUtils.EntityAction {
    readonly type = ADD_ACTION;

    constructor(public payload: SikoActionLoader) {}
}

export class RemoveAction extends StateUtils.EntityRemoveAction {
    readonly type = REMOVE_ACTION;

    constructor(public uid: string) {
        super(SIKO_ACTION_LOADER_DATA, uid);
    }
}

export type SikoActionLoaderAction = AddAction | RemoveAction;
