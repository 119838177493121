import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import {
    SIKO_SELECTED_DELIVERY_MODE_ENUM,
    SikoCart,
    SikoCartModification,
    SikoOrderEntry,
    SikoPointOfService,
    SikoProductUnitData,
    SikoUser,
} from '@siko/models';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isNotNullable } from '@spartacus/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { SikoAutoUnsubscribe } from '@siko/common';
import { UserAccountFacade } from '@spartacus/user/account/root';

@SikoAutoUnsubscribe(['cartSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-added-to-cart-dialog-item',
    templateUrl: './added-to-cart-dialog-item.component.html',
    styleUrls: ['./added-to-cart-dialog-item.component.scss'],
})
export class SikoAddedToCartDialogItemComponent implements OnInit, OnChanges {

    @Input() orderEntry?: SikoOrderEntry;
    @Input() cartModification?: SikoCartModification;

    calculatedAddedQuantity?: number;
    selectedPOS?: SikoPointOfService;
    isDelivery = false;
    isDeliveryModeSelected = false;
    isShipment: boolean = false;


    sikoUser$: Observable<SikoUser> = this.userProfileFacade.get()
        .pipe(
            filter(isNotNullable),
        );
    cartSubscription = new Subscription();

    constructor(
        readonly userProfileFacade: UserAccountFacade,
        readonly activeCartService: ActiveCartFacade
    ) {}

    ngOnInit(): void {
        this.cartSubscription.add(
            this.activeCartService.getActive()
                .pipe(filter(isNotNullable))
                .subscribe((cart: SikoCart) => {
                    this.isDeliveryModeSelected = cart.selectedDeliveryPOS !== undefined || cart.selectedDeliveryMode !== undefined;
                    this.selectedPOS = cart.selectedDeliveryPOS;
                    this.isDelivery = cart.selectedDeliveryMode?.code === SIKO_SELECTED_DELIVERY_MODE_ENUM.BAL_NET;
                    this.isShipment = cart.selectedDeliveryMode?.code === SIKO_SELECTED_DELIVERY_MODE_ENUM.ZAV_NET;
                })
        );
    }

    ngOnChanges(): void {
        if (this.orderEntry && this.orderEntry.product?.unitData && this.orderEntry?.quantity) {
            this.calculatedAddedQuantity = this.calculateAddedQuantity(this.orderEntry.product.unitData, this.cartModification?.quantityAdded);
        }
    }

    private calculateAddedQuantity(unitData: SikoProductUnitData, quantity: number | undefined): number | undefined {
        if (!unitData?.conversion) {return;}
        if (quantity === undefined) {return;}

        return +(+unitData.conversion * quantity).toFixed(3);
    }

}
