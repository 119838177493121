<ng-container *ngIf='sikoBreakpointObserver.breakpointChange$ | async'>
<ng-container *ngIf='product'>
  <a
    class='d-flex flex-column h-100'
    [routerLink]='product | sikoProductUrl'
  >
    <div>
      <div class='d-flex label-container'>
        <app-product-label *ngFor='let label of product.productLabels' [label]='label'
                           class='label'></app-product-label>
      </div>
      <div class='cx-product-image'>
        <ng-container *ngIf='sikoBreakpointObserver.isBreakpointActive(["xs","sm","md"]) else desktopLabels'>
          <div class='labels'>
            <siko-label [labels]='product.labels' [maxLabels]='3'></siko-label>
          </div>
        </ng-container>
        <ng-template #desktopLabels>
          <div class='labels'>
            <siko-label [labels]='product.labels'></siko-label>
          </div>
        </ng-template>
        <app-media
          class='img-responsive'
          [container]='product.images?.PRIMARY'
          format='product'
          [alt]='product.summary'
        ></app-media>
      </div>
      <div [ngClass]='product.averageRating ? "mt-3" : "rating-container"'>
        <siko-star-rating
          [style.visibility]="product.averageRating ? 'visible' : 'hidden'"
          [disabled]='true'
          [ratingCount]='5'
          [resetAble]='true'
          [value]='product.averageRating ? product.averageRating : 0'
          [countReviews]='product.numberOfReviews'
          size='md'
        ></siko-star-rating>
      </div>
      <div class='product-name'>
        {{product.name}}
      </div>
      <div class='product-recalculation-container'
           [ngClass]='updatePriceSuccess.getValue() || updatePriceFailed.getValue() ? "not-hover-effect" : ""'
           (click)='updateProductPrice(product.code,$event)'
           ngbTooltip
           sikoTooltipText="{{'siko.updatePrice' | cxTranslate}}"
           [disableTooltip]='sikoBreakpointObserver.isBreakpointActive(["xs","sm","md"]) || updatePriceSuccess.getValue() || updatePriceFailed.getValue()'
           [openDelay]=400>
        <div class='cx-product-price-container'>
          <ng-container *ngIf='updatedPrice | async as price'>
            <div [ngClass]='this.isUpdating.getValue() ? "animate-price" : ""'>
              <div
                [ngClass]='product?.discountPrice ? "sale-price" : ""'
                class='cx-product-price'
                [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
              ><app-price [price]='price' [MOPrice]='!updatePriceSuccess.getValue()'></app-price>
              </div>
            </div>
          </ng-container>
        </div>
        <div class='d-flex action-wrapper'>
          <app-product-group [sikoProduct]='product' *ngIf='!product.discountPrice && product.bonusGroup'></app-product-group>
          <ng-container
            *ngIf='!updatePriceSuccess.getValue() && !updatePriceFailed.getValue() else recalculationProcessed'>
            <span class='product-recalculate-icon-wrapper'>
              <span class='product-recalculate-icon'
                    [ngClass]="this.isUpdating.getValue() ? 'product-recalculate-icon--animated' : ''"></span>
            </span>
          </ng-container>
          <ng-template #recalculationProcessed>
          <ng-container *ngIf='updatePriceSuccess.getValue()'>
            <div class='success-icon'></div>
          </ng-container>
          <ng-container *ngIf='updatePriceFailed.getValue()'>
            <span class='fail-icon'></span>
          </ng-container>
        </ng-template>
        </div>
      </div>
      <div class='mt-auto pt-3'>
        <app-product-disponibility
          *ngIf='!isStoreDisponibility'
          [sikoProduct]=product
          [isStoreDisponibility]='false'
        >
        </app-product-disponibility>
        <app-store-product-disponibility
          *ngIf='isStoreDisponibility'
          [plp]='true'
          [unit]='product.unitData'
          [productDisponibility]='product.disponibility.storeDisponibility'
          [sapStatus]='product.sapStatus'>
        </app-store-product-disponibility>
      </div>
      <div class='cx-product-summary' *ngIf='product.summary' [innerHTML]='product.summary.substr(0, 100) + "..."'>
      </div>
    </div>
    <div class='mt-auto d-flex'>
      <ng-container *ngIf='!product?.configurable'>
        <div class='cx-product-show-detail'>
          <siko-button
            testId='show-product-detail-button'
            class='show-detail-btn'
            mode='secondary'
            size='sm'
            [routerLink]='product | sikoProductUrl'
            [text]='"siko.showDetails"|cxTranslate'
          >
          </siko-button>
        </div>
      </ng-container>
    </div>
  </a>
</ng-container>
</ng-container>


