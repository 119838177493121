import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { SikoOrderHistory, SikoSapQuote, SikoTicket } from '@siko/models';
import { Cart } from '@spartacus/cart/base/root';
import { SikoBonusProgramModel } from '../../models/bonus-program.model';

export type SortColumn = keyof Cart | keyof SikoOrderHistory | keyof SikoSapQuote | keyof SikoTicket | keyof SikoBonusProgramModel | '';
export type SortDirection = '' | 'asc' | 'desc';
const rotate: { [key: string]: SortDirection } = {
    asc: 'desc',
    desc: '',
    '': 'asc',
};

export interface SortEvent {
    column: SortColumn;
    direction: SortDirection;
}

@Directive({
    selector: 'th[sortable]',
    host: {
        '[class.asc]': 'direction === "asc"',
        '[class.desc]': 'direction ==="desc"',
        '(click)': 'rotate()',
    },
    standalone: true,
})
export class SikoNgbB2BDatatableSortableHeaderDirective {

    @Input() sortable: SortColumn = '';
    @Input() direction: SortDirection = '';
    @Output() readonly sort = new EventEmitter<SortEvent>();


    rotate(): void {
        this.direction = rotate[this.direction];
        this.sort.emit({
            column: this.sortable,
            direction: this.direction,
        });
    }

}
