<ng-container *ngIf="product$ | async as product">
  <div class="review-form-container">
    <form (ngSubmit)="submitReview(product)" [formGroup]="reviewForm">
      <div id="js--write-review">
        <h3 class="add-review-title">{{'pdp.reviewTab.title' | cxTranslate}}</h3>
        <span class="siko-font-primary-semiBold siko-fs-14 mb-8">
            {{'pdp.reviewTab.yourRating' | cxTranslate}}
        </span>
        <div class="mb-24">
          <siko-star-rating
            [ratingCount]="5"
            [resetAble]="true"
            [countReviews]=""
            [value]="reviewForm.controls.rating.value"
            (valueChange)="setRating($any($event))"
            size="xl"
          ></siko-star-rating>
        </div>
        <div class="mb-24">
          <siko-text-area
            id='reviewContent'
            name='reviewContent'
            [label]="'pdp.reviewTab.yourReview' | cxTranslate"
            [control]="$any(reviewForm.controls.comment)"
          >
          </siko-text-area>
        </div>
        <div class="mb-24">
          <siko-input-field
            id='reviewerName'
            name='reviewerName'
            [label]="'pdp.reviewTab.alias' | cxTranslate"
            [control]="$any(reviewForm.controls.reviewerName)"
          ></siko-input-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <siko-button
            testId='send-review-button'
            size="md"
            [block]="true"
            [disabled]="!reviewForm.valid"
            [text]='"pdp.reviewTab.send" | cxTranslate'
            (clickEvent)="submitReview(product)"
          >
          </siko-button>
        </div>
      </div>
    </form>
  </div>
</ng-container>
