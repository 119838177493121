<div [ngClass]='buttonWrapperClassList'>
  <div
    [attr.data-testid]='$any("delivery-button")'
    class='d-flex justify-content-center align-items-center'
    [ngClass]='this.topTextClassList'
    (click)='onClick($event)'
  >
    <div [ngClass]='iconClass'>
    </div>
    <div>
      <div *ngIf='textTop' class='text-top'>
        {{ textTop }}
      </div>
    </div>
  </div>
</div>
