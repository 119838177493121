import { NgModule } from '@angular/core';
import {
    AuthConfig,
    AuthHttpHeaderService,
    AuthRedirectService,
    AuthService,
    ConfigModule,
    HttpErrorHandler,
    isNotNullable,
    isNotUndefined,
    ProtectedRoutesGuard,
    ProtectedRoutesService,
    provideConfig,
    provideConfigFactory,
    RoutingService,
    SiteContextConfig,
    UserIdService,
} from '@spartacus/core';
import { Store } from '@ngrx/store';
import { translationChunksConfig, translations } from '@spartacus/assets';
import { StateWithSikoNews } from '@siko/features/news/store/siko-news-state';
import {
    CmsPageGuard,
    LogoutGuard,
    OutletPosition,
    PageLayoutComponent,
    provideOutlet,
} from '@spartacus/storefront';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { RouterModule } from '@angular/router';
import { SikoNewsPageOutletComponent } from '@siko/features/outlets/components/news-page-template/news-page-template.component';
import { SikoMyAccountOutletComponent } from '@siko/features/outlets/components/my-account-wrapper/my-account-wrapper.component';
import { SikoOrderConfirmationOutletComponent } from '@siko/features/outlets/components/order-confirmation-wrapper/order-confirmation-outlet.component';
import { SikoCheckoutOutletComponent } from '@siko/features/outlets/components/checkout-template/checkout-outlet.component';
import { SikoUtils } from '@siko/shared';
import { AsmAuthService, AsmConfig, CsAgentAuthService } from '@spartacus/asm/root';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LIBRARY_TRANSLATIONS } from '@siko/components';
import { CheckoutDeliveryAddressEventListener } from '@spartacus/checkout/base/root';
import { CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CheckoutB2BAuthGuard } from '@spartacus/checkout/b2b/components';
import { SikoProductGridOutletComponent } from '@siko/features/outlets/components/siko-product-grid/siko-product-grid-outlet.component';
import { UserAccountEventListener } from '@spartacus/user/account/root';
import { CheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/core';
import { SikoOccCheckoutPaymentTypeAdapter } from '@siko/features/checkout/occ/adapters/occ-checkout-payment-type.adapter';
import { SikoAsmAuthCorrectionService } from '@siko/shared/services/asm-auth-correction.service';
import { SikoAsmComponentService } from '@siko/features/asm/services/asm-component.service';
import { AsmComponentService } from '@spartacus/asm/components';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { SikoProductDetailsPageOutletComponent } from '@siko/features/outlets/components/product-details-page-template/product-details-page-template.component';
import { SikoSearchOutletComponent } from '@siko/features/outlets/components/siko-search-outlet/siko-search-outlet.component';
import { SikoConfigurableRoutesService, SikoProtectedRoutesService } from './siko/routing';
import { SIKO_GLOBAL_TRANSLATIONS } from '@siko/translations';
import { SikoNewsActions } from './siko/features/news/store/actions';
import { SikoAuthenticationErrorHandler } from './siko/interceptors/authentication-error.handler';
import { SikoValidationErrorHandler } from './siko/interceptors/validation-error.handler';
import { SikoCartValidationGuard } from './siko/guards/cart-validation.guard';
import { SikoCmsPageGuard } from './siko/guards/siko-cms-page.guard';
import { SikoCheckoutDeliveryAddressEventListener, SikoUserAccountEventListener } from './siko/event-listeners';
import { SikoAuthHttpHeaderService } from './siko/auth/user-auth/services/auth-http-header.service';
import { SikoCsAgentAuthService } from '@siko/features/asm/services/csagent-auth.service';
import { SikoBlogListOutletComponent } from '@siko/features/outlets/components/blog/blog-list/blog-list-outlet.component';
import { SikoBlogDetailOutletComponent } from '@siko/features/outlets/components/blog/blog-detail/blog-detail-outlet.component';
import { SikoHttpInterceptor } from './siko/interceptors/siko-http.interceptor';
import { SikoLogoutGuard } from './siko/guards/logout.guard';
import { SikoProtectedRoutesGuard } from './siko/guards/protected-routes.guard';
import { ClearOrderStateGuard } from './siko/guards/clear-order-state.guard';
import { SikoConfigurationTemplateComponent } from '@siko/features/outlets/components/configuration-template/configuration-template.component';
import { SikoHeaderOutletComponent } from '@siko/features/header';
import { FooterOutletReplaceComponent } from '@siko/features/footer';
import { SikoAsmMainUiComponent } from '@siko/features/asm/components/asm-main-ui/asm-main-ui.component';
import { SikoBadGatewayHandler } from './siko/interceptors/bad-gateway.handler';
import { sikoTranslationChunksConfig } from '../assets/i18n/translation-chunks-config';

export const PRODUCT_DETAIL_EP = 'orgProducts/product/${productCode}?fields=FULL';
export const USER_ANONYMOUS_UID = 'anonymous';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'my-account/sap-order/:sapOrderCode',
                component: PageLayoutComponent,
                canActivate: [CmsPageGuard],
            },
            {
                path: 'my-account/orders',
                component: PageLayoutComponent,
                canActivate: [CmsPageGuard],
                canDeactivate: [ClearOrderStateGuard],
            },
            {
                path: 'my-account/orders/:orderCode',
                component: PageLayoutComponent,
                canActivate: [CmsPageGuard],
                canDeactivate: [ClearOrderStateGuard],
            },
            {
                path: 'organization/units/:unitCode/documents/create-ticket/:code/:documentNumber',
                component: PageLayoutComponent,
                canActivate: [CmsPageGuard],
            },
            {
                path: 'organization/units/:unitCode/tickets',
                component: PageLayoutComponent,
                canActivate: [CmsPageGuard],
            },
            {
                path: 'organization/units/:unitCode/tickets/:ticketCode',
                component: PageLayoutComponent,
                canActivate: [CmsPageGuard],
            },
        ]),
        ConfigModule.withConfig({
            pagination: {
                addFirst: true,
                addLast: true,
                addDots: true,
            },
        }),
    ],
    providers: [
        provideOutlet({
            id: 'ProductDetailsPageTemplate',
            position: OutletPosition.REPLACE,
            component: SikoProductDetailsPageOutletComponent,
        }),
        provideOutlet({
            id: 'SikoNewsPageTemplate',
            position: OutletPosition.REPLACE,
            component: SikoNewsPageOutletComponent,
        }),
        provideOutlet({
            id: 'AccountPageTemplate',
            position: OutletPosition.REPLACE,
            component: SikoMyAccountOutletComponent,
        }),
        provideOutlet({
            id: 'ProductGridPageTemplate',
            position: OutletPosition.REPLACE,
            component: SikoProductGridOutletComponent,
        }),
        provideOutlet({
            id: 'SearchResultsGridPageTemplate',
            position: OutletPosition.REPLACE,
            component: SikoSearchOutletComponent,
        }),
        provideOutlet({
            id: 'OrderConfirmationPageTemplate',
            position: OutletPosition.REPLACE,
            component: SikoOrderConfirmationOutletComponent,
        }),
        provideOutlet({
            id: 'MultiStepCheckoutSummaryPageTemplate',
            position: OutletPosition.REPLACE,
            component: SikoCheckoutOutletComponent,
        }),
        provideOutlet({
            id: 'SikoBlogPageTemplate',
            position: OutletPosition.REPLACE,
            component: SikoBlogListOutletComponent,
        }),
        provideOutlet({
            id: 'SikoBlogDetailPageTemplate',
            position: OutletPosition.REPLACE,
            component: SikoBlogDetailOutletComponent,
        }),
        provideOutlet({
            id: 'VariantConfigurationTemplate',
            position: OutletPosition.REPLACE,
            component: SikoConfigurationTemplateComponent,
        }),
        provideOutlet({
            id: 'cx-header',
            position: OutletPosition.REPLACE,
            component: SikoHeaderOutletComponent,
        }),
        provideOutlet({
            id: 'cx-footer',
            position: OutletPosition.REPLACE,
            component: FooterOutletReplaceComponent,
        }),
        provideConfig({
            backend: {
                occ: {
                    endpoints: {
                        product: {
                            default: PRODUCT_DETAIL_EP,
                            details: PRODUCT_DETAIL_EP,
                            attributes: PRODUCT_DETAIL_EP,
                            price: PRODUCT_DETAIL_EP,
                            list: PRODUCT_DETAIL_EP,
                            stock: PRODUCT_DETAIL_EP,
                            variants: PRODUCT_DETAIL_EP,
                            list_item: PRODUCT_DETAIL_EP,
                        },
                        cart: 'users/${userId}/carts/current?fields=FULL',
                        paymentTypes: 'orgUsers/${userId}/paymenttypes',
                        storescounts: '/stores/storescounts',
                        // CWS overriden routes with prefix
                        deleteCart: 'b2b/users/${userId}/carts/${cartId}',
                        setDeliveryAddress: 'b2b/users/${userId}/carts/${cartId}/addresses/delivery',
                        userResetPassword: 'b2b/resetpassword',
                        cartVoucher: 'b2b/users/${userId}/carts/${cartId}/vouchers',
                        orgUnitsTree: '/b2b/users/${userId}/orgUnitsRootNodeTree',
                        stores: '/b2b/stores?fields=FULL',
                        removeEntries: 'b2b/users/${userId}/carts/${cartId}/entries/${entryNumber}',
                        userUpdatePassword: 'b2b/users/${userId}/password',
                        savedCart: 'b2b/users/${userId}/carts/${cartId}/savedcart',
                        restoreSavedCart: 'b2b/users/${userId}/carts/${cartId}/restoresavedcart',
                        cloneSavedCart: 'b2b/users/${userId}/carts/${cartId}/clonesavedcart?name=${saveCartName}',
                        savedCarts:
                            'b2b/users/${userId}/carts?savedCartsOnly=true&fields=carts(DEFAULT,entries(price(formattedValue,value),product(images(FULL),stock(FULL),unitData(FULL))),totalPrice(FULL),totalItems,saveTime,user,name,description)',
                        saveCart:
                            'b2b/users/${userId}/carts/${cartId}/save?saveCartName=${saveCartName}&saveCartDescription=${saveCartDescription}',
                        carts: 'b2b/users/${userId}/carts?fields=carts(DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description)',
                        userUpdateProfile: 'b2b/users/${userId}',
                        updateEntries: 'b2b/users/${userId}/carts/${cartId}/entries/${entryNumber}',
                        createDeliveryAddress: 'b2b/users/${userId}/carts/${cartId}/addresses/delivery',
                        removeDeliveryAddress: 'b2b/users/${userId}/carts/${cartId}/addresses/delivery',
                        orderDetail: 'b2b/users/${userId}/orders/${orderId}?fields=DEFAULT',
                        orderHistory: 'b2b/users/${userId}/orders?fields=FULL',
                        userForgotPassword: 'b2b/forgottenpasswordtokens',
                        productSearch: 'b2b/products/search?fields=FULL',
                        productReferences: 'b2b/products/${productCode}/references?fields=FULL',
                        productReviews: 'b2b/products/${productCode}/reviews',
                        setDeliveryMode: 'b2b/users/${userId}/carts/${cartId}/deliverymode',
                        clearDeliveryMode: 'b2b/users/${userId}/carts/${cartId}/deliverymode',
                        createCart: 'b2b/users/${userId}/carts?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user',
                        validate: 'b2b/users/${userId}/carts/${cartId}/validate?fields=DEFAULT',
                        claimCoupon: 'b2b/users/${userId}/customercoupons/${couponCode}/claim',
                    },
                },
            },
            launch: {
                ASM: {
                    outlet: 'cx-storefront',
                    component: SikoAsmMainUiComponent,
                },
            },
        }),
        provideConfigFactory(() => {
            const siteContext: SiteContextConfig | undefined = SikoUtils.getSiteContext(false);
            const authConfig = SikoUtils.getAuthConfig();
            const baseSiteUid = siteContext ? siteContext.context?.baseSite : '';

            return {
                authentication: {
                    tokenEndpoint: `/oauth/token?b2b=true&baseSite=${baseSiteUid}`,
                    ...authConfig.authentication,
                },
            } as AuthConfig;
        }),
        provideConfigFactory(() => {
            const siteContext: SiteContextConfig | undefined = SikoUtils.getSiteContext(false);
            const baseSiteUid = siteContext ? siteContext.context?.baseSite : '';

            return {
                backend: {
                    occ: {
                        endpoints: {
                            asmBindCart: `/occ/v2/${baseSiteUid}/b2b/bind-cart`,
                            // ... Potentially other dynamic endpoints
                        },
                    },
                },
            };
        }),
        provideConfig({
            i18n: {
                resources: translations,
                chunks: translationChunksConfig,
            },
        }),
        provideConfig(
            {
                i18n: {
                    backend: {
                        loader: (lng: string, ns: string) => import(`../assets/i18n/${lng}/${ns}.json`),
                    },
                    chunks: sikoTranslationChunksConfig
                },
            },
        ),
        provideConfig({
            i18n: {
                resources: SIKO_GLOBAL_TRANSLATIONS,
            },
        }),
        provideConfig({
            i18n: {
                resources: LIBRARY_TRANSLATIONS,
            },
        }),
        provideConfig({
            asm: {
                userIdHttpHeader: {
                    enable: true,
                },
            },
        } as AsmConfig),
        {
            provide: ProtectedRoutesService,
            useClass: SikoProtectedRoutesService,
        },
        {
            provide: AuthHttpHeaderService,
            useExisting: SikoAuthHttpHeaderService,
        },
        {
            provide: AsmComponentService,
            useExisting: SikoAsmComponentService,
        },
        {
            provide: CmsPageGuard,
            useExisting: SikoCmsPageGuard,
        },
        {
            provide: ProtectedRoutesGuard,
            useExisting: SikoProtectedRoutesGuard,
        },
        {
            provide: HttpErrorHandler,
            useExisting: SikoValidationErrorHandler,
            multi: true,
        },
        {
            provide: HttpErrorHandler,
            useExisting: SikoAuthenticationErrorHandler,
            multi: true,
        },
        {
            provide: HttpErrorHandler,
            useExisting: SikoBadGatewayHandler,
            multi: true,
        },
        {
            provide: CartValidationGuard,
            useClass: SikoCartValidationGuard,
        },
        {
            provide: LogoutGuard,
            useClass: SikoLogoutGuard,
        },
        {
            provide: UserAccountEventListener,
            useClass: SikoUserAccountEventListener,
        },
        {
            provide: CsAgentAuthService,
            useClass: SikoCsAgentAuthService,
        },
        {
            provide: CheckoutDeliveryAddressEventListener,
            useClass: SikoCheckoutDeliveryAddressEventListener,
        },
        {
            provide: CheckoutAuthGuard,
            useExisting: CheckoutB2BAuthGuard,
        },
        {
            provide: CheckoutPaymentTypeAdapter,
            useClass: SikoOccCheckoutPaymentTypeAdapter,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SikoHttpInterceptor,
            multi: true,
        },
    ],
})
export class CoreModule {

    constructor(
        readonly authRedirectService: AuthRedirectService,
        readonly store: Store<StateWithSikoNews>,
        readonly authService: AuthService,
        readonly configurableRoutesService: SikoConfigurableRoutesService,
        readonly asmAuthService: AsmAuthService,
        readonly asmAuthCorrectionService: SikoAsmAuthCorrectionService,
        readonly userIdService: UserIdService,
        readonly routingService: RoutingService,
    ) {
        combineLatest([
            this.userIdService.getUserId()
                .pipe(distinctUntilChanged()),
            this.authService.isUserLoggedIn()
                .pipe(distinctUntilChanged()),
        ])
            .pipe(
                filter(([userId, isLoggedIn]) => isLoggedIn && userId !== USER_ANONYMOUS_UID),
                map(() => true),
            )
            .subscribe(() => {
                this.store.dispatch(new SikoNewsActions.LoadUnseenNewsCount());
            });

        // This will ensure that when ASM is enabled, correct ASM client will be used when requesting token
        // Cannot be distinctUntilChanged!
        this.asmAuthService.isUserLoggedIn()
            .pipe(
                filter(isNotUndefined),
                filter(isNotNullable),
            )
            .subscribe(() => {
                this.asmAuthCorrectionService.resetOAuthConfig();
            });

        this.authService.logoutInProgress$
            .pipe(distinctUntilChanged())
            .subscribe((logoutInProgress: boolean) => {
                if (logoutInProgress) {
                    this.authRedirectService.setRedirectUrl('');
                }
            });

        this.configurableRoutesService.initOverrideRoutes();
        this.configurableRoutesService.initPublicRoutes();
    }

}
