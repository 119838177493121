import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentProductService, OutletModule } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { SikoProduct } from '@siko/models';
import { distinctUntilKeyChanged, filter, tap } from 'rxjs/operators';
import { isProductForSale } from '@siko/features/product/product-utils';
import { SikoTrackingUtils } from '@siko/shared/utils/siko-tracking-utils';
import { isNotNullable } from '@spartacus/core';
import { SikoAutoUnsubscribe } from '@siko/common';
import { B2bCommonModule, SikoScrolledToPdpDirective } from '@siko/shared';
import { SikoPageSlotModule } from '@siko/features/shared-components/page-slot/page-slot.module';
import { SikoSkeletonComponent } from '@siko/features/shared-components/skeleton/skeleton.component';

@SikoAutoUnsubscribe(['productSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-details-page-template',
    templateUrl: './product-details-page-template.component.html',
    styleUrls: ['./product-details-page-template.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoPageSlotModule,
        SikoScrolledToPdpDirective,
        SikoSkeletonComponent
    ]

})
export class SikoProductDetailsPageOutletComponent {

    forSale?: boolean = true;

    constructor(
        private readonly trackingUtils: SikoTrackingUtils,
        private readonly currentProductService: CurrentProductService,
    ) {}


    product$: Observable<SikoProduct | null> = this.currentProductService.getProduct()
        .pipe(
            tap((product: SikoProduct | null) => {
                if (product && product.disponibility && product.availableIn !== undefined && product.hasOwnProperty('sapStatus')) {
                    this.forSale = isProductForSale(product.disponibility, +product.availableIn, product.sapStatus!);
                }
            }),
        );

    productSubscription: Subscription = this.product$
        .pipe(
            filter(isNotNullable),
            distinctUntilKeyChanged('code'),
        )
        .subscribe(product => {
            this.trackingUtils.pushProductViewEvent(product);
        });

}
