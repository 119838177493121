import { Injectable } from '@angular/core';
import { LogoutGuard } from '@spartacus/storefront';
import { from, Observable } from 'rxjs';
import { UrlTree } from '@angular/router';
import { PageType } from '@spartacus/core';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SikoLogoutGuard extends LogoutGuard {
    canActivate(): Observable<boolean | UrlTree> {
        /**
         * First we want to complete logout process before redirecting to logout page
         * We want to avoid errors like `token is no longer valid`
         */
        return from(this.logout()).pipe(
            switchMap(() => {
                return this.cms
                    .hasPage({
                        id: this.semanticPathService.get('login') ?? '',
                        type: PageType.CONTENT_PAGE,
                    })
                    .pipe(
                        map((hasPage) => {
                            if (!hasPage) {
                                return this.getRedirectUrl();
                            }

                            return hasPage;
                        }),
                    );
            }),
        );
    }
}
