<ng-container *ngIf="data">
  <div class="d-flex bordered mb-2 pt-2 justify-content-between align-items-center">
    <div>
      <ng-container *ngIf="data.mode === 'INFORMATION'">
        <app-title-with-count
          [text]="'pdp.tabs.productInformation' | cxTranslate"
          [grey]="true"
        ></app-title-with-count>
      </ng-container>
      <ng-container *ngIf="data.mode === 'REVIEWS'">
        <div *ngIf="showThankYouMessage; else defaultTitle">
          <app-title-with-count
            [text]="'pdp.tabs.thankYouMessage' | cxTranslate"
            [grey]="true"
          ></app-title-with-count>
        </div>
        <ng-template #defaultTitle>
          <app-title-with-count
            [text]="'pdp.tabs.ratings' | cxTranslate"
            [grey]="true"
          ></app-title-with-count>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="data.mode === 'PARAMETERS'">
        <app-title-with-count
          [text]="'pdp.tabs.technicalParameters' | cxTranslate"
          [grey]="true"
        ></app-title-with-count>
      </ng-container>
      <ng-container *ngIf="data.mode === 'DOCUMENTS'">
        <app-title-with-count
          [text]="'pdp.tabs.documents' | cxTranslate"
          [grey]="true"
        ></app-title-with-count>
      </ng-container>
    </div>
    <div (click)="closeTemplateSheetMenu()" class="close-icon"></div>
  </div>
  <div class="row">
    <div class="col">
      <ng-container *ngIf="data.mode === 'INFORMATION'">
        <app-product-information
          [displayHeader]="false"
        ></app-product-information>
      </ng-container>
      <ng-container *ngIf="data.mode === 'REVIEWS'">
        <app-product-reviews-tab
          [displayHeader]="false"
        >
        </app-product-reviews-tab>
      </ng-container>
      <ng-container *ngIf="data.mode === 'PARAMETERS'">
        <app-product-detail-tab
          [displayHeader]="false"
        ></app-product-detail-tab>
      </ng-container>
      <ng-container *ngIf="data.mode === 'DOCUMENTS'">
        <app-product-documents-tab
          [displayHeader]="false"
        ></app-product-documents-tab>
      </ng-container>
    </div>
  </div>
</ng-container>
