import { Injectable } from '@angular/core';
import {
    ProductImportStatus,
} from '@spartacus/cart/base/root';
import { BehaviorSubject, Observable } from 'rxjs';
import { SikoCartModification, SikoProductImportSummary } from '@siko/models';

@Injectable({
    providedIn: 'root'
})
export class SikoImportSummaryService {

    summary$ = new BehaviorSubject<SikoProductImportSummary>({
        loading: false,
        cartName: '',
        count: 0,
        total: 0,
        successesCount: 0,
        warningMessages: [],
        errorMessages: [],
    });

    get summaryObs$(): Observable<SikoProductImportSummary>{
        return this.summary$.asObservable();
    }

    resetSummary(countProducts: number): void {
        this.summary$.next({
            loading: true,
            cartName: '',
            count: 0,
            total: countProducts,
            successesCount: 0,
            warningMessages: [],
            errorMessages: [],
        });
    }

    mapMessages(
        cartModification: SikoCartModification,
    ): void {

        if (cartModification.statusCode === ProductImportStatus.UNKNOWN_IDENTIFIER && cartModification.productCode) {

            const importInfo = {
                productCode: cartModification.productCode,
                statusCode: cartModification.statusCode,
                quantity: cartModification.quantity,
                quantityAdded: cartModification.quantityAdded,
            };

            this.summary$.next({
                ...this.summary$.value,
                count: this.summary$.value.count + 1,
                errorMessages: [...this.summary$.value.errorMessages, importInfo],
            });

        }

        const {
            quantity, quantityAdded, entry, statusCode
        } = cartModification;

        if (cartModification.entry?.product?.code) {
            if (statusCode === ProductImportStatus.LOW_STOCK) {
                const importInfo = {
                    productCode: cartModification.entry.product.code,
                    statusCode,
                    productName: entry?.product?.name,
                    quantity,
                    quantityAdded,
                    conversion: cartModification.entry.product.unitData?.conversion,
                    unit: cartModification.entry.product.unitData?.symbol,
                };

                this.summary$.next({
                    ...this.summary$.value,
                    count: this.summary$.value.count + 1,
                    warningMessages: [...this.summary$.value.warningMessages, importInfo],
                });
            }

            if ( statusCode === ProductImportStatus.NO_STOCK ) {
                const importInfo = {
                    productCode: cartModification.entry.product.code,
                    statusCode,
                    productName: entry?.product?.name,
                };

                this.summary$.next({
                    ...this.summary$.value,
                    count: this.summary$.value.count + 1,
                    warningMessages: [...this.summary$.value.warningMessages, importInfo],
                });
            }

            if ( statusCode === ProductImportStatus.SUCCESS ) {
                this.summary$.next({
                    ...this.summary$.value,
                    count: this.summary$.value.count + 1,
                    successesCount: this.summary$.value.successesCount + 1,
                });
            }
        }
    }

    addQuantityError(productCode: string): void {
        const errorMessage = {
            productCode: productCode,
            statusCode: ProductImportStatus.UNKNOWN_ERROR,
            quantityError: true
        };

        this.summary$.next({
            ...this.summary$.value,
            count: this.summary$.value.count + 1,
            errorMessages: [...this.summary$.value.errorMessages, errorMessage],
        });
    }

}
