import { QuickOrderConfig } from '@spartacus/cart/quick-order/root';

export const SIKO_QUICK_ORDER_CONFIG: QuickOrderConfig = {
  quickOrder: {
    searchForm: {
      displayProductImages: true,
      maxProducts: 5,
      minCharactersBeforeRequest: 2,
    },
    list: {
      hardDeleteTimeout: 7000,
    },
  },
};
