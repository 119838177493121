import { Injectable } from '@angular/core';
import { StoreFinderConfig } from '@spartacus/storefinder/core';
import { ScriptLoader } from '@spartacus/core';
import { SikoUtils } from '@siko/shared';
import { sfGetIconAsset } from '@siko/shared/utils/siko-functions';
import { Loader } from '@googlemaps/js-api-loader';

export interface Coordinates {
    latitude: number;
    longitude: number;
}

@Injectable({
    providedIn: 'root',
})
export class SikoRegistrationGoogleMapRendererService {

    loader = new Loader({
        apiKey: SikoUtils.getGoogleMapsApiKey(),
    });
    googleMap: google.maps.Map | null = null;
    markers: google.maps.Marker[] = [];
    defaultStoreFinderConfig: StoreFinderConfig = {
        googleMaps: {
            apiUrl: 'https://maps.googleapis.com/maps/api/js',
            apiKey: SikoUtils.getGoogleMapsApiKey(),
            scale: 12,
            selectedMarkerScale: 17,
            radius: 50000,
        },
    };

    constructor(
        protected config: StoreFinderConfig,
        protected scriptLoader: ScriptLoader,
    ) {}

    renderMap(
        mapElement: any,
        coordinates: Coordinates,
    ): void {
        if (Object.entries(coordinates).length > 0) {
            void this.loader.load().then(async (): Promise<void> => {
                this.drawMap(mapElement, coordinates);
            });
        }
    }

    private drawMap(
        mapElement: any,
        coordinates: Coordinates,
    ): void {
        this.initMap(mapElement, this.defineMapCenter(coordinates));
        this.createMarker(coordinates);
    }

    private initMap(
        mapElement: any,
        mapCenter: google.maps.LatLng,
    ): void {
        type GestureHandlingOptions = 'auto' | 'cooperative' | 'greedy' | 'none';
        const gestureOption: GestureHandlingOptions = 'greedy';

        const mapProp = {
            center: mapCenter,
            zoom: this.defaultStoreFinderConfig.googleMaps?.scale,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            gestureHandling: gestureOption,
        };

        this.googleMap = new google.maps.Map(mapElement, mapProp);
    }

    private defineMapCenter(coordinates: Coordinates): google.maps.LatLng {
        return new google.maps.LatLng(
            coordinates.latitude,
            coordinates.longitude,
        );
    }

    private createMarker(
        coordinate: Coordinates,
    ): void {
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(
                coordinate.latitude, coordinate.longitude,
            ),
            icon: sfGetIconAsset('map/location.svg'),
        });

        marker.setMap(this.googleMap);
    }

}
