import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { orderTranslationChunksConfig, orderTranslations } from '@spartacus/order/assets';
import { OrderRootModule, ORDER_FEATURE } from '@spartacus/order/root';

@NgModule({
    declarations: [],
    imports: [
        OrderRootModule,
    ],
    providers: [provideConfig(<CmsConfig>{
        featureModules: {
            [ORDER_FEATURE]: {
                module: async () =>
                    import('@spartacus/order').then((m) => m.OrderModule),
            },
        },
    }),
    ],
})
export class OrderFeatureModule {}
