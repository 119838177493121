<ng-container *ngIf='entries'>
  <div class='card d-block d-sm-none' *ngFor='let item of entries; let i = index'>
    <div class='card-body'>
      <div class='row'>
        <div class='d-flex pl-4'>
          <div class='image'>
            <a
              [title]='item.product?.name'
              [routerLink]='item.product | sikoProductUrl'>
              <app-media
                class='cart-icon'
                style='max-width: 120px'
                [container]='$any(item.product?.images)'
                format='cartIcon'
              ></app-media>
            </a>
          </div>
          <div>
            <div>
              <ng-container [ngTemplateOutlet]='catalogProductName'
                            [ngTemplateOutletContext]='{item: item.product}'
              ></ng-container>
              <div class='product-code'>{{ item.product?.code }}</div>
            </div>
          </div>
        </div>
        <div class='col-12 item pt-2'>
          <div><span>{{ "bonusProgram.table.quantity" | cxTranslate }}:</span>
            {{ item.quantity | sikoCxNumeric : '1.0-2' }} {{ item.product?.unitData?.symbol }}
          </div>
        </div>
        <div class='col-12 item'>
          <div><span>{{ "siko.price" | cxTranslate }}
            ({{ (typeOfRecord === 'invoice' ? "siko.withoutVat" : "siko.withVat") | cxTranslate }}):</span>
            {{ item.product?.price | sikoPrice }}
          </div>
        </div>
        <div class='col-12 d-flex pt-2 item'>
          <span class='pr-3'>{{ "bonusProgram.table.countOfPoints" | cxTranslate }}: </span>
          <app-bonus-points-info *ngIf='typeOfRecord == "order"'
                                 type='discount'
                                 [value]='item.points'
                                 [disableTooltip]=true>
          </app-bonus-points-info>
          <app-bonus-points-info *ngIf='typeOfRecord == "invoice"'
                                 type='points'
                                 [value]='item.points'
                                 [coefficient]='item.coefficient'
                                 [disableTooltip]=true>
          </app-bonus-points-info>
        </div>
      </div>
    </div>
  </div>
  <div class='table-responsive d-none d-sm-block'>
    <table
      aria-label='Record items list'
      class='table' [attr.data-testid]='$any("record-items-table")'>
      <thead>
      <tr>
        <th class='product' scope='col'>{{ 'siko.product' | cxTranslate }}</th>
        <th scope='col' class='text-right'>{{ 'bonusProgram.table.quantity' | cxTranslate }}</th>
        <th scope='col' class='text-right'>{{ 'siko.price' | cxTranslate }}
          ({{ (typeOfRecord === 'invoice' ? "siko.withoutVat" : "siko.withVat") | cxTranslate }})
        </th>
        <th scope='col' class='text-right'>{{ 'bonusProgram.table.countOfPoints' | cxTranslate }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor='let item of entries; let i = index'>
        <td>
          <div class='d-flex align-items-center'>
            <div class='image'>
              <a
                [attr.data-testid]='$any("image-product-" + item.product?.code + "-detail-button")'
                [title]='item.product?.name'
                [routerLink]='item.product | sikoProductUrl'>
                <app-media
                  class='cart-icon d-flex align-items-center justify-content-center'
                  [container]='$any(item.product?.images)'
                  format='cartIcon'
                ></app-media>
              </a>
            </div>
            <div class='p-1'>
              <ng-container [ngTemplateOutlet]='catalogProductName'
                            [ngTemplateOutletContext]='{item: item.product}'
              ></ng-container>
              <div class='d-block product-code'>{{ item.product?.code }}</div>
            </div>
          </div>
        </td>
        <td class='text-right'>
          {{ item.quantity | sikoCxNumeric : '1.0-2' }} {{ item.product?.unitData?.symbol }}
        </td>
        <td class='text-right'>
          {{ item.product?.price | sikoPrice }}
        </td>
        <td>
          <app-bonus-points-info *ngIf='typeOfRecord == "order"'
                                 type='discount'
                                 [value]='item.points'
                                 [disableTooltip]=true>
          </app-bonus-points-info>
          <app-bonus-points-info *ngIf='typeOfRecord == "invoice"'
                                 type='points'
                                 [value]='item.points'
                                 [coefficient]='item.coefficient'
                                 [disableTooltip]=true>
          </app-bonus-points-info>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <ng-template #catalogProductName let-item='item'>
    <a
      [attr.data-testid]='$any("product-" + item.product?.code + "-detail-button")'
      class='product-name'
      [routerLink]='item | sikoProductUrl'>
      {{ item.name }}
    </a>
  </ng-template>
</ng-container>
