import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type OrderStatusMode = 'SAP_ORDER' | 'COMMERCE_ORDER';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-order-status-badge',
    templateUrl: './order-status-badge.component.html',
    styleUrls: ['./order-status-badge.component.scss'],
})
export class SikoOrderStatusBadgeComponent {
    @Input() mode?: OrderStatusMode;
    @Input() status?: string;
}
