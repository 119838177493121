import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import SwiperCore, { FreeMode, Navigation, Pagination, SwiperOptions, Thumbs } from 'swiper';
import { SikoProduct, SikoProductReference } from '@siko/models';
import { combineLatest, Observable } from 'rxjs';
import { ProductReferenceService } from '@spartacus/core';
import { map } from 'rxjs/operators';

SwiperCore.use([Navigation, Pagination, FreeMode, Thumbs]);

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-added-to-cart-dialog-references-carousel',
    templateUrl: './added-to-cart-dialog-references-carousel.component.html',
    styleUrls: ['./added-to-cart-dialog-references-carousel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SikoAddedToCartDialogReferencesCarouselComponent implements OnChanges {

    @Input() productCode?: string;
    items$?: Observable<SikoProductReference[]>;

    productCarouselConfig: SwiperOptions = {
        spaceBetween: 12,
        setWrapperSize: true,
        navigation: true,
        breakpoints: {
            320: {
                direction: 'vertical',
                slidesPerView: 'auto',
                allowTouchMove: false,
                autoHeight: true,
            },
            576: {
                direction: 'horizontal',
                slidesPerView: 2,
            },
            920: {
                direction: 'horizontal',
                slidesPerView: 3,
            },
            1200: {
                direction: 'horizontal',
                slidesPerView: 4,
            },
        },
    };

    constructor(
        private readonly productReferenceService: ProductReferenceService,
    ) {

    }

    ngOnChanges(): void {
        if (this.productCode) {
            this.items$ = combineLatest(
                this.productReferenceService.getProductReferences(this.productCode, 'MANDATORY'),
                this.productReferenceService.getProductReferences(this.productCode, 'ACCESSORIES'),
            )
                .pipe(
                    map(([mandatory, accessories]) => {
                        return [...mandatory, ...accessories];
                    }));
        }
    }


    isDiscounted(product: SikoProduct | undefined): boolean | undefined {
        return product?.hasOwnProperty('discountPrice');
    }


}
