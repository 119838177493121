import {
    ConfigurableRoutesService,
    OccEndpointsService,
    ProtectedRoutesService,
    RoutingConfig,
    RoutingConfigService,
    UrlMatcherService,
} from '@spartacus/core';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { SESSION_KEY_PUBLIC_ROUTES } from '@siko/constants';
import { SikoProtectedRoutesService } from './siko-protected-routes.service';
import { SikoPublicUrlsService } from './siko-public-urls.service';

@Injectable({
    providedIn: 'root',
})
export class SikoConfigurableRoutesService extends ConfigurableRoutesService {
    private readonly backendBaseUrl: string;

    constructor(
        protected injector: Injector,
        protected routingConfigService: RoutingConfigService,
        protected urlMatcherService: UrlMatcherService,
        protected config: RoutingConfig,
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected protectedRoutesService: ProtectedRoutesService,
        readonly sikoPublicUrlsService: SikoPublicUrlsService,
    ) {
        super(injector, routingConfigService, urlMatcherService);
        this.backendBaseUrl = this.occEndpoints.getBaseUrl();
    }

    initOverrideRoutes(): void {
        this.addRoutingConfig('cxpreview', ['cx-preview'], false);
    }

    initPublicRoutes(): void {
        if (sessionStorage.getItem(SESSION_KEY_PUBLIC_ROUTES)) {
            return;
        }

        this.sikoPublicUrlsService.getPublicUrls().subscribe((filteredUrls: string[]) => {
            this.addRoutingConfig('cms', filteredUrls, false);
        });
    }

    private addRoutingConfig(key: string, urls: string[], isProtected: boolean): void {
        const routeConfig = this.config.routing?.routes;

        if (this.config.routing && this.protectedRoutesService instanceof SikoProtectedRoutesService) {
            this.config.routing.routes = {
                ...routeConfig,
                ...{
                    [key]: {
                        paths: urls,
                        protected: isProtected,
                    },
                },
            };

            this.configure();
            this.protectedRoutesService.reloadProtectedRoutes();
        }
    }
}
