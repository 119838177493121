import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { SikoProduct } from '@siko/models';
import { Product } from '@spartacus/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-detail-tab',
    templateUrl: './product-detail-tab.component.html',
    styleUrls: ['./product-detail-tab.component.scss'],
})
export class SikoProductDetailTabComponent implements OnInit {

    @Input() displayHeader = true;
    product$: Observable<Product | null> = new Observable<Product | null>();
    packageCode = 'SikoClassification/1.0/hmotnost-a-baleni.baleni';

    constructor(
        protected currentProductService: CurrentProductService,
    ) {}

    get sikoProduct$(): Observable<SikoProduct | null> {
        return this.product$;
    }

    ngOnInit(): void {
        this.product$ = this.currentProductService.getProduct();
    }

    removeUnitSymbolFromValue(value?: string): string | undefined {
        return value?.split(' ')[0];
    }

    parseValueToInt(value: string): number {
        return parseInt(value, 2);
    }

}
