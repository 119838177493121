import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductListComponentService } from '@spartacus/storefront';
import { SikoProductSearchPage } from '@siko/models';
import { SikoTrackingUtils } from '@siko/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-category-description',
    templateUrl: './category-description.component.html',
})

export class SikoCategoryDescriptionComponent {
    @Input() isShowCategoryDescription = false;
    @Input() subTitle = false;


    model$: Observable<SikoProductSearchPage> =
        this.productListComponentService.model$;

    constructor(
        private readonly trackingUtils: SikoTrackingUtils,
        private readonly productListComponentService: ProductListComponentService,
    ) {}

    toggleCategoryDescription(): void {
        this.isShowCategoryDescription = !this.isShowCategoryDescription;
    }
}
