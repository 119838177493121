<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="product.numberOfReviews; else noReviews">
    <div class="row" id="js--reviews">
      <div class="col-6 border-md-none border-right">
        <div class="row">
          <div class="col-12 col-xl-6">
            <app-product-average-rating [averageAmount]="product.averageRating"></app-product-average-rating>
          </div>
          <div class="col-12 col-xl-6 mt-4 mt-xl-32">
            <div class="rating-container">
              <siko-star-rating
                [disabled]="true"
                [ratingCount]="5"
                [resetAble]="true"
                [value]="product.averageRating ? product.averageRating : 0"
                [countReviews]=""
                size="md"
              ></siko-star-rating>
              <div class='siko-font-primary-semiBold'>
                {{'sikoProductShared.review' | cxTranslate : { count: product.numberOfReviews} }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="product.reviews && product.reviews.length > 0"
        class="col-6 mt-0 mt-xl-32 "
      >
        <app-product-reviews-bar
          [reviews]="product.reviews">
        </app-product-reviews-bar>
      </div>
    </div>
  </ng-container>
  <ng-template #noReviews>
    <div class="row">
      <div class="col">
        {{'pdp.reviewTab.noReviews' | cxTranslate}}
      </div>
    </div>
  </ng-template>
</ng-container>
