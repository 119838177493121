import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    OnChanges,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { CmsComponentData, NavigationComponent, NavigationNode, NavigationService } from '@spartacus/storefront';
import {
    AuthService,
    CmsNavigationComponent,
    CmsSelectors,
    isNotNullable,
    isNotUndefined,
    RoutingService,
    StateWithCms,
    User,
    WindowRef,
} from '@spartacus/core';
import { filter, map, pluck, switchMap } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, fromEvent, Observable, of, Subscription } from 'rxjs';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { SikoComponent, SikoUser } from '@siko/models';
import { SikoHeaderService } from '@siko/features/header/siko-header.service';
import { sfGetIconAsset } from '@siko/shared/utils/siko-functions';
import { SikoAutoUnsubscribe, SikoBreakpointObserverService } from '@siko/common';

@SikoAutoUnsubscribe(['scrollerSubscription','userSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-siko-user-navigation',
    templateUrl: './user-navigation.component.html',
    styleUrls: ['./user-navigation.component.scss'],
})
export class SikoUserNavigationComponent extends NavigationComponent implements OnInit, OnChanges {

    @ViewChild('navMenu') navMenu?: ElementRef;
    @ViewChild('openUserNav') openUserNav?: ElementRef;
    @ViewChild('mobileAccordion') mobileAccordion?: NgbAccordion;
    @ViewChild('displayNavMenu') displayNavMenu?: ElementRef;

    user$: Observable<User | undefined>;
    isUserNavOpen = false;
    openable = [...Array(2)
        .keys()];
    scrollerSubscription?: Subscription;
    userSubscription?: Subscription;
    hasActiveBonusProgram: boolean = false;

    constructor(
        private readonly renderer: Renderer2,
        protected componentData: CmsComponentData<CmsNavigationComponent>,
        protected service: NavigationService,
        private readonly auth: AuthService,
        private readonly userAccount: UserAccountFacade,
        public sikoHeaderService: SikoHeaderService,
        private readonly cmsStore: Store<StateWithCms>,
        private readonly routingService: RoutingService,
        protected winRef: WindowRef,
        readonly userFacade: UserAccountFacade,
        readonly breakpointObserver: SikoBreakpointObserverService,
    ) {
        super(componentData, service);
        this.user$ = this.auth.isUserLoggedIn()
            .pipe(
                switchMap((isUserLoggedIn: boolean) => isUserLoggedIn ? this.userAccount.get() : of(undefined)),
            );
    }

    ngOnInit(): void {
        this.scrollerSubscription = fromEvent(window, 'scroll')
            .pipe()
            .subscribe(() => {
                this.makeStickyUserNav(window.pageYOffset);
            });

        this.userSubscription = this.userFacade.get().subscribe((user?: SikoUser) => {
                if (user?.sikoBonusProgramActive) {
                    this.hasActiveBonusProgram = user?.sikoBonusProgramActive;
                }
            });

    }

    routeByUrl(navNode: NavigationNode): void {
        if (!navNode.url) {
            return;
        }

        const routeUrl = String(navNode.url);

        if (routeUrl.includes('/import/csv/saved-cart')) {
            this.sikoHeaderService.importCart();
        }
        else {
            void this.routingService.goByUrl(routeUrl);
        }

        this.openUserNavigation();
    }

    @HostListener('document:click', ['$event'])
    hideUserNavMenu(event: MouseEvent): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        if (this.isUserNavOpen && !this.navMenu?.nativeElement.contains(event.target) &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            !this.openUserNav?.nativeElement.contains(event.target)) {
            this.renderer.removeClass(this.navMenu?.nativeElement, 'active');
            this.winRef.document.body.classList.remove('overflow-hidden');
            this.isUserNavOpen = !this.isUserNavOpen;
        }
    }

    openUserNavigation(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        if (this.navMenu?.nativeElement.classList.contains('active')) {
            this.renderer.removeClass(this.navMenu.nativeElement, 'active');
            this.winRef.document.body.classList.remove('overflow-hidden');
            this.isUserNavOpen = false;
        }
        else {
            this.renderer.addClass(this.navMenu?.nativeElement, 'active');
            this.isUserNavOpen = true;
            this.winRef.document.body.classList.add('overflow-hidden');
        }
    }

    makeStickyUserNav(y: number): void {
        // eslint-disable-next-line no-unsafe-optional-chaining,@typescript-eslint/no-unsafe-assignment
        const { offsetTop } = this.navMenu?.nativeElement;

        if (y >= Number(offsetTop) + 1) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            this.navMenu?.nativeElement.classList.add('sticky-user-nav');
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            this.navMenu?.nativeElement.classList.remove('sticky-user-nav');
        }
    }

    resolveUserName(userName: string | undefined): string | undefined {
        if (userName !== undefined && userName.length >= 25) {
            return userName.slice(0, 23) + '...';
        }

        return userName;
    }

    beforeChange($event: NgbPanelChangeEvent): void {
        if (!this.openable.includes(Number($event.panelId.substr(5)))) {
            $event.preventDefault();
        }
    }

    parentNavigationNodes$: Observable<NavigationNode[] | undefined> = this.cmsStore.pipe(
        select(CmsSelectors.componentsContextSelectorFactory('MobileNavComponent')),
    )
        .pipe(
            filter(isNotNullable),
            filter(isNotUndefined),
            map((mobileNavigationNode: any) => mobileNavigationNode?.component.navigationNode.children
                .filter((children: any) => children.children.length !== 0 || children.entries.length !== 0)),
        );

    // TODO should be populated on for mobiles
    childNavigationNodes$: Observable<any> = this.cmsStore.pipe(
        select(CmsSelectors.componentsContextSelectorFactory('MobileNavComponent')),
    )
        .pipe(
            filter(isNotNullable),
            filter(isNotUndefined),
            switchMap((mobileNavigationNode: any) => this.service.getNavigationNode(of(mobileNavigationNode.component?.navigationNode))
                .pipe(pluck('children'))),
            switchMap(nodes => this.userFacade.get().pipe(
                filter(isNotUndefined),
                map((user: SikoUser) => ({
                    isActive: user.sikoBonusProgramActive || false,
                    nodes
                }))
            )),
            map(({ isActive, nodes }) => {
                if (!isActive) {
                    if (nodes?.[0]) {
                        nodes[0].children = nodes[0].children?.filter((child: any) =>
                            child.url !== '/my-account/bonus-program');
                    }
                }

                return nodes;
            })
        );

    mergedNavigationNodes$: Observable<any> = combineLatest(
        this.parentNavigationNodes$,
        this.childNavigationNodes$,
    )
        .pipe(
            filter(isNotNullable),
            filter(isNotUndefined),
            map(([parentNavigationNodes, childNavigationNodes]) => ({
                parents: parentNavigationNodes,
                children: childNavigationNodes,
            })),
        );

    isOpen(i: number): boolean {
        return !!(this.mobileAccordion && this.mobileAccordion.activeIds.includes('panel' + i.toFixed()));
    }

    isNavigationNodeDisabled(navigationNode: NavigationNode[]): boolean {
        return navigationNode ? navigationNode.length === 0 : true;
    }

    onNavClick(navigationNode: NavigationNode): void {
        if (navigationNode.hasOwnProperty('children')) {
            return;
        }

        this.openUserNavigation();

        if (navigationNode.target && navigationNode.url) {
            window.open(navigationNode.url as string, '_blank');
        }
        else {
            void this.routingService.goByUrl(navigationNode.url as string);
        }
    }

    getIcon(index: number, parentNodes: SikoComponent[]): string {
        const MOBILE_NAV_ICONS: { [K in string]: string } = {
            MyAccountNavNode: sfGetIconAsset('header/user-not-logged.svg'),
            OrderToolsNavNode: sfGetIconAsset('header/mobile/quick-order.svg'),
            CompanyNavNode: sfGetIconAsset('header/mobile/my-company.svg'),
            ClaimsNavNode: sfGetIconAsset('header/mobile/complaint.svg'),
            ClaimsSKNavNode: sfGetIconAsset('header/mobile/complaint.svg'),
            LogOutNavNode: sfGetIconAsset('header/mobile/signout.svg'),
        };

        if (parentNodes[index].uid) {
            return MOBILE_NAV_ICONS[parentNodes[index].uid!];
        }

        return '';
    }

    ngOnChanges(): void {
        this.user$ = this.auth.isUserLoggedIn()
            .pipe(
                switchMap((isUserLoggedIn: boolean) => isUserLoggedIn ? this.userAccount.get() : of(undefined)),
            );
    }

}
