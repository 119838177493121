import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { SikoActiveCartService } from '@siko/features/cart';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { SikoAutoUnsubscribe } from '@siko/common';
import { SavedCartActions } from '@spartacus/cart/saved-cart/core';
import { SikoActionsWithCartService } from '@siko/features/shared-components/actions-with-cart-dialog/services/actions-with-cart.service';
import { B2bCommonModule, SikoDialogService, SikoTrackingUtils } from '@siko/shared';
import { DatePipe } from '@angular/common';
import { Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import { CartActions } from '@spartacus/cart/base/core';
import { GlobalMessageService, GlobalMessageType, RoutingService, UserIdService } from '@spartacus/core';
import { SikoDialogComponentsModule } from '@siko/features/shared-components/dialog-components/dialog-components.module';
import { SikoCart, SikoCartModification } from '@siko/models';
import { SikoCartAdapter } from '@siko/features/cart/occ/adapters/cart.adapter';

@SikoAutoUnsubscribe(['saveCartSubscription', 'removeCartSubscription', 'saveCartFailSubscription', 'saveCartFailSubscription', 'deleteCartFailSubscription'])
@Component({
    standalone: true,
    imports: [SikoDialogComponentsModule, B2bCommonModule],
    selector: 'app-actions-with-cart-dialog',
    templateUrl: './actions-with-cart-dialog.component.html',
    styleUrls: ['./actions-with-cart-dialog.component.scss'],
})
export class SikoActionsWithCartDialogComponent implements OnInit {

    removeBtnText = '';
    @Input() cart?: Cart;
    @Input() cartModifications?: SikoCartModification[];

    static readonly PICKUP = 'pickup';
    static readonly DELIVERY_TO_ADDRESS = 'deliveryToAddress';

    actualDate: Date = new Date();
    removeCartSubscription?: Subscription;
    saveCartSubscription?: Subscription;
    deleteCartFailSubscription?: Subscription;
    saveCartFailSubscription?: Subscription;
    currentPage: string = 'other';
    countOfCartModifications: number = 0;
    invalidProducts: string[] = [];
    typeOfDelivery: string = SikoActionsWithCartDialogComponent.DELIVERY_TO_ADDRESS;
    pickupStore?: string = '';

    savedCartForm: FormGroup = this.fb.group({
        savedCartName: [],
    });

    constructor(
        readonly actionsWithCartService: SikoActionsWithCartService,
        readonly actions: Actions,
        readonly savedCartService: SavedCartFacade,
        readonly activeCartService: SikoActiveCartService,
        readonly multiCartService: MultiCartFacade,
        readonly fb: FormBuilder,
        readonly dialogService: SikoDialogService,
        readonly occCartAdapter: SikoCartAdapter,
        readonly routingService: RoutingService,
        readonly globalMessageService: GlobalMessageService,
        readonly sikoTrackingUtils: SikoTrackingUtils,
        protected userIdService: UserIdService,
    ) {}

    ngOnInit() {
        this.deleteCartFailSubscription = this.actions.pipe(
            ofType(CartActions.DELETE_CART_FAIL),
        )
            .subscribe(() => {
                this.dialogService.closeActiveModal('Cart is not found');
                this.globalMessageService.add(
                    { key: 'httpHandlers.cart.reloadCartRequired' },
                    GlobalMessageType.MSG_TYPE_ERROR);
            });

        this.saveCartFailSubscription = this.actions.pipe(
            ofType(SavedCartActions.SAVE_CART_FAIL),
        )
            .subscribe(() => {
                this.dialogService.closeActiveModal('Cart is not found');
                this.globalMessageService.add(
                    { key: 'httpHandlers.cart.reloadCartRequired' },
                    GlobalMessageType.MSG_TYPE_ERROR);
            });

        this.removeBtnText = 'actionsWithCartDialog.' + this.currentPage + '.removeCartAndContinue';

        if (this.cartModifications) {
            this.currentPage = 'cart';
            this.countOfCartModifications = this.cartModifications.length;
            if (this.cart?.pickupOrderGroups) {
                this.typeOfDelivery = this.cart.pickupOrderGroups.length !== 0 ?
                    SikoActionsWithCartDialogComponent.PICKUP : SikoActionsWithCartDialogComponent.DELIVERY_TO_ADDRESS;
                if (this.typeOfDelivery.includes(SikoActionsWithCartDialogComponent.PICKUP)) {
                    this.pickupStore = this.cart?.pickupOrderGroups[0].deliveryPointOfService?.displayName;
                }
            }

            this.cartModifications.forEach(modification => {
                if (modification.entry?.product?.code) {
                    this.invalidProducts.push(modification.entry?.product?.code);
                }
            });

            this.removeBtnText = this.countOfCartModifications > 1 ? 'actionsWithCartDialog.' + this.currentPage + '.removeCartAndContinue_few' : 'actionsWithCartDialog.' + this.currentPage + '.removeCartAndContinue_one';
        }
    }

    removeCartAndContinue(): void {

        let oldCart: SikoCart | undefined = this.cart;
        if (this.currentPage.includes('cart')) {
            if (this.cart?.code) {
                this.occCartAdapter.deleteInvalidEntries(this.cart?.code)
                    .subscribe(() => {
                        if (oldCart && oldCart.undefinedOrderGroups && oldCart.undefinedOrderGroups[0].entries) {
                            this.sikoTrackingUtils.pushModifyCartEvent(oldCart.undefinedOrderGroups[0]?.entries, undefined, 'removeFromCart');
                        }
                        if (this.cart?.code) {
                            this.multiCartService.reloadCart(this.cart?.code);
                        }
                        this.dialogService.closeActiveModal();
                        this.routingService.goByUrl('checkout');
                    });
            }
        }
        else {
            this.activeCartService.deleteCart();

            this.removeCartSubscription = this.actions.pipe(ofType(CartActions.DELETE_CART_SUCCESS))
                .subscribe(() => {
                    this.actionsWithCartService.setAllowedContinue(true);
                    this.actionsWithCartService.setActionWithCurrentCart('delete');
                    this.dialogService.closeActiveModal();
                });
        }
    }

    saveCartAndContinue(): void {
        if (this.cart?.code) {
            let savedCartName = this.savedCartForm.controls?.savedCartName.value;
            if (savedCartName === null) {
                const datePipe = new DatePipe('cs-CZ');
                savedCartName = datePipe.transform(new Date(), 'd.M.Y H:mm');
            }

            if (this.currentPage.includes('cart')) {
                this.occCartAdapter.saveInvalidEntries(this.cart.code, savedCartName)
                    .subscribe(() => {
                        this.sikoTrackingUtils.pushCartSaveEvent(this.cart, true);
                        this.dialogService.closeActiveModal();
                        if (this.cart?.code) {
                            this.multiCartService.reloadCart(this.cart?.code);
                        }
                        this.routingService.goByUrl('checkout');
                    });
            }
            else {
                const oldCart = this.cart;
                this.savedCartService.saveCart({
                    cartId: this.cart?.code,
                    saveCartName: savedCartName,
                    saveCartDescription: '',
                });

                this.saveCartSubscription = this.actions.pipe(ofType(SavedCartActions.SAVE_CART_SUCCESS))
                    .subscribe(data => {
                        this.sikoTrackingUtils.pushCartSaveEvent(oldCart, false);
                        this.userIdService.takeUserId()
                            .subscribe(userId => {
                                this.multiCartService.createCart({ userId });
                            })
                            .unsubscribe();
                        this.actionsWithCartService.setAllowedContinue(true);
                        this.actionsWithCartService.setActionWithCurrentCart('save');
                        this.dialogService.closeActiveModal();
                    });
            }
        }
    }
}
