<ng-container *ngIf='currentRoute$ | async as route'>
  <div class='container'>
    <div class='user-nav-menu-wrapper' (click)='myAccountSideMenuService.setIsSideMenuOpen(true)'>
      <div class='user-nav-menu'>
        <div class='d-block d-lg-none'>
          <img [src]='"myAccountMenu/hamburger.svg" | sikoIconAsset' alt='my account hamburger menu'>
        </div>
        <div>
          <img [src]='"header/user-not-logged.svg" | sikoIconAsset' alt='user not logged icon'>
        </div>
      </div>
      <ng-container
        *ngIf='route.includes("/sap-order/") || route.includes("/order/") || route.includes("/ticket/"); else notOrder'>
        <h4
          class='my-account-section-title detail'>{{'siko.myAccountTitle' | cxTranslate}}</h4>
        <ng-container *ngIf='route.includes("order")'>
          <siko-button
            class='d-block pl-4 d-lg-none ml-0'
            [routerLink]='["/my-account/orders"]'
            iconPosition='before'
            iconSvg='arrow_left'
            text='{{"ordersHistory.orderHistoryPageTitle" | cxTranslate}}'
            mode='light'
          >
          </siko-button>
        </ng-container>
        <ng-container *ngIf='route.includes("ticket")'>
          <siko-button
            class='d-block pl-4 d-lg-none ml-0'
            [routerLink]='["/my-account/tickets"]'
            iconPosition='before'
            iconSvg='arrow_left'
            text='{{"siko.ticket.ticketHistoryPageTitle" | cxTranslate}}'
            mode='light'
          >
          </siko-button>
        </ng-container>
      </ng-container>
      <ng-template #notOrder>
            <h4
              class='my-account-section-title'>{{'siko.myAccountTitle' | cxTranslate}}</h4>
      </ng-template>
    </div>
    <div class='row'>
      <div class='col-lg-3 col-xl-2 navigation-wrapper'>
        <cx-page-slot position='NavigationMenu'></cx-page-slot>
      </div>
      <div class='col-lg-9 col-xl-10 p-0 p-lg-3'>
        <cx-page-slot position='BodyContent'></cx-page-slot>
      </div>
    </div>
  </div>
</ng-container>
