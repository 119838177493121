import { Component } from '@angular/core';
import { ImportEntriesSummaryComponent } from '@spartacus/cart/import-export/components';
import { SikoImportSummaryService } from '@siko/features/cart/services/siko-import-summary.service';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';

@Component({
    selector: 'app-import-entries-summary',
    templateUrl: './import-entries-summary.component.html',
    styleUrls: ['./import-entries-summary.component.scss'],
})
export class SikoImportEntriesSummaryComponent extends ImportEntriesSummaryComponent {

    constructor(
        protected savedCartService: SavedCartFacade,
        public sikoImportSummaryService: SikoImportSummaryService,
    ) {
        super();
    }

    close(reason: string): void {
        this.closeEvent.emit(reason);
    }

    resolveAddedQuantity(conversion: string | undefined, quantity: number | undefined): number {
        if (conversion && quantity) {
            return Number(conversion) * quantity;
        }

        return Number(quantity);
    }

}
