import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LoggerService, normalizeHttpError } from '@spartacus/core';
import { OccNewsAdapter } from '@siko/features/news/occ/adapters/occ-news.adapter';
import { SikoNewsActions } from '../actions';

@Injectable()
export class UnseenNewsEffect {

    constructor(
        readonly actions$: Actions,
        readonly occNewsAdapter: OccNewsAdapter,
        readonly logger: LoggerService,
    ) {
    }


    getUnseenNewsCount$: Observable<SikoNewsActions.LoadUnseenNewsCountFail
        | SikoNewsActions.LoadUnseenNewsCountSuccess> = createEffect(() => this.actions$.pipe(
        ofType(SikoNewsActions.LOAD_UNSEEN_NEWS_COUNT),
        mergeMap(action =>
            this.occNewsAdapter.getNotSeenCount()
                .pipe(
                    map((data: number) => new SikoNewsActions.LoadUnseenNewsCountSuccess(data)),
                    catchError(error =>
                        of(
                            new SikoNewsActions.LoadUnseenNewsCountFail(normalizeHttpError(error, this.logger)),
                        )),
                )),
    ));


}
