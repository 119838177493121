import { OccB2bUserSerializer } from '@spartacus/organization/administration/occ';
import { Injectable } from '@angular/core';
import { B2BUser, Occ } from '@spartacus/core';

@Injectable({
    providedIn: 'root',
})
export class SikoOccB2bUserSerializer extends OccB2bUserSerializer {

    convert(source: B2BUser, target?: Occ.B2BUser): Occ.B2BUser {
        if (target === undefined) {
            target = { ...(source as any) } as Occ.B2BUser;
        }

        delete (target as B2BUser).isAssignedToApprovers;

        return target;
    }

}
