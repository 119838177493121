import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';

export type SkeletonType =
    'orders-history'
    | 'pdp'
    | 'plp-list'
    | 'plp'
    | 'quotes-creation-prices'
    | 'quotes-creation-products'
    | 'org-documents'
    | 'search-hint';

@Component({
    selector: 'app-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        B2bCommonModule,
    ],
})
export class SikoSkeletonComponent {

    @Input() skeletonType?: SkeletonType;

    get classes(): string[] {
        if (!this.skeletonType) {
            return [''];
        }

        return ['skeleton', `skeleton--${this.skeletonType}`];
    }

}
