<div class="cx-order-items container" *ngIf="order$ | async as order">
  <cx-promotions
    [promotions]="order.appliedOrderPromotions || []"
  ></cx-promotions>

  <app-cart-item-list
    *ngIf="order.entries"
    [items]="order.entries"
    [readonly]="true"
  ></app-cart-item-list>
</div>

