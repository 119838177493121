import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CxDatePipe, GlobalMessageService, UserIdService } from '@spartacus/core';
import { ImportProductsFromCsvService } from '@spartacus/cart/import-export/components';
import {
    FileReaderService,
    FilesFormValidators,
    ImportCsvFileService,
    LaunchDialogService,
} from '@spartacus/storefront';
import { ActiveCartFacade, MultiCartFacade, ProductData } from '@spartacus/cart/base/root';
import { CartNameGeneration, CartNameSource, ImportExportConfig } from '@spartacus/cart/import-export/core';
import {
    SikoImportEntriesFormComponent
} from '@siko/features/shared-components/import-entries-dialog/components/import-entries-form/import-entries-form.component';
import { AbstractControl, FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Papa, ParseResult } from 'ngx-papaparse';
import { SikoImportCsvValidator } from '@siko/features/shared-components/import-entries-dialog/services/import-csv-validator.service';
import { SikoFileUploadService } from '@siko/features/shared-components/import-entries-dialog/services/siko-file-upload.service';
import { SikoDialogService, SikoTrackingUtils } from '@siko/shared';
import { Actions } from '@ngrx/effects';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';

@Component({
    selector: 'app-import-to-new-saved-cart-form',
    templateUrl: './import-to-new-saved-cart-form.component.html',
    styleUrls: ['./import-to-new-saved-cart-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CxDatePipe],
})
export class SikoImportToNewSavedCartFormComponent extends SikoImportEntriesFormComponent {

    descriptionMaxLength = 250;
    nameMaxLength = 50;

    constructor(
        public sikoFileUploadService: SikoFileUploadService,
        launchDialogService: LaunchDialogService,
        importToCartService: ImportProductsFromCsvService,
        importCsvService: ImportCsvFileService,
        filesFormValidators: FilesFormValidators,
        importExportConfig: ImportExportConfig,
        fileReaderService: FileReaderService,
        csvParser: Papa,
        actions: Actions,
        fb: FormBuilder,
        saveCartService: SavedCartFacade,
        cartService: ActiveCartFacade,
        multiCartService: MultiCartFacade,
        userIdService: UserIdService,
        sikoImportCsvValidator: SikoImportCsvValidator,
        globalMessageService: GlobalMessageService,
        dialogService: SikoDialogService,
        protected datePipe: CxDatePipe,
        sikoTracikngUtils: SikoTrackingUtils
    ) {
        // eslint-disable-next-line max-len
        super(
            launchDialogService,
            importToCartService,
            importCsvService,
            filesFormValidators,
            importExportConfig,
            fileReaderService,
            csvParser,
            sikoImportCsvValidator,
            actions,
            fb,
            saveCartService,
            cartService,
            multiCartService,
            userIdService,
            globalMessageService,
            dialogService,
            sikoTracikngUtils
        );
    }

    get descriptionsCharacterLeft(): number {
        return (
            this.descriptionMaxLength -
            (this.form.get('description')?.value?.length || 0)
        );
    }

    get nameCharacterLeft(): number {
        return (
            this.nameMaxLength -
            (this.form.get('name')?.value?.length || 0)
        );
    }

    protected get cartNameGeneration(): CartNameGeneration | undefined {
        return this.importExportConfig.cartImportExport?.import?.cartNameGeneration;
    }

    save(): void {
        const file: File = this.form.get('file')?.value?.[0];
        const fileName = file.name.replace('.csv', '');

        this.csvParser.parse(file, {
            skipEmptyLines: true,
            complete: (parseResult: ParseResult) => {
                this.submitEvent.emit({
                    products: this.sikoImportCsvValidator.csvDataToProduct(parseResult.data, true)
                        .filter((value: ProductData) => value.productCode !== ''),
                    // @ts-expect-error
                    savedCartInfo: {
                        name: this.form.get('name')?.value === '' ? fileName : this.form.get('name')?.value,
                        description: this.form.get('description')?.value,
                    },
                });
            }
        });
    }

    updateCartName(): void {
        const nameField = this.form.get('name');

        if (nameField && !nameField.value && this.cartNameGeneration?.source) {
            switch (this.cartNameGeneration.source) {
                case CartNameSource.FILE_NAME: {
                    this.setFieldValueByFileName(nameField);
                    break;
                }

                case CartNameSource.DATE_TIME: {
                    this.setFieldValueByDatetime(nameField);
                    break;
                }

                default: {
                    break;
                }
            }
        }
    }

    protected buildForm(): UntypedFormGroup {
        const form = new UntypedFormGroup({});

        this.sikoFileUploadService.setUploadFileName('');

        form.setControl(
            'file',
            new UntypedFormControl(
                '',
                [Validators.required, this.filesFormValidators.maxSize(this.maxSize)],
                [
                    control =>
                        this.sikoImportCsvValidator.validateFile(control.value[0], {
                            isDataParsable: this.sikoImportCsvValidator.isDataParsableToProducts,
                            maxEntries: this.maxEntries,
                        })
                ]
            )
        );

        form.setControl(
            'name',
            new UntypedFormControl('', [
                Validators.maxLength(this.nameMaxLength),
            ])
        );

        form.setControl(
            'description',
            new UntypedFormControl('', [Validators.maxLength(this.descriptionMaxLength)])
        );

        return form;
    }

    protected setFieldValueByFileName(nameField: AbstractControl): void {
        const fileName = this.form
            .get('file')
            ?.value?.[0]?.name?.replace(/\.[^/.]+$/, '');

        nameField.setValue(fileName);
    }

    protected setFieldValueByDatetime(nameField: AbstractControl): void {
        const date = new Date();
        const fromDateOptions = this.cartNameGeneration?.fromDateOptions;
        const mask = fromDateOptions?.mask;
        const prefix = fromDateOptions?.prefix ?? '';
        const suffix = fromDateOptions?.suffix ?? '';
        const dateString = mask
            ? this.datePipe.transform(date, mask)
            : this.datePipe.transform(date);

        nameField.setValue(`${prefix}${dateString}${suffix}`);
    }

}
