import { createSelector, MemoizedSelector } from '@ngrx/store';
import {
    SikoActionLoaderState,
    StateWithSikoActionLoader,
} from '@siko/features/action-loader/store/action-loader-state';
import { getSikoActionLoaderState } from '@siko/features/action-loader/store/selectors/feature.selector';
import { SikoActionLoader } from '@siko/features/action-loader/models/action-loader.model';

export const getSikoActionLoaderEntities: MemoizedSelector<
    StateWithSikoActionLoader,
    SikoActionLoader[]
> = createSelector(
    getSikoActionLoaderState,
    (state: SikoActionLoaderState) => state.entities,
);
