import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { OrderConfirmationThankYouMessageComponent } from '@spartacus/order/components';
import { Observable, Subscription } from 'rxjs';
import { PaymentGateway, SikoB2BUser, SikoOrder } from '@siko/models';
import { CHECKOUT_PAYMENT_GATEWAY_STATUS, PAYMENT_TYPES } from '@siko/constants';
import { SikoCheckoutService } from '@siko/features/checkout/checkout-place-order/components/place-order/checkout.service';
import { tap } from 'rxjs/operators';
import { SikoOccCheckoutAdapter } from '@siko/features/checkout/occ/adapters/occ-checkout.adapter';
import { OrderFacade } from '@spartacus/order/root';
import { GlobalMessageService, TranslationService } from '@spartacus/core';
import { SikoTrackingUtils } from '@siko/shared';
import { Actions, ofType } from '@ngrx/effects';
import { OrderActions } from '@spartacus/order/core';
import { LoadOrderDetailsSuccess } from '@spartacus/order/core/store/actions/order-details.action';
import { SikoAutoUnsubscribe } from '@siko/common';

@SikoAutoUnsubscribe(['trackingSubscription', 'paymentGatewaySubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-order-confirmation-thank-message',
    templateUrl: './order-confirmation-thank-message.component.html',
    styleUrls: ['./order-confirmation-thank-message.component.scss'],
})
export class SikoOrderConfirmationThankMessageComponent extends OrderConfirmationThankYouMessageComponent
    implements OnInit {

    @Input() sikoUser$?: Observable<SikoB2BUser>;
    @Input() order?: SikoOrder;
    @Input() pgStatus?: string | null;

    statusMessageTranslationKey?: string;
    checkoutPaymentGatewayStatusEnum = CHECKOUT_PAYMENT_GATEWAY_STATUS;
    trackingSubscription?: Subscription;
    paymentGatewaySubscription: Subscription = new Subscription();

    constructor(
        protected sikoCheckoutService: SikoCheckoutService,
        protected sikoCheckoutAdapter: SikoOccCheckoutAdapter,
        protected orderFacade: OrderFacade,
        protected globalMessageService: GlobalMessageService,
        protected translationService: TranslationService,
        readonly trackingUtils: SikoTrackingUtils,
        readonly actions: Actions,
    ) {
        super(orderFacade, globalMessageService, translationService);
    }

    getSikoOrder(): Observable<SikoOrder | undefined> {
        return this.order$;
    }

    // eslint-disable-next-line complexity
    ngOnInit(): void {
        super.ngOnInit();

        this.trackingSubscription = this.actions.pipe(ofType(OrderActions.LOAD_ORDER_DETAILS_SUCCESS))
            .subscribe((data: LoadOrderDetailsSuccess) => {
                this.trackingUtils.pushPurchaseEvent(data.payload, 'purchase_confirmation');
            });

        switch (this.pgStatus) {
            case CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_PENDING:
            case CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_UNKNOWN:
            case CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_AUTHORIZED:
            case CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_INITIATED:
            case CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_REPLACED:
                this.statusMessageTranslationKey = 'siko.orderConfirmation.pendingMessage';
                break;
            case CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_CANCELLED:
            case CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_ABORTED:
                this.statusMessageTranslationKey = 'siko.orderConfirmation.cancelledMessage';
                break;
            case CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_PAID:
                this.statusMessageTranslationKey = 'siko.orderConfirmation.paidMessage';
                break;
            case CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_ERROR:
                this.statusMessageTranslationKey = 'siko.orderConfirmation.errorMessage';
                break;
            default:
                this.statusMessageTranslationKey = undefined;
        }
    }

    repayOrder(): void {
        if (this.order && this.order.guid) {
            this.paymentGatewaySubscription = this.sikoCheckoutAdapter.getPaymentGatewayBuilder(this.order.guid)
                .subscribe(
                    (pg: PaymentGateway) => {
                        this.sikoCheckoutService.handlePaymentGatewayRedirection(
                            Object.assign({}, this.order, { paymentGateway: pg.pgBuilder }),
                        );
                    },
                );
        }
    }

    showRepayOrderButton(): boolean {
        if (this.pgStatus) {
            return this.pgStatus.toLowerCase()
                    .includes(CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_CANCELLED.toLowerCase()) ||
                this.pgStatus.toLowerCase()
                    .includes(CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_ERROR.toLowerCase());
        }

        return false;
    }

    hasProformaPayment(order: SikoOrder): boolean {
        return order.paymentType?.code === PAYMENT_TYPES.PROFORMA_PAYMENT;
    }

}
