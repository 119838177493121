import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnChanges,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { CategoryNavigationUiComponent } from '@siko/features/header';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {CmsService, WindowRef} from '@spartacus/core';
import { CmsComponentsService, HamburgerMenuService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { Sikob2bHeaderCommunicationService } from '@siko/features/header/Sikob2bHeaderCommunication.service';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-mobile-category-navigation-ui',
    templateUrl: './mobile-category-navigation-ui.component.html',
    styleUrls: ['./mobile-category-navigation-ui.component.scss'],
})
export class MobileCategoryNavigationUiComponent extends CategoryNavigationUiComponent implements OnInit, OnChanges {

    @ViewChild('mobileAccordion') mobileAccordion?: NgbAccordion;
    @ViewChild('mobileMenu') mobileMenu?: ElementRef;
    @ViewChild('hamburgerMenu') hamburgerMenu?: ElementRef;
    @ViewChild('mobileMenuOverlay') mobileMenuOverlay?: ElementRef;

    isShowStickyMenu: Observable<boolean> = this.headerCommunicationService.isShowStickyMenu$;
    offCanvas = false;

    componentWithoutAccordion = [
        'SikoCMSImageGridComponent',
        'SimpleBannerComponent',
        'BrandBannerCMSComponent',
    ];

    componentWithAccordion = [
        'MenuBannerCMSComponent',
    ];

    constructor(
        readonly headerCommunicationService: Sikob2bHeaderCommunicationService,
        private readonly renderer2: Renderer2,
        router: Router,
        renderer: Renderer2,
        elemRef: ElementRef,
        cmsService: CmsService,
        cmsComponentsService: CmsComponentsService,
        protected hamburgerMenuService: HamburgerMenuService,
        protected readonly changeDetectorRef: ChangeDetectorRef,
        windowRef: WindowRef,
    ) {
        super(router,
            renderer,
            elemRef,
            cmsService,
            cmsComponentsService,
            hamburgerMenuService,
            changeDetectorRef,
            windowRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.offCanvas = false;
        this.headerCommunicationService.setIsShowStickyMenu(false);
    }

    ngOnChanges(): void {
        this.offCanvas = false;
    }

    toggleMobileMenu(): void {
        this.offCanvas = !this.offCanvas;
        this.mobileAccordion?.collapseAll();
    }

    @HostListener('document:click', ['$event'])
    hideUserNavMenu(event: MouseEvent): void {
        if (event.target instanceof HTMLElement && event.target.className.includes('redirect-element')) {
            this.toggleMobileMenu();
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (this.offCanvas && !this.hamburgerMenu?.nativeElement.contains(event.target) && !this.mobileAccordion._element.nativeElement.contains(event.target)) {
            this.toggleMobileMenu();
        }
    }
}
