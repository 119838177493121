import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { ConfigModule, DeferLoadingStrategy, PageMetaResolver, provideConfig, UrlModule } from '@spartacus/core';
import { FormErrorsModule, OutletModule, PageComponentModule, StarRatingModule } from '@spartacus/storefront';
import { B2bCommonModule, SikoProductPageMetaResolver, SikoScrolledToPdpDirective } from '@siko/shared';
import { NgbAccordionModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SikoInfoBannerModule } from '@siko/features/shared-components/info-banner-component/info-banner.module';
import { SikoPageSlotModule } from '@siko/features/shared-components/page-slot/page-slot.module';
import { SikoItemCounterModule } from '@siko/features/shared-components/item-counter/item-counter.module';
import { SikoProductDetailDialogModule } from '@siko/features/shared-components/product-detail-dialog/product-detail-dialog.module';
import { SikoMediaComponent } from '@siko/features/shared-components/media/media.component';
import { SikoProductSellUnitsModule } from '@siko/features/shared-components/product-sell-units/product-sell-units.module';
import {
    PromotionCampaignComponent,
    SikoAddToCartComponent,
    SikoProductDetailsFooterComponent,
    SikoProductDetailTabComponent,
    SikoProductDocumentsTabComponent,
    SikoProductInformationComponent,
    SikoProductIntroComponent,
    SikoProductReviewsTabComponent,
    SikoProductSummaryComponent,
    SikoTabParagraphContainerComponent,
} from './components';
import { SikoProductDetailGuard } from '../../../guards/product-detail.guard';
import { SikoTitleWithCountComponent } from './components/tab-paragraph-container/title-with-count/title-with-count.component';
import { SikoProductReviewItemComponent } from './components/tab-paragraph-container/product-review-item/product-review-item.component';
import { SikoProductReviewsBarComponent } from './components/tab-paragraph-container/product-reviews-bar/product-reviews-bar.component';
import { SikoProductAverageRatingComponent } from './components/tab-paragraph-container/product-average-rating/product-average-rating.component';
import { SikoProductMobileTabsComponent } from './components/tab-paragraph-container/product-mobile-tabs/product-mobile-tabs.component';
import { SikoProductTabsBottomSheetComponent } from './components/tab-paragraph-container/product-tabs-bottom-sheet/product-tabs-bottom-sheet.component';
import { SikoProductDocumentItemComponent } from './components/tab-paragraph-container/product-document-item/product-document-item.component';
import { SikoProductReviewsTabBottomSectionComponent } from './components/tab-paragraph-container/product-reviews-tab-bottom-section/product-reviews-tab-bottom-section.component';
import { SikoProductReviewsTabTopSectionComponent } from './components/tab-paragraph-container/product-reviews-tab-top-section/product-reviews-tab-top-section.component';
import { SikoProductReviewsTabFormComponent } from './components/tab-paragraph-container/product-reviews-tab-form/product-reviews-tab-form.component';
import { SikoStoreProductDisponibilityComponent } from '@siko/features/shared-components/store-product-disponibility/store-product-disponibility.component';
import { SikoDuckLoaderComponent } from '@siko/features/shared-components/duck-loader/duck-loader.component';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';
import { SikoProductPriceComponent } from '@siko/features/shared-components/product-price/product-price.component';
import { SikoProductHeaderTitleComponent } from '@siko/features/shared-components/product-header-title/product-header-title.component';
import { SikoProductAddToCartButtonComponent } from '@siko/features/shared-components/product-add-to-cart-button/product-add-to-cart-button.component';
import { SikoProductHeaderDescriptionComponent } from '@siko/features/shared-components/product-header-description/product-header-description.component';
import { SikoOpeningHoursComponent } from '@siko/features/shared-components/opening-hours/opening-hours.component';
import { SikoProductRatingComponent } from '@siko/features/shared-components/product-rating/product-rating.component';
import { SikoSkeletonComponent } from '@siko/features/shared-components/skeleton/skeleton.component';
import { SikoStoreFinderMapComponent } from '@siko/features/shared-components/store-finder-map/store-finder-map.component';
import { SikoDocumentItemComponent } from '@siko/features/shared-components/document-item/document-item.component';
import { SikoProductAvailabilityInfoComponent } from '@siko/features/shared-components/product-availability-info/product-availability-info.component';
import { SikoProductImagesComponent } from '@siko/components';
import { SikoProductAddToCartBonusButtonComponent } from '@siko/features/shared-components/product-add-to-cart-bonus-button/product-add-to-cart-bonus-button.component';

@NgModule({
    providers: [
        {
            provide: PageMetaResolver,
            useExisting: SikoProductPageMetaResolver,
            multi: true,
        }
    ],
    declarations: [
        SikoAddToCartComponent,
        SikoProductDetailsFooterComponent,
        SikoProductDetailTabComponent,
        SikoProductDocumentsTabComponent,
        SikoProductReviewsTabComponent,
        SikoProductSummaryComponent,
        SikoTabParagraphContainerComponent,
        SikoProductInformationComponent,
        SikoTitleWithCountComponent,
        SikoProductReviewItemComponent,
        SikoProductReviewsBarComponent,
        SikoProductAverageRatingComponent,
        SikoProductMobileTabsComponent,
        SikoProductTabsBottomSheetComponent,
        SikoProductDocumentItemComponent,
        SikoProductReviewsTabBottomSectionComponent,
        SikoProductReviewsTabTopSectionComponent,
        SikoProductReviewsTabFormComponent,
        SikoProductIntroComponent,
        PromotionCampaignComponent,
    ],
    imports: [
        FormErrorsModule,
        ConfigModule.withConfig({
            cmsComponents: {
                ProductInfoFooterComponent: {
                    component: SikoProductDetailsFooterComponent,
                    deferLoading: DeferLoadingStrategy.INSTANT,
                },
                ProductAddToCartComponent: {
                    component: SikoAddToCartComponent,
                },
                ProductSummaryComponent: {
                    component: SikoProductSummaryComponent,
                    guards: [SikoProductDetailGuard],
                },
                ProductImagesComponent: {
                    component: SikoProductImagesComponent,
                },
                CMSTabParagraphContainer: {
                    component: SikoTabParagraphContainerComponent,
                    deferLoading: DeferLoadingStrategy.INSTANT,
                },
                ProductDetailsTabComponent: {
                    component: SikoProductDetailTabComponent,
                },
                ProductReviewsTabComponent: {
                    component: SikoProductReviewsTabComponent,
                    deferLoading: DeferLoadingStrategy.INSTANT,
                },
                ProductDocumentsTabComponent: {
                    component: SikoProductDocumentsTabComponent,
                },
                ProductIntroComponent: {
                    component: SikoProductIntroComponent,
                },

            },
        }),
        NgbModalModule,
        OutletModule,
        PageComponentModule,
        ReactiveFormsModule,
        RouterModule,
        B2bCommonModule,
        StarRatingModule,
        SwiperModule,
        NgbAccordionModule,
        SikoStoreProductDisponibilityComponent,
        UrlModule,
        SikoDuckLoaderComponent,
        SikoPageSlotModule,
        SikoProductUnitComponent,
        SikoInfoBannerModule,
        SikoProductDetailDialogModule,
        SikoProductPriceComponent,
        SikoProductHeaderTitleComponent,
        SikoItemCounterModule,
        SikoMediaComponent,
        SikoProductSellUnitsModule,
        SikoProductAddToCartButtonComponent,
        SikoProductHeaderDescriptionComponent,
        SikoOpeningHoursComponent,
        SikoProductRatingComponent,
        SikoSkeletonComponent,
        SikoStoreFinderMapComponent,
        SikoDocumentItemComponent,
        SikoScrolledToPdpDirective,
        SikoProductAvailabilityInfoComponent,
        SikoProductAddToCartBonusButtonComponent,
    ],
    exports: [
        SikoAddToCartComponent,
    ],
})
export class ProductDetailPageModule {
}
