import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { CmsComponentData, CurrentProductService, ProductListItemContext } from '@spartacus/storefront';
import { SikoB2BUser, SikoProduct, SikoProductDisponibility } from '@siko/models';
import { SikoPickUpInOtherStoreDialogComponent } from '@siko/features/shared-components/pick-up-in-other-store-dialog/components/pick-up-in-other-store-dialog/pick-up-in-other-store-dialog.component';
import { CmsAddToCartComponent, EventService, isNotNullable, StateWithProduct, StateWithUser } from '@spartacus/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, pluck, tap } from 'rxjs/operators';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { PRODUCT_SAP_STATUS } from '@siko/constants';
import { NavigationEnd, Router } from '@angular/router';
import { SikoDialogService } from '@siko/shared';
import {
    SikoCartEntryEnum,
} from '@siko/features/shared-components/added-to-cart-dialog/components/added-to-cart-dialog/added-to-cart-dialog.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { AddToCartComponent } from '@spartacus/cart/base/components/add-to-cart';
import { isProductForSale, showAddToCartButtons } from '../../../product-utils';
import { SikoActiveCartService } from '../../../../cart';
import { SikoAutoUnsubscribe } from '@siko/common';


@SikoAutoUnsubscribe(['routerEventsSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-add-to-cart',
    templateUrl: './add-to-cart.component.html',
    styleUrls: ['./add-to-cart.component.scss'],
})
export class SikoAddToCartComponent extends AddToCartComponent implements OnInit {

    forSale = true;
    isSale = false;
    showAddToCartButtons?: boolean;
    purchasableJustInOtherShop = false;
    modalRef!: NgbModalRef;
    routerEventsSubscription?: Subscription;
    showDeliveryInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    user$: Observable<SikoB2BUser | undefined> = this.userProfileFacade.get();

    predefinedPointOfServiceName$: Observable<unknown> = this.userProfileFacade.get()
        .pipe(
            pluck('orgUnit', 'sikoPredefinedPointOfService', 'displayName'),
        );

    sikoProduct$: Observable<SikoProduct | null> = this.currentProductService.getProduct()
        .pipe(
            filter(isNotNullable),
            tap((product: SikoProduct) => {
                if (product && product.disponibility && product.availableIn !== undefined && product.hasOwnProperty('sapStatus')) {
                    this.forSale = isProductForSale(product.disponibility, +product.availableIn, product.sapStatus!);
                }
                else {
                    this.forSale = true;
                }

                if (product.hasOwnProperty('sapStatus')) {
                    this.isSale = product.sapStatus === PRODUCT_SAP_STATUS.SAP_STATUS_Z2;
                }

                if (product.disponibility?.level === 0 && product.availableIn !== '0') {
                    this.purchasableJustInOtherShop = true;
                }
                else {
                    this.purchasableJustInOtherShop = false;
                }

                if (product.availableIn && product.code && this.forSale) {
                    const inCentralStock: boolean = product.disponibility?.level ? product.disponibility.level > 0 : false;

                    this.productCode = product.code;
                    this.showAddToCartButtons = showAddToCartButtons(this.forSale, inCentralStock, +product.availableIn, product.sapStatus);
                }
                else {
                    this.showAddToCartButtons = false;
                }

                if (product.disponibility) {
                    this.showDeliveryInfo.next(product.disponibility.status.includes('onOrder'));
                }
            }),
        );

    constructor(
        private readonly store: Store<StateWithProduct>,
        private readonly userStore: Store<StateWithUser>,
        private readonly userProfileFacade: UserProfileFacade,
        private readonly router: Router,
        readonly dialogService: SikoDialogService,
        private readonly sikoActiveCartService: SikoActiveCartService,
        protected currentProductService: CurrentProductService,
        protected cd: ChangeDetectorRef,
        protected activeCartService: ActiveCartFacade,
        protected component: CmsComponentData<CmsAddToCartComponent>,
        protected eventService: EventService,
        @Optional() protected productListItemContext?: ProductListItemContext,
    ) {
        super(
            currentProductService,
            cd,
            activeCartService,
            component,
            eventService,
            productListItemContext,
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.routerEventsSubscription = this.router.events.pipe(
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        )
            .subscribe((value: NavigationEnd) => {
                this.addToCartForm.reset({ quantity: 1 });
            });
    }

    sikoAddToCart(sikoProduct: SikoProduct, bonusPoints = 0): void {

        const canAddToCart = this.sikoActiveCartService.canAddToCart();

        if (!canAddToCart) {return;}

        const productStoreDisponibility: SikoProductDisponibility | undefined = sikoProduct.disponibility;

        if (!productStoreDisponibility) {
            return;
        }

        if (this.addToCartForm.get('quantity')) {
            const quantity: number = this.addToCartForm.get('quantity')?.value as number;

            if (!this.productCode || quantity <= 0) {
                return;
            }

            this.sikoActiveCartService.openAddingToCartModal(
                quantity,
                this.productCode,
                SikoCartEntryEnum.DELIVERY,
                'pdp',
                bonusPoints
            );
        }
    }

}
