/* eslint-disable @typescript-eslint/consistent-type-assertions */
import {
    CartNameSource,
    defaultImportExportConfig,
    ExportConfig,
    ImportExportConfig
} from '@spartacus/cart/import-export/core';
import { OrderEntriesSource } from '@spartacus/cart/base/root';

export const CUSTOM_IMPORT_EXPORT_CONFIG = {
    cartImportExport: {
        file: { ...defaultImportExportConfig.cartImportExport?.file },
        import: {
            fileValidity: {
                maxSize: 1,
                maxEntries: {
                    [OrderEntriesSource.NEW_SAVED_CART]: 100,
                    [OrderEntriesSource.SAVED_CART]: 100,
                    [OrderEntriesSource.ACTIVE_CART]: 100,
                    [OrderEntriesSource.QUICK_ORDER]: 100,
                },
                allowedTypes: [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                    'text/csv',
                    '.csv',
                ],
            },
            cartNameGeneration: {
                source: CartNameSource.FILE_NAME,
            },
        },
        export: {
            additionalColumns: [
                {
                    name: {
                        key: 'code',
                    },
                    value: 'product.code',
                },
                {
                    name: {
                        key: 'quantity',
                    },
                    value: 'quantity',
                },
                {
                    name: {
                        key: 'package',
                    },
                    value: 'product.package',
                },
                {
                    name: {
                        key: 'name',
                    },
                    value: 'product.name',
                },
            ],
            messageEnabled: true,
            downloadDelay: 1000,
            maxEntries: 1000,
            fileOptions: {
                fileName: 'cart',
                extension: 'csv',
                type: 'text/csv',
            },
        } as ExportConfig
    }
} as ImportExportConfig;

export const CUSTOM_EXPORT_OFFER_CONFIG = {
    cartImportExport: {
        file: { ...defaultImportExportConfig.cartImportExport?.file },
        import: { ...defaultImportExportConfig.cartImportExport?.import },
        export: {
            additionalColumns: [
                {
                    name: {
                        key: 'code',
                    },
                    value: 'product.code',
                },
                {
                    name: {
                        key: 'name',
                    },
                    value: 'product.name',
                },
                {
                    name: {
                        key: 'retailPrice',
                    },
                    value: 'product.sikoRetailPrice.formattedValue',
                },
                {
                    name: {
                        key: 'quantity',
                    },
                    value: 'product.amount',
                },
                {
                    name: {
                        key: 'unit',
                    },
                    value: 'product.unit',
                },
                {
                    name: {
                        key: 'discount',
                    },
                    value: 'product.discount',
                },
                {
                    name: {
                        key: 'customerPrice',
                    },
                    value: 'product.price.formattedValue',
                },
                {
                    name: {
                        key: 'totalPrice',
                    },
                    value: 'totalPrice.formattedValue',
                },
                {
                    name: {
                        key: 'url',
                    },
                    value: 'product.url.formattedValue',
                },
            ],
            messageEnabled: true,
            downloadDelay: 1000,
            maxEntries: 1000,
            fileOptions: {
                fileName: 'offer',
                extension: 'csv',
                type: 'text/csv',
            },
        } as ExportConfig
    }
} as ImportExportConfig;
