import { Component, Input } from '@angular/core';
import { SikoBonusRecordEntryModel } from '../../../../../../../models/bonus-program.model';

@Component({
    selector: 'app-record-item-list',
    templateUrl: './record-item-list.component.html',
    styleUrls: ['./record-item-list.component.scss'],
})
export class SikoRecordItemListComponent {
    @Input() entries?: SikoBonusRecordEntryModel[];
    @Input() typeOfRecord? : 'order' | 'invoice';
}
