import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { SikoAtomicComponentsModule } from '@siko/components';
import { SikoInfoBannerModule } from '@siko/features/shared-components/info-banner-component/info-banner.module';
import { SikoMessageComponentModule } from '@siko/features/misc/message/message.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SikoOrderOverviewComponent } from './order-overview/order-overview.component';
import { SikoSapOrderPaymentComponent } from './sap-order-payment/sap-order-payment.component';
import { MultiCartService } from '@spartacus/cart/base/core';
import { B2bCommonModule } from '@siko/shared';
import { CheckoutPaymentTypeConnector, CheckoutPaymentTypeService } from '@spartacus/checkout/b2b/core';
import { SikoOrderStatusBadgeModule } from '@siko/features/shared-components/order-status-badge/order-status-badge.module';
import { SikoReorderButtonComponent } from '@siko/features/shared-components/reorder-button/reorder-button.component';

@NgModule({
    providers: [
        MultiCartService,
        CheckoutPaymentTypeService,
        CheckoutPaymentTypeConnector
    ],
    declarations: [SikoOrderOverviewComponent, SikoSapOrderPaymentComponent],
    exports: [
        SikoSapOrderPaymentComponent,
        SikoOrderOverviewComponent,
    ],
    imports: [
        CommonModule,
        I18nModule,
        SikoAtomicComponentsModule,
        SikoInfoBannerModule,
        SikoMessageComponentModule,
        NgbModule,
        B2bCommonModule,
        SikoOrderStatusBadgeModule,
        SikoReorderButtonComponent,
    ],
})
export class SikoOrderOverviewModule {}
