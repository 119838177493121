<ng-container *ngIf='sikoProduct'>
  <div class='pdp-product-price'>
    <!--    Mobile retail price start-->
    <div class='d-block d-lg-none'>
      <div class='row'>
        <div class='col'>
          <div class='d-flex'>
            <div class='text'>{{ 'sikoProductShared.sikoRetailPrice' | cxTranslate }}&nbsp;</div>
            <div class='text'>
              <app-product-unit
                [withSlash]='true'
                [productUnitData]='sikoProduct.unitData'
                [inheritTextStyles]='true'
              ></app-product-unit>
            </div>
          </div>
        </div>
      </div>
      <div class='row'>
        <div [ngClass]='isDiscounted ? "col-12 mt-8" : "col-6"'>
          <div class='d-flex align-items-center'>
            <div *ngIf='isDiscounted' class='text--discount-mute'>
              {{ sikoProduct.sikoBasePrice | sikoPrice }}&nbsp;
            </div>
            <div class='text pl-2'>
              <app-price
                [price]='sikoProduct.sikoRetailPrice | sikoPrice'
                [MOPrice]='true'
                [showTaxLabel]='true'
                [withTax]='true'
              ></app-price>
            </div>
          </div>
        </div>
        <div class='col-6'>
          <div class='d-flex align-items-center'>
            <ng-container *ngIf='isDiscounted; else notDiscountedMobile'>
              <div class='text text--discount'>
                <app-price
                  [price]='sikoProduct.discountPrice | sikoPrice'
                  [MOPrice]='true'
                  [showTaxLabel]='true'
                ></app-price>
              </div>
            </ng-container>
            <ng-template #notDiscountedMobile>
              <div class='text'>
                <app-price
                  [price]='sikoProduct.basePrice | sikoPrice'
                  [MOPrice]='true'
                  [showTaxLabel]='true'
                ></app-price>
              </div>
            </ng-template>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
    <!--    Mobile retail price end-->
    <!--    Main price section start-->
    <div class='main-price'>
      <div class='main-price-container'>
        <div class='price-section-border mr-16 mr-lg-0'>
        </div>
        <div class='price-section'>
          <div class='main-price-inner-wrapper p-2'>
            <div class='d-block'>
              <span class='text'>{{ 'sikoProductShared.yourB2bPrice' | cxTranslate }}</span>&nbsp;
              <span class='text text--muted text-nowrap'
                    [attr.data-testId]='$any("pdp-discount")'>({{ 'siko.withoutVat' | cxTranslate }})</span>&nbsp;
              <span *ngIf='sikoProduct.discountPercentString' class='caption h4'><span
                [ngClass]="isUpdating.getValue() ? 'animated-opacity' : ''">-{{ sikoProduct.discountPercentString }}</span></span>&nbsp;
              <span class='d-inline-block'><app-product-group [sikoProduct]='sikoProduct'></app-product-group></span>
            </div>
            <div class='d-flex align-items-end'>
          <span *ngIf='sikoProduct.price as productPrice'
                [innerHTML]='isUpdating.getValue() ? constructPriceHtml(productPrice, true) : constructPriceHtml(productPrice, false)'></span>&nbsp;
              <span class='caption caption--muted title--h3 pb-1'>
            <app-product-unit
              [productUnitData]='sikoProduct.unitData'
              [withSlash]='true'
              [inheritTextStyles]='true'
            ></app-product-unit>
          </span>
            </div>
            <div class='product-recalculation-container'>
              <ng-container
                *ngIf='!updatePriceSuccess.getValue() && !updatePriceFailed.getValue() else recalculationProcessed'>
                <div class='recalculate-action' (click)='updateProductPrice()'
                     [attr.data-testid]='$any("update-price-button")'>
                  <span class='product-recalculate-icon'
                        [ngClass]="this.isUpdating.getValue() ? 'product-recalculate-icon--animated' : ''">
                  </span>&nbsp;
                  <span class='text--muted text--underline'>
                    <ng-container *ngIf='!isUpdating.getValue() else isUpdatingTemplate'>
                      <span>
                        {{ 'sikoProductShared.updatePrice.title' | cxTranslate }}
                      </span>
                    </ng-container>
                    <ng-template #isUpdatingTemplate>{{ 'sikoProductShared.updatePrice.updating' | cxTranslate }}
                    </ng-template>
                  </span>
                </div>
              </ng-container>
              <ng-template #recalculationProcessed>
                <ng-container *ngIf='updatePriceSuccess.getValue()'>
                  <div class='recalculation-status'>
                    <div class='success-icon mr-2'></div>
                    <span class='siko-fs-14 siko-color-success-primary'>
                      {{ 'sikoProductShared.updatePrice.success' | cxTranslate }}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf='updatePriceFailed.getValue()'>
                  <div class='recalculation-status'>
                    <span class='fail-icon mr-2'></span>
                    <span class='siko-fs-14 siko-color-error-primary'>
                      {{ 'sikoProductShared.updatePrice.fail' | cxTranslate }}
                    </span>
                  </div>
                </ng-container>
              </ng-template>
              <div class='bonus-points'>
                <app-bonus-points-info *ngIf='sikoProduct.sikoBonusProductCoefficient'
                                       [type]='"reactive"'
                                       [coefficient]='sikoProduct.sikoBonusProductCoefficient'
                                       [recalculated]='updatePriceSuccess'
                                       [productCode]='sikoProduct.code'>
                </app-bonus-points-info>
              </div>
            </div>
          </div>
        </div>
        <!--      Tablet and desktop retail price start-->
        <div class='p-2 py-lg-3 pl-lg-32 d-none d-lg-block'>
          <div class='d-flex'>
            <span class='text'>{{ 'sikoProductShared.sikoRetailPrice' | cxTranslate }}&nbsp;</span>
            <span class='text'><app-product-unit
              [withSlash]='true'
              [productUnitData]='sikoProduct.unitData'
              [inheritTextStyles]='true'
            ></app-product-unit></span>
          </div>
          <ng-container
            *ngIf='isDiscounted else notDiscounted'>
            <div class='d-flex align-items-center'>
              <div class='text--discount-mute mt-8'>
                {{ sikoProduct.sikoBasePrice | sikoPrice }}
              </div>
            </div>
            <div class='text'>
              <app-price
                [price]='sikoProduct.sikoRetailPrice | sikoPrice'
                [MOPrice]='true'
                [showTaxLabel]='true'
                [withTax]='true'
              ></app-price>
            </div>
            <div class='text text--discount'>
              <app-price
                [price]='sikoProduct.discountPrice | sikoPrice'
                [MOPrice]='true'
                [showTaxLabel]='true'
              ></app-price>
            </div>
          </ng-container>
          <ng-template #notDiscounted>
            <div class='text'>
              <app-price
                [attr.data-testId]='$any("pdp-moPrice")'
                [price]='sikoProduct.sikoRetailPrice | sikoPrice'
                [showTaxLabel]='true'
                [withTax]='true'
                [MOPrice]='true'>
              </app-price>
              <app-price
                [attr.data-testId]='$any("pdp-moPrice-withTax")'
                [price]='sikoProduct.basePrice | sikoPrice'
                [showTaxLabel]='true'
                [MOPrice]='true'>
              </app-price>
            </div>
          </ng-template>
        </div>
      </div>
      <!--    Main price section end-->
      <div class='d-flex mb-32 align-items-center' *ngIf='sikoProduct?.unitData?.code === productWithConversionRate'>
        <div class='mr-3'>
          <img [src]='"pdp/package.svg" | sikoIconAsset' alt='Package icon'>
        </div>
        <div class='text text--muted'>
          <div class='d-block'>
            {{ 'sikoProductShared.priceForPackage' | cxTranslate }}
            ({{ sikoProduct.unitData?.conversion | sikoCxNumeric : '1.0-3' }}
            <app-product-unit
              [productUnitData]='sikoProduct.unitData'
              [inheritTextStyles]='true'
            ></app-product-unit>
            )
          </div>
          <div class='d-block'>
            {{ sikoProduct.unitPrice | sikoPrice }}&nbsp;({{ 'siko.withoutVat' | cxTranslate }})
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
