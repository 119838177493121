<ng-container *ngIf='messages$ | async as messages'>
  <div class='alert-wrapper'>
    <div
      [@slideInOut]
      class='alert alert-success' *ngFor='
      let confMsg of messages[messageType.MSG_TYPE_CONFIRMATION];
      let i = index
    '>
      <div class='d-flex w-100 align-items-center'>
        <div class='icon-wrapper success p-4'>
        </div>
        <div class='text-wrapper'>
          <div [innerHTML]='confMsg | cxTranslate'></div>
          <div class='close-button'
               (click)='clear(messageType.MSG_TYPE_CONFIRMATION, i)'>
          </div>
        </div>
      </div>
    </div>
    <div
      [@slideInOut]
      class='alert alert-info' *ngFor='
      let confMsg of messages[messageType.MSG_TYPE_INFO];
      let i = index
    '>
      <div class='d-flex w-100 align-items-center'>
        <div class='icon-wrapper info p-4'>
        </div>
        <div class='text-wrapper'>
          <div [innerHTML]='confMsg | cxTranslate'></div>
          <div class='close-button'
               (click)='clear(messageType.MSG_TYPE_INFO, i)'>
          </div>
        </div>
      </div>
    </div>
    <div
      [@slideInOut]
      class='alert alert-warning' *ngFor='
      let confMsg of messages[messageType.MSG_TYPE_WARNING];
      let i = index
    '>
      <div class='d-flex w-100 align-items-center'>
        <div class='icon-wrapper warning p-4'>
        </div>
        <div class='text-wrapper'>
          <div [innerHTML]='confMsg | cxTranslate'></div>
          <div class='close-button'
               (click)='clear(messageType.MSG_TYPE_WARNING, i)'>
          </div>
        </div>
      </div>
    </div>
    <div
      [@slideInOut]
      class='alert alert-danger' *ngFor='
      let confMsg of messages[messageType.MSG_TYPE_ERROR];
      let i = index
    '>
      <div class='d-flex w-100 align-items-center'>
        <div class='icon-wrapper danger p-4'>
        </div>
        <div class='text-wrapper'>
          <div [innerHTML]='confMsg | cxTranslate'></div>
          <div class='close-button'
               (click)='clear(messageType.MSG_TYPE_ERROR, i)'>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
