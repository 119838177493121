<ng-container *ngIf='currentProduct$ | async as currentProduct'>
  <ng-container *ngIf='storeEntities else loading'>
    <app-dialog-header (clickEvent)='close()'>
      <div class='d-flex align-items-center'>
        <img [src]='"pdp/locations.svg" | sikoIconAsset' alt='Locations icon'
             class='d-none d-md-inline-block title-icon'>
        {{ "siko.pickupInOtherStoreDialog.productAvailabilityByStore" | cxTranslate }}
      </div>
    </app-dialog-header>
    <div class='siko-dialog-body no-scroll-md mt-0 mt-sm-32'>
      <div class='row desktop px-2 pb-4 d-none d-md-flex'>
        <div class='col-md-6 border-right'>
          <div class='ml-0 ml-sm-24'>
            <div class='siko-font-primary-semiBold'>
              {{"siko.otherStoreDialog.searchHeading" | cxTranslate }}
            </div>
            <div class='search mb-32'>
              <app-stores-search-input
                [placeholder]='"siko.searchStorePlaceHolder" | cxTranslate'
                (searchValue)='searchStore($any($event))'
              ></app-stores-search-input>
            </div>
          </div>
          <div class='store-list'>
            <ul class='list-group '>
              <li
                *ngFor='let storeEntity of filteredStoreEntities'
                [ngClass]="{'active': storeEntity === modalSelectedPointOfService}"
                class='list-group-item'
                (click)='setModalSelectedPointOfService(storeEntity)'
              >
                <div class='d-flex justify-content-between'>
                  <div class='p-2 store-name'>
                    <strong class='d-block'><span
                      class='siko-prefix'>SIKO</span>{{removeSikoPrefix(storeEntity.displayName)}}
                    </strong>
                    <small class='address'>
                      {{storeEntity.address?.line1}}
                      {{storeEntity.address?.line2}},
                      {{storeEntity.address?.postalCode}},
                      {{storeEntity.address?.town}}
                    </small>
                  </div>
                  <div class='p-2 align-self-center disponibility-info'>
                    <app-store-product-disponibility
                      [mainStoreEntity]='mainStoreEntity'
                      [storeEntity]='storeEntity'
                      [unit]='currentProduct.unitData'
                      [deliveryTimeText]='storeEntity.name?.includes("O 10100265") ? currentProduct.disponibility?.deliveryTimeText : undefined'
                    ></app-store-product-disponibility>
                  </div>
                  <div class='arrow align-self-center'>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class='col-md-6 store-info'>
          <ng-container *ngIf='modalSelectedPointOfService'>
              <h3 class='heading'>{{modalSelectedPointOfService.displayName}}</h3>
              <app-store-finder-map
                #storeMap
                [latitude]='$any(modalSelectedPointOfService.geoPoint?.latitude)'
                [longitude]='$any(modalSelectedPointOfService.geoPoint?.longitude)'
              >
              </app-store-finder-map>
              <div class='row pt-5'>
                <div class='col-lg-6'>
                  <app-opening-hours
                    [tooltipPosition]='$any("right")'
                    [openingHours]=modalSelectedPointOfService?.openingHours
                  ></app-opening-hours>
                </div>
                <div class='col-lg-6'>
                  <h5>{{"siko.contactHeading" | cxTranslate }}</h5>
                  <div class='phone' *ngIf='modalSelectedPointOfService.address?.phone'>
                    <span class='icon'></span> {{modalSelectedPointOfService.address?.phone}}
                  </div>
                  <div class='email' *ngIf='modalSelectedPointOfService.address?.email'>
                    <span class='icon'></span> {{modalSelectedPointOfService.address?.email}}
                  </div>
                  <div class='btn-store-detail'>
                    <siko-button
                      [mode]='$any("light")'
                      [size]='$any("md")'
                      [block]='false'
                      [text]='"siko.storeDetail" | cxTranslate'
                      (clickEvent)='goToStoreDetailLink(modalSelectedPointOfService)'>
                    </siko-button>
                  </div>
                </div>
              </div>
          </ng-container>
        </div>
      </div>
      <div class='d-block d-md-none'>
        <app-mobile-pick-up-in-other-store-dialog-content
          [currentProduct]='currentProduct'
          [storeEntities]='storeEntities'
          [filteredStoreEntities]='filteredStoreEntities'
          [modalSelectedPointOfService]='modalSelectedPointOfService'>
        </app-mobile-pick-up-in-other-store-dialog-content>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <app-dialog-header [loading]='true'>
    </app-dialog-header>
    <div class='siko-dialog-body loading'>
      <app-stores-loading-skeleton></app-stores-loading-skeleton>
    </div>
  </ng-template>
</ng-container>
