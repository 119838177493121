<ng-container *ngIf='sikoUser$ | async as sikoUser'>
  <ng-container *ngIf='order'>
    <div class='section-thank-message'>
      <div class='cx-page-header'>
        <ng-container
          *ngIf='showRepayOrderButton() else paymentSucceeded'>
          <div class='row pb-24'>
            <div class='col'>
              <img [src]='"checkout/warning.svg" | sikoIconAsset' alt='Warning icon'>
            </div>
          </div>
          <h1 *ngIf='statusMessageTranslationKey'>{{ statusMessageTranslationKey | cxTranslate }}</h1>
        </ng-container>
        <ng-template #paymentSucceeded>
          <div class='process-complete'>
            <div class='row'>
              <div class='col-4 step'>
                <img [src]='"checkout/done-small.svg" | sikoIconAsset' alt='Check mark small icon'>
              </div>
              <div class='col-4 step main'>
                <img [src]='"checkout/done-big.svg" | sikoIconAsset' alt='Check mark big icon'>
              </div>
              <div class='col-4 step'>
                <img [src]='"checkout/done-small.svg" | sikoIconAsset' alt='Check mark small icon'>
              </div>
            </div>
          </div>
          <h1>{{ 'siko.orderConfirmation.thankYouMessage' | cxTranslate }}</h1>
        </ng-template>
      </div>
      <div class='row'>
        <div class='col-12 text-center'>
          <div class='siko-fs-16'>
            <p>
              {{'siko.orderConfirmation.processingOrderMessage' | cxTranslate }}
              {{"siko.orderConfirmation.confirmOrderMessage" | cxTranslate }}
            </p>
            <p>
              {{ 'siko.orderConfirmation.emailMessage' | cxTranslate}}
              <span class='siko-font-primary-semiBold'>
                {{sikoUser.uid}}
              </span>
              <span class='proforma-info' *ngIf='hasProformaPayment(order)'
                    [innerHtml]="'siko.orderConfirmation.proformaMessage' | cxTranslate:
              { paymentType: '<b>' + (order.paymentType | sikoPaymentTypeName) + '</b>' }">
              </span>
            </p>
            <div class='bonus-program-info' *ngIf='order.sikoBonusPointsReceived'>
              <div class='points'><app-bonus-program-group-icon class="pr-2" iconMode='yellow'></app-bonus-program-group-icon>{{'siko.orderConfirmation.bonusProgramPoints' | cxTranslate : { count: order.sikoBonusPointsReceived | cxNumeric : "1.0-2"} }}</div>
              <div class='info d-flex justify-content-center'>{{'siko.orderConfirmation.bonusProgramInfo' | cxTranslate}}</div>
            </div>
            <ng-container *ngIf='showRepayOrderButton()'>
              <div class='row mt-32'>
                <div *ngIf='statusMessageTranslationKey' class='col-md-4 mx-auto'>
                  <siko-button
                    mode='cart'
                    size='md'
                    text="{{'siko.repeatPayment' | cxTranslate }}"
                    (clickEvent)='repayOrder()'>
                  </siko-button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <cx-add-to-home-screen-banner></cx-add-to-home-screen-banner>
    </div>
  </ng-container>
</ng-container>

