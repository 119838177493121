import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Translatable } from '@spartacus/core';

export type BannerMode = 'danger' | 'info' | 'success' | 'warning';

@Component({
    selector: 'app-info-banner-component',
    templateUrl: './info-banner-component.component.html',
    styleUrls: ['./info-banner-component.component.scss']
})
export class SikoInfoBannerComponentComponent {

  @Input() text?: Translatable | string;
  @Input() mode?: BannerMode;
  @Input() closable = false;
  @Input() link = '';
  @Input() titleLink = '';
  @Input() heading?: string;
  @Input() isInnerHtml: boolean = false;
  @Output() readonly closeClickEvent = new EventEmitter();

  get bannerWrapperClass(): string{
      return `banner-wrapper--${this.mode}`;
  }

  get iconWrapperClass(): string{
      return `icon-wrapper--${this.mode}`;
  }

  onCloseButtonCLick($event: Event): void {
      this.closeClickEvent.emit();
      $event.preventDefault();
  }

}
