import { StateUtils } from '@spartacus/core';
import { SikoOrderHistory, SikoSelectField } from '@siko/models';

export const ORDER_FILTER_HISTORY_FEATURE = 'orderFilterHistory';

export interface StateWithFilterHistory {
    [ORDER_FILTER_HISTORY_FEATURE]: OrderHistoryFilterState;
}

export interface OrderHistoryFilterState {
    orderHistoryState: StateUtils.LoaderState<OrderHistoryFilterProps>;
}

export interface OrderHistoryFilterProps {
    filters: OrderHistoryFilters;
    orders: SapOrderHistory;
}

export interface OrderHistoryFilters {
    startDate?: string;
    endDate?: string;
    selectedPeriod?: string;
    searchedText?: string;
    orderStatus?: string;
    orderOrigin?: string;
    deliveryMethod?: string;
    ownerUnit?: string;
    extendedFiltersOn?: boolean;
    pageNumber?: number;
}

export interface SapOrderHistory {
    orderHistoryList: SikoOrderHistory[];
    orderOriginCriteria?: SikoSelectField[];
    orderStatusCriteria?: SikoSelectField[];
}

export interface SapOrderHistoryDateParams {
    startDate: string;
    endDate: string;
}
