import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SikoProductDocument } from '@siko/models';
import { DOCUMENT_ICON_TYPE } from '@siko/constants';
import { SikoUtils } from '@siko/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-document-item',
    templateUrl: './product-document-item.component.html',
    styleUrls: ['./product-document-item.component.scss'],
})
export class SikoProductDocumentItemComponent {
    @Input() sikoProductDocument?: SikoProductDocument;

    // TODO replace for Angular pipe
    getIconType(documentType: string | undefined): DOCUMENT_ICON_TYPE {
        if (documentType === 'MODEL') {
            return DOCUMENT_ICON_TYPE.MODEL;
        }

        return DOCUMENT_ICON_TYPE.BY_TYPE;
    }

    // TODO replace for Angular pipe
    getDocumentExtension(mimeType: string | undefined): string | undefined {
        if (!mimeType) {return;}
        return SikoUtils.getFileExtensionByMimeType(mimeType);
    }
}
