import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { SikoGlobalMessageComponent } from './global-message.component';

@NgModule({
    imports: [CommonModule, I18nModule, IconModule],
    declarations: [SikoGlobalMessageComponent],
    exports: [
        SikoGlobalMessageComponent,
    ],
})
export class SikoGlobalMessageComponentModule {
}
