<ng-container *bonusProgramVisibility>
  <div [ngClass]='buttonWrapperClassList'>
    <div class='buy-with-points mt-4 mt-md-0' [ngClass]='isShowingBonusPointOptions.value ? "shown-options" : ""'>
      <div class='button' (click)='isShowingBonusPointOptions.next(!isShowingBonusPointOptions.value)'>
        <app-bonus-program-group-icon [iconMode]='isShowingBonusPointOptions.value ? "yellow" : undefined'
                                      class='pr-2'></app-bonus-program-group-icon>
        {{ 'sikoProductShared.buyWithPoints' | cxTranslate }}
        <app-vector-icon class='icon toggle' iconMode='delivery-type'></app-vector-icon>
      </div>
      <div class='groups' *ngIf='isShowingBonusPointOptions.value'>
        <div class='siko-fs-14 pl-4'
             [innerHTML]='"sikoProductShared.actualPoints" | cxTranslate: {count: this.currentPoints, countString: (this.currentPoints | cxNumeric : "1.0-2")}'>
        </div>
        <ng-container *ngFor='let bonusConfig of inputProduct?.sikoBonusProductConfigs'>
          <div class='button config mb-3 d-flex justify-content-center' (click)='onClick(bonusConfig.points,$event)'
               [ngClass]='{ inactive : !isConfigActive(bonusConfig.points) }'>
            <div class='info'>
              <span class='points pr-2'><app-bonus-program-group-icon
                [iconMode]='isConfigActive(bonusConfig.points) ? "small" : "red"'></app-bonus-program-group-icon>
                {{ bonusConfig.points | cxNumeric : "1.0-2" }}</span> = <span class='pl-2'><app-label-icon
              class='pr-1'></app-label-icon>-{{ bonusConfig.discount | sikoPrice }}</span>
            </div>
            <app-cart-icon class='icon'></app-cart-icon>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
