import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SikoProduct, SikoProductDisponibility } from '@siko/models';
import { B2bCommonModule } from '@siko/shared';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';
import { SikoVectorIconComponent } from '@siko/features/shared-components/icons/vector-icon/vector-icon.component';
import { BehaviorSubject } from 'rxjs';
import { SikoLabelIconComponent } from '@siko/features/shared-components/icons/label-icon/label-icon.component';
import { SikoCartIconComponent } from '@siko/features/shared-components/icons/cart-icon/cart-icon.component';
import { SikoBonusProgramGroupIconComponent } from '@siko/features/shared-components/icons/bonus-program-group-icon/bonus-program-group-icon.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-add-to-cart-button',
    templateUrl: './product-add-to-cart-button.component.html',
    styleUrls: ['./product-add-to-cart-button.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoProductUnitComponent,
        NgbTooltipModule,
        SikoVectorIconComponent,
        SikoLabelIconComponent,
        SikoCartIconComponent,
        SikoBonusProgramGroupIconComponent,
    ],
})
export class SikoProductAddToCartButtonComponent {

    @Input() textTop?: string;

    @Input() set sikoProduct(input: SikoProduct) {
        this.inputProduct = input;
    }

    @Output() readonly clickEvent = new EventEmitter();

    inputProduct?: SikoProduct;
    productDisponibility?: SikoProductDisponibility;


    // TODO Replace for Angular pipe
    get buttonWrapperClassList(): string[] {
        return [
            'button-wrapper',
        ];
    }

    // TODO Replace for Angular pipe
    get topTextClassList(): string[] {
        return [
            'top-text-container',
            `top-text-container--delivery`,
        ];
    }

    // TODO Replace for Angular pipe
    get iconClass(): string {
        return 'icon icon--cart';
    }

    onClick($event: Event): void {
        this.clickEvent.emit();
        $event.preventDefault();
    }

}
