import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { GlobalMessageType } from '@spartacus/core';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CartModification, CartValidationStatusCode, OrderEntry, Voucher } from '@spartacus/cart/base/root';
import { SIKO_CART_VALIDATION_STATUS, SikoCartModificationList } from '@siko/models';

@Injectable({
    providedIn: 'root',
})
export class SikoCartValidationGuard extends CartValidationGuard {

    canActivate(): Observable<UrlTree | boolean> {
        return !this.cartConfigService.isCartValidationEnabled()
            ? of(true)
            : this.cartValidationService.validateCart().pipe(
                withLatestFrom(this.activeCartService.getEntries()),
                map(([cartModificationList, cartEntries]) => {
                    const sikoCartModifications: SikoCartModificationList = cartModificationList;
                    const cartModifications = sikoCartModifications.cartModifications ?? [];
                    const removedCoupons = sikoCartModifications.removedCouponsList ?? [];

                    this.cartValidationStateService.updateValidationResultAndRoutingId(
                        cartModifications
                    );

                    if (!sikoCartModifications.setDeliveryType) {
                        this.globalMessageService.add(
                            {
                                key: 'sikoCartDetails.deliveryChangeButton.error'
                            },
                            GlobalMessageType.MSG_TYPE_WARNING,
                        );

                        return this.router.parseUrl(this.semanticPathService.get('cart') ?? '');
                    }

                    if (removedCoupons.length > 0) {
                        const coupons: string[] = [];

                        removedCoupons.map((coupon: Voucher) => {
                            if (coupon.code){
                                coupons.push(coupon.code);
                            }
                        });

                        this.globalMessageService.add(
                            {
                                key: 'cartShared.removedUnappliedCoupons',
                                params: {
                                    count: coupons.length,
                                    coupons: coupons.join(','),
                                },
                            },
                            GlobalMessageType.MSG_TYPE_WARNING,
                        );
                    }

                    if (cartModifications.length > 0) {
                        this.handleValidationMessage(cartModifications, cartEntries);
                        this.activeCartService.reloadActiveCart();

                        return this.router.parseUrl(this.semanticPathService.get('cart') ?? '');
                    }

                    return true;
                }),
                catchError(() => {
                    this.globalMessageService.add(
                        { key: 'httpHandlers.cart.reloadCartRequired' },
                        GlobalMessageType.MSG_TYPE_ERROR,
                    );
                    return of(false);
                })
            );
    }

    handleValidationMessage(cartModifications: CartModification[], cartEntries: OrderEntry[]): void {
        let validationResultMessage;
        const hasOneCartEntry = cartEntries.length === 1 &&
            cartEntries[0].product?.code === cartModifications[0].entry?.product?.code;
        const entryStatus = cartModifications[0].statusCode;

        if (hasOneCartEntry && (entryStatus === CartValidationStatusCode.NO_STOCK ||
            entryStatus === SIKO_CART_VALIDATION_STATUS.NOT_AVAILABLE)
        ) {
            validationResultMessage = {
                key: entryStatus === CartValidationStatusCode.NO_STOCK ?
                    'validation.productOutOfStock' : 'validation.cartEntryRemoved',
                params: {
                    name: cartModifications[0].entry?.product?.name,
                },
            };
        } else if (entryStatus === 'couponNotValid') {
            validationResultMessage = {
                key: 'validation.couponNotValid',
            }
        }
        else {
            validationResultMessage = {
                key: 'validation.cartEntriesChangeDuringCheckout',
            };
        }

        this.globalMessageService.add(
            validationResultMessage,
            GlobalMessageType.MSG_TYPE_ERROR,
            this.GLOBAL_MESSAGE_TIMEOUT
        );
    }

}
