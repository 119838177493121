import { Component, Input } from '@angular/core';
import { SikoProductUnitData } from '@siko/models';
import { B2bCommonModule } from '@siko/shared';

@Component({
    selector: 'app-product-unit',
    templateUrl: './product-unit.component.html',
    styleUrls: ['./product-unit.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule
    ]
})
export class SikoProductUnitComponent {
    @Input() productUnitData?: SikoProductUnitData;
    @Input() withSlash = false;
    @Input() muted = false;
    @Input() inheritTextStyles = false;
    @Input() count?:number = 1;

    get unitClasses(): string[] {
        return [
            this.muted ? 'unit--muted' : '',
            this.inheritTextStyles ? 'unit--inherit' : ''
        ];
    }
}
