import { MediaConfig } from '@spartacus/storefront';

export const MEDIA_CONFIG: MediaConfig = {
    mediaFormats: {
        mobile: { width: 800 },
        tablet: { width: 1125 },
        desktop: { width: 2100 },
        widescreen: { width: 2100 },
        // product media
        cartIcon: { width: 65 },
        thumbnail: { width: 96 },
        product: { width: 284 },
        zoom: { width: 515 },
    },
};
