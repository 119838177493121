import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RoutingService } from '@spartacus/core';
import { SikoCmsService } from '@siko/common';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-top-series',
    templateUrl: './top-series.component.html',
    styleUrls: ['./top-series.component.scss'],
})

export class Sikob2bTopSeriesComponent {

    @Input() localizedUrl = '';
    @Input() url = '';
    @Input() target = '';
    @Input() imageUrl = '';
    @Input() imageAlt = null;
    @Input() linkName = null;
    @Input() title = null;

    origin: string;
    readonly openWindow: (WindowProxy & typeof globalThis) | null;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly routingService: RoutingService,
        readonly sikoCmsService: SikoCmsService
    ) {
        this.origin = this.document.location.origin;
        this.openWindow = this.document.defaultView;
    }

    handleredirect(
        localizedUrl: string | undefined,
        url: string | undefined,
        external: boolean | string | undefined
    ): void {
        this.sikoCmsService.handleRedirect(localizedUrl, url, external);
    }

}
