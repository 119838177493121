<div class='d-md-max' *ngIf='product$ | async as product'>
  <app-title-with-count
    *ngIf='displayHeader'
    [text]="'pdp.tabs.ratings' | cxTranslate"
    [count]='product.numberOfReviews'
  ></app-title-with-count>
  <div class='row mt-32'>
    <div [ngClass]='sikoBreakpointObserver.isBreakpointActive(["xs","sm","md"]) &&
    (product.numberOfReviews === undefined || product.numberOfReviews === 0) ? "col-md-12" : "col-md-6"'>
      <app-product-reviews-tab-top-section></app-product-reviews-tab-top-section>
      <ng-container *ngIf='isReviewSent$ | async as isReviewSent'>
        <div *ngIf='isReviewSent; else failed' class='pt-32'>
          <app-info-banner-component
            mode='success'
            heading='{{ "pdp.reviewTab.thanksForReview" | cxTranslate }}'
          ></app-info-banner-component>
        </div>
        <ng-template #failed>
          <div class='pt-32'>
            <app-info-banner-component
              text='{{ "sikoCheckout.reviewSentFailed" | cxTranslate }}'
              mode='danger'
            ></app-info-banner-component>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf='isUserAllowedToWriteReview(product.sikoCanWriteReview)'>
        <ng-container *ngIf='!isWritingReview else isWriting'>
          <div class='responsive-button'>
            <div class='mt-4'>
              <siko-button
                testId='write-review-button'
                #writeReviewButton
                mode='secondary'
                size='md'
                (clickEvent)='initiateWriteReview()'
                [text]='"pdp.reviewTab.writeReview" | cxTranslate'
              >
              </siko-button>
            </div>
          </div>
        </ng-container>
        <ng-template #isWriting>
          <div class='row my-32'>
            <div class='col'>
              <ng-container *ngIf='showReviewTab$ | async as showReviewTab'>
                <div *ngIf='showReviewTab'>
                  <app-product-reviews-tab-form></app-product-reviews-tab-form>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class='col-md-6'>
      <app-product-reviews-tab-bottom-section></app-product-reviews-tab-bottom-section>
    </div>
  </div>
</div>

<div class='container d-md-min' *ngIf='product$ | async as product'>
  <app-title-with-count
    *ngIf='displayHeader'
    [text]="'pdp.tabs.ratings' | cxTranslate"
    [count]='product.numberOfReviews ? product.numberOfReviews : 0'
  ></app-title-with-count>
  <div class='row mt-24'>
    <ng-container *ngIf='isReviewSent$ | async as isReviewSent'>
      <div [ngClass]='product.numberOfReviews && product.numberOfReviews > 0 ? "reduced-col col-md-6" : "reduced-col col-md-12"'>
        <div *ngIf='isReviewSent; else failed'>
          <app-info-banner-component
            mode='success'
            heading='{{ "pdp.reviewTab.thanksForReview" | cxTranslate }}'
          ></app-info-banner-component>
        </div>
        <ng-template #failed>
          <div>
            <app-info-banner-component
              text='{{ "sikoCheckout.reviewSentFailed" | cxTranslate }}'
              mode='danger'
            ></app-info-banner-component>
          </div>
        </ng-template>
        <div class='pt-24'>
          <siko-button
            mode='secondary'
            (click)='closeTemplateSheetMenu()'
            [text]='"siko.backToStore" | cxTranslate'
          >
          </siko-button>
        </div>
      </div>
    </ng-container>
    <div [ngClass]='product.numberOfReviews && product.numberOfReviews > 0 ? "col-md-6" : "col-md-12"'>
      <ng-container *ngIf='showReviewTab$ | async as showReviewTab'>
        <div *ngIf='showReviewTab'>
          <app-product-reviews-tab-top-section></app-product-reviews-tab-top-section>
        </div>
      </ng-container>
      <ng-container *ngIf='isUserAllowedToWriteReview(product.sikoCanWriteReview)'>
        <ng-container *ngIf='!isWritingReview else writingReview'>
          <div class='responsive-button'>
            <div class='mt-4'>
              <siko-button
                testId='write-review-button'
                #writeReviewButton
                mode='secondary'
                size='sm'
                (clickEvent)='initiateWriteReview()'
                [text]='"pdp.reviewTab.writeReview" | cxTranslate'
              >
              </siko-button>
            </div>
          </div>
        </ng-container>
        <ng-template #writingReview>
          <div class='row my-32'>
            <ng-container *ngIf='showReviewTab$ | async as showReviewTab'>
              <div *ngIf='showReviewTab'>
                <app-product-reviews-tab-form
                ></app-product-reviews-tab-form>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class='col-md-6'>
      <ng-container *ngIf='showReviewTab$ | async as showReviewTab'>
        <div *ngIf='showReviewTab'>
          <app-product-reviews-tab-bottom-section></app-product-reviews-tab-bottom-section>
        </div>
      </ng-container>
    </div>
  </div>
</div>
