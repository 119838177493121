import { GlobalMessageConfig, GlobalMessageType } from '@spartacus/core';

export function sikoB2BGlobalMessageConfigFactory(): GlobalMessageConfig {
    return {
        globalMessages: {
            [GlobalMessageType.MSG_TYPE_CONFIRMATION]: {
                timeout: 7000,
            },
            [GlobalMessageType.MSG_TYPE_INFO]: {
                timeout: 7000,
            },
            [GlobalMessageType.MSG_TYPE_ERROR]: {
                timeout: 10000,
            },
            [GlobalMessageType.MSG_TYPE_WARNING]: {
                timeout: 10000,
            },
        },
    };
}
