import {
    ChangeDetectionStrategy,
    Component, ElementRef, Input, OnInit,
} from '@angular/core';
import { SortingComponent } from '@spartacus/storefront';

@Component({
    selector: 'app-sorting',
    templateUrl: './sorting.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./sorting.component.scss'],
})
export class SikoSortingComponent extends SortingComponent implements OnInit {
    @Input() countOfProducts?: number;
    @Input() showMobileSorting = false;
    countShowingOptions: number = 3;
    showMoreOptions: boolean = true;

    constructor(private readonly element: ElementRef) {
        super();
    }

    ngOnInit(): void {
        if (this.sortOptions && this.sortOptions.length <= 4) {
            this.countShowingOptions = 4;
            this.showMoreOptions = false;
        }
    }

    changeFn(val: any): void {
        if (val) {
            const el = this.element.nativeElement.querySelector('.' + val.code);

            el.click();
        }
    }
}
