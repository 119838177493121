import { DeferLoadingStrategy } from '@spartacus/core';
import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { SikoSavedCartFormDialogComponent } from '@siko/features/my-account/saved-cart/components/saved-cart-form-dialog/saved-cart-form-dialog.component';

export const CUSTOM_LAYOUT_CONFIG: LayoutConfig = {
    deferredLoading: {
        strategy: DeferLoadingStrategy.DEFER,
        intersectionMargin: '50px',
    },
    layoutSlots: {
        LoginPageTemplate: {
            header: {
                slots: ['SiteContext', 'SiteLinks', 'SiteLogo', 'SiteLogin', 'MiniCart'],
            },
            slots: ['LeftContentSlot', 'RightContentSlot'],
        },
        CartPageTemplate: {
            pageFold: 'TopContent',
            slots: ['TopContent', 'EmptyCartMiddleContent', 'CenterLeftContentSlot', 'CenterRightContentSlot', 'BottomContentSlot'],
        },
        SikoNewsPageTemplate: {
            slots: ['SikoNewsDynamicContentSlot', 'SectionB', 'SikoNewsListSlot', 'SikoNewsAdSlot'],
        },
        SikoBlogPageTemplate: {
            slots: [
                'SikoBlogCategoriesSlot',
                'SikoBlogFiltersSlot',
                'SikoBlogSocialSlot',
                'SikoBlogAdSlot',
                'SikoBlogSideContentSlot',
                'SikoBlogWideContentSlot',
            ],
        },
        SikoBlogDetailPageTemplate: {
            slots: [
                'SikoBlogCategoriesSlot',
                'SikoBlogSideSlot',
                'SikoBlogDetailComponentSlot',
                'SikoBlogDetailNarrowContentSlot',
                'SikoBlogDetailContextualPagesSlot',
                'SikoBlogDetailWideContentSlot',
            ],
        },
        header: {
            lg: {
                slots: [
                    'SiteContext',
                    'SiteLinks',
                    'SiteLogo',
                    'SearchBox',
                    'SiteLogin',
                    'MiniCart',
                    'NavigationBar',
                    'NavigationBarSide',
                ],
            },
            md: {
                slots: [
                    'SiteContext',
                    'SiteLinks',
                    'SiteLogo',
                    'SiteLogin',
                    'MiniCart',
                    'SearchBox',
                    'NavigationBarSide',
                    'NavigationBar',
                ],
            },
            sm: {
                slots: [
                    'SiteLinks',
                    'SiteLogo',
                    'SiteLogin',
                    'MiniCart',
                    'SearchBox',
                    'NavigationBarSide',
                    'NavigationBar',
                ],
            },
            xs: {
                slots: [
                    'MobileUserNavMenu',
                    'SiteLogo',
                    'NavigationBarSide',
                    'SiteLogin',
                    'SearchBox',
                    'MiniCart',
                    'NavigationBar',
                ],
            },
            slots: ['SiteLogo', 'SiteLogin', 'SearchBox', 'MiniCart', 'NavigationBarSide'],
        },
        footer: {
            slots: ['FooterA1', 'FooterA2', 'FooterB1', 'FooterB2', 'FooterC', 'FooterD'],
        },
    },
    launch: {
        SAVED_CART: {
            inline: true,
            component: SikoSavedCartFormDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};
