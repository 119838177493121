<div class='product-sell-units' *ngIf='getReducedSellUnits() as reducedSellUnits'>
  <div class='show-button' (click)='showProductSellUnits.next(!showProductSellUnits.getValue())'>
    <img [src]='"pdp/pallet.svg" | sikoIconAsset' alt='pallet icon' class='pallet-arrow'>
    {{'pdp.showProductSellUnits' | cxTranslate}}
    <img [src]='"select_arrow.svg" | sikoIconAsset'
         alt='select arrow' class='select-arrow'
         [ngClass]='showProductSellUnits.getValue() ? "showed" : "hidden"'>
  </div>
  <div class='table-units' *ngIf='showProductSellUnits.getValue()'>
    <table aria-label='Table with products package units'>
      <tr *ngFor='let sellUnit of reducedSellUnits'>
        <td class='code'>
          <span class='siko-font-primary-semiBold siko-color-grey-3 siko-fs-14'>
            {{"siko.sellUnits." + sellUnit.unit?.sapCode | cxTranslate}}
          </span>
        </td>
        <td class='unit'>
          <span class='siko-fs-14' *ngIf='sellUnit.sapBaseUnitConversion'>
            {{sellUnit.sapBaseUnitConversion | sikoConvertUnitConversion | cxNumeric}}
          </span>
          <app-product-unit
            [count]='sellUnit.sapBaseUnitConversion | sikoConvertUnitConversion'
            [productUnitData]='sikoProduct?.unitData'
            [inheritTextStyles]='true'>
          </app-product-unit>
          <ng-container *ngIf='sellUnit.unit?.sapCode === "MBL"'>
            ({{sikoProduct | sikoProductWeight | cxNumeric : '1.0-2'}} kg)
          </ng-container>
        </td>
      </tr>
    </table>
  </div>
</div>

