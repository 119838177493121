import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { PageSlotModule } from '@spartacus/storefront';
import { SikoBreakpointObserverService } from '@siko/common';
import { AsyncPipe } from '@angular/common';
import { B2bCommonModule } from '@siko/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-blog-list-template',
    templateUrl: './blog-list-outlet.component.html',
    styleUrls: ['./blog-list-outlet.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        B2bCommonModule,
        PageSlotModule,
    ],
})

export class SikoBlogListOutletComponent {

    constructor(
        public breakpointObserver: SikoBreakpointObserverService
    ) {}
}
