import { Injectable } from '@angular/core';
import { BaseSites, SikoPhonePrefix } from '@siko/models';


@Injectable({
    providedIn: 'root',
})
export class SikoPhonePrefixService {

    SIKO_PHONE_PREFIXES: Record<BaseSites, SikoPhonePrefix> = {
        [BaseSites.B2B_SPA_SK]: {
            code: '+421',
            name: '+421',
        },
        [BaseSites.B2B_SPA_CZ]: {
            code: '+420',
            name: '+420',
        },
    };

    get phonePrefixes(): SikoPhonePrefix[] {
        return Object.values(this.SIKO_PHONE_PREFIXES);
    }

    getSikoPhonePrefix(key: BaseSites | undefined): SikoPhonePrefix | undefined {
        if (!key) {return undefined;}
        return this.SIKO_PHONE_PREFIXES[key];
    }
}
