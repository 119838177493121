import { NgModule } from '@angular/core';
import {
    AnonymousConsentsModule,
    AuthModule,
    CostCenterOccModule,
    ExternalRoutesModule,
    ProductModule,
    ProductOccModule, UserModule,
    UserOccModule,
} from '@spartacus/core';
import {
    AnonymousConsentManagementBannerModule,
    AnonymousConsentsDialogModule,
    BannerCarouselModule,
    BannerModule,
    BreadcrumbModule,
    CategoryNavigationModule,
    CmsParagraphModule,
    ConsentManagementModule,
    FooterNavigationModule,
    HamburgerMenuModule,
    HomePageEventModule, JsonLdBuilderModule,
    LinkModule,
    LoginRouteModule,
    LogoutModule,
    MyCouponsModule,
    MyInterestsModule,
    NavigationEventModule,
    NavigationModule,
    NotificationPreferenceModule, PageTitleModule,
    PaymentMethodsModule, PDFModule,
    ProductCarouselModule,
    ProductDetailsPageModule,
    ProductFacetNavigationModule,
    ProductImagesModule,
    ProductIntroModule,
    ProductListingPageModule,
    ProductListModule,
    ProductPageEventModule,
    ProductReferencesModule,
    ProductSummaryModule,
    ProductTabsModule, ScrollToTopModule,
    SearchBoxModule,
    SiteContextSelectorModule,
    StockNotificationModule,
    TabParagraphContainerModule, VideoModule,
} from '@spartacus/storefront';
import { UserFeatureModule } from './features/user/user-feature.module';
import { StoreFinderFeatureModule } from './features/storefinder/store-finder-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { ProductVariantsFeatureModule } from './features/product/product-variants-feature.module';
import { ProductImageZoomFeatureModule } from './features/product/product-image-zoom-feature.module';
import { OrderFeatureModule } from './features/order/order-feature.module';
import { CartSavedCartFeatureModule } from './features/cart/cart-saved-cart-feature.module';
import { CartQuickOrderFeatureModule } from './features/cart/cart-quick-order-feature.module';
import { CartImportExportFeatureModule } from './features/cart/cart-import-export-feature.module';
import { OrganizationAdministrationFeatureModule } from './features/organization/organization-administration-feature.module';
import { OrganizationOrderApprovalFeatureModule } from './features/organization/organization-order-approval-feature.module';
import { CheckoutScheduledReplenishmentRootModule } from '@spartacus/checkout/scheduled-replenishment/root';
import { CartBaseFeatureModule } from '@siko-spartacus/features/cart/cart-base-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { CheckoutRootModule } from '@spartacus/checkout/base/root';
import { CheckoutB2BCoreModule } from '@spartacus/checkout/b2b/core';
import { CheckoutB2BOccModule } from '@spartacus/checkout/b2b/occ';
import { CheckoutB2BRootModule } from '@spartacus/checkout/b2b/root';
import { AsmFeatureModule } from '@siko-spartacus/features/asm/asm-feature.module';
import { AddressBookModule } from '@spartacus/user/profile/components';

@NgModule({
    declarations: [],
    imports: [
        // Auth Core
        AuthModule.forRoot(),
        LogoutModule, // will be come part of auth package
        LoginRouteModule, // will be come part of auth package

        // Basic Cms Components
        HamburgerMenuModule,
        SiteContextSelectorModule,
        LinkModule,
        BannerModule,
        CmsParagraphModule,
        TabParagraphContainerModule,
        BannerCarouselModule,
        CategoryNavigationModule,
        NavigationModule,
        FooterNavigationModule,
        PageTitleModule,
        BreadcrumbModule,
        PDFModule,
        ScrollToTopModule,
        VideoModule,

        // User Core
        UserModule,
        UserOccModule,
        // User UI
        AddressBookModule,
        PaymentMethodsModule,
        NotificationPreferenceModule,
        MyInterestsModule,
        StockNotificationModule,
        ConsentManagementModule,
        MyCouponsModule,

        // Anonymous Consents Core
        AnonymousConsentsModule.forRoot(),
        // Anonymous Consents UI
        AnonymousConsentsDialogModule,
        AnonymousConsentManagementBannerModule,

        // Product Core
        ProductModule.forRoot(),
        ProductOccModule,

        // Product UI
        ProductDetailsPageModule,
        ProductListingPageModule,
        ProductListModule,
        SearchBoxModule,
        ProductFacetNavigationModule,
        ProductTabsModule,
        ProductCarouselModule,
        ProductReferencesModule,
        ProductImagesModule,
        ProductSummaryModule,
        ProductIntroModule,

        // Cost Center
        CostCenterOccModule,

        // Cart Core,
        CartBaseFeatureModule,
        CartSavedCartFeatureModule,
        CartQuickOrderFeatureModule,
        CartImportExportFeatureModule,

        // Checkout,
        CheckoutFeatureModule,
        CheckoutRootModule,
        CheckoutB2BCoreModule,
        CheckoutB2BOccModule,
        CheckoutB2BRootModule,
        CheckoutScheduledReplenishmentRootModule,

        // Page Events
        NavigationEventModule,
        HomePageEventModule,
        ProductPageEventModule,

        //Organization
        OrganizationAdministrationFeatureModule,
        OrganizationOrderApprovalFeatureModule,

        //ASM
        AsmFeatureModule,

        /************************* Opt-in features *************************/

        ExternalRoutesModule.forRoot(), // to opt-in explicitly, is added by default schematics
        JsonLdBuilderModule,

        /************************* Feature libraries *************************/
        UserFeatureModule,
        OrderFeatureModule,
        SmartEditFeatureModule,
        StoreFinderFeatureModule,

        ProductVariantsFeatureModule,
        ProductImageZoomFeatureModule

    ],
})
export class SpartacusFeaturesModule {}
