import { BaseSites } from '@siko/models';
import { GeoPoint } from '@spartacus/core/src/model/misc.model';

export const COUNTRIES_CENTERS: { [countryCode: string]: GeoPoint } = {
    [BaseSites.B2B_SPA_CZ]: {
        latitude: 49.8175,
        longitude: 15.4730,
    },
    [BaseSites.B2B_SPA_SK]: {
        latitude: 48.6690,
        longitude: 19.6990,
    },
};