<ng-container *ngIf="isSwiperZoomContainer; else noWrapper">
  <div class='swiper-zoom-container'>
    <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #noWrapper>
  <ng-container *ngTemplateOutlet="imageTemplate"></ng-container>
</ng-template>

<ng-template #imageTemplate>
  <img
    #imgRef
    *ngIf='media && media.src'
    [attr.src]="media.src"
    [defaultImage]='getThumb(media.srcset)'
    [attr.srcset]='media.srcset'
    [attr.alt]='media.alt'
    [attr.role]='media.role'
    [attr.loading]='loading'
    [lazyLoad]='media.src'
    (onStateChange)='stateCallback($event)'
    (load)="loadHandler()"
  />
</ng-template>
