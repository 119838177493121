import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ViewModes } from '@spartacus/storefront';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Router } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})

export class SikoB2bPlpService {
    private readonly isSideMenuOpenSubject = new BehaviorSubject(false);
    private readonly viewModes: BehaviorSubject<string> = new BehaviorSubject<string>(ViewModes.Grid);
    private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
    private readonly displayPlpSkeletonSubject = new BehaviorSubject<boolean>(false);

    protected codec: HttpUrlEncodingCodec = new HttpUrlEncodingCodec();

    constructor(
        private readonly router: Router,
        private readonly routingService: RoutingService,
    ) {
    }

    get viewMode$(): Observable<string> {
        return this.viewModes.asObservable();
    }

    setViewMode(viewMode: string): void {
        sessionStorage.setItem('sikoViewMode', viewMode);
        this.viewModes.next(viewMode);
    }

    get displayPlpSkeleton$(): Observable<boolean> {
        return this.displayPlpSkeletonSubject.asObservable().pipe(filter(value => value !== undefined));
    }

    setDisplayPlpSkeleton(value: boolean): void {
        this.displayPlpSkeletonSubject.next(value);
    }

    get isLoadingSubject$(): Observable<boolean> {
        return this.isLoadingSubject.asObservable();
    }

    setLoadingSubject(value: boolean): void {
        this.isLoadingSubject.next(value);
    }

    get isSideMenuOpen$(): Observable<boolean> {
        return this.isSideMenuOpenSubject.asObservable();
    }

    cancelAllFilters(virtualCategory?: string): void {
        if (virtualCategory) {
            this.routingService.goByUrl('/c/' + virtualCategory);
        }
        else {
            this.router.navigate([], {
                queryParams: null,
            });
        }

    }

    setIsSideMenuOpen(event: boolean) {
        this.isSideMenuOpenSubject.next(event);
    }

}
