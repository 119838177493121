import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService, OccEndpointsService, PRODUCT_NORMALIZER } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SikoProduct } from '@siko/models';

@Injectable({
    providedIn: 'root',
})
export class SikoOccProductAdapter {
    private readonly backendBaseUrl: string;

    constructor(
        protected converter: ConverterService,
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
    ) {
        this.backendBaseUrl = this.occEndpoints.getBaseUrl();
    }

    updateProductPrice(productCode: unknown): Observable<SikoProduct> {
        return this.http.get<SikoProduct>(`${this.backendBaseUrl}/orgProducts/${productCode}/update-price?fields=FULL`)
            .pipe(this.converter.pipeable(PRODUCT_NORMALIZER));
    }
}
