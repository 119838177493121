import { Injectable } from '@angular/core';
import {
    BasePageMetaResolver,
    CategoryPageMetaResolver,
    CmsService,
    PageType,
    ProductSearchService,
    TranslationService
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { SikoProductSearchPage } from '../../models/siko.product.search.mode';



@Injectable({
    providedIn: 'root'
})
export class SikoCategoryPageMetaResolver extends CategoryPageMetaResolver {

    constructor(
        protected productSearchService: ProductSearchService,
        protected cms: CmsService,
        protected translation: TranslationService,
        protected basePageMetaResolver: BasePageMetaResolver,
    ) {
        super(productSearchService, cms, translation, basePageMetaResolver);
        this.pageType = PageType.CATEGORY_PAGE;
        this.pageTemplate = 'ProductGridPageTemplate';
    }

    resolveTitle(): Observable<string> {
        return (<Observable<SikoProductSearchPage>> this.searchPage$).pipe(
            filter((page: SikoProductSearchPage) => !!page.pagination),
            switchMap((p: SikoProductSearchPage) =>
                this.translation.translate('productListingPage.plpPageTitle', {
                    title: p.category?.name
                }))
        );
    }

}
