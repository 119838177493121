import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-move-icon',
    templateUrl: './move-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoMoveIconComponent {
}
