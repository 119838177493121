export const sikoTranslationChunksConfig = {
    header: ['creditLimitBanner',
        'search','siteContext', 'miniCart'],
    news: ['news'],
    pdp: ['tabs', 'reviewTab'],
    addedToCartDialog: [],
    cartShared: ['coupon', 'addToSavedCart'],
    quote: ['table', 'mobileSortSelect','quoteDialog'],
    sikoCartDetails: ['promotions', 'promoType', 'notAvailableList','deliveryChangeButton','deliveryChangeDialog'],
    sikoCheckout: ['paymentTypes', 'pickupdate','invalid', 'checkoutReview','documents','checkoutProgress', 'paymentTypesShared', 'shippingAddress', 'placeOrder'],
    sikoOrder: ['orderSummary', 'orderStatusTrans', 'sapOrderStatus', 'sapOrderAdditionalPayment','sapOrderDetail','sapOrderDocuments','orderDetail'],
    orderHistory: ['periods','table','mobileSortSelect','account'],
    savedCart: ['table','detail','mobileSortSelect','savedCartDialog'],
    sikoMyCoupons: [],
    sikoQuickOrder: ['quickOrderModal','quickOrderCartForm','quickOrderForm','quickOrderList','quickOrderTable'],
    sikoMyAccount: ['updatePasswordForm','contact'],
    sikoOrganization: [],
    sikoTicket:['ticketHistory', 'ticketAction'],
    sikoRegistration: ['verifiedRegistration','registrationSelectStoreDialog','validations'],
    productShared: []
};
