import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { StateWithFilterHistory } from '@siko/features/my-account/order-history/store/order-history-state';
import { ClearFilters } from '@siko/features/my-account/order-history/store/actions/order-history.action';

@Injectable({
    providedIn: 'root',
})
export class ClearOrderStateGuard implements CanDeactivate<any> {

    constructor(
        readonly filterHistoryStore: Store<StateWithFilterHistory>,
        readonly router: Router,
    ) {}

    canDeactivate(
        _component: any,
        _currentRoute: ActivatedRouteSnapshot,
        _currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot,
    ): boolean {
        // eslint-disable-next-line max-len
        if (nextState && !nextState.url.includes('/my-account/order') && !nextState.url.includes('/my-account/sap-order')) {
            this.filterHistoryStore.dispatch(new ClearFilters());
        }

        return true;
    }

}
