import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    CustomerCouponSearchResult,
    CustomerCouponService,
    GlobalMessageService, GlobalMessageType,
    Occ,
    PaginationModel,
    UserActions,
} from '@spartacus/core';
import { map, tap } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { SikoAutoUnsubscribe } from '@siko/common';
import Pagination = Occ.Pagination;

@SikoAutoUnsubscribe([
    'subscriptions',
    'claimCouponSubscription',
])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-my-coupons',
    templateUrl: './my-coupons.component.html',
    styleUrls: ['./my-coupons.component.scss'],
})
export class SikoMyCouponsComponent implements OnInit {

    couponResult$: Observable<CustomerCouponSearchResult> | undefined;
    couponsLoading$: Observable<boolean> | undefined;
    couponSubscriptionLoading$: Observable<boolean> | undefined;
    pagination: PaginationModel | undefined;
    claimCouponSubscription?: Subscription;

    private readonly subscriptions = new Subscription();

    private readonly PAGE_SIZE = 10;

    constructor(
        readonly messageService: GlobalMessageService,
        readonly store: Store,
        protected couponService: CustomerCouponService,
    ) {}

    ngOnInit(): void {

        this.claimCouponSubscription = this.couponService
            .getClaimCustomerCouponResultSuccess()
            .subscribe((success: boolean) => {
                if (success) {
                    this.messageService.add(
                        { key: 'sikoMyCoupons.claimCustomerCoupon' },
                        GlobalMessageType.MSG_TYPE_CONFIRMATION,
                    );
                }
            });

        this.store.dispatch(new UserActions.ResetLoadCustomerCoupons());

        this.couponResult$ = this.couponService
            .getCustomerCoupons(this.PAGE_SIZE)
            .pipe(
                tap(
                    (coupons: CustomerCouponSearchResult) =>
                        this.pagination = {
                            currentPage: coupons.pagination?.page,
                            pageSize: coupons.pagination?.count,
                            totalPages: coupons.pagination?.totalPages,
                            totalResults: coupons.pagination?.totalCount,
                        },
                ),
            );
        this.couponsLoading$ = this.couponService.getCustomerCouponsLoading();
        this.couponSubscriptionLoading$ = combineLatest([
            this.couponService.getSubscribeCustomerCouponResultLoading(),
            this.couponService.getUnsubscribeCustomerCouponResultLoading(),
        ])
            .pipe(
                map(([subscribing, unsubscribing]) => subscribing || unsubscribing),
            );

        this.subscriptions
            .add(
                this.couponService
                    .getSubscribeCustomerCouponResultError()
                    .subscribe((error: boolean) => {
                        this.subscriptionFail(error);
                    }),
            );

        this.subscriptions.add(
            this.couponService
                .getUnsubscribeCustomerCouponResultError()
                .subscribe((error: boolean) => {
                    this.subscriptionFail(error);
                }),
        );
    }

    private subscriptionFail(error: boolean) {
        if (error) {
            this.couponService.loadCustomerCoupons(this.PAGE_SIZE);
        }
    }

    pageChange(page: number): void {
        this.couponService.loadCustomerCoupons(
            this.PAGE_SIZE,
            page,
        );
    }

    getPaginationValue(paginationValue: number | undefined): number {
        if (paginationValue === undefined) {
            return 0;
        }

        return paginationValue;
    }

    // eslint-disable-next-line complexity
    getShownRecordsRange(pagination: Pagination): string {
        if (pagination.page === undefined || pagination.count === undefined
            || pagination.totalPages === undefined || pagination.totalCount === undefined) {
            return '';
        }

        const startIndex: number = pagination.page * pagination.count + 1;
        const lastPage: boolean = pagination.page === pagination.totalPages - 1;
        const endIndex: number = lastPage ? pagination.totalCount : startIndex + pagination.count - 1;

        return `${startIndex}-${endIndex}`;
    }

    notificationChange({ couponId, notification }: {
        couponId: string;
        notification: boolean;
    }): void {
        if (notification) {
            this.couponService.subscribeCustomerCoupon(couponId);
        }
        else {
            this.couponService.unsubscribeCustomerCoupon(couponId);
        }
    }

}
