import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CmsAdBannerContextComponent } from '@siko/models';
import { Media } from '@spartacus/storefront';
import { RoutingService } from '@spartacus/core';
import { SikoCmsService } from '@siko/common';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-ad-banner',
    templateUrl: './ad-banner.component.html',
    styleUrls: ['./ad-banner.component.scss']
})
export class Sikob2bAdBannerComponent implements OnInit {

    @Input() adBanner?: CmsAdBannerContextComponent;
    @Input() isGrid?: boolean;
    backgroundColor = '#F0F4F4';

    constructor(
        readonly sikoCmsService: SikoCmsService,
        private readonly routingService: RoutingService,
    ) {}

    ngOnInit(): void {
        this.resolveBackgroundColor();
    }

    handleRedirect(external: boolean | string | undefined, urlLink: string | undefined): void {
        if (Boolean(external) && urlLink?.includes('http')) {
            window.open(urlLink, '_blank');
        }
        else if (urlLink){
            void this.routingService.goByUrl(urlLink);
        }
    }

    resolveBackgroundColor(): void {
        if (this.adBanner?.backgroundColor) {
            this.backgroundColor = this.adBanner.backgroundColor;
        }
    }

    getMediaForElement(element: any | null, size: string): Media | undefined {
        return this.sikoCmsService.getMediaForElement(element, size);
    }

}
