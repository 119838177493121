import { Component, ChangeDetectionStrategy, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { ProductFacetNavigationComponent, ProductListComponentService, BreakpointService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { RoutingService } from '@spartacus/core';
import { SikoProductSearchPage } from '@siko/models';
import { tap } from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-facet-navigation',
    templateUrl: './product-facet-navigation.component.html',
    styleUrls: ['./product-facet-navigation.component.scss'],
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class SikoProductFacetNavigationComponent extends ProductFacetNavigationComponent implements OnInit {

    @Input() productCount? = 0;
    model$: Observable<SikoProductSearchPage> = this.productListComponentService.model$.pipe(
        tap((productSearchPage: SikoProductSearchPage) => {
            this.productCount = productSearchPage.pagination?.totalResults;
        })
    );

    constructor(
        private readonly routingService: RoutingService,
        private readonly productListComponentService: ProductListComponentService,
        breakpointService: BreakpointService,
    ) {
        super(breakpointService);
    }

    ngOnInit(): void {
        this.open$.next(true);
    }

    routeToCategory(categoryUrl: string | undefined): void {
        if (categoryUrl) {
            void this.routingService.goByUrl(categoryUrl);
        }
    }

    scrollToFilters(el: HTMLDivElement): void {
        el.scrollIntoView();
    }

}
