/* eslint-disable */

import { Injectable } from '@angular/core';
import { NavigationService } from '@spartacus/storefront';
import { CmsNavigationComponent, NodeItem } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SikoNavigationNode } from '../../models';


@Injectable({
    providedIn: 'root',
})
export class SikoNavigationService extends NavigationService {

    public getNavigationNode(
        data$: Observable<CmsNavigationComponent>,
    ): Observable<SikoNavigationNode> {
        // @ts-ignore
        return data$.pipe(
            filter((data: CmsNavigationComponent) => !!data),
            switchMap((data: CmsNavigationComponent) => {
                const navigation = data.navigationNode ? data.navigationNode : data;
                // @ts-ignore
                return this.cmsService.getNavigationEntryItems(navigation.uid)
                    .pipe(
                        tap((items: NodeItem) => {
                            if (items === undefined) {
                                this.customLoadNavigationEntryItems(navigation, true);
                            }
                            else {
                                // we should check whether the existing node items are what expected
                                const expectedItems: never[] = [];
                                this.customLoadNavigationEntryItems(navigation, false, expectedItems);
                                const existingItems = Object.keys(items)
                                    .map(
                                        (key: string) => items[key].uid,
                                    );
                                // @ts-ignore
                                const missingItems = expectedItems.filter((it) => !existingItems.includes(it.id));
                                if (missingItems.length > 0) {
                                    // @ts-ignore
                                    this.cmsService.loadNavigationItems(navigation.uid, missingItems);
                                }
                            }
                        }),
                        filter(Boolean),
                        map(items => this.customPopulateNavigationNode(navigation, items)),
                    );
            }),
        );
    }

    /**
     * Loads all navigation entry items' type and id. Dispatch action to load all these items
     * @param nodeData
     * @param root
     * @param itemsList
     */
    private customLoadNavigationEntryItems(
        nodeData: any,
        root: boolean,
        itemsList = [],
    ): void {
        if (nodeData.entries && nodeData.entries.length > 0) {
            // @ts-ignore
            nodeData.entries.forEach(entry => {
                // @ts-ignore
                itemsList.push({ superType: entry.itemSuperType, id: entry.itemId });
            });
        }

        if (nodeData.children && nodeData.children.length > 0) {
            // @ts-ignore
            nodeData.children.forEach(child => {
                    this.customLoadNavigationEntryItems(child, false, itemsList);
                },
            );
        }

        if (root) {
            this.cmsService.loadNavigationItems(nodeData.uid, itemsList);
        }
    }

    /**
     * Create a new node tree for the view
     * @param nodeData
     * @param items
     */
    private customPopulateNavigationNode(nodeData: any, items: any): SikoNavigationNode {
        const node: SikoNavigationNode = {};

        if (nodeData.title) {
            node.title = nodeData.title;
        }

        if (nodeData.styleClasses) {
            node.styleClasses = nodeData.styleClasses;
        }

        if (nodeData.styleAttributes) {
            node.styleAttributes = nodeData.styleAttributes;
        }

        if (nodeData.entries && nodeData.entries.length > 0) {
            this.populateEntry(node, nodeData.entries[0], items);
        }

        if (nodeData.children?.length > 0) {
            const children = nodeData.children
                .map((child: any) => this.customPopulateNavigationNode(child, items))
                .filter(Boolean);
            if (children.length > 0) {
                node.children = children;
            }
        }

        return node;
    }

    /**
     * Populate data from node entries to internal models
     *
     * @param node SikoNavigationNode
     * @param entry CMSComponentData
     * @param items Loaded CMS Items data from OCC
     * @private
     */
    private populateEntry(node: SikoNavigationNode, entry: any, items: any) {
        const item = items[`${entry.itemId}_${entry.itemSuperType}`];

        node.title = item?.title;
        node.uid = item.uid;
        node.itemType = item.typeCode;

        // CMSLinkComponent
        if (item && entry.itemType === 'CMSLinkComponent') {
            if (!node.title) {
                node.title = item.linkName;
            }
            const url = this.getLink(item);
            if (node.title && url) {
                node.url = url;
                if (item.target === 'true' || item.target === true) {
                    node.target = '_blank';
                }
            }
        }

        // SimpleBannerCMSComponent & MenuBannerCMSComponent
        if (item && (entry.itemType === 'SimpleBannerComponent' || entry.itemType === 'MenuBannerCMSComponent')) {
            node.media = item.media;
            const url = this.getLink(item);
            if (node.title && url) {
                node.url = url;
                if (item.target === 'true' || item.target === true) {
                    node.target = '_blank';
                }
            }
        }

        // PromoBannerCMSComponent
        if (item && entry.itemType === 'PromoBannerCMSComponent') {
            node.media = item.media;
            node.titleSecondary = item.titleSecondary;
            node.text = item.altText;
            const url = this.getLink(item);
            if (node.title && url) {
                node.url = url;
                if (item.target === 'true' || item.target === true) {
                    node.target = '_blank';
                }
            }
        }

        // SikoImageGridCMSComponent
        if (item && entry.itemType === 'SikoCMSImageGridComponent') {
            const url = this.getLink(item);
            if (node.title && url) {
                node.url = url;
                if (item.target === 'true' || item.target === true) {
                    node.target = '_blank';
                }
            }
        }

        // populate style classes to apply CMS driven styling
        if (item.styleClasses) {
            node.styleClasses = item.styleClasses;
        }
        // populate style attributes to apply CMS driven styling
        if (item.styleAttributes) {
            node.styleAttributes = item.styleAttributes;
        }
    }

}
