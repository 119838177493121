import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { CmsComponentData, SiteContextComponentService, SiteContextSelectorComponent } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { CmsSiteContextSelectorComponent } from '@spartacus/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SikoAutoUnsubscribe } from '@siko/common';

@SikoAutoUnsubscribe(['componentSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-site-context-selector',
    templateUrl: './site-context-selector.component.html',
    styleUrls: ['./site-context-selector.component.scss'],
})
export class SikoSiteContextSelectorComponent extends SiteContextSelectorComponent implements OnInit {

    componentSubscription: Subscription = new Subscription();
    data$: Observable<CmsSiteContextSelectorComponent> = this.componentData.data$;
    isInFooter = false;

    changeLangForm: UntypedFormGroup = this.fb.group({
        changeLangSelect: [],
    });

    sikoLangOptions: any[] = [];

    constructor(
        private readonly fb: UntypedFormBuilder,
        protected componentData: CmsComponentData<CmsSiteContextSelectorComponent>,
        componentService: SiteContextComponentService,
    ) {
        super(componentService);
    }

    ngOnInit(): void {
        this.componentSubscription = this.data$.pipe()
            .subscribe(componentData => {
                if (componentData.uid === 'FooterLanguageComponent') {
                    this.isInFooter = true;
                }
            });

        this.items$.subscribe(langs => {
            for (let item of langs) {
                this.sikoLangOptions.push({
                    isocode: item.isocode,
                    label: item.label,
                });
            }
        });

        this.activeItem$.subscribe((item: any) => {
            this.changeLangForm.controls.changeLangSelect.setValue(item);
        });
    }

    setLang(event: any): void {
        this.active = event.isocode;
    }

}
