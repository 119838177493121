import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-vector-icon',
    templateUrl: './vector-icon.component.html',
    styleUrls: ['./vector-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
    ],
})
export class SikoVectorIconComponent {

    @Input() iconMode?: 'delivery-type' | 'store-item-active' | 'store-item' | 'store-not-selected' | 'store-selected';

    get size(): number {
        return this.iconMode === 'delivery-type' ? 20 : 24;
    }

}
