import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    PaginationModel,
    ProductActions,
    ProductSearchConnector,
    ProductSearchPage,
    ProductSelectors,
    StateWithProduct,
} from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'app-infinite-scroll',
    templateUrl: './infinite-scroll.component.html',
    styleUrls: ['./infinite-scroll.component.scss'],
})
export class SikoInfiniteScrollComponent implements OnInit {

    @Input() pagination?: PaginationModel;

    protected quantitySeen = new BehaviorSubject<number | undefined>(0);
    protected total = new BehaviorSubject<number | undefined>(0);
    protected loadingNewProducts = new BehaviorSubject<boolean>(false);

    productSearchResult$: Observable<ProductSearchPage> = this.store.pipe(
        select(ProductSelectors.getSearchResults),
    );

    get quantitySeen$(): Observable<number | undefined> {
        return this.quantitySeen.asObservable();
    }

    get total$(): Observable<number | undefined> {
        return this.total.asObservable();
    }

    get loadingNewProducts$(): Observable<boolean> {
        return this.loadingNewProducts.asObservable();
    }

    constructor(
        private readonly store: Store<StateWithProduct>,
        private readonly productSearchConnector: ProductSearchConnector,
    ) { }

    ngOnInit(): void {
        this.productSearchResult$.subscribe((productSearchPage: ProductSearchPage) => {
            this.quantitySeen.next(productSearchPage.products?.length);
            this.total.next(productSearchPage.pagination?.totalResults);
        });
    }

    displayInfiniteScroll(): boolean {
        if (!this.pagination?.totalPages || !this.pagination?.currentPage) return true;
        return this.pagination.totalPages !== this.pagination.currentPage + 1;
    }

    loadMore(): void {
        this.loadingNewProducts.next(true);
        this.productSearchResult$.subscribe((productSearchPage: ProductSearchPage) => {
            this.quantitySeen.next(productSearchPage.products?.length);
            this.total.next(productSearchPage.pagination?.totalResults);

            const productSearchPageExtensible: ProductSearchPage = JSON.parse(JSON.stringify(productSearchPage));
            const queryText = productSearchPage.currentQuery?.query?.value;
            let searchConfig;

            if (productSearchPage.pagination?.hasOwnProperty('currentPage') && productSearchPage.pagination.pageSize) {
                const { currentPage } = productSearchPage.pagination;

                searchConfig = {
                    currentPage: currentPage! + 1,
                    pageSize: productSearchPage.pagination.pageSize,
                };
            }

            if (queryText && searchConfig) {
                this.productSearchConnector.search(queryText, searchConfig)
                    .subscribe((data: ProductSearchPage) => {
                        if (data.products) {
                            productSearchPageExtensible.products?.push(...data.products);
                            productSearchPageExtensible.pagination = data.pagination;
                        }

                        this.store.dispatch(new ProductActions.SearchProductsSuccess(productSearchPageExtensible));
                        this.loadingNewProducts.next(false);
                    });
            }
        })
            .unsubscribe();
    }

}
