import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss'],
})
export class SikoDialogHeaderComponent {

    @Input() hasBorder = true;
    @Input() loading = false;

    @Output() readonly clickEvent = new EventEmitter();

    onCloseButton(): void {
        this.clickEvent.emit();
    }

}
