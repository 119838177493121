import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ProductListItemComponent, ProductListItemContextSource } from '@spartacus/storefront';
import { SikoProduct } from '@siko/models';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-header-search-list-item',
    templateUrl: './header-search-list-item.component.html',
    styleUrls: ['./header-search-list-item.component.scss'],
    providers: [ProductListItemContextSource],
})
export class SikoHeaderSearchListItemComponent extends ProductListItemComponent implements OnInit {

    @Input() isStoreDisponibility = false;
    @Input() order = '';
    @Input() shadow = '';
    @Input() searchBoxResult = false;
    @Input() product: SikoProduct = {};

    get hasDiscountPrice(): boolean {
        return this.product.discountPrice !== undefined;
    }

    getPriceBlockClasses(staticClass: string): string[] {
        return [
            staticClass,
            this.hasDiscountPrice ? 'discount' : '',
        ];
    }

    ngOnInit(): void {
        this.order = String(Number(this.order) + 1);
    }

}
