import { Component } from '@angular/core';
import { SikoBreakpointObserverService } from '@siko/common';

@Component({
    selector: 'app-added-to-cart-skeleton',
    templateUrl: './added-to-cart-skeleton.component.html',
    styleUrls: ['./added-to-cart-skeleton.component.scss'],
})
export class SikoAddedToCartSkeletonComponent {

    constructor(
        public breakpointObserver: SikoBreakpointObserverService,
    ) { }

}
