import { Injectable } from '@angular/core';
import { SikoImportCartService } from '@siko/features/cart/services/siko-import-cart.service';
import { Router } from '@angular/router';
import { CmsService, Page, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SikoHeaderService {

    checkoutPageTemplate = 'MultiStepCheckoutSummaryPageTemplate';
    isCheckoutPage$: Observable<boolean> = this.isCheckoutPageTemplate();

    constructor(
        private readonly sikoImportCartService: SikoImportCartService,
        private readonly sikoRouter: Router,
        private readonly routingService: RoutingService,
        protected cmsService: CmsService,
    ) {}

    importCart(): void {
        const savedCartsPageSegment = /\/saved-carts\/?$/;
        const cartPageSegment = /(?<!saved-)\/cart\/?$/;

        if (savedCartsPageSegment.test(this.sikoRouter.url) || cartPageSegment.test(this.sikoRouter.url)) {
            this.sikoImportCartService.sikoImportCartDialog();

            return;
        }

        void this.routingService.goByUrl('/cart?importCart=true');
    }

    isCheckoutPageTemplate(): Observable<boolean> {
        return this.cmsService.getCurrentPage()
            .pipe(
                map((page: Page): boolean => !!(page && page.template && page.template === this.checkoutPageTemplate)),
            );
    }

}
