import { NgModule } from '@angular/core';
import { SESSION_KEY_PUBLIC_ROUTES } from '@siko/constants';
import { SikoUtils } from '@siko/shared';
import { translationChunksConfig, translations } from '@spartacus/assets';
import { defaultB2BCheckoutConfig } from '@spartacus/checkout/b2b/root';
import { FeaturesConfig, I18nConfig, provideConfig, provideConfigFactory } from '@spartacus/core';
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from '@spartacus/storefront';
import { defaultB2bOccConfig } from '@spartacus/setup';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        provideConfig(layoutConfig),
        provideConfig(mediaConfig),
        provideConfig(defaultB2bOccConfig),
        provideConfig(defaultB2BCheckoutConfig),
        ...defaultCmsContentProviders,
        provideConfigFactory(() => {
            return SikoUtils.getSiteContext();
        }),
        provideConfigFactory(
            () => {
                let config = {};

                const publicUrlsData = sessionStorage.getItem(SESSION_KEY_PUBLIC_ROUTES);
                if (publicUrlsData) {
                    const publicUrls: string[] = JSON.parse(publicUrlsData);

                    config = {
                        routing: {
                            routes: {
                                cms: {
                                    paths: publicUrls,
                                    protected: false,
                                },
                            },
                        },
                    };
                }

                return config;
            },
        ),
        provideConfig({
            i18n: {
                resources: translations,
                chunks: translationChunksConfig,
                fallbackLang: 'en',
            },
        } as I18nConfig),
        provideConfig({
            features: {
                level: '5.2',
            },
        } as FeaturesConfig),
    ],
})
export class SpartacusConfigurationModule {}
