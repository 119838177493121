<ng-container *ngIf='orderEntry'>
  <div class='my-16 my-sm-32'>
    <div class='px-8 pt-8 d-flex justify-content-between'>
      <a
        [title]='orderEntry.product?.name'
        class='product-item' [routerLink]='{ cxRoute: "product", params: orderEntry.product } | cxUrl'>
        <app-media
          [container]='$any(orderEntry.product?.images)'
          format='cartIcon'
          class='d-flex justify-content-center align-items-center'
        ></app-media>
        <div class='product-check'>
          <div class='check-icon'></div>
        </div>
      </a>
      <div class='w-100 ml-8 d-flex flex-column flex-sm-row justify-content-sm-between'>
        <div>
          <h4 class='product-title mb-8'>{{orderEntry.product?.name}}</h4>
          <div class='d-flex flex-row flex-sm-column justify-content-between'>
            <div class='siko-font-primary-semiBold siko-fs-13 siko-color-grey-3 mb-8'>{{orderEntry.product?.code}}</div>
            <app-product-group [sikoProduct]='orderEntry.product'></app-product-group>
          </div>
        </div>
        <div class='d-flex flex-row flex-sm-column justify-content-between justify-content-sm-start'>
          <ng-container *ngIf='orderEntry.product?.unitData as unitData'>
            <div
              class='product-info text-right'>{{ calculatedAddedQuantity | cxNumeric : '1.0-3' }}&nbsp;<app-product-unit
              [productUnitData]='unitData'
              [inheritTextStyles]='true'
              [count]='orderEntry.quantity'
            ></app-product-unit>
            </div>
          </ng-container>
          <div class='d-flex'>
            <span class='product-price'>{{orderEntry.product?.price | sikoPrice}}</span>
            <div class='unit-symbol d-flex'>
              <div>{{ " /" }}</div>
              <app-product-unit [productUnitData]='orderEntry.product?.unitData'
                                [inheritTextStyles]='true'></app-product-unit>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf='isDeliveryModeSelected'>
      <div class='pl-2 pt-3'>
              <span class='siko-fs-13'>
                <ng-container *ngIf='selectedPOS !== undefined && !isShipment'>
                  {{ "addedToCartDialog.pickUpAtStore" | cxTranslate: { store: selectedPOS.displayName } }}
                </ng-container>
                <ng-container *ngIf='isDelivery'>
                  {{ "siko.deliveryToAddress" | cxTranslate }}
                </ng-container>
                <ng-container *ngIf='isShipment'>
                  <ng-container *ngIf='sikoUser$ | async as user'>
                    {{ "sikoProductShared.availabilityInfo.shipment" | cxTranslate }}: {{ user.orgUnit?.sikoPredefinedPointOfService?.displayName }}
                  </ng-container>
                </ng-container>
              </span>
      </div>
    </ng-container>
  </div>
</ng-container>
