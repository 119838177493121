<div class='mb-40'>
  <app-title-with-count
    *ngIf='displayHeader'
    [text]="'pdp.tabs.technicalParameters' | cxTranslate"
  ></app-title-with-count>
</div>
<ng-container *ngIf='sikoProduct$ | async as product'>
  <ng-container *ngIf='product.classifications else noParameters'>
    <table
      aria-label='Technical parameters'
      class='pdp-table'
      *ngFor='let classification of product?.classifications; let lastClassification = last'>
      <tr aria-colspan='2'>
        <td class='clasification-title'>
          <h4>{{ classification.name }}</h4></td>
      </tr>
      <tr class='body' *ngFor='let feature of classification.features'>
        <td class='feature-title'>{{ feature.name }}</td>
        <td>
          <ul>
            <li *ngFor='let featureValue of feature?.featureValues'>
              <ng-container *ngIf='feature.code === packageCode else other'>
                <span class='feature-text'>
                  <ng-container
                    *ngIf='removeUnitSymbolFromValue(featureValue?.value) as valueWithoutUnit'>
                    {{ valueWithoutUnit }}

                    <app-product-unit
                      [productUnitData]='product.unitData'
                      [inheritTextStyles]='true'
                      [count]='parseValueToInt(valueWithoutUnit)'
                    ></app-product-unit>
                  </ng-container>
                </span>
              </ng-container>
              <ng-template #other>
                <span
                  class='feature-text'>{{ featureValue?.value }}&nbsp;<ng-container
                  *ngIf='feature.featureUnit'>{{ feature.featureUnit.symbol }}</ng-container></span>
              </ng-template>
            </li>
          </ul>
        </td>
      </tr>
      <ng-container
        *ngIf='lastClassification'
      >
        <tr *ngIf='product.bonusGroup as bonusGroup' class='body'>
          <td class='feature-title'>{{ 'siko.bonusGroup'| cxTranslate }}</td>
          <td>
            <ul>
              <li>
                <span class='feature-text'>
                    {{ +bonusGroup >= 10 ? "AC" : bonusGroup }}
                  </span>
              </li>
            </ul>
          </td>
        </tr>
      </ng-container>
    </table>
  </ng-container>
  <ng-template #noParameters>
    <p class='siko-fs-16'>{{ 'pdp.tabs.noTechnicalParametersMessage' | cxTranslate }}</p>
    <p class='siko-fs-16'>{{ 'pdp.tabs.contactOurSupportMessage' | cxTranslate }}</p>
  </ng-template>
</ng-container>
