<div class='menu-wrapper' *ngIf='data !== undefined'>
  <div class='image-wrapper'>
    <app-media [container]='data.media' class='image'></app-media>
  </div>
  <div class='menu_banner__container d-flex desktop-view' [routerLink]='data.url' [attr.data-testid]='data.uid'>
    <a class='menu_banner__main_title' [innerHTML]='data.title'></a>
    <img class='icon' [src]='"header/arrow.svg" | sikoIconAsset' alt='Dropdown arrow icon'>
  </div>
  <div class='menu_banner__container mobile-view' [attr.data-testid]='data.uid'>
    <a class='menu_banner__main_title' [innerHTML]='data.title'></a>
    <img class='icon' [src]='"header/arrow.svg" | sikoIconAsset' alt='Dropdown arrow icon'>
    <button class='btn-full-offer redirect-element'><a class='redirect-element' [routerLink]='data.url'>{{"siko.fullOffer"|cxTranslate}}</a>
    </button>
  </div>
</div>
