import { Injectable } from '@angular/core';
import { SikoSelectField } from '@siko/models';
import { Config, PointOfService, RoutingService, TranslationService } from '@spartacus/core';
import { Observable, of, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SikoHelperFunctionsService {

    constructor(
        protected translation: TranslationService,
        private readonly router: Router,
        private readonly routingService: RoutingService,
        private readonly config: Config,
    ) {}

    isObjectEmpty(object: object): boolean {
        return typeof object != 'undefined' && Object.keys(object).length === 0 && object.constructor === Object;
    }

    getCountOfStringOccurencesInArray(array: string[], query: string): number {
        return array.filter(element => element.includes(query)).length;
    }

    isCurrent(urlSufix: string): boolean {
        const currentRoute = this.router.routerState.snapshot.url;


        return currentRoute.includes(urlSufix);
    }

    displayProductsInStock(pointOfServiceCode: string | undefined): void {
        if (!pointOfServiceCode) {
            return;
        }


        this.routingService.goByUrl(`/c/1?query=:relevance:allCategories:1:availableInStores:${pointOfServiceCode}`);
    }

    translate(sikoSelectFields: SikoSelectField[]): Observable<SikoSelectField[]> {
        return of(sikoSelectFields)
            .pipe(
                mergeMap(sikoSelectFields =>
                    zip(...sikoSelectFields.map(sikoSelectField =>
                        this.translation.translate(sikoSelectField.label)
                            .pipe(
                                map(translation => ({
                                    key: sikoSelectField.key,
                                    label: translation,
                                })),
                            )))),
            );
    }

    getRandomString(): string {
        return [...Array(8)].map(() => Math.random()
            .toString(36)[2])
            .join('');
    }

    removeSikoPrefix(name: string | undefined): string {
        return name ? name.replace('SIKO', '') : '';
    }

    scrollToElement(elementId: string): void {
        const headerOffset = 80;
        const el = document.getElementById(elementId);
        const elementPosition = el?.getBoundingClientRect().top;

        if (elementPosition) {
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    }

    getBaseUrl(): string {
        return (
            this.config.backend?.media?.baseUrl ??
            this.config.backend?.occ?.baseUrl ??
            ''
        );
    }

    goToStoreDetailLink(pointOfService: PointOfService): void {
        this.routingService.goByUrl('store-finder?store=' + pointOfService.name);
    }

    /**
     * Checks if parameter is of type Number
     *
     * @param input raw data type
     * @returns boolean value. True if parameter is a number, false otherwise.
     */
    isNumber(input: any): boolean {
        return !isNaN(parseFloat(input)) && !isNaN(input - 0);
    }

}
