import { ChangeDetectorRef, Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { SikoUser } from '@siko/models';
import { isNotUndefined, User } from '@spartacus/core';


@Directive({
    selector: '[bonusProgramVisibility]',
})
export class SikoBonusProgramComponentVisibilityDirective implements OnInit, OnDestroy {

    destroy$ = new Subject<void>();

    constructor(
        readonly userFacade: UserAccountFacade,
        readonly templateRef: TemplateRef<any>,
        readonly viewContainer: ViewContainerRef,
        readonly cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.userFacade.get().pipe(
            filter(isNotUndefined),
            map((user: User): SikoUser => ({
                ...user,
            })),
            tap((user: SikoUser) => {
                if (user.sikoBonusProgramActive) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }
                else {
                    this.viewContainer.clear();
                }

                this.cdr.markForCheck();
            }),
            takeUntil(this.destroy$)
        )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
