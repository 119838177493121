import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService, CMSTabParagraphContainer, WindowRef } from '@spartacus/core';
import { CmsComponentData, CurrentProductService, TabParagraphContainerComponent } from '@spartacus/storefront';
import { SikoPdpCommunicationService } from '@siko/features/product/product-detail/pdp-communication.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { SikoProduct } from '@siko/models';
import { SikoAutoUnsubscribe, SikoBreakpointObserverService } from '@siko/common';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';


@SikoAutoUnsubscribe(['activeParagraphSubscription', 'productSubscription', 'mutationsSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-tab-paragraph-container',
    templateUrl: './tab-paragraph-container.component.html',
    styleUrls: ['./tab-paragraph-container.component.scss'],
})
export class SikoTabParagraphContainerComponent extends TabParagraphContainerComponent implements OnInit, OnDestroy {

    activeParagraphSubscription?: Subscription;
    observer?: MutationObserver;
    mutations = new Subject<MutationRecord[]>();
    mutationsSubscription?: Subscription;
    productSubscription?: Subscription;
    hasScrolled = false;

    private readonly isSimilarProductsTabShownSubject$ = new BehaviorSubject<boolean>(false);

    get isSimilarProductsTabShown$(): Observable<boolean> {
        return this.isSimilarProductsTabShownSubject$.asObservable();
    }

    constructor(
        public pdpCommunicationService: SikoPdpCommunicationService,
        public componentData: CmsComponentData<CMSTabParagraphContainer>,
        public sikoBreakpointObserver: SikoBreakpointObserverService,
        protected currentProductService: CurrentProductService,
        protected cmsService: CmsService,
        protected winRef: WindowRef,
        readonly route: ActivatedRoute,
    ) {
        super(componentData, cmsService, winRef);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.activeParagraphSubscription = this.pdpCommunicationService.tabParagraphActiveNum$
            .subscribe((selectedTabNum: number) => {
                this.select(selectedTabNum);
            });

        this.productSubscription = this.currentProductService.getProduct()
            .subscribe((product: SikoProduct | null) => {
                if (!product) {
                    return;
                }

                if (this.route.snapshot.queryParams.review === 'on') {
                    this.pdpCommunicationService.setTabParagraphActiveNum(3);
                    this.observer = new MutationObserver((mutations) => {
                        if (!this.hasScrolled) {
                            this.mutations.next(mutations);
                        }
                    });

                    this.observer.observe(document.body, {
                        childList: true,
                        subtree: true,
                    });

                    this.mutationsSubscription = this.mutations.pipe(
                        debounceTime(100)
                    )
                        .subscribe(() => {
                            if (!this.hasScrolled) {
                                this.pdpCommunicationService.handleScroll(
                                    'js--review-tab',
                                    'REVIEWS',
                                    true,
                                );
                                this.hasScrolled = true;
                                this.observer?.disconnect();
                            }
                        });
                }
                else {
                    this.pdpCommunicationService.setTabParagraphActiveNum(1);
                }

                const hasSimilarProducts = product.sikoSimilarProductsCount !== undefined
                    && product.sikoSimilarProductsCount > 0;

                this.isSimilarProductsTabShownSubject$.next(hasSimilarProducts);
            });
    }

    ngOnDestroy(): void {
        this.observer?.disconnect();
    }

}


