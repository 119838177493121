import { Pipe, PipeTransform } from '@angular/core';
import { SikoUtils } from '@siko/shared';

@Pipe({
    name: 'sikoConvertUnitConversion',
})
export class SikoConvertUnitConversionPipe implements PipeTransform {

    transform(conversion?: string): number {
        if (conversion) {
            return SikoUtils.sikoRound(Number(conversion), 2);
        }
        return 0;
    }

}
