<div *ngrxLet='currentValue$ as value'
     [ngClass]='getMainClass()'
     ngbTooltip
     placement='auto'
     container='body'
     sikoTooltipText='{{ getNote() ?? "" | cxTranslate}}'
     [disableTooltip]='getNote() === undefined || disableTooltip'
>
  <div [ngClass]='getIconClass()'></div>
  <span [ngClass]='getValueClass()'>{{ isNumeric(value) ? (value | sikoCxNumeric) : value }}</span>
</div>
