<ng-container *ngIf='orderCode'>
  <span
    ngbTooltip
    role='button'
    tabindex='0'
    placement='top'
    class='reorder-tooltip'
    sikoTooltipText="{{ 'sikoOrder.orderDetail.reorderTolltip' | cxTranslate }}"
  >
    <siko-button
      testId='reorder-button'
      [size]='$any("md")'
      [mode]='$any("cart")'
      [block]='displayBlock'
      [disabled]='(reorderService.isReordering$ | async) ?? false'
      text='{{ "siko.reorder" | cxTranslate }}'
      (clickEvent)='reorder(orderCode)'></siko-button>
  </span>
</ng-container>
