import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class Sikob2bSerpService {

    private readonly freeTextSearch = new BehaviorSubject('');

    get freeTextSearch$(): Observable<string>{
        return this.freeTextSearch.asObservable();
    }

    setIsShowStickyMenu(value: string){
        this.freeTextSearch.next(value);
    }

}
