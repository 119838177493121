import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SIKO_NEWS_FEATURE } from '@siko/features/news/store/siko-news-state';
import { META_REDUCERS, REDUCER_PROVIDER, REDUCER_TOKEN } from '@siko/features/news/store/reducers';
import { SikoNewsEffects } from '@siko/features/news/store/effects';

@NgModule({
    declarations: [],
    imports: [
        B2bCommonModule,
        StoreModule.forFeature(SIKO_NEWS_FEATURE, REDUCER_TOKEN, {
            metaReducers: META_REDUCERS
        }),
        EffectsModule.forFeature(SikoNewsEffects)
    ],
    providers: [
        REDUCER_PROVIDER
    ]
})
export class SikoNewsStoreModule { }
