import { createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import {
    SikoNewsState,
    SikoDefaultNewsState,
    StateWithSikoNews,
} from '@siko/features/news/store/siko-news-state';
import { SikoSelectedNewsData } from '@siko/models';
import { getSikoNewsState } from './news-feature.selector';

export const getSikoDefaultNewsState: MemoizedSelector<StateWithSikoNews,
    StateUtils.LoaderState<SikoDefaultNewsState>> = createSelector(
        getSikoNewsState, (newsState: SikoNewsState) =>
            newsState.news,
    );

export const getSikoDefaultNewsStateLoading: MemoizedSelector<StateWithSikoNews,
    boolean> = createSelector(
        getSikoDefaultNewsState, (newsState: StateUtils.LoaderState<SikoDefaultNewsState>) =>
            StateUtils.loaderLoadingSelector(newsState),
    );

export const getNewsData: MemoizedSelector<StateWithSikoNews,
    any> = createSelector(
        getSikoDefaultNewsState, (newsState: StateUtils.LoaderState<SikoDefaultNewsState>) =>
            newsState.value?.newsData ?? {},
    );

export const getSelectedNews: MemoizedSelector<StateWithSikoNews,
    SikoSelectedNewsData> = createSelector(
        getSikoDefaultNewsState, (newsState: StateUtils.LoaderState<SikoDefaultNewsState>) =>
            newsState.value?.selectedNews ?? undefined,
    );

export const getCurrentPage: MemoizedSelector<StateWithSikoNews,
    number> = createSelector(
        getSikoDefaultNewsState, (newsState: StateUtils.LoaderState<SikoDefaultNewsState>) =>
            newsState.value?.currentPage ?? 0,
    );

export const getPaginationSize: MemoizedSelector<StateWithSikoNews,
    number | undefined> = createSelector(
        getSikoDefaultNewsState, (newState: StateUtils.LoaderState<SikoDefaultNewsState>) =>
            newState.value?.newsData.paginationSize ?? undefined,
    );

export const getNumberOfPagesShows: MemoizedSelector<StateWithSikoNews,
    number | undefined> = createSelector(
        getSikoDefaultNewsState, (newsState: StateUtils.LoaderState<SikoDefaultNewsState>) =>
            newsState.value?.newsData.numberPagesShown ?? undefined,
    );


