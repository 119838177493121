<ng-container *ngIf='sikoProduct$ | async as sikoProduct'>
  <div class='row'>
    <div class='col'>
      <app-product-availability-info [product]='sikoProduct'></app-product-availability-info>
    </div>
  </div>

  <ng-container *ngIf='showAddToCartButtons; else saleEnded'>
    <form [formGroup]='addToCartForm'>
      <div class='d-flex flex-column flex-md-row mb-4'>
        <div class='item-counter'>
          <app-pdp-item-counter
            [product]='sikoProduct'
            [control]="$any(addToCartForm).get('quantity')"
            [isLimited]='true'
            counterSize='lg'
          ></app-pdp-item-counter>
        </div>
        <div class='mb-3 flex-grow-1'>
          <app-product-add-to-cart-button
            tabindex='0'
            textTop="{{'addToCart.addToCart' | cxTranslate}}"
            [sikoProduct]='sikoProduct'
            (clickEvent)='sikoAddToCart(sikoProduct)'
          ></app-product-add-to-cart-button>
          <ng-container *ngIf='user$ | async as user'>
            <app-product-add-to-cart-bonus-button
              *ngIf='user.sikoBonusProgramActive && sikoProduct.sikoBonusProductConfigs'
              textTop="{{'addToCart.addToCart' | cxTranslate}}"
              [sikoProduct]='sikoProduct'
              (points)='sikoAddToCart(sikoProduct, $event)'
            ></app-product-add-to-cart-bonus-button>
          </ng-container>
        </div>
      </div>
    </form>

    <div class='delivery-info-section'>
      <div class='d-flex heading' (click)='this.showDeliveryInfo.next(!this.showDeliveryInfo.getValue())'>
        <div class='pr-2 pl-3'>
          <img [src]='"pdp/delivery-info-icon.svg" | sikoIconAsset' alt='Delivery info icon'>
        </div>
        <div>
          {{ 'pdp.deliveryInfoHeading' | cxTranslate }}
        </div>
        <div class='pl-2' [ngClass]='showDeliveryInfo.getValue() ? "d-none" : ""'>
          <img [src]='"arrow_down.svg" | sikoIconAsset' alt='Show delivery info'>
        </div>
      </div>
      <div class='pl-3 info' [ngClass]='showDeliveryInfo.getValue() ? "" : "d-none"'>
        {{
          'pdp.deliveryInfoText' | cxTranslate : {
            deliveryTime: sikoProduct.disponibility?.deliveryTime
          }
        }}
      </div>
    </div>
  </ng-container>

  <ng-template #saleEnded>
    <ng-container *ngIf='sikoProduct.sapStatus === "Z1"; else outOfStock'>
      <app-info-banner-component mode='danger'>
        <div>
          {{ 'siko.saleEnded' | cxTranslate }}
        </div>
      </app-info-banner-component>
    </ng-container>
    <ng-template #outOfStock>
      <app-info-banner-component
        text="{{'siko.outOfStock' | cxTranslate}}"
        mode='danger'
      ></app-info-banner-component>
    </ng-template>
  </ng-template>
</ng-container>
