import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { FacetComponent, FacetService } from '@spartacus/storefront';
import { FacetValue } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { SikoAddress, SikoB2BUser, SikoFacet, SikoFacetValue } from '@siko/models';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { SikoAutoUnsubscribe } from '@siko/common';

@SikoAutoUnsubscribe(['subscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-facet',
    templateUrl: './facet.component.html',
    styleUrls: ['./facet.component.scss'],
})
export class SikoFacetComponent extends FacetComponent implements OnInit {
    @Input() virtualCategory?: string;

    filteredData?: SikoFacetValue[];
    facetNotFound?: boolean;
    searching: boolean = false;
    minItemsToShowingSearch: number = 6;
    activeFacet = 'hide-notification';
    predefinedPointOfServiceFacetValue?: SikoFacetValue;
    predefinedPointOfServiceFacet?: SikoAddress;
    searchValue?: string;

    user$: Observable<SikoB2BUser | undefined> = this.userProfileFacade.get();
    subscription?: Subscription;

    constructor(
        private readonly userProfileFacade: UserProfileFacade,
        facetService: FacetService,
        elementRef: ElementRef<HTMLElement>,
        cd: ChangeDetectorRef,
    ) {
        super(facetService, elementRef, cd);
    }

    get sikoFacet(): SikoFacet {
        return this._facet;
    }

    ngOnInit(): void {
        this.subscription = this.user$.subscribe((user) => {
            if (user) {
                this.predefinedPointOfServiceFacet = user.orgUnit?.sikoPredefinedPointOfService;
            }

            if (this.facet.values) {
                this.predefinedPointOfServiceFacetValue = this.facet.values.find((item: any) => {
                    if (this.predefinedPointOfServiceFacet && item) {
                        return item.name.toLowerCase()
                            .includes(
                                this.predefinedPointOfServiceFacet.displayName?.toLowerCase(),
                            );
                    }
                });
            }
        });

        this.filteredData = this.facet.values;

        if (this.facet.values) {
            for (const facetValue of this.facet.values) {
                if (facetValue.selected) {
                    this.activeFacet = 'show-notification';
                }
            }
        }
    }


    onSearchChange(searchValue: string): void {
        this.searching = searchValue !== '';
        this.minItemsToShowingSearch = this.searching ? 0 : 6;

        if (this.facet.values) {
            this.filteredData = this.facet.values.filter((item: any) => {
                if (item.name) {
                    return item.name.toLowerCase()
                        .includes(searchValue.toLowerCase());
                }
            });

            if (this.filteredData.length === 0) {
                this.facetNotFound = true;
            }
            else {
                this.facetNotFound = false;
            }
        }
    }

    getSikoLinkParams(value: FacetValue): any {
        if (value?.query?.query?.value) {
            const link: string = this.facetService.getLinkParams(value.query.query.value)['query'];
            const params: string[] = link.split(':');

            params.forEach((value, index) => {
                if (value === 'availableInStores') {
                    if (params[index + 1] !== this.predefinedPointOfServiceFacet?.name) {
                        delete params[index];
                        delete params[index + 1];
                    }
                }
            });

            return {
                query: params.filter(_ => true)
                    .join(':'),
            };
        }

        return null;
    }

    get isExpanded(): boolean {
        const isExpanded = super.isExpanded;

        if (isExpanded) {
            this.onSearchChange('');
            this.searchValue = '';
        }

        return isExpanded;
    }
}
