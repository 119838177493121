import { Component } from '@angular/core';
import { SikoBreakpointObserverService } from '@siko/common';

@Component({
    selector: 'app-detail-loading-skeleton',
    templateUrl: './detail-loading-skeleton.component.html',
    styleUrls: ['./detail-loading-skeleton.component.scss'],
})
export class SikoDetailLoadingSkeletonComponent {

    constructor(public breakpointObserver: SikoBreakpointObserverService) { }

}
