import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerCouponService, GlobalMessageService, RouterState, RoutingService } from '@spartacus/core';
import { CouponClaimComponent } from '@spartacus/storefront';

@Component({
    template: '',
    selector: 'cx-coupon-claim',
})
export class SikoCouponClaimComponent extends CouponClaimComponent implements OnInit, OnDestroy {


    constructor(
        couponService: CustomerCouponService,
        routingService: RoutingService,
        messageService: GlobalMessageService,
    ) {
        super(couponService, routingService, messageService);
    }

    ngOnInit(): void {
        this.routingService
            .getRouterState()
            .subscribe((k: RouterState) => {
                const {couponCode} = k.state.params;

                if (couponCode) {
                    this.couponService.claimCustomerCoupon(couponCode);
                    void this.routingService.go({ cxRoute: 'coupons' });
                }
                else {
                    void this.routingService.go({ cxRoute: 'notFound' });
                }
            })
            .unsubscribe();
    }

}
