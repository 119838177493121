import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SikoReview } from '@siko/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-review-item',
    templateUrl: './product-review-item.component.html',
    styleUrls: ['./product-review-item.component.scss'],
})
export class SikoProductReviewItemComponent {

    @Input() review?: SikoReview;

}
