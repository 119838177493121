<ng-container *ngIf='activeDeliveryMode | async as deliveryMode'>
  <ng-container *ngIf='sikoProduct && !sikoProduct?.sapStatus?.includes("Z1")'>
    <div class='availability-info'>
      <div class='section favorite-pos' [ngClass]="{
         'active': deliveryMode === 'pickup',
         'outOfStock' : sikoProduct.disponibility?.storeDisponibility?.status?.includes('outOfStock')
        }">
        <div class='d-flex flex-column flex-sm-row flex-wrap w-100 align-items-start align-items-sm-center'>
          <ng-container *ngIf='predefinedPOS | async as predefinedPOS'>
            <div class='title-delivery'>
              <div class='d-flex align-items-center siko-lh-120'>
                <app-store-icon></app-store-icon>
                <span class='pl-3'>{{'sikoProductShared.availabilityInfo.pickupInStore' | cxTranslate }} <span
                  class='store'>{{predefinedPOS.displayName}}</span></span>
              </div>
            </div>
          </ng-container>
          <div class='ml-2 ml-sm-0'>
            <app-store-product-disponibility
              [centralStock]='sikoProduct?.disponibility'
              [unit]='sikoProduct?.unitData'
              [plp]='true'
              [productDisponibility]='sikoProduct.disponibility?.storeDisponibility'></app-store-product-disponibility>
          </div>
        </div>
      </div>
      <div class='section delivery' [ngClass]="{
        'active': deliveryMode === 'deliveryToAddress',
        'outOfStock' : sikoProduct.disponibility?.status?.includes('outOfStock')
        }">
        <div class='d-flex flex-column flex-sm-row flex-wrap w-100 align-items-start align-items-sm-center'>
          <div class='title-delivery'>
            <div class='d-flex  align-items-center siko-lh-120'>
              <app-shipping-icon></app-shipping-icon>
              <span class='pl-3'>{{'sikoProductShared.availabilityInfo.deliveryToAddress' | cxTranslate }} </span>
            </div>
          </div>
          <div class=' ml-2 ml-sm-0'>
            <app-store-product-disponibility
              [unit]='sikoProduct?.unitData'
              [plp]='true'
              [productDisponibility]='sikoProduct.disponibility'
              [deliveryTimeText]='sikoProduct.disponibility?.deliveryTimeText'>
            </app-store-product-disponibility>
          </div>
        </div>
      </div>
      <div class='section other-store'>
        <div class='d-flex flex-column flex-sm-row flex-wrap w-100 align-items-start align-items-sm-center'>
          <div class='title-delivery'>
            <div class='d-flex align-items-center siko-lh-120'>
              <app-location-icon></app-location-icon>
              <span class='pl-3'>{{'sikoProductShared.availabilityInfo.pickupInOtherStore' | cxTranslate }} </span>
            </div>
          </div>
          <div class='ml-2 ml-sm-0'>
            <ng-container *ngIf='isProductAvailable() else outOfStock'>
              <div class='d-flex align-items-center'>
              <div class='pr-3 pr-md-2'>
                <app-check-circle-icon [iconMode]='"circle-dark"'></app-check-circle-icon>
              </div>
              <div *ngIf='sikoProduct.availableIn'>
                <div class='other-stores pt-2 pt-md-0' *ngIf='+sikoProduct.availableIn > 0'>
                  {{"sikoProductShared.availabilityInfo.storeAvailability" | cxTranslate: {count: +sikoProduct.availableIn} }}
                </div>
                <div class='siko-font-primary-semiBold'>
                  <siko-link
                    tabindex='0'
                    label='{{"sikoProductShared.availabilityInfo.showAvailability" | cxTranslate}}'
                    (click)='openPickUpInOtherStoreDialog(sikoProduct)'
                    [style]="$any('primary')"
                    [disableLink]='true'>
                  </siko-link>
                </div>
              </div>
            </div>
            </ng-container>
            <ng-template #outOfStock>
              <div class='d-flex align-items-center product-unavailable'>
                <div class='pr-2'>
                  <app-red-cross-icon></app-red-cross-icon>
                </div>
                {{"siko.outOfStock" | cxTranslate }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <ng-container *ngIf='deliveryMode === "pickupOtherStore"'>
        <ng-container *ngIf='selectedPOS | async as selectedPOS'>
          <div class='section selected-store active'
               [ngClass]='sikoProduct?.disponibility?.selectedStoreDisponibility?.status'>
            <div class='d-flex flex-column flex-sm-row flex-wrap w-100 align-items-start align-items-sm-center'>
              <div class='title-delivery'>
                <div class='d-flex align-items-center siko-lh-120'>
                  <app-store-icon></app-store-icon>
                  <span class='pl-3'>{{'sikoProductShared.availabilityInfo.pickupInStore' | cxTranslate }} <span
                    class='store'>{{selectedPOS?.displayName}}</span></span>
                </div>
              </div>
              <div class=' ml-2 ml-sm-0'>
                <app-store-product-disponibility
                  [centralStock]='sikoProduct?.disponibility'
                  [plp]='true'
                  [productDisponibility]='sikoProduct?.disponibility?.selectedStoreDisponibility'
                  [unit]='sikoProduct?.unitData'></app-store-product-disponibility>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf='deliveryMode === "shipment"'>
        <ng-container *ngIf='selectedPOS | async as selectedPOS'>
          <div class='section selected-store active'
               [ngClass]='sikoProduct?.disponibility?.selectedStoreDisponibility?.status'>
            <div class='d-flex flex-column flex-sm-row flex-wrap w-100 align-items-start align-items-sm-center'>
              <div class='title-delivery'>
                <div class='d-flex align-items-center siko-lh-120'>
                  <app-move-icon></app-move-icon>
                  <span class='pl-3'>{{'sikoProductShared.availabilityInfo.shipment' | cxTranslate }} <span
                    class='store'>{{selectedPOS?.displayName}}</span></span>
                </div>
              </div>
              <div class=' ml-2 ml-sm-0'>
                <app-store-product-disponibility
                  [plp]='true'
                  [centralStock]='sikoProduct?.disponibility'
                  [productDisponibility]='sikoProduct?.disponibility?.selectedStoreDisponibility'
                  [unit]='sikoProduct?.unitData'></app-store-product-disponibility>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

