<div class='mobile-nav hide-desktop'>
  <div class='d-flex pt-2' (click)='toggleMobileMenu()' #hamburgerMenu>
    <div class='category-name d-flex align-items-center'>
      {{ 'siko.category' | cxTranslate }}
    </div>
    <div class='hamburger-icon'>
      <img class='icon' alt='Main menu toggle icon'
           [src]='"header/mobile/" + (offCanvas ? "close-menu.svg" : "hamburger-menu-mobile.svg") | sikoIconAsset'>
    </div>
  </div>
</div>

<ng-container *ngIf='{observableValue : isShowStickyMenu | async } as showMenuSticky'>
  <div [ngClass]="offCanvas ? showMenuSticky.observableValue ? 'is-open sticky' : 'is-open' : 'is-closed'" #mobileMenu>
    <div ngbAccordion #mobileAccordion>
      <ng-container *ngFor="let child of node.children; trackBy: trackByNavigationNode">
        <div class='card' ngbAccordionItem>
          <div class='card-header' ngbAccordionHeader>
            <button class='btn btn-link' ngbAccordionButton>
              <div class="nav__level1" *ngIf="child.itemType === 'CMSLinkComponent'">
                <div class="d-flex flex-wrap justify-content-between heading">
                  <div class="button-content" *ngIf="child?.url" [attr.data-testid]="child?.uid">
                    <img class="back-arrow" [src]="'header/mobile/mobile-heading-arrow.svg' | sikoIconAsset" alt="Arrow icon">
                    <cx-generic-link [url]="child.url ?? ''" class="link">{{child.title}}</cx-generic-link>
                    <div class="rotate-arrow">
                      <img [src]="'header/mobile/mobile-side-arrow.svg' | sikoIconAsset" alt="Arrow icon">
                    </div>
                  </div>
                  <button class="btn-full-offer" (click)="toggleMobileMenu()">
                    <cx-generic-link [url]="child.url ?? ''">
                      {{ "siko.fullOffer" | cxTranslate }}
                    </cx-generic-link>
                  </button>
                </div>
              </div>
            </button>
          </div>

          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="card-wrapper">
                  <div class="wrapper nav__level2" *ngIf="child.children && child.children.length > 0">
                    <ng-container *ngFor="let childV2 of child.children; trackBy: trackByNavigationNode">
                      <ng-container *ngTemplateOutlet="nav2nd; context: { node: childV2, depth: 1 }"></ng-container>
                    </ng-container>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- 2nd level navigation -->
<ng-template ngbPanelContent #nav2nd let-node='node' let-depth='depth'>
  <div [ngClass]='this.componentWithoutAccordion.includes(node.itemType)
  || (node.itemType === "PromoBannerCMSComponent" && node?.children !== undefined && node.children.length === 0) ? "" : "d-none"'>
    <div class='{{ node.itemType }}'>
      <ng-container *ngIf='node.itemType === "PromoBannerCMSComponent"'>
        <app-promo-banner [data]='node'></app-promo-banner>
      </ng-container>
      <ng-container *ngIf='node.itemType !== "PromoBannerCMSComponent"'>
        <ng-container [cxComponentWrapper]='{
                        uid: node.uid,
                        typeCode: node.itemType,
                        flexType: node.itemType
                        }'>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div [ngClass]='this.componentWithAccordion.includes(node.itemType)
  || (node.itemType === "PromoBannerCMSComponent" && node?.children !== undefined && node.children.length > 0) ? "" : "d-none"'>
    <div ngbAccordion class="card {{node.itemType}}-accordion">
      <div class='card' ngbAccordionItem>
        <div class='card-header'>
          <button class='btn btn-link' ngbAccordionButton>
            <ng-container *ngIf="node.itemType === 'PromoBannerCMSComponent'">
              <app-promo-banner [data]="node"></app-promo-banner>
            </ng-container>
            <ng-container *ngIf="node.itemType === 'MenuBannerCMSComponent'">
              <app-menu-banner [data]="node"></app-menu-banner>
            </ng-container>
          </button>
        </div>

        <div ngbAccordionCollapse>
          <div class='card-body' ngbAccordionBody>
            <ng-template>
              <div class="card-wrapper">
                <div class="wrapper nav__level3" *ngIf="node.children && node.children.length > 0">
                  <ng-container *ngFor="let child of node.children">
                    <ng-container *ngTemplateOutlet="nav3rd; context: { node: child, depth: 1 }">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- 3rd level navigation -->
<ng-template #nav3rd let-node='node' let-depth='depth'>
  <div class='{{ node.itemType }}' (click)='toggleMobileMenu()' [attr.data-testid]='node.uid'>
    <cx-generic-link [url]='node.url'>{{node.title}}</cx-generic-link>
  </div>
</ng-template>

<ng-container *ngIf='{observableValue : isShowStickyMenu | async } as showMenuSticky'>
  <div #mobileMenuOverlay
       [ngClass]='offCanvas ? showMenuSticky.observableValue ? "overlay-bg sticky" : "overlay-bg" : ""'
       (click)='toggleMobileMenu()'></div>
</ng-container>
