export enum PRODUCT_COMPONENT_TYPE_CODE {
  PRODUCT_CAROUSEL_COMPONENT_TYPE_CODE = 'ProductCarouselComponent',
  PRODUCT_REFERENCES_COMPONENT_TYPE_CODE = 'ProductReferencesComponent'
}
export enum PRODUCT_SAP_STATUS {
  SAP_STATUS_Z1 = 'Z1',
  SAP_STATUS_Z2 = 'Z2',
  SAP_STATUS_ZN = 'ZN'
}

export enum PRODUCT_DISPONIBILITY {
  FORCE_IN_STOCK = -1,
  PRODUCT_DISPONIBILITY_MAX_DISPLAYED_LEVEL = 99,
  PRODUCT_WITH_CONVERSION_RATE = 'MTK',
  OUT_OF_STOCK_DISPONIBILITY_STATUS = 'outOfStock',
  ZERO_PRODUCTS_STORE_AVAILABILITY = 0
}

export enum PRODUCT_TRANSPORT_RESTRICTIONS{
  PRODUCT_PALETTE_TRANSPORT = 'DOPRAVAPAL',
  PALETTE_DELIVERY_CODE = 'PAL_net',
  PACKAGE_DELIVERY_CODE = 'BAL_net',
  PICKUP = 'pickup'
}

export const PRODUCT_VIDEO_IFRAME_URL_PREFIX = 'https://www.youtube.com/embed/';
