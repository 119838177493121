import { Observable, Subscription } from 'rxjs';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ProductListComponentService } from '@spartacus/storefront';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { SikoProductSearchPage } from '@siko/models';
import { SikoAutoUnsubscribe } from '@siko/common';
import { OccEndpointsService, RoutingService } from '@spartacus/core';
import { SikoHelperFunctionsService } from '@siko/shared';

@SikoAutoUnsubscribe(['modelSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
    styleUrls: ['./product-categories.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class SikoProductCategoriesComponent implements OnInit {
    @ViewChild('topSellingProducts') topSellingProducts?: ElementRef;
    @ViewChild('nav') nav?: any;
    @ViewChild('carousel') carousel?: NgbCarousel;

    posIni: any;
    activeId = 0;
    baseUrl = '';
    isShowMoreCategories = false;
    isShowMoreBrands = false;
    isShowMoreSeries = false;
    isEmptySearchResult = false;
    notShowCategoriesBlock = false;


    arrayOfCategories: any[] = [];
    featureCategories: any[] = [];
    model$: Observable<SikoProductSearchPage> =
        this.productListComponentService.model$;

    modelSubscription?: Subscription;

    constructor(
        public readonly sikoHelperService: SikoHelperFunctionsService,
        private readonly routingService: RoutingService,
        private readonly occEndpointsService: OccEndpointsService,
        private readonly productListComponentService: ProductListComponentService,
        config: NgbCarouselConfig,
    ) {
        this.baseUrl = this.occEndpointsService.getBaseUrl({
            baseUrl: true,
            prefix: false,
            baseSite: false,
        });
        config.interval = 10000;
        config.showNavigationArrows = false;
    }

    ngOnInit(): void {
        this.modelSubscription = this.model$.subscribe(res => {

            this.arrayOfCategories = [];
            this.featureCategories = [];
            this.activeId = this.setActiveTab(res);

            this.notShowCategoriesBlock = !res.sikoCategoriesCount &&
                !res.sikoBrandsCount &&
                !res.sikoStoresCount &&
                !res.sikoSeriesCount;

            this.isEmptySearchResult =
                this.notShowCategoriesBlock &&
                res.products?.length == 0;

            if (res.category?.featureCategories) {
                this.featureCategories = res.category.featureCategories;

                let count = 0;
                let index = 0;

                this.featureCategories.forEach((val: any, key: any) => {
                    if (!this.arrayOfCategories[index]) {
                        this.arrayOfCategories[index] = [];
                    }

                    this.arrayOfCategories[index].push(val);

                    if (count == 3) {
                        count = 0;
                        index++;
                    }
                    else {
                        count++;
                    }
                });
            }
        });
    }

    setActiveTab(res: any): number {
        if (res.sikoCategoriesCount > 0) {
            return 1;
        }

        if (res.sikoSeriesCount > 0) {
            return 2;
        }

        if (res.sikoBrandsCount > 0) {
            return 4;
        }

        if (res.sikoStoresCount > 0) {
            return 5;
        }

        return 1;
    }

    switchTab(direction: string): void {
        let nextItem;
        const activeItem = this.nav.items._results.filter((item: any) => item.active == true)[0].elementRef.nativeElement;

        if (direction === 'back') {
            nextItem = activeItem.previousElementSibling;

            if (nextItem.children.length == 0) {
                nextItem = this.nav.items.last.elementRef.nativeElement;
            }
        }
        else {
            nextItem = activeItem.nextElementSibling;

            if (nextItem.children.length == 0) {
                nextItem = this.nav.items.first.elementRef.nativeElement;
            }
        }

        nextItem.children[0].click();
    }

    getImageUrl(imageUrl?: string): string {
        if (imageUrl) {
            return this.baseUrl + imageUrl;
        }

        return '';
    }

    move(pos: number): void {
        const offset = this.posIni - pos;
        if (offset < -100 && this.carousel) {
            this.carousel.prev();
        }

        if (offset > 100 && this.carousel) {
            this.carousel.next();
        }
    }
}
