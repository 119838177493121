import { Component, Input, OnChanges } from '@angular/core';
import { SikoBopisPointOfService, SikoProductDisponibility, SikoProductUnitData } from '@siko/models';
import { B2bCommonModule, SikoProductDisponibilityService, SikoUtils } from '@siko/shared';
import { PRODUCT_SAP_STATUS } from '@siko/constants';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';
import { SikoRedCrossIconComponent } from '@siko/features/shared-components/icons/red-cross/red-cross.component';

@Component({
    selector: 'app-store-product-disponibility',
    templateUrl: './store-product-disponibility.component.html',
    styleUrls: ['./store-product-disponibility.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoProductUnitComponent,
        SikoRedCrossIconComponent
    ]
})

export class SikoStoreProductDisponibilityComponent implements OnChanges {
    @Input() storeEntity?: SikoBopisPointOfService;
    @Input() mainStoreEntity?: SikoBopisPointOfService;
    @Input() productDisponibility?: SikoProductDisponibility;
    @Input() unit?: SikoProductUnitData;
    @Input() plp = false;
    @Input() checkout = false;
    @Input() cart = false;
    @Input() entryQuantity?: number;
    @Input() deliveryTimeText?: string;
    @Input() sapStatus?: string;
    @Input() centralStock?: SikoProductDisponibility;

    stockLevelStatus?: string;
    level?: number;
    saleEnded: boolean = false;

    constructor(protected sikoProductDisponibilityService: SikoProductDisponibilityService,
    ) {}

    ngOnChanges(): void {

        if (this.sapStatus?.includes(PRODUCT_SAP_STATUS.SAP_STATUS_Z1)) {
            this.saleEnded = true;
        }

        if (this.storeEntity) {
            this.stockLevelStatus = this.storeEntity.stockInfo?.stockLevelStatus;
            this.level = this.storeEntity.stockInfo?.stockLevel;

            if (this.mainStoreEntity && this.mainStoreEntity.stockInfo?.stockLevelStatus == 'upcoming' && this.storeEntity.stockInfo?.stockLevel == null) {
                this.stockLevelStatus = 'upcoming';
                this.checkout = true;
            }
        }

        if (this.productDisponibility) {
            this.stockLevelStatus = this.productDisponibility.status;
            this.level = this.productDisponibility.level;
        }

        if (this.entryQuantity && this.unit?.conversion && this.level) {
            if (this.entryQuantity > this.sikoProductDisponibilityService.calculateMaxField(this.level, Number(this.unit.conversion))) {
                this.stockLevelStatus = 'onOrder';
            }
        }

        if(this.saleEnded) {
            this.stockLevelStatus = 'outOfStock';
        }

        if (this.stockLevelStatus === 'onOrder' && this.centralStock?.status === "inStock"){
            if (this.entryQuantity && this.unit && this.centralStock.level) {
                if (this.entryQuantity <= this.sikoProductDisponibilityService.calculateMaxField(this.centralStock.level, Number(this.unit.conversion))) {
                    this.stockLevelStatus = 'inCentralStock';
                }
            }
        }

        if (this.centralStock && this.centralStock.status === 'upcoming' && this.productDisponibility?.level == undefined) {
            this.stockLevelStatus = 'upcoming';
            this.checkout = true;
        }
    }

    getStockLevel(): string | number {
        if (this.level) {
            return SikoUtils.formatDisponibilityLevel(this.level, this.unit);
        }

        return '';
    }

}
