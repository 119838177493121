import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomerSelectionComponent } from '@spartacus/asm/components';

@Component({
    selector: 'app-customer-selection',
    templateUrl: './customer-selection.component.html',
    styleUrl: './customer-selection.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoCustomerSelectionComponent extends CustomerSelectionComponent {

}
