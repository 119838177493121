import { Component, Input } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';

@Component({
    selector: 'app-duck-loader',
    templateUrl: './duck-loader.component.html',
    styleUrls: ['./duck-loader.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
    ],
})
export class SikoDuckLoaderComponent {
    @Input() transparentBackground = false;
    @Input() fixed = false;
    @Input() miniMode = false;
}
