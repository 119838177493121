import { ConfigEntry } from './utils/environment-config';
import {
    B2B_CZ,
    B2B_SK,
    getCloudUrls,
    getProdUrls,
    GOOGLE_API_KEY_PROD,
    GTM_PROD, GTM_STAGE, OAUTH_CONFIG_ASM_PROD,
    OAUTH_CONFIG_PROD,
    SENTRY_CONF_DEV1,
    SENTRY_CONF_DEV2,
    SENTRY_CONF_DEV3,
    SENTRY_CONF_PROD,
    SENTRY_CONF_STAGE,
} from '@siko-spartacus/configurations';

export const environment = {
    production: true,
};

export const DOMAINS: { [key: string]: ConfigEntry } = {
    'spa-b2b-cz.ck7dj7qam-sikokoupe1-p1-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_CZ, ...getProdUrls('cz') },
        oauthConfig: OAUTH_CONFIG_PROD,
        asmOauthConfig: OAUTH_CONFIG_ASM_PROD,
        sentry: SENTRY_CONF_PROD,
        googleMapApiKey: GOOGLE_API_KEY_PROD,
        gtmId: GTM_STAGE,
    },
    'spa-b2b-cz.ck7dj7qam-sikokoupe1-s1-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_CZ, ...getCloudUrls('s1', 'cz') },
        sentry: SENTRY_CONF_STAGE,
        gtmId: GTM_STAGE,
    },
    'spa-b2b-cz.ck7dj7qam-sikokoupe1-d1-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_CZ, ...getCloudUrls('d1', 'cz') },
        sentry: SENTRY_CONF_DEV1,
        gtmId: GTM_STAGE,
    },
    'spa-b2b-cz.ck7dj7qam-sikokoupe1-d2-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_CZ, ...getCloudUrls('d2', 'cz') },
        sentry: SENTRY_CONF_DEV2,
        gtmId: GTM_STAGE,
    },
    'spa-b2b-cz.ck7dj7qam-sikokoupe1-d3-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_CZ, ...getCloudUrls('d3', 'cz') },
        sentry: SENTRY_CONF_DEV3,
        gtmId: GTM_STAGE,
    },
    'spa-b2b-sk.ck7dj7qam-sikokoupe1-p1-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_SK, ...getProdUrls('sk') },
        oauthConfig: OAUTH_CONFIG_PROD,
        asmOauthConfig: OAUTH_CONFIG_ASM_PROD,
        sentry: SENTRY_CONF_PROD,
        googleMapApiKey: GOOGLE_API_KEY_PROD,
        gtmId: GTM_STAGE,
    },
    'spa-b2b-sk.ck7dj7qam-sikokoupe1-s1-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_SK, ...getCloudUrls('s1', 'sk') },
        sentry: SENTRY_CONF_STAGE,
        gtmId: GTM_STAGE,
    },
    'spa-b2b-sk.ck7dj7qam-sikokoupe1-d1-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_SK, ...getCloudUrls('d1', 'sk') },
        sentry: SENTRY_CONF_DEV1,
        gtmId: GTM_STAGE,
    },
    'spa-b2b-sk.ck7dj7qam-sikokoupe1-d2-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_SK, ...getCloudUrls('d2', 'sk') },
        sentry: SENTRY_CONF_DEV2,
        gtmId: GTM_STAGE,
    },
    'spa-b2b-sk.ck7dj7qam-sikokoupe1-d3-public.model-t.cc.commerce.ondemand.com': {
        context: { ...B2B_SK, ...getCloudUrls('d3', 'sk') },
        sentry: SENTRY_CONF_DEV3,
        gtmId: GTM_STAGE,
    },


    'velkoobchod.siko.cz': {
        context: { ...B2B_CZ, ...getProdUrls('cz') },
        gtmId: GTM_PROD,
        oauthConfig: OAUTH_CONFIG_PROD,
        asmOauthConfig: OAUTH_CONFIG_ASM_PROD,
        sentry: SENTRY_CONF_PROD,
        googleMapApiKey: GOOGLE_API_KEY_PROD,
    },
    'b2b.s1.siko.cz': {
        context: { ...B2B_CZ, ...getCloudUrls('s1', 'cz') },
        sentry: SENTRY_CONF_STAGE,
        gtmId: GTM_STAGE,
    },
    'spa-b2b.s1.siko.cz': {
        context: { ...B2B_CZ, ...getCloudUrls('s1', 'cz') },
        sentry: SENTRY_CONF_STAGE,
        gtmId: GTM_STAGE,
    },
    'b2b.d1.siko.cz': {
        context: { ...B2B_CZ, ...getCloudUrls('d1', 'cz') },
        sentry: SENTRY_CONF_DEV1,
        gtmId: GTM_STAGE,
    },
    'spa-b2b.d1.siko.cz': {
        context: { ...B2B_CZ, ...getCloudUrls('d1', 'cz') },
        sentry: SENTRY_CONF_DEV1,
        gtmId: GTM_STAGE,
    },
    'b2b.d2.siko.cz': {
        context: { ...B2B_CZ, ...getCloudUrls('d2', 'cz') },
        sentry: SENTRY_CONF_DEV2,
        gtmId: GTM_STAGE,
    },
    'spa-b2b.d2.siko.cz': {
        context: { ...B2B_CZ, ...getCloudUrls('d2', 'cz') },
        sentry: SENTRY_CONF_DEV2,
        gtmId: GTM_STAGE,
    },
    'b2b.d3.siko.cz': {
        context: { ...B2B_CZ, ...getCloudUrls('d3', 'cz') },
        sentry: SENTRY_CONF_DEV3,
        gtmId: GTM_STAGE,
    },
    'spa-b2b.d3.siko.cz': {
        context: { ...B2B_CZ, ...getCloudUrls('d3', 'cz') },
        sentry: SENTRY_CONF_DEV3,
        gtmId: GTM_STAGE,
    },


    'velkoobchod.siko.sk': {
        context: { ...B2B_SK, ...getProdUrls('sk') },
        gtmId: GTM_PROD,
        oauthConfig: OAUTH_CONFIG_PROD,
        asmOauthConfig: OAUTH_CONFIG_ASM_PROD,
        sentry: SENTRY_CONF_PROD,
        googleMapApiKey: GOOGLE_API_KEY_PROD,
    },
    'b2b.s1.siko.sk': {
        context: { ...B2B_SK, ...getCloudUrls('s1', 'sk') },
        sentry: SENTRY_CONF_STAGE,
        gtmId: GTM_STAGE,
    },
    'spa-b2b.s1.siko.sk': {
        context: { ...B2B_SK, ...getCloudUrls('s1', 'sk') },
        sentry: SENTRY_CONF_STAGE,
        gtmId: GTM_STAGE,
    },
    'b2b.d1.siko.sk': {
        context: { ...B2B_SK, ...getCloudUrls('d1', 'sk') },
        sentry: SENTRY_CONF_DEV1,
        gtmId: GTM_STAGE,
    },
    'spa-b2b.d1.siko.sk': {
        context: { ...B2B_SK, ...getCloudUrls('d1', 'sk') },
        sentry: SENTRY_CONF_DEV1,
        gtmId: GTM_STAGE,
    },
    'b2b.d2.siko.sk': {
        context: { ...B2B_SK, ...getCloudUrls('d2', 'sk') },
        sentry: SENTRY_CONF_DEV2,
        gtmId: GTM_STAGE,
    },
    'spa-b2b.d2.siko.sk': {
        context: { ...B2B_SK, ...getCloudUrls('d2', 'sk') },
        sentry: SENTRY_CONF_DEV2,
        gtmId: GTM_STAGE,
    },
    'b2b.d3.siko.sk': {
        context: { ...B2B_SK, ...getCloudUrls('d3', 'sk') },
        sentry: SENTRY_CONF_DEV3,
        gtmId: GTM_STAGE,
    },
    'spa-b2b.d3.siko.sk': {
        context: { ...B2B_SK, ...getCloudUrls('d3', 'sk') },
        sentry: SENTRY_CONF_DEV3,
        gtmId: GTM_STAGE,
    },
};
