import { Component, Input } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';

@Component({
    standalone: true,
    selector: 'app-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss'],
    imports: [
        B2bCommonModule
    ],
})
export class SikoPriceComponent {
    @Input() price?: string;
    @Input() showTaxLabel?: boolean = false;
    @Input() withTax?: boolean = false;
    @Input() MOPrice?: boolean = false;
    @Input() alignRight?: boolean = false;
    @Input() finalPriceSmall?: boolean | null = false;
    @Input() finalPriceBig?: boolean | null = false;
}
