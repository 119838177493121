import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StateWithSikoSapOrder } from '@siko/features/my-account/sap-order-detail/store/siko-sap-order-state';
import { SikoSapOrder, SikoSapOrderDeliveryNote, SikoSapOrderInvoice } from '@siko/models';
import { SikoSapOrderSelectors } from '@siko/features/my-account/sap-order-detail/store/selectors';
import { OccOrderHistoryAdapter } from '@siko/features/my-account/order-history/occ/adapters/occ-order-history.adapter';
import { UserIdService } from '@spartacus/core';
import { OccSapOrderAdapter } from '@siko/features/my-account/sap-order-detail/occ/adapters/occ-sap-order.adapter';
import { SikoOrderDocumentsService } from '@siko/shared';
import { SikoOrderHistoryService } from '@siko/features/my-account/order-history/services/order-history.service';
import { DOCUMENT_ACTION_TYPE, DOCUMENT_ICON_TYPE } from '@siko/constants';
import { SikoActionLoader } from '@siko/features/action-loader/models/action-loader.model';
import { SikoButtonComponent } from '@siko/components';
import { SikoActionLoaderService } from '@siko/features/action-loader/action-loader.service';

@Component({
    selector: 'app-order-documents-dialog',
    templateUrl: './order-documents-dialog.component.html',
    styleUrls: ['./order-documents-dialog.component.scss'],
})
export class SikoOrderDocumentsDialogComponent {
    orderCode?: string;
    sapOrderCode?: string;
    purchaseOrderNumber?: string;

    orderConfirmationIcon: DOCUMENT_ICON_TYPE = DOCUMENT_ICON_TYPE.ORDER_CONFIRMATION;
    proformaInvoiceIcon: DOCUMENT_ICON_TYPE = DOCUMENT_ICON_TYPE.PROFORMA_INVOICE;
    downloadAction: DOCUMENT_ACTION_TYPE = DOCUMENT_ACTION_TYPE.DOWNLOAD;

    sikoSapOrderInvoices$: Observable<SikoSapOrderInvoice[] | undefined> = this.store.pipe(
        select(SikoSapOrderSelectors.getSapOrderInvoices),
    );
    sikoSapOrderDeliveryNotes$: Observable<SikoSapOrderDeliveryNote[] | undefined> =
        this.store.pipe(select(SikoSapOrderSelectors.getSapOrderDeliveryNotes));
    sapOrderData$: Observable<SikoSapOrder | undefined> =
        this.store.pipe(select(SikoSapOrderSelectors.getSapOrderData));

    constructor(
        public orderHistoryService: SikoOrderHistoryService,
        private readonly sikoOrderDocumentsService: SikoOrderDocumentsService,
        private readonly occOrderHistoryAdapter: OccOrderHistoryAdapter,
        private readonly occSapOrderAdapter: OccSapOrderAdapter,
        private readonly userIdService: UserIdService,
        private readonly store: Store<StateWithSikoSapOrder>,
        private readonly actionLoaderService: SikoActionLoaderService,
    ) {}

    onPdfIconClick(code: string, isInvoice: boolean, button: SikoButtonComponent): void {
        const loader: SikoActionLoader = this.actionLoaderService.create(
            { key: `header.actionLoader.document.${isInvoice ? 'invoice' : 'deliveryNote'}` },
            button,
        );
        const finishCallback = this.actionLoaderService.removeCallback(loader);

        this.sikoOrderDocumentsService.downloadDocumentPdf(code, finishCallback);
    }
}
