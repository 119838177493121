import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SikoProductDetailGuard implements CanActivate {

    constructor(
        private readonly router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean {
        const productCode: string | null = route.paramMap.get('productCode');

        if (!productCode || productCode.toUpperCase() === productCode) {
            return true;
        }

        const slashIndex = state.url.lastIndexOf('/');
        const url = state.url.substring(0, slashIndex + 1) + productCode.toUpperCase();

        return this.router.parseUrl(url);
    }

}
