<ng-container>
  <div class='container siko-news-container'>
    <div class='row'>
      <div class='col-lg-4 col-xl-4 splitter siko-news-list-wrapper'>
        <cx-page-slot position='SikoNewsListSlot'></cx-page-slot>
      </div>
      <div class='col-lg-8 col-xl-6 siko-news-content-wrapper'>
        <cx-page-slot position='SikoNewsDynamicContentSlot'></cx-page-slot>
        <cx-page-slot position='SectionB'></cx-page-slot>
      </div>
      <div class='col-lg-12 col-xl-2 siko-news-ads-wrapper'>
        <cx-page-slot position='SikoNewsAdSlot'></cx-page-slot>
      </div>
    </div>
  </div>
</ng-container>
