import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SikoProduct } from '@siko/models';
import { B2bCommonModule } from '@siko/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-group',
    templateUrl: './product-group.component.html',
    styleUrls: ['./product-group.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule
    ]
})
export class SikoProductGroupComponent implements OnInit {
    bonusGroup?: string;

    @Input() sikoProduct?: SikoProduct;

    ngOnInit(): void {
        if (this.sikoProduct && !this.sikoProduct.discountPrice){
            this.bonusGroup = this.sikoProduct.bonusGroup ?? '';
        }
    }
}
