<ng-container *ngIf='sikoImportSummaryService.summaryObs$ | async as summary'>

  <div class='cx-import-entries-summary-status'>
    <p *ngIf='!summary.loading; else loading' [ngSwitch]='type'>
      <ng-container *ngSwitchCase='orderEntriesSource.QUICK_ORDER'>
        {{ 'cartShared.importEntriesDialog.summary.loaded' | cxTranslate: summary }}
      </ng-container>
    </p>
    <ng-template #loading>
      <p class='pt-5'>
        <cx-icon class='info' [type]='iconTypes.INFO'></cx-icon>
        <span class='siko-font-primary-semiBold siko-fs-16'>
          {{ 'cartShared.importEntriesDialog.summary.loading' | cxTranslate: summary }}
        </span>
      </p>
      <p class='siko-fs-14'>
        {{ 'cartShared.importEntriesDialog.notCloseWindow' | cxTranslate }}
      </p>
    </ng-template>
  </div>
  <div
    *ngIf='!summary.loading'
    class='cx-import-entries-summary-successes d-flex'
  >
    <div>
      <cx-icon class='success pr-4' [type]='iconTypes.SUCCESS'></cx-icon>
    </div>
    <div class='siko-font-primary-semiBold siko-fs-16'>
      {{ 'cartShared.importEntriesDialog.summary.success' | cxTranslate: summary }}
    </div>
  </div>
  <div
    *ngIf='summary.warningMessages.length > 0'
    class='cx-import-entries-summary-warnings'
  >
    <div class='list-of-warnings d-flex' *ngIf='summary.warningMessages && summary.warningMessages.length !== 0'>
      <div class='left-section'>
        <img [src]='"info-banner/warning.svg" | sikoIconAsset' alt='Warning icon'>
      </div>
      <div class='errors'>
        <h4 class='mb-8'>
          {{"cartShared.importEntriesDialog.errorsTitle" | cxTranslate }}: {{summary.warningMessages.length }}
        </h4>
        <div class='siko-fs-14' *ngFor='let message of summary.warningMessages'>
          <ng-container *ngIf='message.statusCode === "lowStock"'>
            - {{ 'cartShared.importEntriesDialog.summary.messages.' + message.statusCode | cxTranslate :
            { productName: message.productName,
              quantity: resolveAddedQuantity(message.conversion, message.quantity) | number : '1.0-2',
              quantityAdded: resolveAddedQuantity(message.conversion, message.quantityAdded) | number : '1.0-2',
              unit: message.unit} }}
          </ng-container>
          <ng-container
            *ngIf='message.statusCode === "noStock"'
          >
            - {{ 'cartShared.importEntriesDialog.summary.messages.' + message.statusCode | cxTranslate :
            { productName: message.productName} }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf='summary.errorMessages.length > 0'
    class='cx-import-entries-summary-errors'
  >
    <div class='list-of-errors d-flex' *ngIf='summary.errorMessages && summary.errorMessages.length !== 0'>
      <div class='left-section'>
        <img [src]='"info-banner/error.svg" | sikoIconAsset' alt='Error icon'>
      </div>
      <div class='errors'>
        <h4 class='mb-8'>
          {{"cartShared.importEntriesDialog.errorsTitle" | cxTranslate }}: {{summary.errorMessages.length }}
        </h4>
        <div class='siko-fs-14' *ngFor='let message of summary.errorMessages'>
          <ng-container
            *ngIf='message.quantityError else defaultError'>
            <span>- {{ 'cartShared.importEntriesDialog.quantityError' | cxTranslate  : { product: message.productCode } }}</span>
          </ng-container>
          <ng-template #defaultError>
            <span>- {{
              'cartShared.importEntriesDialog.summary.messages.' + message.statusCode
                | cxTranslate: message
              }}</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class='cx-import-entries-summary-footer'>
    <ng-container *ngIf='!summary.loading;'>
      <ng-container *ngIf="type === 'NEW_SAVED_CART'; else activeCart">
        <siko-button
          [block]='true'
          *ngIf='!summary.loading'
          text="{{ 'cartShared.importEntriesDialog.closeNewSavedCart' | cxTranslate }}"
          iconPosition='before'
          iconSvg='importCart/cart'
          (clickEvent)="close('Close Import Products Dialog')"
          mode='cart'>
          <span class='icon'></span>
        </siko-button>
      </ng-container>
      <ng-template #activeCart>
        <siko-button
          [block]='true'
          *ngIf='!summary.loading'
          text="{{ 'cartShared.importEntriesDialog.close' | cxTranslate }}"
          iconPosition='before'
          iconSvg='importCart/cart'
          (clickEvent)="close('Close Import Products Dialog')"
          mode='cart'>
        </siko-button>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
