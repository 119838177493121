import { Injectable } from '@angular/core';
import { AsmComponentService } from '@spartacus/asm/components';
import { AuthService, WindowRef } from '@spartacus/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { SikoAsmAuthCorrectionService } from '@siko/shared/services/asm-auth-correction.service';
import { ASM } from '@siko/constants';

@Injectable({
    providedIn: 'root',
})
export class SikoAsmComponentService extends AsmComponentService {

    constructor(
        protected authService: AuthService,
        protected csAgentAuthService: CsAgentAuthService,
        protected winRef: WindowRef,
        private readonly asmAuthCorrectionService: SikoAsmAuthCorrectionService,
    ) {
        super(authService, csAgentAuthService, winRef);
    }

    logoutCustomerSupportAgentAndCustomer(): void {
        this.winRef.localStorage?.setItem(ASM.ASM_AGENT, '');
        this.csAgentAuthService.logoutCustomerSupportAgent();
    }

    unload(): void {
        super.unload();

        // This will ensure that when ASM closed, correct basic client will be used when requesting token
        this.asmAuthCorrectionService.resetOAuthConfig();
    }

}
