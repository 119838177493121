import { Injectable } from '@angular/core';
import {
    BasePageMetaResolver,
    CmsService, Page,
    PageType, TranslationService
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutPageMetaResolver } from '@spartacus/checkout/base/core';

@Injectable({
    providedIn: 'root'
})
export class SikoCheckoutPageMetaResolver extends CheckoutPageMetaResolver {

    constructor(
        protected translation: TranslationService,
        protected activeCartService: ActiveCartFacade,
        protected basePageMetaResolver: BasePageMetaResolver,
        protected cmsService: CmsService
    ) {
        super(translation, activeCartService, basePageMetaResolver);
        this.pageType = PageType.CONTENT_PAGE;
        this.pageTemplate = 'MultiStepCheckoutSummaryPageTemplate';
    }

    resolveTitle(): Observable<string> {
        return this.translation.translate('siko.checkoutPageTitle');
    }

    getScore(page: Page): number {
        if (page.pageId?.toLowerCase === 'CheckoutPaymentType'.toLowerCase || page.pageId?.toLowerCase === 'checkoutShippingAddress'.toLowerCase || page.pageId?.toLowerCase === 'checkoutReviewOrder'.toLowerCase) {
            return super.getScore(page) + 1;
        }

        return super.getScore(page);
    }

}
