import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SikoFileUploadService {

    uploadFileName$: BehaviorSubject<string> = new BehaviorSubject('');

    getUploadFileName(): Observable<string> {
        return this.uploadFileName$.asObservable();
    }

    setUploadFileName(value: string): void {
        this.uploadFileName$.next(value);
    }

}
