import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SikoNewsSelectors } from '@siko/features/news/store/selectors';
import { StateWithSikoNews } from '@siko/features/news/store/siko-news-state';
import { RoutingService } from '@spartacus/core';
import { Router } from '@angular/router';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-news-link',
    templateUrl: './news-link.component.html',
    styleUrls: ['./news-link.component.scss']
})
export class SikoNewsLinkComponent {

    constructor(
      public routingService: RoutingService,
      readonly store: Store<StateWithSikoNews>,
      readonly router: Router,
    ) {}

    unseenMessagesCount$: Observable<number> = this.store.pipe(
        select(SikoNewsSelectors.getSikoUnseenNewsCount)
    );

    navigateToBlogArticles(): void {
        void this.router.navigate(['/blog'], {
            queryParams: { category: 'all_articles' },
            replaceUrl: false
        });
    }

}
