import { Injectable } from '@angular/core';
import {
    GlobalMessageService,
    GlobalMessageType,
    HttpErrorModel, LoggerService,
    normalizeHttpError,
    RoutingService,
    UserIdService,
} from '@spartacus/core';
import { catchError, concatMap, finalize, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CartActions, CartAdapter, getCartIdByUserId, StateWithMultiCart } from '@spartacus/cart/base/core';
import {
    ActiveCartFacade,
    Cart,
    CartModification,
    MultiCartFacade,
    OrderEntry,
    ProductImportStatus,
} from '@spartacus/cart/base/root';
import { OccBopisAdapter } from '@siko/features/cart';
import { BehaviorSubject, from, NEVER, Observable, of, Subject, Subscription } from 'rxjs';
import { SikoCart, SikoCartModification, SikoOrderEntry } from '@siko/models';
import {
    SikoAddedToCartDialogComponent,
    SikoCartEntryType,
} from '@siko/features/shared-components/added-to-cart-dialog/components/added-to-cart-dialog/added-to-cart-dialog.component';
import { SikoImportSummaryService } from '@siko/features/cart/services/siko-import-summary.service';
import { SikoDialogService, SikoTrackingUtils, SikoUtils } from '@siko/shared';
import { SikoRemoveCartEntryComponent } from '@siko/features/cart/remove-cart-entry/remove-cart-entry.component';
import { generateImportBatchId, HTTP_ERROR } from '@siko/constants';
import { SikoCartAdapter } from '@siko/features/cart/occ/adapters/cart.adapter';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export enum ADD_TO_CART_ENTRY_STATUS {
    LOW_STOCK = 'lowStock',
    NO_STOCK = 'noStock',
    SUCCESS = 'success',
    CANNOT_COMBINE_BP_WITH_REGULAR = 'cannotCombineBonusPointsWithRegular',
    INSUFFICIENT_BONUS_PROGRAM_POINTS = 'insufficientBonusProgramPoints',
    LOW_BONUS_PROGRAM_POINTS = 'lowBonusProgramPoints',
    CANNOT_COMBINE_DIFFERENT_BONUS_POINTS = 'cannotCombineDifferentBonusPoints'
}

export interface UpdateCartPayload {
    cartId: string;
    productCode: string;
    quantity: number;
    storeId: string;
}

export interface SikoAddToCartSuccessPayload {
    userId: string;
    cartId: string;
    cartModification: CartModification;
    sourceForGTM: string;
}

@Injectable({
    providedIn: 'root',
})
export class SikoActiveCartService {

    addToCartSuccessSubject$ = new Subject<SikoAddToCartSuccessPayload>();
    addToCartFailureSubject$ = new Subject<HttpErrorModel | undefined>();
    updatePayloadSubject$ = new Subject<UpdateCartPayload>();
    updateEntrySubject$ = new Subject<CartModification>();

    updateCartSubscription?: Subscription;

    private readonly recalculating = new BehaviorSubject(false);
    private readonly recalculatingItem = new BehaviorSubject(-1);
    private readonly recalculatingFailed = new BehaviorSubject(false);
    private readonly restoringEntries: BehaviorSubject<OrderEntry[]> = new BehaviorSubject<OrderEntry[]>([]);

    get isRecalculating$(): Observable<boolean> {
        return this.recalculating.asObservable();
    }

    get recalculatingItem$(): Observable<any> {
        return this.recalculatingItem.asObservable();
    }

    get getRecalculatingFailed$(): Observable<boolean> {
        return this.recalculatingFailed.asObservable();
    }

    setRestoringEntries(entries: OrderEntry[]) {
        this.restoringEntries.next(entries);
    }

    setRecalculatingFailed(value: boolean): void {
        this.recalculatingFailed.next(value);
    }

    setRecalculatingItem(index: number) {
        this.recalculatingItem.next(index);
    }

    setRecalculating(event: boolean) {
        this.recalculating.next(event);
    }

    subscription: Subscription = this.updatePayloadSubject$.pipe(
        switchMap((payload: UpdateCartPayload) => this.occBopisAdapter.updateCartAmount(
            payload.cartId,
            payload.productCode,
            payload.quantity,
            payload.storeId,
        )
            .pipe(
                catchError(error => {

                    const normalizedError: HttpErrorModel | undefined = normalizeHttpError(error, this.logger);

                    if (normalizedError?.details && normalizedError.details[0] as unknown as HTTP_ERROR === HTTP_ERROR.ADD_TO_CART_QUANTITY_RESTRICTION) {
                        this.globalMessageService.add(
                            {
                                key: 'cartShared.updateEntryFailed',
                            },
                            GlobalMessageType.MSG_TYPE_ERROR,
                        );
                    }
                    else {
                        this.globalMessageService.add(
                            {
                                key: 'cartShared.updateFailedMessage',
                            },
                            GlobalMessageType.MSG_TYPE_ERROR,
                        );
                    }

                    return NEVER;
                }),
            )),
        withLatestFrom(
            this.userIdService.getUserId(),
            this.activeCartService.getActiveCartId(),
        ),
    )
        .subscribe(([data, userId, cartId]) => {
            this.store.dispatch(new CartActions.LoadCart({
                userId,
                cartId,
            }));
            this.updateEntrySubject$.next(data);
            this.generateResultMessageAndTracking(data);

        });

    constructor(
        protected store: Store<StateWithMultiCart>,
        protected multiCartService: MultiCartFacade,
        protected userIdService: UserIdService,
        readonly trackingUtils: SikoTrackingUtils,
        readonly sikoImportSummaryService: SikoImportSummaryService,
        readonly occBopisAdapter: OccBopisAdapter,
        readonly sikoCartAdapter: SikoCartAdapter,
        readonly globalMessageService: GlobalMessageService,
        readonly activeCartService: ActiveCartFacade,
        readonly routingService: RoutingService,
        readonly router: Router,
        readonly dialogService: SikoDialogService,
        readonly logger: LoggerService,
    ) {
    }

    updateCartAmount(
        cartId: string,
        productCode: string,
        quantity: number,
        storeId: string,
    ): void {
        const updateCartPayload = {
            cartId,
            productCode,
            quantity,
            storeId,
        };

        this.updatePayloadSubject$.next(updateCartPayload);
    }

    setPartialDelivery(cartId: string, value: boolean, entryNumber: number): void {
        this.sikoCartAdapter.setPartialDelivery(cartId, value, entryNumber).subscribe(data => {
            this.store.dispatch(new CartActions.LoadCartSuccess({
                userId: 'current',
                cartId: cartId,
                cart: data,
            }));

            }, error => {
            this.globalMessageService.add(
                { key: 'httpHandlers.cart.changePartialDeliveryFailed' },
                GlobalMessageType.MSG_TYPE_ERROR,
            );
        });
    }

    canAddToCart(): boolean {
        const activeDialogWindow: NgbModalRef | null = this.dialogService.getActiveModal();

        if (activeDialogWindow === null) {
            return true;
        }
        else {
            return !(activeDialogWindow?.componentInstance instanceof SikoAddedToCartDialogComponent);
        }
    }

    extractSikoEntries(cart: SikoCart) {
        let sikoInactiveEntries: SikoOrderEntry[] | undefined;
        let sikoActiveEntries: SikoOrderEntry[] | undefined;

        // Update sikoInactiveEntries
        if (cart.undefinedOrderGroups && cart.undefinedOrderGroups.length > 0) {
            if (cart.undefinedOrderGroups[0].entries) {
                sikoInactiveEntries = cart.undefinedOrderGroups[0].entries;
            }
        }

        // Update sikoActiveEntries
        if (cart.deliveryOrderGroups && cart.deliveryOrderGroups.length > 0) {
            if (cart.deliveryOrderGroups[0].entries) {
                sikoActiveEntries = cart.deliveryOrderGroups[0].entries;
            }
        }
        else if (cart.pickupOrderGroups && cart.pickupOrderGroups.length > 0) {
            if (cart.pickupOrderGroups[0].entries) {
                sikoActiveEntries = cart.pickupOrderGroups[0].entries;
            }
        }

        return {
            sikoInactiveEntries,
            sikoActiveEntries
        };
    }

    deleteCart(): void {
        let cart: SikoCart = {};
        let userId: string = '';

        this.activeCartService
            .getActive()
            .subscribe((activeCart: Cart) => cart = activeCart)
            .unsubscribe();

        this.userIdService.getUserId()
            .subscribe(data => userId = data)
            .unsubscribe();

        if (cart && cart.entries && cart.code) {
            this.multiCartService.deleteCart(cart.code, userId);
            this.trackingUtils.pushModifyCartEvent(cart.entries, undefined, 'removeFromCart');

            if (this.router.url.includes('checkout')) {
                void this.routingService.goByUrl('/cart');
            }
        }
    }

    sikoUpdateEntry(
        userId: string,
        cartId: string,
        productCode: string,
        quantity: number): void {
        this.sikoCartAdapter.update(
            userId,
            cartId,
            productCode,
            quantity,
        )
            .pipe(
                withLatestFrom(
                    this.userIdService.getUserId(),
                    this.activeCartService.getActiveCartId(),
                ), take(1),
            )
            .subscribe(([data, userId, cartId]) => {
                this.store.dispatch(new CartActions.LoadCart({
                    userId,
                    cartId,
                }));

                this.generateResultMessageAndTracking(data);
                this.updateEntrySubject$.next(data);
            }, error => {
                this.globalMessageService.add(
                    { key: 'httpHandlers.cart.reloadCartRequired' },
                    GlobalMessageType.MSG_TYPE_ERROR,
                );
            });
    }

    generateResultMessageAndTracking(data: SikoCartModification): void {

        if (data.quantityAdded) {
            if (data.quantityAdded > 0) {
                this.trackingUtils.pushModifyCartEvent([data.entry], Math.abs(data.quantityAdded), 'addToCart');
            }
            else {
                this.trackingUtils.pushModifyCartEvent([data.entry], Math.abs(data.quantityAdded), 'removeFromCart');
            }
        }

        if (data.statusCode === 'success') {
            this.globalMessageService.add(
                {
                    key: 'cartShared.updateEntrySuccess',
                },
                GlobalMessageType.MSG_TYPE_INFO,
            );
        }
        else if (data.statusCode === 'maxOrderQuantityExceeded') {
            this.globalMessageService.add(
                {
                    key: 'cartShared.updateEntryFailed',
                },
                GlobalMessageType.MSG_TYPE_WARNING,
            );
        }
        else {
            const conversion: number = data.entry?.product ? Number(data.entry.product.unitData?.conversion ?? 1) : 1;
            const quantity: number = data.preferredQuantity ?? 0;
            const quantityAdded: number = data.entry?.quantity ?? 0;

            this.globalMessageService.add(
                {
                    key: data.statusCode === 'insufficientBonusProgramPoints' ? 'cartShared.lowBonusProgramPoints' : 'cartShared.addedToCartWithReducedAmount',
                    params: {
                        product: data.entry?.product?.name,
                        quantity: SikoUtils.sikoRound(quantity * conversion, 3),
                        addedQuantity: SikoUtils.sikoRound(quantityAdded * conversion, 3),
                        unit: SikoUtils.formatUnitSymbol(data.entry?.product?.unitData),
                    },
                },
                GlobalMessageType.MSG_TYPE_WARNING,
            );

        }
    }

    addEntriesToFavoriteStore(cartEntries: OrderEntry[]): void {
        const entriesToAdd: any[] = [];

        cartEntries.forEach((value, index) => {
            if (value.quantity && value.quantity > 0) {
                entriesToAdd.push({
                    productCode: value.product?.code,
                    quantity: value.quantity,
                });
            }
            else {
                this.sikoImportSummaryService.addQuantityError(value.product?.code ? value.product.code : '');
            }
        });

        this.activeCartService.requireLoadedCart()
            .pipe(withLatestFrom(this.userIdService.getUserId()))
            .subscribe(([cartState, userId]) => {
                if (cartState.code) {
                    const cartId = getCartIdByUserId(cartState, userId);
                    const batchKey = generateImportBatchId();

                    from(entriesToAdd)
                        .pipe(
                            concatMap((product: any) => this.occBopisAdapter.importCart(
                                userId,
                                cartId,
                                product.quantity,
                                product.productCode,
                                `${batchKey}`,
                            )),
                            finalize(() => {
                                this.sikoImportSummaryService.summary$.next({
                                    ...this.sikoImportSummaryService.summary$.value,
                                    loading: false,
                                });
                            }),
                        )
                        .subscribe(data => {
                                this.trackingUtils.pushModifyCartEvent([data.entry], data.quantityAdded, 'addToCart', 'import');
                                this.sikoImportSummaryService.mapMessages(data);
                            },
                            error => {

                                if (error.error.errors[0].type === 'Sikob2bCartNotEmptyError') {
                                    this.globalMessageService.add({ key: 'httpHandlers.cart.reloadCartRequired' }, GlobalMessageType.MSG_TYPE_ERROR);
                                    this.dialogService.closeActiveModal('Cart is not empty');
                                    return;
                                }

                                const errorMessage = {
                                    productCode: 'deliveryError',
                                    statusCode: ProductImportStatus.UNKNOWN_ERROR,
                                };

                                this.sikoImportSummaryService.summary$.next({
                                    ...this.sikoImportSummaryService.summary$.value,
                                    count: this.sikoImportSummaryService.summary$.value.count + 1,
                                    errorMessages: [...this.sikoImportSummaryService.summary$.value.errorMessages, errorMessage],
                                });
                            });
                }
            });
    }

    addEntryFavourite(
        productCode: string,
        quantity: number,
        sourceForGTM: string,
    ): void {
        this.activeCartService.requireLoadedCart()
            .pipe(withLatestFrom(this.userIdService.getUserId()))
            .subscribe(([cartState, userId]) => {
                if (cartState) {
                    const cartId = getCartIdByUserId(cartState, userId);

                    this.occBopisAdapter.addToCartForFavouriteStorePickUp(
                        cartId,
                        productCode,
                        quantity,
                    )
                        .subscribe((cartModification: CartModification) => {

                                const successPayload: SikoAddToCartSuccessPayload = {
                                    userId,
                                    cartId,
                                    cartModification,
                                    sourceForGTM,
                                };
                                this.addToCartSuccessSubject$.next(successPayload);
                            },
                            (error: HttpErrorModel | undefined) => {
                                this.addToCartFailureSubject$.next(error);
                            });
                }
            }, error => {
                this.store.dispatch(new CartActions.CartAddEntryFail(error));
            });
    }

    openModal(
        cart: CartModification | undefined,
        httpError: string | undefined = undefined,
    ): void {
        let modalSize = 'lg';

        if (httpError) {
            modalSize = 'md';
        }

        const modalRef: NgbModalRef = this.dialogService.open(SikoAddedToCartDialogComponent, {
            windowClass: 'siko-dialog-window',
            size: modalSize,
        });

        const modalInstance: SikoAddedToCartDialogComponent =
            modalRef.componentInstance as SikoAddedToCartDialogComponent;

        modalInstance.cartModification = cart;

        if (cart?.entry) {
            modalInstance.entry$ = of(cart.entry);
        }

        modalInstance.cart$ = this.activeCartService.getActive();
        modalInstance.addToCartStatus = cart?.statusCode;

        if (httpError) {
            modalInstance.httpError = httpError;
        }
    }

    addEntry(productCode: string,
             quantity: number,
             bonusPoints = 0,
             sourceForGTM = ''): void {
        this.addEntryForDelivery(productCode, quantity, bonusPoints, sourceForGTM);
    }

    addEntryForDelivery(
        productCode: string,
        quantity: number,
        bonusPoints = 0,
        sourceForGTM = '',
    ): void {
        this.activeCartService.requireLoadedCart()
            .pipe(withLatestFrom(this.userIdService.getUserId()))
            .subscribe(([cartState, userId]) => {
                if (cartState) {
                    const cartId = getCartIdByUserId(cartState, userId);

                    this.occBopisAdapter.addToCart(
                        userId,
                        cartId,
                        quantity,
                        productCode,
                        bonusPoints,
                    )
                        .subscribe((cartModification: CartModification) => {
                                const successPayload: SikoAddToCartSuccessPayload = {
                                    userId,
                                    cartId,
                                    cartModification,
                                    sourceForGTM,
                                };
                                this.addToCartSuccessSubject$.next(successPayload);
                            },
                            (error: HttpErrorModel | undefined) => {
                                this.addToCartFailureSubject$.next(error);
                            });
                }
            }, error => {
                this.store.dispatch(new CartActions.CartAddEntryFail(error));
            });
    }

    addEntryForPickupInSelected(
        productCode: string,
        quantity: number,
        storeId: string,
        sourceForGTM = '',
    ): void {
        this.activeCartService.requireLoadedCart()
            .pipe(withLatestFrom(this.userIdService.getUserId()))
            .subscribe(([cartState, userId]) => {
                if (cartState) {
                    const cartId = getCartIdByUserId(cartState, userId);

                    this.occBopisAdapter.addToCartForSelectedStorePickUp(
                        cartId,
                        productCode,
                        quantity,
                        storeId,
                    )
                        .subscribe((cartModification: CartModification) => {

                                const successPayload: SikoAddToCartSuccessPayload = {
                                    userId,
                                    cartId,
                                    cartModification,
                                    sourceForGTM,
                                };
                                this.addToCartSuccessSubject$.next(successPayload);
                            },
                            (error: HttpErrorModel | undefined) => {
                                this.addToCartFailureSubject$.next(error);
                            });
                }
            }, error => {
                this.store.dispatch(new CartActions.CartAddEntryFail(error));
            });
    }

    openAddingToCartModal(
        quantity: number,
        productCode: string,
        cartEntryType: SikoCartEntryType,
        sourceForGTM: string = '',
        bonusPoints: number = 0,
        selectedStoreId: string | undefined = undefined,
    ): void {
        const modalRef: NgbModalRef = this.dialogService.open(SikoAddedToCartDialogComponent, {
            windowClass: 'siko-dialog-window',
            size: 'lg',
        });

        const modalInstance: SikoAddedToCartDialogComponent = modalRef.componentInstance as SikoAddedToCartDialogComponent;

        modalInstance.quantityToAdd = quantity;
        modalInstance.productCode = productCode;
        modalInstance.addToCartType = cartEntryType;
        modalInstance.sourceForGTM = sourceForGTM;
        modalInstance.bonusPoints = bonusPoints;
        modalInstance.selectedStoreId = selectedStoreId ?? undefined;
    }

    openDeleteModal(index: number | undefined, productCode: string | undefined): void {
        const modalRef: NgbModalRef = this.dialogService.open(SikoRemoveCartEntryComponent, {
            windowClass: 'siko-dialog-window',
            size: 'sm',
        });
        const modalInstance: SikoRemoveCartEntryComponent = modalRef.componentInstance as SikoRemoveCartEntryComponent;

        modalInstance.index = index;
        modalInstance.modalIsOpen = true;
        modalInstance.productCode = productCode;
    }

    checkRestoredCarts(entries: SikoOrderEntry[]): void {
        const result = this.restoringEntries.getValue()
            .filter(function(o1: SikoOrderEntry) {
                return !entries.some(function(o2: SikoOrderEntry) {
                    return o1.product?.code === o2.product?.code;
                });
            });

        if (result.length > 0) {
            this.globalMessageService.add({
                key: result.length > 1 ? 'sikoSavedCart.nonRestoredEntries' : 'sikoSavedCart.nonRestoredEntry',
                params: {
                    entries: result.map(i => i.product?.code)
                        .join(','),
                },
            }, GlobalMessageType.MSG_TYPE_WARNING);
        }

        this.restoringEntries.next([]);
    }

}
