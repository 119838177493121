import { createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import {
    SIKO_ACTION_LOADER_FEATURE,
    SikoActionLoaderState,
    StateWithSikoActionLoader,
} from '@siko/features/action-loader/store/action-loader-state';

export const getSikoActionLoaderState: MemoizedSelector<
    StateWithSikoActionLoader,
    SikoActionLoaderState
> = createFeatureSelector<SikoActionLoaderState>(SIKO_ACTION_LOADER_FEATURE);
