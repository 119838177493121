import { Injectable } from '@angular/core';
import { AsmAuthHttpHeaderService } from '@spartacus/asm/root';

@Injectable({
    providedIn: 'root',
})
export class SikoAuthHttpHeaderService extends AsmAuthHttpHeaderService {

    handleExpiredRefreshToken(): void {
        this.authRedirectService.saveCurrentNavigationUrl();

        this.authService.coreLogout()
            .finally(() => {
                this.routingService.go({ cxRoute: 'login' });
            });
    }

}
