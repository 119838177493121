import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
    AuthService,
    EventService,
    isNotNullable,
    isNotUndefined,
    LoginEvent,
    LogoutEvent,
    RoutingService,
    SiteContextActions,
    User,
    WindowRef,
} from '@spartacus/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, first, switchMap } from 'rxjs/operators';
import { SikoTrackingUtils } from '@siko/shared/utils/siko-tracking-utils';
import * as Sentry from '@sentry/angular-ivy';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { interval, Observable, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { SikoExponeaService } from '@siko/shared/utils/siko-exponea.service';
import { SikoDialogService } from '@siko/shared';
import { SikoUserActivityService } from '@siko/shared/services/user-activity.service';
import { SikoUser } from '@siko/models';
import dayjs from 'dayjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

    title = 'b2b';
    currentUrl = '';
    isUserLogged = false;
    sapCustomerGroup = 'none';
    loginEventListener$ = this.eventService.get(LoginEvent)
        .subscribe(() => {
            this.userProfileFacade.get()
                .pipe(
                    filter(isNotNullable),
                    first()
                )
                .subscribe((user: User) => {
                    Sentry.setUser({ email: user.uid });
                    this.exponeaService.identify(user.uid);
                    this.checkUsersInActivity();
                });
        });

    logoutEventListener$ = this.eventService.get(LogoutEvent)
        .subscribe(() => {
            Sentry.setUser(null);

            void this.routingService.go('/login');
        });

    intervalSubscription?: Subscription;
    routeIsChanged: boolean = false;
    loggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
    private readonly inactivityLogoutTimeout = 3600; // 60 minutes in seconds
    private readonly timeoutRepeat = 60 * 1000; // 1 minute in milliseconds

    constructor(
        readonly winRef: WindowRef,
        readonly dialogService: SikoDialogService,
        readonly actions: Actions,
        readonly userProfileFacade: UserProfileFacade,
        readonly authService: AuthService,
        readonly trackingUtils: SikoTrackingUtils,
        readonly router: Router,
        readonly eventService: EventService,
        readonly exponeaService: SikoExponeaService,
        private readonly userActivityService: SikoUserActivityService,
        readonly routingService: RoutingService,
    ) { }

    changeLanguageSubscription: Subscription = this.actions.pipe(
        ofType(SiteContextActions.LANGUAGE_CHANGE)
    )
        .subscribe(() => {
            this.trackingUtils.pushSikoPageEvent(this.isUserLogged, this.currentUrl, this.sapCustomerGroup);
        });

    ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        )
            .subscribe((event) => {
                this.routeIsChanged = true;
                this.authService.isUserLoggedIn()
                    .subscribe(data => {
                        this.isUserLogged = data;
                    })
                    .unsubscribe();

                if (event instanceof RouterEvent) {
                    this.currentUrl = event.url;
                    this.trackingUtils.pushResetEvent();

                    this.userProfileFacade.get()
                        .pipe(
                            filter(isNotUndefined),
                        )
                        .subscribe(data => {
                            if (event.url.includes('login')) {
                                return;
                            }

                            const sikoUser: SikoUser | undefined = data;

                            if (sikoUser.orgUnit?.sikoSapCustomerGroup) {
                                this.sapCustomerGroup = sikoUser.orgUnit.sikoSapCustomerGroup;
                            }

                            if (this.routeIsChanged) {
                                this.trackingUtils.pushSikoPageEvent(this.isUserLogged, event.url, this.sapCustomerGroup);
                                this.routeIsChanged = false;
                            }
                        });

                    if (event.url.includes('login')){
                        this.trackingUtils.pushSikoPageEvent(this.isUserLogged, event.url, 'none');
                        this.routeIsChanged = false;
                    }

                    this.dialogService.dismissAll('Change of route');
                }
            });
    }

    checkUsersInActivity(): void {
        this.intervalSubscription = interval(this.timeoutRepeat)
            .pipe(
                switchMap(() => this.loggedIn$),
                filter((value: boolean) => value),
            )
            .subscribe(() => {
                const currentDate = dayjs();
                const lastActiveDate = this.userActivityService.getLastActiveDate();

                if (currentDate.diff(lastActiveDate, 'second') > this.inactivityLogoutTimeout) {
                    this.intervalSubscription?.unsubscribe();
                    void this.authService.coreLogout();
                }
            });
    }

    ngOnDestroy(): void {
        this.loginEventListener$.unsubscribe();
        this.logoutEventListener$.unsubscribe();
        this.changeLanguageSubscription.unsubscribe();
    }

}
