import { StateUtils } from '@spartacus/core';
import { SIKO_NEWS_DATA } from '@siko/features/news/store/reducers';

export const LOAD_UNSEEN_NEWS_COUNT = '[News] Load Unseen News Count';
export const LOAD_UNSEEN_NEWS_COUNT_FAIL = '[News] Load Unseen News Count Fail';
export const LOAD_UNSEEN_NEWS_COUNT_SUCCESS = '[News] Load Unseen News Count Success';

export class LoadUnseenNewsCount extends StateUtils.LoaderLoadAction {
    readonly type = LOAD_UNSEEN_NEWS_COUNT;

    constructor(){
        super(SIKO_NEWS_DATA);
    }
}

export class LoadUnseenNewsCountFail extends StateUtils.LoaderFailAction {
    readonly type = LOAD_UNSEEN_NEWS_COUNT_FAIL;

    constructor(public payload: any){
        super(SIKO_NEWS_DATA);
    }
}

export class LoadUnseenNewsCountSuccess extends StateUtils.LoaderSuccessAction{
    readonly type = LOAD_UNSEEN_NEWS_COUNT_SUCCESS;

    constructor(public payload: number) {
        super(SIKO_NEWS_DATA);
    }
}

export type SikoLoadUnseenNewsAction =
  | LoadUnseenNewsCount
  | LoadUnseenNewsCountFail
  | LoadUnseenNewsCountSuccess;
