import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { SikoProduct } from '@siko/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-documents-tab',
    templateUrl: './product-documents-tab.component.html',
    styleUrls: ['./product-documents-tab.component.scss']
})
export class SikoProductDocumentsTabComponent {

    @Input() displayHeader = true;

    sikoProduct$: Observable<SikoProduct | null> = this.currentProductService.getProduct();

    constructor(
    private readonly currentProductService: CurrentProductService,
    ) {
    }

}
