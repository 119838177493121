import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ProductActions, ProductScope, StateWithProduct, Price, LanguageService } from '@spartacus/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PRODUCT_DISPONIBILITY } from '@siko/constants';
import { SikoProduct } from '@siko/models';
import { SikoOccProductAdapter } from '@siko/features/product/occ/adapters/occ-product.adapter';
import { B2bCommonModule } from '@siko/shared';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';
import { SikoProductGroupComponent } from '@siko/features/shared-components/product-group/product-group.component';
import { SikoPriceComponent } from '@siko/features/shared-components/price/price.component';
import { SikoBonusPointsInfoModule } from '@siko/features/shared-components/bonus-points-info/bonus-points-info.module';
import { SikoAutoUnsubscribe } from '@siko/common';

@SikoAutoUnsubscribe(['updatePriceSubscription'])
@Component({
    selector: 'app-product-price',
    templateUrl: './product-price.component.html',
    styleUrls: ['./product-price.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoProductUnitComponent,
        SikoProductGroupComponent,
        SikoPriceComponent,
        SikoBonusPointsInfoModule,
    ],
})
export class SikoProductPriceComponent implements OnChanges {

    productWithConversionRate: string = PRODUCT_DISPONIBILITY.PRODUCT_WITH_CONVERSION_RATE;
    isDiscounted = false;
    updatePriceSuccess = new BehaviorSubject<boolean>(false);
    updatePriceFailed = new BehaviorSubject<boolean>(false);
    isUpdating = new BehaviorSubject<boolean>(false);
    sikoProduct?: SikoProduct;
    updatePriceSubscription?: Subscription;

    @Input() set product(input: SikoProduct) {
        this.sikoProduct = input;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.product.previousValue?.code !== changes.product.currentValue?.code) {
            this.updatePriceSuccess.next(false);
            this.updatePriceFailed.next(false);
            this.isUpdating.next(false);

            this.isDiscounted = false;

            if (changes.product.currentValue.discountPrice?.value) {
                this.isDiscounted = changes.product.currentValue.discountPrice.value !== changes.product.currentValue.basePrice?.value;
            }
        }
    }

    constructor(
        private readonly languageService: LanguageService,
        private readonly occSikoProductAdapter: SikoOccProductAdapter,
        private readonly store: Store<StateWithProduct>,
        private readonly ref: ChangeDetectorRef,
    ) {
    }

    updateProductPrice(): void {
        this.isUpdating.next(true);

        const productCode = this.sikoProduct?.code;

        this.updatePriceSubscription = this.occSikoProductAdapter.updateProductPrice(productCode)
            .subscribe(product => {
                    this.store.dispatch(
                        new ProductActions.LoadProductSuccess({
                                code: product.code,
                                ...product,
                            },
                            ProductScope.DETAILS),
                    );
                    this.updatePriceSuccess.next(true);
                    this.isUpdating.next(false);
                    this.ref.detectChanges();
                },
                error => {
                    this.isUpdating.next(false);
                    this.updatePriceSuccess.next(false);
                    this.updatePriceFailed.next(true);
                    this.ref.detectChanges();
                });
    }

    constructPriceHtml(price: Price, animated: boolean) {
        let language = '';

        this.languageService.getActive()
            .subscribe(data => {
                language = data;
            })
            .unsubscribe();

        const isDecimal = price.value && price.value % 1 != 0;
        const valueString = price.value?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        if (valueString) {
            let mainPrice = isDecimal ? valueString.substring(0, valueString.indexOf('.')) : valueString;
            mainPrice = language === 'en' ? mainPrice.replace(' ', ',') : mainPrice;

            const decimalSeparator = language === 'en' ? '.' : ',';
            const decimal = isDecimal ? `${decimalSeparator}${valueString.split('.')
                .pop()}` : '';
            const currency = price.currencyIso === 'CZK' ? 'Kč' : '€';

            const animatedClass = animated ? 'animated-opacity' : '';

            const defaultPrice = `<span class='title h1 ${animatedClass}'>${mainPrice}</span>
              <span class='title h2'><sub class='${animatedClass}' *ngIf='${decimal}'>${decimal}</sub>
              <span>&nbsp;${currency}</span></span>
                `;

            const enPrice = `<span class='title h1 ${animatedClass}'>${currency}${mainPrice}</span>
              <span class='title h2'><sub class='${animatedClass}' *ngIf='${decimal}'>${decimal}</sub>
               </span>
                `;

            return language === 'en' ? enPrice : defaultPrice;
        }


        return '';
    }

}
