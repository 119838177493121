import {
    ChangeDetectionStrategy,
    Component
} from '@angular/core';
import { ICON_TYPE, ViewModes } from '@spartacus/storefront';
import { SikoB2bPlpService } from '@siko/features/product/product-listing/siko-b2b-plp.service';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-product-view-switcher',
    templateUrl: './product-view-switcher.component.html',
    styleUrls: ['./product-view-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoProductViewSwitcherComponent  {
    ViewModes = ViewModes;
    iconTypes = ICON_TYPE;

    constructor(
        private readonly plpCommunication: SikoB2bPlpService
    ) {}

    changeMode(viewMode: string): void {
        this.plpCommunication.setViewMode(viewMode);
    }

    get viewMode$(): Observable<string> {
        return this.plpCommunication.viewMode$;
    }
}
