import { StateUtils } from '@spartacus/core';
import { SikoNewsData } from '@siko/models';

export const SIKO_NEWS_FEATURE = 'sikoNews';

export interface StateWithSikoNews {
  [SIKO_NEWS_FEATURE]: SikoNewsState;
}

export interface SikoNewsState {
    news: StateUtils.LoaderState<SikoDefaultNewsState>;
    unreadNews: StateUtils.LoaderState<SikoUnseenNewsState>;
}

export interface SikoDefaultNewsState {
  newsData: SikoNewsData;
  selectedNews?: any;
  currentPage: number;
}

export interface SikoUnseenNewsState {
  unseenNewsCount?: number;
}
