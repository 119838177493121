import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { ProductListComponentService } from '@spartacus/storefront';
import { Sikob2bSerpService } from '@siko/features/product/search/siko-serp.service';
import { SikoProductSearchPage } from '@siko/models';
import { SikoAutoUnsubscribe } from '@siko/common';
import { Actions, ofType } from '@ngrx/effects';
import { ProductActions } from '@spartacus/core';
import { SearchProducts } from '@spartacus/core/src/product/store/actions/product-search.action';
import { SikoB2bPlpService } from '@siko/features/product/product-listing/siko-b2b-plp.service';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { B2bCommonModule } from '@siko/shared';
import { SikoSkeletonComponent } from '@siko/features/shared-components/skeleton/skeleton.component';
import { SikoPageSlotModule } from '@siko/features/shared-components/page-slot/page-slot.module';


@SikoAutoUnsubscribe([
    'modelSubscription',
    'scrollerSubscription',
    'searchProductsSubscription',
    'searchProductsFinishedSubscription',
])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-siko-search-outlet',
    templateUrl: './siko-search-outlet.component.html',
    styleUrls: ['./siko-search-outlet.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoSkeletonComponent,
        SikoPageSlotModule,
    ],
})
export class SikoSearchOutletComponent implements OnInit, OnDestroy {

    @ViewChild('topPage') el?: ElementRef;

    private readonly isScrollingUp$ = new BehaviorSubject(false);
    lastScroll = 0;
    isEmptySearchResult = true;

    model$: Observable<SikoProductSearchPage> =
        this.productListComponent.model$;

    modelSubscription?: Subscription;
    scrollerSubscription?: Subscription;
    searchProductsSubscription?: Subscription;
    searchProductsFinishedSubscription?: Subscription;

    constructor(
        public readonly actions: Actions,
        public readonly sikoPlpService: SikoB2bPlpService,
        private readonly sikoSerpService: Sikob2bSerpService,
        private readonly productListComponent: ProductListComponentService,
    ) {
    }

    get isScrollingUp(): Observable<boolean> {
        return this.isScrollingUp$.asObservable();
    }

    ngOnInit(): void {

        this.modelSubscription = this.model$.subscribe(
            res => {
                this.isEmptySearchResult =
                    !res.sikoCategoriesCount &&
                    !res.sikoBrandsCount &&
                    !res.sikoStoresCount &&
                    !res.sikoSeriesCount &&
                    res.products?.length == 0;

                if (res.freeTextSearch) {
                    this.sikoSerpService.setIsShowStickyMenu(res.freeTextSearch);
                }
            },
        );

        this.isScrollingUp$.next(false);

        this.scrollerSubscription = fromEvent(window, 'scroll')
            .pipe()
            .subscribe(() => {
                this.dealWithScroll(window.scrollY);
            });

        this.searchProductsSubscription = this.actions.pipe(ofType(ProductActions.SEARCH_PRODUCTS))
            .pipe(distinctUntilKeyChanged('payload.queryText'))
            .subscribe((data: SearchProducts) => {
                if (data.auxiliary === undefined) {
                    this.sikoPlpService.setLoadingSubject(true);
                }
            });

        this.searchProductsFinishedSubscription = this.actions.pipe(ofType(ProductActions.SEARCH_PRODUCTS_SUCCESS, ProductActions.SEARCH_PRODUCTS_FAIL))
            .subscribe(() => {
                this.sikoPlpService.setLoadingSubject(false);
            });
    }


    dealWithScroll(y: number) {
        if (this.lastScroll > y && y > 500) {
            this.isScrollingUp$.next(true);
        }
        else {
            this.isScrollingUp$.next(false);
        }

        this.lastScroll = y;
    }

    scrollToProducts(): void {
        this.el?.nativeElement.scrollIntoView();
    }

    ngOnDestroy(): void {
        this.sikoSerpService.setIsShowStickyMenu('');
    }
}

