import { StateUtils } from '@spartacus/core';
import { SIKO_NEWS_DATA } from '@siko/features/news/store/reducers';
import { SikoDocument, SikoSelectedNewsData } from '@siko/models';

export const LOAD_NEWS = '[News] Load News';
export const LOAD_NEWS_FAIL = '[News] Load News Fail';
export const LOAD_NEWS_SUCCESS = '[News] Load News Success';

export const CHANGE_NEWS_STATUS = '[News] Change News Status';
export const CHANGE_NEWS_STATUS_FAIL = '[News] Change News Status Fail';

export const LOAD_SELECTED_NEWS = '[News] Load Selected News';
export const LOAD_SELECTED_NEWS_FAIL = '[News] Load Selected News Fail';
export const LOAD_SELECTED_NEWS_SUCCESS = '[News] Load Selected News Success';

export const MARK_ALL_AS_SEEN = '[News] Mark All News as Seen';
export const MARK_ALL_AS_SEEN_FAIL = 'News Mark All News as Seen Fail';

export const CHANGE_CURRENT_PAGE = '[News] Change Current Page';

export class LoadNews extends StateUtils.LoaderLoadAction {
    readonly type = LOAD_NEWS;

    constructor(public payload: { currentPage: number }){
        super(SIKO_NEWS_DATA);
    }
}

export class LoadNewsFail extends StateUtils.LoaderFailAction {
    readonly type = LOAD_NEWS_FAIL;

    constructor(public payload: any) {
        super(SIKO_NEWS_DATA, payload);
    }
}

export class LoadNewsSuccess extends StateUtils.LoaderSuccessAction {
    readonly type = LOAD_NEWS_SUCCESS;

    constructor(public payload: any) {
        super(SIKO_NEWS_DATA);
    }
}

export class ChangeNewsStatus extends StateUtils.LoaderLoadAction {
    readonly type = CHANGE_NEWS_STATUS;

    constructor(public payload: { newsUid: string; isNewsSeen: boolean }){
        super(SIKO_NEWS_DATA);
    }
}

export class ChangeNewsStatusFail extends StateUtils.LoaderFailAction {
    readonly type = CHANGE_NEWS_STATUS_FAIL;

    constructor(public payload: any) {
        super(SIKO_NEWS_DATA, payload);
    }
}

export class LoadSelectedNews extends StateUtils.LoaderLoadAction {
    readonly type = LOAD_SELECTED_NEWS;

    constructor(public payload: { newsUid: string; documents?: SikoDocument[] }) {
        super(SIKO_NEWS_DATA);
    }
}

export class LoadSelectedNewsFail extends StateUtils.LoaderSuccessAction {
    readonly type = LOAD_SELECTED_NEWS_FAIL;

    constructor(public payload: any) {
        super(SIKO_NEWS_DATA);
    }
}

export class LoadSelectedNewsSuccess extends StateUtils.LoaderSuccessAction {
    readonly type = LOAD_SELECTED_NEWS_SUCCESS;

    constructor(public payload: SikoSelectedNewsData) {
        super(SIKO_NEWS_DATA);
    }
}

export class MarkAllAsSeen extends StateUtils.LoaderLoadAction {
    readonly type = MARK_ALL_AS_SEEN;

    constructor(public payload: any) {
        super(SIKO_NEWS_DATA);
    }
}

export class MarkAllAsSeenFail extends StateUtils.LoaderFailAction {
    readonly type = MARK_ALL_AS_SEEN_FAIL;

    constructor(public payload: any) {
        super(SIKO_NEWS_DATA, payload);
    }
}

export class ChangeCurrentPage extends StateUtils.LoaderSuccessAction {
    readonly type = CHANGE_CURRENT_PAGE;

    constructor(public payload: number){
        super(SIKO_NEWS_DATA);
    }
}


export type SikoNewsAction =
  ChangeNewsStatus | ChangeNewsStatusFail | ChangeCurrentPage | LoadNews | LoadNewsFail | LoadNewsSuccess | LoadSelectedNews | LoadSelectedNewsFail | LoadSelectedNewsSuccess | MarkAllAsSeen;
