import { InjectionToken, Provider } from '@angular/core';
import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer
} from '@ngrx/store';
import { StateUtils, SiteContextActions } from '@spartacus/core';
import { SikoNewsState, SikoDefaultNewsState, SikoUnseenNewsState } from '@siko/features/news/store/siko-news-state';
import { sikoNewsReducer } from '@siko/features/news/store/reducers/news.reducer';
import { sikoUnseenNewsReducer } from '@siko/features/news/store/reducers/unseen-news.reducer';

export const SIKO_NEWS_DATA = '[News] Siko News Data';

export const getReducers = (): ActionReducerMap<SikoNewsState> => ({
    news:
        StateUtils.loaderReducer<SikoDefaultNewsState>(SIKO_NEWS_DATA, sikoNewsReducer),
    unreadNews:
        StateUtils.loaderReducer<SikoUnseenNewsState>(SIKO_NEWS_DATA, sikoUnseenNewsReducer)
});

export const REDUCER_TOKEN: InjectionToken<ActionReducerMap<SikoNewsState>> =
  new InjectionToken<ActionReducerMap<SikoNewsState>>('SikoNewsReducers');

export const REDUCER_PROVIDER: Provider = {
    provide: REDUCER_TOKEN,
    useFactory: getReducers
};

export const clearSikoNewsState = (
    reducer: ActionReducer<SikoNewsState>
): ActionReducer<SikoNewsState> => (state, action) => {
    if (action.type === SiteContextActions.LANGUAGE_CHANGE) {
        state = undefined;
    }

    // TODO add other actions if neccessary

    return reducer(state, action);
};

export const META_REDUCERS: MetaReducer[] = [clearSikoNewsState];
