<ng-container *ngIf='iconMode === "store-item" else otherVectors'>
  <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 23.75L11.4437 13.9591C12.2865 13.1689 12.2865 11.8311 11.4437 11.0409L0.999999 1.25" stroke="#73979C" stroke-linejoin="round"/>
  </svg>
</ng-container>
<ng-template #otherVectors>
  <svg [ngClass]='iconMode' [attr.width]='size' [attr.height]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 6L10.5409 15.6437C11.3311 16.4865 12.6689 16.4865 13.4591 15.6437L22.5 6" stroke-width="2" stroke-linejoin="round"/>
  </svg>
</ng-template>
