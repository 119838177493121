import { Component } from '@angular/core';
import { SikoProductReviewsTabComponent } from '@siko/features/product/product-detail/components';

@Component({
    selector: 'app-product-reviews-tab-bottom-section',
    templateUrl: './product-reviews-tab-bottom-section.component.html',
    styleUrls: ['./product-reviews-tab-bottom-section.component.scss'],
})
export class SikoProductReviewsTabBottomSectionComponent extends SikoProductReviewsTabComponent {

}
