<header class='header' id='header-outlet'>
  <div #topHeaderSlot>
    <cx-page-slot [position]="'TopHeaderSlot'"></cx-page-slot>
  </div>

  <ng-container *ngIf='isLoggedIn$ | async else notLogged'>
    <ng-container
      *ngIf='{isMobile: isMobile$ | async, isTablet: isTablet$ | async, isDesktop: isDesktop$ | async} as screen'>
      <ng-container *ngIf='isCheckoutPage$ | async; else defaultHeader'>
        <div class='d-flex justify-content-between checkout-header'>
          <cx-page-slot [position]="'SiteLogo'"></cx-page-slot>
          <div class='d-flex'>
            <app-checkout-contacts></app-checkout-contacts>
            <cx-page-slot [position]="'MiniCart'"></cx-page-slot>
          </div>
        </div>
      </ng-container>
      <ng-template #defaultHeader>
        <ng-container *ngIf='screen.isDesktop?.matches'>
          <div class='desktop'>
            <div>
              <div class='row links-slot desktop-links-slot'>
                <cx-page-slot [position]="'SiteContext'"></cx-page-slot>
                <cx-page-slot [position]="'SiteLinks'"></cx-page-slot>
              </div>
              <div class='row d-flex justify-content-center align-items-center header-middle__desktop'>
                <cx-page-slot [position]="'SiteLogo'"></cx-page-slot>
                <cx-page-slot [position]="'SearchBox'"></cx-page-slot>
                <cx-page-slot [position]="'SiteLogin'"></cx-page-slot>
                <cx-page-slot [position]="'MiniCart'"></cx-page-slot>
              </div>
              <div class='row desktop-nav-slot'>
                <cx-page-slot [position]="'NavigationBar'"></cx-page-slot>
                <cx-page-slot [position]="'NavigationBarSide'"></cx-page-slot>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf='screen.isTablet?.matches'>
          <div class='tablet'>
            <div>
              <div class='row links-slot tablet-links-slot'>
                <cx-page-slot [position]="'SiteLinks'"></cx-page-slot>
              </div>
              <div class='row d-flex justify-content-center align-items-center header-middle__tablet'>
                <cx-page-slot [position]="'SiteLogo'"></cx-page-slot>
                <cx-page-slot [position]="'SiteLogin'"></cx-page-slot>
                <cx-page-slot [position]="'MiniCart'"></cx-page-slot>
              </div>
              <div class='row justify-content-center align-items-center'>
                <cx-page-slot [position]="'SearchBox'"></cx-page-slot>
                <cx-page-slot [position]="'NavigationBarSide'"></cx-page-slot>
              </div>
              <div class='row'>
                <cx-page-slot [position]="'NavigationBar'"></cx-page-slot>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf='screen.isMobile?.matches'>
          <div class='mobile'>
            <div class='d-flex justify-content-between align-items-center py-4 mobile-border top-nav' #topNav>
              <cx-page-slot [position]="'SiteLogo'"></cx-page-slot>
              <cx-page-slot [position]="'NavigationBarSide'"></cx-page-slot>
            </div>
            <div class='d-flex justify-content-between bottom-nav position-relative sticky-menu' #stickyMenuRef>
              <div class='d-flex align-items-center'>
                <cx-page-slot [position]="'SiteLogin'"></cx-page-slot>
                <cx-page-slot [position]="'SearchBox'"></cx-page-slot>
                <cx-page-slot [position]="'MiniCart'"></cx-page-slot>
              </div>
              <div class='divider'></div>
              <div class='d-flex align-items-center'>
                <cx-page-slot [position]="'NavigationBar'"></cx-page-slot>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #notLogged>
    <div class='d-flex align-items-center not-logged position-relative'>
      <cx-page-slot [position]="'SiteContext'"></cx-page-slot>
      <cx-page-slot [position]="'SiteLinks'"></cx-page-slot>
    </div>
    <div class='col-12 d-flex align-items-center justify-content-between wrapper-slots not-logged'>
      <cx-page-slot [position]="'SiteLogo'"></cx-page-slot>
      <div class='d-flex align-items-center login-actions'>
        <cx-page-slot [position]="'SiteLogin'"></cx-page-slot>
        <cx-page-slot [position]="'MiniCart'"></cx-page-slot>
      </div>
    </div>
  </ng-template>
</header>
<cx-page-slot [position]="'BottomHeaderSlot'"></cx-page-slot>
<app-global-message
  aria-atomic='true'
  aria-live='assertive'
></app-global-message>
<app-action-loader></app-action-loader>
