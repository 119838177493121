import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-bonus-program-group-icon',
    templateUrl: './bonus-program-group-icon.component.html',
    styleUrls: ['./bonus-program-group-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
    ],
})
export class SikoBonusProgramGroupIconComponent {
    @Input() iconMode?: 'small' | 'yellow' | 'red' | 'table';
}
