import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-check-circle-icon',
    templateUrl: './check-circle.component.html',
    styleUrls: ['./check-circle.component.scss'],
    imports: [
        CommonModule,
    ],
})
export class SikoCheckCircleIconComponent {

    @Input() iconMode?: 'circle-dark';
}
