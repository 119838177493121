<div class='xs-search-icon hide-desktop justify-content-center' [ngClass]="this.isSearchBoxOpen ? 'searchbox-icon' : ''"
     (click)='openSearchBar()' #openSearchBox>
  <img class='icon' [src]='"header/mobile/menu-search-icon.svg" | sikoIconAsset' alt='Search bar icon'>
</div>

<div #searchBar [attr.aria-label]="'searchBox.productSearch' | cxTranslate"
     role='search' class='siko-search-wrapper hide-xs'>
  <label class='searchbox' #searchBox [class.dirty]='!!searchInput.value'>
    <input
      [attr.data-testid]='$any("searchbox-input")'
      #searchInput
      [placeholder]="'siko.searchBoxPlaceHolder' | cxTranslate"
      autocomplete='off'
      aria-describedby='initialDescription'
      aria-controls='results'
      [attr.aria-label]="'searchBox.placeholder' | cxTranslate"
      (focus)='safeOpen($event)'
      (click)='safeOpen($event)'
      (input)='search(searchInput.value)'
      (keydown.escape)='close($any($event))'
      (keydown.enter)='
        close($any($event), true);
        launchSearchResult($any($event), searchInput.value);
        updateChosenWord(searchInput.value)
      '
      (keydown.arrowup)='focusPreviousChild($any($event))'
      (keydown.arrowdown)='focusNextChild($any($event))'
      value='{{ chosenWord }}'
      class='place-holder'
    />

    <button
      [attr.data-testid]='$any("search-button")'
      [attr.aria-label]="'common.search' | cxTranslate"
      class='search'
      (click)='showAllResults($any($event))'
    >
      <cx-icon [type]='iconTypes.SEARCH'></cx-icon>
    </button>

    <button
      [attr.data-testid]='$any("reset-button")'
      [attr.aria-label]="'common.reset' | cxTranslate"
      (mousedown)='clear(searchInput, $any($event))'
      (keydown.enter)='clear(searchInput, $any($event))'
      class='reset'
    >
      <img [src]='"header/mobile/close-menu.svg" | sikoIconAsset' alt='Close menu icon'>
    </button>
  </label>
</div>

<ng-container *ngIf='searchingState | async as searchingState'>
  <div
    *ngIf='results$ | async as result'
    [ngClass]='searchingState'
    class='results'
    (click)='close($any($event), true)'
    (scroll)='closeKeyboard()'
    role='listbox'
    #results
  >
    <div class='row'>
      <div class='col-4 mobile-design'>
        <div class='section' *ngIf=' result.sikoStores && result.sikoStores.length > 0'
             [attr.data-testid]='$any("stores-list")'>
          <div class='col-12 header'>
          <span>{{'siko.stores' | cxTranslate}}
            <span class='number'>{{result.sikoStoresCount}}</span>
          </span>
          </div>
          <div class='item' *ngFor='let stores of result.sikoStores'>
            <a (click)='sikoHelperService.goToStoreDetailLink(stores)' (mousedown)='preventDefault($event)'>
              <img [src]='"serp-stores.svg" | sikoIconAsset' alt='Location icon'> <span
              [innerHTML]='highlightPartOfText(searchInput.value,stores.displayName)'></span>
            </a>
          </div>
        </div>
        <div class='section' *ngIf='result.sikoCategoriesCount > 0' [attr.data-testid]='$any("categories-list")'>
          <div class='col-12 header'>
          <span>{{'siko.category' | cxTranslate}}
            <span class='number'>{{result.sikoCategoriesCount}}</span>
          </span>
          </div>
          <div class='item' *ngFor='let categories of result.sikoCategories | slice:0:3'>
            <a [routerLink]='getCategoryUrl(categories.url)' (mousedown)='preventDefault($event)'>
              <img [src]='"serp-categories.svg" | sikoIconAsset' alt='Categories icon'> <span
              [innerHTML]='highlightPartOfText(searchInput.value,categories.name)'></span>
            </a>
          </div>
        </div>
        <div class='serp-brands-desktop'>
          <ng-container *ngTemplateOutlet='serpBrands'></ng-container>
        </div>
        <div class='serp-series-desktop'>
          <ng-container *ngTemplateOutlet='serpSeries'></ng-container>
        </div>
      </div>
      <div class='col-8 mobile-design' [attr.data-testId]='$any("searchbox-found-products")' *ngIf='result.sikoProductsCount > 0'>
        <div class='section' [attr.data-testid]='$any("products-list")'>
          <div class='col-12 header'>
          <span>{{'siko.products' | cxTranslate}}
            <span class='number'>{{result.sikoProductsCount}}</span>
          </span>
          </div>
        </div>
        <div class='row' *ngFor='let product of result.products | keyvalue' [attr.data-testId]='$any("found-product-" + product?.value?.code)'>
          <ng-container>
            <app-header-search-list-item
              [searchBoxResult]='true'
              [routerLink]='product.value | sikoProductUrl'
              [product]='product.value'
              [order]=product.key
              class='cx-product-list-item'
              (click)='
          close($any($event), true);
          dispatchProductEvent({
            freeText: searchInput.value,
            productCode: product.value.code
          });
        '
              (keydown.arrowup)='focusPreviousChild($any($event))'
              (keydown.arrowdown)='focusNextChild($any($event))'
              (keydown.enter)='close($any($event), true)'
              (keydown.escape)='close($any($event), true)'
              (blur)='close($any($event))'
              (mousedown)='preventDefault($any($event))'
            ></app-header-search-list-item>
          </ng-container>
        </div>
        <div class='serp-brands-mobile'>
          <ng-container *ngTemplateOutlet='serpBrands'></ng-container>
        </div>
        <div class='serp-series-mobile'>
          <ng-container *ngTemplateOutlet='serpSeries'></ng-container>
        </div>
        <div class='col-12 text-center show-all'>
          <siko-button
            testId='show-all-results-button'
            (mousedown)='preventDefault($any($event))'
            mode='secondary'
            size='lg'
            (clickEvent)='showAllResults($any($event))'
            [text]='"siko.showAllResults" | cxTranslate'
          >
          </siko-button>
        </div>
      </div>
      <ng-container *ngIf='searchingState === SEARCHING '>
        <div class='d-flex'>
          <app-skeleton skeletonType='search-hint'></app-skeleton>
        </div>
      </ng-container>
      <ng-container *ngIf='searchingState === EMPTY_RESULT'>
        <div [ngClass]='result.sikoStores && result.sikoStores.length > 0 ||
      result.sikoCategoriesCount > 0 || result.sikoBrandsCount > 0 || result.sikoSeriesCount > 0
      ? "col-md-8" : "col-md-12"' class='text-left'>
          <div class='pt-8 pb-32 siko-font-primary-semiBold siko-fs-14' [attr.data-testID]='$any("emptySearchInfo")'>
            {{ "header.search.emptySearchInfo" | cxTranslate }}
          </div>
          <ul class='no-results-listing'>
            <li>
              {{ "header.search.checkSpelling" | cxTranslate }}
            </li>
            <li>
              {{ "header.search.lessSpecificSearch" | cxTranslate }}
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <span class='cx-visually-hidden'>
      {{ 'searchBox.initialDescription' | cxTranslate }}
    </span>
    <div
      *ngIf='result.suggestions?.length || result.products?.length'
      aria-live='assertive'
      class='cx-visually-hidden'
    >
      {{
      'searchBox.suggestionsResult'
        | cxTranslate: { count: result.suggestions?.length }
      }}
      {{
      'searchBox.productsResult'
        | cxTranslate: { count: result.products?.length }
      }}
      {{ 'searchBox.initialDescription' | cxTranslate }}
    </div>
    <ng-template #serpSeries>
      <div class='section' *ngIf='result.sikoSeriesCount > 0' [attr.data-testid]='$any("series-list")'>
        <div class='col-12 header'>
          <span>{{'siko.series' | cxTranslate}}
            <span class='number'>{{result.sikoSeriesCount}}</span>
          </span>
        </div>
        <div class='item' *ngFor='let series of result.sikoSeries | slice:0:3'>
          <a [routerLink]='getCategoryUrl(series.url)' (mousedown)='preventDefault($event)'>
            <img [src]='"serp-series.svg" | sikoIconAsset' alt='Brands icon'> <span
            [innerHTML]='highlightPartOfText(searchInput.value, series.name)'></span>
          </a>
        </div>
      </div>
    </ng-template>
    <ng-template #serpBrands>
      <div class='section' *ngIf='result.sikoBrandsCount > 0' [attr.data-testid]='$any("brands-list")'>
        <div class='col-12 header'>
          <span>{{'siko.brands' | cxTranslate}}
            <span class='number'>{{result.sikoBrandsCount}}</span>
          </span>
        </div>
        <div class='item' *ngFor='let brands of result.sikoBrands | slice:0:3'>
          <a [routerLink]='getCategoryUrl(brands.url)' (mousedown)='preventDefault($event)'>
            <img [src]='"serp-brands.svg" | sikoIconAsset' alt='Brands icon'> <span
            [innerHTML]='highlightPartOfText(searchInput.value, brands.name)'></span>
          </a>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>

