import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService, RoutingConfigService } from '@spartacus/core';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SikoPublicUrlsService {

    publicUrls$?: Observable<string[]>;

    constructor(
        readonly http: HttpClient,
        readonly occEndpoints: OccEndpointsService,
        readonly routingConfigService: RoutingConfigService,
    ) {}

    getPublicUrls(): Observable<string[]> {
        if (!this.publicUrls$) {
            const backendBaseUrl = this.occEndpoints.getBaseUrl();

            this.publicUrls$ = this.http.get<string[]>(`${backendBaseUrl}/public-urls`).pipe(
                map(data => {
                    const filteredUrls: string[] = [];

                    data.forEach(url => {
                        if (!this.routingConfigService.getRouteName(url)) {
                            filteredUrls.push(url);
                        }
                    });

                    return data;
                }),
                shareReplay(1)
            );
        }

        return this.publicUrls$;
    }

}
