<ng-container *ngIf='sikoBreakpointObserver.breakpointChange$ | async'>
  <ng-container *ngIf='pdpCommunicationService.tabParagraphActiveNum$ | async as activeNum'>
    <!--Desktop tabs-->
    <ng-container *ngIf='sikoBreakpointObserver.isBreakpointActive(["lg", "xl"])'>
      <div class='row mb-120 border-bottom justify-content-center text-center sticky'>
        <div class='d-flex justify-content-between'>
          <div
            class='tab-item'
            [ngClass]="activeNum === 1 ? 'tab-item--active' : ''"
            (click)="pdpCommunicationService.scrollToElement(
          'js--product-information-and-documents',
                    1,
                    'UNDEFINED'
          )"
          >
            <h4 class='text'>{{'pdp.tabs.descriptionAndDocuments' | cxTranslate}}</h4>
          </div>
          <div
            class='tab-item'
            [ngClass]="activeNum === 2 ? 'tab-item--active' : ''"
            (click)="pdpCommunicationService.scrollToElement(
          'js--product-detail-tab',
                    2,
                    'UNDEFINED'
          )"
          >
            <h4 class='text'>{{'pdp.tabs.technicalParameters' | cxTranslate}}</h4>
          </div>
          <div
            class='tab-item'
            [ngClass]="activeNum === 3 ? 'tab-item--active' : ''"
            (click)="pdpCommunicationService.scrollToElement(
          'js--review-tab',
                    3,
                    'UNDEFINED'
          )"
          >
            <h4 class='text'>{{'pdp.tabs.ratings' | cxTranslate}}</h4>
          </div>
          <ng-container *ngIf='{ observableValue: isSimilarProductsTabShown$ | async } as isShown'>
            <div
              *ngIf='isShown.observableValue === true'
              class='tab-item'
              [ngClass]="activeNum === 4 ? 'tab-item--active' : ''"
              (click)="pdpCommunicationService.scrollToElement(
          'js--similar-products-tab',
                    4,
                    'UNDEFINED'
          )"
            >
              <h4 class='text'>{{'pdp.tabs.similarProducts' | cxTranslate}}</h4>
            </div>
          </ng-container>
        </div>
      </div>
      <!--Desktop tabs content-->
      <div
        scrolledToPdp #scrolledToElement='scrolledToPdp'
        id='js--product-information-and-documents'
        class='row d-none d-lg-flex mb-96'>
        <!--    Product information section-->
        <div class='col-8 pt-4'>
          <app-product-information></app-product-information>
        </div>
        <div class='col-4 pt-4'>
          <app-product-documents-tab></app-product-documents-tab>
        </div>
      </div>
      <div
        scrolledToPdp #scrolledToElement='scrolledToPdp'
        id='js--product-detail-tab'
        class='row d-none d-lg-flex mb-96'>
        <div class='col-8 pt-4'>
          <app-product-detail-tab></app-product-detail-tab>
        </div>
      </div>
      <div
        scrolledToPdp #scrolledToElement='scrolledToPdp'
        id='js--review-tab'
        class='row d-none d-lg-flex mb-96'>
        <div class='col pt-4'>
          <app-product-reviews-tab></app-product-reviews-tab>
        </div>
      </div>
    </ng-container>
    <!--  Tablet and mobile tabs-->
    <ng-container *ngIf='sikoBreakpointObserver.isBreakpointActive(["xs", "sm", "md"])'>
      <div class='pt-5'>
        <app-product-mobile-tabs></app-product-mobile-tabs>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
