<ng-container *ngIf='adBanner'>
    <ng-container *ngIf='isGrid; else isList'>
        <a class='d-flex flex-column h-100'
           (click)='handleRedirect(adBanner.linkTo?.external, adBanner.linkTo?.localizedUrl ?? adBanner.linkTo?.url)'>
            <div class='grid-view-image'
                 [ngStyle]='{ background: backgroundColor }'>
              <app-media
                class='w-100 d-flex justify-content-center'
                [container]='{ image: $any(adBanner.gridViewImage)}'
              ></app-media>
            </div>
        </a>
    </ng-container>
    <ng-template #isList>
        <div class='list-view-image d-flex justify-content-center'
             [ngStyle]='{ background: backgroundColor }'
             (click)='handleRedirect(adBanner.linkTo?.external, adBanner.linkTo?.localizedUrl ?? adBanner.linkTo?.url)'>
            <ng-container *ngIf='adBanner.listViewImage'>
                <app-media
                    class='widescreen'
                    [container]='getMediaForElement(adBanner.listViewImage, "widescreen")'
                ></app-media>
                <app-media
                    class='desktop'
                    [container]='getMediaForElement(adBanner.listViewImage, "desktop")'
                ></app-media>
                <app-media
                    class='tablet'
                    [container]='getMediaForElement(adBanner.listViewImage, "tablet")'
                ></app-media>
                <app-media
                    class='mobile'
                    [container]='getMediaForElement(adBanner.listViewImage, "mobile")'
                ></app-media>
            </ng-container>
        </div>
    </ng-template>
</ng-container>
