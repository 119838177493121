import { Component, Input } from '@angular/core';
import { SikoProduct } from '@siko/models';

@Component({
    selector: 'app-promotion-campaign',
    templateUrl: './promotion-campaign.component.html',
    styleUrls: ['./promotion-campaign.component.scss'],
})
export class PromotionCampaignComponent {

    sikoProduct?: SikoProduct;

    @Input() set product(product: SikoProduct) {
        this.sikoProduct = product;
    }

}
