import { Component, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProductGridItemComponent, ProductListItemContextSource } from '@spartacus/storefront';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SikoOccProductAdapter } from '@siko/features/product/occ/adapters/occ-product.adapter';
import { SikoAutoUnsubscribe, SikoBreakpointObserverService } from '@siko/common';
import { SikoPricePipe } from '@siko/shared/pipes/price.pipe';

@SikoAutoUnsubscribe(['updateProductPriceSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-grid-item',
    templateUrl: './product-grid-item.component.html',
    styleUrls: ['./product-grid-item.component.scss'],
    providers: [ProductListItemContextSource],
})
export class SikoProductGridItemComponent extends ProductGridItemComponent implements OnInit {
    @Input() isStoreDisponibility = false;

    updatedPrice = new BehaviorSubject<string>('');
    isUpdating = new BehaviorSubject<boolean>(false);
    updatePriceSuccess = new BehaviorSubject<boolean>(false);
    updatePriceFailed = new BehaviorSubject<boolean>(false);
    updateProductPriceSubscription?: Subscription;

    constructor(
        private readonly sikoPricePipe: SikoPricePipe,
        public sikoBreakpointObserver: SikoBreakpointObserverService,
        private readonly occSikoProductAdapter: SikoOccProductAdapter,
        private readonly ref: ChangeDetectorRef,
        productListItemContextSource: ProductListItemContextSource,
    ) {
        super(productListItemContextSource);
    }


    ngOnInit(): void {
        this.updatedPrice.next(
            this.product?.discountPrice ?
                this.sikoPricePipe.transform(this.product.discountPrice?.value) :
                this.sikoPricePipe.transform(this.product.basePrice?.value),
        );
    }

    updateProductPrice(code: string, event: Event): void {

        if (this.isUpdating.value === true) {return;}

        if (this.updatePriceSuccess.getValue() || this.updatePriceFailed.getValue()) {
            return;
        }
        else {
            event.stopPropagation();
            event.preventDefault();
        }

        this.isUpdating.next(true);

        this.updateProductPriceSubscription = this.occSikoProductAdapter.updateProductPrice(code)
            .subscribe(product => {
                    if (product.price) {
                        this.updatedPrice.next(this.sikoPricePipe.transform(product.price?.value));
                    }

                    this.updatePriceSuccess.next(true);
                    this.isUpdating.next(false);
                    this.ref.detectChanges();
                },
                error => {
                    this.isUpdating.next(false);
                    this.updatePriceSuccess.next(false);
                    this.updatePriceFailed.next(true);
                    this.ref.detectChanges();
                });
    }

}


