<div class="cx-order-summary container" *ngIf="order$ | async as order">
  <div class="row justify-content-end">
    <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4">
      <app-order-detail-totals
        [order]='order'
        [orderConfirmation]='true'
      >
      </app-order-detail-totals>
    </div>
  </div>
</div>
