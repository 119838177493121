<div class='d-flex'>
  <div class='phone d-flex align-items-center'>
    <a href="tel:{{ 'common.contacts.' + baseSite + '.phone' | cxTranslate }}">
      <img [src]='"header/checkout/phone.svg" | sikoIconAsset' alt='Phone contact icon' class='phone-icon'>
      <h4 class='d-none d-sm-inline'>
        {{ "common.contacts." + baseSite + ".phone" | cxTranslate }}
      </h4>
    </a>
  </div>
  <div class='email d-flex align-items-center'>
    <a href="mailto:{{ 'common.contacts.' + baseSite + '.email' | cxTranslate }}">
      <img [src]='"header/checkout/email.svg" | sikoIconAsset' alt='Email contact icon' class='email-icon'>
      <h4 class='d-none d-sm-inline'>
        {{ "common.contacts." + baseSite + ".email" | cxTranslate }}
      </h4>
    </a>
  </div>
  <a class='minicart d-flex' [routerLink]="{ cxRoute: 'cart' } | cxUrl">
    <img [src]='"header/mini-cart.svg" | sikoIconAsset' alt='Mini cart icon'>
  </a>
</div>
