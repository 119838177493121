import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener, Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { HamburgerMenuService, NavigationUIComponent } from '@spartacus/storefront';
import { Router } from '@angular/router';
import { SikoHeaderService } from '@siko/features/header/siko-header.service';
import { WindowRef } from '@spartacus/core';
import { sfGetIconAsset } from '@siko/shared/utils/siko-functions';
import { BehaviorSubject } from 'rxjs';
import { NavigationNode } from '@spartacus/storefront/cms-components/navigation/navigation/navigation-node.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'cx-navigation-ui',
    templateUrl: './navigation-ui.component.html',
})
export class SikoNavigationUIComponent extends NavigationUIComponent implements OnInit, OnDestroy {

    @ViewChild('wrapper') wrapper?: ElementRef;
    @Input() showBonusProgram?: boolean;

    node$: BehaviorSubject<NavigationNode | null> = new BehaviorSubject<NavigationNode | null>(null);

    constructor(
        public sikoHeaderService: SikoHeaderService,
        router: Router,
        renderer: Renderer2,
        elemRef: ElementRef,
        hamburgerMenuService: HamburgerMenuService,
        windowRef: WindowRef,
    ) {
        super(router, renderer, elemRef, hamburgerMenuService, windowRef);
    }

    @HostListener('document:click', ['$event'])
    hideUserNavMenu(event: MouseEvent): void {
        this.clear();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.node$.next(this.node);

        if (this.node?.children && !this.showBonusProgram) {
            let filteredNode = this.node?.children[0].children?.findIndex(n => n.url === "/my-account/bonus-program")

            if (filteredNode) {
                this.node.children[0].children?.splice(filteredNode,1);
                this.node$.next(this.node);
            }
        }
    }

    protected readonly sfGetIconAsset = sfGetIconAsset;
}
