import { Pipe, PipeTransform } from '@angular/core';
import { PaymentType } from '@spartacus/cart/base/root';
import { TranslatePipe } from '@spartacus/core';

@Pipe({
    name: 'sikoPaymentTypeName',
    pure: false,
    standalone: true,
})
export class SikoPaymentTypeNamePipe implements PipeTransform {

    constructor(private readonly translatePipe: TranslatePipe) { }

    transform(paymentType: PaymentType | string | undefined): string {
        if (!paymentType) {
            return '';
        }

        if (typeof paymentType === 'string') {
            return this.translatePipe.transform(
                `sikoCheckout.paymentTypesShared.paymentType_${paymentType}`,
            );
        }

        return !paymentType.code ? '' : this.translatePipe.transform(
            `sikoCheckout.paymentTypesShared.paymentType_${paymentType.code}`,
        );
    }

}
