<ng-container *ngIf='getOrder$ | async as order'>
  <div class='d-flex flex-column'>
    <div class='order-items'>
      <h3>{{"sikoOrder.orderDetail.deliveryInfo" | cxTranslate }}</h3>
      <ng-container *ngIf='order.pickupOrderGroups && order.pickupOrderGroups.length > 0; else deliveryToAddress'>
        <div class='package' *ngFor='let package of order.pickupOrderGroups; let indexOfelement=index;'>
          <div class='info'>
            <div class='row pt-4'>
              <div class='col-sm-6 col-md-4 border-right-custom store' *ngIf='package.deliveryPointOfService'>
                <div>
                  <h4 class='siko-color-grey-3'>
                    {{"sikoOrder.orderDetail.store" | cxTranslate}}
                  </h4>
                </div>
                <div>
                  <siko-link
                    testId='store-detail-link'
                    [label]='$any(package.deliveryPointOfService.displayName)'
                    [style]="$any('primary')"
                    (click)='sikoHelperService.goToStoreDetailLink(package.deliveryPointOfService)'
                    [disableLink]='true'
                  >
                  </siko-link>
                </div>
                <app-address
                  [address]='package.deliveryPointOfService.address'
                  mode='address'
                ></app-address>
              </div>
              <div class='col-sm-6 col-md-4 border-right-custom about-store' *ngIf='package.deliveryPointOfService'>
                <h5 class='siko-color-grey-3'>
                  {{"sikoOrder.orderDetail.moreAboutStore" | cxTranslate}}
                </h5>
                <div class='email' *ngIf='package.deliveryPointOfService.address?.email'>
                  <span class='icon'></span>
                  <siko-link
                    testId='email-link'
                    [phoneLink]='true'
                    externalPath='mailto:{{package.deliveryPointOfService.address?.email}}'
                    [label]='$any(package.deliveryPointOfService.address?.email)'
                    [style]="$any('primary')"
                    [disableLink]='true'>
                  </siko-link>
                </div>
                <div class='about-store-actions'>
                  <div class='pb-3 opening-hours' *ngIf='package.deliveryPointOfService.openingHours'>
                    <siko-button
                      testId='opening-hours-button'
                      [block]='false'
                      [size]='$any("sm")'
                      [mode]='$any("white")'
                      [text]='"siko.openingHours.title" | cxTranslate'
                      iconPosition='before'
                      iconCode='watch_later'
                      [iconOutline]='true'
                      (clickEvent)='openOpeningHoursModal(
                    package.deliveryPointOfService.openingHours,
                    package.deliveryPointOfService.displayName
                    )'>
                    </siko-button>
                  </div>
                  <div class='in-stock' *ngIf='package.deliveryPointOfService.name'>
                    <siko-button
                      testId='products-in-stock-button'
                      [block]='false'
                      [size]='$any("sm")'
                      [mode]='$any("white")'
                      [text]='"sikoOrder.orderDetail.productsInStock" | cxTranslate'
                      iconPosition='before'
                      iconCode='warehouse'
                      [iconOutline]='true'
                      (clickEvent)='goToPLPPage(package.deliveryPointOfService.name)'>
                    </siko-button>
                  </div>
                </div>
              </div>
              <div class='col-md-4 shipping-method'>
                <div>
                  <h5 class='siko-color-grey-3'>
                    {{"siko.shippingMethod" | cxTranslate}}
                  </h5>
                </div>
                {{order.deliveryMode?.name}}
              </div>
            </div>
          </div>
          <div class='items' *ngIf='package.entries'>
            <app-cart-item-list [items]='package.entries' [order]='order' [readonly]='true' [isOrder]='true'
            ></app-cart-item-list>
          </div>
        </div>
      </ng-container>
      <ng-template #deliveryToAddress>
        <div class='package'>
          <div class='info'>
            <div class='row'>
              <div class='col-md-4 border-right-custom' *ngIf='order.deliveryAddress as deliveryAddress'>
                <app-address
                  [address]='deliveryAddress'
                  [name]='deliveryAddress.companyName'
                  mode='address'
                ></app-address>
              </div>
              <div class='col-md-4'>
                <div>
                  <h5 class='siko-color-grey-3'>
                    {{"siko.shippingMethod" | cxTranslate}}
                  </h5>
                </div>
                {{order.deliveryMode?.name}}
              </div>
            </div>
          </div>
          <div class='items' *ngIf='order.entries'>
            <app-cart-item-list [items]='order.entries' [order]='order' [readonly]='true' [isOrder]='true'
            ></app-cart-item-list>
          </div>
        </div>
      </ng-template>
    </div>
    <div class='row billing-info'>
      <div class='col-12 heading'>
        <h3>{{"sikoOrder.sapOrderDetail.billingInformation" | cxTranslate }}</h3>
      </div>
      <div class='col-sm-6 col-md-4 border-right'>
        <app-address
          [address]='order?.orgCustomer?.orgUnit?.sikoDefaultBillingAddress'
          [name]='order?.orgCustomer?.orgUnit?.sikoDefaultBillingAddress?.companyName'
          mode='address'
        ></app-address>
      </div>
      <div class='col-sm-6 col-md-4 border-right'>
        <app-address
          [address]='order?.orgCustomer?.orgUnit?.sikoDefaultBillingAddress'
          mode='businessIds'
        ></app-address>
      </div>
      <div class='col-md-4 payment-method pt-4 pt-md-0'>
        <div>
          <h5 class='siko-color-grey-3'>
            {{"siko.paymentType" | cxTranslate}}
          </h5>
        </div>
        {{order.paymentType | sikoPaymentTypeName}}
      </div>
    </div>
  </div>
</ng-container>
