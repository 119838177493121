<ng-container *ngIf='model$ | async as model'>
  <div class='categories' *ngIf='!model.virtualCategoryName && model.subCategories && model.subCategories.length > 0'>
    <h3 class='siko-color-grey-3'>{{ 'siko.category' | cxTranslate }}</h3>
    <div class='mt-3'>
      <a class="d-flex" [attr.data-testId]="$any('subcategory-' + category.code)" (click)='routeToCategory(category.url)' *ngFor='let category of model?.subCategories'>
        {{ category.name }}
      </a>
    </div>
  </div>

  <button
    #trigger
    class='btn btn-action btn-block dialog-trigger'
    style='display: none'
    (click)='launch()'
  >
    <cx-icon [type]='iconTypes.FILTER'></cx-icon>
    {{ 'productList.filterBy.label' | cxTranslate }}
  </button>
  <div #facetList></div>
  <app-facet-list
    *ngIf='isOpen$ | async'
    [isDialog]='hasTrigger'
    (closeList)='close()'
    [class.active]='isActive$ | async'
    [class.dialog]='hasTrigger'
    [productCount]=productCount
    [virtualCategory]='model.virtualCategoryName'
  ></app-facet-list>
  <ng-container *ngIf='model.facets && model.facets.length > 0'>
    <siko-button
      class='btn-scroll-to-filter d-none d-lg-block'
      [size]="$any('md')"
      [mode]="$any('secondary')"
      text='{{"siko.facet.updateFilters"|cxTranslate}}'
      (clickEvent)='scrollToFilters(facetList)'>
    </siko-button>
  </ng-container>
</ng-container>


