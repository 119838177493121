import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalMessageService, GlobalMessageType, UserIdService } from '@spartacus/core';
import { StateWithSikoSapOrder } from '@siko/features/my-account/sap-order-detail/store/siko-sap-order-state';
import { SikoSapOrderActions } from '@siko/features/my-account/sap-order-detail/store/actions';
import { SikoFileDownloadService } from '@siko/shared/utils/siko-file-download.service';
import { OccSapOrderAdapter } from '@siko/features/my-account/sap-order-detail/occ/adapters/occ-sap-order.adapter';
import { SikoDialogService } from '@siko/shared/utils/siko-dialog.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    SikoOrderDocumentsDialogComponent,
} from '@siko/features/shared-components/order-documents/components/order-documents-dialog/order-documents-dialog.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SikoOrderDocumentsService {

    isDownloadingDocumentSubject$ = new BehaviorSubject<boolean>(false);

    constructor(
        readonly globalMessageService: GlobalMessageService,
        readonly store: Store<StateWithSikoSapOrder>,
        readonly userIdService: UserIdService,
        readonly dialogService: SikoDialogService,
        readonly occSapOrderAdapter: OccSapOrderAdapter,
        readonly fileDownloadService: SikoFileDownloadService,
    ) {}

    fetchDocuments(orderCode: string, isSapOrder: boolean): void {
        let currentUserId;

        this.userIdService
            .getUserId()
            .subscribe((userId: string) => {
                currentUserId = userId;
            })
            .unsubscribe();

        if (currentUserId) {
            const action = isSapOrder
                ? new SikoSapOrderActions.LoadSapOrderDocuments({
                    currentUserId,
                    orderCode,
                })
                : new SikoSapOrderActions.LoadOrderDocuments({
                    currentUserId,
                    orderCode,
                });

            this.store.dispatch(action);
        }
    }

    displayDocumentsDialog(
        orderCode: string | undefined,
        sapOrderCode: string | undefined,
        poNumber: string | undefined,
    ): void {
        const modalRef: NgbModalRef = this.dialogService.open(SikoOrderDocumentsDialogComponent, {
            windowClass: 'siko-dialog-window',
            size: 'lg',
        });

        modalRef.componentInstance.sapOrderCode = sapOrderCode;
        modalRef.componentInstance.orderCode = orderCode;
        modalRef.componentInstance.purchaseOrderNumber = poNumber;
    }

    downloadDocumentPdf(code: string, finishCallback: () => void): void {
        let userId;
        this.isDownloadingDocumentSubject$.next(true);

        this.userIdService
            .getUserId()
            .subscribe(occUserId => userId = occUserId)
            .unsubscribe();

        if (userId) {
            this.occSapOrderAdapter
                .getOrderDocumentPdf(userId, code)
                .subscribe((pdfByteCode: Blob) => {
                    this.fileDownloadService.downloadFile(pdfByteCode, code);
                    this.isDownloadingDocumentSubject$.next(false);
                    finishCallback();
                }, error => {
                    this.globalMessageService.add(
                        { key: 'sikoOrganization.documentDownloadError' },
                        GlobalMessageType.MSG_TYPE_ERROR,
                    );
                    this.isDownloadingDocumentSubject$.next(false);
                    finishCallback();
                });
        }
        else {
            this.isDownloadingDocumentSubject$.next(false);
            finishCallback();
        }
    }
}
