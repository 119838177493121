import { SikoProduct, SikoPromotionResult } from '@siko/models';
import { AbstractControl } from '@angular/forms';

export const SIKO_B2B_ADMIN_ROLE = 'b2badmingroup';
export const SIKO_B2B_CUSTOMER_ROLE = 'b2bcustomergroup';

export function sfGetIconAsset(relativePath: string): string {
    return '../assets/icons/' + relativePath;
}

export function sfGetProductWeight(product: SikoProduct | null | undefined): number {
    if (product?.sikoWeight && (product.sikoWeightUnit?.code.includes('KGM') || product.sikoWeightUnit?.code.includes('GRM'))) {
        return product.sikoWeightUnit.code.includes('KGM') ? product.sikoWeight : product.sikoWeight / 1000.0;
    }

    return 0;
}

export function sfGenerateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
        const randomValue = Math.random() * 16 | 0;
        const v = char === 'x' ? randomValue : (randomValue & 0x3 | 0x8);

        return v.toString(16);
    });
}

export function sfIsCustomer(roles: string[] | undefined) {
    return roles?.includes(SIKO_B2B_CUSTOMER_ROLE) && !roles.includes(SIKO_B2B_ADMIN_ROLE);
}

export function sfIsAdmin(roles: string[] | undefined) {
    return roles?.includes(SIKO_B2B_ADMIN_ROLE);
}

export function sfIsFreeGift(index: number, promotions: SikoPromotionResult[] | undefined): boolean {
    if (!promotions) {
        return false;
    }

    return promotions.find((promotion: SikoPromotionResult) => promotion.consumedEntries?.find(entry => entry.orderEntryNumber === index) &&
        promotion.promotion?.sikoPromoType?.includes('FREE_GIFT')) !== undefined;
}

export function sfValidateRangeDate(startDate: AbstractControl | null, endDate: AbstractControl | null): string {

    if (startDate?.value == null) {
        return '';
    }

    if (endDate?.value === null) {
        return startDate.value;
    }

    if (typeof startDate.value !== 'string' && startDate?.value.diff(endDate?.value, 'days') > 0) {
        endDate?.setValue(startDate?.value.format('YYYY-MM-DD'));
        return endDate?.value;
    }

    return startDate.value;
}

export function sfBlobToString(blob: Blob): string {
    const url = URL.createObjectURL(blob);
    let xmlRequest = new XMLHttpRequest();
    xmlRequest.open('GET', url, false);
    xmlRequest.send();
    URL.revokeObjectURL(url);
    return xmlRequest.responseText;
}
