<ng-container *ngIf="reviews">
<div class="siko-reviews-progress">
  <div *ngFor="let index of reviesGroup" class="d-flex flex-row align-items-center">
    <div>{{index}}</div>
    <div class="star-icon"></div>
    <div class="siko-progress">
      <div class="siko-bar" [ngStyle]="{'width': reviewsOccurences(reviews,index)}"></div>
    </div>
  </div>
</div>
</ng-container>
