import { Injectable } from '@angular/core';
import { AuthConfig, AuthConfigService, OccConfig } from '@spartacus/core';
import { AsmEnablerService } from '@spartacus/asm/root';
import { SikoUtils } from '@siko/shared';

@Injectable({
    providedIn: 'root',
})
export class SikoAuthConfigService extends AuthConfigService {

    constructor(
        protected authConfig: AuthConfig,
        protected occConfig: OccConfig,
        private readonly asmEnablerService: AsmEnablerService
    ) {
        super(authConfig, occConfig);
    }

    /**
     * Get client_id
     *
     * @return client_id
     */
    getClientId(): string {
        const isAsmEnabled = this.asmEnablerService.isEnabled();

        return isAsmEnabled
            ? SikoUtils.getAsmAuthConfig().authentication?.client_id ?? ''
            : super.getClientId();
    }

    /**
     * Get client_secret. OAuth server shouldn't require it from web apps (but Hybris OAuth server requires).
     *
     * @return client_secret
     */
    getClientSecret(): string {
        const isAsmEnabled = this.asmEnablerService.isEnabled();

        return isAsmEnabled
            ? SikoUtils.getAsmAuthConfig().authentication?.client_secret ?? ''
            : super.getClientSecret();
    }

}

