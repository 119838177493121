<div (click)='onClick()' class='document-item'>
  <div class='align-items-center justify-content-between'>
    <div class='d-flex'>
      <div [ngClass]='getIconClass()'>
      </div>
      <div class='ml-4 align-self-center'>
        {{name}}
        <ng-container *ngIf='getExtension() as extension'>
          <span class='siko-color-grey-3'>
            .{{extension}}
          </span>
        </ng-container>
      </div>
    </div>
  </div>
  <div [ngClass]='getActionClass()'></div>
</div>
