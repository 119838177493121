import { Component, Input } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { SikoProduct } from '@siko/models';
import { SikoPdpCommunicationService } from '@siko/features/product/product-detail/pdp-communication.service';
import { B2bCommonModule } from '@siko/shared';

@Component({
    selector: 'app-product-rating',
    templateUrl: './product-rating.component.html',
    standalone: true,
    imports: [
        B2bCommonModule,
    ],
})
export class SikoProductRatingComponent {

    productFromInput?: SikoProduct;

    @Input() set inputProduct(input: SikoProduct) {
        this.productFromInput = input;
        this.scrollNeedsRedirect = true;
    }

    scrollNeedsRedirect = false;

    constructor(
        public pdpCommunicationService: SikoPdpCommunicationService,
        private readonly currentProductService: CurrentProductService,
    ) { }

    get product$(): Observable<SikoProduct | null> {
        return this.productFromInput ? of(this.productFromInput) : this.currentProductService.getProduct();
    }
}
