import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SikoAutoUnsubscribe } from '@siko/common';

@SikoAutoUnsubscribe(['subscription'])
@Directive({
    selector: '[appDebounceClick]',
    standalone: true,
})
export class SikoDebounceClickDirective implements OnInit {
    @Input() throttleTime = 500;

    @Output() readonly debounceClick = new EventEmitter();

    private readonly clicks = new Subject();
    private subscription?: Subscription;

    ngOnInit(): void {
        this.subscription = this.clicks.pipe(
            debounceTime(this.throttleTime),
        )
            .subscribe(e => {
                this.debounceClick.emit(e);
            });
    }

    @HostListener('click', ['$event'])
    clickEvent(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }
}
