import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SikoActionLoaderService } from '@siko/features/action-loader/action-loader.service';
import { SikoActionLoader } from '@siko/features/action-loader/models/action-loader.model';

@Component({
    selector: 'app-action-loader',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './action-loader.component.html',
    styleUrls: ['./action-loader.component.scss'],
})
export class SikoActionLoaderComponent {

    instances$: Observable<SikoActionLoader[]> = this.actionLoaderService.getInstances();

    constructor(private readonly actionLoaderService: SikoActionLoaderService) {}

}
