import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule, provideConfig } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { B2bCommonModule } from '@siko/shared';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SIKO_BONUS_POINTS_INFO_TRANSLATIONS } from '@siko/features/shared-components/bonus-points-info/translations/bonus-points-info.translations';
import { LetModule } from '@ngrx/component';
import { SikoBonusPointsInfoComponent } from './bonus-points-info.component';

@NgModule({
    providers: [
        provideConfig({
            i18n: {
                resources: SIKO_BONUS_POINTS_INFO_TRANSLATIONS,
            },
        }),
    ],
    declarations: [
        SikoBonusPointsInfoComponent,
    ],
    imports: [
        CommonModule,
        I18nModule,
        B2bCommonModule,
        IconModule,
        NgbTooltipModule,
        LetModule,
    ],
    exports: [
        SikoBonusPointsInfoComponent,
    ],
})
export class SikoBonusPointsInfoModule {}
