import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    SapOrderHistory,
    StateWithFilterHistory,
} from '@siko/features/my-account/order-history/store/order-history-state';
import { Store } from '@ngrx/store';
import { OccOrderHistoryAdapter } from '@siko/features/my-account/order-history/occ/adapters/occ-order-history.adapter';
import { Observable } from 'rxjs';
import { AuthActions, LoggerService, normalizeHttpError, UserIdService } from '@spartacus/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    OrderFilterHistoryActions,
    SapOrdersHistoryActions,
} from '@siko/features/my-account/order-history/store/actions';
import { OrderHistoryAction } from '@siko/features/my-account/order-history/store/actions/order-history.action';

@Injectable({ providedIn: 'root' })
export class OrderHistoryEffect {

    userId?: string;

    constructor(
        readonly actions$: Actions<OrderHistoryAction>,
        readonly store: Store<StateWithFilterHistory>,
        protected occOrderHistoryAdapter: OccOrderHistoryAdapter,
        protected userIdService: UserIdService,
        readonly logger: LoggerService,
    ) {
    }

    loadOrderHistory$: Observable<
        SapOrdersHistoryActions.LoadOrderHistoryItemsFail |
        SapOrdersHistoryActions.LoadOrderHistoryItemsSuccess
    > = createEffect(() =>
            this.actions$.pipe(
                ofType(SapOrdersHistoryActions.LOAD_SAP_ORDERS),
                switchMap((action) => {
                    const {payload} = action as SapOrdersHistoryActions.LoadOrderHistoryItems;

                    this.userIdService
                        .getUserId()
                        .subscribe((occUserId: string) => this.userId = occUserId)
                        .unsubscribe();

                    const {startDate} = payload;
                    const {endDate} = payload;

                    if (!this.userId) {
                        return [];
                    }

                return this.occOrderHistoryAdapter
                    .getSapOrders(this.userId, startDate, endDate)
                    .pipe(
                        switchMap((orderHistory: SapOrderHistory) => [
                            new SapOrdersHistoryActions.LoadOrderHistoryItemsSuccess(orderHistory),
                        ]),
                        catchError((error) => [
                            new SapOrdersHistoryActions.LoadOrderHistoryItemsFail(normalizeHttpError(error, this.logger)),
                        ]),
                    );
            }),
        ));

    clearOrderHistory$: Observable<
        OrderFilterHistoryActions.ClearFilters
    > = createEffect(() =>
            this.actions$.pipe(
                ofType(AuthActions.LOGOUT, AuthActions.LOGIN),
                map(() => new OrderFilterHistoryActions.ClearFilters()),
            ));

}
