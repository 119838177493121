import { ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SikoActiveCartService } from '@siko/features/cart';
import { GlobalMessageService, GlobalMessageType, UserIdService } from '@spartacus/core';
import { CartActions } from '@spartacus/cart/base/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { SIKO_SELECTED_DELIVERY_MODE_ENUM, SikoCart, SikoOrderEntry, SikoProductDisponibility } from '@siko/models';
import { SikoAutoUnsubscribe } from '@siko/common';
import { SikoDialogService, SikoProductDisponibilityService } from '@siko/shared';
import { ActiveCartFacade, CartModification } from '@spartacus/cart/base/root';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SikoRemoveCartEntryComponent } from '@siko/features/cart/remove-cart-entry/remove-cart-entry.component';
import { startWith } from 'rxjs/operators';
import { SikoAbstractItemCounterComponent } from '../abstract-item-counter/abstract-item-counter.component';

@SikoAutoUnsubscribe([
    'storeActionsSubscription',
    'lifecycleSubscription',
    'cartUpdatedSubscription',
])
@Component({
    selector: 'app-cart-item-counter',
    templateUrl: '../abstract-item-counter/abstract-item-counter.component.html',
    styleUrls: ['../abstract-item-counter/abstract-item-counter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoCartItemCounterComponent extends SikoAbstractItemCounterComponent implements OnInit, OnChanges {

    cartId?: string;
    storeActionsSubscription?: Subscription;
    lifecycleSubscription?: Subscription;
    cartUpdatedSubscription?: Subscription;

    ngOnChangesSubject = new Subject<void>();
    ngAfterViewInitSubject = new Subject<void>();

    constructor(
        readonly userIdService: UserIdService,
        readonly sikoActiveCartService: SikoActiveCartService,
        readonly globalMessageService: GlobalMessageService,
        readonly actions: Actions,
        readonly activeCartService: ActiveCartFacade,
        readonly dialogService: SikoDialogService,
        protected sikoProductDisponibilityService: SikoProductDisponibilityService,
    ) {
        super(sikoProductDisponibilityService, activeCartService);
    }

    ngOnInit(): void {
        if (this.product?.disponibility?.selectedStoreDisponibility?.level !== undefined) {
            this.highlighted = this.highlighted && this.product.disponibility.selectedStoreDisponibility.level > 0;
        }

        this.lifecycleSubscription = combineLatest([
            this.ngAfterViewInitSubject.asObservable(),
            this.ngOnChangesSubject.asObservable()
                .pipe(
                    startWith(null),
                ),
        ])
            .subscribe(([_, changes]) => {
                this.displayValue?.valueAccessor?.writeValue(this.sikoProductDisponibilityService.calculateDisplayValue(this.control?.value, this.productUnitConversion));
            });

        this.cartUpdatedSubscription = this.sikoActiveCartService.updateEntrySubject$.subscribe((payload: CartModification) => {
            if (payload.quantity && payload.entry?.product?.code === this.product?.code) {
                this.control?.setValue(payload.quantity);
                this.displayValue?.valueAccessor?.writeValue(this.sikoProductDisponibilityService.calculateDisplayValue(payload.quantity, this.productUnitConversion));
            }
        });

        this.storeActionsSubscription = this.actions.pipe(
            ofType(CartActions.CART_UPDATE_ENTRY_SUCCESS, CartActions.CART_UPDATE_ENTRY_FAIL, CartActions.LOAD_CART_SUCCESS),
        )
            .subscribe((action: Action) => {
                if (action.type === CartActions.LOAD_CART_SUCCESS) {
                    this.isButtonDisabled = false;
                }

                if (action.type === CartActions.CART_UPDATE_ENTRY_SUCCESS) {
                    this.globalMessageService.add(
                        {
                            key: 'cartShared.updateEntrySuccess',
                        },
                        GlobalMessageType.MSG_TYPE_INFO,
                    );
                }
                else if (action.type === CartActions.CART_UPDATE_ENTRY_FAIL) {
                    this.globalMessageService.add(
                        {
                            key: 'cartShared.updateFailedMessage',
                        },
                        GlobalMessageType.MSG_TYPE_ERROR,
                    );
                }
            });


        this.cartUpdatedSubscription = this.sikoActiveCartService.updateEntrySubject$.subscribe((payload: CartModification) => {
            if (payload.quantity && payload.entry?.product?.code === this.product?.code) {
                this.control?.setValue(payload.quantity);
                this.displayValue?.valueAccessor?.writeValue(this.sikoProductDisponibilityService.calculateDisplayValue(payload.quantity, this.productUnitConversion));
            }
        });


    }

    ngAfterViewInit(): void {
        this.ngAfterViewInitSubject.next();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.productUnitConversion = Number(this.product?.unitData?.conversion ?? 1);
        this.minVal = this.productUnitConversion;
        this.displayValue?.valueAccessor?.writeValue(this.sikoProductDisponibilityService.calculateDisplayValue(this.control?.value, this.productUnitConversion));

        this.activeCartService.getActive()
            .subscribe((cart: SikoCart) => {
                this.cartId = cart.code;

                let cartProductsDisponibility: SikoProductDisponibility | undefined;
                let levelForCalculation: number | undefined;

                const isDeliveryModeOrPOSSelected =
                    cart.selectedDeliveryMode?.code === SIKO_SELECTED_DELIVERY_MODE_ENUM.BAL_NET || cart.selectedDeliveryPOS !== undefined;

                if (isDeliveryModeOrPOSSelected) {
                    cartProductsDisponibility = cart.selectedDeliveryPOS !== undefined
                        ? this.product?.disponibility?.selectedStoreDisponibility
                        : this.product?.disponibility;
                }
                else {
                    cartProductsDisponibility = this.product?.disponibility;
                }

                if (cartProductsDisponibility && !cartProductsDisponibility.isFIS) {
                    if (isDeliveryModeOrPOSSelected) {
                        levelForCalculation = cart.selectedDeliveryMode?.code === SIKO_SELECTED_DELIVERY_MODE_ENUM.BAL_NET
                            ? cartProductsDisponibility.maximum
                            : cartProductsDisponibility.level;
                    }
                    else {
                        levelForCalculation = cartProductsDisponibility.maximum;
                    }
                }
                else {
                    levelForCalculation = 9999;
                }

                this.max = this.sikoProductDisponibilityService.calculateMaxField(levelForCalculation ?? 1, this.productUnitConversion);
                this.ngOnChangesSubject.next();
            })
            .unsubscribe();
    }

    focusOut(event: FocusEvent): void {
        if (!this.control) {
            return;
        }

        let { value } = this.control;

        if (/^0+$/.test(value) || !value || value < 0 || this.control.value === null) {
            this.control.setValue(this.minVal);
            value = this.minVal;
            this.openModal(this.index);
        }

        let entry: SikoOrderEntry | undefined = {};

        if (this.product?.code) {
            this.activeCartService.getEntry(this.product.code)
                .subscribe(data => {
                    entry = data;
                })
                .unsubscribe();
        }

        const addToCartValue = this.sikoProductDisponibilityService.getActualQuantity(value, this.productUnitConversion);

        if (!this.control.pristine) {

            this.sikoActiveCartService.setRecalculating(true);

            if (this.index !== undefined) {
                this.sikoActiveCartService.setRecalculatingItem(this.index);
            }

            this.updateAmount(entry, addToCartValue);

            this.control.markAsPristine();
        }

        setTimeout(() => {
            this.sikoActiveCartService.setRecalculating(false);
            this.sikoActiveCartService.setRecalculatingItem(-1);
        }, 1000);
    }

    increment($event: Event, allow = true): void {
        if (!allow) {
            return;
        }

        super.increment($event, allow);
        this.isButtonDisabled = true;

        let entry: SikoOrderEntry | undefined = {};
        const productCode: string | undefined = this.product?.code;

        if (productCode) {
            this.activeCartService.getEntry(productCode)
                .subscribe(data => {
                    entry = data;
                })
                .unsubscribe();
        }

        if (this.index !== undefined) {
            this.sikoActiveCartService.setRecalculating(true);
            this.sikoActiveCartService.setRecalculatingItem(this.index);
        }

        setTimeout(() => {
            this.updateAmount(entry, this.control?.value);
            this.sikoActiveCartService.setRecalculating(false);
            this.sikoActiveCartService.setRecalculatingItem(-1);
        }, 1000);
    }

    decrement($event: Event): void {
        $event.stopPropagation();

        if (this.index !== undefined && this.control?.value) {
            this.sikoActiveCartService.setRecalculating(true);
            this.sikoActiveCartService.setRecalculatingItem(this.index);
        }

        let entry: SikoOrderEntry | undefined = {};
        const productCode: string | undefined = this.product?.code;

        if (productCode) {
            this.activeCartService.getEntry(productCode)
                .subscribe(data => {
                    entry = data;
                })
                .unsubscribe();
        }

        const target = $event.target as HTMLElement;

        if (this.control?.value - 1 <= 0) {
            this.sikoActiveCartService.setRecalculating(false);
            this.sikoActiveCartService.setRecalculatingItem(-1);
            this.openModal(this.index, target);

            setTimeout(() => {
                target.blur();
            }, 300);
        }
        else {
            super.decrement($event);
            this.isButtonDisabled = true;
            setTimeout(() => {
                this.updateAmount(entry, this.control?.value);
                this.sikoActiveCartService.setRecalculating(false);
                this.sikoActiveCartService.setRecalculatingItem(-1);
            }, 1000);
        }
    }

    updateAmount(entry: SikoOrderEntry | undefined, value: number): void {
        if (entry?.entryNumber !== undefined) {
            let userId = 'current';

            this.userIdService.getUserId()
                .subscribe(data => userId = data)
                .unsubscribe();

            this.sikoActiveCartService.sikoUpdateEntry(
                userId,
                this.cartId ?? 'current',
                entry.entryNumber.toString(),
                value,
            );
        }
    }

    openModal(index: number | undefined, target?: HTMLElement): void {
        const modalRef: NgbModalRef = this.dialogService.open(SikoRemoveCartEntryComponent, {
            windowClass: 'siko-dialog-window',
            size: 'sm',
        });
        const modalInstance: SikoRemoveCartEntryComponent = modalRef.componentInstance as SikoRemoveCartEntryComponent;

        modalInstance.index = index;
        modalInstance.modalIsOpen = true;

        modalRef.dismissed.subscribe(() => {
            setTimeout(() => {
                target?.blur();
            }, 10);
        });
    }

}
