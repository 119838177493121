<div class='d-flex nav-items'>
  <div class='blog-nav'>
    <a (click)='navigateToBlogArticles()'>
      <img [src]='"blog/blog-nav-icon.svg" | sikoIconAsset' alt='Blog nav'>
      <span class='text'>{{'blog.nav' | cxTranslate}}</span>
    </a>
  </div>
  <div class='news-link' (click)="routingService.goByUrl('/aktuality')">
    <div class='wrapper d-flex justify-content-center align-items-center'>
      <div class='icon-container'>
        <img class='icon-message' [src]='"header/news-message.svg" | sikoIconAsset' alt='News message icon'>
      </div>
      <h4 class='mb-0'>{{ 'news.title' | cxTranslate }}</h4>
      <app-bubble-item-counter
        [counter]='unseenMessagesCount$'
        [type]='"yellow"'
      ></app-bubble-item-counter>
    </div>
  </div>
</div>
