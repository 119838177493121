import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService, ProductSearchPage, SearchConfig } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SikoOccQuickOrderAdapter {
    private readonly backendBaseUrl: string;

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
    ) {
        this.backendBaseUrl = this.occEndpoints.getBaseUrl();
    }

    search(
        query: string,
        searchConfig: SearchConfig,
    ): Observable<ProductSearchPage> {
        return this.http.get<ProductSearchPage>(`${this.backendBaseUrl}/b2b/products/search-only-products?fields=FULL`, {
            params: { query, ...searchConfig },
        });
    }

}
