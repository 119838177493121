import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OccConfig } from '@spartacus/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT_ACTION_TYPE, DOCUMENT_ICON_TYPE, FILE_EXTENSION } from '@siko/constants';
import { B2bCommonModule, SikoFileDownloadService } from '@siko/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-document-item',
    templateUrl: './document-item.component.html',
    styleUrls: ['./document-item.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule
    ]
})
export class SikoDocumentItemComponent {
    @Input() action?: DOCUMENT_ACTION_TYPE;
    @Input() icon?: DOCUMENT_ICON_TYPE;
    @Input() extension?: string;
    @Input() url?: string;
    @Input() name?: string;

    constructor(
        private readonly fileDownloadService: SikoFileDownloadService,
        private readonly backendConfig: OccConfig,
        protected http: HttpClient
    ) {}

    onClick(): void {
        if (this.getActionType() === DOCUMENT_ACTION_TYPE.DOWNLOAD) {
            this.downloadDocument(this.url, this.name);
        }
        else {
            this.displayDocument(this.url);
        }
    }

    downloadDocument(url: string | undefined, name: string | undefined): void {
        if (!url) {
            return;
        }

        const backendBaseUrl: string | undefined = this.backendConfig.backend?.occ?.baseUrl;

        if (backendBaseUrl) {
            const downloadUrl = `${backendBaseUrl}${url}`;

            this.fileDownloadService.download(downloadUrl, name);
        }
    }

    displayDocument(url: string | undefined): void {
        if (!url) {
            return;
        }

        const backendBaseUrl: string | undefined = this.backendConfig.backend?.occ?.baseUrl;

        if (backendBaseUrl) {
            const modifiedUrl = url.replace('attachment=true', 'attachment=no');

            window.open(
                backendBaseUrl + modifiedUrl,
                '_blank',
                'width=1024,height=768,resizable=yes,scrollbars=yes'
            );
        }
    }

    getIconClass(): string {
        if (!this.icon) {
            return '';
        }

        switch (this.icon) {
            case DOCUMENT_ICON_TYPE.BY_TYPE:
                return this.getIconClassByType();
            case DOCUMENT_ICON_TYPE.MODEL:
                return 'icon icon--model-icon';
            case DOCUMENT_ICON_TYPE.DELIVERY_NOTE:
                return 'icon icon--delivery-icon';
            case DOCUMENT_ICON_TYPE.INVOICE:
                return 'icon icon--invoice-icon';
            case DOCUMENT_ICON_TYPE.ORDER_CONFIRMATION:
                return 'icon icon--confirmation-icon';
            case DOCUMENT_ICON_TYPE.PROFORMA_INVOICE:
                return 'icon icon--proforma-icon';
            default:
                return '';
        }
    }

    getActionClass(): string {
        const action = this.getActionType();

        switch (action) {
            case DOCUMENT_ACTION_TYPE.PREVIEW:
                return 'action-icon action-icon--preview';
            case DOCUMENT_ACTION_TYPE.DOWNLOAD:
                return 'action-icon action-icon--download';
        }
    }

    getExtension(): string | undefined {
        return this.extension?.toUpperCase();
    }

    private getActionType(): DOCUMENT_ACTION_TYPE {
        if (this.action) {
            return this.action;
        }

        if (this.extension === FILE_EXTENSION.PDF) {
            return DOCUMENT_ACTION_TYPE.PREVIEW;
        }

        return DOCUMENT_ACTION_TYPE.DOWNLOAD;
    }

    private getIconClassByType(): string {
        const extension = this.getExtension();

        if (!extension) {
            return 'icon icon--file-icon';
        }

        switch (extension) {
            case 'PDF':
                return 'icon icon--pdf-icon';
            case 'TXT':
            case 'RTF':
            case 'DOC':
            case 'DOCX':
                return 'icon icon--document-icon';
            case 'CSV':
            case 'XLS':
            case 'XLSX':
                return 'icon icon--table-icon';
            case 'BMP':
            case 'GIF':
            case 'JPG':
            case 'PNG':
            case 'SVG':
                return 'icon icon--image-icon';
            case 'ZIP':
            case 'RAR':
            case 'TAR':
            case '7Z':
                return 'icon icon--archive-icon';
            case 'XML':
                return 'icon icon--xml-icon';
            default:
                return 'icon icon--file-icon';
        }
    }
}
