import { Action } from '@ngrx/store';
import { SikoUnseenNewsState } from '@siko/features/news/store/siko-news-state';
import { SikoNewsActions } from '../actions';

export const INITIAL_STATE: SikoUnseenNewsState = {
    unseenNewsCount: undefined
};

export const sikoUnseenNewsReducer = (
    state = INITIAL_STATE,
    action: Action
): SikoUnseenNewsState => {
    switch (action.type) {
        case SikoNewsActions.LOAD_UNSEEN_NEWS_COUNT_SUCCESS: {
            const unseenNewsCount = (action as SikoNewsActions.LoadUnseenNewsCountSuccess).payload;

            return {
                ...state,
                unseenNewsCount
            };
        }
    }

    return state;
};
