import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule, TranslatePipe, UrlModule, UrlPipe } from '@spartacus/core';
import { SikoAtomicComponentsModule } from '@siko/components';
import { SikoSharedModule } from '@siko/common';
import { SikoSlugPipe } from '@siko/shared/pipes/slug.pipe';
import { SikoProductUrlPipe } from '@siko/shared/pipes/product-url.pipe';
import { SikoIconAssetPipe } from './pipes/icon-asset.pipe';
import { SikoDiscountPercentStringPipe } from './pipes/discount-percent-string.pipe';
import { SikoNumericPipe } from '@siko/shared/pipes/numeric.pipe';
import { SikoPricePipe } from '@siko/shared/pipes/price.pipe';
import { SikoImagePipe } from '@siko/shared/pipes/image.pipe';
import { SikoBonusProgramComponentVisibilityDirective } from '@siko/shared/directives/bonus-program.directive';


@NgModule({
    declarations: [
        SikoIconAssetPipe,
        SikoPricePipe,
        SikoDiscountPercentStringPipe,
        SikoNumericPipe,
        SikoImagePipe,
        SikoBonusProgramComponentVisibilityDirective
    ],
    imports: [
        // Angular modules
        CommonModule,
        // Spartacus modules
        I18nModule,
        UrlModule,
        // Shared module for B2B a B2C project
        SikoAtomicComponentsModule,
        SikoSharedModule,
    ],
    providers: [
        UrlPipe,
        TranslatePipe,
        SikoSlugPipe,
        SikoProductUrlPipe,
        SikoIconAssetPipe,
    ],
    exports: [
        CommonModule,
        I18nModule,
        SikoAtomicComponentsModule,
        SikoIconAssetPipe,
        SikoDiscountPercentStringPipe,
        SikoSharedModule,
        SikoNumericPipe,
        SikoPricePipe,
        SikoImagePipe,
        SikoBonusProgramComponentVisibilityDirective
    ],
})
export class B2bCommonModule {
}
