import { Component, OnDestroy } from '@angular/core';
import { ImportEntriesDialogComponent } from '@spartacus/cart/import-export/components';
import { LaunchDialogService } from '@spartacus/storefront';
import { ProductData, ProductImportSummary, AddOrderEntriesContext, ActiveCartFacade } from '@spartacus/cart/base/root';
import { CartActions, StateWithMultiCart } from '@spartacus/cart/base/core';
import { SikoActiveCartOrderEntriesContext } from '@siko/features/cart/active-cart-order-entries-context';
import { SikoImportSummaryService } from '@siko/features/cart/services/siko-import-summary.service';
import { SikoNewSavedCartOrderEntriesContext } from '@siko/features/cart/new-saved-cart-order-entries-context';
import { RouterState, RoutingService, UserIdService } from '@spartacus/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { ActivatedRoute, Router } from '@angular/router';
import { SikoDialogService } from '@siko/shared';
import { SikoAutoUnsubscribe } from '@siko/common';

@SikoAutoUnsubscribe([
    'savedCartContextSubscription',
    'importSummarySubscription',
    'routerStateSubscription',
    'activeCartContextSubscription',
])
@Component({
    selector: 'app-import-entries-dialog',
    templateUrl: './import-entries-dialog.component.html',
})
export class SikoImportEntriesDialogComponent extends ImportEntriesDialogComponent implements OnDestroy {

    cartPage = '/cart';
    savedCartContextSubscription?: Subscription;
    activeCartContextSubscription?: Subscription;
    importSummarySubscription: Subscription = this.sikoImportSummaryService.summaryObs$.subscribe(
        (input: ProductImportSummary) => {
            if (!input.loading && input.count > 0) {
                this.updatePageContextAfterImport();
            }
        },
    );

    constructor(
        private readonly userIdService: UserIdService,
        private readonly sikoImportSummaryService: SikoImportSummaryService,
        private readonly activeCartContext: SikoActiveCartOrderEntriesContext,
        private readonly savedCartContext: SikoNewSavedCartOrderEntriesContext,
        launchDialogService: LaunchDialogService,
        protected activeCartService: ActiveCartFacade,
        protected store: Store<StateWithMultiCart>,
        protected savedCartService: SavedCartFacade,
        private readonly routingService: RoutingService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        readonly dialogService: SikoDialogService,
    ) {
        super(launchDialogService);
    }

    updatePageContextAfterImport(): void {
        this.routingService.getRouterState()
            .subscribe((router: RouterState) => {
                if (router.state.url.includes(this.cartPage)) {
                    this.activeCartService.getActiveCartId()
                        .subscribe(cartId => {
                            let userId = 'current';

                            this.userIdService.getUserId()
                                .subscribe(data => userId = data)
                                .unsubscribe();

                            this.store.dispatch(new CartActions.LoadCart({
                                userId,
                                cartId,
                            }));
                        });
                }
                else {
                    this.savedCartService.loadSavedCarts();
                }
            })
            .unsubscribe();
    }

    importProductsToSavedCart(
        context: AddOrderEntriesContext,
        {
            products,
            savedCartInfo,
        }: {
            products: ProductData[];
            savedCartInfo?: {
                name: string;
                description: string;
            };
        },
    ): void {
        this.formState = false;
        this.sikoImportSummaryService.resetSummary(products.length);
        this.savedCartContextSubscription = this.savedCartContext
            .add(products, savedCartInfo)
            .subscribe();
    }

    importProductsToActiveCart(
        context: AddOrderEntriesContext,
        { products }: {
            products: ProductData[];
        },
    ): void {
        this.formState = false;
        this.sikoImportSummaryService.resetSummary(products.length);
        this.activeCartContextSubscription = this.activeCartContext
            .add(products)
            .subscribe();
    }

    close(reason: string): void {
        super.close(reason);

        this.dialogService.closeActiveModal(reason);
    }

    ngOnDestroy(): void {
        this.importSummarySubscription.unsubscribe();
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.importCart) {
                this.router.navigate([], {
                    relativeTo: this.activatedRoute,
                    queryParams: [],
                });
            }
        })
            .unsubscribe();
    }

}
