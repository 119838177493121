<ng-container *bonusProgramVisibility>
  <div id='bonus-prgoram-record-modal'>
    <app-dialog-header class='siko-color-base-grey-3'>
    <span
      class='siko-color-grey-3'>{{ 'bonusProgram.modal.header_' + typeOfRecord  | cxTranslate }} </span> {{ record?.id }}
    </app-dialog-header>
    <div class='siko-dialog-body mt-5'>
      <div class='d-flex justify-content-between' *ngIf='record?.invoiceOrders'>
        <div class='sub-title'
             [innerHTML]='("bonusProgram.modal.subTitle" | cxTranslate : {count: record?.invoiceOrders?.length}) + record?.invoiceOrders?.join(", ")'>
        </div>
      </div>
      <app-record-item-list
        [entries]='record?.entries'
        [typeOfRecord]='typeOfRecord'>
      </app-record-item-list>
    </div>
  </div>
</ng-container>
