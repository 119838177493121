<ng-container *ngIf='breakpointObserver.breakpointChange$ | async'>
  <div class='d-flex'>
    <ng-container *ngIf='breakpointObserver.isBreakpointActive(["xl","xxl"])'>
      <div class='left-content'>
        <cx-page-slot position='SikoBlogCategoriesSlot'></cx-page-slot>
        <cx-page-slot position='SikoBlogFiltersSlot'></cx-page-slot>
        <cx-page-slot position='SikoBlogSideSlot'></cx-page-slot>
      </div>
    </ng-container>
    <div class='right-content'>
      <cx-page-slot position='SikoBlogNarrowBlogsSlot'></cx-page-slot>
    </div>
  </div>
  <div class='d-block'>
    <cx-page-slot position='SikoBlogWideContentSlot-blog'></cx-page-slot>
  </div>
</ng-container>
