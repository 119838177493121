import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { B2bCommonModule, SikoDialogService } from '@siko/shared';
import { SikoOrderOverviewService } from '@siko/features/order-overview/occ/services/order-overview.service';
import { NgbModalRef, NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SikoAutoUnsubscribe } from '@siko/common';
import { Subscription } from 'rxjs';
import { SikoCart } from '@siko/models';
import { SikoActionsWithCartService } from '@siko/features/shared-components/actions-with-cart-dialog/services/actions-with-cart.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { SikoActionsWithCartDialogComponent } from '@siko/features/shared-components/actions-with-cart-dialog/actions-with-cart-dialog.component';

@SikoAutoUnsubscribe(['cartSubscription', 'actionsWithCartSubscription'])
@Component({
    selector: 'app-reorder-button',
    standalone: true,
    imports: [B2bCommonModule, NgbTooltipModule],
    templateUrl: './reorder-button.component.html',
    styleUrls: ['./reorder-button.component.scss'],
})
export class SikoReorderButtonComponent implements OnInit {
    @ViewChild(NgbTooltip) tooltip?: NgbTooltip;

    @Input() orderCode?: string;
    @Input() displayBlock = false;
    cartSubscription?: Subscription;
    actionsWithCartSubscription?: Subscription;
    actualCart?: SikoCart;

    constructor(
        public reorderService: SikoOrderOverviewService,
        readonly actionWithCartService: SikoActionsWithCartService,
        readonly dialogService: SikoDialogService,
        readonly activeCartService: ActiveCartFacade,
    ) {
    }

    ngOnInit(): void {
        this.cartSubscription = this.activeCartService.getActive()
            .subscribe(cart => {
                this.actualCart = cart;
            });

    }

    reorder(orderCode: string | undefined): void {
        this.orderCode = orderCode;

        this.actionsWithCartSubscription = this.actionWithCartService.isAllowedContinue$()
            .subscribe((data: boolean) => {
                if (data) {
                    this.reorderService.reorder(this.orderCode);
                    this.actionWithCartService.setAllowedContinue(false);
                    this.actionsWithCartSubscription?.unsubscribe();
                }
            });

        if (this.actualCart?.entries && this.actualCart.entries.length > 0) {
            const modalRef: NgbModalRef = this.dialogService.open(SikoActionsWithCartDialogComponent, {
                windowClass: 'siko-dialog-window',
                size: 'sm',
            });

            const modalInstance: SikoActionsWithCartDialogComponent = modalRef.componentInstance as SikoActionsWithCartDialogComponent;

            modalInstance.cart = this.actualCart;

            this.tooltip?.close();
        }
        else {
            this.actionWithCartService.setAllowedContinue(true);
        }

    }

}
