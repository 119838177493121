import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SikoOpeningSchedule, SikoOrder } from '@siko/models';
import { RoutingService } from '@spartacus/core';
import {
    SikoOpeningHoursDialogComponent
} from '@siko/features/shared-components/opening-hours-dialog/opening-hours-dialog.component';
import { SikoDialogService, SikoHelperFunctionsService } from '@siko/shared';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetailsService } from '@spartacus/order/components';

@Component({
    selector: 'app-order-details-items',
    templateUrl: './order-detail-items.component.html',
    styleUrls: ['./order-detail-items.component.scss']
})
export class SikoOrderDetailItemsComponent {

    @Input() sikoOrder$?: Observable<SikoOrder>;

    constructor(
    public sikoHelperService: SikoHelperFunctionsService,
    readonly dialogService: SikoDialogService,
    readonly routingService: RoutingService,
    readonly orderDetailsService: OrderDetailsService
    ) {
    }

    get getOrder$(): Observable<SikoOrder> {
        return this.sikoOrder$ != null ? this.sikoOrder$ : this.orderDetailsService.getOrderDetails();
    }

    goToPLPPage(pointOfService: string) {
        this.routingService.goByUrl('/c/1?query=:relevance:allCategories:1:availableInStores:' + pointOfService);
    }

    openOpeningHoursModal(
        openingSchedule: SikoOpeningSchedule,
        pointOfServiceName: string | undefined
    ): void {
        const modalRef: NgbModalRef = this.dialogService.open(SikoOpeningHoursDialogComponent, {
            windowClass: 'siko-dialog-window',
            size: 'sm'
        });

        modalRef.componentInstance.openingHours = openingSchedule;
        modalRef.componentInstance.pointOfServiceName = pointOfServiceName;
    }
}
