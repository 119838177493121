import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ProductSummaryComponent } from '@spartacus/storefront';
import { isNotNullable, ProductScope } from '@spartacus/core';
import { SikoProduct } from '@siko/models';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SikoAutoUnsubscribe } from '@siko/common';
import { isProductForSale } from '../../../product-utils';

@SikoAutoUnsubscribe(['subscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-summary',
    templateUrl: './product-summary.component.html',
})
export class SikoProductSummaryComponent extends ProductSummaryComponent implements OnInit {

    forSale?: boolean = true;
    sikoProduct?: SikoProduct;
    subscription?: Subscription;

    ngOnInit(): void {
        this.subscription = this.currentProductService.getProduct(ProductScope.DETAILS)
            .pipe(
                filter(isNotNullable),
            )
            .subscribe((product: SikoProduct) => {
                this.forSale = true;

                if (product.disponibility && product.availableIn && product.sapStatus) {
                    this.forSale = isProductForSale(product.disponibility, +product.availableIn, product.sapStatus);
                }

                this.sikoProduct = product;
            });
    }

}
