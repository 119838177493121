<ng-container *ngIf="facetList$ | async as facetList">
  <div class='active-facets' *ngIf='facetList.activeFacets && facetList.activeFacets.length > 0'>
    <span>{{"siko.facet.activeList"|cxTranslate}}</span>
    <ng-container *ngFor="let facet of facetList?.activeFacets">
      <a
        *ngIf='facet.facetName !== "allCategories"'
        #activeFacet
        [attr.data-testId]='$any("active-facet-" + facet.facetValueCode)'
        routerLink="{{virtualCategory ? '/c/'+virtualCategory : './'}}"
        [queryParams]="getLinkParams(facet)"
        role="button"
        [attr.aria-label]="
      'productList.activeFilter'|cxTranslate: { filter: facet.facetValueName }
    "
      >
        <div class='active-facet'>
          <div class='header-facet'>
            <div class='name'>
              {{facet.facetName}}:
            </div>
            <cx-icon aria-hidden="true" [type]="closeIcon"></cx-icon>
          </div>
          <span>{{ facet.facetValueName }}</span>
        </div>
      </a>
    </ng-container>
    <a class="cancel-facets" (click)="plpCommunication.cancelAllFilters(virtualCategory)">
      {{"siko.facet.cancelFacets" | cxTranslate}}
    </a>
  </div>


</ng-container>
