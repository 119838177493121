<ng-container *ngIf='state$ | async as state' >
  <ng-container *ngIf='sikoFacet.values && sikoFacet.values.length > 0'>
    <button class='heading' (click)='toggleGroup($event)'>
      <span>
        <span class='heading-label {{activeFacet}}'>{{ sikoFacet.name }}</span>
        <span *ngIf='facet.description'
              ngbTooltip
              role='button'
              tabindex='0'
              placement='auto'
              container='body'
              sikoTooltipText='{{ sikoFacet.description }}'
              class='tooltip-icon'
        >
          <img [src]='"plp/tooltip.svg" | sikoIconAsset' alt='tooltip icon'>
        </span>
      </span>
      <cx-icon class='collapse-icon'></cx-icon>
    </button>
    <a
      *ngIf='predefinedPointOfServiceFacetValue'
      #facetValue
      routerLink="{{virtualCategory ? '/c/'+virtualCategory : './'}}"
      [queryParams]='getSikoLinkParams(predefinedPointOfServiceFacetValue)'
      class='value'
      [cxFocus]='$any(predefinedPointOfServiceFacetValue.name)'
      [class.selected]='predefinedPointOfServiceFacetValue.selected'
      (keydown.space)='openLink($any($event))'
      [attr.data-testId]='$any("facet-value-" + predefinedPointOfServiceFacetValue.code)'
    >
      <span>
        <span class='label'>{{"siko.onPredefinedPointOfService"| cxTranslate}}
          <div>( {{ predefinedPointOfServiceFacetValue.name }} )
            <span class='count'>{{predefinedPointOfServiceFacetValue.count}}</span>
          </div>
        </span>
      </span>
    </a>
    <ng-container *ngIf='!this.sikoFacet.code?.includes("netPriceRange")'>
      <div class='search-input'
           *ngIf='filteredData && filteredData.length >= minItemsToShowingSearch && !predefinedPointOfServiceFacetValue?.selected'>
        <div class='search-icon'><img [src]='"find.svg" | sikoIconAsset' alt='Search icon'></div>
        <input name='searchFacet' placeholder='{{"siko.facet.typeSearchString"|cxTranslate}}' [(ngModel)]='searchValue'
               (input)='onSearchChange($any(test.value))' #test />
      </div>
    </ng-container>
    <div class='facet-not-found' *ngIf='facetNotFound'>
      {{"siko.facet.notFound"| cxTranslate}}
    </div>
    <ng-container *ngFor='let value of filteredData | slice: 0:state.topVisible'>
      <a
        #facetValue
        routerLink="{{virtualCategory ? '/c/'+virtualCategory : './'}}"
        [queryParams]='getLinkParams(value)'
        class='value {{predefinedPointOfServiceFacetValue?.name === value.name || predefinedPointOfServiceFacetValue?.selected  ? "d-none" : ""}}'
        [cxFocus]='$any(value.name)'
        [class.selected]='value.selected'
        (keydown.space)='openLink($any($event))'
        [attr.data-testId]='$any("facet-value-" + value.code)'
      >
      <span>
        <span class='label'>{{ value.name }}</span>
        <span class='count'>{{ value.count }}</span>
      </span>
      </a>
    </ng-container>
    <div class='more'
         *ngIf='!searching && !predefinedPointOfServiceFacetValue?.selected'>
      <ng-container *ngFor='let value of sikoFacet.values | slice: $any(state.topVisible):state.maxVisible'>
        <a
          #facetValue
          routerLink='./'
          [queryParams]='getLinkParams(value)'
          class='value {{predefinedPointOfServiceFacetValue?.name === value.name ? "d-none" : ""}}'
          [cxFocus]='$any(value.name)'
          [class.selected]='value.selected'
          (keydown.space)='openLink($any($event))'
          [attr.data-testId]='$any("facet-value-" + value.code)'
        >
        <span>
          <span class='label'>{{ value.name }}</span>
          <span class='count'>{{ value.count }}</span>
        </span>
        </a>
      </ng-container>

      <button
        *ngIf='state.maxVisible && state.topVisible && state.maxVisible > state.topVisible'
        (click)='decreaseVisibleValues()'
        class='cx-action-link more-facet'
        [cxFocus]='$any("moreorless")'
        [attr.data-testId]='$any("less-facets-" + sikoFacet.code)'
      >
        <siko-link
          [phoneLink]=true
          [mode]='$any("default")'
          [style]='$any("primary")'
          [disableLink]=true
          label='{{"siko.hide"|cxTranslate}}'>
        </siko-link>
        <img [src]='"arrow_up.svg" | sikoIconAsset' alt='Arrow icon'>
      </button>

      <button
        *ngIf='facet.values !== undefined && state.maxVisible !== undefined && state.maxVisible > 0 && state.maxVisible < sikoFacet.values.length'
        (click)='increaseVisibleValues()'
        class='cx-action-link less-facet'
        [cxFocus]='$any("moreorless")'
        [attr.data-testId]='$any("more-facets-" + sikoFacet.code)'
      >
        <siko-link
          [phoneLink]=true
          [mode]='$any("default")'
          [style]='$any("primary")'
          [disableLink]=true
          label='{{"siko.facet.showMoreFacets"|cxTranslate}} {{facet.values && state.topVisible ?  sikoFacet.values.length - state.topVisible : 0}}'>
        </siko-link>
        <img [src]='"arrow_down.svg" | sikoIconAsset' alt='Arrow icon'>
      </button>
    </div>
  </ng-container>
</ng-container>
