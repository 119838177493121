import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CSAgentLoginFormComponent } from '@spartacus/asm/components';
import { Validators } from '@angular/forms';
import { SikoCustomFormValidators } from '@siko/features/my-account/update-password/components/update-password/validator/custom-form-validators';

@Component({
    selector: 'siko-csagent-login-form',
    templateUrl: './csagent-login-form.component.html',
    styleUrls: ['./csagent-login-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoCsagentLoginFormComponent extends CSAgentLoginFormComponent implements OnInit {

    ngOnInit(): void {
        this.csAgentLoginForm = this.fb.group({
            userId: ['', [Validators.required, SikoCustomFormValidators.forbidEmailValidator]],
            password: ['', [Validators.required]],
        });
    }

}
