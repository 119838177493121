import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalMessageType, HttpErrorHandler, HttpResponseStatus, Priority } from '@spartacus/core';

@Injectable({
    providedIn: 'root',
})
export class SikoAuthenticationErrorHandler extends HttpErrorHandler {
    responseStatus = HttpResponseStatus.UNAUTHORIZED;

    handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
        const authenticationRequest = response.url?.includes('/authorizationserver/oauth/token');

        if (!authenticationRequest) {
            return;
        }

        const errorMsg = this.getErrorMessage(response);

        if (errorMsg !== undefined) {
            this.globalMessageService.add({ key: errorMsg }, GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);
        }
    }

    getPriority(): Priority {
        return Priority.HIGH;
    }

    private getErrorMessage(response: HttpErrorResponse): string | undefined {
        let errorMsg;

        switch (response.error?.error_description) {
            case 'Missing ID':
                errorMsg = 'httpHandlers.authenticationErrors.missingId';
                break;
            case 'Missing store':
                errorMsg = 'httpHandlers.authenticationErrors.missingStore';
                break;
            case 'Wrong baseStore':
                errorMsg = 'httpHandlers.authenticationErrors.wrongBaseStore';
                break;
            case 'Unknown baseStore':
                errorMsg = 'httpHandlers.authenticationErrors.badRequest';
                break;
            case 'Only Employee may login into ASM':
                errorMsg = 'httpHandlers.authenticationErrors.wrongUserModel';
                break;
            case 'ASM agent missing roles':
                errorMsg = 'httpHandlers.authenticationErrors.asmAgentMissingRoles';
        }

        return errorMsg;
    }
}
