<ng-container *ngIf='model$ | async as model'>
  <ng-container *ngIf='!model.virtualCategoryName'>
    <div class='container content-empty-search-result'>
    <div class='row'>
      <div class='col-lg-8'>
        <h1 class='search-term siko-color-grey-3' *ngIf='model.freeTextSearch !== ""' [ngClass]='notShowCategoriesBlock ? "without-categories" : ""'>
          {{'header.search.searching' | cxTranslate}} "<span class='term'>{{model.freeTextSearch}}</span>"
          <div class='empty-search-info' *ngIf='isEmptySearchResult'>
            {{"header.search.emptySearchInfo" | cxTranslate}}
          </div>
        </h1>
      </div>
      <div class='col-lg-4 mt-5' *ngIf='isEmptySearchResult && model.freeTextSearch !== ""'>
        <div class='empty-search-box'>
          <div class='header'>
            <h3>
              {{"header.search.searchTips" | cxTranslate}}
            </h3>
            <div class='points'>
              <div>
                <img [src]='"search-info-check.svg" | sikoIconAsset' alt='Check mark icon'>
                <span>{{"header.search.checkSpellingErrors" | cxTranslate}}</span>
              </div>
              <div>
                <img [src]='"search-info-check.svg" | sikoIconAsset' alt='Check mark icon'>
                <span>{{"header.search.useSimilarTerm" | cxTranslate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class='categories-block' [ngClass]='
  ( notShowCategoriesBlock && model.freeTextSearch !== "" ) || (model.freeTextSearch === "" && (!model?.category || (model?.category?.featureCategories && model?.category?.featureCategories?.length === 0))) ? "d-none" : ""'>
      <div *ngIf='model.freeTextSearch === ""' class='cx-page-categories'>
      <siko-text-subheader text='{{"siko.category"| cxTranslate}}'></siko-text-subheader>
      <div class='cx-page-categories-container'>
        <a class='container d-none d-md-flex' *ngFor='let category of model?.category?.featureCategories'
           [routerLink]='category.url'
           [attr.data-testId]='$any("feature-category-" + category.code)'>
          <div class='image'>
            <app-media
              class='img-responsive'
              [container]='{ image: $any(category.thumbnail)}'
            ></app-media>
          </div>
          {{category.name}}
        </a>
        <div class='d-md-none col-12 p-0'>
          <ngb-carousel #carousel
                        (touchstart)='posIni = $event.changedTouches[0].pageX'
                        (touchend)='move($event.changedTouches[0].pageX)'>
            <ng-template ngbSlide *ngFor='let category of arrayOfCategories'>
              <div class='categories-slider'>
                <a class='container' *ngFor='let subCategory of category' [routerLink]='subCategory.url'>
                  <div class='image'>
                    <app-media
                      class='img-responsive'
                      [container]='{ image: $any(subCategory.thumbnail)}'
                    ></app-media>
                  </div>
                  {{subCategory.name}}
                </a>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
    </div>
    <div *ngIf='model.freeTextSearch !== ""' class='cx-page-categories search-results'>
      <ul ngbNav #nav='ngbNav' [destroyOnHide]='false' class='nav-tabs' [activeId]='activeId'>
        <img class='prev' [src]='"arrow_up.svg" | sikoIconAsset' alt='Arrow icon' (click)='switchTab("back")'>
        <ng-container *ngIf='model.sikoCategoriesCount && model.sikoCategoriesCount > 0'>
          <li [ngbNavItem]='1' [destroyOnHide]='false' class='serp-categories'>
            <a class='d-md-block d-none' ngbNavLink><img [src]='"serp-categories.svg" | sikoIconAsset' alt='Categories icon'> {{'siko.categories' | cxTranslate}} <span
              class='count'>{{model.sikoCategoriesCount}}</span></a>
            <a class='d-md-none d-block' ngbNavLink>
              <div class='mobile-nav'>
                <div class='header'>
                  <img [src]='"serp-categories.svg" | sikoIconAsset' alt='Categories icon'>
                  <span
                    class='count'>{{model.sikoCategoriesCount}}</span>
                </div>
                {{'siko.categories' | cxTranslate}}
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class='cx-page-categories-container'
                   [ngClass]='model.sikoCategoriesCount > 10 && !isShowMoreCategories ? "box-shadow" : ""'>
                <a class='container' *ngFor='let category of model?.sikoCategories'
                   [routerLink]='category.url'>
                  <div class='image'>
                    <app-media
                      class='img-responsive'
                      [container]='{ image: $any(category.thumbnail)}'
                    ></app-media>
                  </div>
                  {{category.name}}
                </a>
              </div>
              <div class='col-12 text-center shadow-row'
                   *ngIf='!isShowMoreCategories && model.sikoCategoriesCount && model.sikoCategoriesCount > 10'>
                <siko-link
                  [phoneLink]='true'
                  [mode]='$any("default")'
                  [style]='$any("primary")'
                  [disableLink]=true
                  (click)='isShowMoreCategories = !isShowMoreCategories'
                  label="{{'siko.showMore' | cxTranslate}}"></siko-link>
              </div>
            </ng-template>
          </li>
        </ng-container>
        <li [ngbNavItem]='2' *ngIf='model.sikoSeriesCount && model.sikoSeriesCount > 0' class='serp-series'>
          <a class='d-md-block d-none' ngbNavLink><img [src]='"serp-series.svg" | sikoIconAsset' alt='Brands icon'> {{'siko.series' | cxTranslate}} <span
            class='count'>{{model.sikoSeriesCount}}</span></a>
          <a class='d-md-none d-block' ngbNavLink>
            <div class='mobile-nav'>
              <div class='header'>
                <img [src]='"serp-series.svg" | sikoIconAsset' alt='Brands icon'>
                <span
                  class='count'>{{model.sikoSeriesCount}}</span>
              </div>
              {{'siko.series' | cxTranslate}}
            </div>
          </a>
          <ng-template ngbNavContent>
            <div
              class='series-item '
              [ngClass]='model.sikoSeriesCount > 6 && !isShowMoreSeries ? "box-shadow" : ""'
            >
              <ng-container *ngFor='let serie of model?.sikoSeries'>
                <app-top-series
                  [localizedUrl]='$any(serie?.url)'
                  [linkName]=$any(serie?.name)
                  [imageUrl]='getImageUrl(serie?.image?.url)'></app-top-series>
              </ng-container>
            </div>
            <div class='col-12 text-center shadow-row'
                 *ngIf='!isShowMoreSeries && model.sikoSeriesCount && model.sikoSeriesCount > 6'>
              <siko-link
                [phoneLink]='true'
                [mode]='$any("default")'
                [style]='$any("primary")'
                [disableLink]=true
                (click)='isShowMoreSeries = !isShowMoreSeries'
                label="{{'siko.showMore' | cxTranslate}}"></siko-link>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]='3' *ngIf='model.sikoArticlesCount && model.sikoArticlesCount > 0'>
          <a class='d-md-block d-none' ngbNavLink><img [src]='"serp-articles.svg" | sikoIconAsset' alt='Brands icon'>{{'siko.articles' | cxTranslate}} <span
            class='count'>{{model.sikoArticlesCount}}</span></a>
          <a class='d-md-none d-block' ngbNavLink>
            <div class='mobile-nav'>
              <div class='header'>
                <img [src]='"serp-articles.svg" | sikoIconAsset' alt='Categories icon'>
                <span
                  class='count'>{{model.sikoArticlesCount}}</span>
              </div>
              {{'siko.categories' | cxTranslate}}
            </div>
          </a>
          <ng-template ngbNavContent let-active>
            <div class='cx-page-categories-container'>
              <a class='container d-none d-md-flex' *ngFor='let article of model?.sikoArticles'
                 [routerLink]='article.url'>
                <div class='image'>
                  <app-media
                    class='img-responsive'
                    [container]='{ image: $any(article.thumbnail)}'
                  ></app-media>
                </div>
                {{article.name}}
              </a>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]='4' *ngIf='model.sikoBrandsCount && model.sikoBrandsCount > 0' class='serp-brands'>
          <a class='d-md-block d-none' ngbNavLink><img [src]='"serp-brands.svg" | sikoIconAsset' alt='Brands icon'> {{'siko.brands' | cxTranslate}} <span
            class='count'>{{model.sikoBrandsCount}}</span></a>
          <a class='d-md-none d-block' ngbNavLink>
            <div class='mobile-nav'>
              <div class='header'>
                <img [src]='"serp-brands.svg" | sikoIconAsset' alt='Brands icon'>
                <span
                  class='count'>{{model.sikoBrandsCount}}</span>
              </div>
              {{'siko.brands' | cxTranslate}}
            </div>
          </a>
          <ng-template ngbNavContent let-active>
            <div class='brands-items' [ngClass]='!isShowMoreBrands && model.sikoBrandsCount > 12 ? "box-shadow" : ""'>
              <ng-container *ngFor='let brand of model?.sikoBrands'>
                <app-image-grid [url]='$any(brand.url)'
                                [imageUrl]='getImageUrl(brand?.thumbnail?.url)'></app-image-grid>
              </ng-container>
            </div>
            <div class='col-12 text-center shadow-row'
                 *ngIf='!isShowMoreBrands && model.sikoBrandsCount && model.sikoBrandsCount > 12'>
              <siko-link
                [phoneLink]='true'
                [mode]='$any("default")'
                [style]='$any("primary")'
                [disableLink]=true
                (click)='isShowMoreBrands = !isShowMoreBrands'
                label="{{'siko.showMore' | cxTranslate}}"></siko-link>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]='5' *ngIf='model.sikoStoresCount && model.sikoStoresCount > 0' class='serp-stores'>
          <a class='d-md-block d-none' ngbNavLink><img [src]='"serp-stores.svg" | sikoIconAsset' alt='Stores icon'> {{'siko.stores' | cxTranslate}} <span
            class='count'>{{model.sikoStoresCount}}</span></a>
          <a class='d-md-none d-block' ngbNavLink>
            <div class='mobile-nav'>
              <div class='header'>
                <img [src]='"serp-stores.svg" | sikoIconAsset' alt='Stores icon'>
                <span
                  class='count'>{{model.sikoStoresCount}}</span>
              </div>
              {{'siko.stores' | cxTranslate}}
            </div>
          </a>
          <ng-template ngbNavContent let-active>
            <div class='cx-page-categories-container'>
              <a class='container store-link' *ngFor='let store of model?.sikoStores'
                 (click)='sikoHelperService.goToStoreDetailLink(store)'>
                <div class='image'>
                  <app-media
                    class='img-responsive'
                    [container]='{ image: $any(store?.storeImages[0])}'
                  ></app-media>
                </div>
                {{store.displayName}}
              </a>
            </div>
          </ng-template>
        </li>
        <img class='next' [src]='"arrow_up.svg" | sikoIconAsset' alt='Show more arrow icon' (click)='switchTab("next")'>
      </ul>

        <div [ngbNavOutlet]='nav' class='mt-2'></div>

    </div>
  </div>
  </ng-container>
</ng-container>

