import { StateUtils } from '@spartacus/core';
import {
    SapOrderHistory,
    SapOrderHistoryDateParams,
} from '@siko/features/my-account/order-history/store/order-history-state';

export const LOAD_SAP_ORDERS = '[Order History] Load SAP Orders';
export const LOAD_SAP_ORDERS_SUCCESS = '[Order History] Load SAP Orders Success';
export const LOAD_SAP_ORDERS_FAIL = '[Order History] Load SAP Orders Fail';

export class LoadOrderHistoryItems extends StateUtils.LoaderLoadAction {
    readonly type = LOAD_SAP_ORDERS;

    constructor(public payload: SapOrderHistoryDateParams) {
        super(LOAD_SAP_ORDERS);
    }
}

export class LoadOrderHistoryItemsSuccess extends StateUtils.LoaderSuccessAction {
    readonly type = LOAD_SAP_ORDERS_SUCCESS;

    constructor(public payload: SapOrderHistory) {
        super(LOAD_SAP_ORDERS_SUCCESS);
    }
}

export class LoadOrderHistoryItemsFail extends StateUtils.LoaderFailAction {
    readonly type = LOAD_SAP_ORDERS_FAIL;

    constructor(public payload: any) {
        super(LOAD_SAP_ORDERS_FAIL);
    }
}

export class LoadOrderHistoryItemsReset extends StateUtils.LoaderResetAction {
    readonly type = LOAD_SAP_ORDERS_FAIL;

    constructor() {
        super(LOAD_SAP_ORDERS_FAIL);
    }
}
