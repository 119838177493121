<ng-container *ngIf='isInFooter; else isInHeader'>
  <label class='footer-language' *ngIf='(items$ | async)?.length > 1 && (items$ | async) as items'>
    <span>{{ 'header.siteContext.changeLanguage' | cxTranslate }}</span>
    <div class='language-select'>
      <siko-select-field
        [control]='$any(changeLangForm.controls.changeLangSelect)'
        [items]='sikoLangOptions'
        [block]='true'
        [searchable]='false'
        bindValue='isocode' bindLabel='label' (changeEvent)='setLang($event)'></siko-select-field>
      </div>
  </label>
</ng-container>
<ng-template #isInHeader>
  <label class='header-language' *ngIf='(items$ | async)?.length > 1 && (items$ | async) as items'>
    <span>{{ 'header.siteContext.language' | cxTranslate }}</span>
    <select (change)='active = $any($event.target).value' [attr.data-testid]="$any('header-language-dropdown')">
      <option
        *ngFor='let item of items'
        value='{{ item.isocode }}'
        [selected]='(activeItem$ | async) === item.isocode'>
        {{ item.isocode }}
      </option>
    </select>
  </label>
</ng-template>
