<!-- 1st level navigation -->
<ng-container *ngFor='let child of node?.children; index as i; trackBy: trackByNavigationNode'>
  <nav class='nav-wrapper' #wrapper (mouseenter)='calculateChildTop(wrapper)' (click)='indexer = i'>

    <!-- Main category links (in the category) -->
    <div class='nav__level1'>
      <ng-container *ngIf="child.itemType === 'CMSLinkComponent'">
        <div class='d-flex category-nav-title-wrapper' [attr.data-testid]='child.uid'>
          <ng-container *ngIf='child.children && child.children.length > 0 else noChildren'>
            <div class='category-nav-title'>{{ child.title }}</div>
            <div class='d-none d-lg-block'>
              <img class='icon' [src]='"header/arrow.svg" | sikoIconAsset' alt='Dropdown arrow icon'>
            </div>
          </ng-container>
          <ng-template #noChildren>
            <div class='category-nav-title'>
              <a [routerLink]='child.url' *ngIf='child.url'>
                {{ child.title }}
              </a>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf='child?.children !== undefined'>
      <div #navLevel2 class='wrapper nav__level2' *ngIf='child.children !== undefined && child.children.length > 0'>
        <ng-container *ngIf="child.itemType === 'CMSLinkComponent'">
          <div class='d-flex'>
            <a [routerLink]='child.url' *ngIf='child.url' class='d-inline-flex'>
              <div class='nav-title'>{{ child.title }}</div>
              <img class='icon' [src]='"header/arrow.svg" | sikoIconAsset' alt='Arrow icon'>
            </a>
          </div>
        </ng-container>


        <ng-container *ngIf='child?.children'>
          <div class='childs'
               [ngClass]='hasSimpleBannerComponent(child.children) || hasImageGridComponent(child.children) ? "has-side-content" : ""'>
            <div class='main-content'>
              <ng-container *ngFor='let childV2 of child?.children; trackBy: trackByNavigationNode'>
                <ng-container *ngTemplateOutlet='nav2nd; context: { node: childV2, depth: 1 }'></ng-container>
              </ng-container>
            </div>

            <div class='side-content'>
              <ng-container
                *ngTemplateOutlet='nav2ndSideContent; context: {banner: hasSimpleBannerComponent(child.children), imageGrid: hasImageGridComponent(child.children), depth: 1 }'></ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </nav>
</ng-container>

<!-- 2nd level navigation template -->
<ng-template #nav2nd let-node='node' let-depth='depth' let-fullWidth='fullWidth'>
  <div class='{{node?.itemType}}-wrapper card-wrapper'>

    <!-- 2nd level navigation in dropdown (Banners) -->
    <ng-container
      *ngIf='node.itemType !== "SimpleBannerComponent" && node.itemType !== "SikoCMSImageGridComponent"'>
      <app-menu-banner *ngIf='node.itemType === "MenuBannerCMSComponent"' [data]='node'></app-menu-banner>
      <app-promo-banner *ngIf='node.itemType === "PromoBannerCMSComponent"' [data]='node'></app-promo-banner>
    </ng-container>

    <!-- 3rd level navigation in dropdown (Links) -->
    <div class='wrapper nav__level3' *ngIf='node.children?.length > 0'>
      <ng-container *ngIf='node.children'>
        <div #navLevel3 class='childs flex-wrap flex-column'>
          <ng-container *ngIf='node.children.length === 5 else showMore'>
            <ng-container *ngFor='let child of node.children; trackBy: trackByNavigationNode'>
              <ng-container *ngTemplateOutlet='nav3rd; context: { node: child, depth: 1 }'></ng-container>
            </ng-container>
          </ng-container>
          <ng-template #showMore>
            <ng-container *ngFor='let child of node.children | slice : 0:4'>
              <ng-container *ngTemplateOutlet='nav3rd; context: { node: child, depth: 1 }'></ng-container>
            </ng-container>
            <div class='hidden-items-container'>
              <ng-container *ngIf='node.children.length > 4'>
                <span class='show-more' #refEl (click)='showHiddenItems(refEl)'>{{ "siko.showMore" | cxTranslate }}
                  <img class='icon' [src]='"header/arrow.svg" | sikoIconAsset' alt='Arrow icon'>
                </span>
                <ng-container *ngFor='let child of node.children | slice : 4 : node.children.length'>
                  <ng-container *ngTemplateOutlet='nav3rdHide; context: { node: child, depth: 1 }'></ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- Menu side content -->
<ng-template #nav2ndSideContent let-banner='banner' let-imageGrid='imageGrid' let-depth='depth'
             let-fullWidth='fullWidth'>
  <div class='card-wrapper'>
    <ng-container *ngIf='imageGrid'>
      <div class='{{ imageGrid.itemType }}'>
        <ng-container
          [cxComponentWrapper]='{
            uid: imageGrid.uid,
            typeCode: imageGrid.itemType,
            flexType: imageGrid.itemType
          }'>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf='banner'>
      <div class='{{ banner.itemType }}'>
        <app-simple-banner [data]='banner'></app-simple-banner>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- 3rd level navigation-->
<ng-template #nav3rd let-node='node' let-depth='depth'>
  <div class='item'>
    <cx-generic-link [url]='node.url' [attr.data-testid]='node.uid'>{{ node.title }}</cx-generic-link>
  </div>
</ng-template>

<ng-template #nav3rdHide let-node='node' let-depth='depth'>
  <div style='display: none'>
    <cx-generic-link [url]='node.url'>{{ node.title }}</cx-generic-link>
  </div>
</ng-template>
