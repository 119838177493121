<ng-container *ngIf='sikoProduct$ | async as product'>
  <app-title-with-count
    *ngIf='displayHeader'
    [text]="'pdp.tabs.documents' | cxTranslate"
    [count]='product.documents?.length ? product.documents?.length : 0'
  ></app-title-with-count>
  <div class='row mt-3 mt-lg-0'>
    <ng-container *ngIf='product.documents else noDocuments'>
      <div class='product-document col-sm-6 col-lg-12' *ngFor='let document of product.documents'>
        <app-product-document-item [sikoProductDocument]='document'></app-product-document-item>
      </div>
    </ng-container>
    <ng-template #noDocuments>
      <div class='col'>
        <p>{{'pdp.tabs.noDocumentsMessage' | cxTranslate}}</p>
        <p>{{'pdp.tabs.contactOurSupportMessage' | cxTranslate}}</p>
      </div>
    </ng-template>
  </div>
</ng-container>
