import {
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';

export class SikoCustomFormValidators extends CustomFormValidators {

    static passwordValidator(control: AbstractControl): ValidationErrors | null {
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9!@$%^*()_\-{};:.,]{8,}$/;
        const password = control.value as string;

        return password && (!password.length || passwordPattern.test(password))
            ? null
            : { cxInvalidPassword: true };
    }

    static oldPasswordValidator(control: AbstractControl): ValidationErrors | null {
        const oldPassword = control.value as string;

        return oldPassword ? null : { cxInvalidOldPassword: true };
    }

    static forbidEmailValidator(control: AbstractControl): ValidationErrors | null {
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

        return emailRegex.test(control.value) ? { emailNotAllowed: true } : null;
    }

}
