import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ImportEntriesFormComponent, ImportProductsFromCsvService } from '@spartacus/cart/import-export/components';
import {
    FileReaderService,
    FilesFormValidators,
    FormUtils,
    ImportCsvFileService,
    LaunchDialogService,
} from '@spartacus/storefront';
import { ActiveCartFacade, Cart, MultiCartFacade, ProductData } from '@spartacus/cart/base/root';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ImportExportConfig } from '@spartacus/cart/import-export/core';
import { Papa, ParseResult } from 'ngx-papaparse';
import { SikoImportCsvValidator } from '@siko/features/shared-components/import-entries-dialog/services/import-csv-validator.service';
import { Subscription } from 'rxjs';
import { SikoAutoUnsubscribe } from '@siko/common';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { DatePipe } from '@angular/common';
import { SavedCartActions } from '@spartacus/cart/saved-cart/core';
import { Actions, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType, UserIdService } from '@spartacus/core';
import { CartActions } from '@spartacus/cart/base/core';
import { CUSTOM_IMPORT_EXPORT_CONFIG } from '../../../../../configuration/import-export-config';
import { SikoDialogService, SikoTrackingUtils } from '@siko/shared';
import { SikoCart } from '@siko/models';

@SikoAutoUnsubscribe([
    'userIdSubscription',
    'activeCartSubscription',
    'saveCartAddSuccessSubscription',
    'deleteCartSubscription',
    'deleteCartFailSubscription',
    'saveCartFailSubscription'
])
@Component({
    selector: 'app-import-entries-form',
    templateUrl: './import-entries-form.component.html',
    styleUrls: ['./import-entries-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoImportEntriesFormComponent extends ImportEntriesFormComponent implements OnInit {

    actualDate: Date = new Date();
    currentCart?: Cart;
    currentCartEntries = 0;

    activeCartSubscription?: Subscription;
    saveCartAddSuccessSubscription?: Subscription;
    deleteCartSubscription?: Subscription;
    deleteCartFailSubscription?: Subscription;
    saveCartFailSubscription?: Subscription;
    userIdSubscription?: Subscription;


    savedCartForm: FormGroup = this.fb.group({
        savedCartName: [],
    });

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected importToCartService: ImportProductsFromCsvService,
        protected importCsvService: ImportCsvFileService,
        protected filesFormValidators: FilesFormValidators,
        protected importExportConfig: ImportExportConfig,
        protected fileReaderService: FileReaderService,
        protected csvParser: Papa,
        protected sikoImportCsvValidator: SikoImportCsvValidator,
        readonly actions: Actions,
        readonly fb: FormBuilder,
        readonly saveCartService: SavedCartFacade,
        readonly cartService: ActiveCartFacade,
        readonly multiCartService: MultiCartFacade,
        readonly userIdService: UserIdService,
        readonly globalMessageService: GlobalMessageService,
        readonly dialogService: SikoDialogService,
        readonly sikoTrackingUtils: SikoTrackingUtils
    ) {
        super(launchDialogService, importToCartService, importCsvService, filesFormValidators, importExportConfig);
    }

    protected get maxEntries(): number | undefined {
        return CUSTOM_IMPORT_EXPORT_CONFIG.cartImportExport?.import?.fileValidity
            ?.maxEntries?.[this.type];
    }

    ngOnInit(): void {
        this.form = this.buildForm();

        this.deleteCartFailSubscription = this.actions.pipe(
            ofType(CartActions.DELETE_CART_FAIL),
        )
            .subscribe(() => {
                this.dialogService.closeActiveModal('Cart is not found');
                this.globalMessageService.add(
                    { key: 'httpHandlers.cart.reloadCartRequired' },
                    GlobalMessageType.MSG_TYPE_ERROR
                );
            });

        this.saveCartFailSubscription = this.actions.pipe(
            ofType(SavedCartActions.SAVE_CART_FAIL),
        )
            .subscribe(() => {
                this.dialogService.closeActiveModal('Cart is not found');
                this.globalMessageService.add(
                    { key: 'httpHandlers.cart.reloadCartRequired' },
                    GlobalMessageType.MSG_TYPE_ERROR
                );
            });

        this.activeCartSubscription = this.cartService.getActive()
            .subscribe(
                data => {
                    this.currentCart = data;

                    if (data.entries) {
                        this.currentCartEntries = data.entries.length;
                    }
                },
            );

        this.formSubmitSubject$
            .pipe(
                tap(() => {
                    if (this.form.invalid) {
                        this.form.markAllAsTouched();
                        FormUtils.deepUpdateValueAndValidity(this.form);
                    }
                }),
                switchMap(() =>
                    this.form.statusChanges.pipe(
                        startWith(this.form.get('file')?.status),
                        filter(status => status !== 'PENDING'),
                        take(1),
                    )),
                filter(status => status === 'VALID'),
            )
            .subscribe(() => {
                this.save();
            });
    }

    removeEntriesAndImport(): void {
        let userID = 'current';

        this.userIdSubscription = this.userIdService.getUserId().subscribe(data => {
            userID = data;
        });

        this.cartService.getActiveCartId()
            .subscribe(cartId => {
                this.multiCartService.deleteCart(cartId, userID);
            })
            .unsubscribe();

        let oldCart: SikoCart | undefined = this.currentCart;
        this.deleteCartSubscription = this.actions.pipe(
            ofType(CartActions.DELETE_CART_SUCCESS),
        )
            .subscribe(() => {
                if (oldCart && oldCart.entries) {
                    this.sikoTrackingUtils.pushModifyCartEvent(oldCart.entries, undefined, 'removeFromCart');
                }
                this.formSubmitSubject$.next(undefined);
            });
    }

    saveEntriesAndImport(): void {
        if (this.currentCart?.code) {
            let savedCartName = this.savedCartForm.controls.savedCartName.value;

            if (savedCartName === null) {
                const datePipe = new DatePipe('cs-CZ');

                savedCartName = datePipe.transform(new Date(), 'd.M.Y H:mm');
            }

            const oldCart = this.currentCart;
            this.saveCartService.saveCart({
                cartId: this.currentCart.code,
                saveCartName: savedCartName,
                saveCartDescription: '',
            });

            this.saveCartAddSuccessSubscription = this.actions.pipe(
                ofType(SavedCartActions.SAVE_CART_SUCCESS),
            )
                .subscribe(data => {
                    this.sikoTrackingUtils.pushCartSaveEvent(oldCart,false);
                    this.userIdService.takeUserId()
                        .subscribe((userId) => {
                            this.multiCartService.createCart({ userId });
                        })
                        .unsubscribe();

                    this.formSubmitSubject$.next(undefined);
                });
        }
    }

    save(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        const file: File = this.form.get('file')?.value?.[0];

        this.csvParser.parse(file, {
            skipEmptyLines: true,
            complete: (parseResult: ParseResult) => {
                this.submitEvent.emit({
                    products: this.sikoImportCsvValidator.csvDataToProduct(parseResult.data, true)
                        .filter((value: ProductData) => value.productCode !== ''),
                });
            },
        });
    }

    protected buildForm(): UntypedFormGroup {
        const form = new UntypedFormGroup({});

        form.setControl(
            'file',
            new UntypedFormControl(
                '',
                [Validators.required, this.filesFormValidators.maxSize(this.maxSize)],
                [
                    (control: AbstractControl) =>
                        this.sikoImportCsvValidator.validateFile(control.value[0], {
                            isDataParsable: this.sikoImportCsvValidator.isDataParsableToProducts,
                            maxEntries: this.maxEntries,
                        }),
                ],
            ),
        );

        return form;
    }

}

