import { Injectable } from '@angular/core';
import { ActiveCartFacade, Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { SavedCartActions, SavedCartService } from '@spartacus/cart/saved-cart/core';
import { Store } from '@ngrx/store';
import { CartActions, StateWithMultiCart } from '@spartacus/cart/base/core';
import {
    EventService,
    GlobalMessageService,
    GlobalMessageType, normalizeHttpError,
    LoggerService,
    RoutingService,
    StateWithProcess,
    UserIdService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SikoSavedCartAdapter } from '@siko/features/my-account/saved-cart/occ/adapters/SikoSavedCartAdapter';
import { LaunchDialogService } from '@spartacus/storefront';

@Injectable({
    providedIn: 'root',
})
export class SikoSavedCartService extends SavedCartService implements SavedCartFacade {

    restoreSavedCartFail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        readonly activeCartService: ActiveCartFacade,
        readonly savedCartConnector: SikoSavedCartAdapter,
        readonly globalMessageService: GlobalMessageService,
        readonly launchDialogService: LaunchDialogService,
        readonly logger: LoggerService,
        readonly routingService: RoutingService,
        store: Store<StateWithMultiCart | StateWithProcess<void>>,
        userIdService: UserIdService,
        userAccountFacade: UserAccountFacade,
        multiCartService: MultiCartFacade,
        eventService: EventService,
    ) {
        super(store, userIdService, userAccountFacade, multiCartService, eventService);
    }

    getRestoreSavedCartFail(): Observable<boolean> {
        return this.restoreSavedCartFail.asObservable();
    }

    setRestoreSavedCartFail(value: boolean): void {
        this.restoreSavedCartFail.next(value);
    }

    cloneSavedCart(cartId: string, saveCartName?: string): Observable<any> {
        return this.userIdService.takeUserId(true)
            .pipe(
                tap((userId) => {
                    if (saveCartName !== undefined) {
                        this.savedCartConnector.cloneSavedCart(userId, cartId, saveCartName)
                            .pipe(
                                switchMap(() => {
                                    this.store.dispatch(new SavedCartActions.CloneSavedCartSuccess({
                                        userId,
                                        cartId,
                                        saveCartName,
                                    }));

                                    return [];
                                }),
                            )
                            .subscribe(null,
                                error => {
                                    this.store.dispatch(new SavedCartActions.CloneSavedCartFail({
                                        userId,
                                        cartId,
                                        saveCartName,
                                        error: normalizeHttpError(error, this.logger),
                                    }));
                                });
                    }

                }),
            );
    }

    restoreSavedCart(cartId: string): Observable<any> {
        return this.userIdService.takeUserId(true)
            .pipe(
                tap((userId) => {
                    this.savedCartConnector.restoreSavedCart(userId, cartId)
                        .pipe(
                            switchMap((savedCart: Cart) => {
                                this.globalMessageService.add(
                                    {
                                        key: 'savedCartList.swapCartNoActiveCart',
                                        params: {
                                            cartName: cartId,
                                        },
                                    },
                                    GlobalMessageType.MSG_TYPE_CONFIRMATION,
                                );
                                this.launchDialogService.closeDialog('');
                                this.store.dispatch(new CartActions.LoadCartSuccess({
                                    userId,
                                    cartId,
                                    cart: savedCart,
                                    extraData: { active: true },
                                }));

                                this.store.dispatch(new SavedCartActions.RestoreSavedCartSuccess({ userId, cartId }));
                                void this.routingService.go('cart');

                                return [];
                            }),
                        )
                        .subscribe(null,
                            error => {
                                this.setRestoreSavedCartFail(true);
                            });
                }),
            );
    }

}
