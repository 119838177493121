import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Product, ProductActions } from '@spartacus/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SikoAutoUnsubscribe } from '@siko/common';
import { LoadProductSuccess } from '@spartacus/core/src/product/store/actions/product.action';
import { SikoBonusProgramService } from '@siko/features/my-account/bonus-program/bonus-program.service';

@SikoAutoUnsubscribe([
    'recalculatePriceSubscription',
])
@Component({
    selector: 'app-bonus-points-info',
    templateUrl: './bonus-points-info.component.html',
    styleUrls: ['./bonus-points-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoBonusPointsInfoComponent implements OnChanges {

    @Input() type?: 'discount' | 'points' | 'reactive';
    @Input() value?: number | string;
    @Input() coefficient?: number;
    @Input() disableTooltip: boolean = false;
    @Input() smallerValueFont: boolean = false;
    @Input() recalculated?: BehaviorSubject<boolean>;
    @Input() productCode?: string;

    currentValue$ = new BehaviorSubject<number | string | undefined>(undefined);
    recalculatePriceSubscription?: Subscription;

    constructor(
        private readonly actions: Actions,
        private readonly bonusProgramService: SikoBonusProgramService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.currentValue$.next(this.getValue());

        if (this.type === 'reactive') {
            if (changes.productCode.previousValue !== changes.productCode.currentValue) {
                this.value = undefined;
            }

            this.recalculatePriceSubscription = this.actions.pipe(ofType(ProductActions.LOAD_PRODUCT_SUCCESS))
                .subscribe((loadProduct: LoadProductSuccess) => {
                    this.recalculated?.subscribe(data => {
                        if (data) {
                            const product: Product = loadProduct.payload;
                            const productPrice: number = product.price?.value ? product.price.value : 0;

                            this.value = productPrice * (this.coefficient ?? 0);
                            this.currentValue$.next(Math.round(this.value));
                        }
                    });
                });
        }
    }

    getMainClass(): string {
        if (this.type === 'discount') {
            return 'bonus-points-info discount' + (this.value === undefined ? ' no-value' : '');
        }
        else if (this.isNoPointsState()) {
            return 'bonus-points-info none';
        }

        return 'bonus-points-info' + (this.value === undefined ? ' no-value' : '');
    }

    getValueClass(): string {
        return 'value' + (this.smallerValueFont ? ' smallerFont' : '');
    }

    getIconClass(): string {
        if (this.coefficient === undefined) {
            return 'icon icon--base';
        }

        return 'icon icon--' + this.bonusProgramService.getBonusCoefficient(this.coefficient);
    }

    getNote(): string | undefined {
        if (this.coefficient === undefined) {
            return undefined;
        }

        return 'siko.bonusProgram.notes.' + this.bonusProgramService.getBonusCoefficient(this.coefficient);
    }

    private isNoPointsState(): boolean {
        return this.type === 'points' && this.value === undefined;
    }

    private getValue(): number | string | undefined {
        if (this.isNoPointsState()) {
            return '-';
        }

        if (this.type === 'discount') {
            return this.value === undefined ? '' : '-' + this.value;
        }

        return this.value;
    }

    isNumeric(value?: number | string | undefined): boolean {
        value = Number(value);
        return !isNaN(value) && isFinite(value);
    }

}
