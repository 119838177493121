import {
    Component,
    ChangeDetectionStrategy,
    OnInit
} from '@angular/core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { NUMBER_OF_SK_STORES, NUMBER_OF_CZ_STORES } from '@siko/constants';
import { SikoAutoUnsubscribe } from '@siko/common';

@SikoAutoUnsubscribe(['animationSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-footer-map',
    templateUrl: './footer-map.component.html',
    styleUrls: ['./footer-map.component.scss']
})
export class SikoFooterMapComponent implements OnInit {

    animationSubscription?: Subscription;
    animationClass$ = new BehaviorSubject<string>('step1');
    numberOfSkStores = NUMBER_OF_SK_STORES;
    numberOfCzStores = NUMBER_OF_CZ_STORES;
    animationInterval = 2000;
    switch = true;

    ngOnInit(): void {
        this.animationSubscription = interval(this.animationInterval).subscribe(() => {
            this.animationClass$.next(this.switch ? 'step2' : 'step1');
            this.switch = !this.switch;
        });
    }

}
