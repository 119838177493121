import { Injectable } from '@angular/core';
import { SearchBoxComponentService, SearchBoxConfig } from '@spartacus/storefront';
import { combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SikoSearchResults } from '@siko/models';

const HAS_SEARCH_RESULT_CLASS = 'has-searchbox-results';

@Injectable({
    providedIn: 'root',
})
export class SikoSearchBoxComponentService extends SearchBoxComponentService {

    getResults(config: SearchBoxConfig): Observable<any> {
        return combineLatest([
            this.getProductResults(config),
            this.getProductSuggestions(config),
            this.getSearchMessage(config),
        ])
            .pipe(
                map(([productResults, suggestions, message]) => ({
                    products: productResults ? productResults.products : null,
                    suggestions,
                    message,
                    sikoStores: productResults ? productResults.sikoStores : null,
                    sikoCategories: productResults ? productResults.sikoCategories : null,
                    sikoBrands: productResults ? productResults.sikoBrands : null,
                    sikoSeries: productResults ? productResults.sikoSeries : null,
                    sikoCategoriesCount: productResults ? productResults.sikoCategoriesCount : null,
                    sikoBrandsCount: productResults ? productResults.sikoBrandsCount : null,
                    sikoSeriesCount: productResults ? productResults.sikoSeriesCount : null,
                    sikoProductsCount: productResults ? productResults.sikoProductsCount : null,
                    sikoStoresCount: productResults ? productResults.sikoStoresCount : null,
                    freeTextSearch: productResults ? productResults.freeTextSearch : null,
                })),
                tap(results => {
                    this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, this.hasResults(results));
                }),
            );
    }

    protected hasResults(results: any): boolean {
        return (
            !!results.products && results.products.length > 0 ||
            !!results.suggestions && results.suggestions.length > 0 ||
            !!results.message
        );
    }

    protected getProductResults(
        config: SearchBoxConfig,
    ): Observable<SikoSearchResults> {
        if (config.displayProducts) {
            return this.searchService.getResults();
        }

        return of({});

    }
}
