import { NgModule } from '@angular/core';
import { asmTranslationChunksConfig, asmTranslations } from '@spartacus/asm/assets';
import { AsmRootModule, ASM_FEATURE } from '@spartacus/asm/root';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { SIKO_ASM_TRANSLATIONS } from '@siko/translations';

@NgModule({
    declarations: [],
    imports: [
        AsmRootModule
    ],
    providers: [provideConfig(<CmsConfig>{
        featureModules: {
            [ASM_FEATURE]: {
                module: async() =>
                    import('@spartacus/asm').then(m => m.AsmModule),
            },
        }
    }),
    provideConfig(<I18nConfig>{
        i18n: {
            resources: SIKO_ASM_TRANSLATIONS,
            chunks: asmTranslationChunksConfig,
        },
    })
    ]
})
export class AsmFeatureModule { }
