import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActiveFacetsComponent, FacetService } from '@spartacus/storefront';
import { SikoB2bPlpService } from '@siko/features/product/product-listing/siko-b2b-plp.service';

/**
 * Active facets render the applied facet values as a list of focusable buttons
 * which can be used to remove the applied facet value.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-active-facets',
    templateUrl: './active-facets.component.html',
    styleUrls: ['./active-facets.component.scss'],
})
export class SikoActiveFacetsComponent extends ActiveFacetsComponent {
    @Input() virtualCategory?: string;

    constructor(
        facetService: FacetService,
        public plpCommunication: SikoB2bPlpService,
    ) {
        super(facetService);
    }

}
