import {
    Cart,
    CartItemComponentOptions,
    CartModification,
    DeliveryOrderEntryGroup,
    OrderEntry,
    ProductImportInfo,
    ProductImportSummary,
    PromotionLocation,
    PromotionOrderEntryConsumed,
    Voucher,
} from '@spartacus/cart/base/root';
import { SikoOrderEntry } from './order.model';
import { SikoPromotionResult } from './product.model';
import { SikoPointOfService } from './pointOfService.model';
import { SikoAddress } from './address.model';

export interface SikoCart extends Cart {
    appliedOrderPromotions?: SikoPromotionResult[];
    appliedProductPromotions?: SikoPromotionResult[];
    sikoPreferredPickupDate?: string;
    entries?: SikoOrderEntry[];
    sikoTotalWeight?: number;
    undefinedOrderGroups?: SikoDeliveryOrderEntryGroup[];
    undefinedItemsQuantity?: number;
    selectedDeliveryMode?: SikoSelectedDeliveryMode;
    selectedDeliveryPOS?: SikoPointOfService;
    deliveryAddress?: SikoAddress;
    sikoBonusPointsReceived?: number;
}

export interface SikoDeliveryOrderEntryGroup extends DeliveryOrderEntryGroup {
    entries?: SikoOrderEntry[];
}

export interface SikoCartModification extends CartModification {
    productCode?: string;
    entry?: SikoOrderEntry;
    preferredQuantity?: number;
}

export interface SikoCartModificationList {
    cartModifications?: SikoCartModification[];
    removedCouponsList?: Voucher[];
    setDeliveryType?: boolean;
}

export interface SikoProductImportSummary extends ProductImportSummary {
    warningMessages: SikoProductImportInfo[];
    errorMessages: SikoProductImportInfo[];
}

export interface SikoProductImportInfo extends ProductImportInfo {
    conversion?: string;
    unit?: string;
    quantityError?: boolean;
}

export interface SikoPromotionOrderEntryConsumed extends PromotionOrderEntryConsumed {
    title?: string;
    sikoPromoType?: string;
}

export interface ItemListContext {
    readonly?: boolean;
    hasHeader?: boolean;
    options?: CartItemComponentOptions;
    cartId?: string;
    items?: OrderEntry[];
    promotionLocation?: PromotionLocation;
    cartIsLoading?: boolean;
}

export interface SikoSimulatedCartModification extends CartModification {
    productCode?: string;
    entry?: SikoOrderEntry;
    preferredQuantity?: number;
    calculatedBackgroundStatusCode?: string;
    calculatedDisponibilityStatusCode?: string;
    previousStatusCode?: string;
    centralStockStatus?: string;
}

export interface SikoCartItemPromotionOrderEntryConsumed extends SikoOrderEntry {
    promotionConsumed?: SikoPromotionOrderEntryConsumed;
}

export interface SikoSelectedDeliveryMode {
    code?: string;
    name?: string;
    description?: string;
    deliveryCost?: {
        value: number;
        priceType?: string;
        formattedValue?: string;
        minQuantity?: number;
        maxQuantity?: number;
    },
    sapCode?: string;
}

export enum SIKO_SELECTED_DELIVERY_MODE_ENUM {
    PICKUP = 'pickup',
    BAL_NET = 'BAL_net',
    ZAV_NET = 'ZAV_net'
}

export type SikoSelectedDeliveryModeType = 'pickup' | 'BAL_net' | 'ZAV_net';

export enum SIKO_CART_VALIDATION_STATUS {
    NOT_AVAILABLE = 'unavailable'
}

export const DELIVERY_TO_ADDRESS = 'delivery';
export const PICKUP = 'pickup';
export const SIKO_SHIPMENT = 'shipment';

export interface SikoVoucher extends Voucher {
    sikoVoucherCondition?: string;
}
