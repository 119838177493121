import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SikoOrderHistoryList, SikoSapOrder, SikoSapOrderHistory } from '@siko/models';



@Injectable({
    providedIn: 'root'
})
export class OccOrderHistoryAdapter {

    private readonly backendBaseUrl: string;
    userId?: string;

    constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected userIdService: UserIdService
    ) {
        this.backendBaseUrl = this.occEndpoints.getBaseUrl();
        this.userIdService
            .getUserId()
            .subscribe((occUserId: string) => this.userId = occUserId)
            .unsubscribe();
    }

    getOrders(userId: string): Observable<SikoOrderHistoryList>{
        return this.http.get<SikoOrderHistoryList>(`${this.backendBaseUrl}/b2b/users/${userId}/orders`);
    }

    getSapOrders(
        userId: string,
        startDate: string | undefined,
        endDate: string | undefined
    ): Observable<SikoSapOrderHistory>{
        return this.http.get<SikoSapOrderHistory>(
          `${this.backendBaseUrl}/users/${userId}/sap-orders?startDate=${startDate}&endDate=${endDate}`
        );
    }

    getSapOrderDetail(
        userId: string,
        sapOrderId: string
    ): Observable<SikoSapOrder>{
        return this.http.get(
            `${this.backendBaseUrl}/users/${userId}/sap-orders/${sapOrderId}`
        );
    }

}
