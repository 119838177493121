import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { QuickOrderService } from '@spartacus/cart/quick-order/core';
import {
    Config,
    EventService,
    Product,
    ProductSearchConnector,
    ProductSearchPage,
    SearchConfig,
    UserIdService,
} from '@spartacus/core';
import { CartActions, getCartIdByUserId } from '@spartacus/cart/base/core';
import { ActiveCartFacade, CartModification, OrderEntry } from '@spartacus/cart/base/root';
import { defaultQuickOrderConfig, QuickOrderAddEntryEvent } from '@spartacus/cart/quick-order/root';
import { concatMap, filter, finalize, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OccBopisAdapter, SikoActiveCartService } from '@siko/features/cart';
import { SikoHttpErrorResponse } from '@siko/models';
import { Store } from '@ngrx/store';
import { SikoTrackingUtils } from '@siko/shared';
import { generateImportBatchId, HTTP_ERROR } from '@siko/constants';
import { SikoOccQuickOrderAdapter } from '@siko/features/quick-order/occ/adaters/occ-quick-order.adapter';

@Injectable({
    providedIn: 'root',
})

export class SikoQuickOrderService extends QuickOrderService {

    sikoWarnings = new BehaviorSubject<any[]>([]);
    sikoErrors = new BehaviorSubject<any[]>([]);
    sikoDeliveryRestricition = new BehaviorSubject(false);
    sikoSuccess = new BehaviorSubject(false);
    reloadCartIsRequired = new BehaviorSubject(false);
    importing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    setSikoDeliveryRestriction(value: boolean) {
        this.sikoDeliveryRestricition.next(value);
    }

    setSikoSuccess(value: boolean) {
        this.sikoSuccess.next(value);
    }

    addSikoWarnings(item: QuickOrderAddEntryEvent[]) {
        this.sikoWarnings.next(this.sikoWarnings.getValue()
            .concat(item));
    }

    addSikoErrors(item: QuickOrderAddEntryEvent[]) {
        this.sikoErrors.next(this.sikoErrors.getValue()
            .concat(item));
    }

    resetErrorsAndWarnings(): void {
        this.resetErrors();
        this.resetWarnings();
    }

    resetErrors(): void {
        this.sikoErrors.next([]);
    }

    resetWarnings(): void {
        this.sikoWarnings.next([]);
    }

    get sikoDeliveryRestriction$(): Observable<boolean> {
        return this.sikoDeliveryRestricition.asObservable();
    }

    get sikoWarnings$(): Observable<any> {
        return this.sikoWarnings.asObservable();
    }

    get sikoErrors$(): Observable<any> {
        return this.sikoErrors.asObservable();
    }

    get sikoSuccess$(): Observable<boolean> {
        return this.sikoSuccess.asObservable();
    }

    constructor(
        protected sikoActiveCartService: SikoActiveCartService,
        protected activeCartService: ActiveCartFacade,
        protected config: Config,
        protected eventService: EventService,
        protected productSearchConnector: ProductSearchConnector,
        readonly trackingUtils: SikoTrackingUtils,
        readonly store: Store,
        readonly userIdService: UserIdService,
        readonly occBopisAdapter: OccBopisAdapter,
        readonly occQuicOrderAdapter: SikoOccQuickOrderAdapter,
    ) {
        super(activeCartService, config, eventService, productSearchConnector);
    }

    events: QuickOrderAddEntryEvent[] = [];

    addToCart(): Observable<[OrderEntry[], QuickOrderAddEntryEvent[]]> {
        let entries: OrderEntry[] = [];

        return this.getEntries()
            .pipe(
                first(),
                switchMap(elements => {
                    entries = elements;
                    this.sikoAddEntries(elements);


                    return this.activeCartService.isStable();
                }),
                filter(isStable => isStable),
                map(() => [entries, this.events] as [OrderEntry[], QuickOrderAddEntryEvent[]]),
            );
    }

    sikoAddEntries(cartEntries: OrderEntry[]): void {
        const entriesToAdd = cartEntries.map((entry: OrderEntry) => ({
            productCode: entry.product?.code,
            quantity: entry.quantity,
        }));

        this.resetErrorsAndWarnings();
        this.setSikoSuccess(false);
        this.setSikoDeliveryRestriction(false);

        this.activeCartService.requireLoadedCart()
            .pipe(
                withLatestFrom(this.userIdService.getUserId()),
            )
            .subscribe(([cartState, userId]) => {
                if (cartState) {
                    const cartId = getCartIdByUserId(cartState, userId);
                    const batchKey = generateImportBatchId();

                    this.importing.next(true);

                    from(entriesToAdd)
                        .pipe(
                            concatMap((product: any) => this.occBopisAdapter.addToCartQuickOrder(
                                userId,
                                cartId,
                                product.quantity,
                                product.productCode,
                                `${batchKey}`,
                            )),
                            finalize(() => {
                                const loadCartPayload = {
                                    userId,
                                    cartId,
                                };

                                this.importing.next(false);
                                this.store.dispatch(new CartActions.LoadCart(loadCartPayload));

                                combineLatest(
                                    this.sikoErrors$,
                                    this.reloadCartIsRequired,
                                )
                                    .subscribe(([data, reloadCartIsRequired]) => {
                                        if (!data.length && !reloadCartIsRequired) {
                                            this.setSikoSuccess(true);
                                            setTimeout(() => {
                                                this.setSikoSuccess(false);
                                            }, 4000);
                                        }
                                    })
                                    .unsubscribe();


                                this.sikoDeliveryRestriction$.subscribe(data => {
                                    if (!data) {
                                        this.clearList();
                                    }
                                    else {
                                        this.setSikoSuccess(false);
                                    }
                                })
                                    .unsubscribe();
                            }),
                        )
                        .subscribe((data: CartModification) => {
                                this.trackingUtils.pushModifyCartEvent([data.entry], data.quantityAdded, 'addToCart', 'quickOrder');

                                const event: QuickOrderAddEntryEvent | undefined = this.createQuickOrderEvent(data);

                                if (data.quantityAdded !== data.quantity && event !== undefined) {
                                    if (data.quantityAdded === 0) {
                                        this.addSikoErrors([event]);
                                    }
                                    else {
                                        this.addSikoWarnings([event]);
                                    }
                                }
                            },
                            (error: SikoHttpErrorResponse) => {

                                if (error.error.errors[0].type === 'Sikob2bCartNotFoundReloadRequiredError') {
                                    this.reloadCartIsRequired.next(true);
                                }

                                if (error.error.errors[0].type === 'Sikob2bDeliveryRestrictionError') {
                                    this.setSikoDeliveryRestriction(true);
                                    this.showDeliveryRestrictionModal();
                                }

                                if (error.error.errors[0].type === 'Sikob2bPickupToDifferentStoresError') {
                                    this.setSikoDeliveryRestriction(true);
                                    this.showDeliveryToDifferentStoreRestrictionModal();
                                }
                            });
                }
            });
    }

    showDeliveryRestrictionModal(): void {
        this.sikoActiveCartService.openModal(
            undefined,
            HTTP_ERROR.ADD_TO_CART_DELIVERY_RESTRICTION,
        );
    }

    showDeliveryToDifferentStoreRestrictionModal(): void {
        this.sikoActiveCartService.openModal(
            undefined,
            HTTP_ERROR.ADD_TO_CART_PICKUP_TO_DIFFERENT,
        );
    }

    createQuickOrderEvent(data: CartModification): QuickOrderAddEntryEvent | undefined {
        if (data.entry?.product?.code && data.quantity) {
            return {
                productCode: data.entry.product.code,
                entry: data.entry,
                quantityAdded: data.quantityAdded,
                quantity: data.quantity,
            };
        }

        return undefined;
    }

    searchProducts(query: string, maxProducts?: number): Observable<Product[]> {
        if (this.productSearchConnector) {
            const searchConfig: SearchConfig = {
                pageSize:
                    maxProducts ||
                    defaultQuickOrderConfig.quickOrder?.searchForm?.maxProducts,
            };
            return this.occQuicOrderAdapter
                .search(query, searchConfig)
                .pipe(
                    map((searchPage: ProductSearchPage) => searchPage.products || []),
                );
        }
        else {
            return of([]);
        }
    }

}
