import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-label',
    templateUrl: './product-label.component.html',
    styleUrls: ['./product-label.component.scss']
})
export class SikoProductLabelComponent implements OnInit {
    productLabels?: string[];
    className?: string;
    tagTranslationsKey?: string;

    @Input() label?: string;

    ngOnInit(): void {
        if (this.label) {
            this.assignProperties(this.label);
        }

    }

    assignProperties(label: string): void {
        switch (label) {
            case 'ENDSALE':
                this.className = 'bg-danger';
                this.tagTranslationsKey = 'siko.productLabel.sale';
                break;
            case 'NEWPRODUCT':
                this.className = 'news';
                this.tagTranslationsKey = 'siko.productLabel.new';
                break;
            case 'HIT':
                this.className = 'hit';
                this.tagTranslationsKey = 'siko.productLabel.hit';
                break;
            default:
                this.className = '';
                this.tagTranslationsKey = '';
        }
    }


}
