import { Component } from '@angular/core';
import { SikoAbstractItemCounterComponent } from '@siko/features/shared-components/item-counter/abstract-item-counter/abstract-item-counter.component';

@Component({
    selector: 'app-pdp-item-counter',
    templateUrl: '../abstract-item-counter/abstract-item-counter.component.html',
    styleUrls: ['../abstract-item-counter/abstract-item-counter.component.scss'],
})
export class SikoPdpItemCounterComponent extends SikoAbstractItemCounterComponent {
}
