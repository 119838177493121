import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { SikoMessageComponent } from '@siko/features/misc/message/message.component';
import { B2bCommonModule } from '@siko/shared';

@NgModule({
    imports: [CommonModule, IconModule, I18nModule, B2bCommonModule],
    declarations: [SikoMessageComponent],
    exports: [SikoMessageComponent],
})
export class SikoMessageComponentModule {}
