import '@angular/common/locales/global/cs';
import '@angular/common/locales/global/sk';
import { APP_ID, APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from '@spartacus/storefront';
import { ConfigModule, PageMetaResolver } from '@spartacus/core';
import { CmsComponentsModule } from '@siko/components';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SikoGlobalMessageComponentModule } from '@siko/features/misc/global-message/global-message.module';
import { SikoTrackingModule } from '@siko/features/tracking/tracking.module';
import { SikoNewsStoreModule } from '@siko/features/news/store/news-store.module';
import { SikoContentPageMetaResolver } from '@siko/shared';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { OccB2bUserSerializer } from '@spartacus/organization/administration/occ';
import { SikoOccB2bUserSerializer } from '@siko/features/organization/occ/serializers/occ-b2b-user-serializer';
import { SikoSharedModule } from '@siko/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SikoOccOrderNormalizer } from '@siko/features/my-account/order-details/occ/converters/occ-order-normalizer';
import { SikoUserActivityService } from '@siko/shared/services/user-activity.service';
import { CustomConfigurationModule } from './siko/configuration/custom-configuration.module';
import { CoreModule } from './core.module';
import { SpartacusModule } from './spartacus/spartacus.module';
import { AppComponent } from './app.component';
import { LAZY_LOADED_MODULES } from './siko/configuration/lazy-loaded-modules';
import { OccOrderNormalizer } from '@spartacus/order/occ';
import { SikoRemoveCartEntryModule } from '@siko/features/cart/remove-cart-entry/cart.module';
import { GlobalErrorHandler } from './siko/interceptors/global-error.handler';
import { SikoProductListModule } from '@siko/features/product/product-listing/product-list/product-list.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SikoOrderHistoryStoreModule } from '@siko/features/my-account/order-history/store/order-history-store.module';
import { SikoAsmComponentsModule } from '@siko/features/asm/asm-components.module';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';


@NgModule({
    providers: [
        {
            provide: APP_ID,
            useValue: 'spartacus-app'
        },
        {
            provide: PageMetaResolver,
            useExisting: SikoContentPageMetaResolver,
            multi: true,
        },
        {
            provide: OccB2bUserSerializer,
            useExisting: SikoOccB2bUserSerializer,
        },
        {
            provide: OccOrderNormalizer,
            useExisting: SikoOccOrderNormalizer,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [SikoUserActivityService],
            useFactory: (userActivityService: SikoUserActivityService) => (): void => {
                userActivityService.startMonitoringUserActivity();
            }
        },
    ],
    declarations: [
        AppComponent,
    ],
    imports: [
        SpartacusModule,
        BrowserModule,
        BrowserAnimationsModule,
        // BrowserModule.withServerTransition({ appId: 'spartacus-app' }),
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        AppRoutingModule,
        SikoTrackingModule,
        CustomConfigurationModule,
        CmsComponentsModule,
        MatBottomSheetModule,
        ConfigModule.withConfig({
            featureModules: LAZY_LOADED_MODULES,
        }),
        HttpClientModule,
        CoreModule,
        SikoNewsStoreModule,
        SikoOrderHistoryStoreModule,
        SikoRemoveCartEntryModule,
        SikoGlobalMessageComponentModule,
        SikoSharedModule,
        SikoProductListModule,
        SikoAsmComponentsModule,
        ServiceWorkerModule.register('service-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        // Increases total JS heap size drastically. Uncomment only if needed for dev purposes.
        // StoreDevtoolsModule.instrument({
        //     maxAge: 25,
        //     logOnly: false,
        // }),
    ],

    bootstrap: [AppComponent],
})
export class AppModule {

}
