import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { SikoOrderOverviewService } from '@siko/features/order-overview/occ/services/order-overview.service';
import { SikoOrder, SikoPromotionResult, SikoSapOrder } from '@siko/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-order-detail-totals',
    templateUrl: './order-detail-totals.component.html',
    styleUrls: ['./order-detail-totals.component.scss'],
})

export class SikoOrderDetailTotalsComponent {

    @Input() order?: SikoOrder;
    @Input() sikoSapOrder?: SikoSapOrder;
    @Input() orderConfirmation?: boolean = false;
    @Output() readonly reorderClick = new EventEmitter<MouseEvent>();

    orderWithPromotions?: SikoOrder;

    constructor(
        public reorderService: SikoOrderOverviewService,
        readonly routingService: RoutingService,
    ) {
    }

    get displayReorderButton(): boolean {
        const order: SikoOrder | undefined = this.order !== undefined ? this.order : this.sikoSapOrder?.order;

        return order !== undefined && order.statusDisplay !== 'cancelled'
            && (!this.sikoSapOrder || this.sikoSapOrder.orderStatus !== 'cancelled') && !this.orderConfirmation;
    }

    backToOrderHistory(): void {
        void this.routingService.goByUrl('/my-account/orders');
    }

    backToShop(): void {
        void this.routingService.goByUrl('/c/1');
    }

    hasGiveawayCoupons(): boolean {
        this.orderWithPromotions = this.sikoSapOrder ? this.sikoSapOrder.order : this.order;

        return !this.orderConfirmation &&
            this.orderWithPromotions?.appliedOrderPromotions?.find(
                (i: SikoPromotionResult) => i.giveAwayCouponCodes && i.giveAwayCouponCodes.length > 0,
            ) !== undefined;
    }

}
