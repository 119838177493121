import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import {
    SikoInfoBannerComponentComponent
} from '@siko/features/shared-components/info-banner-component/info-banner-component.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        SikoInfoBannerComponentComponent
    ],
    imports: [
        B2bCommonModule,
        RouterModule,
    ],
    exports: [
        SikoInfoBannerComponentComponent
    ]
})
export class SikoInfoBannerModule { }
