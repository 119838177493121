import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CurrentProductService } from '@spartacus/storefront';
import { SikoProduct } from '@siko/models';
import { B2bCommonModule } from '@siko/shared';

@Component({
    selector: 'app-product-header-title',
    templateUrl: './product-header-title.component.html',
    styles: ['h1 {font-size: 32px} '],
    standalone: true,
    imports: [
        B2bCommonModule
    ]
})
export class SikoProductHeaderTitleComponent {

    @Input() inputProduct?: SikoProduct;

    constructor(private readonly currentProductService: CurrentProductService) {
    }

    get product$(): Observable<SikoProduct | null> {
        return this.inputProduct ? of(this.inputProduct) : this.currentProductService.getProduct();
    }

}
