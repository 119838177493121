import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
    BasePageMetaResolver,
    CmsService, PageLinkService,
    PageType, ProductPageMetaResolver, ProductService, RoutingService, TranslationService
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SikoProductPageMetaResolver extends ProductPageMetaResolver {

    constructor(
    protected translation: TranslationService,
    protected activeCartService: ActiveCartFacade,
    protected cmsService: CmsService,
    protected routingService: RoutingService,
    protected productService: ProductService,
    protected basePageMetaResolver: BasePageMetaResolver,
    protected pageLinkService: PageLinkService
    ) {
        super(routingService, productService, translation, basePageMetaResolver, pageLinkService);
        this.pageType = PageType.PRODUCT_PAGE;
        this.pageTemplate = 'ProductDetailsPageTemplate';
    }

    resolveTitle(): Observable<string> {
        return this.product$.pipe(
            switchMap((product) => {
                const title = product.name;

                return this.translation.translate('product.productPageTitle', {
                    title
                });
            })
        );
    }

}
