export const LAZY_LOADED_MODULES = {
    sikoHeader: {
        module: async () =>
            import('@siko/features/header/header.module').then(m => m.SikoHeaderModule),
        cmsComponents: [
            'SearchBoxComponent',
            'SikoUserNavigationComponent',
            'CMSSiteContextComponent',
            'LoginComponent',
            'SikoNewsLinkComponent',
            'CategoryNavigationComponent',
            'CreditLimitNotificationComponent',
            'NavigationComponent',
            'ChooseFavouriteStore',
        ],
        dependencies: [
            () => import('@siko/features/shared-components/import-entries-dialog/import-entries-dialog.module').then(m =>
                m.SikoImportEntriesDialogModule,
            ),
        ],
    },
    sikoFooter: {
        module: async () =>
            import('@siko/features/footer/footer.module').then(m => m.SikoFooterModule),
        cmsComponents: [
            'FooterMap',
            'FooterSocial',
            'FooterHelp',
        ],
    },
    sikoStoreFinder: {
        module: async () =>
            import('@siko/features/store-finder/store-finder.module').then(m => m.SikoStoreFinderModule),
        cmsComponents: [
            'StoreFinderComponent',
        ],
    },
    sikoHomePageProductCarousel: {
        module: async () =>
            import('@siko/features/product/product-carousels/product-carousel/product-carousel.module').then(m => m.SikoProductCarouselModule),
        cmsComponents: [
            'ProductCarouselComponent',
        ],
    },
    sikoProductReferencesCarousel: {
        module: async () =>
            import ('@siko/features/product/product-carousels/product-references/product-references.module').then(m => m.SikoProductReferencesModule),
        cmsComponents: [
            'ProductReferencesComponent',
        ],
    },
    sikoMainCategory: {
        module: async () =>
            import ('@siko/features/main-category/main-category-component.module').then(m => m.SikoMainCategoryModule),
        cmsComponents: [
            'SikoMainNavigationComponent',
        ],
    },
    sikoCustomerLoginModule: {
        module: async () =>
            import('@siko/features/my-account/customer-login/customer-login.module').then(m => m.SikoCustomerLoginModule),
        cmsComponents: [
            'ReturningCustomerLoginComponent',
        ],
    },
    sikoProductSearch: {
        module: async () =>
            import ('@siko/features/product/search/search-page.module').then(m => m.SikoSearchPageModule),
        cmsComponents: [
            'SearchResultsGridComponent',
        ],
    },
    // PDP
    sikoProductPDP: {
        module: async () =>
            import('@siko/features/product/product-detail/product-detail-page.module').then(m => m.ProductDetailPageModule),
        cmsComponents: [
            'AddToCartComponent',
            'ProductAddToCartComponent',
            'ProductSummaryComponent',
            'TabParagraphContainerComponent',
            'ProductImagesComponent',
            'ProductDetailsTabComponent',
            'ProductReviewsComponent',
            'ProductDocumentsTabComponent',
            'AddedToCartDialogComponent',
            'CMSTabParagraphContainer',
            'ProductInfoFooterComponent',
        ],
        dependencies: [
            () => import('@siko/features/shared-components/pick-up-in-other-store-dialog/pick-up-in-other-store-dialog.module').then(m =>
                m.SikoPickUpInOtherStoreDialogModule,
            ),
            () => import('@siko/features/shared-components/added-to-cart-dialog/added-to-cart-dialog.module').then(m =>
                m.SikoAddedToCartDialogModule,
            ),
        ],
    },
    // PLP
    sikoProductPLPList: {
        module: async () =>
            import('@siko/features/product/product-listing/product-list/product-list.module').then(m => m.SikoProductListModule),
        cmsComponents: [
            'SikoCMSProductGridComponent',
            'ContentBeforeProductGridComponent',
            'CategoryDescriptionComponent',
            'ProductRefinementComponent',
        ],
        dependencies: [
            () => import('@siko/features/shared-components/pick-up-in-other-store-dialog/pick-up-in-other-store-dialog.module').then(m =>
                m.SikoPickUpInOtherStoreDialogModule,
            ),
            () => import('@siko/features/shared-components/added-to-cart-dialog/added-to-cart-dialog.module').then(m =>
                m.SikoAddedToCartDialogModule,
            ),
        ],
    },
    sikoProductPLPBreadcrumb: {
        module: async () =>
            import ('@siko/features/product/product-listing/breadcrumb/breadcrumb.module').then(m => m.SikoBreadcrumbModule),
        cmsComponents: [
            'BreadcrumbComponent',
        ],
    },
    sikoQuickOrder: {
        module: async () =>
            import('@siko/features/quick-order/quick-order.module').then(m => m.SikoQuickOrderModule),
        cmsComponents: [
            'QuickOrderComponent',
            'QuickOrderFormComponent',
            'QuickOrderTableComponent',
            'QuickOrderItemComponent',
        ],
    },
    sikoB2BOrganization: {
        module: async () =>
            import ('@siko/features/organization/organization.module').then(m => m.SikoOrganizationModule),
        cmsComponents: [
            'ManageUnitsListComponent',
            'MyCompanyIntroComponent',
            'ListComponent',
            'TableComponent',
            'UnitUserCreateComponent',
            'UnitDetailsComponent',
            'UnitListComponent',
            'UnitUserListComponent',
            'BannerComponent',
            'ManageUsersListComponent',
        ],
        dependencies: [
            () => import('@spartacus/organization').then(m =>
                m.AdministrationModule,
            ),
            () => import('@siko/features/organization/components/ticket-history/ticket-history.module').then(m => m.SikoTicketHistoryModule),
            () => import('@siko/features/organization/components/ticket-creation/ticket-creation.module').then(m => m.SikoTicketCreationModule),
        ],
    },
    // CART MODULES
    sikoMiniCart: {
        module: async () =>
            import ('@siko/features/cart/mini-cart/mini-cart.module').then(m => m.SikoMiniCartModule),
        cmsComponents: [
            'MiniCartComponent',
        ],
    },
    sikoEmptyCart: {
        module: async () =>
            import ('@siko/features/cart/empty-cart/empty-cart.module').then(m => m.SikoEmptyCartModule),
        cmsComponents: [
            'EmptyCartComponent',
        ],
    },
    sikoCartDetails: {
        module: async () =>
            import ('@siko/features/cart/cart-details/cart-details.module').then(m => m.SikoCartDetailsModule),
        cmsComponents: [
            'CartComponent',
        ],
        dependencies: [
            () => import('@siko/features/quotes-creation/quotes-creation.module').then(m => m.SikoQuotesCreationModule),
            () => import('@siko/features/my-account/saved-cart/saved-cart.module').then(m => m.SikoSavedCartModule),
        ],
    },
    sikoImportExportEntries: {
        module: async () =>
            import ('@siko/features/cart/import-export-order-entries/import-export-order-entries.module').then(m => m.SikoImportExportOrderEntriesModule),
        cmsComponents: [
            'ImportExportOrderEntriesComponent',
        ],
    },
    sikoCartSuggestions: {
        module: async () =>
            import ('@siko/features/cart/cart-suggestion/cart-suggestion.module').then(m => m.SikoCartSuggestionModule),
        cmsComponents: [
            'CartSuggestionComponent',
        ],
    },
    // MY ACCOUNT MODULES
    sikoMyAccountNavigation: {
        module: async () =>
            import ('@siko/features/my-account/my-account-navigation/my-account-navigation.module').then(m => m.SikoMyAccountNavigationModule),
        cmsComponents: [
            'SideNavigationMenuComponent',
        ],
    },
    sikoB2BQuote: {
        module: async () =>
            import ('@siko/features/my-account/quotes-history/quotes-history.module').then(m => m.QuotesHistoryModule),
        cmsComponents: [
            'AccountQuoteHistoryComponent',
        ],
    },
    sikoSavedCart: {
        module: async () =>
            import ('@siko/features/my-account/saved-cart/saved-cart.module').then(m => m.SikoSavedCartModule),
        cmsComponents: [
            'SavedCartDetailsOverviewComponent',
            'SavedCartDetailsItemsComponent',
            'SavedCartDetailsActionComponent',
        ],
    },
    sikoOrdersHistory: {
        module: async () =>
            import ('@siko/features/my-account/order-history/order-history.module').then(
                m => m.SikoOrderHistoryModule,
            ),
        cmsComponents: [
            'AccountOrderHistoryComponent',
        ],
        dependencies: [
            'sikoSapOrderDetails',
        ],

    },
    sikoSapOrderDetails: {
        module: async () =>
            import ('@siko/features/my-account/sap-order-detail/sap-order-detail.module').then(m => m.SapOrderDetailModule),
        cmsComponents: [
            'AccountSapOrderDetailsOverviewComponent',
            'AccountSapOrderDetailsItemsComponent',
            'AccountSapOrderDetailsTotalsComponent',
        ],
        dependencies: [
            () => import('@spartacus/order').then(m =>
                m.OrderModule,
            ),
        ],
    },
    sikoOrderDetails: {
        module: async () =>
            import('@siko/features/my-account/order-details/order-details.module').then(m => m.SikoOrderDetailsModule),
        cmsComponents: [
            'AccountOrderDetailsOverviewComponent',
            'AccountOrderDetailsTotalsComponent',
            'AccountOrderDetailsItemsComponent',
        ],
        dependencies: [
            () => import('@spartacus/order').then(m =>
                m.OrderModule,
            ),
        ],
    },
    sikoTicketDetails: {
        module: async () =>
            import('@siko/features/organization/components/tickets/components/ticket-details/ticket-details.module').then((m) => m.SikoTicketDetailsModule),
        cmsComponents: [
            'TicketDetailsOverviewComponent',
            'TicketCommentsComponent',
        ],
        dependencies: [
            () => import('@siko/features/organization/organization.module').then(m => m.SikoOrganizationModule),
        ],
    },
    sikoSavedCarts: {
        module: async () =>
            import ('@siko/features/my-account/account-saved-cart-history/saved-cart.module').then(m => m.SikoSavedCartModule),
        cmsComponents: [
            'AccountSavedCartHistoryComponent',
        ],
        dependencies: [
            'sikoSavedCart',
        ],
    },
    sikoBonusProgram: {
        module: async () =>
            import ('@siko/features/my-account/bonus-program/bonus-program.module').then(m => m.SikoBonusProgramModule),
        cmsComponents: [
            'BonusProgramSummaryComponent',
            'BonusRecordsComponent',
            'BonusProgramTitleComponent',
        ],
    },
    sikoCustomerCoupons: {
        module: async () =>
            import ('@siko/features/my-account/customer-coupons/customer-coupons.module').then(m => m.SikoCustomerCouponsModule),
        cmsComponents: [
            'MyCouponsComponent',
            'CouponClaimComponent'
        ],
    },
    sikoUpdatePassword: {
        module: async () =>
            import ('@siko/features/my-account/update-password/update-password.module').then(m => m.UpdatePasswordModule),
        cmsComponents: [
            'UpdatePasswordComponent',
        ],
    },
    sikoUpdateProfile: {
        module: async () =>
            import ('@siko/features/my-account/update-profile/update-profile.module').then(m => m.SikoUpdateProfileModule),
        cmsComponents: [
            'UpdateProfileComponent',
        ],
    },
    // SIKO NEWS
    sikoNews: {
        module: async () =>
            import ('@siko/features/news/news.module').then(m => m.SikoNewsModule),
        cmsComponents: [
            'SikoNewsPagePagination',
            'SikoNewsDetailComponent',
        ],
    },
    // SIKO CONTACT
    sikoContact: {
        module: async () =>
            import('@siko/features/contact-page/contact.module').then(m => m.SikoContactModule),
        cmsComponents: [
            'SikoContactPageComponent',
        ],
    },
    // FORGOT PASSWORD
    sikoForgotPassword: {
        module: async () =>
            import('@siko/features/forgot-password/forgot-password.module').then(m => m.SikoForgotPasswordModule),
        cmsComponents: [
            'ForgotPasswordComponent',
        ],
    },
    // REGISTRATION
    sikoB2BRegistration: {
        module: async () =>
            import ('@siko/features/registration/registration.module').then(m => m.SikoRegistrationModule),
        cmsComponents: [
            'RegisterCustomerComponent',
        ],
    },
    // NOT FOUND PAGE
    sikoNotFound: {
        module: async () =>
            import ('@siko/features/not-found-page/not-found.module').then(m => m.SikoNotFoundModule),
        cmsComponents: [
            'TopErrorPageComponent',
        ],
    },
    // RESET PASSWORD
    sikoResetPassword: {
        module: async () =>
            import ('@siko/features/reset-password/reset-password.module').then(m => m.SikoResetPasswordModule),
        cmsComponents: [
            'ResetPasswordComponent',
        ],
    },
    // CHECKOUT MODULES
    sikoCheckoutAddress: {
        module: async () =>
            import ('@siko/features/checkout/shipping-address/shipping-address.module').then(m => m.SikoShippingAddressModule),
        cmsComponents: [
            'AddressFormComponent',
            'CheckoutShippingAddress',
        ],
        dependencies: [
            () => import('@spartacus/checkout/base').then(m =>
                m.CheckoutModule,
            ),
            () => import('@spartacus/checkout/b2b').then(m =>
                m.CheckoutB2BModule,
            ),
        ],
    },
    sikoCheckoutPayment: {
        module: async () =>
            import ('@siko/features/checkout/payment-type/payment-type.module').then(m => m.SikoPaymentTypeModule),
        cmsComponents: [
            'CheckoutPaymentType',
        ],
        dependencies: [
            () => import('@spartacus/checkout/base').then(m =>
                m.CheckoutModule,
            ),
            () => import('@spartacus/checkout/b2b').then(m =>
                m.CheckoutB2BModule,
            ),
        ],
    },
    sikoCheckoutOrderSummary: {
        module: async () =>
            import ('@siko/features/checkout/checkout-order-summary/checkout-order-summary.module').then(m => m.SikoCheckoutOrderSummaryModule),
        cmsComponents: [
            'CheckoutOrderSummary',
        ],
    },
    sikoCheckoutPlaceOrder: {
        module: async () =>
            import ('@siko/features/checkout/checkout-place-order/checkout-place-order.module').then(m => m.SikoCheckoutPlaceOrderModule),
        cmsComponents: [
            'CheckoutPlaceOrder',
        ],
        dependencies: [
            () => import('@spartacus/checkout/base').then(m =>
                m.CheckoutModule,
            ),
        ],
    },
    sikoCheckoutReviewOrder: {
        module: async () =>
            import ('@siko/features/checkout/review-submit/review-submit.module').then(m => m.SikoReviewSubmitModule),
        cmsComponents: [
            'CheckoutReviewOrder',
        ],
        dependencies: [
            () => import('@spartacus/checkout/base').then(m =>
                m.CheckoutModule,
            ),
        ],
    },
    sikoCheckoutProgressBar: {
        module: async () =>
            import ('@siko/features/checkout/checkout-progress/checkout-progress.module').then(m => m.SikoCheckoutProgressModule),
        cmsComponents: [
            'CheckoutProgress',
        ],
    },
    sikoOrderConfirmation: {
        module: async () =>
            import ('@siko/features/checkout/order-confirmation/order-confirmation.module').then(m => m.SikoOrderConfirmationModule),
        cmsComponents: [
            'OrderConfirmationThankMessageComponent',
            'OrderConfirmationOverviewComponent',
            'OrderConfirmationItemsComponent',
            'OrderConfirmationTotalsComponent',
        ],
        dependencies: [
            () => import('@spartacus/checkout/base').then(m =>
                m.CheckoutModule,
            ),
        ],
    },
    sikoProductBundleList: {
        module: async () => import('@siko/features/cms/product-bundle-list/product-bundle.module').then(m => m.SikoProductBundleModule),
        cmsComponents: [
            'SikoCMSProductsListComponent',
        ],
    },
};
