<ng-container *ngIf='modelWithAdBanners$ | async as model'>
  <div class='cx-page'>
    <section class='cx-page-section'>
      <ng-container *ngIf='getViewMode() | async as viewMode'>
        <div class='cx-sorting top'>
          <div class='product-block'>
            <div *ngIf='!isInfiniteScroll' class='col-auto'>
              <div
                class='cx-pagination'
                [attr.aria-label]="'productList.productSearchPagination' | cxTranslate">
              </div>
            </div>
          </div>
        </div>
        <app-active-facets [virtualCategory]='model.productSearchPageModel.virtualCategoryName'></app-active-facets>
        <div class='mobile-filter' *ngIf='model.productSearchPageModel.products && model.productSearchPageModel.products.length > 0'>
          <siko-button
            [disabled]='isShowFilterButtonDisabled(model.productSearchPageModel)'
            class='filter'
            [mode]='$any("secondary")'
            [size]='$any("md")'
            text='{{"siko.filtering"|cxTranslate}}'
            (click)='showFilter(isShowFilterButtonDisabled(model.productSearchPageModel))'>
          </siko-button>
          <siko-button
            class='sorting'
            [mode]='$any("secondary")'
            [size]='$any("md")'
            text='{{"siko.sorting"|cxTranslate}}'
            (click)='toggleMobileSorting()'>
          </siko-button>
        </div>
        <ng-container *ngIf='!isEmptySearchResult'>
          <app-sorting
            *ngIf="model.productSearchPageModel.products && model.productSearchPageModel.products.length > 0 && model.productSearchPageModel.pagination && model.productSearchPageModel.sorts && model.productSearchPageModel.pagination.hasOwnProperty('sort')"
            [sortOptions]='model.productSearchPageModel.sorts'
            (sortListEvent)='sortList($event)'
            [selectedOption]='$any(model.productSearchPageModel.pagination.sort)'
            [countOfProducts]='model.productSearchPageModel.pagination ? model.productSearchPageModel.pagination.totalResults : 0'
            [showMobileSorting]=showMobileSorting
            placeholder="{{ 'productList.sortBy' | cxTranslate }}">
          </app-sorting>
        </ng-container>
        <div class='cx-product-container'>
          <ng-container *ngIf='viewMode === "grid"'>
            <ng-container *ngFor='let item of model.items; let i = index'>
              <ng-container *ngIf='item.type === "adBanner"'>
                <app-ad-banner
                  class='grid cx-product col-xl-3 col-md-4 col-6 mb-5 pt-3'
                  [adBanner]='item.data'
                  [isGrid]='true'>
                </app-ad-banner>
              </ng-container>
              <ng-container *ngIf='item.type === "product"'>
                <app-product-grid-item
                  class='cx-product col-xl-3 col-md-4 col-6 mb-5 pt-3'
                  [product]='item.data'
                  [isStoreDisponibility]='isStoreDisponibility'>
                </app-product-grid-item>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf='viewMode === "list"'>
            <ng-container *ngFor='let item of model.items; let i = index'>
              <ng-container *ngIf='item.type === "product"'>
                <app-product-list-item-plp
                  [product]='item.data'
                  class='cx-product-list-item-plp'>
                </app-product-list-item-plp>
              </ng-container>
              <ng-container *ngIf='item.type === "adBanner"'>
                <app-ad-banner
                  [adBanner]='item.data'
                  [isGrid]='false'
                  class='w-100 mb-1'>
                </app-ad-banner>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <app-infinite-scroll [pagination]='model.productSearchPageModel.pagination'></app-infinite-scroll>
        <div class='cx-sorting bottom mt-5 mt-xl-0'>
          <div *ngIf='!isInfiniteScroll' class='col-auto'>
            <siko-pagination
              scrollPositionQuerySelector='app-siko-search-outlet'
              *ngIf='model.productSearchPageModel.pagination'
              [pagination]='model.productSearchPageModel.pagination'
              queryParam='currentPage'
              [defaultPage]='0'>
            </siko-pagination>
          </div>
        </div>
      </ng-container>
    </section>
  </div>
</ng-container>
