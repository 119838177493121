<ng-container *ngIf='productUnitData'>
  <span class='unit' [ngClass]='unitClasses'>
    <ng-container *ngIf="productUnitData.code === 'MTK'">
      <ng-container *ngIf='withSlash'>/</ng-container>
      m<sup>2</sup>
    </ng-container>
    <ng-container *ngIf="productUnitData.code === 'MTR'">
      <ng-container *ngIf='withSlash'>/</ng-container>
      m
    </ng-container>
    <ng-container *ngIf="productUnitData.code === 'MTQ'">
      <ng-container *ngIf='withSlash'>/</ng-container>
      m<sup>3</sup>
    </ng-container>
    <ng-container *ngIf="productUnitData.code === 'KGM'">
      <ng-container *ngIf='withSlash'>/</ng-container>
      kg
    </ng-container>
    <ng-container *ngIf="productUnitData.code === 'SET'">
      <ng-container *ngIf='withSlash'>/</ng-container>
      set
    </ng-container>
    <ng-container *ngIf="productUnitData.code === 'C62'">
      <ng-container *ngIf='withSlash'>/</ng-container>
      <span [innerHTML]='"siko.unit.items" | cxTranslate : {count}'></span>
    </ng-container>
    <ng-container *ngIf="productUnitData.code === 'PAR' || productUnitData.code === 'PK'">
      <ng-container *ngIf='withSlash'>/</ng-container>
      {{ productUnitData.symbol }}
    </ng-container>
  </span>
</ng-container>
