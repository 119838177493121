import { Injectable } from '@angular/core';
import { RoutingService, UserIdService } from '@spartacus/core';
import { OccSapOrderAdapter } from '@siko/features/my-account/sap-order-detail/occ/adapters/occ-sap-order.adapter';
import { SikoFileDownloadService } from '@siko/shared/utils/siko-file-download.service';
import { SikoSapOrder } from '@siko/models';
import { SikoActionLoaderService } from '@siko/features/action-loader/action-loader.service';
import { SikoActionLoader } from '@siko/features/action-loader/models/action-loader.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SikoOrderHistoryService {

    private isDocumentLoadingSubject$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly occSapOrderAdapter: OccSapOrderAdapter,
        private readonly fileDownloadService: SikoFileDownloadService,
        private readonly userIdService: UserIdService,
        private readonly routingService: RoutingService,
        private readonly actionLoaderService: SikoActionLoaderService,
    ) {}

    get isDocumentLoading$(): Observable<boolean> {
        return this.isDocumentLoadingSubject$.asObservable();
    }

    onPdfIconClick(code: string, type: string): void {
        let userId;

        this.isDocumentLoadingSubject$.next(true);

        const loader: SikoActionLoader = this.actionLoaderService.create(
            { key: `header.actionLoader.order.${type}` },
        );
        const finishCallback = this.actionLoaderService.removeCallback(loader);

        this.userIdService
            .getUserId()
            .subscribe(occUserId => userId = occUserId)
            .unsubscribe();

        if (userId) {
            this.occSapOrderAdapter
                .getSapOrderDocumentPdf(userId, code, type)
                .subscribe((pdfByteCode: Blob) => {
                        const fileName = `${type}_${code}`;

                        this.fileDownloadService.downloadFile(pdfByteCode, fileName);
                        this.isDocumentLoadingSubject$.next(false);
                        finishCallback();
                    },
                    (error) => {
                        this.isDocumentLoadingSubject$.next(false);
                    });
        }
        else {
            finishCallback();
        }
    }

    onSapOrderIdClick(sapOrder: SikoSapOrder): void {
        if (!sapOrder || !sapOrder.code) {
            return;
        }

        this.routingService.goByUrl(
            `/my-account/sap-order/${sapOrder.code}`,
        );
    }
}
