import { Component } from '@angular/core';
import { OrderConfirmationItemsComponent } from '@spartacus/order/components';

@Component({
    selector: 'app-order-confirmation-items',
    templateUrl: './order-confirmation-items.component.html'
})
export class SikoOrderConfirmationItemsComponent extends OrderConfirmationItemsComponent {

}
