<ng-container *ngIf="product$ | async as product">
  <div class='siko-lh-150 siko-fs-14'>
    {{product.summary}}
  </div>
  <div class="row">
    <div class="col">
      <siko-link
        testId='more-information-link'
        label="{{'sikoProductShared.moreAboutProduct' | cxTranslate}}"
        [style]="$any('primary')"
        [disableLink]="true"
        (click)="pdpCommunicationService.scrollToElement(
        'js--product-information-and-documents',
        1,
        'INFORMATION',
         product,
         scrollNeedsRedirect,
          false)"
      ></siko-link>
    </div>
  </div>
</ng-container>
