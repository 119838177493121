import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SikoReview } from '@siko/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-reviews-bar',
    templateUrl: './product-reviews-bar.component.html',
    styleUrls: ['./product-reviews-bar.component.scss'],
})
export class SikoProductReviewsBarComponent implements OnInit {

    @Input() reviews?: SikoReview[];
    reviesGroup: number[] = [5, 4, 3, 2, 1];
    reviewsBarRounded: number[] = [];

    ngOnInit(): void {
        this.reviews?.forEach((review: SikoReview) => {
            if (review.rating) {
                this.reviewsBarRounded.push(Math.round(review.rating));
            }
        });
    }

    // TODO Replace for Angular pipe
    reviewsOccurences(reviews: SikoReview[], rating: number): string {
        const occurences = this.reviewsBarRounded.filter(x => x === rating).length;

        return reviews.length === 0 ? '0%' : occurences / reviews.length * 100 + '%';
    }

}
