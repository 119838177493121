<div
  [cxFocus]='$any(focusConfig)'
  (esc)="close('Escape clicked')"
>
  <app-dialog-header>
    {{ 'cartShared.importEntriesDialog.title' | cxTranslate }}
  </app-dialog-header>
  <div class='siko-dialog-body'>
    <ng-container *ngIf='context$ | async as context'>
      <ng-container *ngIf='formState; else importSummary'>
        <app-import-to-new-saved-cart-form
          *ngIf='isNewCartForm(context); else reducedForm'
          [type]='context.type'
          (submitEvent)='importProductsToSavedCart(context, $event)'
        ></app-import-to-new-saved-cart-form>
        <ng-template #reducedForm>
          <app-import-entries-form
            [type]='context.type'
            (submitEvent)='importProductsToActiveCart(context, $event)'
          ></app-import-entries-form>
        </ng-template>
      </ng-container>

      <ng-template #importSummary>
        <app-import-entries-summary
          [type]='context.type'
          (closeEvent)="close('Close Import Products Dialog')"
        ></app-import-entries-summary>
      </ng-template>
    </ng-container>
  </div>
</div>
