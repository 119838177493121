export enum DOCUMENT_TYPE {
    PURCHASE_ORDER = 'Purchase Order',
    INVOICE = 'Invoice',
    CREDIT_NOTE = 'Credit Note',
    DEBIT_NOTE = 'Debit Note',
    STATEMENT = 'Statement',
    DELIVERY_NOTE = 'Delivery Note',
}

export enum FILE_EXTENSION {
    PDF = 'pdf',
    CSV = 'csv',
    XLS = 'xls',
    XLSX = 'xlsx',
}

export enum DOCUMENT_ACTION_TYPE {
    PREVIEW = 1,
    DOWNLOAD = 2,
}

export enum DOCUMENT_ICON_TYPE {
    BY_TYPE = 1,
    MODEL = 2,
    DELIVERY_NOTE = 3,
    INVOICE = 4,
    ORDER_CONFIRMATION = 5,
    PROFORMA_INVOICE = 6,
}
