import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CouponCardComponent } from '@spartacus/storefront';
import { SikoCustomerCoupon } from '@siko/models';
import { PROMOTION_ACTION_TYPE } from '@siko/constants';
import { B2bCommonModule, SikoUtils } from '@siko/shared';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-coupon-card',
    templateUrl: './coupon-card.component.html',
    styleUrls: ['./coupon-card.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
    ],
})
export class SikoCouponCardComponent extends CouponCardComponent {

    @Input() giveAwayCoupon?: boolean;
    copyCode = false;

    get sikoCoupon(): SikoCustomerCoupon {
        return this.coupon;
    }

    getCouponIcon(): string {
        switch (this.sikoCoupon.sikoCouponType) {
            case PROMOTION_ACTION_TYPE.FREE_DELIVERY:
                return 'promotions/shipping.svg';
            case PROMOTION_ACTION_TYPE.FREE_GIFT:
                return 'promotions/gift.svg';
            default:
                return 'promotions/discount.svg';
        }
    }

    copyToClipboard(text: string | undefined): void {
        if (text) {
            SikoUtils.copyToClipboard(text);
        }
    }

}
