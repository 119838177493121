import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order, OrderFacade } from '@spartacus/order/root';

@Injectable({
    providedIn: 'root'
})
export class SikoOrderConfirmationGuard implements CanActivate {
    constructor(
    private readonly orderFacade: OrderFacade,
    private readonly router: Router,
    private readonly semanticPathService: SemanticPathService
    ) {
    }

    canActivate(
        activatedRouteSnapshot: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean {
        if (activatedRouteSnapshot.paramMap.has('orderCode')) {
            return true;
        }

        return this.orderFacade.getOrderDetails().pipe(
            map((orderDetails: Order | undefined) => {
                if (orderDetails && Object.keys(orderDetails).length !== 0) {
                    return true;
                }

                return this.router.parseUrl(this.semanticPathService.get('home') ?? '');

            })
        );

    }
}
