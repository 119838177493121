import { Directive, ElementRef, HostListener } from '@angular/core';
import { SikoPdpCommunicationService } from '@siko/features/product/product-detail/pdp-communication.service';

@Directive({
    selector: '[scrolledToPdp]',
    exportAs: 'scrolledToPdp',
    standalone: true
})
export class SikoScrolledToPdpDirective {

    constructor(
        public el: ElementRef,
        private readonly pdpCommunicationService: SikoPdpCommunicationService,
    ) { }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(): void {
        const elementRect = this.el.nativeElement.getBoundingClientRect();
        const documentRect = document.body.getBoundingClientRect();
        const elementOffset = elementRect.top - documentRect.top - 53;

        if (window.pageYOffset > elementOffset && window.pageYOffset < elementOffset + elementRect.height + 53) {
            const elementId = this.el.nativeElement.id;

            switch (elementId) {
                case 'js--similar-products-tab':
                    this.pdpCommunicationService.setTabParagraphActiveNum(4);
                    break;
                case 'js--product-information-and-documents':
                    this.pdpCommunicationService.setTabParagraphActiveNum(1);
                    break;
                case 'js--product-detail-tab':
                    this.pdpCommunicationService.setTabParagraphActiveNum(2);
                    break;
                case 'js--review-tab':
                    this.pdpCommunicationService.setTabParagraphActiveNum(3);
                    break;
            }
        }
    }

}
