import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config, OCC_HTTP_TOKEN, OCC_USER_ID_CONSTANTS, UserIdService } from '@spartacus/core';
import { concatMap, map } from 'rxjs/operators';
import { AsmConfig } from '@spartacus/asm/root';

@Injectable({ providedIn: 'root' })
export class SikoHttpInterceptor implements HttpInterceptor {

    protected readonly userIdHeader = 'sap-commerce-cloud-user-id';

    protected readonly uniqueUserIdConstants: Set<string>;

    constructor(
        protected config: Config,
        protected userIdService: UserIdService,
        @Inject(OCC_USER_ID_CONSTANTS)
        protected userIdConstants: { [identifier: string]: string },
    ) {
        this.uniqueUserIdConstants = new Set(Object.values(userIdConstants));
    }

    intercept(
        httpRequest: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        /*
            For ASM to correctly work with all endpoints we either would have to add context to all
            our http calls, or we could use an interceptor to selectively apply same logic
            as ASM UserId interceptor. (UserIdHttpHeaderInterceptor)

            We cannot change the interceptor order so adding only the context to the httpRequest
            was not possible because it would be added after the OG ASM interceptor thus it would
            be without effect. Also, the OD interceptor is not exported, so we had to copy the logic.

            This will selectively apply the logic only on endpoints that would pass the condition.
         */
        if (this.shouldApply(httpRequest)) {

            if (!(this.config as AsmConfig).asm?.userIdHttpHeader?.enable) {
                return next.handle(httpRequest);
            }

            const userIdObservable: Observable<string | undefined> = this.userIdService
                .takeUserId()
                .pipe(
                    map((userId) => this.uniqueUserIdConstants.has(userId) ? undefined : userId),
                );

            return userIdObservable.pipe(
                concatMap((userId) => {
                    if (userId) {
                        const request = httpRequest.clone({
                            headers: httpRequest.headers.set(this.userIdHeader, userId),
                        });

                        return next.handle(request);
                    }

                    return next.handle(httpRequest);
                }),
            );
        }

        return next.handle(httpRequest);
    }

    private shouldApply(httpRequest: HttpRequest<unknown>): boolean {
        return httpRequest.context.get(OCC_HTTP_TOKEN).sendUserIdAsHeader === undefined
            && httpRequest.url.includes('/occ/v2/sikob2b');
    }

}
