<ng-container *ngIf='modalIsOpen'>
  <div class='remove-cart-entry-modal'>
    <app-dialog-header>
      {{ 'cartShared.removeEntry' | cxTranslate }}
    </app-dialog-header>
    <div class='row align-items-end'>
      <div class='col-md-3 order-2 order-md-1'>
        <siko-button
          testId='back-button'
          (click)='dismissModal()'
          mode='light'
          text='{{ "common.back" | cxTranslate }}'
          size='md'>
        </siko-button>
      </div>
      <div class='col-md-4 order-1 order-md-2 mb-16 mb-md-0'>
        <siko-button
          testId='remove-product-button'
          class='pl-3'
          (click)='removeCartEntry()'
          mode='primary'
          iconPosition='before'
          iconSvg='cart/remove-white'
          text='{{ "siko.delete" | cxTranslate }}'
          size='md'>
        </siko-button>
      </div>
    </div>
  </div>
</ng-container>

