import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SikoBonusProgramModel, SikoBonusRecordDetailModel } from '../../../../../models/bonus-program.model';

@Injectable({
    providedIn: 'root',
})
export class SikoOccBonusProgramAdapter {

    private readonly backendBaseUrl: string;

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
    ) {
        this.backendBaseUrl = this.occEndpoints.getBaseUrl();
    }

    getCurrentStateOfPoints(): Observable<any> {
        return this.http.get(
            `${this.backendBaseUrl}/bonus-program/points`,
        );
    }

    getRecords(): Observable<SikoBonusProgramModel[]> {
        return this.http.get<SikoBonusProgramModel[]>(
            `${this.backendBaseUrl}/bonus-program/records`,
        );
    }

    getDetailRecord(code: string): Observable<SikoBonusRecordDetailModel> {
        return this.http.get<SikoBonusRecordDetailModel>(
            `${this.backendBaseUrl}/bonus-program/records/${code}`,
        );
    }
}
