/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access */
import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AuthActions, StateUtils } from '@spartacus/core';
import { orderFilterHistoryReducer } from '@siko/features/my-account/order-history/store/reducers/order-history.reducer';
import {
    OrderHistoryFilterProps,
    OrderHistoryFilterState,
} from '@siko/features/my-account/order-history/store/order-history-state';

export const ORDER_HISTORY_FILTER_DATA = '[Registration] Siko Registration Data';

export const getReducers = (): ActionReducerMap<OrderHistoryFilterState> => ({
    orderHistoryState:
        StateUtils.loaderReducer<OrderHistoryFilterProps>(ORDER_HISTORY_FILTER_DATA, orderFilterHistoryReducer),
});

export const REDUCER_TOKEN: InjectionToken<ActionReducerMap<OrderHistoryFilterState>> =
    new InjectionToken<ActionReducerMap<OrderHistoryFilterState>>('OrderHistoryFilterReducers');

export const REDUCER_PROVIDER: Provider = {
    provide: REDUCER_TOKEN,
    useFactory: getReducers,
};

export const clearOrderHistoryFilterState = (
    reducer: ActionReducer<OrderHistoryFilterState>,
): ActionReducer<OrderHistoryFilterState> => (state, action) => {
    if (action.type === AuthActions.LOGOUT) {
        state = undefined;
    }

    return reducer(state, action);
};

export const META_REDUCERS: MetaReducer[] = [clearOrderHistoryFilterState];

