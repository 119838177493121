import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SikoOrderHistory, SikoPointOfService, SikoProduct } from '@siko/models';
import { SikoSortFunctionsService, SortColumn, SortDirection } from '@siko/shared';
import { distinct, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

interface SearchResult {
    sapOrders: SikoOrderHistory[];
    searchedProductItems: SikoProduct[] | undefined;
    total: number;
}

interface SearchState {
    page: number;
    pageSize: number;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    searchOrderNumber: string;
    searchPoNumber: string;
    searchOrderStatus: string;
    searchOrderOrigin: string;
    deliveryMethod: string;
    ownerUnit: string;
    searchProductCode: string;
}


@Injectable({
    providedIn: 'root',
})
export class SapOrderSortService {
    search$ = new Subject<void>();
    isSortingSubject = new BehaviorSubject<boolean>(true);
    isFetchingSubject = new BehaviorSubject<boolean>(false);

    sapOrdersInputSubject$ = new BehaviorSubject<SikoOrderHistory[]>([]);
    sapOrdersResultSubject$ = new BehaviorSubject<SikoOrderHistory[]>([]);
    searchedProductItemsSubject$ = new BehaviorSubject<SikoProduct[] | undefined>(undefined);
    totalSubject$ = new BehaviorSubject<number>(0);

    constructor(
        private readonly sikoSortFunctionsService: SikoSortFunctionsService,
    ) {
        this.search$.pipe(
            tap(() => {
                this.isSortingSubject.next(true);
            }),
            switchMap(() => this.search()),
        )
            .subscribe(result => {
                this.isSortingSubject.next(false);
                this.sapOrdersResultSubject$.next(result.sapOrders);
                this.searchedProductItemsSubject$.next(result.searchedProductItems);
                this.totalSubject$.next(result.total);
                if (this.page !== 0 && result.total <= this.page * this.pageSize) {
                    this.page = 0;
                }
            });
    }

    private searchState: SearchState = {
        page: 0,
        pageSize: 15,
        sortColumn: '',
        sortDirection: '',
        searchOrderNumber: '',
        searchPoNumber: '',
        searchOrderStatus: '',
        searchOrderOrigin: '',
        deliveryMethod: '',
        ownerUnit: '',
        searchProductCode: '',
    };

    get sapOrdersResult$(): Observable<SikoOrderHistory[]> {
        return this.sapOrdersResultSubject$.asObservable();
    }

    get searchedProductItems$(): Observable<SikoProduct[] | undefined> {
        return this.searchedProductItemsSubject$.asObservable()
            .pipe(distinct());
    }

    get isFetching$(): Observable<boolean> {
        return this.isFetchingSubject.asObservable()
            .pipe(
                filter((isSorting => isSorting)),
            );
    }

    get total$(): Observable<number> {
        return this.totalSubject$.asObservable();
    }

    get page(): number {
        return this.searchState.page;
    }

    get pageSize(): number {
        return this.searchState.pageSize;
    }

    set sortColumn(sortColumn: SortColumn) {
        this.set({ sortColumn });
    }

    set sortDirection(sortDirection: SortDirection) {
        this.set({ sortDirection });
    }

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    set page(page: number) {
        this.set({ page });
    }

    set searchOrderNumber(searchOrderNumber: string) {
        this.set({ searchOrderNumber });
    }

    set searchPoNumber(searchPoNumber: string) {
        this.set({ searchPoNumber });
    }

    set searchOrderStatus(searchOrderStatus: string) {
        if (searchOrderStatus === 'all') {
            searchOrderStatus = '';
        }

        this.set({ searchOrderStatus });
    }

    set searchOrderOrigin(searchOrderOrigin: string) {
        if (searchOrderOrigin === 'all') {
            searchOrderOrigin = '';
        }

        this.set({ searchOrderOrigin });
    }

    set deliveryMethod(deliveryMethod: string) {
        if (deliveryMethod === 'all') {
            deliveryMethod = '';
        }

        this.set({ deliveryMethod });
    }

    set ownerUnit(ownerUnit: string) {
        if (ownerUnit === 'all') {
            ownerUnit = '';
        }

        this.set({ ownerUnit });
    }

    set searchProductCode(searchProductCode: string) {
        this.set({ searchProductCode });
    }

    set sapOrders(sapOrders: SikoOrderHistory[]) {
        this.sapOrdersInputSubject$.next(sapOrders);
        this.search$.next();
    }

    private set(patch: Partial<SearchState>): void {
        Object.assign(this.searchState, patch);
        this.search$.next();
    }

    private search(): Observable<SearchResult> {
        const {
            sortColumn,
            sortDirection,
            pageSize,
            page,
            searchOrderStatus,
            searchOrderOrigin,
            deliveryMethod,
            ownerUnit,
        } = this.searchState;

        let sortedSapOrders: SikoOrderHistory[] = [];
        let filteredSapOrders: SikoOrderHistory[] = [];
        let searchedProductItems: SikoProduct[] = [];

        this.sapOrdersInputSubject$.pipe(distinctUntilChanged())
            .subscribe((sapOrders: SikoOrderHistory[]) => {
                if (sortColumn === 'total') {
                    sortedSapOrders = this.sikoSortFunctionsService.dataTableSort(
                        sapOrders,
                        sortColumn,
                        sortDirection,
                        'total',
                        'value',
                        true,
                    );
                }
                else {
                    sortedSapOrders = this.sikoSortFunctionsService.dataTableSort(sapOrders, sortColumn, sortDirection);
                }

                searchedProductItems = this.filterByProduct(sortedSapOrders, filteredSapOrders);

                this.filterByCode(sortedSapOrders, filteredSapOrders);


                // Filter by status
                if (searchOrderStatus && searchOrderStatus.length > 0) {
                    filteredSapOrders = filteredSapOrders.filter(
                        (sikoSapOrder: SikoOrderHistory) => sikoSapOrder.statusDisplay?.toLowerCase()
                            ?.includes(searchOrderStatus.toLowerCase()),
                    );
                }

                // Filter by Origin
                if (searchOrderOrigin && searchOrderOrigin.length > 0) {
                    filteredSapOrders = filteredSapOrders.filter(
                        (sikoSapOrder: SikoOrderHistory) => {
                            return sikoSapOrder.orderOriginCode?.toLowerCase()
                                ?.includes(searchOrderOrigin.toLowerCase()) || sikoSapOrder.user?.uid?.includes(searchOrderOrigin);
                        },
                    );
                }

                // Filter by Owner Unit
                if (ownerUnit && ownerUnit.length > 0) {
                    filteredSapOrders = filteredSapOrders.filter(
                        (sikoSapOrder: SikoOrderHistory) => sikoSapOrder.b2bUnit?.uid === ownerUnit.toLowerCase(),
                    );
                }

                // Filter by Delivery method
                if (deliveryMethod && deliveryMethod.length > 0) {
                    if (deliveryMethod === 'delivery') {
                        filteredSapOrders = filteredSapOrders.filter(
                            (sikoSapOrder: SikoOrderHistory) => sikoSapOrder.isDelivery,
                        );
                    }
                    else if (deliveryMethod === 'shipment') {
                        filteredSapOrders = filteredSapOrders.filter(
                            (sikoSapOrder: SikoOrderHistory) => sikoSapOrder.isShipment,
                        );
                    }
                    else if (deliveryMethod === 'combined') {
                        filteredSapOrders = filteredSapOrders.filter(
                            (sikoSapOrder: SikoOrderHistory) => sikoSapOrder.pointsOfService && sikoSapOrder.pointsOfService?.length > 1,
                        );
                    }
                    else {
                        filteredSapOrders = filteredSapOrders.filter((sikoSapOrder: SikoOrderHistory) =>
                            sikoSapOrder.isDelivery === false &&
                            sikoSapOrder.pointsOfService?.some((pos: SikoPointOfService) => pos.sikoSapStoreId && pos.sikoSapStoreId.toString() === deliveryMethod),
                        );
                    }
                }
            })
            .unsubscribe();

        const result = filteredSapOrders;
        const pagedResult = result.slice(page * pageSize, page * pageSize + pageSize);

        return of({
            sapOrders: pagedResult,
            searchedProductItems,
            total: result.length,
        });
    }

    private filterByCode(sapOrders: SikoOrderHistory[], filteredSapOrders: SikoOrderHistory[]): void {
        const { searchOrderNumber } = this.searchState;
        const { searchPoNumber } = this.searchState;

        const filteredByCode = sapOrders.filter(
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            (sikoSapOrder: SikoOrderHistory) => sikoSapOrder.code?.includes(searchOrderNumber.toLowerCase()) ||
                sikoSapOrder.purchaseOrderNumber?.toLowerCase()
                    .includes(searchPoNumber.toLowerCase()),
        );

        filteredByCode.forEach(sapOrder => {
            if (!filteredSapOrders.includes(sapOrder)) {
                filteredSapOrders.push(sapOrder);
            }
        });
    }

    private filterByProduct(sapOrders: SikoOrderHistory[], filteredSapOrders: SikoOrderHistory[]): SikoProduct[] {
        const { searchProductCode } = this.searchState;
        const filteredByProduct: SikoOrderHistory[] = [];
        const searchedProductItems: SikoProduct[] = [];

        if (searchProductCode.length > 0) {
            sapOrders.forEach((sikoSapOrder: SikoOrderHistory) =>
                sikoSapOrder.products?.forEach((product: SikoProduct) => {
                    if (product.code?.toLowerCase()
                        .includes(searchProductCode.toLowerCase())) {
                        filteredByProduct.push(sikoSapOrder);

                        if (!searchedProductItems.map(p => p.code)
                            .includes(product.code)) {
                            searchedProductItems.push(product);
                        }
                    }
                }));
        }

        filteredByProduct.forEach(sapOrder => {
            if (!filteredSapOrders.includes(sapOrder)) {
                filteredSapOrders.push(sapOrder);
            }
        });

        return searchedProductItems;
    }

    resetSearchState(): void {
        this.searchState = {
            page: 0,
            pageSize: 10,
            sortColumn: '',
            sortDirection: '',
            searchOrderNumber: '',
            searchPoNumber: '',
            searchOrderStatus: '',
            searchOrderOrigin: '',
            deliveryMethod: '',
            ownerUnit: '',
            searchProductCode: '',
        };
    }

}
