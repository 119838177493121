import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SikoActionsWithCartService {

    allowedContinue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    actionWithCurrentCart: BehaviorSubject<string> = new BehaviorSubject<string>('');

    isAllowedContinue$(): Observable<boolean> {
        return this.allowedContinue.asObservable();
    }

    setAllowedContinue(value: boolean): void {
        this.allowedContinue.next(value);
    }

    actionWithCurrentCart$(): Observable<string> {
        return this.actionWithCurrentCart.asObservable();
    }

    setActionWithCurrentCart(value: string): void {
        this.actionWithCurrentCart.next(value);
    }

}
