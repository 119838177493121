<ng-container *ngIf='cart'>
  <div
    [cxFocus]='focusConfig'
    (esc)="close('Escape clicked')"
    class='siko-dialog-window'
  >
    <div class='modal-dialog modal-sm'>
      <div class='modal-content show'>
        <ng-container [ngSwitch]='layoutOption'>
          <app-dialog-header (clickEvent)="close('Close Save Cart Dialog')">
            <ng-container *ngSwitchCase='savedCartFormType.EDIT'>
              {{ 'sikoSavedCart.savedCartDialog.editSavedCart' | cxTranslate }}
            </ng-container>
            <ng-container *ngSwitchCase='savedCartFormType.DELETE'>
              {{ 'sikoSavedCart.savedCartDialog.deleteSavedCart' | cxTranslate }}
            </ng-container>
            <ng-container *ngSwitchCase='savedCartFormType.SAVE'>
              {{ 'sikoSavedCart.savedCartDialog.saveForLater' | cxTranslate }}
            </ng-container>
            <ng-container *ngSwitchCase='savedCartFormType.RESTORE'>
              {{ 'sikoSavedCart.savedCartDialog.restoreSavedCart' | cxTranslate }}
            </ng-container>
          </app-dialog-header>
        </ng-container>
        <form [formGroup]='form' class='siko-saved-cart-form-container'>
          <!-- Modal Body -->
          <div class='siko-dialog-body siko-saved-cart-form-body'>
            <!-- start DELETE and RESTORE form -->
            <ng-container *ngIf='layoutOption === savedCartFormType.DELETE
            || layoutOption === savedCartFormType.RESTORE; else saveAndEditCart'>
              <p class='cx-saved-cart-form-subtitle text-left siko-fs-14'>
                {{
                  (layoutOption === savedCartFormType.DELETE
                      ? 'sikoSavedCart.savedCartDialog.followingCartDelete'
                      : 'sikoSavedCart.savedCartDialog.followingCartRestore'
                  ) | cxTranslate
                }}
              </p>

              <div class=' px-1'>
                <div class='d-block d-sm-none siko-fs-14'>
                  <div class='siko-saved-cart-values-container'>
                    <div class='siko-saved-cart-label'>
                      {{ 'sikoSavedCart.savedCartDialog.name' | cxTranslate }}
                    </div>
                    <div class='siko-saved-cart-value'>
                      {{ cart.name }}
                    </div>
                  </div>
                  <div class='siko-saved-cart-values-container'>
                    <div class='siko-saved-cart-label'>
                      {{ 'savedCartDialog.id' | cxTranslate }}
                    </div>
                    <div class='siko-saved-cart-value'>
                      {{ cart.code }}
                    </div>
                  </div>
                  <div class='siko-saved-cart-values-container'>
                    <div class='siko-saved-cart-label'>
                      {{ 'sikoSavedCart.savedCartDialog.quantity' | cxTranslate }}
                    </div>
                    <div class='siko-saved-cart-value'>
                      {{ cart.totalItems }}
                    </div>
                  </div>
                </div>
                <div class='d-none d-sm-block'>
                  <table class='w-50 mb-4'>
                    <tr class='my-2'>
                      <td class='w-50 align-baseline'>
                        <div class='siko-color-grey-3 siko-fs-14 pr-4'>
                          {{ 'sikoSavedCart.savedCartDialog.name' | cxTranslate }}
                        </div>
                      </td>
                      <td>
                        <div class='siko-fs-14'>
                          {{ cart.name }}
                        </div>
                      </td>
                    </tr>
                    <tr class='my-2'>
                      <td class='align-baseline'>
                        <div class='siko-color-grey-3 siko-fs-14'>
                          {{ 'savedCartDialog.id' | cxTranslate }}
                        </div>
                      </td>
                      <td>
                        <div class='siko-fs-14'>
                          {{ cart.code }}
                        </div>
                      </td>
                    </tr>
                    <tr class='my-2'>
                      <td class='align-baseline'>
                        <div class='siko-color-grey-3 siko-fs-14'>
                          {{ 'sikoSavedCart.savedCartDialog.quantity' | cxTranslate }}
                        </div>
                      </td>
                      <td>
                        <div class='siko-fs-14'>
                          {{ cart.totalItems }}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <ng-container *ngIf='layoutOption === savedCartFormType.RESTORE'>
                  <siko-checkbox
                    label='{{ "sikoSavedCart.savedCartDialog.keepCopySavedCart" | cxTranslate }}'
                    [size]='$any("md")'
                    [childChecked]='false'
                    [mode]='$any("default")'
                    [control]='$any(form.controls.isCloneSavedCart)'
                    (change)='toggleIsCloneSavedCart()'>
                  </siko-checkbox>
                  <div *ngIf='isCloneSavedCart' class='cx-copy-saved-cart-row d-none'>
                    <label>
                <span class='label-content'>
                  {{ 'savedCartDialog.nameOfCloneCart' | cxTranslate }}
                </span>

                      <input
                        [maxLength]='nameMaxLength'
                        class='form-control'
                        formControlName='cloneName'
                        type='text'
                        placeholder="{{
                    'savedCartDialog.defaultCloneCartName'
                      | cxTranslate: { name: form.get('name')?.value }
                  }}"
                      />
                    </label>
                  </div>
                  <ng-container *ngIf='currentCartEntries > 0'>
                    <hr class='my-5'>
                    <h3>
                      <span class='siko-color-grey-3'>
                      {{ 'sikoSavedCart.savedCartDialog.notAllowedMergeCarts' | cxTranslate }}
                      </span>
                      {{ 'cartShared.importEntriesDialog.actionWithCurrentCart' | cxTranslate }}
                    </h3>
                    <div class='d-flex flex-column flex-sm-row align-items-center justify-content-between mt-3 mb-4'>
                      <div class='saved-cart-name w-100'>
                        <siko-input-field
                          name='savedCartName'
                          placeholder='{{actualDate | cxDate : "d.M.Y H:mm"}}'
                          label="{{ 'cartShared.importEntriesDialog.nameOfSavedCart' | cxTranslate }}"
                          [control]='$any(savedCartForm.controls?.savedCartName)'
                        >
                        </siko-input-field>
                      </div>
                      <div class='saved-cart w-100'>
                        <siko-button
                          (clickEvent)='saveEntriesAndRestore(cart.code)'
                          [text]='"sikoSavedCart.savedCartDialog.saveCartAndRestore" | cxTranslate'
                          size='md'
                          iconPosition='after'
                          iconSvg='checkout/arrow_white'
                          mode='cart'
                          [block]=true
                        >
                        </siko-button>
                      </div>
                    </div>
                    <siko-button
                      (clickEvent)='removeEntriesAndRestore(cart.code)'
                      [text]='"sikoSavedCart.savedCartDialog.removeCartAndRestore" | cxTranslate'
                      size='md'
                      iconPosition='after'
                      iconSvg='cart/arrow-next-black'
                      mode='light'
                    >
                    </siko-button>
                  </ng-container>
                </ng-container>
              </div>


              <div class='siko-saved-cart-form-footer'>
                <ng-container
                  *ngIf='layoutOption === savedCartFormType.DELETE; else isRestoreSavedCart'>
                  <div class='mb-24'>
                    <siko-button class='w-100'
                                 testId='remove-cart-button'
                                 *ngIf='cart.code'
                                 text='{{ "sikoSavedCart.savedCartDialog.delete" | cxTranslate }}'
                                 iconPosition='before'
                                 iconCode='delete_forever'
                                 (clickEvent)='deleteCart(cart.code)'>
                    </siko-button>
                  </div>
                </ng-container>
                <ng-template #isRestoreSavedCart>
                  <div class='mb-24' *ngIf='currentCartEntries === 0'>
                    <siko-button *ngIf='cart.code'
                                 testId='restore-cart-button'
                                 [mode]='$any("secondary")'
                                 text='{{ "sikoSavedCart.table.makeCartActive" | cxTranslate }}'
                                 (clickEvent)='restoreSavedCart(cart.code)'
                    >
                    </siko-button>
                  </div>
                </ng-template>
                <siko-button
                  *ngIf='layoutOption === savedCartFormType.DELETE || currentCartEntries === 0'
                  testId='back-button'
                  [mode]="$any('light')"
                  text='{{ "sikoSavedCart.savedCartDialog.back" | cxTranslate }}'
                  (clickEvent)="close('Close Save Cart Dialog')">
                </siko-button>
              </div>
            </ng-container>
            <!-- end DELETE form -->

            <!-- start SAVE and EDIT form -->
            <ng-template #saveAndEditCart>
              <ng-container *ngIf='layoutOption === savedCartFormType.SAVE'>
                <p class='cx-saved-cart-form-subtitle text-left'>
                  {{ 'sikoSavedCart.savedCartDialog.itemsSavedForLater' | cxTranslate }}
                </p>
              </ng-container>

              <div class='cx-saved-cart-form-row text-left'>
                <ng-container>
              <span class='cx-saved-carts-label label-content'>
                  {{ 'sikoSavedCart.savedCartDialog.savedCartName' | cxTranslate }}
              </span>
                  <input
                    [attr.data-testid]='$any("saved-cart-name-input")'
                    aria-required='true'
                    [maxLength]='nameMaxLength'
                    class='form-control w-100'
                    formControlName='name'
                    required
                    type='text'
                  />
                  <cx-form-errors
                    aria-live='assertive'
                    aria-atomic='true'
                    [control]="$any(form.get('name'))"
                  ></cx-form-errors>
                  <p class='siko-saved-carts-input-hint'>
                    {{
                      'siko.charactersLeft'
                        | cxTranslate: { count: nameCharacterLeft }
                    }}
                  </p>
                </ng-container>
              </div>

              <div class='cx-saved-cart-form-row text-left'>
              <span class='cx-saved-carts-label label-content'
              >{{ 'sikoSavedCart.savedCartDialog.savedCartDescription' | cxTranslate }}</span
              >
                <textarea
                  [attr.data-testid]='$any("saved-cart-description-input")'
                  [maxLength]='descriptionMaxLength'
                  class='form-control'
                  formControlName='description'
                  rows='5'
                ></textarea>
                <cx-form-errors
                  aria-live='assertive'
                  aria-atomic='true'
                  [control]="$any(form.get('description'))"
                ></cx-form-errors>

                <p class='siko-saved-carts-input-hint'>
                  {{
                    'siko.charactersLeft'
                      | cxTranslate: { count: descriptionsCharacterLeft }
                  }}
                </p>
              </div>
              <div
                *ngIf='cart.code'
                class='siko-saved-cart-form-footer'>
                <div class='save'>
                  <siko-button
                    testId='save-button'
                    [mode]="$any('secondary')"
                    [disabled]='form.invalid'
                    text='{{ "sikoSavedCart.savedCartDialog.save" | cxTranslate }}'
                    (clickEvent)='saveOrEditCart(cart.code)'
                  >
                  </siko-button>
                </div>
                <siko-button
                  testId='back-button'
                  [mode]="$any('light')"
                  text='{{ "sikoSavedCart.savedCartDialog.back" | cxTranslate }}'
                  (clickEvent)="close('Close Save Cart Dialog')">
                </siko-button>
              </div>
            </ng-template>
            <!-- end SAVE and EDIT form -->
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
