<ng-container *ngIf='product$ | async as product'>
  <div class='d-flex align-items-center'>
    <ng-container *ngIf='product.numberOfReviews && product.numberOfReviews > 0'>
      <ng-container *ngIf='product.averageRating as averageRating'>
        <div class='mr-3'>
          <siko-star-rating
            [disabled]='true'
            [ratingCount]='5'
            [resetAble]='true'
            [value]='averageRating'
            [countReviews]=''
            size='sm'
          ></siko-star-rating>
        </div>
      </ng-container>
      <div
        type='button'
        class='mr-3'
        (click)="pdpCommunicationService.scrollToElement(
      'js--reviews',
       3,
       'REVIEWS',
       product,
       scrollNeedsRedirect,
       false
       )"
      >
        <span class='siko-lh-120 siko-fs-13'>
        <u>{{'sikoProductShared.review' | cxTranslate : { count: product.numberOfReviews } }}</u>
      </span>
      </div>
    </ng-container>
    <div
      *ngIf='productFromInput === undefined && product.sikoCanWriteReview'
      type='button'
      class='mr-3'
      (click)="pdpCommunicationService.scrollToElement(
      'js--write-review',
      3,
      'REVIEWS',
      product,
      scrollNeedsRedirect,
      true
      )"
    >
      <span class='siko-lh-120 siko-fs-13'>
        <u>{{'sikoProductShared.addReview' | cxTranslate}}</u>
      </span>
    </div>
  </div>
</ng-container>
