<ng-container *ngIf="sikoProduct">
  <app-product-header-description></app-product-header-description>
  <app-promotion-campaign [product]='sikoProduct'></app-promotion-campaign>

  <div class="row mt-16">
    <div class="col">
      <app-product-price *ngIf="forSale" [product]=sikoProduct></app-product-price>
    </div>
  </div>
  <div class='row'>
    <div class='col'>
      <app-product-sell-units [product]='sikoProduct'></app-product-sell-units>
    </div>
  </div>
</ng-container>
