<ng-container *ngIf='currentBasesite$ | async as currentBaseSite'>
  <ng-container *ngIf='address'>
    <ng-container *ngIf='name && mode != "businessIds" && !isShippingAddress'>
      <div>{{name}}</div>
    </ng-container>
    <ng-container *ngIf='mode === "businessIds" || mode === "full"'>
      <div *ngIf='!isShippingAddress'>
        {{"siko.address.id" | cxTranslate }}:&nbsp;{{address.businessId}}
      </div>
      <div *ngIf='getBusinessTaxId(address) as businessTaxId'>
        {{"siko.address.taxId" | cxTranslate }}:&nbsp;{{address.businessTaxId}}
      </div>
      <div *ngIf='!isShippingAddress && address?.businessVatId'>
        {{getVatIdTranslationKey(currentBaseSite) | cxTranslate }}:&nbsp;{{address.businessVatId}}
      </div>
    </ng-container>
    <ng-container *ngIf='mode === "address" || mode === "full"'>
      <div>
        {{address.line1}}&nbsp;{{address.line2}}
      </div>
      <div>
        {{address.postalCode}}&nbsp;{{address.town}}
      </div>
      <div *ngIf='!isShippingAddress'>{{address.region?.name}}</div>
      <div>
        {{address.country?.name}}
      </div>
    </ng-container>
  </ng-container>
</ng-container>
