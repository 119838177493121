<form
  (ngSubmit)='onSubmit()'
  [formGroup]='csAgentLoginForm'
  *ngIf='!csAgentTokenLoading'
>
  <label>
    <input
      required='true'
      type='text'
      formControlName='userId'
      placeholder="{{ 'asm.loginForm.userId.label' | cxTranslate }}"
      [attr.aria-label]="'asm.loginForm.userId.label' | cxTranslate"
    />
    <cx-form-errors [control]="csAgentLoginForm.get('userId')"></cx-form-errors>
  </label>

  <label>
    <input
      required='true'
      type='password'
      placeholder="{{ 'asm.loginForm.password.label' | cxTranslate }}"
      formControlName='password'
      [attr.aria-label]="'asm.loginForm.password.label' | cxTranslate"
      cxPasswordVisibilitySwitch
    />
    <cx-form-errors
      [control]="csAgentLoginForm.get('password')"
    ></cx-form-errors>
  </label>
  <button [disabled]="!csAgentLoginForm.valid" type='submit'>
    {{ 'asm.loginForm.submit' | cxTranslate }}
  </button>
</form>

<cx-dot-spinner
  *ngIf='csAgentTokenLoading'
  aria-hidden='false'
  [attr.aria-label]="'common.loading' | cxTranslate"
></cx-dot-spinner>
