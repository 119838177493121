import { Injectable } from '@angular/core';
import {
    CheckoutDeliveryAddressCreatedEvent,
    CheckoutDeliveryAddressEventListener,
    CheckoutQueryResetEvent,
    CheckoutSupportedDeliveryModesQueryResetEvent,
} from '@spartacus/checkout/base/root';
import { LoadUserAddressesEvent, OCC_USER_ID_ANONYMOUS } from '@spartacus/core';

@Injectable({
    providedIn: 'root',
})
export class SikoCheckoutDeliveryAddressEventListener extends CheckoutDeliveryAddressEventListener {

    protected onDeliveryAddressCreated(): void {
        this.subscriptions.add(
            this.eventService
                .get(CheckoutDeliveryAddressCreatedEvent)
                .subscribe(({ cartId, userId }) => {
                    if (userId !== OCC_USER_ID_ANONYMOUS) {
                        this.eventService.dispatch({ userId }, LoadUserAddressesEvent);
                    }

                    this.eventService.dispatch(
                        { userId, cartId },
                        CheckoutSupportedDeliveryModesQueryResetEvent,
                    );

                    this.eventService.dispatch({}, CheckoutQueryResetEvent);
                }),
        );
    }
}
