import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SikoProductReviewsTabComponent } from '@siko/features/product/product-detail/components';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-reviews-tab-form',
    templateUrl: './product-reviews-tab-form.component.html',
    styleUrls: ['./product-reviews-tab-form.component.scss'],
})
export class SikoProductReviewsTabFormComponent extends SikoProductReviewsTabComponent {

}
