<ng-container *ngIf='sikoProduct$ | async as sikoProduct'>
  <div class='pdp-footer mt-16 md-md-0'>
    <div *ngIf="sikoProduct.hasOwnProperty('code')" class='row'>
      <div class='col-3'>{{'siko.ourProductCode' | cxTranslate}}:</div>
      <div class='col-9'>{{sikoProduct.code}}</div>
    </div>
    <div *ngIf="sikoProduct.hasOwnProperty('brands')" class='row'>
      <div class='col-3'>{{'siko.mark' | cxTranslate}}:</div>
      <div class='col-9'>
        <a *ngFor='let brand of sikoProduct.brands' [routerLink]='brand.url'>{{brand.name}}</a>
      </div>
    </div>
    <div *ngIf="sikoProduct.hasOwnProperty('categories')" class='row'>
      <div class='col-3'>{{'siko.category' | cxTranslate}}:</div>
      <div class='col-9'>
        <span *ngFor='let category of sikoProduct.categories; last as isLast'>
          <ng-container *ngIf='category.name'>
            <a [routerLink]='category.url'>{{category.name}}</a>
            <span *ngIf='category.name!.length > 0 && !isLast'>, </span>
          </ng-container>
        </span>
      </div>
    </div>
    <div *ngIf="sikoProduct.hasOwnProperty('series')" class='row'>
      <div class='col-3'>{{'siko.series' | cxTranslate}}:</div>
      <div class='col-9'>
        <span *ngFor='let serie of sikoProduct.series; last as isLast'>
          <ng-container *ngIf='serie.name'>
            <a [routerLink]='serie.url'>{{serie.name}}</a>
            <span *ngIf='serie.name!.length > 0 && !isLast'>, </span>
          </ng-container>
        </span>
      </div>
    </div>
    <div *ngIf="sikoProduct.hasOwnProperty('concepts')" class='row'>
      <div class='col-3'>{{'siko.concept' | cxTranslate}}:</div>
      <div class='col-9'>
        <span *ngFor='let concept of sikoProduct.concepts; last as isLast'>
          <ng-container *ngIf='concept.name'>
            <a [routerLink]='concept.url'>{{concept.name}}</a>
            <span *ngIf='concept.name!.length > 0 && !isLast'>, </span>
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</ng-container>
