<ng-container *ngIf='{observableValue : sikoPlpService.isLoadingSubject$ | async } as isLoading '>
  <div class='product-grid-layout container'
       [ngClass]="{
          'animate': isLoading.observableValue,
          'plp-skeleton-displayed': (sikoPlpService.displayPlpSkeleton$ | async)}"
       #topPage>
    <cx-page-slot position='ProductLeftRefinements' *ngIf='!isEmptySearchResult'></cx-page-slot>
    <div class='product-grid-container' [ngClass]='isEmptySearchResult ? "full-width" : ""'>
      <cx-page-slot position='BreadcrumbSlot'></cx-page-slot>
      <cx-page-slot position='ContentBeforeProductGridSlot' [isPageFold]='true'></cx-page-slot>
      <cx-page-slot position='SearchResultsGridSlot' class='btf-slot'></cx-page-slot>
    </div>
    <div class='break'></div>
    <cx-page-slot position='ContentAfterProductGridComponent' class='btf-slot'></cx-page-slot>
    <ng-container *ngIf='isScrollingUp | async as isScrolling'>
      <div class='quick-scroll-up' (click)='scrollToProducts()' [ngClass]='isScrolling == true ? "d-block" : "d-none"'>
        <img [src]='"arrow_up.svg" | sikoIconAsset' alt='Scroll up icon'>
      </div>
    </ng-container>
    <ng-container *ngIf='sikoPlpService.displayPlpSkeleton$ | async'>
      <div class='plp-skeleton-placeholder'></div>
      <app-skeleton
        [skeletonType]='(sikoPlpService.viewMode$ | async) === "list" ? "plp-list" : "plp"'></app-skeleton>
    </ng-container>
  </div>
</ng-container>


