export enum HTTP_ERROR {
    CHECKOUT_PG_CONNECTION_ERROR = 400,
    CHECKOUT_INCORRECT_ORDER_CODE = 404,
    BUSINESS_REGISTER_TIMEOUT_EXCEPTION = 'Sikob2bBusinessRegisterTimeoutError',
    REGISTER_CUSTOMER_EXISTS_TYPE = 'Sikob2bActiveCustomerError',
    REGISTER_COMPANY_ALREADY_EXISTS = 'Sikob2bCompanyAlreadyRegisteredError',
    SUBMIT_CUSTOMER_ALREADY_EXISTS = 'CustomerAlreadyExistsError',
    REGISTER_RFC_FAILED_ERROR = 'Sikob2bRfcFailedError',
    REGISTER_RFC_LOCKED_ENTRY = 'Sikob2bRfcLockedEntryError',
    REGISTER_PIN_VERIFICATION_FAILED = 'Sikob2bCustomerVerificationError',
    REGISTER_CUSTOMER_ACTIVE = 'Sikob2bActiveCustomerError',
    ADD_TO_CART_DELIVERY_RESTRICTION = 'Sikob2bDeliveryRestrictionError',
    ADD_TO_CART_QUANTITY_RESTRICTION = 'Sikob2bQuantityRestrictionError',
    ADD_TO_CART_REDUCTION_RESTRICTION = 'Sikob2bReductionQuantityError',
    ADD_TO_CART_PICKUP_TO_DIFFERENT = 'Sikob2bPickupToDifferentStoresError',
    REGION_NOT_FOUND = 'Sikob2bRegionNotFoundError',
    WRONG_EXPORT_TYPE = 'Sikob2bWrongExportTypeError',
    EXPORT_FAILED = 'Sikob2bExportFailedError',
    CART_NOT_FOUND_RELOAD_REQUIRED = 'Sikob2bCartNotFoundReloadRequiredError'
}
