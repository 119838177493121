import { NgModule } from '@angular/core';
import { AuthGuard, ConfigModule } from '@spartacus/core';
import { B2bCommonModule } from '@siko/shared';
import { IconModule, ListNavigationModule, MyCouponsModule, PaginationModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { SikoMyCouponsComponent } from './components/my-coupons/my-coupons.component';
import { SikoCouponClaimComponent } from '@siko/features/my-account/customer-coupons/components/coupon-claim/coupon-claim.component';
import { SikoDuckLoaderComponent } from '@siko/features/shared-components/duck-loader/duck-loader.component';
import { SikoCouponCardComponent } from '@siko/features/shared-components/coupon-card/coupon-card.component';


@NgModule({
    declarations: [
        SikoMyCouponsComponent,
    ],
    imports: [
        B2bCommonModule,
        MyCouponsModule,
        ConfigModule.withConfig({
            cmsComponents: {
                MyCouponsComponent: {
                    component: SikoMyCouponsComponent,
                },
                CouponClaimComponent: {
                    component: SikoCouponClaimComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        IconModule,
        PaginationModule,
        ListNavigationModule,
        RouterModule,
        SikoDuckLoaderComponent,
        SikoCouponCardComponent,
    ],
})
export class SikoCustomerCouponsModule {}
