import { Injectable } from '@angular/core';
import { OccCheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/occ';
import { HttpClient, HttpContext } from '@angular/common/http';
import {
    backOff,
    ConverterService,
    isJaloError, normalizeHttpError, Occ,
    OCC_HTTP_TOKEN,
    OccEndpointsService,
    UserIdService,
} from '@spartacus/core';
import { Observable, of, throwError } from 'rxjs';
import { PaymentType } from '@spartacus/cart/base/root';
import { catchError, map } from 'rxjs/operators';
import { CHECKOUT_PAYMENT_TYPE_NORMALIZER } from '@spartacus/checkout/b2b/core';

@Injectable({
    providedIn: 'root',
})
export class SikoOccCheckoutPaymentTypeAdapter extends OccCheckoutPaymentTypeAdapter {

    private userId?: string;

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected converter: ConverterService,
        protected userIdService: UserIdService,
    ) {
        super(http, occEndpoints, converter);
        this.userIdService.getUserId().subscribe(
            (occUserId: string) => this.userId = occUserId,
        )
            .unsubscribe();
    }

    protected getPaymentTypesEndpoint(): string {
        return this.occEndpoints.buildUrl('paymentTypes', {
            urlParams: { userId: this.userId },
        });
    }

}
