import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-label-icon',
    templateUrl: './label-icon.component.html',
    styleUrls: ['./label-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
    ],
})
export class SikoLabelIconComponent {
    @Input() iconMode?: 'grey';
}
