import { NgModule } from '@angular/core';
import { B2bCommonModule, SikoModalDirective } from '@siko/shared';
import { SikoDialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { SikoDialogCloseIconSvgComponent } from '@siko/features/shared-components/dialog-components/components/dialog-close-icon/dialog-close-icon-svg.component';
import { SikoDialogHeaderSkeletonIconComponent } from './components/dialog-header-skeleton-icon/dialog-header-skeleton-icon.component';


@NgModule({
    declarations: [
        SikoDialogHeaderComponent,
        SikoDialogCloseIconSvgComponent,
        SikoDialogHeaderSkeletonIconComponent,
    ],
    imports: [
        B2bCommonModule,
        SikoModalDirective
    ],
    exports: [
        SikoDialogHeaderComponent,
    ],
})
export class SikoDialogComponentsModule {}
