import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    Optional,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { CmsComponentData, SearchBoxComponent, SearchBoxConfig } from '@spartacus/storefront';
import { CmsSearchBoxComponent, ProductActions, RoutingService, WindowRef } from '@spartacus/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { SikoSearchBoxComponentService } from '@siko/features/header/components/search-box/search-box-component.service';
import { NavigationEnd, Router } from '@angular/router';
import { SikoSearchResults } from '@siko/models';
import { SikoAutoUnsubscribe } from '@siko/common';
import { Actions, ofType } from '@ngrx/effects';
import { SearchProductsSuccess } from '@spartacus/core/src/product/store/actions/product-search.action';
import { SikoHelperFunctionsService } from '@siko/shared';


@SikoAutoUnsubscribe([
    'scrollerSubscription',
    'subscriptionRouter',
    'searchResultSubscription',
    'searchProductSubscription',
    'searchProductSuccessSubscription',
    'searchProductFailSubscription',
])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'cx-searchbox',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class SikoSearchBoxComponent extends SearchBoxComponent implements OnInit {

    readonly SEARCHING = 'searching';
    readonly EMPTY_RESULT = 'empty';
    readonly SUCCESS = 'success';

    @ViewChild('searchBar') searchWrapper?: ElementRef;
    @ViewChild('searchInput') searchInput?: ElementRef;
    @ViewChild('searchBox') searchBox?: ElementRef;
    @ViewChild('openSearchBox') openSearchBox?: ElementRef;
    @ViewChild('results') results?: ElementRef;

    textSearch?: string;
    isSearchBoxOpen = false;
    searchingState: BehaviorSubject<string> = new BehaviorSubject<string>('');

    results$: Observable<SikoSearchResults> = this.config$.pipe(
        switchMap((config: SearchBoxConfig) => this.searchBoxComponentService.getResults(config)),
    );

    scrollerSubscription?: Subscription;
    subscriptionRouter?: Subscription;
    searchResultSubscription?: Subscription;
    searchProductSubscription?: Subscription;
    searchProductSuccessSubscription?: Subscription;
    searchProductFailSubscription?: Subscription;

    constructor(
        readonly sikoHelperService: SikoHelperFunctionsService,
        private readonly actions: Actions,
        private readonly router: Router,
        private readonly renderer: Renderer2,
        protected searchBoxComponentService: SikoSearchBoxComponentService,
        @Optional()
        protected componentData: CmsComponentData<CmsSearchBoxComponent>,
        protected winRef: WindowRef,
        protected routingService: RoutingService,
    ) {
        super(searchBoxComponentService, componentData, winRef, routingService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.scrollerSubscription = fromEvent(window, 'scroll')
            .pipe()
            .subscribe(() => {
                this.makeStickyMenu(window.pageYOffset);
            });

        this.subscriptionRouter = this.router.events
            .pipe(
                filter((e): e is NavigationEnd => e instanceof NavigationEnd),
            )
            .subscribe((val: NavigationEnd) => {
                this.renderer.setProperty(this.searchInput?.nativeElement, 'value', '');
                this.searchBoxComponentService.clearResults();
                this.searchWrapper?.nativeElement.classList.add('hide-xs');
                this.isSearchBoxOpen = false;
                this.close(new UIEvent('click'), true);
            });

        this.searchResultSubscription = this.results$.subscribe(res => {
            this.textSearch = res.freeTextSearch;
        });

        this.searchProductSubscription = this.actions.pipe(ofType(ProductActions.SEARCH_PRODUCTS))
            .subscribe(() => {
                this.searchingState.next(this.SEARCHING);
            });

        this.searchProductSuccessSubscription = this.actions.pipe(ofType(ProductActions.SEARCH_PRODUCTS_SUCCESS))
            .subscribe((data: SearchProductsSuccess) => {
                this.searchingState.next(this.isResultEmpty(data.payload) ? this.EMPTY_RESULT : this.SUCCESS);
            });

        this.searchProductFailSubscription = this.actions.pipe(ofType(ProductActions.SEARCH_PRODUCTS_FAIL))
            .subscribe(() => {
                this.searchingState.next(this.EMPTY_RESULT);
            });
    }

    search(query: string): void {
        this.searchBoxComponentService.search(query.replace(/[()]/g, ''), this.config);
        this.open();
    }

    safeOpen(event: UIEvent): void {
        this.preventDefault(event);
        this.open();
    }

    open(): void {
        let scrollbarWidth = 0;
        if (this.winRef.nativeWindow?.innerWidth) {
            scrollbarWidth = this.winRef.nativeWindow?.innerWidth - this.winRef.document.body.clientWidth;
        }
        /* Timeout to fix issue on iOS devices that scrolls whole page up (even out of viewport)
            after focusing on search input element. This also works only maybe 85% of the time.
            Increasing the timeout would help but even 150ms delay is currently noticeable for customer */
        setTimeout(() => {
            this.searchBoxComponentService.toggleBodyClass('searchbox-is-active', true);
            this.winRef.document.body.style.paddingRight = (scrollbarWidth).toString() + 'px';

            this.scrollIntoSearchBarIfCreditLimitIsVisibleOnIos();
        }, 150);
    }

    scrollIntoSearchBarIfCreditLimitIsVisibleOnIos(): void {
        const userAgent = this.winRef.nativeWindow?.navigator.userAgent;

        const creditLimit = this.winRef.document.querySelector('.credit-limit-banner');

        if (creditLimit) {
            const isScrolledIntoCreditLimit = this.isScrolledIntoView(creditLimit);

            if (userAgent && /iPad|iPhone|iPod/i.test(userAgent) && isScrolledIntoCreditLimit) {
                const creditLimitHeight = creditLimit.getBoundingClientRect().height;

                this.winRef.nativeWindow?.scrollTo({ top: -creditLimitHeight });
            }
        }
    }

    isScrolledIntoView(element: Element): boolean {
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top;
        const elementBottom = rect.bottom;

        return elementTop < window.innerHeight && elementBottom >= 0;
    }

    launchSearchResult(event: UIEvent, query: string): void {
        if (!query || query.trim().length === 0) {
            return;
        }

        this.closeSearchBox(event);
        this.searchBoxComponentService.launchSearchPage(query);
    }

    closeSearchBox(event: UIEvent): void {
        /* open() function uses a 150ms timeout, because of that when a Search page is being triggered on 'enter'
            action we put a timeout for a closing SERP dropdown. */
        setTimeout(() => {
            this.close(event);
        }, 180);
    }


    makeStickyMenu(y: number): void {
        const { offsetTop } = this.searchWrapper?.nativeElement;

        if (y >= offsetTop + 1) {
            this.searchBox?.nativeElement.classList.add('sticky-searchbox');
        }
        else {
            this.searchBox?.nativeElement.classList.remove('sticky-searchbox');
        }
    }

    @HostListener('document:click', ['$event'])
    hideSearchBox(event: MouseEvent): void {
        if (this.searchBoxComponentService.hasBodyClass('searchbox-is-active') &&
            !this.searchBox?.nativeElement.contains(event.target) &&
            !this.results?.nativeElement.contains(event.target)) {
            this.renderer.removeClass(document.body, 'searchbox-is-active');
            this.close(event, true);
        }

        if (this.isSearchBoxOpen &&
            !this.openSearchBox?.nativeElement.contains(event.target) &&
            !this.searchWrapper?.nativeElement.contains(event.target) &&
            !this.searchBox?.nativeElement.contains(event.target) &&
            !this.results?.nativeElement.contains(event.target)) {
            if (!this.searchBoxComponentService.hasBodyClass('searchbox-is-active')) {
                this.renderer.addClass(this.searchWrapper?.nativeElement, 'hide-xs');
                this.isSearchBoxOpen = false;
            }
        }
    }

    openSearchBar(): void {
        if (this.searchWrapper?.nativeElement.classList.contains('hide-xs')) {
            this.renderer.removeClass(this.searchWrapper.nativeElement, 'hide-xs');
            this.isSearchBoxOpen = true;
        }
        else {
            this.renderer.addClass(this.searchWrapper?.nativeElement, 'hide-xs');
            this.isSearchBoxOpen = false;
        }
    }

    highlightPartOfText(term: string, value: string): string {
        return value.replace(term, '<strong>' + term + '</strong>');
    }

    clear(el: HTMLInputElement, event?: UIEvent): void {
        el.value = '';
        this.searchBoxComponentService.clearResults();

        if (event) {
           this.close(event);
        }
    }

    close(event: UIEvent, force?: boolean): void {
        this.winRef.document.body.style.paddingRight = '';
        super.close(event, force);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        this.searchInput?.nativeElement.blur();
    }

    closeKeyboard(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        this.searchInput?.nativeElement.blur();
    }

    showAllResults(event: UIEvent): void {
        if (this.textSearch?.length) {
            void this.routingService.goByUrl('/search/' + this.textSearch);
        }

        this.closeSearchBox(event);
    }

    isResultEmpty(searchResult: SikoSearchResults): boolean {
        return searchResult.sikoProductsCount === 0;
    }

    /**
     * Ensures category URL starts with '/' (forward slash).
     * In some cases when the '/' was missing, routerLink directive incorrectly resolved given url
     * and set incorrect value to href attribute. (See B2B1-1545)
     *
     * @param url - category url as given by API
     */
    getCategoryUrl(url: string): string {
        return url.startsWith('/') ? url : `/${url}`;
    }

}
