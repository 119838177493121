<div class='container-fluid'>
  <div class='row'>
    <div class='col'>
      <h1>{{'sikoMyCoupons.pageTitle' | cxTranslate}}</h1>
      <hr>
    </div>
  </div>
  <div id='coupons-container' class='row my-coupons'>
    <div class='col'>
      <ng-container *ngIf='(couponsLoading$ | async) === false; else loading'>
        <ng-container *ngIf='couponResult$ | async as couponResult'>
          <ng-container *ngIf='getPaginationValue(couponResult?.pagination?.totalCount) > 0; else noCoupons'>
            <p class='description'>{{'sikoMyCoupons.description' | cxTranslate}}</p>
            <div class='row coupon-cards'>
              <div *ngFor='let coupon of couponResult.coupons' class='col-xl-6'>
                <app-coupon-card
                  [coupon]='coupon'
                  [couponSubscriptionLoading$]='$any(couponSubscriptionLoading$)'
                  [giveAwayCoupon]='false'
                  (notificationChanged)='notificationChange($event)'
                ></app-coupon-card>
              </div>
            </div>
            <div *ngIf='couponResult?.pagination as resultsPagination' class='pagination'>
              <div class='records-info'>
                {{getShownRecordsRange(resultsPagination)}}&nbsp;{{'siko.datatableOfQuantityRecords' | cxTranslate:
                { count: getPaginationValue(resultsPagination.totalCount) }
                }}
              </div>
              <div class='pages'>
                <siko-pagination
                  [pagination]=$any(pagination)
                  scrollPositionQuerySelector='#coupons-container'
                  (viewPageEvent)='pageChange($event)'
                ></siko-pagination>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noCoupons>
          <span>{{ 'sikoMyCoupons.notFound' | cxTranslate }}</span>
        </ng-template>
      </ng-container>
      <ng-template #loading>
        <app-duck-loader></app-duck-loader>
      </ng-template>
    </div>
  </div>
</div>
