import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { StateWithSikoSapOrder } from '@siko/features/my-account/sap-order-detail/store/siko-sap-order-state';
import { Observable, Subscription } from 'rxjs';
import { SikoHttpErrorResponse, SikoSapOrder } from '@siko/models';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService } from '@spartacus/core';
import { BannerMode } from '@siko/features/shared-components/info-banner-component/info-banner-component.component';
import { SikoSapOrderSelectors } from '@siko/features/my-account/sap-order-detail/store/selectors';
import { CHECKOUT_PAYMENT_GATEWAY_STATUS } from '@siko/constants';
import { SikoAutoUnsubscribe } from '@siko/common';

enum SAP_ORDER_PAYMENT_STATUS {
    PAID = 'paid',
    NOT_PAID = 'notPaid',
    PARTLY_PAID = 'partlyPaid'
}

@SikoAutoUnsubscribe(['httpSubscription'])
@Component({
    selector: 'app-sap-order-additional-payment',
    templateUrl: './sap-order-payment.component.html',
    styleUrls: ['./sap-order-payment.component.scss'],
})
export class SikoSapOrderPaymentComponent {

    @Input() reorderButtonDisplayed: boolean | undefined = false;
    httpSubscription?: Subscription;

    constructor(
        private readonly http: HttpClient,
        private readonly occEndpoints: OccEndpointsService,
        private readonly store: Store<StateWithSikoSapOrder>,
        private readonly globalMessageService: GlobalMessageService,
    ) { }

    sapOrderPaymentStatus?: SAP_ORDER_PAYMENT_STATUS;
    depositDiffValue?: number;
    priceTotalWithTransportPrice?: number;

    sapOrder$: Observable<SikoSapOrder | undefined> = this.store.pipe(
        select(SikoSapOrderSelectors.getSapOrderData),
    )
        .pipe(
            tap((sikoSapOrder: SikoSapOrder | undefined) => {
                if (!sikoSapOrder) {
                    return;
                }

                this.depositDiffValue = sikoSapOrder.depositDiff?.value;
                this.priceTotalWithTransportPrice = sikoSapOrder.priceTotalWithTransportPrice?.value;

                if (this.depositDiffValue !== undefined && this.priceTotalWithTransportPrice !== undefined) {
                    this.sapOrderPaymentStatus = this.determineSapOrderPaymentStatus(this.priceTotalWithTransportPrice, this.depositDiffValue);
                }
            }),
        );

    getPaymentStatusClass(sapOrderPaymentStatus: SAP_ORDER_PAYMENT_STATUS | undefined): string {
        if (!sapOrderPaymentStatus || sapOrderPaymentStatus === SAP_ORDER_PAYMENT_STATUS.PAID) {
            return '';
        }

        return 'unpaid-status';
    }

    getInfoBannerMode(sapOrderPaymentStatus: string): BannerMode {
        if (sapOrderPaymentStatus === 'ERROR'
            || sapOrderPaymentStatus === 'CANCELLED'
            || sapOrderPaymentStatus === 'ABORTED') {
            return 'danger';
        }
        else if (sapOrderPaymentStatus === 'PAID') {
            return 'success';
        }

        return 'info';
    }

    getActionButtonTranslationKey(): string {
        if (this.sapOrderPaymentStatus === SAP_ORDER_PAYMENT_STATUS.PARTLY_PAID) {
            return 'sikoOrder.sapOrderAdditionalPayment.button.payRemaining';
        }

        return 'sikoOrder.sapOrderAdditionalPayment.button.payFull';
    }

    displayPaymentButton(sikoSapOrder: SikoSapOrder): boolean {
        return !(!this.canBePaidByOrigin(sikoSapOrder)
            || !this.canBePaidByStatus(sikoSapOrder)
            || !this.canBePaidByTotals(sikoSapOrder));
    }

    displayCheckPaymentButton(sikoSapOrder: SikoSapOrder): boolean {
        return sikoSapOrder.sapOrderPaymentStatus === CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_INITIATED;
    }

    canBePaidByOrigin(sikoSapOrder: SikoSapOrder): boolean {
        return (sikoSapOrder.order !== undefined && sikoSapOrder.orderOriginCode === 'b2b_new')
            || sikoSapOrder.orderOriginCode !== 'b2b_new';
    }

    canBePaidByStatus(sikoSapOrder: SikoSapOrder): boolean {
        return sikoSapOrder.orderStatus !== 'cancelled'
            && sikoSapOrder.orderStatus !== 'completed'
            && sikoSapOrder.orderStatus !== 'error'
            && this.sapOrderPaymentStatus !== SAP_ORDER_PAYMENT_STATUS.PAID;
    }

    canBePaidByTotals(sikoSapOrder: SikoSapOrder): boolean {
        if (sikoSapOrder.depositDiff?.value === undefined
            || sikoSapOrder.depositDiff.value <= 0
            || sikoSapOrder.priceTotalWithTransportPrice?.value === undefined) {
            return false;
        }

        return sikoSapOrder.depositDiff.value <= sikoSapOrder.priceTotalWithTransportPrice.value;
    }

    onAdditionalPaymentClick(sikoSapOrderId: string | undefined): void {
        if (!sikoSapOrderId) {
            return;
        }

        const backendBaseUrl = this.occEndpoints.getBaseUrl();

        this.httpSubscription = this.http.get(`${backendBaseUrl}/b2b/pg/pay-sap-order/${sikoSapOrderId}`)
            .subscribe((redirectUrl: any) => {
                if (redirectUrl.redirectUrl.length > 0) {
                    location.replace(redirectUrl.redirectUrl);
                }
            }, (errorResponse: SikoHttpErrorResponse) => {
                let messageKey = 'siko.payment.errorGeneral';

                if (errorResponse.error.errors[0].type === 'PaymentGatewayError') {
                    messageKey = 'siko.payment.error';
                }
                else if (errorResponse.error.errors[0].type === 'PaymentGatewayNotReachableException') {
                    messageKey = 'siko.payment.pgnr';
                }
                else if (errorResponse.error.errors[0].type === 'Sikob2bSapOrderNotFoundException') {
                    messageKey = 'siko.payment.sapOrderNotFound';
                }

                this.globalMessageService.add(
                    { key: messageKey },
                    GlobalMessageType.MSG_TYPE_ERROR,
                );
            });
    }

    private determineSapOrderPaymentStatus(priceFullValue: number, depositDiffValue: number): SAP_ORDER_PAYMENT_STATUS {
        if (depositDiffValue <= 0) {
            return SAP_ORDER_PAYMENT_STATUS.PAID;
        }
        else if (depositDiffValue >= priceFullValue) {
            return SAP_ORDER_PAYMENT_STATUS.NOT_PAID;
        }

        return SAP_ORDER_PAYMENT_STATUS.PARTLY_PAID;
    }

}
