import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sikoSlug',
    standalone: true
})
export class SikoSlugPipe implements PipeTransform {

    transform(value: string): string {
        const specialChars = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const replaceChars = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const regex = new RegExp(specialChars.split('').join('|'), 'g');

        const slug = value
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(regex, c => replaceChars.charAt(specialChars.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, ''); // Trim - from end of text

        return slug;
    }

}
