import { ParamsMapping, RoutingConfig } from '@spartacus/core';
import { ROUTE_PARAMS } from '@spartacus/organization/administration/root';

const organizationParamsMapping: ParamsMapping = {
    unitCode: 'uid',
    addressId: 'id',
    userCode: 'customerId',
};

export const CUSTOM_ROUTING_CONFIG = {
    routing: {
        protected: true,
    },
};

export const CUSTOM_ROUTES_CONFIG: RoutingConfig = {
    routing: {
        routes: {
            orderConfirmation: {
                paths: [
                    'order-confirmation/:orderCode',
                    'order-confirmation',
                ],
            },
            registrationWrapper: {
                paths: [
                    'register',
                ],
                protected: false,
            },
            product: {
                paths: [
                    ':name/p/:productCode',
                    'product/:productCode/:name',
                    'p/:productCode',
                ],
            },
            category: {
                paths: [
                    'category/:categoryCode/:categoryName',
                    'category/:categoryCode',
                ],
            },
            quotes: {
                paths: [
                    'my-account/quotes',
                ],
            },
            sapOrder: {
                paths: [
                    'my-account/sap-order/:orderCode',
                ],
                paramsMapping: { orderCode: 'code' }
            },
            /** Spartaacus default routes configuration for the orders savedCarts and quickOrder
             * routing caused flickering on My account menu. Setting each route as disabled,
             * removes this default configuration. */
            orders: {
                disabled: true,
            },
            quickOrder: {
                disabled: true,
            },
            ticketHistory: {
                paths: [
                    'organization/units/:unitCode/tickets',
                    'organization/units/tickets',
                ],
            },
            ticket: {
                paths: [
                    'organization/units/:unitCode/tickets/:ticketCode',
                ],
            },
            createTicket: {
                paths: [
                    'organization/units/:unitCode/documents/create-ticket/:code/:documentNumber',
                ],
            },
            checkout: { paths: ['checkout'] },
            checkoutDeliveryAddress: { paths: ['checkout/shipping-address'] },
            checkoutPaymentDetails: { paths: ['checkout/payment-type'] },
            checkoutReviewOrder: { paths: ['checkout/review-order'] },
            sikoOrgUnitUserEdit: {
                paths: [
                    `organization/units/:${ROUTE_PARAMS.unitCode}/users/:${ROUTE_PARAMS.userCode}/edit`
                ],
                paramsMapping: organizationParamsMapping
            },
            sikoOrgUnitUserChangePassword: {
                paths: [
                    `organization/units/:${ROUTE_PARAMS.unitCode}/users/:${ROUTE_PARAMS.userCode}/change-password`
                ],
                paramsMapping: organizationParamsMapping
            },
            sikoOrgUnitUserDetails: {
                paths: [
                    `organization/units/:${ROUTE_PARAMS.unitCode}/users/:${ROUTE_PARAMS.userCode}/detail`
                ],
                paramsMapping: organizationParamsMapping
            },
        }
    }
};
