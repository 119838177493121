import { OrderHistoryFilterProps } from '@siko/features/my-account/order-history/store/order-history-state';
import { Action } from '@ngrx/store';
import {
    OrderFilterHistoryActions,
    SapOrdersHistoryActions,
} from '@siko/features/my-account/order-history/store/actions';

export const INITIAL_STATE: OrderHistoryFilterProps = {
    filters: {
        startDate: undefined,
        endDate: undefined,
        selectedPeriod: 'LAST_MONTH',
        searchedText: '',
        orderStatus: 'all',
        orderOrigin: 'all',
        deliveryMethod: 'all',
        extendedFiltersOn: false,
        pageNumber: undefined,
    },
    orders: {
        orderHistoryList: [],
        orderOriginCriteria: [],
        orderStatusCriteria: [],
    },
};

export const orderFilterHistoryReducer = (
    state = INITIAL_STATE,
    action: Action,
): OrderHistoryFilterProps => {
    switch (action.type) {
        case SapOrdersHistoryActions.LOAD_SAP_ORDERS_SUCCESS: {
            return {
                ...state,
                orders: {
                    ...(action as SapOrdersHistoryActions.LoadOrderHistoryItemsSuccess).payload,
                },
            };
        }

        case OrderFilterHistoryActions.UPDATE_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...(action as OrderFilterHistoryActions.UpdateFilters).payload,
                },
            };
        }


        case OrderFilterHistoryActions.CLEAR_FILTERS: {
            return INITIAL_STATE;
        }
    }

    return state;
};

