import { NgModule } from '@angular/core';
import { B2bCommonModule, SikoPaymentTypeNamePipe } from '@siko/shared';
import {
    ConfigModule,
    provideConfig,
} from '@spartacus/core';
import { OrderConfirmationGuard, OrderConfirmationModule } from '@spartacus/order/components';
import {
    PromotionsModule,
    PwaModule, SpinnerModule,
} from '@spartacus/storefront';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { OccOrderAdapter } from '@spartacus/order/occ';
import { SikoOrderConfirmationGuard } from '@siko/guards';
import { SikoOrderDocumentsModule } from '@siko/features/shared-components/order-documents/order-documents.module';
import {
    SikoOrderDetailTotalsModule,
} from '@siko/features/shared-components/order-details-total/order-detial-totals.module';
import {
    SikoOrderDetailsItemsModule,
} from '@siko/features/shared-components/order-details-items/order-details-items.module';
import { SikoCartItemListModule } from '@siko/features/shared-components/cart-item-list/cart-item-list.module';
import { SikoOrderOverviewModule } from '@siko/features/order-overview/order-overview.module';
import { SikoMessageComponentModule } from '@siko/features/misc/message/message.module';
import {
    SikoOrderConfirmationItemsComponent,
    SikoOrderConfirmationOverviewComponent,
    SikoOrderConfirmationThankMessageComponent,
    SikoOrderConfirmationTotalsComponent,
} from './index';
import { SikoOrderSummaryComponent } from '@siko/features/shared-components/order-summary/order-summary.component';
import {
    SikoBonusProgramGroupIconComponent
} from "@siko/features/shared-components/icons/bonus-program-group-icon/bonus-program-group-icon.component";


@NgModule({
    providers: [
        OccOrderAdapter,
        {
            provide: OrderConfirmationGuard,
            useExisting: SikoOrderConfirmationGuard,
        },
        provideConfig({
            cmsComponents: {
                OrderConfirmationThankMessageComponent: {
                    component: SikoOrderConfirmationThankMessageComponent,
                    guards: [SikoOrderConfirmationGuard],
                },
                OrderConfirmationItemsComponent: {
                    component: SikoOrderConfirmationItemsComponent,
                    guards: [SikoOrderConfirmationGuard],
                },
                OrderConfirmationTotalsComponent: {
                    component: SikoOrderConfirmationTotalsComponent,
                    guards: [SikoOrderConfirmationGuard],
                },
                OrderConfirmationOverviewComponent: {
                    component: SikoOrderConfirmationOverviewComponent,
                    guards: [SikoOrderConfirmationGuard],
                },
            },
        }),
    ],
    declarations: [
        SikoOrderConfirmationItemsComponent,
        SikoOrderConfirmationOverviewComponent,
        SikoOrderConfirmationThankMessageComponent,
        SikoOrderConfirmationTotalsComponent,
    ],
    imports: [
        CartSharedModule,
        OrderConfirmationModule,
        ConfigModule.withConfig({
            cmsComponents: {
                OrderConfirmationThankMessageComponent: {
                    component: SikoOrderConfirmationThankMessageComponent,
                },
                OrderConfirmationOverviewComponent: {
                    component: SikoOrderConfirmationOverviewComponent,
                },
                OrderConfirmationItemsComponent: {
                    component: SikoOrderConfirmationItemsComponent,
                },
                OrderConfirmationTotalsComponent: {
                    component: SikoOrderConfirmationTotalsComponent,
                },
            },
        }),
        PromotionsModule,
        PwaModule,
        B2bCommonModule,
        SpinnerModule,
        SikoOrderSummaryComponent,
        SikoOrderDocumentsModule,
        SikoOrderDetailTotalsModule,
        SikoOrderDetailsItemsModule,
        SikoCartItemListModule,
        SikoOrderOverviewModule,
        SikoMessageComponentModule,
        SikoPaymentTypeNamePipe,
        SikoBonusProgramGroupIconComponent
    ],
    exports: [
        SikoOrderConfirmationThankMessageComponent,
        SikoOrderConfirmationOverviewComponent,
    ],
})
export class SikoOrderConfirmationModule {}
