import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ConverterService,
    OccEndpointsService,
    Occ,
    UserIdService,
} from '@spartacus/core';
import { Cart } from '@spartacus/cart/base/root';
import { Order, ORDER_NORMALIZER } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { PaymentGateway } from '@siko/models';
import { OccOrderAdapter } from '@spartacus/order/occ';


@Injectable({
    providedIn: 'root'
})
export class SikoOccCheckoutAdapter extends OccOrderAdapter {

    private readonly backendBaseUrl: string;
    private userId?: string;

    constructor(
      protected http: HttpClient,
      protected occEndpoints: OccEndpointsService,
      protected converter: ConverterService,
      private readonly occEndpointsService: OccEndpointsService,
      protected userIdService: UserIdService,
    ) {
        super(http, occEndpoints, converter);
        this.backendBaseUrl = this.occEndpointsService.getBaseUrl();
        this.userIdService.getUserId().subscribe(
            (occUserId: string) => this.userId = occUserId
        )
            .unsubscribe();
    }

    sikoPlaceOrder(
        userId: string,
        cartId: string,
        termsChecked: boolean,
        payload: object | undefined
    ): Observable<Order> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http
            .post<Occ.Order>(
                this.getPlaceOrderEndpoint(userId, cartId, termsChecked.toString()),
                payload,
                { headers }
            )
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            .pipe(this.converter.pipeable(ORDER_NORMALIZER));
    }

    getPaymentGatewayRedirectUrl(urlSuffix: string): Observable<object | string>{
        return this.http
            .get(`${this.backendBaseUrl}${urlSuffix}`, { responseType: 'text' });
    }

    syncOrder(
        userId: string,
        cartId: string
    ): Observable<HttpResponse<object>>{
        return this.http
            .get(`${this.backendBaseUrl}/orgUsers/${userId}/syncOrder?cartId=${cartId}`, {
                observe: 'response'
            });
    }

    getPaymentGatewayBuilder(orderUID: string): Observable<PaymentGateway> {
        if (this.userId && orderUID) {
            return this.http.get(
                `${this.backendBaseUrl}/orgUsers/${this.userId}/orders/pg`,
                { params: new HttpParams().append('orderUID', orderUID) }
            );
        }

        return new Observable<PaymentGateway>();
    }


    setPickupDate(date: string, pos?: string): Observable<Cart> {
        pos = pos === undefined ? '' : pos;
        return this.http.put(
            `${this.backendBaseUrl}/users/${this.userId}/carts/current/setpickupdate?preferredPickupDate=${date}&pos=${pos}`,
            null
        );
    }
}
