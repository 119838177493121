import { DOMAINS } from '@environments/environment';
import { B2B_CZ, GOOGLE_API_KEY_STAGE, OAUTH_ASM_CONFIG, OAUTH_CONFIG } from '@siko-spartacus/configurations';
import { AuthConfig, Config } from '@spartacus/core';
import { ConfigEntry } from '@environments/utils/environment-config';
import { SikoProductUnitData } from '@siko/models';
import * as Sentry from '@sentry/angular-ivy';

export class SikoUtils {

    static getDomainConfig(): ConfigEntry | undefined {
        const tmpWindow: Window | undefined = typeof window === 'undefined' ? undefined : window;
        const host = tmpWindow?.location.host ? tmpWindow.location.host : undefined;

        if (host === undefined) {
            return undefined;
        }

        if (DOMAINS.hasOwnProperty(host)) {
            return DOMAINS[host];
        }

        return undefined;
    }

    static getSiteContext(useDefault = true): Config {
        const domainConfig: ConfigEntry | undefined = this.getDomainConfig();

        if (domainConfig) {
            return domainConfig.context;
        }

        return useDefault ? B2B_CZ : {} as Config;
    }

    static getAuthConfig(): AuthConfig {
        const domainConfig: ConfigEntry | undefined = this.getDomainConfig();

        if (!!domainConfig && !!domainConfig.oauthConfig) {
            return domainConfig.oauthConfig;
        }

        return OAUTH_CONFIG;
    }

    static getAsmAuthConfig(): AuthConfig {
        const domainConfig: ConfigEntry | undefined = this.getDomainConfig();

        if (!!domainConfig && !!domainConfig.asmOauthConfig) {
            return domainConfig.asmOauthConfig;
        }

        return OAUTH_ASM_CONFIG;
    }

    static getGtmId(): string | undefined {
        const domainConfig: ConfigEntry | undefined = this.getDomainConfig();

        if (!!domainConfig && !!domainConfig.gtmId) {
            return domainConfig.gtmId;
        }

        return undefined;
    }

    static getSentryConfig(): Sentry.BrowserOptions | undefined {
        const domainConfig: ConfigEntry | undefined = this.getDomainConfig();

        if (!!domainConfig && !!domainConfig.sentry) {
            return domainConfig.sentry;
        }

        return;
    }

    static getGoogleMapsApiKey(): string {
        const domainConfig: ConfigEntry | undefined = this.getDomainConfig();

        if (!!domainConfig && !!domainConfig.googleMapApiKey) {
            return domainConfig.googleMapApiKey;
        }

        return GOOGLE_API_KEY_STAGE;
    }

    static getFileExtensionByMimeType(mimeType: string): string | undefined {
        const type = mimeType.split('/')[1];

        switch (type) {
            case 'csv':
            case 'pdf':
            case 'rtf':
            case 'xml':
            case 'zip':
            case 'gif':
            case 'png':
            case 'bmp':
                return type;
            case 'msword':
                return 'doc';
            case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'docx';
            case 'plain':
                return 'txt';
            case 'excel':
            case 'vnd.ms-excel':
                return 'xls';
            case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'xlsx';
            case 'vnd.rar':
                return 'rar';
            case 'x-tar':
                return 'tar';
            case 'x-7z-compressed':
                return '7z';
            case 'jpeg':
                return 'jpg';
            case 'svg+xml':
                return 'svg';
            default:
                return undefined;
        }
    }

    static getEnumKeyByValue<E extends { [index: string]: number | string }>(
        enumType: E,
        value: number | string,
    ): keyof E | undefined {
        const matchingEnumKeys = Object.keys(enumType)
            .filter(key => enumType[key] === value);

        return matchingEnumKeys.length > 0 ? matchingEnumKeys[0] : undefined;
    }

    static sikoRound(num: number, precision: number): number {
        return +(Math.round(Number(num + ('e+' + precision))) + ('e-' + precision));
    }

    static calculateMaxField(productStockLevel: number, productConversionRate: number): number {
        const unitsCount = Math.ceil(productStockLevel / productConversionRate);

        const possibleStockLevel = unitsCount * productConversionRate;

        if (Math.floor(possibleStockLevel) === productStockLevel) {
            return unitsCount;
        }

        return unitsCount - 1;
    }

    static formatUnitSymbol(unitData?: SikoProductUnitData): string {
        if (unitData) {
            if (unitData.code === 'MTK') {
                return 'm<sup>2</sup>';
            }
            else if (unitData.code === 'MTQ') {
                return 'm<sup>3</sup>';
            }

            return unitData.symbol;
        }

        return '';

    }

    static formatDisponibilityLevel(level: number, unit?: SikoProductUnitData): number | string {

        if (level > 99) {
            return unit?.code === 'MTK' || unit?.code === 'MTQ' ? '> 99' : '99+';
        }

        return level;
    }

    static copyToClipboard(text: string): void {
        navigator.clipboard
            .writeText(text)
            .catch((e: Error) => {
                console.error(e);
            });
    }

}
