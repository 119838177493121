import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ComponentsContext, LoggerService, normalizeHttpError } from '@spartacus/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OccNewsAdapter } from '@siko/features/news/occ/adapters/occ-news.adapter';
import { SikoNewsCmsData, SikoNewsData, SikoSelectedNewsData } from '@siko/models';
import { StateWithSikoNews } from '@siko/features/news/store/siko-news-state';
import { SikoNewsSelectors } from '@siko/features/news/store/selectors';
import { SikoNewsAction } from '@siko/features/news/store/actions/news.action';
import { SikoNewsActions } from '../actions';

@Injectable()
export class NewsEffect {

    constructor(
        readonly actions$: Actions<SikoNewsAction>,
        readonly store: Store<StateWithSikoNews>,
        readonly cmsStore: Store<ComponentsContext>,
        readonly occNewsAdapter: OccNewsAdapter,
        readonly logger: LoggerService,
    ) {
    }

    loadNews$: Observable<SikoNewsActions.LoadNewsFail
        | SikoNewsActions.LoadNewsSuccess> = createEffect(() => this.actions$.pipe(
        ofType(SikoNewsActions.LOAD_NEWS),
        withLatestFrom(this.store.select(SikoNewsSelectors.getSelectedNews)),
        switchMap(([action, selectedNews]) => this.occNewsAdapter.getNews(action.payload.currentPage)
            .pipe(
                map((data: SikoNewsData) => {
                    const { currentPage } = action.payload;

                    if (!selectedNews && data.newsPages?.length && data.newsPages[0].uid) {
                        this.store.dispatch(new SikoNewsActions.LoadSelectedNews(
                            {
                                newsUid: data.newsPages[0].uid,
                                documents: data.newsPages[0].documents,
                            },
                        ));
                    }

                    this.store.dispatch(new SikoNewsActions.ChangeCurrentPage(currentPage));
                    this.store.dispatch(new SikoNewsActions.LoadUnseenNewsCount());

                    return new SikoNewsActions.LoadNewsSuccess(data);
                }),
                catchError(error => of(
                    new SikoNewsActions.LoadNewsFail(normalizeHttpError(error, this.logger)),
                )),
            )),
    ));


    loadSelectedNews$: Observable<SikoNewsActions.LoadSelectedNewsFail
        | SikoNewsActions.LoadSelectedNewsSuccess> = createEffect(() => this.actions$.pipe(
        ofType(SikoNewsActions.LOAD_SELECTED_NEWS),
        switchMap(action =>
            this.occNewsAdapter.getSelectedNews(action.payload.newsUid)
                .pipe(
                    map((data: SikoNewsCmsData) => {
                        const selectedNews: SikoSelectedNewsData = {
                            cmsData: data,
                            documents: action.payload.documents,
                        };

                        return new SikoNewsActions.LoadSelectedNewsSuccess(selectedNews);
                    }),
                    catchError(error => of(
                        new SikoNewsActions.LoadSelectedNewsFail(error),
                    )),
                )),
    ));


    markAllAsSeen$: Observable<SikoNewsActions.LoadNews
        | SikoNewsActions.MarkAllAsSeenFail> = createEffect(() => this.actions$.pipe(
        ofType(SikoNewsActions.MARK_ALL_AS_SEEN),
        withLatestFrom(this.store.select(SikoNewsSelectors.getCurrentPage)),
        switchMap(([action, currentPage]) => this.occNewsAdapter.markAllAsSeen()
            .pipe(
                map(() => new SikoNewsActions.LoadNews({ currentPage })),
                catchError(error => of(
                    new SikoNewsActions.MarkAllAsSeenFail(normalizeHttpError(error, this.logger)),
                )),
            )),
    ));


    changeNewsStatus$: Observable<SikoNewsActions.ChangeNewsStatusFail
        | SikoNewsActions.LoadNews> = createEffect(() => this.actions$.pipe(
        ofType(SikoNewsActions.CHANGE_NEWS_STATUS),
        withLatestFrom(this.store.select(SikoNewsSelectors.getCurrentPage)),
        switchMap(([action, currentPage]) =>
            this.changeNewsStatus(action.payload.newsUid, action.payload.isNewsSeen)
                .pipe(
                    map(() => new SikoNewsActions.LoadNews({ currentPage })),
                    catchError(error => of(
                        new SikoNewsActions.ChangeNewsStatusFail(normalizeHttpError(error, this.logger)),
                    )),
                )),
    ));

    private changeNewsStatus(newsUid: string, isNewsSeen: boolean): Observable<unknown> {
        if (isNewsSeen) {
            return this.occNewsAdapter.markNewsAsUnseen(newsUid);
        }

        return this.occNewsAdapter.markNewsAsSeen(newsUid);
    }

}
