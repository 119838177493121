import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService, LanguageService, Price } from '@spartacus/core';
import { formatNumber, getLocaleId } from '@angular/common';

@Pipe({
    name: 'sikoPrice',
})
export class SikoPricePipe implements PipeTransform {

    constructor(
        public currencyService: CurrencyService,
        private readonly language: LanguageService,
    ) {
    }

    transform(price: Price | number | undefined): string {
        let formattedPrice = '';

        if (price == undefined) {
            return formattedPrice;
        }

        const convertedPrice = typeof price === 'object' ? price.value : price;
        if (convertedPrice === undefined) {
            return formattedPrice;
        }

        const currency = this.getCurrency();
        let prefix = '';
        let suffix = '';

        if (this.getLang()
            .includes('en')) {
            prefix = currency;
        }
        else {
            suffix = '\xA0' + currency;
        }

        const digitsInfo = Number.isInteger(convertedPrice) ? '' : '1.2-2';

        formattedPrice = formatNumber(convertedPrice, this.getLang(), digitsInfo);
        return prefix + formattedPrice + suffix;
    }

    private getCurrencyFromIsoCode(isoCode: string | undefined): string {
        return isoCode?.includes('EUR') ? '€' : 'Kč';
    }

    private getCurrency(): string {
        let currency = '';

        this.currencyService.getActive()
            .subscribe((data) => currency = data)
            .unsubscribe();

        return this.getCurrencyFromIsoCode(currency);
    }

    private getLang(): string {
        const lang = this.getActiveLang();

        try {
            getLocaleId(lang);
            return lang;
        } catch {
            return 'en';
        }
    }

    private getActiveLang(): string {
        let result = '';

        this.language
            .getActive()
            .subscribe((lang) => result = lang)
            .unsubscribe();

        return result;
    }

}
