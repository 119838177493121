import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    Input, OnDestroy,
    OnInit, QueryList,
    Renderer2,
    ViewChildren,
} from '@angular/core';
import { CmsComponentsService, HamburgerMenuService, NavigationUIComponent } from '@spartacus/storefront';
import { CmsService, WindowRef } from '@spartacus/core';
import { NavigationStart, Router } from '@angular/router';
import { SikoNavigationNode } from '@siko/models';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-category-navigation-ui',
    templateUrl: './category-navigation-ui.component.html',
    styleUrls: ['./category-navigation-ui.component.scss'],
})
export class CategoryNavigationUiComponent extends NavigationUIComponent implements OnInit, OnDestroy {

    @Input() node!: SikoNavigationNode;

    @ViewChildren('wrapper') navWrapper?: QueryList<ElementRef>;
    @ViewChildren('navLevel2') navigationsLevel2?: QueryList<ElementRef>;
    @ViewChildren('navLevel3') navigationsLevel3?: QueryList<ElementRef>;
    @ViewChildren('refEl') showMore?: QueryList<ElementRef>;

    indexer = 0;
    private routingSubscription?: Subscription;
    private readonly cmsService: CmsService;
    private readonly cmsComponentsService: CmsComponentsService;

    protected readonly sikoRouter: Router;
    private readonly sikoRenderer: Renderer2;

    constructor(
        router: Router,
        renderer: Renderer2,
        elemRef: ElementRef,
        cmsService: CmsService,
        cmsComponentsService: CmsComponentsService,
        protected hamburgerMenuService: HamburgerMenuService,
        protected readonly changeDetectorRef: ChangeDetectorRef,
        windowRef: WindowRef,
    ) {
        super(router, renderer, elemRef, hamburgerMenuService, windowRef);
        this.cmsService = cmsService;
        this.cmsComponentsService = cmsComponentsService;
        this.sikoRouter = router;
        this.sikoRenderer = renderer;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.routingSubscription = this.sikoRouter.events.pipe(
            filter((e): e is NavigationStart => e instanceof NavigationStart),
        )
            .subscribe((value: NavigationStart) => {
                this.changeNodeStatus(this.indexer);

                this.navigationsLevel2?.forEach((navigationLevel2: ElementRef) => {
                    this.sikoRenderer.setStyle(navigationLevel2.nativeElement, 'display', 'none');
                });

                this.navigationsLevel3?.forEach((navigationLevel3: ElementRef) => {
                    const overElements = navigationLevel3.nativeElement.querySelector('.hidden-items-container');

                    if (overElements !== null && overElements.children.length > 1) {
                        for (let i = 0; i < overElements.children.length; i++) {
                            this.sikoRenderer.setStyle(overElements.children[i], 'display', 'none');
                        }

                        const showMoreButton = navigationLevel3.nativeElement.querySelector('.show-more');

                        if (showMoreButton !== null) {
                            this.sikoRenderer.setStyle(showMoreButton, 'display', 'inline-flex');
                        }
                    }
                });
            });
    }

    changeNodeStatus(index: number): void {
        if (this.node && this.node.children && this.node.children[index]) {
            if (this.node.children[index].isActive === undefined) {
                this.node.children[index].isActive = false;
                this.changeDetectorRef.detectChanges();

                return;
            }
            else if (this.node.children[index].isActive) {
                this.node.children[index].isActive = false;
                this.changeDetectorRef.detectChanges();

                return;
            }
            else if (!this.node.children[index].isActive) {
                this.node.children[index].isActive = true;
                this.changeDetectorRef.detectChanges();

                return;
            }
        }
    }

    trackByNavigationNode(index: number, item: SikoNavigationNode) {
        return `${item.uid ?? ''}` + `${item.isActive ?? ''}`;
    }

    calculateChildTop(wrapper: HTMLElement): void {
        const top = wrapper.offsetTop + wrapper.offsetHeight - 10;
        const wrapperLevel2 = Array.from(wrapper.children)
            .filter(child => child.className.includes('nav__level2'));

        if (wrapperLevel2.length) {
            wrapperLevel2[0].setAttribute('style', `top: ${top}px`);
        }
    }

    showHiddenItems(el: any): void {
        const childs = el.parentNode.children;

        Array.from(childs)
            .filter((child: any) => child.tagName === 'SPAN')
            .forEach((child: any) => child.style.display = 'none');

        Array.from(childs)
            .filter((child: any) => child.tagName === 'DIV')
            .forEach((child: any) => child.style.display = 'block');
    }

    hasSimpleBannerComponent(children: SikoNavigationNode[] | undefined): any {
        return !children ? undefined : Array.from(children)
            .filter((child: SikoNavigationNode) => child.itemType === 'SimpleBannerComponent')
            .pop();
    }

    hasImageGridComponent(children: SikoNavigationNode[] | undefined): any {
        return !children ? undefined : Array.from(children)
            .filter((child: SikoNavigationNode) => child.itemType === 'SikoCMSImageGridComponent')
            .pop();
    }

    hasBrandBannerCMSComponent(children: SikoNavigationNode[] | undefined): any {
        return !children ? undefined : Array.from(children)
            .filter((child: SikoNavigationNode) => child.itemType === 'BrandBannerCMSComponent')
            .pop();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.routingSubscription?.unsubscribe();
    }

}
