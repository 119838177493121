import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, FormControlDirective } from '@angular/forms';
import { SikoProduct, SikoProductDisponibility } from '@siko/models';
import { SikoProductDisponibilityService, SikoUtils } from '@siko/shared';
import { Subscription } from 'rxjs';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { filter } from 'rxjs/operators';
import { isNotUndefined } from '@spartacus/core';
import { SikoAutoUnsubscribe } from '@siko/common';

@SikoAutoUnsubscribe(['activeCartSubscription'])
@Component({
    selector: 'app-abstract-item-counter',
    template: '',
})
export class SikoAbstractItemCounterComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() highlighted: boolean = false;
    @Input() control?: UntypedFormControl;
    @Input() product?: SikoProduct;
    @Input() label?: string;
    @Input() isLimited = false;
    @Input() displayRemoveIcon = false;
    @Input() counterSize: 'md' | 'lg' = 'md';

    @Input() isCreateTicketItemCounter = false;

    @Input() max?: number;
    @Input() step = 1;
    @Input() index?: number;
    @Input() allowTooltip: boolean = true;

    @ViewChild('displayValue', { read: FormControlDirective }) displayValue?: FormControlDirective;
    @ViewChild('itemCounterInputField') itemCounterInputField?: ElementRef;

    // quickOrderItemCounter QuickOrder and itemCounterCart variables
    @Input() posName?: string;

    //createTicketItemCounter
    @Input() displayQuantityMessage = false;

    minVal = 0;
    productUnitConversion = 1;

    // quickOrderItemCounter QuickOrder and itemCounterCart variables
    productDisponibility?: SikoProductDisponibility;
    productStockLevel?: number;
    inputIsFocused = false;
    isButtonDisabled = false;

    isStoreSelected = false;

    activeCartSubscription: Subscription = new Subscription();

    constructor(
        protected sikoProductDisponibilityService: SikoProductDisponibilityService,
        readonly activeCartService: ActiveCartFacade,
    ) {}

    ngOnInit(): void {
        this.activeCartSubscription.add(
            this.activeCartService.getActive()
                .pipe(
                    filter(isNotUndefined),
                )
                .subscribe((cart) => {
                        this.isStoreSelected = false;
                    },
                ));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.product?.previousValue?.code === changes.product?.currentValue?.code) {return;}

        this.productUnitConversion = Number(this.product?.unitData?.conversion ?? 1);
        this.minVal = this.productUnitConversion;
        this.displayValue?.valueAccessor?.writeValue(this.sikoProductDisponibilityService.calculateDisplayValue(this.control?.value, this.productUnitConversion));

        const maximum = this.product?.disponibility?.maximum;

        this.productDisponibility = this.product?.disponibility;

        if (this.max === undefined) {
            if (this.isLimited && !this.productDisponibility?.isFIS && maximum) {
                this.max = this.productUnitConversion === 1 ? maximum : this.sikoProductDisponibilityService.calculateMaxField(maximum, this.productUnitConversion);
            }
            else {
                this.max = this.productUnitConversion === 1 ? 9999 : this.sikoProductDisponibilityService.calculateMaxField(9999, this.productUnitConversion);
            }
        }
    }

    focusInput(): void {
        const input = this.itemCounterInputField?.nativeElement;
        input?.focus();
        input?.setSelectionRange(0, input?.value.length);
    }

    preventLetters(event: KeyboardEvent): void {
        const pattern = /^\d$|Backspace|Arrow(Left|Right|Up|Down)|[,\.]$/;

        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }

    roundQuantity(quantity: number): string {
        return SikoUtils.sikoRound(quantity, 3).toString();
    }

    ngAfterViewInit(): void {
        this.displayValue?.valueAccessor?.writeValue(this.sikoProductDisponibilityService.calculateDisplayValue(this.control?.value, this.productUnitConversion));
    }

    focusOut(event: FocusEvent): void {
        if (!this.control || this.control.pristine) {return;}

        let { value } = this.control;

        value = this.validateValue(value);

        let comparedValue = this.productUnitConversion < 1 ? value / this.productUnitConversion : value;
        if (this.max && comparedValue > this.max) {
            value = this.max * this.productUnitConversion;
        }

        value = value <= 0 ? this.minVal : value;

        const addToCartValue = this.sikoProductDisponibilityService.getActualQuantity(value.toString(), this.productUnitConversion);
        this.control?.setValue(addToCartValue);
        this.displayValue?.valueAccessor?.writeValue(this.sikoProductDisponibilityService.calculateDisplayValue(addToCartValue, this.productUnitConversion));
        this.control?.markAsPristine();
    }

    decrement($event: Event): void {
        $event.stopPropagation();

        let actualValue = this.sikoProductDisponibilityService.calculateDisplayValue(this.control?.value, this.productUnitConversion);
        if (this.control && (this.control.value === 1 || +actualValue === this.minVal)) {
            return;
        }
        this.control?.setValue(this.control?.value - this.step);
        this.displayValue?.valueAccessor?.writeValue(this.sikoProductDisponibilityService.calculateDisplayValue(this.control?.value, this.productUnitConversion));
    }

    increment($event: Event, allow = true): void {
        $event.stopPropagation();
        if (allow) {
            this.control?.setValue(this.control.value + this.step);
            this.displayValue?.valueAccessor?.writeValue(this.sikoProductDisponibilityService.calculateDisplayValue(this.control?.value, this.productUnitConversion));
        }
    }

    // itemCounterQuickOrder and itemCounterCart methods
    showRemoveIcon(): boolean {
        return this.displayRemoveIcon && this.control?.value - this.step <= 0;
    }

    isTooltipEnabled(control: UntypedFormControl): boolean {

        if (this.productDisponibility?.isFIS && control.value !== 9999) {
            return true;
        }

        return this.max === undefined ? true : control.value < this.max;
    }

    validateValue(value: any): any {
        value = value === '.' || value === ',' ? this.productUnitConversion.toString() : value;
        return value.replace(',', '.');
    }

}
