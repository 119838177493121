import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sikoOrderStatusBadge',
})
export class SikoOrderStatusBadgePipe implements PipeTransform {

    private readonly SAP_ORDER_BADGE_STYLES: { [key in string]: string } = {
        open: 'info',
        completed: 'success',
        cancelled: 'danger',
        error: 'error',
        delivering: 'warning',
        ready: 'info',
        processing: 'info',
        'partly-instock': 'default',
        'partly-outstock': 'default',
        outstock: 'default',
        pickup: 'default',
        packeta: 'default',
        delivery: 'default',
    };

    private readonly COMMERCE_ORDER_BADGE_STYLES: { [key in string]: string } = {
        COMPLETED: 'success',
        CREATED: 'success',
        APPROVED: 'success',
        REJECTED: 'danger',
        REJECTED_BY_MERCHANT: 'danger',
        CANCELLED: 'danger',
        CANCELLING: 'warning',
        CHECKED_INVALID: 'warning',
        PAYMENT_NOT_AUTHORIZED: 'warning',
        PAYMENT_NOT_CAPTURED: 'warning',
        PAYMENT_AMOUNT_NOT_RESERVED: 'warning',
        PAYMENT_NOT_VOIDED: 'warning',
        PAYMENT_AMOUNT_RESERVED: 'warning',
        PROCESSING_ERROR: 'warning',
        TAX_NOT_VOIDED: 'warning',
        TAX_NOT_COMMITTED: 'warning',
        TAX_NOT_REQUOTED: 'warning',
        WAIT_FRAUD_MANUAL_CHECK: 'warning',
        SUSPENDED: 'warning',
        OPEN: 'info',
        FRAUD_CHECKED: 'info',
        CHECKED_VALID: 'info',
        APPROVED_BY_MERCHANT: 'info',
        PENDING_APPROVAL: 'info',
        PENDING_APPROVAL_FROM_MERCHANT: 'info',
        ASSIGNED_TO_ADMIN: 'info',
        ON_VALIDATION: 'info',
        ORDER_SPLIT: 'info',
        PAYMENT_AUTHORIZED: 'info',
        PAYMENT_CAPTURED: 'info',
    };

    transform(statusDisplay: string | undefined, mode: string): string {
        const styles = mode === 'SAP_ORDER' ? this.SAP_ORDER_BADGE_STYLES : this.COMMERCE_ORDER_BADGE_STYLES;
        const defaultStyle = mode === 'SAP_ORDER' ? 'default' : 'success';

        return statusDisplay ? styles[statusDisplay] : defaultStyle;
    }

}
