<div class='filter-section p-2 p-md-0 ' [ngClass]='showMobileSorting ? "d-flex" : ""'>
  <label>{{"siko.sortingBy"|cxTranslate}}</label>
  <ng-select *ngIf='sortOptions'
             class='mobile-sorting'
             [ngClass]='showMobileSorting ? "show" : "hide"'
             [items]='sortOptions'
             [searchable]='false'
             bindLabel='name'
             bindValue='code'
             [searchable]='false'
             (change)='changeFn($event)'>
  </ng-select>
  <ul ngbNav #nav='ngbNav' class='nav-tabs'>
    <li [ngClass]='$any(option.value).code' [ngbNavItem]='option.key'
        *ngFor='let option of sortOptions | keyvalue | slice:0:countShowingOptions'
        (click)='sortList($any(option.value).code)'>
      <a class="nav-link {{selectedOption === $any(option.value).code ? 'active-sort' : ''}}"
          >
        {{$any(option.value).name}}
      </a>
    </li>
    <li class='more-sort nav-link' *ngIf='showMoreOptions'>
      {{'siko.more' | cxTranslate}} <img [src]='"arrow_down.svg" | sikoIconAsset' alt='Arrow icon' />
      <ul>
        <li [ngClass]='$any(option.value).code' *ngFor='let option of sortOptions | keyvalue | slice:3:6'
            (click)='sortList($any(option.value).code)'>
          <a class="{{selectedOption === $any(option.value).code ? 'active-sort' : ''}}">{{$any(option.value).name}}</a>
        </li>
      </ul>
    </li>
  </ul>
  <div class='options'>
    <div class='count-of-products d-block' *ngIf='countOfProducts'>
      {{"siko.countOfProducts"|cxTranslate : { count: countOfProducts } }}
    </div>
    <div class='switcher'>
      <span>
        {{'siko.viewMode' | cxTranslate}}
      </span>
      <app-product-view-switcher
      ></app-product-view-switcher>
    </div>
  </div>
</div>
<div class='switcher mobile'>
  <div class='count-of-products non-desktop' *ngIf='countOfProducts'>
    {{"siko.countOfProducts"|cxTranslate : { count: countOfProducts } }}
  </div>
  <div class='switcher-section'>
      <span>
        {{'siko.viewMode' | cxTranslate}}
      </span>
    <app-product-view-switcher
    ></app-product-view-switcher>
  </div>
</div>
