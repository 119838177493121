import { Action } from '@ngrx/store';

import { SikoDefaultNewsState } from '@siko/features/news/store/siko-news-state';
import { SikoNewsActions } from '../actions';

export const INITIAL_STATE: SikoDefaultNewsState = {
    newsData: {},
    selectedNews: undefined,
    currentPage: 0
};

export const sikoNewsReducer = (
    state = INITIAL_STATE,
    action: Action
): SikoDefaultNewsState => {
    switch (action.type) {
        case SikoNewsActions.LOAD_NEWS_SUCCESS: {
            const newsData = (action as SikoNewsActions.SikoNewsAction).payload;

            return {
                ...state,
                newsData,
            };
        }

        case SikoNewsActions.LOAD_SELECTED_NEWS_SUCCESS: {
            const selectedNews = (action as SikoNewsActions.SikoNewsAction).payload;

            return {
                ...state,
                selectedNews
            };
        }

        case SikoNewsActions.CHANGE_CURRENT_PAGE: {
            const currentPage = (action as SikoNewsActions.SikoNewsAction).payload;

            return {
                ...state,
                currentPage
            };
        }
    }


    return state;
};
