import { StateUtils } from '@spartacus/core';
import { SikoSapOrder, SikoSapOrderDeliveryNote, SikoSapOrderInvoice } from '@siko/models';

export const SIKO_SAP_ORDER_FEATURE = 'sikoSapOrder';

export interface StateWithSikoSapOrder {
  [SIKO_SAP_ORDER_FEATURE]: SikoSapOrderState;
}

export interface SikoSapOrderState{
  sapOrder: StateUtils.LoaderState<SikoSapOrderDetailState>;
}

export interface SikoSapOrderDetailState{
  sapOrderData?: SikoSapOrder;
  invoices: SikoSapOrderInvoice[];
  deliveryNotes: SikoSapOrderDeliveryNote[];
}
