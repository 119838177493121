<div *ngIf='productDisponibility'>
  <div class='stockLevelInfo' [ngClass]='productDisponibility.status'
       *ngIf='isStoreDisponibility; else eshopDisponibility'>
    <span *ngIf='user$ | async as user'>
      <span
        class='text-muted'>{{'siko.storeDisponibility' | cxTranslate}} {{user.orgUnit?.sikoDefaultShippingAddress?.companyName}}</span>
      <span class='d-block'> {{productDisponibility.text}}</span>
    </span>
  </div>
  <ng-template #eshopDisponibility>
    <div *ngIf='productDisponibility' class="stock-level-info {{displayShortVersion ? 'short-version' : ''}}"
         [ngClass]="saleEnded ? 'sale-ended' : productDisponibility.status">
      <!--      skladem-->
      <div *ngIf="productDisponibility.status === 'inStock' && !saleEnded;">
        <div class='stock-info'>
          <span *ngIf='!displayShortVersion else shortVersion'>
            {{'siko.inStock' | cxTranslate}} {{roundedAmount}}&nbsp;
            <app-product-unit
              [productUnitData]='unitData'
              [inheritTextStyles]='true'
              [count]='productDisponibility.level'>
            </app-product-unit>
          </span>
          <ng-template #shortVersion>
            <span>
              <div class='value'>{{roundedAmount}}</div>&nbsp;
              <app-product-unit
                [count]='productDisponibility.level'
                [productUnitData]='unitData'
                [inheritTextStyles]='true'>
              </app-product-unit>
            </span>
          </ng-template>
        </div>
      </div>
      <div *ngIf="productDisponibility.status === 'outOfStock' || saleEnded; else upcoming">
        <div class='stock-info'>
          <div class='content' *ngIf="productDisponibility.status === 'outOfStock' && !saleEnded">
            {{'siko.outOfStock' | cxTranslate}}
          </div>
          <div class='content' *ngIf="productDisponibility.status === 'outOfStock' && saleEnded">
            {{'siko.saleEnded' | cxTranslate}}
          </div>
        </div>
      </div>
      <ng-template #upcoming>
        <div *ngIf="productDisponibility.status === 'upcoming'" class='d-flex align-items-center'>
          <div>
            <span class='upcoming-icon d-flex'></span>
          </div>
          <div class='stock-info icon' *ngIf='!displayShortVersion else shortVersion'>
            <span class='state'>{{'siko.awaiting' | cxTranslate}}</span>
            <span class='countDay'>{{productDisponibility.text}}</span>
          </div>
          <ng-template #shortVersion>
            <div class='value'>{{this.upcomingDate | cxDate : 'd.M.'}}</div>
          </ng-template>
        </div>
      </ng-template>
      <div *ngIf="productDisponibility.status === 'onOrder' && !saleEnded" class='d-flex align-items-center'>
        <div>
          <span class='on-order-icon d-flex'></span>
        </div>
        <div class='stock-info' *ngIf='!displayShortVersion else shortVersion'>
          <span class='state'>{{'siko.onOrder' | cxTranslate}}</span>
          <span class='countDay' *ngIf='productDisponibility.deliveryTime'>{{productDisponibility.text}}</span>
        </div>
        <ng-template #shortVersion>
          <div class='value'>{{productDisponibility.deliveryTime}} <span *ngIf='productDisponibility.deliveryTime'>{{'siko.days' | cxTranslate}}</span></div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>
