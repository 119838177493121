import { Pipe, PipeTransform } from '@angular/core';
import { sfGetIconAsset } from '@siko/shared/utils/siko-functions';

@Pipe({
    name: 'sikoIconAsset',
})
export class SikoIconAssetPipe implements PipeTransform {

    transform(relativePath: string): string {
        return sfGetIconAsset(relativePath);
    }

}
