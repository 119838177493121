import { NgModule } from '@angular/core';
import { BaseTmsModule, TmsConfig } from '@spartacus/tracking/tms/core';
import { GtmModule } from '@spartacus/tracking/tms/gtm';
import { provideConfigFactory } from '@spartacus/core';
import { SikoUtils } from '@siko/shared';
import { SikoPageEvent } from '../../events/SikoPageEvent';
import { SikoViewEvent } from '../../events/SikoViewEvent';
import { SikoModifyCartEvent } from '../../events/SikoModifyCartEvent';
import { SikoCheckoutEvent } from '../../events/SikoCheckoutEvent';
import { SikoPurchaseEvent } from '../../events/SikoPurchaseEvent';
import { SikoResetEvent } from '../../events/SikoResetEvent';
import { SikoCartSaveEvent } from '../../events/SikoCartSaveEvent';


@NgModule({
    declarations: [],
    imports: [
        BaseTmsModule.forRoot(),
        GtmModule,
    ],
    providers: [
        provideConfigFactory(() => ({
            tagManager: {
                gtm: {
                    gtmId: SikoUtils.getGtmId(),
                    events: [
                        SikoPageEvent,
                        SikoViewEvent,
                        SikoModifyCartEvent,
                        SikoCheckoutEvent,
                        SikoPurchaseEvent,
                        SikoResetEvent,
                        SikoCartSaveEvent
                    ],
                },
            },
        } as TmsConfig)),
    ],
})
export class SikoTrackingModule {}
