import { Component } from '@angular/core';
import { ProductIntroComponent } from '@spartacus/storefront';

@Component({
    selector: 'app-product-intro',
    templateUrl: './product-intro.component.html',
})
export class SikoProductIntroComponent extends ProductIntroComponent {

}
