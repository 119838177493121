<div class='row'>
  <div class='col-12'>
    <h2 id='opening-hours-title' *ngIf='showTitle'>{{'siko.openingHours.title' | cxTranslate}}</h2>
    <ng-container *ngIf='isCurrentlyOpen else storeIsClosed'>
      <div class='status'>
        {{'siko.openingHours.currentlyOpen' | cxTranslate}}
      </div>
    </ng-container>
    <ng-template #storeIsClosed>
      <div class='status closed'>
        {{'siko.openingHours.currentlyClosed' | cxTranslate}}
      </div>
    </ng-template>
    <div class='table-responsive'>
      <table aria-describedby='opening-hours-title' class='table table-sm table-borderless'>
        <ng-container *ngFor='let openingHour of openingHours?.weekDayOpeningList'>
          <tr [ngClass]='checkDay(openingHour, openingHours?.specialDayOpeningList)'
              (click)='onTabClick("day" + openingHour.weekDayIndex)'>
            <th scope='row' [ngClass]="openingHour.weekDayIndex === currentIndexOfDay ? 'actual-day' : ''">
              {{openingHour.sikoWeekDayName}}
              <span
                *ngIf='getSpecialDayMessage(openingHour, openingHours?.specialDayOpeningList).length !== 0 && specialDayMessage[0]'
                ngbTooltip
                role='button'
                tabindex='0'
                triggers='click:blur'
                placement='{{tooltipPosition}}'
                sikoTooltipText='{{ specialDayMessage[0] }}'
                class='tooltip-icon d-inline-block d-lg-none'
              >
                <img [src]='"plp/tooltip.svg" | sikoIconAsset' alt='tooltip icon'>
               </span>
              <span
                *ngIf='getSpecialDayMessage(openingHour, openingHours?.specialDayOpeningList).length !== 0 && specialDayMessage[0]'
                ngbTooltip
                role='button'
                tabindex='0'
                placement='{{tooltipPosition}}'
                sikoTooltipText='{{ specialDayMessage[0] }}'
                class='tooltip-icon d-none d-lg-inline-block'
              >
                <img [src]='"plp/tooltip.svg" | sikoIconAsset' alt='tooltip icon'>
               </span>
            </th>
            <td class='text-center' [ngClass]="openingHour.weekDayIndex === currentIndexOfDay ? 'actual-day' : ''">
              <ng-container *ngIf='openingHour.closed else opened'>
            <span class='red-text'>
              {{'siko.openingHours.closed' | cxTranslate}}
            </span>
              </ng-container>
              <ng-template #opened>
                {{openingHour.openingTime?.formattedHour}} - {{openingHour.closingTime?.formattedHour}}
              </ng-template>
            </td>
          </tr>
          <tr [ngClass]='isInOpenedDay("day"+openingHour.weekDayIndex) ? "" : "d-none"'
              *ngIf='getSpecialDayMessage(openingHour, openingHours?.specialDayOpeningList).length !== 0'
              class='special-day-note-row'>
            <th scope='row'>
              <div class='heading-special-day'>
                {{'siko.openingHours.forDay'| cxTranslate}} {{specialDayMessage[1] }}
              </div>
              <span>
                 {{specialDayMessage[4] ? specialDayMessage[4] : 'siko.openingHours.openingHoursChanged' | cxTranslate }}
              </span>
            </th>
            <td>
              <span class='red-text' *ngIf='specialDayMessage[2]'>{{'siko.openingHours.closed'| cxTranslate}}</span>
              <span class='special-opening-hours' *ngIf='!specialDayMessage[2]'>
                {{specialDayMessage[5] ? specialDayMessage[5] : openingHour.openingTime?.formattedHour}}
                -
                {{specialDayMessage[3] ? specialDayMessage[3] : openingHour.closingTime?.formattedHour}}
                </span>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</div>
