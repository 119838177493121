<app-dialog-header>
  <div class='row'>
    <div class='col-12'>
      <siko-text
        text='{{"siko.openingHours.title" | cxTranslate}}'
        fontSize='26'
        fontFamily='secondary'
        fontWeight='700'
        color='base-grey-3'
      ></siko-text>
    </div>
    <div class='col-12' *ngIf='pointOfServiceName'>
      <siko-text
        text='{{pointOfServiceName}}'
        fontSize='26'
        fontFamily='secondary'
        fontWeight='700'
        color='base-black'
      ></siko-text>
    </div>
  </div>
</app-dialog-header>
<div class='siko-dialog-body'>
  <app-opening-hours [openingHours]='openingHours' [showTitle]='false'></app-opening-hours>
  <div class='mt-32'>
    <siko-button
      [mode]='$any("secondary")'
      [block]='false'
      [text]='"common.back" | cxTranslate'
      (clickEvent)='dialogService.dismissAll()'>
    </siko-button>
  </div>
</div>
