import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable } from 'rxjs';
import { SikoB2BUser, SikoProductUnitData, SikoProduct, SikoProductDisponibility } from '@siko/models';
import { PRODUCT_SAP_STATUS } from '@siko/constants';
import { B2bCommonModule, SikoUtils } from '@siko/shared';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';
import dayjs from 'dayjs';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-disponibility',
    templateUrl: './product-disponibility.component.html',
    styleUrls: ['./product-disponibility.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoProductUnitComponent,
    ],
})
export class SikoProductDisponibilityComponent implements OnChanges {

    @Input() isStoreDisponibility?: boolean;
    @Input() displayShortVersion?: boolean = false;

    inputSikoProduct?: SikoProduct;
    saleEnded?: boolean;
    productDisponibility?: SikoProductDisponibility;
    roundedAmount?: number | string;
    unitData?: SikoProductUnitData;
    sapStatus?: string;
    user$: Observable<SikoB2BUser | undefined> = this.userProfileFacade.get();
    upcomingDate?: Date;

    @Input() set sikoProduct(input: SikoProduct) {
        this.inputSikoProduct = input;
        this.assignVariables();
    }

    constructor(protected userProfileFacade: UserProfileFacade) {}

    ngOnChanges(): void {
        if (this.inputSikoProduct) {
            this.assignVariables();
        }
    }

    assignVariables(): void {
        if (this.inputSikoProduct) {
            this.saleEnded = this.inputSikoProduct.sapStatus === PRODUCT_SAP_STATUS.SAP_STATUS_Z1;
            this.productDisponibility =
                this.isStoreDisponibility ?
                    this.inputSikoProduct.disponibility?.storeDisponibility : this.inputSikoProduct.disponibility;
            this.sapStatus = this.inputSikoProduct.sapStatus;
            this.unitData = this.inputSikoProduct.unitData;

            if (!this.isStoreDisponibility && this.inputSikoProduct.disponibility) {
                this.roundedAmount = SikoUtils.formatDisponibilityLevel(this.inputSikoProduct.disponibility.level, this.inputSikoProduct.unitData);
            }

            if (this.productDisponibility?.status.includes('upcoming')) {
                this.upcomingDate = dayjs()
                    .add(this.productDisponibility?.daysUntilDelivery, 'days')
                    .toDate();
            }
        }
    }

}
