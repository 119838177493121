<div class="row">
  <div class="col">
    <div class="banner-wrapper" [ngClass]="bannerWrapperClass">
      <div class="d-flex w-100 align-items-center">
        <div class="icon-wrapper p-3" [ngClass]="iconWrapperClass" >
        </div>
        <div class="text-wrapper">
          <div class='heading' *ngIf='heading'>
            <h4>{{heading}}</h4>
          </div>
          <ng-container *ngIf='isInnerHtml else nonInnerHtml'>
            <div [ngClass]='heading ? "with-heading" : ""' [innerHTML]='text'></div>
          </ng-container>
          <ng-template #nonInnerHtml >
            <div [ngClass]='heading ? "with-heading" : ""'>
              {{text}}
              <ng-content></ng-content>
            </div>
          </ng-template>
          <ng-container *ngIf="closable">
            <div
              (click)="onCloseButtonCLick($event)"
              class="close">
              <img [src]='"info-banner/close.svg" | sikoIconAsset' alt='Close icon'>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
