<input
  class='hide-input'
  type='file'
  aria-hidden='true'
  [accept]='accept'
  [multiple]='multiple'
  (change)='selectFile($event)'
  #fileInput
/>
<ng-container *ngIf='selectedFiles && selectedFiles.length == 0 else selectedFile'>
  <siko-button
    (click)='fileInput.click()'
    mode='secondary'
    size='md'
    [text]='"siko.selectCsvFile" | cxTranslate'
    iconPosition='before'
    iconSvg='importCart/file'
    [block]='true'>
  </siko-button>
</ng-container>
<ng-template #selectedFile>
  <div class='pb-2' *ngFor='let file of selectedFiles'>
    <img [src]='"importCart/done.svg" | sikoIconAsset' class='done' alt='Cart imported successfully icon'>
    <h4 class='d-inline'>
      {{ file?.name }}
    </h4>
    <span class='pl-4'>
      <siko-link
        [mode]='$any("default")'
        [style]='$any("primary")'
        [disableLink]=true
        (click)='fileInput.click()'
        label='{{"siko.importOtherFile" | cxTranslate}}'>
      </siko-link>
    </span>
  </div>
</ng-template>

