import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import { MediaComponent, MediaService } from '@spartacus/storefront';
import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';

@Component({
    selector: 'app-media',
    standalone: true,
    imports: [
        B2bCommonModule,
        LazyLoadImageModule,
    ],
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss'],
})
export class SikoMediaComponent extends MediaComponent {

    @Input() isSwiperZoomContainer = false;
    @ViewChild('imgRef', { static: false }) imgRef?: ElementRef;

    constructor(
        protected mediaService: MediaService,
        protected renderer: Renderer2,
    ) {
        super(mediaService);
    }

    getThumb(srcSet: string | undefined): string | undefined {
        if (srcSet) {
            const urls: string[] = srcSet.split(',');

            if (urls.length > 0) {
                return urls[0].trim();
            }
        }

        return undefined;
    }

    stateCallback(event: StateChange): void {
        switch (event.reason) {
            case 'setup':
                // The lib has been instantiated, but we have not done anything yet.
                break;
            case 'observer-emit':
                // The image observer (intersection/scroll/custom observer) has emitted a value, so we should check if the image is in the viewport.
                // `event.data` is the event in this case.
                break;
            case 'start-loading':
                this.renderer.addClass(this.imgRef?.nativeElement, 'img-background');
                break;
            case 'mount-image':
                // The image has been loaded successfully so lets put it into the DOM
                break;
            case 'loading-succeeded':
                // The image has successfully been loaded and placed into the DOM
                this.renderer.removeClass(this.imgRef?.nativeElement, 'img-background');
                break;
            case 'loading-failed':
                // The image could not be loaded for some reason.
                // `event.data` is the error in this case
                break;
            case 'finally':
                // The last event before cleaning up
                break;
        }
    }

}
