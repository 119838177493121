<ng-container *ngIf='sikoProduct$ | async as sikoProduct else loading'>
  <app-dialog-header>
    {{'siko.pdpProductDialog.title' | cxTranslate}}
  </app-dialog-header>
  <div class='siko-dialog-body product-detail-dialog'>
    <div class='row mb-16'>
      <div class='col-12 d-block d-md-none mb-16'>
        <h3 [tabIndex]='1' class='siko-fs-18 mb-16'>{{sikoProduct.name}}</h3>
        <app-product-rating
          [inputProduct]='sikoProduct'
        ></app-product-rating>
      </div>
      <div class='col-md-5 d-flex mb-16 mb-md-0 justify-content-center align-items-center img-container'>
        <div class='ml-16 labels'>
          <siko-label [labels]='sikoProduct.labels'></siko-label>
        </div>
        <app-media
          [container]='$any(sikoProduct?.images?.PRIMARY)'
        ></app-media>
      </div>
      <div class='col-md-7'>
        <div class='d-md-block d-none'>
          <app-product-rating
            [inputProduct]='sikoProduct'
          ></app-product-rating>
          <h3 class='mb-16'>{{sikoProduct.name}}</h3>
        </div>
        <app-product-header-description
          [inputProduct]='sikoProduct'
        ></app-product-header-description>
        <div class='row mt-32'>
          <div class='col'>
            <app-product-price [product]=sikoProduct></app-product-price>
          </div>
        </div>
        <div class='row mt-32'>
          <div class='col'>
            <app-product-availability-info [product]='sikoProduct' [openedFromPLP]='true'></app-product-availability-info>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf='showAddToCartButtons; else saleEnded' class="d-flex flex-column flex-md-row mb-4 p-2">
      <div class="item-counter">
        <app-pdp-item-counter
          [product]='sikoProduct'
          [control]="$any(addToCartForm).get('quantity')"
          [isLimited]='true'
          counterSize='lg'
        ></app-pdp-item-counter>
      </div>
      <div class='mb-3 flex-grow-1'>
        <app-product-add-to-cart-button
          textTop="{{'addToCart.addToCart' | cxTranslate}}"
          [sikoProduct]='sikoProduct'
          (clickEvent)='addToCart(sikoProduct, false)'
        >
        </app-product-add-to-cart-button>
      </div>
    </div>
    <ng-template #saleEnded>
      <ng-container *ngIf='sikoProduct.sapStatus === "Z1"; else outOfStock'>
        <app-info-banner-component mode='danger'>
          <div>
            {{'siko.saleEnded' | cxTranslate}}
          </div>
        </app-info-banner-component>
      </ng-container>
      <ng-template #outOfStock>
        <app-info-banner-component
          text="{{'siko.outOfStock' | cxTranslate}}"
          mode='danger'
        ></app-info-banner-component>
      </ng-template>
    </ng-template>
  </div>
</ng-container>
<ng-template #loading>
  <app-dialog-header [loading]='true'>
  </app-dialog-header>
  <div class='siko-dialog-body loading'>
    <app-detail-loading-skeleton></app-detail-loading-skeleton>
  </div>
</ng-template>
