import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SikoPromotionOrderEntryConsumed } from '@siko/models';

@Injectable({
    providedIn: 'root',
})
export class SikoCartPromotionsService {

    readonly promotionOrderEntriesConsumedSubject = new BehaviorSubject<SikoPromotionOrderEntryConsumed[]>([]);
    //TODO Delivery for free promotion cannot be displayed when the delivery mode is pickupInStore -> remove if not needed.
    readonly isPickupInStoreSubject = new BehaviorSubject<boolean>(false);

    set promotionOrderEntries(promotionOrderEntries: SikoPromotionOrderEntryConsumed[]) {
        this.promotionOrderEntriesConsumedSubject.next(promotionOrderEntries);
    }

    set isPickupInStore(isBopis: boolean) {
        this.isPickupInStoreSubject.next(isBopis);
    }

    get isPickupInStore$(): Observable<boolean> {
        return this.isPickupInStoreSubject.asObservable();
    }

    getPromotionOrderEntriesConsumed(index: number): SikoPromotionOrderEntryConsumed | undefined {
        const promotionOrderEntriesConsumed: SikoPromotionOrderEntryConsumed[] = this.promotionOrderEntriesConsumedSubject.getValue();

        return promotionOrderEntriesConsumed.find((orderEntry: SikoPromotionOrderEntryConsumed) => orderEntry.orderEntryNumber === index);
    }
}
