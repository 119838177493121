<app-dialog-header>
  <div
    [innerHTML]='"actionsWithCartDialog."+currentPage+".header" | cxTranslate : {count: countOfCartModifications}'></div>
</app-dialog-header>
<div class='siko-dialog-body mt-3'>
  <ng-container *ngIf='currentPage.includes("cart")'>
    <div class='siko-fs-14'
         [innerHTML]='("actionsWithCartDialog.cart.listOfProducts" | cxTranslate : {products: invalidProducts.join(", "), count: countOfCartModifications}) + ("actionsWithCartDialog.cart."+ typeOfDelivery | cxTranslate) + pickupStore'>
    </div>
    <div class='siko-fs-14 mb-5 mt-2' [innerHTML]='"actionsWithCartDialog.cart.options" | cxTranslate : {count: countOfCartModifications}'>
    </div>
  </ng-container>
  <div class='d-flex flex-column flex-sm-row align-items-center justify-content-between saved-cart-form'>
    <div class='name-saved-cart w-100 mb-2'>
      <siko-input-field
        name='savedCartName'
        [maxlength]='50'
        placeholder='{{actualDate | cxDate : "d.M.Y H:mm"}}'
        label="{{ 'actionsWithCartDialog.nameOfSavedCart' | cxTranslate }}"
        [control]='$any(savedCartForm.controls?.savedCartName)'
      >
      </siko-input-field>
    </div>
    <div class='saved-cart w-100 ml-0 ml-sm-3 mt-16'>
      <siko-button
        (clickEvent)='saveCartAndContinue()'
        [text]='"actionsWithCartDialog."+currentPage+".saveCartAndContinue" | cxTranslate'
        size='md'
        iconPosition='after'
        iconSvg='checkout/arrow_white'
        mode='cart'
        [block]=true
      >
      </siko-button>
    </div>
  </div>
  <div class='mt-5 mt-sm-2'>
    <siko-button
      (clickEvent)='removeCartAndContinue()'
      [text]='removeBtnText | cxTranslate'
      size='md'
      iconPosition='after'
      iconSvg='cart/arrow-next-black'
      mode='light'
    >
    </siko-button>
  </div>
</div>

