<div class='row pb-5'>
  <ng-container *ngIf='{observableValue: checkoutService.getFinishingOrder() | async} as finishingOrder'>
    <ng-container *ngIf='finishingOrder.observableValue'>
      <app-duck-loader [transparentBackground]='true' [fixed]='true'></app-duck-loader>
    </ng-container>
  </ng-container>
  <div class='col-lg-7'>
    <div class='checkout-body-content'>
      <cx-page-slot position='BodyContent'></cx-page-slot>
    </div>
  </div>
  <div class='col-lg-5'>
    <cx-page-slot position='SideContent'></cx-page-slot>
  </div>
</div>
