import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import { SikoRemoveCartEntryComponent } from './remove-cart-entry.component';
import { SikoDialogComponentsModule } from '@siko/features/shared-components/dialog-components/dialog-components.module';


@NgModule({
    declarations: [
        SikoRemoveCartEntryComponent,
    ],
    imports: [
        B2bCommonModule,
        SikoDialogComponentsModule,
    ],
})
export class SikoRemoveCartEntryModule {
}
