import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-average-rating',
    templateUrl: './product-average-rating.component.html',
    styleUrls: ['./product-average-rating.component.scss']
})
export class SikoProductAverageRatingComponent implements OnInit {
  @Input() averageAmount?: number;
  displayAverageAmount?: string;

  ngOnInit() {
      this.constructRating();
  }

  constructRating(): void{
      if (this.averageAmount && this.averageAmount % 1 != 0) {
          const averageAmountString = this.averageAmount.toFixed(2).toString();
          const wholeNumber = averageAmountString.substring(0, averageAmountString.indexOf('.'));
          const decimal = averageAmountString.split('.').pop();

          this.displayAverageAmount = `${wholeNumber},${decimal}`;
      }
      else {
          this.displayAverageAmount = this.averageAmount?.toFixed(2).toString();
      }
  }
}
