<div class='product-list-item' [routerLink]='product | sikoProductUrl'>
  <div class='image-block'>
    <a
      [attr.aria-label]="
        !product.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: product.name })
          : undefined
      "
      class='cx-product-image-container'
    >
      <div *ngIf='searchBoxResult' class='text-center product-image' [ngClass]='{"d-flex": product.images?.PRIMARY}'>
        <cx-media class='img-responsive'
                  [container]='$any(product.images?.PRIMARY)'
                  format='product'
                  [alt]='product.description ?? ""'
        ></cx-media>
      </div>
    </a>
  </div>
  <div class='summary-block'>
    <a [title]='product.name' [routerLink]='product | sikoProductUrl' class='cx-product-name'>
      <span class='cx-product-order'>{{order}}.</span>{{product.name}}
    </a>
    <ng-container *ngIf='searchBoxResult'>
      <siko-label [labels]='product.labels' [showShortVersion]='true' [maxLabels]='3'></siko-label>
    </ng-container>
    <div class='cx-product-summary' *ngIf='product.summary && !searchBoxResult'>
      {{product.summary.substr(0, 90)}}...
    </div>
    <div [ngClass]='getPriceBlockClasses("mobile-price-block")'>
      <app-price [price]='hasDiscountPrice ? (product.discountPrice | sikoPrice) : (product.basePrice | sikoPrice)'
                 [MOPrice]='true'>
      </app-price>
    </div>
    <div class='mobile-price-block'>
      <app-product-group [sikoProduct]='product'></app-product-group>
    </div>
  </div>
  <div class='price-block d-none d-md-flex align-items-end flex-column'>
    <div
      [ngClass]='getPriceBlockClasses("cx-product-price")'
      [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
    >
      <app-price [price]='hasDiscountPrice ? (product.discountPrice | sikoPrice) : (product.basePrice | sikoPrice)'
                 [MOPrice]='true'>
      </app-price>
    </div>
    <div *ngIf='searchBoxResult' class='bonus-group'>
      <app-product-group [sikoProduct]='product'></app-product-group>
    </div>
  </div>
</div>
