<ng-container *ngIf='breakpointObserver.breakpointChange$ | async'>
  <ng-container *ngIf='breakpointObserver.isBreakpointActive(["xs"]) else tabletOrDesktop'>
    <svg width='100%' height='100%' viewBox='0 0 312 1036' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='85' height='16' rx='8' fill='#F0F4F4' />
      <rect x='97' width='85' height='16' rx='8' fill='#F0F4F4' />
      <rect x='194' width='85' height='16' rx='8' fill='#F0F4F4' />
      <rect y='48' width='312' height='32' rx='8' fill='#F0F4F4' />
      <rect y='112' width='312' height='312' rx='16' fill='#F0F4F4' />
      <rect y='472' width='16' height='48' rx='8' transform='rotate(-90 0 472)' fill='#F0F4F4' />
      <rect x='60' y='456' width='16' height='16' rx='8' fill='#F0F4F4' />
      <rect x='88' y='456' width='16' height='16' rx='8' fill='#F0F4F4' />
      <rect x='116' y='456' width='16' height='16' rx='8' fill='#F0F4F4' />
      <rect x='144' y='456' width='16' height='16' rx='8' fill='#F0F4F4' />
      <rect x='172' y='456' width='16' height='16' rx='8' fill='#F0F4F4' />
      <rect x='200' y='456' width='16' height='16' rx='8' fill='#F0F4F4' />
      <rect y='504' width='312' height='16' rx='8' fill='#F0F4F4' />
      <rect y='528' width='312' height='16' rx='8' fill='#F0F4F4' />
      <rect y='552' width='207' height='16' rx='8' fill='#F0F4F4' />
      <rect y='600' width='98.6667' height='16' rx='8' fill='#F0F4F4' />
      <rect x='106.667' y='600' width='98.6667' height='16' rx='8' fill='#F0F4F4' />
      <rect x='213.333' y='600' width='98.6667' height='16' rx='8' fill='#F0F4F4' />
      <rect y='624' width='186' height='32' rx='8' fill='#F0F4F4' />
      <rect y='664' width='95' height='16' rx='8' fill='#F0F4F4' />
      <rect y='712' width='312' height='48' rx='8' fill='#F0F4F4' />
      <rect y='776' width='312' height='48' rx='8' fill='#F0F4F4' />
      <rect y='840' width='312' height='48' rx='8' fill='#F0F4F4' />
      <rect y='920' width='312' height='2' rx='1' fill='#F0F4F4' />
      <rect y='938' width='247' height='32' rx='8' fill='#F0F4F4' />
      <rect x='280' y='938' width='32' height='32' rx='8' fill='#F0F4F4' />
      <rect y='986' width='312' height='2' rx='1' fill='#F0F4F4' />
      <rect y='1004' width='247' height='32' rx='8' fill='#F0F4F4' />
      <rect x='280' y='1004' width='32' height='32' rx='8' fill='#F0F4F4' />
    </svg>
  </ng-container>
  <ng-template #tabletOrDesktop>
    <ng-container *ngIf='breakpointObserver.isBreakpointActive(["sm", "md"]) else desktop'>
      <svg width='100%' height='100%' viewBox='0 0 730 680' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='349' height='348' rx='16' fill='#F0F4F4' />
        <rect x='381' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='409' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='437' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='465' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='493' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='381' y='48' width='349' height='48' rx='8' fill='#F0F4F4' />
        <rect x='381' y='104' width='291' height='48' rx='8' fill='#F0F4F4' />
        <rect x='381' y='184' width='349' height='16' rx='8' fill='#F0F4F4' />
        <rect x='381' y='208' width='349' height='16' rx='8' fill='#F0F4F4' />
        <rect x='381' y='232' width='349' height='16' rx='8' fill='#F0F4F4' />
        <rect x='381' y='256' width='291' height='16' rx='8' fill='#F0F4F4' />
        <rect x='381' y='304' width='158.5' height='16' rx='8' fill='#F0F4F4' />
        <rect x='381' y='328' width='186' height='32' rx='8' fill='#F0F4F4' />
        <rect x='381' y='368' width='186' height='16' rx='8' fill='#F0F4F4' />
        <rect x='571.5' y='304' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='571.5' y='328' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='571.5' y='352' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='381' y='416' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='381' y='440' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='381' y='464' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect y='520' width='349' height='64' rx='8' fill='#F0F4F4' />
        <rect x='381' y='520' width='349' height='64' rx='8' fill='#F0F4F4' />
        <rect y='616' width='349' height='64' rx='8' fill='#F0F4F4' />
        <rect y='2' width='228.333' height='64' rx='8' fill='#F0F4F4' />
      </svg>
    </ng-container>
    <ng-template #desktop>
      <svg width='100%' height='100%' viewBox='0 0 1338 742' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='591' height='590' rx='16' fill='#F0F4F4' />
        <rect x='623' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='651' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='679' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='707' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='735' width='16' height='16' rx='8' fill='#F0F4F4' />
        <rect x='623' y='48' width='715' height='48' rx='8' fill='#F0F4F4' />
        <rect x='623' y='104' width='291' height='48' rx='8' fill='#F0F4F4' />
        <rect x='623' y='184' width='715' height='16' rx='8' fill='#F0F4F4' />
        <rect x='623' y='208' width='715' height='16' rx='8' fill='#F0F4F4' />
        <rect x='623' y='232' width='715' height='16' rx='8' fill='#F0F4F4' />
        <rect x='623' y='256' width='291' height='16' rx='8' fill='#F0F4F4' />
        <rect x='623' y='304' width='341.5' height='16' rx='8' fill='#F0F4F4' />
        <rect x='623' y='328' width='186' height='32' rx='8' fill='#F0F4F4' />
        <rect x='623' y='368' width='186' height='16' rx='8' fill='#F0F4F4' />
        <rect x='996.5' y='304' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='996.5' y='328' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='996.5' y='352' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='623' y='416' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='623' y='440' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect x='623' y='464' width='137' height='16' rx='8' fill='#F0F4F4' />
        <rect y='614' width='430' height='64' rx='8' fill='#F0F4F4' />
        <rect x='454' y='614' width='430' height='64' rx='8' fill='#F0F4F4' />
        <rect x='908' y='614' width='430' height='64' rx='8' fill='#F0F4F4' />
      </svg>
    </ng-template>
  </ng-template>
</ng-container>
