<ng-container *ngIf='breakpointObserver.breakpointChange$ | async'>
  <ng-container *ngIf='isLoading else loaded'>
    <app-dialog-header
      [loading]='true'
      [title]="'addedToCartDialog.updatingCart' | cxTranslate"></app-dialog-header>
    <!-- Modal Body -->
    <div class='siko-dialog-body loading'>
      <app-added-to-cart-skeleton></app-added-to-cart-skeleton>
    </div>
  </ng-container>
  <ng-template #loaded>
    <app-dialog-header>
      <ng-container *ngIf='
             cartModification?.quantityAdded && httpError === undefined && addToCartStatus !== ADD_TO_CART_ENTRY_STATUS_ENUM.NO_STOCK; else itemNotAddedTitle'>
        {{'addedToCartDialog.addedToShoppingCart' | cxTranslate}}      </ng-container>
      <ng-template #itemNotAddedTitle>
        <ng-container *ngIf='cartDeleted; else notDeletedCart'>
          {{'siko.removedCart' | cxTranslate}}
        </ng-container>
        <ng-template #notDeletedCart>
          {{'addedToCartDialog.notAddedTitle' | cxTranslate}}
        </ng-template>
      </ng-template>
    </app-dialog-header>
    <!-- Modal Body -->
    <div class='siko-dialog-body'>
      <ng-container *ngIf='addToCartStatus === ADD_TO_CART_ENTRY_STATUS_ENUM.LOW_STOCK'>
        <div class='py-32' *ngIf='cartModification?.entry?.product?.unitData as unitData'>
          <app-info-banner-component mode='warning'
                                     [isInnerHtml]='true'
                                     [text]='"cartShared.addedToCartWithReducedAmount" | cxTranslate : {
            product: cartModification?.entry?.product?.name,
            quantity: calculateAddedQuantity(unitData, cartModification?.quantity),
            addedQuantity: calculateAddedQuantity(unitData, cartModification?.quantityAdded),
            unit: formatUnitSymbol(unitData)}
            '></app-info-banner-component>
        </div>
      </ng-container>
      <ng-container *ngIf='addToCartStatus === ADD_TO_CART_ENTRY_STATUS_ENUM.CANNOT_COMBINE_DIFFERENT_BONUS_POINTS'>
        <div class='py-32'>
          <app-info-banner-component
            [text]="'addedToCartDialog.cannotCombineDifferentBonusPoints' | cxTranslate"
            mode='danger'
          ></app-info-banner-component>
        </div>
      </ng-container>
      <ng-container *ngIf='addToCartStatus === ADD_TO_CART_ENTRY_STATUS_ENUM.CANNOT_COMBINE_BP_WITH_REGULAR'>
        <div class='py-32'>
          <app-info-banner-component
            [text]="'addedToCartDialog.cannotCombineBonusPointsWithRegular' | cxTranslate"
            mode='danger'
          ></app-info-banner-component>
        </div>
      </ng-container>
      <ng-container *ngIf='addToCartStatus === ADD_TO_CART_ENTRY_STATUS_ENUM.INSUFFICIENT_BONUS_PROGRAM_POINTS'>
        <div class='py-32'>
          <app-info-banner-component
            [text]="'addedToCartDialog.insufficientBonusProgramPoints' | cxTranslate"
            mode='danger'
          ></app-info-banner-component>
        </div>
      </ng-container>
      <ng-container *ngIf='addToCartStatus === ADD_TO_CART_ENTRY_STATUS_ENUM.LOW_BONUS_PROGRAM_POINTS'>
        <div class='py-32' *ngIf='cartModification?.entry?.product?.unitData as unitData'>
          <app-info-banner-component mode='warning'
                                     [isInnerHtml]='true'
                                     [text]='"cartShared.lowBonusProgramPoints" | cxTranslate : {
            product: cartModification?.entry?.product?.name,
            quantity: calculateAddedQuantity(unitData, cartModification?.quantity),
            addedQuantity: calculateAddedQuantity(unitData, cartModification?.quantityAdded),
            unit: formatUnitSymbol(unitData)}
            '></app-info-banner-component>
        </div>
      </ng-container>
      <ng-container *ngIf='addToCartStatus === ADD_TO_CART_ENTRY_STATUS_ENUM.NO_STOCK'>
        <div class='py-32'>
          <app-info-banner-component
            [text]="'addedToCartDialog.notAddedInsufficientStockMessage' | cxTranslate"
            mode='warning'
          ></app-info-banner-component>
        </div>
      </ng-container>
      <ng-container *ngIf='httpError === HTTP_ERROR_ENUM.ADD_TO_CART_DELIVERY_RESTRICTION'>
        <div class='py-32'>
          {{ 'siko.notAddedDeliveryRestrictionMessage' | cxTranslate }}
        </div>
      </ng-container>
      <ng-container *ngIf='httpError === HTTP_ERROR_ENUM.ADD_TO_CART_PICKUP_TO_DIFFERENT'>
        <div class='py-32'>
          {{'addedToCartDialog.notAddedPickupToDifferentStoresMessage' | cxTranslate}}
        </div>
      </ng-container>
      <ng-container *ngIf='httpError === CART_ERROR'>
        <div class='py-32'>
          {{'httpHandlers.cartNotFound' | cxTranslate}}
        </div>
      </ng-container>
      <ng-container *ngIf='sikoOrderEntry$ | async as entry'>
        <ng-container *ngIf='addToCartStatus === ADD_TO_CART_ENTRY_STATUS_ENUM.SUCCESS && httpError === undefined'>
          <app-added-to-cart-dialog-item
            [orderEntry]='entry'
            [cartModification]='cartModification'
          ></app-added-to-cart-dialog-item>
          <div class='d-flex flex-column-reverse flex-sm-column'>
            <app-added-to-cart-dialog-references-carousel
              [productCode]='entry.product?.code'
              class='product-reference-price'
            ></app-added-to-cart-dialog-references-carousel>
            <ng-container *ngTemplateOutlet='actionButtons; context:{httpError: undefined}'></ng-container>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf='httpError'>
        <ng-container *ngTemplateOutlet='actionButtons; context:{httpError: httpError}'></ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-template let-httpError='httpError' #actionButtons>
  <div class='row reduced-gutters flex-column-reverse flex-md-row fixed-buttons'>
    <!-- Actions -->
    <div class='col col-md-4' [ngClass]='httpError ? "col-md-5" : "col-md-4"'>
      <siko-button
        testId='back-to-store-button'
        mode='secondary'
        [size]='breakpointObserver.isBreakpointActive(["xs", "sm"]) ? "sm" : "lg"'
        [text]='"siko.backToStore"|cxTranslate'
        cxModal='dismiss'
        cxModalReason='Closing dialog'
      >
      </siko-button>
    </div>
    <ng-container *ngIf='httpError === undefined else deleteCartButton'>
      <div class='col col-md-8 mb-16 mb-md-0'>
        <siko-button
          testId='go-to-cart-button'
          mode='cart'
          [text]='"siko.goToCart" | cxTranslate'
          [routerLink]='{cxRoute: "cart"} | cxUrl'
          cxModal='dismiss'
          cxModalReason='Proceed To Checkout click'
        ></siko-button>
      </div>
    </ng-container>
    <ng-template #deleteCartButton>
      <div class='col col-md-5 mb-16 mb-md-0'>
        <siko-button
          *ngIf='httpError !== CART_ERROR'
          testId='delete-cart-button'
          iconCode='delete_forever'
          iconPosition='before'
          [text]='"siko.deleteCart" | cxTranslate'
          (clickEvent)='deleteCart()'
        ></siko-button>
        <siko-button
          *ngIf='httpError === CART_ERROR'
          mode='secondary'
          testId='delete-cart-button'
          iconSvg='reload-page'
          iconPosition='before'
          [text]='"siko.reloadPage" | cxTranslate'
          (clickEvent)='reloadPage()'
        ></siko-button>
      </div>
    </ng-template>
  </div>
</ng-template>
