<div class='promo-banner' *ngIf='data !== undefined'>
  <div class='background'>
    <app-media [container]='data.media' class='promo-image'></app-media>
    <div class='content'>
      <div class='main-title-wrapper'>
        <a [routerLink]='data.url'>
          <div class='main-title' [innerHTML]='data.title'></div>
        </a>
        <img class='icon' [src]='"header/arrow.svg" | sikoIconAsset' alt='Arrow icon'>
      </div>
      <div class='description' *ngIf='data.text' [innerHTML]='data.text'></div>
      <button class='btn-full-offer'><a [routerLink]='data.url'>{{"siko.fullOffer"|cxTranslate}}</a>
      </button>
    </div>
  </div>
</div>
