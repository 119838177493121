import { Injectable } from '@angular/core';
import { SikoUtils } from '@siko/shared';

@Injectable({
    providedIn: 'root',
})
export class SikoProductDisponibilityService {

    calculateDisplayValue(controlValue: number, unitConversion: number): string {
        return SikoUtils.sikoRound(controlValue * unitConversion, 3)
            .toString();
    }

    calculateMaxField(productStockLevel: number, productConversionRate: number): number {
        return Math.ceil(productStockLevel / productConversionRate);
    }

    getActualQuantity(value: string, unitConversion: number): number {
        value = value.replace(',','.');
        const quantity = Math.ceil(Number(value) / unitConversion);

        return this.areFloatNumbersSimilar(quantity, (Number(value) + unitConversion) / unitConversion) ? quantity - 1 : quantity;
    }

    areFloatNumbersSimilar(number1: number, number2: number): boolean {
        return Math.abs(number1 - number2) < 0.0001;
    }
}
