import { AuthConfig, SiteContextConfig } from '@spartacus/core';
import { BaseSites } from '@siko/models';
import * as Sentry from "@sentry/angular-ivy";

export const VERSION = 'v34.0';

export const B2B_CZ = {
    context: {
        currency: ['CZK'],
        language: ['cs', 'en', 'sk'],
        baseSite: [BaseSites.B2B_SPA_CZ],
    },
} as SiteContextConfig;

export const B2B_SK = {
    context: {
        currency: ['EUR'],
        language: ['sk', 'en', 'cs'],
        baseSite: [BaseSites.B2B_SPA_SK],
    },
} as SiteContextConfig;

export const LOCAL_URLS = {
    backend: {
        occ: {
            baseUrl: 'https://localhost:9002',
        },
    },
};

export function getProdUrls(tld: string): SiteContextConfig {
    return {
        backend: {
            occ: {
                baseUrl: `https://api.siko.${tld}`,
            },
            media: {
                baseUrl: `https://media.siko.${tld}`,
            },
        },
    } as SiteContextConfig;
}

export function getCloudUrls(envCode: string, tld: string): SiteContextConfig {
    return {
        backend: {
            occ: {
                baseUrl: `https://api.${envCode}.siko.${tld}`,
            },
        },
    } as SiteContextConfig;
}

export const OAUTH_CLIENT_ID = 'siko_b2b_client';
export const OAUTH_CLIENT_ASM_ID = 'siko_b2b_asm_client';
export const OAUTH_SECRET_STAGE = 'l89wsvatkxnysf4i';
export const OAUTH_SECRET_ASM_STAGE = 'ybOKjISAtK2DBp89';
export const OAUTH_SECRET_PROD = 'hs7qtfmmm3ez63m9';
export const OAUTH_SECRET_ASM_PROD = 'Qt1KtWdBNDA1sFdb';

export const OAUTH_CONFIG = {
    authentication: {
        client_id: OAUTH_CLIENT_ID,
        client_secret: OAUTH_SECRET_STAGE,
    },
} as AuthConfig;

export const OAUTH_ASM_CONFIG = {
    authentication: {
        client_id: OAUTH_CLIENT_ASM_ID,
        client_secret: OAUTH_SECRET_ASM_STAGE,
    },
} as AuthConfig;

export const OAUTH_CONFIG_PROD = {
    authentication: {
        client_id: OAUTH_CLIENT_ID,
        client_secret: OAUTH_SECRET_PROD,
    },
} as AuthConfig;

export const OAUTH_CONFIG_ASM_PROD = {
    authentication: {
        client_id: OAUTH_CLIENT_ASM_ID,
        client_secret: OAUTH_SECRET_ASM_PROD,
    },
} as AuthConfig;

export const GTM_PROD = 'GTM-MS6STD7';
export const GTM_STAGE = 'GTM-PZ82R7C';
export const GOOGLE_API_KEY_PROD = 'AIzaSyAcak1S9YOYXL8Q_lFrClgjFU6wZZs5V4Q';
export const GOOGLE_API_KEY_STAGE = 'AIzaSyDLc0GcjRTPse3GBUi7Y0lLKDn3Nmv2VAc';

export const SENTRY_DSN = 'https://01b331b9168940fc88d81f58095ca975@sentry.cassoviacode.com/32';
export const _SENTRY_CONF = {
    dsn: SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
    ],
    tracePropagationTargets: [
        'api.siko.cz/occ/v2',
        'api.siko.sk/occ/v2',
        'api.s1.siko.cz/occ/v2',
        'api.s1.siko.sk/occ/v2',
        'api.d1.siko.cz/occ/v2',
        'api.d1.siko.sk/occ/v2',
        'api.d2.siko.cz/occ/v2',
        'api.d2.siko.sk/occ/v2',
        'api.d3.siko.cz/occ/v2',
        'api.d3.siko.sk/occ/v2',
        'api.ck7dj7qam-sikokoupe1-p1-public.model-t.cc.commerce.ondemand.com/occ/v2',
        'api.ck7dj7qam-sikokoupe1-s1-public.model-t.cc.commerce.ondemand.com/occ/v2',
        'api.ck7dj7qam-sikokoupe1-d1-public.model-t.cc.commerce.ondemand.com/occ/v2',
        'api.ck7dj7qam-sikokoupe1-d2-public.model-t.cc.commerce.ondemand.com/occ/v2',
        'api.ck7dj7qam-sikokoupe1-d3-public.model-t.cc.commerce.ondemand.com/occ/v2',
    ],
    tracesSampleRate: 1,
    release: VERSION,
} as Sentry.BrowserOptions;

export const SENTRY_CONF_PROD = {
    ..._SENTRY_CONF,
    environment: 'P1',
};
export const SENTRY_CONF_STAGE = {
    ..._SENTRY_CONF,
    environment: 'S1',
};
export const SENTRY_CONF_DEV1 = {
    ..._SENTRY_CONF,
    environment: 'D1',
};
export const SENTRY_CONF_DEV2 = {
    ..._SENTRY_CONF,
    environment: 'D2',
};
export const SENTRY_CONF_DEV3 = {
    ..._SENTRY_CONF,
    environment: 'D3',
};
