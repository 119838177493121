import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import { SikoProductSellUnitsComponent } from '@siko/features/shared-components/product-sell-units/product-sell-units.component';
import { SikoConvertUnitConversionPipe } from '@siko/features/shared-components/product-sell-units/pipes/siko-convert-unit-conversion.pipe';
import { SikoProductWeightPipe } from '@siko/features/shared-components/product-sell-units/pipes/product-weight.pipe';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';

@NgModule({
    declarations: [
        SikoProductSellUnitsComponent,
        SikoConvertUnitConversionPipe,
        SikoProductWeightPipe
    ],
    imports: [
        B2bCommonModule,
        SikoProductUnitComponent,
    ],
    exports: [
        SikoProductSellUnitsComponent,
    ],
})
export class SikoProductSellUnitsModule {}
