import { Pipe, PipeTransform } from '@angular/core';
import { Product, UrlPipe } from '@spartacus/core';
import { SikoSlugPipe } from '@siko/shared/pipes/slug.pipe';

@Pipe({
    name: 'sikoProductUrl',
    standalone: true
})
export class SikoProductUrlPipe implements PipeTransform {

    constructor(private readonly urlPipe: UrlPipe, private readonly slugPipe: SikoSlugPipe) { }

    transform(product: Product | undefined): any[] {
        let params = {};

        if (product?.name) {
            params = {
                code: product.code,
                name: this.slugPipe.transform(product.name),
            };
        }
        else {
            params = {
                code: product?.code,
            };
        }

        return this.urlPipe.transform({
            cxRoute: 'product',
            params,
        });
    }

}
