import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MessageComponent } from '@spartacus/storefront';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class SikoMessageComponent extends MessageComponent {
}
