import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-dialog-header-skeleton-icon',
    templateUrl: './dialog-header-skeleton-icon.component.html',
    styleUrls: ['./dialog-header-skeleton-icon.component.scss'],
})
export class SikoDialogHeaderSkeletonIconComponent {

}
