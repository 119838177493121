import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SikoProduct, SikoProductDisponibility, SikoUser } from '@siko/models';
import { B2bCommonModule } from '@siko/shared';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';
import { SikoVectorIconComponent } from '@siko/features/shared-components/icons/vector-icon/vector-icon.component';
import { BehaviorSubject, config } from 'rxjs';
import { SikoLabelIconComponent } from '@siko/features/shared-components/icons/label-icon/label-icon.component';
import { SikoCartIconComponent } from '@siko/features/shared-components/icons/cart-icon/cart-icon.component';
import { SikoBonusProgramGroupIconComponent } from '@siko/features/shared-components/icons/bonus-program-group-icon/bonus-program-group-icon.component';
import { UserProfileFacade } from '@spartacus/user/profile/root';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-add-to-cart-bonus-button',
    templateUrl: './product-add-to-cart-bonus-button.component.html',
    styleUrls: ['./product-add-to-cart-bonus-button.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoProductUnitComponent,
        NgbTooltipModule,
        SikoVectorIconComponent,
        SikoLabelIconComponent,
        SikoCartIconComponent,
        SikoBonusProgramGroupIconComponent,
    ],
})
export class SikoProductAddToCartBonusButtonComponent implements OnInit {

    currentPoints: number = 0;

    constructor(
        readonly userProfile: UserProfileFacade,
    ) {}

    ngOnInit(): void {
        this.userProfile.get()
            .subscribe(data => {
                const sikoUser: SikoUser | undefined = data;
                if (sikoUser) {
                    this.currentPoints = sikoUser?.sikoBonusPoints != undefined ? sikoUser.sikoBonusPoints : 0;
                }
            });
    }

    @Input() textTop?: string;
    isShowingBonusPointOptions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    @Input() set sikoProduct(input: SikoProduct) {
        this.inputProduct = input;
    }

    @Output() readonly points = new EventEmitter<number>();

    inputProduct?: SikoProduct;
    productDisponibility?: SikoProductDisponibility;


    // TODO Replace for Angular pipe
    get buttonWrapperClassList(): string[] {
        return [
            'button-wrapper',
        ];
    }

    onClick(points: number | undefined, $event: Event): void {
        if (points && this.currentPoints < points) {
            return;
        }

        this.points.emit(points);
        $event.preventDefault();
    }

    isConfigActive(points: number | undefined): boolean {
        return points != undefined && this.currentPoints >= points;
    }

    protected readonly config = config;
}
