import { ChangeDetectionStrategy, Component, Inject, Renderer2 } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { SikoProduct } from '@siko/models';
import { takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { ProductActions } from '@spartacus/core';
import { Action } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { SikoAutoUnsubscribe } from '@siko/common';

export type ProductMobileTabMode = 'DOCUMENTS' | 'INFORMATION' | 'PARAMETERS' | 'REVIEWS' | 'UNDEFINED';

@SikoAutoUnsubscribe(['productActionSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-tabs-bottom-sheet',
    templateUrl: './product-tabs-bottom-sheet.component.html',
    styleUrls: ['./product-tabs-bottom-sheet.component.scss'],
})
export class SikoProductTabsBottomSheetComponent {

    destroyed$ = new Subject<void>();
    showThankYouMessage = false;
    bottomSheetElement: Element | null = null;
    productActionSubscription?: Subscription;

    constructor(
        private readonly bottomSheet: MatBottomSheet,
        private readonly actions: Actions,
        protected renderer: Renderer2,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: { product: SikoProduct; mode: ProductMobileTabMode },
    ) {
        this.bottomSheetElement = document.querySelector('.mat-bottom-sheet-container');

        this.productActionSubscription = this.actions.pipe(
            takeUntil(this.destroyed$),
            ofType(
                ProductActions.POST_PRODUCT_REVIEW_SUCCESS,
                ProductActions.POST_PRODUCT_REVIEW_FAIL,
            ),
        )
            .subscribe((action: Action) => {
                if (action.type === ProductActions.POST_PRODUCT_REVIEW_SUCCESS) {
                    this.showThankYouMessage = true;

                    this.updateBottomSheetHeight();
                }
            });
    }

    updateBottomSheetHeight(): void {
        if (this.bottomSheetElement) {
            this.renderer.addClass(this.bottomSheetElement, 'small-sheet-container');
        }
    }

    closeTemplateSheetMenu(): void {
        this.bottomSheet.dismiss();
    }

}
