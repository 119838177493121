import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { SikoOrder } from '@siko/models';
import { OrderDetailsService } from '@spartacus/order/components';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-order-confirmation-overview',
    templateUrl: './order-confirmation-overview.component.html',
})
export class SikoOrderConfirmationOverviewComponent {

    sikoOrder$: Observable<SikoOrder> = this.orderDetailsService.getOrderDetails();

    constructor(
        protected orderDetailsService: OrderDetailsService,
    ) {
    }

}
