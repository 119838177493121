import { Component, OnChanges, Input } from '@angular/core';
import { SikoOpeningSchedule, SikoWeekdayOpeningDay } from '@siko/models';
import { SpecialOpeningDay } from '@spartacus/core';
import { B2bCommonModule } from '@siko/shared';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

@Component({
    selector: 'app-opening-hours',
    templateUrl: './opening-hours.component.html',
    styleUrls: ['./opening-hours.component.scss'],
    standalone: true,
    imports: [
        B2bCommonModule,
        NgbTooltipModule
    ]
})
export class SikoOpeningHoursComponent implements OnChanges {

    @Input() openingHours?: SikoOpeningSchedule;
    @Input() showTitle = true;
    @Input() tooltipPosition = 'auto';
    isCurrentlyOpen?: boolean = undefined;
    currentIndexOfDay?: number;

    openedDays: string[] = [];
    specialDayMessage: any[] = [];

    constructor() {
        dayjs.extend(customParseFormat);
        dayjs.extend(utc);
        dayjs.extend(timezone);
    }

    ngOnChanges(): void {
        if (this.openingHours?.weekDayOpeningList) {
            const currentWeekdayOpeningDay: SikoWeekdayOpeningDay | undefined = this.openingHours.weekDayOpeningList.find(
                (weekdayOpening) => weekdayOpening.weekDayIndex === this.getCurrentWeekDayIndex(),
            );

            if (currentWeekdayOpeningDay) {
                this.setIsCurentlyOpen(currentWeekdayOpeningDay, this.openingHours.specialDayOpeningList);
            }
        }
    }

    onTabClick(idOfDay: string): void {
        const indexDayInArray: number = this.openedDays.findIndex(x => x === idOfDay);

        if (indexDayInArray === -1) {
            this.openedDays.push(idOfDay);
        }
        else {
            this.openedDays.splice(indexDayInArray, 1);
        }
    }

    isInOpenedDay(idOfDay: string): boolean {
        return this.openedDays.findIndex(x => x === idOfDay) !== -1;
    }

    checkDay(openingHour: any, specialeOpeninigDays?: SpecialOpeningDay[]): string {
        let result = '';

        if (this.isWeekend(openingHour.weekDayIndex)) {
            result += 'bg-light ';
        }

        if (specialeOpeninigDays) {
            for (const day of specialeOpeninigDays) {
                const specialeDay = dayjs(day.date).day();

                if (specialeDay === openingHour.weekDayIndex) {
                    result += 'special-day';
                }
            }
        }

        return result;
    }

    getSpecialDayMessage(openingHour: any, specialeOpeninigDays?: SpecialOpeningDay[]): string[] {
        this.specialDayMessage = [];

        if (specialeOpeninigDays) {
            for (const day of specialeOpeninigDays) {
                const specialeDay = dayjs(day.date).day();

                if (specialeDay === openingHour.weekDayIndex) {
                    this.specialDayMessage.push(day.comment);
                    this.specialDayMessage.push(day.formattedDate);
                    this.specialDayMessage.push(day.closed);
                    this.specialDayMessage.push(day.closingTime?.formattedHour);
                    this.specialDayMessage.push(day.name);
                    this.specialDayMessage.push(day.openingTime?.formattedHour);
                }
            }
        }

        return this.specialDayMessage;
    }

    private getCurrentWeekDayIndex(): number {
        return dayjs().day();
    }

    private setIsCurentlyOpen(currentWeekdayOpeningDay: SikoWeekdayOpeningDay, specialDayOpeningList?: SpecialOpeningDay[]): void {
        const specialDayOpening = specialDayOpeningList?.find(obj => currentWeekdayOpeningDay.weekDayIndex === dayjs(obj.date).day());
        const currentDayjs: dayjs.Dayjs = dayjs().tz('CET');
        const currentDayjsAsDate: Date = currentDayjs.toDate();

        this.currentIndexOfDay = currentDayjs.day();
        this.isCurrentlyOpen = false;

        if (specialDayOpening?.closed) {
            return;
        }

        let storeOpeningDayjs: Date | undefined;
        let storeClosingDayjs: Date | undefined;

        if (specialDayOpening?.openingTime?.formattedHour) {
            storeOpeningDayjs = this.getStoreHours(specialDayOpening.openingTime.formattedHour, currentDayjs);
        }
        else if (currentWeekdayOpeningDay.openingTime?.formattedHour) {
            storeOpeningDayjs = this.getStoreHours(currentWeekdayOpeningDay.openingTime.formattedHour, currentDayjs);
        }

        if (specialDayOpening?.closingTime?.formattedHour) {
            storeClosingDayjs = this.getStoreHours(specialDayOpening.closingTime.formattedHour, currentDayjs);
        }
        else if (currentWeekdayOpeningDay.closingTime?.formattedHour) {
            storeClosingDayjs = this.getStoreHours(currentWeekdayOpeningDay.closingTime.formattedHour, currentDayjs);
        }

        if (!storeOpeningDayjs || !storeClosingDayjs) {
            return;
        }

        this.isCurrentlyOpen = currentDayjsAsDate.getTime() >= storeOpeningDayjs.getTime()
            && currentDayjsAsDate.getTime() <= storeClosingDayjs.getTime();
    }

    private getStoreHours(time: string, today: dayjs.Dayjs): Date {
        time = time.includes('PM') || time.includes('AM') ? dayjs(time, 'h:mm A').format('HH:mm') : time;

        const splitTime = time.split(/:/);

        return today.hour(parseInt(splitTime[0]))
            .minute(parseInt(splitTime[1]))
            .second(0)
            .millisecond(0)
            .toDate();
    }

    private isWeekend(weekDayIndex: number): boolean {
        return weekDayIndex === 0 || weekDayIndex === 6;
    }

}
