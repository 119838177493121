<form *ngIf='form' [formGroup]='form' (submit)='formSubmitSubject$.next(undefined)'>
  <div class='pt-5 siko-fs-14'>
    {{ 'cartShared.importEntriesDialog.importProductFileDetails' | cxTranslate }}
  </div>
  <div class='pt-3 siko-fs-14'>
    {{ 'cartShared.importEntriesDialog.importProductFileInfo' | cxTranslate }}
  </div>
  <div class='cx-import-entries-row'>
    <span class='siko-font-primary-semiBold siko-fs-14'>{{ 'cartShared.importEntriesDialog.savedCartName' | cxTranslate }}</span>
      <input
        [placeholder]='sikoFileUploadService.getUploadFileName() | async'
        [maxLength]='nameMaxLength'
        class='form-control new-cart-name'
        formControlName='name'
        type='text'
      />
    <cx-form-errors [control]="$any(form.get('name'))"></cx-form-errors>
    <p class='cx-import-entries-input-hint'>
      {{
      'siko.charactersLeft'
        | cxTranslate: {count: nameCharacterLeft}
      }}
    </p>
  </div>
  <div class='cx-import-entries-row mt-3'>
    <span class='siko-font-primary-semiBold siko-fs-14'>{{ 'cartShared.importEntriesDialog.savedCartDescription' | cxTranslate }}</span>
    <textarea
      [maxLength]='descriptionMaxLength'
      class='form-control'
      formControlName='description'
      rows='5'
    ></textarea>
    <cx-form-errors [control]="$any(form.get('description'))"></cx-form-errors>

    <p class='cx-import-entries-input-hint'>
      {{ 'cartShared.importEntriesDialog.charactersLeft'| cxTranslate }}: {{descriptionsCharacterLeft}}
    </p>
  </div>
  <div class='upload-section'>
    <app-file-upload [formControl]="$any(form.get('file'))" [accept]='allowedTypes'>
    </app-file-upload>
  </div>
  <div class='form-errors' *ngIf='form.get("file")?.value.length !== 0'>
    <siko-form-errors
      [control]="$any(form.get('file'))"
      prefix='formErrors.file'
    ></siko-form-errors>
  </div>
  <div class='cx-import-entries-footer'>
    <ng-container *ngIf='form.get("file")?.value.length === 0; else readyToImport'>
      <div class='import-button'>
        <span class='icon'></span>
        <h4 class='d-inline siko-color-grey-2'>
          {{'cartShared.importEntriesDialog.import' | cxTranslate}}
        </h4>
      </div>
    </ng-container>
    <ng-template #readyToImport>
      <div class='ready-to-import-button'>
        <siko-button
          [disabled]="form.get('file')?.status === 'PENDING'"
          mode='cart'
          [text]='"cartShared.importEntriesDialog.title" | cxTranslate'
          iconPosition='before'
          iconSvg='importCart/import'
          (clickEvent)='formSubmitSubject$.next(undefined)'
        >
        </siko-button>
      </div>
    </ng-template>
  </div>
</form>
