import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SikoCheckoutService } from '@siko/features/checkout/checkout-place-order/components/place-order/checkout.service';
import { B2bCommonModule } from '@siko/shared';
import { SikoPageSlotModule } from '@siko/features/shared-components/page-slot/page-slot.module';
import { SikoDuckLoaderComponent } from '@siko/features/shared-components/duck-loader/duck-loader.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-checkout-template',
    templateUrl: './checkout-outlet.component.html',
    styleUrls: ['./checkout-outlet.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        B2bCommonModule,
        SikoDuckLoaderComponent,
        SikoPageSlotModule
    ]
})

export class SikoCheckoutOutletComponent {

    constructor(
        public readonly checkoutService: SikoCheckoutService,
    ) {}
}
