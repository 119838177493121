<ng-container>
  <app-dialog-header>
    {{"sikoOrder.sapOrderDocuments.title" | cxTranslate}}:&nbsp;
    <ng-container *ngIf='sapOrderCode else commerceOrderCode'>
      {{sapOrderCode}}
    </ng-container>
    <ng-template #commerceOrderCode>
      {{orderCode}}
    </ng-template>
  </app-dialog-header>
  <div class='siko-dialog-body'>
    <ng-container *ngIf='sapOrderCode'>
      <ng-container *ngIf='sapOrderData$ | async as sapOrderData'>
        <div class='mb-5'>
          <div class='row'>
            <div class='col'>
              <button class='order-document-action no-style-button'
                      [disabled]='(orderHistoryService.isDocumentLoading$ | async) === true'
                      (click)="orderHistoryService.onPdfIconClick($any(sapOrderData.orderId), 'confirmation')">
                <app-document-item [action]='downloadAction'
                                   [icon]='orderConfirmationIcon'
                                   extension='pdf'
                                   name='{{"siko.orderConfirmationMessage" | cxTranslate}}'>
                </app-document-item>
              </button>
              <button class='order-document-action no-style-button'
                      *ngIf="sapOrderData.orderStatus !== 'completed'
                   && sapOrderData.orderStatus !== 'cancelled'
                   && sapOrderData.orderStatus !== 'error'"
                      [disabled]='(orderHistoryService.isDocumentLoading$ | async) === true'
                      (click)="orderHistoryService.onPdfIconClick($any(sapOrderData.orderId), 'proforma')">
                <app-document-item [action]='downloadAction'
                                   [icon]='proformaInvoiceIcon'
                                   extension='pdf'
                                   name='{{"siko.proformInvoice" | cxTranslate}}'>
                </app-document-item>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class='mb-5'>
      <div class='row'>
        <div class='col'>
          <h3
            id='sap-order-documents-table-title'
          >
            {{"sikoOrder.sapOrderDocuments.invoices" | cxTranslate}}
          </h3>
        </div>
      </div>
      <div class='row'>
        <div class='col'>
          <ng-container *ngIf='sikoSapOrderInvoices$ | async as sikoSapOrderInvoices'>
            <ng-container *ngIf='sikoSapOrderInvoices.length > 0 else noDocuments'>
              <div class='siko-responsive d-none d-sm-block'>
                <table
                  aria-label='sap-order-documents-table-title'
                  class='siko-table extended-last'>
                  <thead class='siko-table-header'>
                  <tr>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.document' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.invoiceType' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.documentDate' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.fullPrice' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.status' | cxTranslate}}</th>
                    <th scope='col'></th>
                  </tr>
                  </thead>
                  <tbody class='siko-table-body'>
                  <tr *ngFor='let invoice of sikoSapOrderInvoices'>
                    <td>{{invoice.documentNumber}}</td>
                    <td>{{'siko.invoiceType.' + invoice.sikoInvoiceType | cxTranslate}}</td>
                    <td>{{invoice.invoiceDate | cxDate : "dd.MM.YYYY"}}</td>
                    <td>{{invoice.sikoFormattedGrandTotal}}</td>
                    <td>{{"sikoCheckout.documents.invoiceStatuses." + invoice.status | cxTranslate}}</td>
                    <td class='text-left load-document'>
                      <siko-button
                        #invoiceButton
                        [block]='false'
                        mode='light'
                        [text]='"sikoOrder.sapOrderDocuments.load" | cxTranslate'
                        iconPosition='before'
                        iconSvg='orderDetail/invoice_mini'
                        (clickEvent)='onPdfIconClick($any(invoice.documentNumber), true, invoiceButton)'
                      ></siko-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class='d-block d-sm-none'>
                <div class='document-detail' *ngFor='let invoice of sikoSapOrderInvoices'>
                  <table
                    aria-label='sap-order-documents-table-title'
                  >
                    <thead>
                    <tr>
                      <th scope='col'>
                        <span>{{"sikoOrder.sapOrderDocuments.document" | cxTranslate}}</span> {{invoice.documentNumber}}
                      </th>
                      <th scope='col'></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.invoiceType' | cxTranslate}}
                      </td>
                      <td>
                        {{'siko.invoiceType.' + invoice.sikoInvoiceType | cxTranslate}}
                      </td>
                    </tr>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.documentDate' | cxTranslate}}
                      </td>
                      <td>
                        {{invoice.invoiceDate | cxDate : "dd.MM.YYYY"}}
                      </td>
                    </tr>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.fullPrice' | cxTranslate}}
                      </td>
                      <td>
                        {{invoice.sikoFormattedGrandTotal}}
                      </td>
                    </tr>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.status' | cxTranslate}}
                      </td>
                      <td>
                        {{"sikoCheckout.documents.invoiceStatuses." + invoice.status | cxTranslate}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class='load-document text-right'>
                    <siko-button
                      #mobileInvoiceButton
                      [mode]='$any("light")'
                      [block]='false'
                      [text]='"sikoOrder.sapOrderDocuments.load" | cxTranslate'
                      iconPosition='before'
                      iconSvg='orderDetail/invoice_mini'
                      (clickEvent)='onPdfIconClick($any(invoice.documentNumber), true, mobileInvoiceButton)'
                    ></siko-button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noDocuments>
              <p>{{'sikoOrder.sapOrderDocuments.noDocumentsMessage' | cxTranslate}}</p>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
    <div class='mb-5'>
      <div class='row'>
        <div class='col'>
          <h3 id='delivery-notes-table-title'>{{'sikoOrder.sapOrderDocuments.deliveryNotes' | cxTranslate}}</h3>
        </div>
      </div>
      <div class='row'>
        <div class='col'>
          <ng-container *ngIf='sikoSapOrderDeliveryNotes$ | async as sikoDeliveryNotes'>
            <ng-container *ngIf='sikoDeliveryNotes.length > 0 else noDocuments'>
              <div class='siko-responsive d-none d-sm-block'>
                <table
                  aria-describedby='delivery-notes-table-title'
                  class='siko-table extended-last'>
                  <thead>
                  <tr class='siko-table-header'>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.deliveryNote' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.plannedCollectionDate' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.dateOfExpedition' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.totalWeight' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.status' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.deliveryPlace' | cxTranslate}}</th>
                    <th scope='col'>{{'sikoOrder.sapOrderDocuments.poNumber' | cxTranslate}}</th>
                    <th scope='col'></th>
                  </tr>
                  </thead>
                  <tbody class='siko-table-body' *ngIf='sikoSapOrderDeliveryNotes$ | async as sikoDeliveryNotes'>
                  <tr *ngFor='let sikoDeliveryNote of sikoDeliveryNotes'>
                    <td>{{sikoDeliveryNote.documentNumber}}</td>
                    <td>{{sikoDeliveryNote.sikoExpectedDeliveryDate | cxDate : "dd.MM.YYYY"}}</td>
                    <td>{{sikoDeliveryNote.sikoExpectedDeliveryDate | cxDate : "dd.MM.YYYY"}}</td>
                    <td>{{sikoDeliveryNote.grossWeight}}</td>
                    <td>{{"sikoCheckout.documents.deliveryNoteStatuses." + sikoDeliveryNote.status?.toUpperCase() | cxTranslate}}</td>
                    <td>{{sikoDeliveryNote.sikoPointOfService?.displayName}}</td>
                    <td>{{purchaseOrderNumber === "" ? "-" : purchaseOrderNumber}}</td>
                    <td class='text-left load-document'>
                      <siko-button
                        #deliveryNoteButton
                        [mode]='$any("light")'
                        [block]='false'
                        [text]='"sikoOrder.sapOrderDocuments.load" | cxTranslate'
                        iconPosition='before'
                        iconSvg='orderDetail/invoice_mini'
                        (clickEvent)='onPdfIconClick($any(sikoDeliveryNote.documentNumber), false, deliveryNoteButton)'
                      ></siko-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class='d-block d-sm-none'>
                <div class='document-detail' *ngFor='let sikoDeliveryNote of sikoDeliveryNotes'>
                  <table
                    aria-describedby='delivery-notes-table-title'
                  >
                    <thead>
                    <tr>
                      <th scope='col'>
                        <span>{{"sikoOrder.sapOrderDocuments.deliveryNote" | cxTranslate}}</span> {{sikoDeliveryNote.documentNumber}}
                      </th>
                      <th scope='col'></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.plannedCollectionDate' | cxTranslate}}
                      </td>
                      <td>
                        {{sikoDeliveryNote.sikoExpectedDeliveryDate | cxDate : "dd.MM.YYYY"}}
                      </td>
                    </tr>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.dateOfExpedition' | cxTranslate}}
                      </td>
                      <td>
                        {{sikoDeliveryNote.sikoExpectedDeliveryDate | cxDate : "dd.MM.YYYY"}}
                      </td>
                    </tr>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.totalWeight' | cxTranslate}}
                      </td>
                      <td>
                        {{sikoDeliveryNote.grossWeight}}
                      </td>
                    </tr>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.status' | cxTranslate}}
                      </td>
                      <td>
                        {{"sikoCheckout.documents.deliveryNoteStatuses." + sikoDeliveryNote.status?.toUpperCase() | cxTranslate}}
                      </td>
                    </tr>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.deliveryPlace' | cxTranslate}}
                      </td>
                      <td>
                        {{sikoDeliveryNote.sikoPointOfService?.displayName}}
                      </td>
                    </tr>
                    <tr>
                      <td class='light-text'>
                        {{'sikoOrder.sapOrderDocuments.poNumber' | cxTranslate}}
                      </td>
                      <td>
                        {{purchaseOrderNumber === "" ? "-" : purchaseOrderNumber}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class='load-document text-right'>
                    <siko-button
                      #mobileDeliveryNoteButton
                      [mode]='$any("light")'
                      [block]='false'
                      [text]='"sikoOrder.sapOrderDocuments.load" | cxTranslate'
                      iconPosition='before'
                      iconSvg='orderDetail/invoice_mini'
                      (clickEvent)='onPdfIconClick($any(sikoDeliveryNote.documentNumber), false, mobileDeliveryNoteButton)'
                    ></siko-button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noDocuments>
              <p>{{'sikoOrder.sapOrderDocuments.noDocumentsMessage' | cxTranslate}}</p>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
