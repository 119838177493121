import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import {
    SikoOrderDocumentsComponent
} from '@siko/features/shared-components/order-documents/components/order-documents/order-documents.component';
import { SikoOrderDocumentsDialogComponent } from '@siko/features/shared-components/order-documents/components/order-documents-dialog/order-documents-dialog.component';
import { SikoDocumentItemComponent } from '@siko/features/shared-components/document-item/document-item.component';
import { SikoDialogComponentsModule } from '@siko/features/shared-components/dialog-components/dialog-components.module';


@NgModule({
    declarations: [
        SikoOrderDocumentsDialogComponent,
        SikoOrderDocumentsComponent,
    ],
    imports: [
        B2bCommonModule,
        SikoDocumentItemComponent,
        SikoDialogComponentsModule,
    ],
    exports: [SikoOrderDocumentsComponent]
})
export class SikoOrderDocumentsModule {}
