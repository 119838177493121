import { StateUtils } from '@spartacus/core';
import { ORDER_HISTORY_FILTER_DATA } from '@siko/features/my-account/order-history/store/reducers';

export const LOAD_FILTERS = '[Filter History] Load Filters';
export const UPDATE_FILTER = '[Filter History] Update Filter Property';
export const CLEAR_FILTERS = '[Filter History] Clear Filters';

export class LoadFiltersForOrderHistory extends StateUtils.LoaderLoadAction {
    readonly type = LOAD_FILTERS;

    constructor() {
        super(LOAD_FILTERS);
    }
}

export class UpdateFilters extends StateUtils.LoaderSuccessAction {
    readonly type = UPDATE_FILTER;

    constructor(public payload: any) {
        super(ORDER_HISTORY_FILTER_DATA);
    }
}

export class ClearFilters extends StateUtils.LoaderResetAction {
    readonly type = CLEAR_FILTERS;

    constructor() {
        super(ORDER_HISTORY_FILTER_DATA);
    }
}

export type OrderHistoryAction = ClearFilters | LoadFiltersForOrderHistory | UpdateFilters;
