import { Component, ChangeDetectionStrategy } from '@angular/core';
import { OrderConfirmationTotalsComponent } from '@spartacus/order/components';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-order-confirmation-totals',
    templateUrl: './order-confirmation-totals.component.html'
})
export class SikoOrderConfirmationTotalsComponent extends OrderConfirmationTotalsComponent {

}
