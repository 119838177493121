<div class='coupon-card' [ngClass]='giveAwayCoupon ? "wider" : "" '>
  <div class='head'>
    <div class='coupon-type'>
      <img [src]='getCouponIcon() | sikoIconAsset' alt='Coupon type icon'>
    </div>
    <div class='coupon-code'>
      <span>{{ giveAwayCoupon ? sikoCoupon.couponCode : coupon.couponId }}</span>
    </div>
    <div *ngIf='giveAwayCoupon' class='coupon-copy' (click)='copyToClipboard(sikoCoupon.couponCode)'>
      <div
        class='d-flex placeholder'
        role='button'
        tabindex='0'
        ngbTooltip
        placement='top'
        sikoTooltipText="{{ 'sikoMyCoupons.copyCode' | cxTranslate }}"
        (click)='copyCode = !copyCode'
      >
        <div *ngIf='!copyCode' class='relative'>
          <img [src]='"copy-to-clipboard.svg" | sikoIconAsset' alt='Copy to clipboard icon' />
        </div>
      </div>
      <div *ngIf='copyCode' class='copied'>
        <img [ngClass]='copyCode ? "animate" : ""' [src]='"checkout/done-small.svg" | sikoIconAsset'
             alt='Copied to clipboard icon' />
      </div>
    </div>
  </div>
  <div class='coupon-info'>
    <h3>{{ coupon.name }}</h3>
    <p>{{ coupon.description }}</p>
  </div>
  <div *ngIf='coupon?.startDate && coupon?.endDate; else startDate' class='coupon-validity'>
    {{ 'sikoMyCoupons.validity' | cxTranslate:
    {
      from: coupon.startDate | cxDate: 'd.M.yyyy': 'UTC',
      to: coupon.endDate | cxDate: 'd.M.yyyy': 'UTC'
    }
    }}
  </div>
  <ng-template #startDate>
    <div *ngIf='coupon?.startDate; else endDate' class='coupon-validity'>
      {{ 'sikoMyCoupons.validityFrom' | cxTranslate:{ date: coupon.startDate | cxDate: 'd.M.yyyy' } }}
    </div>
  </ng-template>
  <ng-template #endDate>
    <div *ngIf='coupon?.endDate' class='coupon-validity'>
      {{ 'sikoMyCoupons.validityTo' | cxTranslate:{ date: coupon.endDate | cxDate: 'd.M.yyyy':'UTC' } }}
    </div>
  </ng-template>
  <div *ngIf='!giveAwayCoupon' class='coupon-products'>
    <siko-button
      (click)='findProducts()'
      mode='light'
      size='md'
      [text]='"sikoMyCoupons.relatedProducts" | cxTranslate'>
    </siko-button>
  </div>
</div>
