import { Component } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';

@Component({
    standalone: true,
    selector: 'app-red-cross-icon',
    templateUrl: './red-cross.component.html',
    imports: [
        B2bCommonModule,
    ],
})
export class SikoRedCrossIconComponent {
}
