import { NgModule } from '@angular/core';
import { B2bCommonModule, SikoPaymentTypeNamePipe } from '@siko/shared';
import { SikoOrderDetailItemsComponent } from '@siko/features/shared-components/order-details-items/order-detail-items.component';
import { SikoCartItemListModule } from '@siko/features/shared-components/cart-item-list/cart-item-list.module';
import { SikoAddressComponent } from '@siko/features/shared-components/address/address.component';

@NgModule({
    declarations: [
        SikoOrderDetailItemsComponent,
    ],
    imports: [
        B2bCommonModule,
        SikoCartItemListModule,
        SikoAddressComponent,
        SikoPaymentTypeNamePipe
    ],
    exports: [
        SikoOrderDetailItemsComponent,
    ],
})
export class SikoOrderDetailsItemsModule {}
