import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import { AsmComponentsModule } from '@spartacus/asm/components';
import {
    FormErrorsModule,
    IconModule,
    MessageComponentModule,
    PasswordVisibilityToggleModule,
} from '@spartacus/storefront';
import { ReactiveFormsModule } from '@angular/forms';
import { SikoCustomerSelectionComponent } from '@siko/features/asm/components/customer-selection/customer-selection.component';
import { SikoAsmMainUiComponent } from './components/asm-main-ui/asm-main-ui.component';
import { SikoCsagentLoginFormComponent } from './components/csagent-login-form/csagent-login-form.component';


@NgModule({
    imports: [
        B2bCommonModule,
        AsmComponentsModule,
        MessageComponentModule,
        IconModule,
        FormErrorsModule,
        ReactiveFormsModule,
        PasswordVisibilityToggleModule,
    ],
    declarations: [
        SikoAsmMainUiComponent,
        SikoCsagentLoginFormComponent,
        SikoCustomerSelectionComponent,
    ],
    exports: [
        SikoAsmMainUiComponent,
        SikoCustomerSelectionComponent,
    ],
    providers: [],
})
export class SikoAsmComponentsModule {}
