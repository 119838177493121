import { ChangeDetectorRef, Component, Input, OnChanges, Optional } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CartItemListComponent } from '@spartacus/cart/base/components';
import { ItemListContext, SikoOrderEntry, SikoPromotionResult, SikoSapOrderItem } from '@siko/models';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { SikoActiveCartService } from '@siko/features/cart';
import {
    OccConfig,
    UserIdService,
} from '@spartacus/core';
import { sfGetIconAsset, sfIsFreeGift } from '@siko/shared/utils/siko-functions';
import { SikoAutoUnsubscribe, SikoBreakpointObserverService } from '@siko/common';
import {
    ActiveCartFacade,
    MultiCartFacade,
    SelectiveCartFacade,
} from '@spartacus/cart/base/root';
import { OutletContextData } from '@spartacus/storefront';
import { Order } from '@spartacus/order/root';
import { SikoHelperFunctionsService } from '@siko/shared';

@SikoAutoUnsubscribe(['activatedCartIdSubscription'])
@Component({
    selector: 'app-cart-item-list',
    templateUrl: './cart-item-list.component.html',
    styleUrls: ['./cart-item-list.component.scss'],
})
export class SikoCartItemListComponent extends CartItemListComponent implements OnChanges {
    @Input() enableActions = true;
    @Input() isSapOrder = false;
    @Input() isOrder = false;
    @Input() sapOrderEntries?: SikoSapOrderItem[];
    @Input() order?: Order;

    isPickUpInStore = false;
    arrayFreeGifts: number[] = [];
    activatedCartIdSubscription?: Subscription;

    constructor(
        public helpers: SikoHelperFunctionsService,
        protected sikoActiveCartService: SikoActiveCartService,
        private readonly backendConfig: OccConfig,
        public sikoBreakpointObserver: SikoBreakpointObserverService,
        protected activeCartService: ActiveCartFacade,
        protected selectiveCartService: SelectiveCartFacade,
        protected userIdService: UserIdService,
        protected multiCartService: MultiCartFacade,
        protected cd: ChangeDetectorRef,
        @Optional() protected outlet?: OutletContextData<ItemListContext>,
    ) {
        super(
            activeCartService,
            selectiveCartService,
            userIdService,
            multiCartService,
            cd,
            outlet,
        );
    }

    get sikoItems(): SikoOrderEntry[] {
        // eslint-disable-next-line
        return this._items;
    }

    ngOnChanges(): void {
        this._items.forEach((sikoOrderEntry: SikoOrderEntry) => {
            if (sikoOrderEntry.entryNumber && sfIsFreeGift(sikoOrderEntry.entryNumber, this.order?.appliedProductPromotions)) {
                this.arrayFreeGifts.push(sikoOrderEntry.entryNumber);
            }
        });

        if (this.sapOrderEntries) {
            this.sapOrderEntries.forEach((item, index) => {
                if (sfIsFreeGift(index, this.order?.appliedProductPromotions)) {
                    this.arrayFreeGifts.push(index);
                }
            });
        }

        this._items.every((sikoOrderEntry: SikoOrderEntry) => {

            if (sikoOrderEntry.hasOwnProperty('deliveryPointOfService')) {
                this.isPickUpInStore = true;

                return false;
            }

            this.isPickUpInStore = false;

            return true;
        });
    }

    getItemImageUrl(imageUrl: string): string {
        const baseSiteUrl: string | undefined = this.backendConfig.backend?.occ?.baseUrl;

        if (baseSiteUrl) {
            return `${baseSiteUrl}${imageUrl}`;
        }

        return sfGetIconAsset('no-product-img.svg');

    }

    isItemCancelled(item: SikoSapOrderItem): boolean {
        return item.statusDisplay === 'cancelled';
    }

    getControl(item: SikoOrderEntry): Observable<UntypedFormGroup> {
        let cartId: string;

        this.activatedCartIdSubscription = this.activeCartService.getActiveCartId()
            .subscribe((id: string) => {
                cartId = id;
            });

        // @ts-expect-error
        return this.form.get(this.getControlName(item))
            .valueChanges
            .pipe(
                // eslint-disable-next-line import/no-deprecated
                startWith(null),
                tap((value) => {
                    if (item.updateable && value && !this.readonly) {
                        if (this.selectiveCartService && this.options.isSaveForLater) {
                            this.selectiveCartService.updateEntry(
                                value.entryNumber,
                                value.quantity,
                            );
                        }
                        else if (this.cartId && this.userId) {
                            this.multiCartService.updateEntry(
                                this.userId,
                                this.cartId,
                                value.entryNumber,
                                value.quantity,
                            );
                        }
                        else if (
                            item.hasOwnProperty('deliveryPointOfService') &&
                            this.isPickUpInStore &&
                            item.deliveryPointOfService?.name
                        ) {
                            this.sikoActiveCartService.updateCartAmount(
                                cartId,
                                value.entryNumber,
                                value.quantity,
                                item.deliveryPointOfService.name,
                            );
                        }
                        else {
                            this.activeCartService.updateEntry(
                                value.entryNumber,
                                value.quantity,
                            );
                        }
                    }
                }),
                map(() => <UntypedFormGroup>this.form.get(this.getControlName(item))),
            );
    }

    onRemoveClick(orderEntry: SikoOrderEntry): void {
        this.activeCartService.removeEntry(orderEntry);
    }

    getPromotionName(index: number): string {
        const promotion = this.order?.appliedProductPromotions?.find((i: SikoPromotionResult) => i.consumedEntries?.find(entry => entry.orderEntryNumber === index) !== undefined);

        if (promotion?.promotion && promotion.promotion.title) {
            return promotion.promotion.title;
        }

        return '';
    }

}
