<ng-container *ngIf='order$ | async as sikoOrder else loading'>
  <div class='container'>
    <app-order-confirmation-thank-message
      [order]='sikoOrder'
      [sikoUser$]='sikoUser$'
      [pgStatus]='paymentGatewayPaymentStatus'
    ></app-order-confirmation-thank-message>
    <div class='giveaway-coupons' *ngIf='hasGiveawayCoupons'>
      <h3 class='coupon-title text-center'>{{ 'sikoMyCoupons.couponGiveAway' | cxTranslate }}</h3>
      <div *ngIf='sikoUser$ | async as user' class='pb-2 text-center siko-lh-150'>
        {{ "sikoMyCoupons.couponGiveAwayDescription" | cxTranslate }}
      </div>
      <ng-container *ngFor='let promotions of sikoOrder.appliedOrderPromotions'>
        <ng-container *ngFor='let coupon of promotions.giveAwayCouponCodes'>
          <app-coupon-card
            [coupon]='coupon'
            [giveAwayCoupon]='true'
            class='d-flex justify-content-center pb-32 wider'
          ></app-coupon-card>
        </ng-container>
      </ng-container>
    </div>
    <div class='row my-1 mb-5'>
      <div class='col-12'>
        <app-order-confirmation-overview></app-order-confirmation-overview>
      </div>
    </div>
    <app-order-details-items [sikoOrder$]='order$'></app-order-details-items>
    <div class='mt-5 mb-5'>
      <app-order-detail-totals
        [order]='sikoOrder'
        [orderConfirmation]='true'>
      </app-order-detail-totals>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class='cx-spinner'>
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
