import { SikoProductDisponibility } from '../../models';
import { PRODUCT_DISPONIBILITY, PRODUCT_SAP_STATUS } from '../../constants';


export const isProductForSale = (
    productDisponibility: SikoProductDisponibility,
    availableIn: number,
    sapStatus: string,
): boolean => {
    let outOfStock: boolean;

    outOfStock = productDisponibility.status === PRODUCT_DISPONIBILITY.OUT_OF_STOCK_DISPONIBILITY_STATUS;

    const outOfMainStock: boolean = outOfStock;

    if (productDisponibility.storeDisponibility) {
        outOfStock =
            outOfMainStock && productDisponibility.storeDisponibility.status
            === PRODUCT_DISPONIBILITY.OUT_OF_STOCK_DISPONIBILITY_STATUS;
    }

    return (availableIn > 0 || !outOfStock) && sapStatus !== PRODUCT_SAP_STATUS.SAP_STATUS_Z1;
};

export const showAddToCartButtons = (
    forSale: boolean,
    inCentralStock: boolean,
    availableIn: number,
    sapStatus?: string,
): boolean => forSale && (inCentralStock ||
    PRODUCT_SAP_STATUS.SAP_STATUS_Z2 !== sapStatus ||
    availableIn > PRODUCT_DISPONIBILITY.ZERO_PRODUCTS_STORE_AVAILABILITY);
