import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class Sikob2bHeaderCommunicationService {

    private readonly isShowStickyMenuSubject = new BehaviorSubject(false);

    get isShowStickyMenu$(): Observable<boolean>{
        return this.isShowStickyMenuSubject.asObservable();
    }

    setIsShowStickyMenu(test: boolean){
        this.isShowStickyMenuSubject.next(test);
    }

}
