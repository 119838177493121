import { Injectable } from '@angular/core';
import {
    AuthActions,
    AuthService,
    AuthToken,
    OAuthLibWrapperService,
    OCC_USER_ID_ANONYMOUS, OCC_USER_ID_CURRENT, RoutingService,
    UserIdService,
    WindowRef,
} from '@spartacus/core';
import { AsmAuthStorageService, CsAgentAuthService, TokenTarget } from '@spartacus/asm/root';
import { Store } from '@ngrx/store';
import { ASM } from '@siko/constants';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Injectable({
    providedIn: 'root',
})
export class SikoCsAgentAuthService extends CsAgentAuthService {

    constructor(
        readonly routingService: RoutingService,
        readonly winRef: WindowRef,
        authService: AuthService,
        authStorageService: AsmAuthStorageService,
        userIdService: UserIdService,
        oAuthLibWrapperService: OAuthLibWrapperService,
        store: Store,
        userAccountFacade: UserAccountFacade,
    ) {
        super(authService, authStorageService, userIdService, oAuthLibWrapperService, store, userAccountFacade);
    }

    async authorizeCustomerSupportAgent(
        userId: string,
        password: string,
    ): Promise<void> {
        let userToken: AuthToken | undefined;

        this.authStorageService
            .getToken()
            .subscribe((token) => userToken = token)
            .unsubscribe();

        this.winRef.localStorage?.setItem(ASM.ASM_AGENT, userId);

        this.authStorageService.switchTokenTargetToCSAgent();

        try {
            await this.oAuthLibWrapperService.authorizeWithPasswordFlow(
                userId,
                password,
            );

            // Start emulation for currently logged in user
            let customerId: string | undefined;

            this.userAccountFacade
                .get()
                .subscribe((user) => customerId = user?.customerId)
                .unsubscribe();
            this.store.dispatch(new AuthActions.Logout());

            if (customerId !== undefined && userToken !== undefined) {
                // OCC specific user id handling. Customize when implementing different backend
                this.userIdService.setUserId(customerId);
                this.authStorageService.setEmulatedUserToken(userToken);
                this.store.dispatch(new AuthActions.Login());
            }
            else {
                // When we can't get the customerId just end all current sessions
                this.userIdService.setUserId(OCC_USER_ID_ANONYMOUS);
                this.authStorageService.clearEmulatedUserToken();
            }
        }
        catch {
            this.winRef.localStorage?.setItem(ASM.ASM_AGENT, '');
            this.authStorageService.switchTokenTargetToUser();
        }
    }

    async logoutCustomerSupportAgent(): Promise<void> {

        const emulatedToken = this.authStorageService.getEmulatedUserToken();

        let isCustomerEmulated;

        this.userIdService
            .isEmulated()
            .subscribe((emulated) => isCustomerEmulated = emulated)
            .unsubscribe();

        await this.oAuthLibWrapperService.revokeAndLogout();

        this.store.dispatch({ type: '[Auth] Logout Customer Support Agent' });
        this.authStorageService.setTokenTarget(TokenTarget.User);

        if (isCustomerEmulated && emulatedToken) {
            this.store.dispatch(new AuthActions.Logout());
            this.authStorageService.setToken(emulatedToken);
            this.userIdService.setUserId(OCC_USER_ID_CURRENT);
            this.authStorageService.clearEmulatedUserToken();
            this.store.dispatch(new AuthActions.Login());
        }
        else {
            this.authService.logout();
            void this.routingService.go('/login');
        }
    }

    startCustomerEmulationSession(customerId: string): void {
        void this.routingService.go('/');

        super.startCustomerEmulationSession(customerId);
    }

}
