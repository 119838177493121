import { Injectable, ViewContainerRef } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SavedCartFormType } from '@spartacus/cart/saved-cart/root';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';

@Injectable({
    providedIn: 'root'
})
export class SikoOpenDialogFunctionsService{
    private readonly subscription = new Subscription();

    constructor(
      protected launchDialogService: LaunchDialogService,
    ) {
    }

    openDialogCustom(vcr: ViewContainerRef, event: Event, cart: Cart, type: SavedCartFormType): void {
        const dialog = this.launchDialogService.openDialog(
            LAUNCH_CALLER.SAVED_CART,
            undefined,
            vcr,
            {
                cart,
                layoutOption: type
            }
        );

        if (dialog) {
            this.subscription.add(dialog.pipe(take(1)).subscribe());
        }

        event?.stopPropagation();
    }

}
