import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import { SikoOrderStatusBadgeComponent } from './components/order-status-badge/order-status-badge.component';
import { SikoOrderStatusBadgePipe } from './pipes/siko-order-status-badge.pipe';


@NgModule({
    declarations: [
        SikoOrderStatusBadgeComponent,
        SikoOrderStatusBadgePipe,
    ],
    imports: [
        B2bCommonModule,
    ],
    exports: [
        SikoOrderStatusBadgeComponent,
    ],
})
export class SikoOrderStatusBadgeModule {}
