<ng-template *ngIf='position'
  [cxOutlet]='position'
  [cxOutletContext]='{ components$: components$ }'
>
    <ng-template
      *ngFor='let component of components'
      [cxOutlet]='$any(component).flexType'
      [cxOutletContext]='{ component: component }'
      [cxOutletDefer]='getComponentDeferOptions($any(component).flexType)'
      (loaded)='isLoaded($event.valueOf())'
    >
      <ng-container [cxComponentWrapper]='component'></ng-container>
    </ng-template>
</ng-template>
