import { ProtectedRoutesService, RoutingConfig, UrlParsingService } from '@spartacus/core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SikoProtectedRoutesService extends ProtectedRoutesService {
    constructor(protected config: RoutingConfig, protected urlParsingService: UrlParsingService) {
        super(config, urlParsingService);
    }

    private reloaded = false;
    private unprotectedPaths: string[][] = [];

    reloadProtectedRoutes(): void {
        this.reloaded = true;
        this.unprotectedPaths = this.getNonProtectedPaths().map(path => this.getSegments(path));
    }

    isUrlProtected(urlSegments: string[]): boolean {
        if (!this.reloaded) {
            return super.isUrlProtected(urlSegments);
        }

        return this.shouldProtect && !this.matchAnyPath(urlSegments, this.unprotectedPaths);
    }
}
