import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { SikoAuthConfigService } from '../../auth/user-auth/services/auth-config.service';

@Injectable({
    providedIn: 'root',
})
export class SikoAsmAuthCorrectionService {

    constructor(
        private readonly oAuthService: OAuthService,
        private readonly authConfigService: SikoAuthConfigService,
        private readonly winRef: WindowRef,
    ) {}

    resetOAuthConfig(): void {
        const isSSR = !this.winRef.isBrowser();

        // This will reset oAuthService configuration. This is important when ASM is toggled, beacause
        // we need to use different OAuth client when ASM is active
        this.oAuthService.configure({
            tokenEndpoint: this.authConfigService.getTokenEndpoint(),
            loginUrl: this.authConfigService.getLoginUrl(),
            clientId: this.authConfigService.getClientId(),
            dummyClientSecret: this.authConfigService.getClientSecret(),
            revocationEndpoint: this.authConfigService.getRevokeEndpoint(),
            logoutUrl: this.authConfigService.getLogoutUrl(),
            userinfoEndpoint: this.authConfigService.getUserinfoEndpoint(),
            issuer:
                this.authConfigService.getOAuthLibConfig()?.issuer ??
                this.authConfigService.getBaseUrl(),
            redirectUri:
                this.authConfigService.getOAuthLibConfig()?.redirectUri ??
                (!isSSR
                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    this.winRef.nativeWindow!.location.origin
                    : ''),
            ...this.authConfigService.getOAuthLibConfig(),
        });
    }
}
