<ng-container *ngIf='{ observableValue: isSideMenuOpen$ | async } as isSideMenuOpen'>
  <div [ngClass]='isSideMenuOpen.observableValue ? "overlay-bg" : ""'></div>
  <div
    [ngClass]='isSideMenuOpen.observableValue ? "show-responsive-version" : ""'
    #facetList
    class='inner facet-list mb-5'
    [cxFocus]='isDialog ? dialogFocusConfig : {}'
    (esc)='close($any($event))'
    (click)='block($event)'
  >
    <div class='filter-header'>
      <h3 class='siko-color-grey-3'>{{"siko.facet.label"| cxTranslate}}</h3>
      <div class='hide-responsive-version' (click)='hideFilter()'><img [src]='"close-icon.svg" | sikoIconAsset' alt='Close icon' /></div>
    </div>
    <ng-container *ngIf='this.facetList$ | async as facetList'>
      <p class='filter-info'
         *ngIf='facetList.activeFacets !== undefined && facetList.activeFacets.length > 0'>
        {{'siko.facet.activeFilters' | cxTranslate : { count: facetList.activeFacets.length } }}
        ,&nbsp;{{'siko.facet.shownProducts' | cxTranslate : { count: productCount } }}
        <span class='facet-link'
              (click)='plpCommunication.cancelAllFilters(virtualCategory)'>{{"siko.facet.cancelFacetsShort" | cxTranslate}}</span>
      </p>
      <hr class='d-block d-lg-none'>
      <ng-container *ngIf='showFacets(facetList) else emptyFilters'>
        <app-facet
          *ngFor='let facet of facetList.facets'
          #facetRef
          [facet]='facet'
          [attr.data-testId]='$any("facet-" + facet.code)'
          [cxFocus]="{ lock: true, trap: true, autofocus: 'a' }"
          (unlock)='expandFacetGroup(facet, facetRef)'
          [class.expanded]='isExpanded(facet) | async'
          [class.collapsed]='isCollapsed(facet) | async'
          [virtualCategory]='virtualCategory'
        ></app-facet>
      </ng-container>
      <ng-template #emptyFilters>
        <div class='empty-filters'>
          <siko-text
            fontWeight='400'
            fontSize='14'
            color='base-black'
            text='{{"siko.emptyFilters" | cxTranslate}}'
          ></siko-text>
        </div>
      </ng-template>
    </ng-container>
    <siko-button
      class='m-2 d-block d-xl-none'
      [mode]='$any("secondary")'
      [size]='$any("md")'
      text='{{"siko.facet.showResults"|cxTranslate }}'
      (click)='hideFilter()'>
    </siko-button>
  </div>
</ng-container>
