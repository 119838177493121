<ng-container *ngIf="product$ | async as product">
  <div class="d-none d-lg-block">
    <div *ngFor="let review of product.reviews | slice: 0:maxListItems">
      <div class="my-32">
        <app-product-review-item [review]="review"></app-product-review-item>
      </div>
    </div>
  </div>
  <!--  no pagination for mobile-->
  <div class="d-block d-lg-none">
    <div *ngFor="let review of product.reviews">
      <div class="my-32">
        <app-product-review-item [review]="review"></app-product-review-item>
      </div>
    </div>
  </div>
  <ng-container *ngIf="product.numberOfReviews && product.numberOfReviews > maxListItems">
    <div class="d-none d-lg-block">
      <div class="row show-more-link-container justify-content-center">
        <div class="col-6 text-center mx-auto">
          <div class="load-more-link">
            <siko-link
              testId='load-more-link'
              [label]="'siko.plpLoadMore' | cxTranslate"
              [disableLink]="true"
              (click)="showMoreReviews()"
              [style]="$any('primary')"
            ></siko-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="pdp-shadow-card">
            <div class="pdp-shadow-child"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
