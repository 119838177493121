import { Injectable } from '@angular/core';
import {
    GlobalMessageService,
    GlobalMessageType,
    UserIdService,
    RoutingService,
    OccEndpointsService, EventService,
} from '@spartacus/core';

import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { SikoHttpErrorResponse, SikoOrder, SikoUser } from '@siko/models';
import {
    CHECKOUT_RECALCULATION_REQUIRED_ERR_MESSAGE,
    PAYMENT_TYPES,
    HTTP_ERROR,
    CHECKOUT_TERMS_CONFIRM_REQUIRED_ERR_MESSAGE,
    CHECKOUT_PAYMENT_GATEWAY_STATUS,
    CHECKOUT_INVALID_TOTAL_PRICE_ERR_MESSAGE,
} from '@siko/constants';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { UserAccountChangedEvent } from '@spartacus/user/account/root';
import { SikoOccCheckoutAdapter } from '@siko/features/checkout/occ/adapters/occ-checkout.adapter';

@Injectable({
    providedIn: 'root'
})
export class SikoCheckoutService {

    private readonly finishingOrder = new BehaviorSubject(false);
    private readonly syncOrderSubject = new BehaviorSubject(false);
    private readonly syncOrderFailedSubject = new BehaviorSubject(false);
    private readonly backendBaseUrl: string;
    private userId?: string;
    private cartId?: string;

    constructor(
        protected activeCartService: ActiveCartFacade,
        protected userIdService: UserIdService,
        private readonly sikoOccCheckoutAdapter: SikoOccCheckoutAdapter,
        private readonly globalMessageService: GlobalMessageService,
        private readonly routingService: RoutingService,
        private readonly occEndpointsService: OccEndpointsService,
        private readonly eventService: EventService,
    ) {

        this.backendBaseUrl = this.occEndpointsService.getBaseUrl();
    }

    getSyncOrderSubject$(): Observable<boolean> {
        return this.syncOrderSubject.asObservable();
    }

    getSyncOrderFailedSubject$(): Observable<boolean> {
        return this.syncOrderFailedSubject.asObservable();
    }

    isOrderSynced(): void {
        this.syncOrderSubject.next(true);
    }

    resetOrderSync(): void {
        this.syncOrderSubject.next(false);
    }

    orderSyncFailed(): void {
        this.syncOrderFailedSubject.next(true);
    }

    resetOrderSyncFailed(): void {
        this.syncOrderFailedSubject.next(false);
    }

    getFinishingOrder(): Observable<boolean> {
        return this.finishingOrder.asObservable();
    }

    setFinishingOrder(value: boolean): void {
        this.finishingOrder.next(value);
    }

    syncOrder(): Observable<HttpResponse<object>>{
        this.getActiveCartAndCurrentuserIds$().subscribe(cartAndUserIds => {
            this.cartId = cartAndUserIds.activeCartId as string;
            this.userId = cartAndUserIds.userId as string;
        })
            .unsubscribe();

        if (this.userId && this.cartId){
            return this.sikoOccCheckoutAdapter.syncOrder(
                this.userId,
                this.cartId
            );
        }

        return of(new HttpResponse<{status: 404; body: {}}>({
            status: 404,
            body: null
        }));
    }

    onPlaceOrderClick(termsChecked: boolean, payload: object): void {
        this.finishingOrder.next(true);
        this.getActiveCartAndCurrentuserIds$().subscribe(cartAndUserIds => {
            this.cartId = cartAndUserIds.activeCartId as string;
            this.userId = cartAndUserIds.userId as string;
        })
            .unsubscribe();

        if (this.userId && this.cartId){
            this.sikoOccCheckoutAdapter.sikoPlaceOrder(this.userId, this.cartId, termsChecked, payload).pipe()
                .subscribe((data: SikoOrder) => {
                    if (data.paymentType?.code === PAYMENT_TYPES.CARD_PAYMENT && data.paymentGateway){
                        this.handlePaymentGatewayRedirection(data);
                    }
                    else if ( data.code ){
                        // Force user reload after place order in order to fetch and store current bonuspoints info
                        this.eventService.dispatch(
                            {user: {}},
                            UserAccountChangedEvent
                        );

                        void this.routingService.goByUrl(`order-confirmation/${data.code}`);
                    }
                }, (errorResponse: SikoHttpErrorResponse) => {
                    this.setFinishingOrder(false);

                    const termsConfirmRequired = errorResponse.error.errors.some((error: Error) =>
                        error.message === CHECKOUT_TERMS_CONFIRM_REQUIRED_ERR_MESSAGE);

                    const recalculationRequired = errorResponse.error.errors.some((error: Error) =>
                        error.message === CHECKOUT_RECALCULATION_REQUIRED_ERR_MESSAGE);

                    const invalidTotalPrice = errorResponse.error.errors.some((error: Error) =>
                        error.message === CHECKOUT_INVALID_TOTAL_PRICE_ERR_MESSAGE);

                    if (termsConfirmRequired) {
                        this.globalMessageService.add(
                            { key: 'formErrors.sikoTermsAndConditions' },
                            GlobalMessageType.MSG_TYPE_WARNING
                        );
                    }

                    if (invalidTotalPrice) {
                        this.globalMessageService.add(
                            { key: 'formErrors.sikoInvalidTotalPrice' },
                            GlobalMessageType.MSG_TYPE_WARNING
                        );
                    }

                    if (recalculationRequired) {
                        this.globalMessageService.add(
                            { key: 'sikoCheckout.placeOrderRecalculationRequired' },
                            GlobalMessageType.MSG_TYPE_WARNING
                        );
                        this.resetOrderSync();
                    }
                    else {
                        // TODO fix CheckoutActions in SikoCheckoutService
                        // this.store.dispatch(new CheckoutActions.PlaceOrderFail(normalizeHttpError(errorResponse)));
                    }
                });
        }
    }

    handlePaymentGatewayRedirection(responseData: SikoOrder):
      void {
        if (!responseData.paymentGateway || !responseData.code) {
            return;
        }

        this.sikoOccCheckoutAdapter.getPaymentGatewayRedirectUrl(responseData.paymentGateway).
            subscribe((redirectUrl: object | string) => {
                if ( typeof redirectUrl === 'string' && redirectUrl.length > 0){
                    location.replace(redirectUrl);
                }
            }, (errorResponse: SikoHttpErrorResponse) => {
                let redirectUrl = '';
                const orderCode = responseData.code ?? 'NOT_FOUND';

                if (
                    errorResponse.status === HTTP_ERROR.CHECKOUT_INCORRECT_ORDER_CODE ||
                    errorResponse.status === HTTP_ERROR.CHECKOUT_PG_CONNECTION_ERROR
                ) {
                    this.globalMessageService.add(
                        { key: 'siko.orderConfirmation.errorMessage' },
                        GlobalMessageType.MSG_TYPE_ERROR
                    );

                    redirectUrl = `order-confirmation/${orderCode}?status=${CHECKOUT_PAYMENT_GATEWAY_STATUS.PAYMENT_STATUS_ERROR}`;
                }
                else {
                    redirectUrl = `order-confirmation/${orderCode}?cancelled`;
                }

                void this.routingService.goByUrl(redirectUrl);
            });

    }

    private getActiveCartAndCurrentuserIds$(): Observable<{ userId: unknown; activeCartId: unknown }>{
        return combineLatest(
            this.userIdService.getUserId(),
            this.activeCartService.getActiveCartId()
        ).pipe(
            map(([userId, activeCartId]) => ({
                userId,
                activeCartId
            })),
        );
    }

}
