import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-image-grid',
    templateUrl: './image-grid.component.html',
    styleUrls: ['./image-grid.component.scss']
})

export class Sikob2bImageGridComponent {
  @Input() url = null;
  @Input() imageUrl = '';
  @Input() altText = null;
}
