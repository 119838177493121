import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import { StoreModule } from '@ngrx/store';
import { META_REDUCERS, REDUCER_PROVIDER, REDUCER_TOKEN } from '@siko/features/my-account/order-history/store/reducers';
import { ORDER_FILTER_HISTORY_FEATURE } from '@siko/features/my-account/order-history/store/order-history-state';
import { EffectsModule } from '@ngrx/effects';
import { OrderHistoryEffect } from '@siko/features/my-account/order-history/store/effects';

@NgModule({
    declarations: [],
    imports: [
        B2bCommonModule,
        StoreModule.forFeature(ORDER_FILTER_HISTORY_FEATURE, REDUCER_TOKEN, {
            metaReducers: META_REDUCERS,
        }),
        EffectsModule.forFeature([OrderHistoryEffect])
    ],
    providers: [REDUCER_PROVIDER],
})
export class SikoOrderHistoryStoreModule {}
