import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import {
    GlobalMessageService, GlobalMessageType,
    UserIdService,
} from '@spartacus/core';
import { Store } from '@ngrx/store';
import { CartActions, StateWithMultiCart } from '@spartacus/cart/base/core';
import { SikoCart } from '@siko/models';
import { SikoQuickOrderService } from '@siko/features/quick-order/siko-quick-order.service';
import { SikoDialogService, SikoTrackingUtils } from '@siko/shared';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { SikoAutoUnsubscribe } from '@siko/common';

@SikoAutoUnsubscribe([
    'cartRemoveEntryFailed',
])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-remove-cart-entry',
    templateUrl: './remove-cart-entry.component.html',
    styleUrls: ['./remove-cart-entry.component.scss'],
})
export class SikoRemoveCartEntryComponent {

    index?: number;
    modalIsOpen: boolean;
    productCode?: string;
    isQuickOrder = false;
    private userId?: string;
    private cartId?: string;
    cartRemoveEntryFailed?: Subscription;

    constructor(
        protected cd: ChangeDetectorRef,
        protected multiCartService: MultiCartFacade,
        protected store: Store<StateWithMultiCart>,
        protected activeCartService: ActiveCartFacade,
        protected userIdService: UserIdService,
        readonly globalMessageService: GlobalMessageService,
        readonly actions: Actions,
        readonly quickOrderService: SikoQuickOrderService,
        readonly trackingUtils: SikoTrackingUtils,
        readonly dialogService: SikoDialogService,
    ) {
        this.modalIsOpen = false;

        this.userIdService.getUserId().subscribe((occUserId: string) => this.userId = occUserId)
            .unsubscribe();

        this.activeCartService.getActiveCartId().subscribe((activeCartId: string) => {
            this.cartId = activeCartId;
        })
            .unsubscribe();
    }

    dismissModal(reason?: string): void {
        this.dialogService.dismissActiveModal(reason);
    }

    removeCartEntry(): void {
        let cart: SikoCart = {};

        this.activeCartService
            .getActive()
            .subscribe((activeCart: SikoCart) => cart = activeCart)
            .unsubscribe();

        if (!this.isQuickOrder) {
            if (this.index !== undefined && this.userId && this.cartId) {
                if (cart.entries) {
                    this.trackingUtils.pushModifyCartEvent([cart.entries[this.index]], undefined, 'removeFromCart');
                }

                this.store.dispatch(new CartActions.CartRemoveEntry(
                    {
                        cartId: this.cartId,
                        userId: this.userId,
                        entryNumber: this.index.toString()
                    }
                ));

                this.cartRemoveEntryFailed = this.actions.pipe(
                    ofType(CartActions.CART_REMOVE_ENTRY_FAIL),
                )
                    .subscribe(() => {
                        this.globalMessageService.add(
                            { key: 'httpHandlers.cart.reloadCartRequired' },
                            GlobalMessageType.MSG_TYPE_ERROR
                        );
                    });

                this.dismissModal();
            }
        }
        else if (this.index !== undefined) {
            this.quickOrderService.softDeleteEntry(this.index);
            this.dismissModal();
        }
    }

}
