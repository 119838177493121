<div class='siko-dialog-header'>
  <div class='siko-dialog-title' [ngClass]='hasBorder ? "siko-dialog-header--bordered" : ""'>
    <ng-container *ngIf='loading else displayContent'>
      <app-dialog-header-skeleton-icon></app-dialog-header-skeleton-icon>
    </ng-container>
    <ng-template #displayContent>
      <ng-content></ng-content>
    </ng-template>
    <div
      class='siko-dialog-close-icon'
      cxModal='dismiss'
      (click)='onCloseButton()'
    >
      <app-dialog-close-icon-svg></app-dialog-close-icon-svg>
    </div>
  </div>
</div>
