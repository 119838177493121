import { Category, CurrencyService, EventService, LanguageService, WindowRef } from '@spartacus/core';
import { Injectable } from '@angular/core';
import {
    SikoCart,
    SikoOrder,
    SikoOrderEntry,
    SikoProduct,
    SikoProductBrand,
    SikoProductSearchPage,
    SikoProductSerie,
    SikoPromotionResult,
    SIKO_SELECTED_DELIVERY_MODE_ENUM,
} from '@siko/models';
import { SikoPageEvent } from '../../events/SikoPageEvent';
import { SikoViewEvent } from '../../events/SikoViewEvent';
import { SikoModifyCartEvent } from '../../events/SikoModifyCartEvent';
import { SikoCheckoutEvent } from '../../events/SikoCheckoutEvent';
import { EcommerceDetailProductModel } from '../../models/ecommerceDetailProduct.model';
import { SikoPurchaseEvent } from '../../events/SikoPurchaseEvent';
import { EcommerceDetailCategoryModel } from '../../models/ecommerceDetailCategory.model';
import { SikoResetEvent } from '../../events/SikoResetEvent';
import { sfGetProductWeight, sfIsFreeGift } from '@siko/shared/utils/siko-functions';
import { SikoUtils } from '@siko/shared/utils/siko-utils';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { SikoCartSaveEvent } from '../../events/SikoCartSaveEvent';
import { EcommercePageViewDetailModel } from '../../models/ecommercePageViewDetail.model';
import { ASM } from '@siko/constants';

@Injectable({
    providedIn: 'root',
})
export class SikoTrackingUtils {

    constructor(
        private readonly activeCartService: ActiveCartFacade,
        private readonly currencyService: CurrencyService,
        private readonly languageService: LanguageService,
        private readonly eventService: EventService,
        private readonly winRef: WindowRef,
    ) {
    }

    getActualCurrency(): string {
        let currency = '';

        this.currencyService.getActive()
            .subscribe(data => {
                currency = data;
            });

        return currency;
    }

    getActualLanguage(): string {
        let language = '';

        this.languageService.getActive()
            .subscribe(data => {
                language = data;
            });

        return language;
    }

    pushSikoPageEvent(consent: boolean, url?: string, sapCustomerGroup?: string): void {
        const sikoPageEvent = new SikoPageEvent();
        let pageType = '';

        if (url) {
            pageType = this.getPageType(url);
        }

        sikoPageEvent.event = 'page_loaded';

        const agentName = this.winRef.localStorage?.getItem(ASM.ASM_AGENT);

        let view : EcommercePageViewDetailModel = {
            consent,
            pageType,
            language: this.getActualLanguage(),
            currency: this.getActualCurrency(),
            sapCustomerGroup,
            ASM: false
        };

        if (agentName) {
            view = {
                ...view,
                ASM: true,
                ASM_ID: agentName
            }
        }

        sikoPageEvent.ecommerce = {
            view
        };

        this.eventService.dispatch(sikoPageEvent);
    }

    pushCategoryViewEvent(model: SikoProductSearchPage): void {
        const categoryViewEvent = new SikoViewEvent();
        let sikoProductSearchPage: SikoProductSearchPage = {};

        sikoProductSearchPage = model;

        const categoryData: EcommerceDetailCategoryModel = {
            name: sikoProductSearchPage.category?.name,
            id: sikoProductSearchPage.categoryCode,
            category: sikoProductSearchPage.sikoBreadcrumbs && sikoProductSearchPage.sikoBreadcrumbs.length > 1 ? this.constructCategory(sikoProductSearchPage.sikoBreadcrumbs, '/') : '',
            categories_path: sikoProductSearchPage.sikoBreadcrumbs && sikoProductSearchPage.sikoBreadcrumbs.length > 1 ? this.constructCategory(sikoProductSearchPage.sikoBreadcrumbs, '>') : '',
        };

        if (sikoProductSearchPage.sikoBreadcrumbs) {
            this.constructCategories(categoryData, sikoProductSearchPage.sikoBreadcrumbs);
        }

        categoryViewEvent.event = 'category';
        categoryViewEvent.ecommerce = {
            detail: {
                category: [categoryData],
            },
        };

        this.eventService.dispatch(categoryViewEvent);
    }

    pushProductViewEvent(data: SikoProduct | null): void {
        const productViewEvent = new SikoViewEvent();
        let group = '';

        if (data?.bonusGroup) {
            group = data.bonusGroup;
        }


        const productData: EcommerceDetailProductModel = {
            name: data?.name,
            id: data?.code,
            category: data?.breadcrumbs && data.breadcrumbs.length > 1 ? this.constructCategory(data.breadcrumbs, '/') : '',
            categories_path: data?.breadcrumbs && data.breadcrumbs.length > 1 ? this.constructCategory(data.breadcrumbs, '>') : '',
            category_id: data?.breadcrumbs && data.breadcrumbs.length > 0 ? data.breadcrumbs[data.breadcrumbs.length - 1].code : '',
            original_price: data?.basePrice?.value,
            price: data?.price?.value,
            weight: sfGetProductWeight(data),
            category_ids: this.constructCategoryIds(data),
            brand: data?.brand,
            bonusGroup: group,
        };

        if (data?.breadcrumbs) {
            this.constructCategories(productData, data.breadcrumbs);
        }

        productViewEvent.event = 'productDetail';
        productViewEvent.ecommerce = {
            detail: {
                products: [productData],
            },
        };

        this.eventService.dispatch(productViewEvent);
    }

    pushModifyCartEvent(entries: (SikoOrderEntry | undefined)[], quantity: number | undefined, action: string, source?: string): void {
        const modifyCartEvent = new SikoModifyCartEvent();

        modifyCartEvent.event = action;
        modifyCartEvent.ecommerce = {
            currencyCode: this.getActualCurrency(),
        };

        const products: {}[] = [];

        entries.forEach(value => {
            let productInfo = {};

            if (value) {
                let quantityProduct = quantity === undefined ? value.quantity : quantity;
                let quantity_in_package = 0;
                if (quantityProduct) {
                    quantity_in_package = quantityProduct * Number(value.product?.unitData?.conversion);
                }

                productInfo = {
                    brand: value.product?.brand,
                    name: value.product?.name,
                    id: value.product?.code,
                    price: value.price?.value,
                    quantity: quantityProduct,
                    quantity_in_package: SikoUtils.sikoRound(quantity_in_package, 3),
                    bonusGroup: value.product?.bonusGroup,
                };

            }

            products.push(productInfo);
        });

        if (action === 'addToCart') {
            modifyCartEvent.ecommerce = {
                currencyCode: this.getActualCurrency(),
                add: {
                    products,
                    source,
                },
            };
        }
        else {
            modifyCartEvent.ecommerce = {
                currencyCode: this.getActualCurrency(),
                remove: {
                    products,
                },
            };
        }

        this.eventService.dispatch(modifyCartEvent);
    }

    pushResetEvent(): void {
        this.eventService.dispatch(new SikoResetEvent());
    }

    pushCheckoutEvent(step: number) {
        const checkoutEvent = new SikoCheckoutEvent();

        checkoutEvent.event = 'checkout';

        const products: EcommerceDetailProductModel[] = [];
        let cartCode: string | undefined;
        let revenue: number | undefined;
        let tax: number | undefined;

        this.activeCartService.getActive()
            .subscribe((cart: SikoCart) => {
                if (cart.entries) {
                    cartCode = cart.code;
                    revenue = cart.subTotal?.value;
                    tax = cart.totalTax?.value;
                    cart.entries.forEach(entry => {
                        let group = '';

                        if (entry.product?.bonusGroup) {
                            group = entry.product.bonusGroup;
                        }

                        let quantity_in_package = 0;
                        if (entry.quantity) {
                            quantity_in_package = entry.quantity * Number(entry.product?.unitData?.conversion);
                        }

                        const productData: EcommerceDetailProductModel = {
                            name: entry.product?.name,
                            id: entry.product?.code,
                            category: entry.product?.breadcrumbs && entry.product.breadcrumbs.length > 1 ? this.constructCategory(entry.product.breadcrumbs, '/') : '',
                            categories_path: entry.product?.breadcrumbs && entry.product.breadcrumbs.length > 1 ? this.constructCategory(entry.product.breadcrumbs, '->') : '',
                            category_id: entry.product?.breadcrumbs ? entry.product.breadcrumbs[entry.product.breadcrumbs.length - 1].code : '',
                            price: entry.price?.value,
                            category_ids: this.constructCategoryIds(entry.product),
                            brand: entry.product?.brand,
                            url: entry.product?.url,
                            quantity: entry.quantity,
                            quantity_in_package: SikoUtils.sikoRound(quantity_in_package, 3),
                            bonusGroup: group,
                            weight: sfGetProductWeight(entry.product)
                        };

                        if (entry.product?.breadcrumbs) {
                            this.constructCategories(productData, entry.product.breadcrumbs);
                        }

                        products.push(productData);
                    });
                }
            })
            .unsubscribe();

        checkoutEvent.ecommerce = {
            checkout: {
                actionField: {
                    id: cartCode,
                    step,
                    revenue: revenue,
                    tax: tax,
                },
                products,
            },
        };


        this.eventService.dispatch(checkoutEvent);
    }

    pushCartSaveEvent(data: SikoCart | undefined, saveInvalidEntries: boolean): void {
        if (data !== undefined) {
            const cartSaveEvent = new SikoCartSaveEvent();
            cartSaveEvent.event = 'cartSave';
            cartSaveEvent.ecommerce = {
                save: {
                    actionField: {
                        cartId: data.code,
                    },
                    products: this.fetchProducts(data, saveInvalidEntries),
                },
            };

            this.eventService.dispatch(cartSaveEvent);
        }
    }

    pushPurchaseEvent(data: SikoCart | SikoOrder, type: string): void {
        const purchaseEvent = new SikoPurchaseEvent();

        purchaseEvent.event = type;

        let shipping_address: string | undefined = '';

        if (data.pickupOrderGroups && data.pickupOrderGroups.length > 0) {
            shipping_address = data.pickupOrderGroups[0].deliveryPointOfService?.displayName;
        }

        let shipping_type = 'Delivery';
        if (data.deliveryMode?.code === SIKO_SELECTED_DELIVERY_MODE_ENUM.ZAV_NET) {
            shipping_type = 'Shipment';
            shipping_address = data.deliveryAddress?.companyName;
        }
        else if (data.deliveryMode?.code === SIKO_SELECTED_DELIVERY_MODE_ENUM.PICKUP) {
            shipping_type = 'Store';
        }

        purchaseEvent.ecommerce = {
            purchase: {
                actionField: {
                    id: data.code,
                    payment_type: data.paymentType?.code?.toLowerCase(),
                    revenue: data.subTotal?.value,
                    shipping: data.deliveryCost?.value,
                    shipping_address,
                    shipping_type,
                    shipping_weight: data.sikoTotalWeight,
                    tax: data.totalTax?.value,
                    coupon: this.getOrderCoupons(data.appliedOrderPromotions),
                },
                products: this.fetchProducts(data, false),
            },
        };

        this.eventService.dispatch(purchaseEvent);

    }

    constructCategory(breadcrumbs: any[], separator: string): string {
        let category = '';

        breadcrumbs.forEach((breadcrumb, index) => {
            if (index == 0) {
                return;
            }

            category += breadcrumb.name;

            if (breadcrumbs.length - 1 !== index) {
                category += ' ' + separator + ' ';
            }
        });

        return category;
    }

    constructCategoryIds(product: SikoProduct | null | undefined): string[] {
        const categoriesIds: any[] = [];

        if (product) {
            const mergedArrays = [...product.brands || [], ...product.categories || [], ...product.series || []];

            mergedArrays.forEach((item: SikoProductBrand | Category | SikoProductSerie) => {
                categoriesIds.push(item.code);
            });
        }

        return categoriesIds;
    }

    constructCategories(data: EcommerceDetailCategoryModel | EcommerceDetailProductModel, array: any[]): void {
        let categoryIdx = 1;

        array.forEach((value, index) => {
            if (index == 0) {
                return;
            }

            // @ts-expect-error
            data['category_' + categoryIdx++] = value.name;
        });
    }

    getPageType(url: string): string {
        switch (true) {
            case url.includes('/c/'):
                return 'category';
            case url.includes('/p/'):
                return 'productDetail';
            case url.includes('/cart'):
                return 'cart';
            case url.includes('/search'):
                return 'searchResults';
            case url.includes('/checkout'):
                return 'checkout';
            case url.includes('/my-account') && !url.includes('quick-order'):
                return 'myAccount';
            case url.includes('/organization'):
                return 'myCompany';
            case url.includes('/quick-order'):
                return 'quickOrder';
            case url.includes('/login') && !url.includes('register'):
                return 'login';
            case url.includes('/register'):
                return 'register';
            case url.includes('/404'):
                return 'errorPage';
            case url.includes('/aktuality'):
                return 'news';
            case url.includes('/store-finder'):
                return 'storeFinder';
            case url.length == 1:
                return 'homepage';
        }

        return 'landingPage';
    }

    getOrderCoupons(promotions: SikoPromotionResult[] | undefined): string[] {

        if (!promotions) {
            return [];
        }

        let coupons: string[] = [];

        promotions.forEach((promotion: SikoPromotionResult) => {
            if (promotion.promotion?.code) {
                coupons.push(promotion.promotion.code);
            }
        });

        return coupons;
    }

    getProductCoupon(promotions: SikoPromotionResult[] | undefined): string {
        if (promotions && promotions.length > 0) {
            if (promotions[0].promotion && promotions[0].promotion.code) {
                return promotions[0].promotion.code;
            }
        }

        return '';
    }

    private fetchProducts(data: SikoCart, saveInvalidEntries: boolean = false): EcommerceDetailProductModel[] {
        const products: EcommerceDetailProductModel[] = [];
        const entries: SikoOrderEntry[] | undefined = saveInvalidEntries && data?.undefinedOrderGroups ? data?.undefinedOrderGroups[0]?.entries : data.entries;

        entries?.forEach((entry, index) => {
            let group = '';

            if (entry.product?.bonusGroup) {
                group = entry.product.bonusGroup;
            }

            let quantity_in_package = 0;
            if (entry.quantity) {
                quantity_in_package = entry.quantity * Number(entry.product?.unitData?.conversion);
            }

            products.push({
                name: entry.product?.name,
                id: entry.product?.code,
                categories_path: entry.product?.breadcrumbs && entry.product.breadcrumbs.length > 1 ? this.constructCategory(entry.product.breadcrumbs, '->') : '',
                category_id: entry.product?.breadcrumbs ? entry.product.breadcrumbs[entry.product.breadcrumbs.length - 1].code : '',
                price: sfIsFreeGift(index, data.appliedProductPromotions) ? 0 : entry.price?.value,
                category_ids: this.constructCategoryIds(entry.product),
                brand: entry.product?.brand,
                url: entry.product?.url,
                original_price: sfIsFreeGift(index, data.appliedProductPromotions) ? entry.price?.value : entry.basePrice?.value,
                quantity: entry.quantity,
                quantity_in_package: SikoUtils.sikoRound(quantity_in_package, 3),
                weight: sfGetProductWeight(entry.product),
                bonusGroup: group,
                coupon: this.getProductCoupon(entry.promotions),
            });
        });

        return products;
    }

}
