import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-footer-outlet-replace',
    templateUrl: './footer-outlet-replace.component.html',
    styleUrls: ['./footer-outlet-replace.component.scss'],
})
export class FooterOutletReplaceComponent {
}
