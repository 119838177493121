import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-title-with-count',
    templateUrl: './title-with-count.component.html',
    styleUrls: ['./title-with-count.component.scss'],
})
export class SikoTitleWithCountComponent {

    @Input() text?: string;
    @Input() count?: number;
    @Input() mobile = false;
    @Input() grey = false;

    get classesList(): string[] {
        return [
            this.mobile ? 'mobile-title' : 'title',
            this.grey ? 'grey' : 'title--bordered',
        ];
    }

}
