import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SikoProduct } from '@siko/models';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-details-footer',
    templateUrl: './product-details-footer.component.html',
    styleUrls: ['./product-details-footer.component.scss'],
})
export class SikoProductDetailsFooterComponent {

    constructor(private readonly currentProductService: CurrentProductService) {}

    sikoProduct$: Observable<SikoProduct | null> = this.currentProductService.getProduct();
}
