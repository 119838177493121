import { Injectable } from '@angular/core';
import {
    BasePageMetaResolver,
    CmsService, ContentPageMetaResolver, Page, PageType, TranslationService
} from '@spartacus/core';
import { defer, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ActiveCartFacade } from '@spartacus/cart/base/root';

@Injectable({
    providedIn: 'root'
})
export class SikoContentPageMetaResolver extends ContentPageMetaResolver {

    organizationPageTemplate = 'CompanyPageTemplate';
    protected page$: Observable<Page> = defer(() =>
        this.cmsService.getCurrentPage()).pipe(filter((p) => Boolean(p)));

    constructor(
    protected translation: TranslationService,
    protected activeCartService: ActiveCartFacade,
    protected cmsService: CmsService,
    protected basePageMetaResolver: BasePageMetaResolver
    ) {
        super(basePageMetaResolver);
        this.pageType = PageType.CONTENT_PAGE;
    }

    resolveTitle(): Observable<string> {
        return this.page$.pipe(
            switchMap((page: Page) => this.translation.translate('siko.contentPageTitle', {
                title: page.title
            }))
        );
    }

    getScore(page: Page): number {
        if (page.type && page.type === 'ContentPage') {
            return super.getScore(page) + 2;
        }

        return super.getScore(page);
    }

}
