import { SikoActionLoader } from '@siko/features/action-loader/models/action-loader.model';

export const SIKO_ACTION_LOADER_FEATURE = 'sikoActionLoader';

export interface StateWithSikoActionLoader {
    [SIKO_ACTION_LOADER_FEATURE]: SikoActionLoaderState;
}

export interface SikoActionLoaderState {
    entities: SikoActionLoader[];
}
