import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SikoNavigationNode } from '@siko/models';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-simple-banner',
    templateUrl: './simple-banner.component.html',
})
export class SikoSimpleBannerComponent {

    @Input() data?: SikoNavigationNode;

}
