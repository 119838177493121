<ng-container *ngIf='items$ | async as productReferences'>
  <ng-container *ngIf='productReferences.length > 0'>
    <h1
      class='added-to-cart-dialog-title mt-32 mt-sm-0'>{{'addedToCartDialog.accessoriesReminderMessage' | cxTranslate}}</h1>
    <div class='added-to-cart-carousel-swiper'>
      <swiper #swiper
              [config]='productCarouselConfig'
              [ngClass]='productReferences.length > 1 ? "swiper-multiple-container" : ""'
      >
        <ng-container *ngFor='let productReference of productReferences'>
          <ng-template swiperSlide>
            <div class='siko-carousel-card'
                 [routerLink]="{ cxRoute: 'product', params: productReference.target } | cxUrl"
                 cxModal='dismiss'
            >
              <div class='carousel-product-image'>
                <div class='labels d-none d-sm-block'>
                  <siko-label [labels]='productReference.target?.labels' [maxLabels]='3'></siko-label>
                </div>
                <app-media
                  class='media justify-content-center'
                  [container]='$any(productReference.target?.images?.PRIMARY)'
                  format='product'
                ></app-media>
              </div>
              <div class='d-flex flex-column w-100 carousel-product-title'>
                <div class='mt-0 mt-sm-8'>
                  <siko-star-rating
                    *ngIf='productReference.target?.numberOfReviews !== undefined && productReference.target?.averageRating'
                    [disabled]='true'
                    [ratingCount]='5'
                    [resetAble]='true'
                    [value]='productReference.target?.averageRating! ? productReference.target?.averageRating! : 0'
                    [countReviews]='productReference.target?.numberOfReviews'
                    size='sm'
                  ></siko-star-rating>
                </div>
                {{productReference.target?.name}}
                <div class='d-flex flex-row flex-sm-column justify-content-between mt-6 mt-sm-8'>
                  <div class='carousel-product-price'
                       [ngClass]='isDiscounted(productReference.target) ? "discount" : ""'>
                    {{productReference.target?.price | sikoPrice}}
                    <span class='d-sm-none'><app-product-unit
                      [productUnitData]='productReference.target?.unitData'
                      [withSlash]='true'
                      [muted]='true'
                    ></app-product-unit></span>
                  </div>
                  <app-product-group [sikoProduct]='productReference.target'></app-product-group>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </swiper>
    </div>
  </ng-container>
</ng-container>
