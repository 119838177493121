import { NgModule } from '@angular/core';
import { provideConfig, provideConfigFactory } from '@spartacus/core';
import * as CustomLayoutConfig from './layout-config';
import * as CustomCartConfig from './cart-config';
import * as CustomCheckoutStepsConfig from './checkout-steps-config';
import * as CustomRoutingConfig from './routing-config';
import * as CustomMediaConfig from './media-config';
import * as PlpProductViewConfig from './plp-product-view-config';
import * as QuickOrderConfig from './quick-order.config';
import { sikoB2BGlobalMessageConfigFactory } from './global-message.config-factory';
import * as SikoPaginationConfig from './siko-pagination-config';


@NgModule({
    declarations: [],
    imports: [],
    providers: [
        provideConfig(CustomLayoutConfig.CUSTOM_LAYOUT_CONFIG),
        provideConfig(CustomCartConfig.CUSTOM_CART_CONFIG),
        provideConfig(CustomCheckoutStepsConfig.CUSTOM_CHECKOUT_STEPS_CONFIG),
        provideConfig(CustomRoutingConfig.CUSTOM_ROUTING_CONFIG),
        provideConfig(CustomRoutingConfig.CUSTOM_ROUTES_CONFIG),
        provideConfig(CustomMediaConfig.MEDIA_CONFIG),
        provideConfig(PlpProductViewConfig.SIKO_PLP_VIEW_CONFIG),
        provideConfig(QuickOrderConfig.SIKO_QUICK_ORDER_CONFIG),
        provideConfig(SikoPaginationConfig.SIKO_PAGINATION_CONFIG),
        provideConfigFactory(sikoB2BGlobalMessageConfigFactory)
    ],
})
export class CustomConfigurationModule {
}
