import { Pipe, PipeTransform } from '@angular/core';
import { SikoProduct } from '@siko/models';
import { sfGetProductWeight } from '@siko/shared/utils/siko-functions';

@Pipe({
    name: 'sikoProductWeight',
})
export class SikoProductWeightPipe implements PipeTransform {

    transform(product: SikoProduct | undefined): number {
        return sfGetProductWeight(product);
    }

}
