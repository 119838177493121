import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProductListItemComponent, ProductListItemContextSource } from '@spartacus/storefront';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Product, StateWithProduct } from '@spartacus/core';
import { SikoOccProductAdapter } from '@siko/features/product/occ/adapters/occ-product.adapter';
import { Store } from '@ngrx/store';
import { SikoProduct } from '@siko/models';
import { SikoAutoUnsubscribe, SikoBreakpointObserverService } from '@siko/common';
import { SikoPricePipe } from '@siko/shared/pipes/price.pipe';
import { SikoDialogService } from '@siko/shared';
import { SikoProductDetailDialogComponent } from '@siko/features/shared-components/product-detail-dialog/components/product-detail-dialog/product-detail-dialog.component';


@SikoAutoUnsubscribe(['updateProductPriceSubscription'])
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-product-list-item-plp',
    templateUrl: './product-list-item-plp.component.html',
    styleUrls: ['./product-list-item-plp.component.scss'],
    providers: [ProductListItemContextSource],
})
export class SikoProductListItemPlpComponent extends ProductListItemComponent implements OnInit {
    @Input() isStoreDisponibility = false;
    @Input() order = '';
    @Input() shadow = '';
    @Input() searchBoxResult = false;

    sikoProduct?: SikoProduct;
    isUpdating = new BehaviorSubject<boolean>(false);
    updatePriceSuccess = new BehaviorSubject<boolean>(false);
    updatePriceFailed = new BehaviorSubject<boolean>(false);
    updatedPrice = new BehaviorSubject<string>('');
    showZoomImage$ = new BehaviorSubject<boolean>(false);
    updateProductPriceSubscription?: Subscription;

    constructor(
        public sikoBreakpointObserver: SikoBreakpointObserverService,
        readonly sikoPricePipe: SikoPricePipe,
        readonly dialogService: SikoDialogService,
        readonly occSikoProductAdapter: SikoOccProductAdapter,
        readonly ref: ChangeDetectorRef,
        readonly store: Store<StateWithProduct>,
        productListItemContextSource: ProductListItemContextSource,
    ) {
        super(productListItemContextSource);
    }

    ngOnInit(): void {
        this.order = String(Number(this.order) + 1);
        this.updatedPrice.next(this.product?.discountPrice ? this.sikoPricePipe.transform(this.product.discountPrice?.value) : this.sikoPricePipe.transform(this.product.basePrice?.value));
    }

    toggleZoomImage(): void {
        this.showZoomImage$.next(!this.showZoomImage$.value);
    }

    updateProductPrice(code: string): void {
        if (this.isUpdating.value === true) {return;}

        this.isUpdating.next(true);

        this.updateProductPriceSubscription = this.occSikoProductAdapter.updateProductPrice(code)
            .subscribe(product => {
                    this.updatedPrice.next(this.sikoPricePipe.transform(product.price?.value));
                    this.updatePriceSuccess.next(true);
                    this.isUpdating.next(false);
                    this.ref.detectChanges();
                },
                error => {
                    this.isUpdating.next(false);
                    this.updatePriceSuccess.next(false);
                    this.updatePriceFailed.next(true);
                    this.ref.detectChanges();
                });
    }

    openProductDetailModal(product: Product): void {

        const modalRef = this.dialogService.open(SikoProductDetailDialogComponent, {
            windowClass: 'siko-dialog-window',
            size: 'lg',
        });

        const modalInstance: SikoProductDetailDialogComponent =
            modalRef.componentInstance as SikoProductDetailDialogComponent;

        modalInstance.sikoProductCode = product.code;
        modalInstance.sourceForGTM = 'plp';
    }

}
