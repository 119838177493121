<ng-container *ngIf='product && control'>
  <ng-container *ngIf='label'>
    <span class='siko-font-primary-semiBold siko-fs-14'>{{label}}</span>
  </ng-container>
  <div class='item-counter-wrapper'>
    <div class='item-counter'
         [class]='counterSize'
         [ngClass]="{
        'item-counter--discounted': control.disabled,
        'justify-content-between': !control.disabled,
        'red-border': this.highlighted
     }"
    >
      <ng-container *ngIf='control.enabled else disabledControl'>
        <button
          type='button'
          [ngClass]='showRemoveIcon() ? "delete-button" : ""'
          class='action-btn decrement'
          (debounceClick)='decrement($event)'
          [throttleTime]='100'
          [disabled]='isButtonDisabled'
          appDebounceClick
          [tabindex]='control.disabled || control.value <= minVal ? -1 : 0'
          [disableTooltip]='!showRemoveIcon()'
          attr.aria-label="{{ 'itemCounter.removeOne' | cxTranslate }}"
          ngbTooltip
          [container]='"body"'
          placement='auto'
          sikoTooltipText="{{ 'siko.deleteItem' | cxTranslate}}"
        >
          <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g id='minimize'>
                <path id='Vector' d='M4 7.33334H12V8.66667H4V7.33334Z' fill='#193137' />
              </g>
          </svg>
        </button>
        <div
          class='input-container'
          [ngClass]="{'input-is-focused': inputIsFocused}"
          (click)='focusInput()'
        >
          <input
            #displayValue
            #itemCounterInputField
            type='tel'
            [min]='minVal'
            [max]='$any(max)'
            maxlength='8'
            [step]='step'
            tabindex='0'
            (focus)='inputIsFocused = true'
            (blur)='inputIsFocused = false'
            (focusout)='focusOut($event)'
            (keydown)='preventLetters($event)'
            (keyup.enter)='displayValue.blur()'
            [formControl]='control'
            attr.aria-label="{{ 'itemCounter.quantity' | cxTranslate }}"
          />
          <div>
            <app-product-unit
              [count]='control.value'
              [productUnitData]='product.unitData'
              [muted]='true'
            >
            </app-product-unit>
          </div>
        </div>
        <button
          type='button'
          class='action-btn increment'
          [ngClass]='isTooltipEnabled(control) ? "" : "disabled"'
          (debounceClick)='increment($event, isTooltipEnabled(control))'
          [throttleTime]='100'
          [disabled]='isButtonDisabled'
          appDebounceClick
          tabindex='0'
          attr.aria-label="{{ 'itemCounter.addOneMore' | cxTranslate }}"
          [style.cursor]="isTooltipEnabled(control) ? '' : 'not-allowed'"
          [disableTooltip]='!this.allowTooltip || isTooltipEnabled(control)'
          ngbTooltip
          placement='auto'
          container='body'
          sikoTooltipText="{{ 'siko.insufficientAmount' | cxTranslate : { pointOfService: posName } }}"
        >
          <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='add'>
              <path id='Vector'
                    d='M12.6666 8.66634H8.66659V12.6663H7.33325V8.66634H3.33325V7.33301H7.33325V3.33301H8.66659V7.33301H12.6666V8.66634Z'
                    fill='#193137' />
            </g>
          </svg>
        </button>
      </ng-container>
      <ng-template #disabledControl>
        0
        <app-product-unit
          [count]='0'
          [productUnitData]='product.unitData'
          [muted]='true'
        >
        </app-product-unit>
      </ng-template>
    </div>
    <ng-container *ngIf='displayQuantityMessage'>
      <div class='quantity-text' *ngIf='max'>
        {{'siko.outOf' | cxTranslate}}
        {{ roundQuantity(this.productUnitConversion * max) }}
        <app-product-unit
          [productUnitData]='product.unitData'
          [muted]='true'
        >
        </app-product-unit>
      </div>
    </ng-container>
  </div>
</ng-container>
