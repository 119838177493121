import { PageSlotComponent } from '@spartacus/storefront';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'cx-page-slot,[cx-page-slot]',
    templateUrl: './page-slot.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SikoPageSlotComponent extends PageSlotComponent {

    protected set pending(count: number) {
        super.pending = count;

        if (this.isPageFold && !this.isPending) {
            const cxContainer = (this.elementRef.nativeElement as HTMLElement).closest('cx-storefront');

            if (cxContainer) {
                this.renderer.addClass(cxContainer, 'atf-loaded');
            }
        }
    }

}
