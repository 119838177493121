import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SikoNavigationNode } from '@siko/models';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-menu-banner',
    templateUrl: './menu-banner.component.html',
    styleUrls: ['./menu-banner.component.scss'],
})
export class MenuBannerComponent {

    @Input() data?: SikoNavigationNode;

}
