import { Injectable } from '@angular/core';
import { sfGenerateGuid } from '@siko/shared/utils/siko-functions';
import { isNotUndefined, Translatable } from '@spartacus/core';
import { SikoActionLoaderActions } from '@siko/features/action-loader/store/actions';
import { select, Store } from '@ngrx/store';
import { StateWithSikoActionLoader } from '@siko/features/action-loader/store/action-loader-state';
import { Observable } from 'rxjs';
import { SikoActionLoaderSelectors } from '@siko/features/action-loader/store/selectors';
import { filter } from 'rxjs/operators';
import { SikoActionLoader } from '@siko/features/action-loader/models/action-loader.model';
import { SikoButtonComponent } from '@siko/components';

@Injectable({
    providedIn: 'root',
})
export class SikoActionLoaderService {

    constructor(protected store: Store<StateWithSikoActionLoader>) {}

    create(message?: Translatable | string, button?: SikoButtonComponent): SikoActionLoader {
        const uid = sfGenerateGuid();
        let actionMessage: Translatable | undefined = typeof message === 'string' ? { raw: message } : message;

        if (actionMessage === undefined) {
            actionMessage = { raw: '' };
        }

        button?.setLoading(true);

        this.store.dispatch(
            new SikoActionLoaderActions.AddAction({
                uid,
                message: actionMessage,
            }),
        );

        return {
            uid,
            message: actionMessage,
            button,
        };
    }

    remove(uid: string, button?: SikoButtonComponent): void {
        button?.setLoading(false);
        this.store.dispatch(new SikoActionLoaderActions.RemoveAction(uid));
    }

    removeCallback(loader: SikoActionLoader): () => void {
        return () => {
            this.remove(loader.uid, loader.button);
        };
    }

    getInstances(): Observable<SikoActionLoader[]> {
        return this.store.pipe(
            select(SikoActionLoaderSelectors.getSikoActionLoaderEntities),
            filter(isNotUndefined),
        );
    }

}
