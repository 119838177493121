import { Component, OnInit } from '@angular/core';
import { CurrentProductService } from '@spartacus/storefront';
import { isNotNullable, PointOfService, RoutingService } from '@spartacus/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { OccBopisAdapter } from '@siko/features/cart/occ/adapters/occ-bopis-adapter.service';
import { SikoBopisPointOfService, SikoProduct } from '@siko/models';
import { SikoActiveCartService } from '@siko/features/cart';
import { SikoAutoUnsubscribe } from '@siko/common';
import { SikoDialogService } from '@siko/shared';
import { SikoProductDetailDialogComponent } from '@siko/features/shared-components/product-detail-dialog/components/product-detail-dialog/product-detail-dialog.component';

@SikoAutoUnsubscribe(['currentProductSubscription'])
@Component({
    selector: 'app-pick-up-in-other-store-dialog',
    templateUrl: './pick-up-in-other-store-dialog.component.html',
})
export class SikoPickUpInOtherStoreDialogComponent implements OnInit {

    storeEntities?: SikoBopisPointOfService[];
    mainStoreEntity?: SikoBopisPointOfService;
    filteredStoreEntities?: SikoBopisPointOfService[];
    currentProductCode?: string;
    modalSelectedPointOfService?: SikoBopisPointOfService;
    isPurchasable?: boolean;
    inputProduct?: SikoProduct;
    currentProductSubscription?: Subscription;
    sourceForGTM = '';
    openedFromPLP = false;

    constructor(
        public dialogService: SikoDialogService,
        private readonly routingService: RoutingService,
        private readonly currentProductService: CurrentProductService,
        private readonly occBopisAdapter: OccBopisAdapter,
        private readonly sikoActiveCartService: SikoActiveCartService,
    ) {
    }

    addToCartForm = new UntypedFormGroup({
        quantity: new UntypedFormControl(1, { updateOn: 'blur' }),
    });

    get currentProduct$(): Observable<SikoProduct | null> {
        return this.inputProduct ? of(this.inputProduct) : this.currentProductService.getProduct();
    }

    ngOnInit(): void {
        this.currentProductSubscription = this.currentProduct$.pipe(
            filter(isNotNullable),
            tap((sikoProduct: SikoProduct) => this.currentProductCode = sikoProduct.code),
            switchMap((sikoProduct: SikoProduct) =>
                this.occBopisAdapter.getPickUpInStorePointsOfServices(sikoProduct.code)
                    .pipe(
                        map((data: PointOfService[]) => {
                            this.mainStoreEntity = data.find(s => s.name == 'O 10100265');
                            this.storeEntities = data;
                            this.filteredStoreEntities = data;
                            this.setModalSelectedPointOfService(this.storeEntities[0]);
                        }),
                    )),
        )
            .subscribe();
    }

    setModalSelectedPointOfService(selectedPointOfService: SikoBopisPointOfService): void {
        this.modalSelectedPointOfService = selectedPointOfService;
        this.isPurchasable =
            this.modalSelectedPointOfService.stockInfo?.stockLevel !== 0 ||
            this.modalSelectedPointOfService.stockInfo.stockLevel === null;
    }

    isInStore(storeEntity?: SikoBopisPointOfService): boolean {
        if (storeEntity) {
            return storeEntity.stockInfo?.stockLevelStatus === 'inStock';
        }

        return false;
    }

    searchStore(name: string): void {
        this.filteredStoreEntities = this.storeEntities;

        if (this.filteredStoreEntities) {
            this.filteredStoreEntities = this.filteredStoreEntities.filter((item: any) => {
                if (item.displayName) {
                    return item.displayName.toLowerCase()
                        .includes(name.toLowerCase()) || item.address.postalCode?.replace(' ', '')
                        .indexOf(name.replace(' ', '')
                            .toLowerCase()) > -1;
                }
            });
        }
    }

    removeSikoPrefix(name: string | undefined): string {
        if (name) {
            return name.replace('SIKO', '');
        }

        return '';
    }

    goToStoreDetailLink(pointOfService: PointOfService): void {
        this.dialogService.dismissActiveModal('Redirecting store detail.');
        this.routingService.goByUrl('store-finder?store=' + pointOfService.name);
    }

    close(): void {
        this.dialogService.dismissActiveModal('');
        if (this.openedFromPLP) {
            const modalRef = this.dialogService.open(SikoProductDetailDialogComponent, {
                windowClass: 'siko-dialog-window',
                size: 'lg',
            });

            const modalInstance: SikoProductDetailDialogComponent =
                modalRef.componentInstance as SikoProductDetailDialogComponent;

            if (this.inputProduct){
                modalInstance.sikoProductCode = this.inputProduct.code;
            }
        }
    }
}
