import { Action } from '@ngrx/store';
import { SikoSapOrderDetailState } from '@siko/features/my-account/sap-order-detail/store/siko-sap-order-state';
import { SikoSapOrderActions } from '@siko/features/my-account/sap-order-detail/store/actions';

export const INITIAL_STATE: SikoSapOrderDetailState = {
    sapOrderData: undefined,
    invoices: [],
    deliveryNotes: [],
};

export const sikoSapOrderReducer = (
    state = INITIAL_STATE,
    action: Action
): SikoSapOrderDetailState => {
    switch (action.type) {
        case SikoSapOrderActions.LOAD_SAP_ORDER_SUCCESS: {
            const sapOrderData = (action as SikoSapOrderActions.SikoSapOrderAction).payload;

            return {
                ...state,
                sapOrderData,
            };
        }

        case SikoSapOrderActions.LOAD_SAP_ORDER_DOCUMENTS_SUCCESS:

        case SikoSapOrderActions.LOAD_ORDER_DOCUMENTS_SUCCESS: {
            const { invoices } = (action as SikoSapOrderActions.SikoSapOrderAction).payload;
            const { deliveryNotes } = (action as SikoSapOrderActions.SikoSapOrderAction).payload;

            return {
                ...state,
                invoices,
                deliveryNotes,
            };
        }
    }

    return state;
};
