import { Injectable } from '@angular/core';
import { SortColumn } from '@siko/shared';

@Injectable({
    providedIn: 'root'
})
export class SikoSortFunctionsService {

    compare = (v1: number | string, v2: number | string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

    // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
    dataTableSort(
        sortedEntities: any[],
        column: SortColumn,
        direction: string,
        nestedSortObjectKeyParent = '',
        nestedSortObjectKeyChild = '',
        isNestedObjectSort = false
    ): any[]{

        if (direction === '' || column === '') {
            return sortedEntities;
        }
        else if (isNestedObjectSort){
            return [...sortedEntities].sort((a, b) => {
                const aValue = a[nestedSortObjectKeyParent]?.[nestedSortObjectKeyChild];
                const bValue = b[nestedSortObjectKeyParent]?.[nestedSortObjectKeyChild];
                const res = this.compare(aValue, bValue);
                return direction === 'asc' ? res : -res;
            });
        }


        return [...sortedEntities].sort((a, b) => {
            const res = this.compare(a[column], b[column]);

            return direction === 'asc' ? res : -res;
        });

    }
}
