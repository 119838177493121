<div class='configuration-wrapper'>
  <cx-page-slot position='VariantConfigHeader'></cx-page-slot>

  <div class='row'>
    <div class='col-md-6'>
<!--      <app-product-images></app-product-images>-->
    </div>

    <div class='col-md-6'>
      <cx-page-slot position='VariantConfigMenu'></cx-page-slot>
      <cx-page-slot position='VariantConfigContent'></cx-page-slot>
    </div>
  </div>
</div>
