/**
 * CMS Models are not exposed from the Spartacus packages. Use just for custom implementations.
 * Not compatible with default Spartacus CMS store.
 * Might change in the future
 */
import { CmsBannerComponent, CmsNavigationEntry, Image } from '@spartacus/core';

export interface SikoComponent {
  modifiedTime?: Date;
  name?: string;
  typeCode?: string;
  uid?: string;
}

export enum PageRobots {
  INDEX_FOLLOW = 'INDEX_FOLLOW',
  NOINDEX_FOLLOW = 'NOINDEX_FOLLOW',
  INDEX_NOFOLLOW = 'INDEX_NOFOLLOW',
  NOINDEX_NOFOLLOW = 'NOINDEX_NOFOLLOW',
}

export interface ComponentList {
  component: SikoComponent[];
}

export interface ContextSlot {
  components?: ComponentList;
  name?: string;
  position?: string;
  slotId?: string;
  slotShared?: boolean;
  slotUuid?: string;
}

export interface SikoNavigationNode {
  uid?: string;
  component?: SikoComponent;
  title?: string;
  url?: string [] | string;
  target?: boolean | string;
  children?: SikoNavigationNode[];
  entries?: CmsNavigationEntry[];
  styleClasses?: string;
  styleAttributes?: string;
  itemType?: string;
  titleSecondary?: string;
  text?: string;
  media?: Image;
  isActive?: boolean;
}

export interface MenuBannerCmsComponent extends CmsBannerComponent {
  title?: string;
  url?: string;
}

export interface PromoBannerCmsComponent extends CmsBannerComponent {
  title?: string;
  titleSecondary?: string;
  altText?: string;
  url?: string;
}
