import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { LoadSapOrderDocumentsResponse } from '@siko/models';

@Injectable({
    providedIn: 'root',
})
export class OccSapOrderAdapter {
    userId?: string;

    private readonly backendBaseUrl: string;

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected userIdService: UserIdService
    ) {
        this.backendBaseUrl = this.occEndpoints.getBaseUrl();
        this.userIdService
            .getUserId()
            .subscribe(occUserId => this.userId = occUserId)
            .unsubscribe();
    }

    getSapOrderDocuments(
        userId: string,
        sapOrderId: string
    ): Observable<LoadSapOrderDocumentsResponse> {
        return this.http.get(
            `${this.backendBaseUrl}/users/${userId}/sap-orders/${sapOrderId}/documents`
        );
    }

    getSapOrderDocumentPdf(userId: string, sapOrderId: string, type: string): Observable<Blob> {
        return this.http.get(
            `${this.backendBaseUrl}/users/${userId}/document-download/${sapOrderId}/${type}`,
            {
                responseType: 'blob',
            }
        );
    }

    getOrderDocuments(userId: string, orderId: string): Observable<LoadSapOrderDocumentsResponse> {
        return this.http.get(`${this.backendBaseUrl}/users/${userId}/orders/${orderId}/documents`);
    }

    getOrderDocumentPdf(userId: string, documentId: string): Observable<Blob> {
        return this.http.get(
            `${this.backendBaseUrl}/users/${userId}/documents/download?documentCode=${documentId}`,
            {
                responseType: 'blob',
            }
        );
    }
}
