import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
} from '@angular/core';
import { SikoActionLoader } from '@siko/features/action-loader/models/action-loader.model';
import { BehaviorSubject } from 'rxjs';
import { SikoActionLoaderService } from '@siko/features/action-loader/action-loader.service';
import { AUTO_CLOSE_TIME, AUTO_OPEN_TIME } from '@siko/features/action-loader/config/action-loader.config';

@Component({
    selector: 'app-action-loader-instance',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './action-loader-instance.component.html',
    styleUrls: ['./action-loader-instance.component.scss'],
})
export class SikoActionLoaderInstanceComponent implements AfterViewInit, OnDestroy {

    @Input() actionLoader: SikoActionLoader | undefined;

    open$ = new BehaviorSubject<boolean>(false);
    autoOpenTimeout = 0;
    autoCloseTimeout = 0;

    constructor(
        private readonly changeDetector: ChangeDetectorRef,
        private readonly actionLoaderService: SikoActionLoaderService,
    ) {}

    ngAfterViewInit(): void {
        if (typeof window !== 'undefined') {
            this.autoOpenTimeout = window.setTimeout(() => {
                this.toggleState(true);
                this.changeDetector.detectChanges();
            }, AUTO_OPEN_TIME);

            this.autoCloseTimeout = window.setTimeout(() => {
                this.toggleState(false);
                this.changeDetector.detectChanges();
            }, AUTO_OPEN_TIME + AUTO_CLOSE_TIME);
        }
    }

    toggleState(open?: boolean): void {
        if (open === undefined) {
            open = !this.open$.getValue();
            this.clearTimeouts();
        }

        this.open$.next(open);
    }

    remove(event: Event): void {
        event.stopPropagation();

        if (this.actionLoader) {
            this.actionLoaderService.remove(this.actionLoader.uid);
        }
    }

    ngOnDestroy(): void {
        this.clearTimeouts();
    }

    private clearTimeouts(): void {
        if (this.autoOpenTimeout) {
            clearTimeout(this.autoOpenTimeout);
        }

        if (this.autoCloseTimeout) {
            clearTimeout(this.autoCloseTimeout);
        }
    }

}
