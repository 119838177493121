import { createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import {
    SikoNewsState,
    SikoUnseenNewsState,
    StateWithSikoNews
} from '@siko/features/news/store/siko-news-state';
import { getSikoNewsState } from './news-feature.selector';

export const getSikoUnseenState: MemoizedSelector<
    StateWithSikoNews,
    StateUtils.LoaderState<SikoUnseenNewsState>
  > = createSelector(
      getSikoNewsState, (newsState: SikoNewsState) =>
          newsState.unreadNews
  );

export const getSikoUnseenNewsCount: MemoizedSelector<
    StateWithSikoNews,
    number
  > = createSelector(
      getSikoUnseenState, (sikoUnseenState: StateUtils.LoaderState<SikoUnseenNewsState>) =>
          sikoUnseenState.value?.unseenNewsCount ?? 0
  );
