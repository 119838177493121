import { NgModule } from '@angular/core';
import { B2bCommonModule } from '@siko/shared';
import { SikoOrderDetailTotalsComponent } from '@siko/features/shared-components/order-details-total/order-detail-totals.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SikoCustomerCouponsModule } from '@siko/features/my-account/customer-coupons/customer-coupons.module';
import { SikoReorderButtonComponent } from '@siko/features/shared-components/reorder-button/reorder-button.component';
import { SikoOrderSummaryComponent } from '@siko/features/shared-components/order-summary/order-summary.component';
import { SikoCouponCardComponent } from '@siko/features/shared-components/coupon-card/coupon-card.component';


@NgModule({
    declarations: [
        SikoOrderDetailTotalsComponent,
    ],
    imports: [
        B2bCommonModule,
        NgbModule,
        SikoCustomerCouponsModule,
        SikoReorderButtonComponent,
        SikoOrderSummaryComponent,
        SikoCouponCardComponent,
    ],
    exports: [
        SikoOrderDetailTotalsComponent,
    ],
})
export class SikoOrderDetailTotalsModule {}
