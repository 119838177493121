<ng-container *ngIf='sikoBreakpointObserver.breakpointChange$ | async'>
  <ng-container *ngIf='{observableValue : showZoomImage$ | async } as isZoomedImage'>
    <div class='product-list-item' [ngClass]='isZoomedImage.observableValue ? "grey-bg" : ""'>
      <div class='zoomed-image-mobile' *ngIf='isZoomedImage.observableValue'>
        <div class='image-zoom'>
          <app-media
            class='img-responsive list'
            [container]='product.images?.PRIMARY'
            format='product'
            [alt]='product.summary'
          ></app-media>
        </div>
        <div class='close-zoomed-image' (click)='toggleZoomImage()'></div>
      </div>
      <div class='image-block'>
        <div class='zoomed-image-desktop'>
          <div class='image-zoom'>
            <app-media
              class='img-responsive list'
              [container]='product.images?.PRIMARY'
              format='product'
              [alt]='product.summary'
            ></app-media>
          </div>
        </div>
        <div
          class='product-image-container'
        >
          <div class='d-flex text-center product-image'>
            <app-media
              class='img-responsive list'
              [container]='product.images?.PRIMARY'
              format='product'
              [alt]='product.summary'
            ></app-media>
          </div>
          <div class='zoom-image' (click)='toggleZoomImage()'></div>
          <div class='d-block d-sm-none'>
            <app-product-disponibility
              *ngIf='!isStoreDisponibility'
              [sikoProduct]=product
              [isStoreDisponibility]='false'
              [displayShortVersion]='true'
            >
            </app-product-disponibility>
          </div>
        </div>
      </div>
      <div class='summary-block'>
        <div class='d-flex flex-wrap align-items-center summary-block-name'>
          <div>
            <div class='labels'>
                <siko-label [labels]='product.labels' [showShortVersion]='true'></siko-label>
            </div>
            <a [routerLink]='product | sikoProductUrl' class='product-name'>
              {{product.name}}
            </a>
          </div>
        </div>
        <div class='disponibility-info d-none d-sm-block'>
          <app-product-disponibility
            *ngIf='!isStoreDisponibility'
            [sikoProduct]=product
            [isStoreDisponibility]='false'
            [displayShortVersion]='true'
          >
          </app-product-disponibility>
        </div>
      </div>
      <div class='price-block d-flex align-items-end flex-column'>
        <ng-container *ngIf='updatedPrice | async as price'>
          <div
            [ngClass]='product?.discountPrice ? "sale-price" : ""'
            class='product-price'
            [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
          ><app-price [price]='price' [MOPrice]='!updatePriceSuccess.getValue()'></app-price>
          </div>
        </ng-container>
        <div class='product-recalculation-container'>
          <app-product-group [sikoProduct]='product'></app-product-group>
          <ng-container
            *ngIf='!updatePriceSuccess.getValue() && !updatePriceFailed.getValue() else recalculationProcessed'>
        <span class='product-recalculate-icon-wrapper'>
          <span class='product-recalculate-icon' (click)='updateProductPrice(product.code)'
                [ngClass]="this.isUpdating.getValue() ? 'product-recalculate-icon--animated' : ''"></span>
        </span>
          </ng-container>
          <ng-template #recalculationProcessed>
            <ng-container *ngIf='updatePriceSuccess.getValue()'>
              <div class='success-icon'></div>
            </ng-container>
            <ng-container *ngIf='updatePriceFailed.getValue()'>
              <span class='fail-icon'></span>
            </ng-container>
          </ng-template>
        </div>
        <div class='mobile-block'>
          <siko-button
            testId='buy-button'
            [mode]='$any("secondary")'
            [size]='$any("sm")'
            text='{{"siko.buy"|cxTranslate}}'
            (click)='openProductDetailModal(product)'>
          </siko-button>
        </div>
      </div>
      <div class='detail-block'>
        <siko-button
          testId='buy-button'
          [mode]='$any("secondary")'
          [size]='$any("sm")'
          text='{{"siko.buy"|cxTranslate}}'
          (click)='openProductDetailModal(product)'>
        </siko-button>
      </div>
    </div>
  </ng-container>
</ng-container>
