import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetOrderEntriesContext, OrderEntriesSource, ProductData, OrderEntry, ActiveCartFacade } from '@spartacus/cart/base/root';
import { SikoActiveCartService } from '@siko/features/cart/services/siko-active-cart.service';

@Injectable({
    providedIn: 'root',
})
export class SikoActiveCartOrderEntriesContext implements GetOrderEntriesContext {
    readonly type = OrderEntriesSource.ACTIVE_CART;

    constructor(
    protected activeCartService: SikoActiveCartService,
    protected activeCartFacade: ActiveCartFacade,
    ) {
    }

    getEntries(): Observable<OrderEntry[]> {
        return this.activeCartFacade.getEntries();
    }

    add(products: ProductData[]): Observable<string> {
        this.activeCartService.addEntriesToFavoriteStore(this.mapProductsToOrderEntries(products));

        return this.activeCartFacade.getActiveCartId();
    }

    protected mapProductsToOrderEntries(products: ProductData[]): OrderEntry[] {
        return products.map(
            (product: { productCode: string; quantity: number }) => ({
                product: { code: product.productCode },
                quantity: product.quantity,
            })
        );
    }
}
