import { Component, Input, OnInit } from '@angular/core';
import {
    SIKO_SELECTED_DELIVERY_MODE_ENUM,
    SIKO_SHIPMENT,
    SikoCart,
    SikoPointOfService,
    SikoProduct,
    SikoUser,
} from '@siko/models';
import { SikoStoreIconComponent } from '@siko/features/shared-components/icons/store/store.component';
import { SikoShippingIconComponent } from '@siko/features/shared-components/icons/shipping/shipping.component';
import { SikoLocationIconComponent } from '@siko/features/shared-components/icons/location/location.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SikoPickUpInOtherStoreDialogComponent } from '@siko/features/shared-components/pick-up-in-other-store-dialog/components/pick-up-in-other-store-dialog/pick-up-in-other-store-dialog.component';
import { SikoActiveCartService } from '@siko/features/cart';
import { B2bCommonModule, SikoDialogService } from '@siko/shared';
import { SikoCheckCircleIconComponent } from '@siko/features/shared-components/icons/check-circle/check-circle.component';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { map } from 'rxjs/operators';
import { SikoAutoUnsubscribe } from '@siko/common';
import { isProductForSale, showAddToCartButtons } from '@siko/features/product/product-utils';
import { SikoRedCrossIconComponent } from '@siko/features/shared-components/icons/red-cross/red-cross.component';
import { ProductActions, ProductScope, StateWithProduct } from '@spartacus/core';
import { Store } from '@ngrx/store';
import { SikoProductDisponibilityComponent } from '@siko/features/shared-components/product-disponibility/product-disponibility.component';
import { SikoStoreProductDisponibilityComponent } from '@siko/features/shared-components/store-product-disponibility/store-product-disponibility.component';
import { SikoMoveIconComponent } from '@siko/features/shared-components/icons/move-icon/move-icon.component';

@SikoAutoUnsubscribe(['subscription'])
@Component({
    standalone: true,
    selector: 'app-product-availability-info',
    templateUrl: './product-availability-info.component.html',
    styleUrls: ['./product-availability-info.component.scss'],
    imports: [
        B2bCommonModule,
        SikoStoreIconComponent,
        SikoShippingIconComponent,
        SikoLocationIconComponent,
        SikoProductDisponibilityComponent,
        SikoCheckCircleIconComponent,
        SikoRedCrossIconComponent,
        SikoStoreProductDisponibilityComponent,
        SikoMoveIconComponent,
    ],
})

export class SikoProductAvailabilityInfoComponent implements OnInit {

    @Input() openedFromPLP: boolean = false;
    sikoProduct?: SikoProduct;
    activeDeliveryMode: BehaviorSubject<string> = new BehaviorSubject<string>('undefined');
    predefinedPOS: BehaviorSubject<SikoPointOfService> = new BehaviorSubject<SikoPointOfService>({});
    selectedPOS: BehaviorSubject<SikoPointOfService> = new BehaviorSubject<SikoPointOfService>({});
    subscription?: Subscription;

    constructor(
        readonly store: Store<StateWithProduct>,
        readonly sikoActiveCartService: SikoActiveCartService,
        readonly userService: UserProfileFacade,
        readonly activeCartService: ActiveCartFacade,
        readonly dialogService: SikoDialogService,
    ) {}

    @Input() set product(input: SikoProduct) {
        this.sikoProduct = input;
    }

    ngOnInit(): void {
        this.subscription = combineLatest(
            this.activeCartService.getActive(),
            this.userService.get(),
        )
            .pipe(
                map(([cart, user]) => {
                    const sikoUser: SikoUser | undefined = user;
                    const sikoCart: SikoCart | undefined = cart;

                    if (sikoUser && sikoUser.orgUnit?.sikoPredefinedPointOfService) {
                        this.predefinedPOS.next(sikoUser.orgUnit?.sikoPredefinedPointOfService);
                    }

                    if (sikoCart.selectedDeliveryMode) {
                        if (sikoCart.selectedDeliveryMode?.code === SIKO_SELECTED_DELIVERY_MODE_ENUM.ZAV_NET) {
                            this.activeDeliveryMode.next(SIKO_SHIPMENT);
                            if (sikoCart.selectedDeliveryPOS) {
                                this.selectedPOS.next(sikoCart.selectedDeliveryPOS);
                            }
                        }
                        else if (sikoCart.selectedDeliveryMode?.code !== 'pickup') {
                            this.activeDeliveryMode.next('deliveryToAddress');
                        }
                        else {
                            if (this.predefinedPOS.getValue()?.name && sikoCart.selectedDeliveryPOS?.name?.includes(<string>this.predefinedPOS.getValue().name)) {
                                this.activeDeliveryMode.next('pickup');
                            }
                            else {
                                if (sikoCart.selectedDeliveryPOS) {
                                    this.selectedPOS.next(sikoCart.selectedDeliveryPOS);
                                    this.activeDeliveryMode.next('pickupOtherStore');
                                }
                            }
                        }

                        if (this.selectedPOS.getValue().name !== this.sikoProduct?.disponibility?.selectedStoreDisponibility?.pointOfService) {
                            if (this.sikoProduct?.code) {
                                this.store.dispatch(
                                    new ProductActions.LoadProduct(this.sikoProduct?.code),
                                );
                            }
                        }
                    }
                }),
            )
            .subscribe();
    }

    isProductAvailable(): boolean {
        let isForSale = true;
        if (this.sikoProduct?.disponibility && this.sikoProduct.availableIn) {
            isForSale = isProductForSale(this.sikoProduct.disponibility, +this.sikoProduct.availableIn, this.sikoProduct.sapStatus!);
            const inCentralStock: boolean = this.sikoProduct?.disponibility?.level ? this.sikoProduct.disponibility.level > 0 : false;

            return showAddToCartButtons(isForSale, inCentralStock, +this.sikoProduct?.availableIn, this.sikoProduct.sapStatus);
        }
        return true;
    }

    openPickUpInOtherStoreDialog(sikoProduct: SikoProduct): void {
        this.dialogService.dismissActiveModal('');
        const canAddToCart = this.sikoActiveCartService.canAddToCart();

        if (!canAddToCart) {return;}

        const modalRef: NgbModalRef = this.dialogService.open(SikoPickUpInOtherStoreDialogComponent, {
            windowClass: 'siko-dialog-window',
            size: 'lg',
        });

        const modalInstance: SikoPickUpInOtherStoreDialogComponent =
            modalRef.componentInstance as SikoPickUpInOtherStoreDialogComponent;

        modalInstance.inputProduct = sikoProduct;
        modalInstance.sourceForGTM = 'pdp';
        modalInstance.openedFromPLP = this.openedFromPLP;
    }

}
