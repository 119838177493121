<ng-container *ngIf='sikoNode$ | async as node'>
  <ng-container *ngIf='sikoComponentData$ | async as componentData'>
    <ng-container *ngIf='isDesktop$ | async as isDesktop'>
      <ng-container *ngIf='isDesktop.matches else notDesktop'>
        <div class='hide-xs'>
          <app-category-navigation-ui
            [node]='node'
            [flyout]='true'
            [isOpen]='true'
            [resetMenuOnClose]='true'
          ></app-category-navigation-ui>
        </div>
      </ng-container>
      <ng-template #notDesktop>
        <div class='hide-desktop'>
          <app-mobile-category-navigation-ui
            [node]='node'
            [flyout]='true'
            [isOpen]='true'
            [resetMenuOnClose]='true'
          ></app-mobile-category-navigation-ui>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
