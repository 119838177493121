<div #navMenu>
  <div #openUserNav class='hide-desktop d-flex justify-content-center'
       [ngClass]="this.isUserNavOpen ? 'user-icon' : ''"
       (click)='openUserNavigation()'>
    <img class='icon hide-desktop' [src]='"header/mobile/user-logged-mobile.svg" | sikoIconAsset'
         alt='Logged user mobile icon'>
  </div>
  <div class='display-nav-menu' #displayNavMenu>
    <div class='user-nav'>
      <ng-container *ngIf='mergedNavigationNodes$ | async as mergedNavigationNodes'>
        <div ngbAccordion [closeOthers]='false' [attr.data-testid]='$any("my-account-dropdown")'>
          <ng-container *ngFor='let navigationNode of mergedNavigationNodes.children; let i = index'>
            <div class='card' ngbAccordionItem id='panel{{(i).toString()}}' [ngClass]="isOpen(i) ? 'openNgbPanel' : ''">
              <div class='card-header'>
                <button ngbAccordionButton class='btn btn-link accordion-button'
                        (click)='onNavClick(navigationNode)'>
                  <div class='d-flex justify-content-between align-items-center'>
                    <div class='d-flex icon-container'>
                      <img class='mobile-nav-icon' [src]='getIcon(i, mergedNavigationNodes.parents)'
                           alt='Navigation icon'>
                    </div>
                    <div class='d-flex mobile-nav-title'>
                      <h4 class='text-left'>{{ navigationNode.title }}</h4>
                    </div>
                    <div class='d-flex'>
                      <img
                        [ngClass]="isNavigationNodeDisabled(navigationNode.children) ? 'd-none' : ''"
                        class='mobile-vector-icon' [src]='"header/mobile/nav-vector.svg" | sikoIconAsset'
                        alt='Arrow icon'>
                    </div>
                  </div>
                </button>
              </div>

              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <ul class='text-left'>
                      <li *ngFor='let navigationChildNode of navigationNode.children'
                          (click)='routeByUrl(navigationChildNode)'>
                        <ng-container *ngIf='navigationChildNode.url === "/import/csv/saved-cart" else nonImportLink'>
                          <a class='navigation-link siko-fs-16'>{{ "siko.importSavedCart" | cxTranslate }}</a>
                        </ng-container>
                        <ng-template #nonImportLink>
                          <a class='navigation-link siko-fs-16'>{{ navigationChildNode.title }}</a>
                        </ng-template>
                      </li>
                    </ul>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf='node$ | async as node'>
    <ng-container *ngIf='styleClass$ | async as styleClass'>
      <div class='nav-wrapper hide-xs'>
        <div class='icon-container'>
          <img class='icon' [src]='"header/user-logged.svg" | sikoIconAsset' alt='Logged user'>
        </div>
        <div class='pl-3'>
          <cx-navigation-ui [showBonusProgram]='hasActiveBonusProgram' [node]='node' [ngClass]='styleClass'
                            [attr.data-testid]='$any("my-account-dropdown")'></cx-navigation-ui>
          <ng-container *ngIf='user$ | async as user'>
            <div class='siko-fs-16' [attr.data-testId]='$any("header-user-name")'>
              {{ resolveUserName(user.name) }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
