import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import { SikoUtils } from '@siko/shared';

if (environment.production) {
    enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
    Sentry.startSpan({ name: 'Angular Bootstrap', op: 'ui.angular.bootstrap' }, async () => {
        try {
            await platformBrowserDynamic()
                .bootstrapModule(AppModule);
        } catch (err) {
            console.error(err);
        }
    });
});

// Initialize Sentry
const sentryConfig = SikoUtils.getSentryConfig();
if (sentryConfig) {
    Sentry.init(sentryConfig);
}
