<ng-container *ngIf="product$ | async as product">
  <div (click)="pdpCommunicationService.openMobile('INFORMATION')" class="accordion-item">
    <div>
      <app-title-with-count
        [text]="'pdp.tabs.productInformation' | cxTranslate"
        [mobile]="true"
      ></app-title-with-count>
    </div>
    <div class="accordion-vector"></div>
  </div>
  <div (click)="pdpCommunicationService.openMobile('REVIEWS')" class="accordion-item">
    <div class="d-flex align-items-center">
      <app-title-with-count
        [text]="'pdp.tabs.ratings' | cxTranslate"
        [count]="product.numberOfReviews"
        [mobile]="true"
      ></app-title-with-count>
      <div class="ml-16" *ngIf="product.numberOfReviews">
        <siko-star-rating
          [disabled]="true"
          [ratingCount]="5"
          [resetAble]="true"
          [value]="product.averageRating ? product.averageRating : 0"
          [countReviews]=""
          size="sm"
        ></siko-star-rating>
      </div>
    </div>
    <div class="accordion-vector"></div>
  </div>
  <div (click)="pdpCommunicationService.openMobile('PARAMETERS')" class="accordion-item">
    <div>
      <app-title-with-count
        [text]="'pdp.tabs.technicalParameters' | cxTranslate"
        [mobile]="true"
      ></app-title-with-count>
    </div>
    <div class="accordion-vector"></div>
  </div>
  <div (click)="pdpCommunicationService.openMobile('DOCUMENTS')" class="accordion-item">
    <div>
      <app-title-with-count
        [text]="'pdp.tabs.documents' | cxTranslate"
        [count]="product.documents?.length ? product.documents?.length : 0"
        [mobile]="true"
      ></app-title-with-count>
    </div>
    <div class="accordion-vector"></div>
  </div>
</ng-container>
