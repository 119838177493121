import { NgModule } from '@angular/core';
import { B2bCommonModule, SikoProductUrlPipe } from '@siko/shared';
import { ConfigModule, UrlModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule, ProductListModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { SikoPageSlotModule } from '@siko/features/shared-components/page-slot/page-slot.module';
import { NgbCarouselModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SikoProductListItemPlpComponent } from '@siko/features/product/product-listing/product-list/components/product-list-plp-item/product-list-item-plp.component';
import { SwiperModule } from 'swiper/angular';
import { SikoProductDetailDialogModule } from '@siko/features/shared-components/product-detail-dialog/product-detail-dialog.module';
import { SikoPricePipe } from '@siko/shared/pipes/price.pipe';
import { Sikob2bAdBannerComponent } from './components/ad-banner/ad-banner.component';
import { SikoSortingComponent } from '@siko/features/product/product-listing/product-list/components/sorting/sorting.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SikoProductViewSwitcherComponent } from '@siko/features/product/product-listing/product-list/components/product-view-switcher/product-view-switcher.component';
import { SikoProductLabelComponent } from '@siko/features/product/product-listing/product-list/components/product-label/product-label.component';
import { SikoCategoryDescriptionComponent } from '@siko/features/product/product-listing/product-list/components/category-description/category-description.component';
import { SikoProductCategoriesComponent } from '@siko/features/product/product-listing/product-list/components/product-categories/product-categories.component';
import { Sikob2bTopSeriesComponent } from '../../search/top-series-component/top-series.component';
import { Sikob2bImageGridComponent } from '@siko/features/product/search/image-grid-component/image-grid.component';
import { SikoProductListComponent } from './product-list.component';
import { SikoProductGridItemComponent } from '@siko/features/product/product-listing/product-list/components/product-grid-item/product-grid-item.component';
import { SikoInfiniteScrollComponent } from '@siko/features/product/product-listing/product-list/components/infinite-scroll/infinite-scroll.component';
import { SikoMediaComponent } from '@siko/features/shared-components/media/media.component';
import { SikoProductUnitComponent } from '@siko/features/shared-components/product-unit/product-unit.component';
import { SikoStoreProductDisponibilityComponent } from '@siko/features/shared-components/store-product-disponibility/store-product-disponibility.component';
import { SikoProductGroupComponent } from '@siko/features/shared-components/product-group/product-group.component';
import { SikoDuckLoaderComponent } from '@siko/features/shared-components/duck-loader/duck-loader.component';
import { SikoActiveFacetsComponent } from '@siko/features/product/product-listing/product-list/components/active-facets/active-facets.component';
import { SikoProductDisponibilityComponent } from '@siko/features/shared-components/product-disponibility/product-disponibility.component';
import { SikoPriceComponent } from '@siko/features/shared-components/price/price.component';
import { SikoProductFacetNavigationComponent } from '@siko/features/product/product-listing/product-list/components/product-facet-navigation/product-facet-navigation.component';
import { SikoFacetListComponent } from '@siko/features/product/product-listing/product-list/components/product-facet-navigation/components/facet-list/facet-list.component';
import { SikoFacetComponent } from '@siko/features/product/product-listing/product-list/components/product-facet-navigation/components/facet/facet.component';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        SikoProductListComponent,
        SikoProductListItemPlpComponent,
        SikoProductCategoriesComponent,
        SikoCategoryDescriptionComponent,
        SikoInfiniteScrollComponent,
        SikoProductGridItemComponent,
        Sikob2bImageGridComponent,
        Sikob2bTopSeriesComponent,
        Sikob2bAdBannerComponent,
        SikoSortingComponent,
        SikoProductViewSwitcherComponent,
        SikoProductLabelComponent,
        SikoActiveFacetsComponent,
        SikoProductFacetNavigationComponent,
        SikoFacetListComponent,
        SikoFacetComponent,
    ],
    exports: [
        SikoCategoryDescriptionComponent,
    ],
    providers: [
        SikoPricePipe,
    ],
    imports: [
        B2bCommonModule,
        ConfigModule.withConfig({
            cmsComponents: {
                SikoCMSProductGridComponent: {
                    component: SikoProductListComponent,
                },
                ContentBeforeProductGridComponent: {
                    component: SikoProductCategoriesComponent,
                },
                CategoryDescriptionComponent: {
                    component: SikoCategoryDescriptionComponent,
                },
                ProductRefinementComponent: {
                    component: SikoProductFacetNavigationComponent,
                },
            },
        }),
        SikoMediaComponent,
        SikoPageSlotModule,
        RouterModule,
        UrlModule,
        SikoProductUnitComponent,
        SikoStoreProductDisponibilityComponent,
        NgbCarouselModule,
        NgbNavModule,
        ProductListModule,
        SwiperModule,
        SikoProductDetailDialogModule,
        SikoProductDisponibilityComponent,
        SikoProductGroupComponent,
        SikoDuckLoaderComponent,
        SikoProductUrlPipe,
        NgbTooltipModule,
        IconModule,
        NgSelectModule,
        SikoPriceComponent,
        KeyboardFocusModule,
        FormsModule,
    ],
})
export class SikoProductListModule {}
